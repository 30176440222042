import { useLocation, Link, useHistory } from 'react-router-dom';
import { Collapse, Select, Modal, Button, Table, Divider, Tooltip, Card } from 'antd';
import { useState, useEffect, useRef, Fragment } from 'react';
import SimpleReactValidator from "simple-react-validator";
import { connect, useDispatch, useSelector } from 'react-redux';
import { getdata, Approvertablereport, getAssigneeWiseData } from '../../../Store/Action/Report/ApproverScoreCard'
import { ExclamationCircleTwoTone, PlayCircleOutlined, FullscreenOutlined, UpOutlined, UpCircleOutlined, EyeOutlined, FilterOutlined } from '@ant-design/icons';
import ScrollToTop from "react-scroll-to-top";
import { fullscreenmode, fullscreenexit, handleKeyDown } from './../../../Libs/fullscreenmode';
import { validationType, validateSelect, onPasteToaster } from './../../../Libs/inputValidation';
import useStickyHeight from "../../../Libs/useStickyHeight";
import ScrollButton from '../../Scrollbar';
import { Toaster } from '../../../Libs/Toaster';
import { CountrySplit, HomeRedirect } from './../../../Libs/country'
const homepage = HomeRedirect()
const uniq_country_data = CountrySplit()

const mapStateToProps = (state) => ({
    reportlist: state.ApproverScoreCard.approverdata,
    assigneetabledatas: state.ApproverScoreCard.approverdata.assigneetabledata
})
const ApproverScoreCard = ({
    getdata,
    Approvertablereport,
    getAssigneeWiseData,
    assigneetabledatas,
    reportlist,
}) => {
    useStickyHeight();
    const historynew = useHistory();
    let completedInProgressSum;
    let completedUserInProgress;
    let delayedSum;
    let delayedUserSum;
    let completedOverdueSum;
    let completedUserOverdue;
    const { Panel } = Collapse;
    const { Option } = Select;
    const location = useLocation();
    const is_Fullscreen = useSelector((state) => state.Login.is_fullscreen);
    const dispatch = useDispatch();
    const paramid = location.pathname;
    const _ = require("lodash");
    const sessionValue = localStorage.getItem('sessionValue')
    let [index, setIndex] = useState(0);
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const sessionParsedValue = JSON.parse(sessionValue)
    const sessionArr = sessionParsedValue.entity_info
    const validator = useRef(new SimpleReactValidator());
    const [assigneeId, setAssigneeId] = useState(0)
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [key, setKey] = useState("1")
    const [titledata, Settitledata] = useState();
    console.log(titledata, 'titledata');
    const [showmore, setShowMore] = useState(false)
    const [type, setType] = useState('')
    const [entity, setEntity] = useState([])
    const [dataList1, setDataList1] = useState([])
    console.log(dataList1, 'dataList1');
    const [type1, setType1] = useState('')
    const [userdetail, setUserdetail] = useState('')
    const [tableshow, setTableShow] = useState(false)
    const [countrydata, setCountryData] = useState(null)
    const [approverdata, setApproverData] = useState(undefined)
    const [domain, setDomain] = useState([])
    const [completedModal, setCompletedModal] = useState(false)
    const [showMoreCount, setShowMoreCount] = useState(0)
    const [completedModal1, setCompletedModal1] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpen1, setIsModalOpen1] = useState(false);
    const [tabledata, setTableData] = useState([])
    const [dataSource, setDataSource] = useState([])
    const [dataSource1, setDataSource1] = useState([])
    console.log(approverdata, 'approverdata');
    const [count, setCount] = useState(2);
    const [count1, setCount1] = useState(2);
    const [counts, setCounts] = useState(100);
    const [filteredArray, setFilteredArray] = useState([])
    const [filteredArray1, setFilteredArray1] = useState([])
    const [filterTaskAcc, setFilterTaskAcc] = useState(true);
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const [exitCollapse, setExitCollapse] = useState(false);
    const [domainValue, setDomainValue] = useState(false)
    const [domainFilter, setDomainFilter] = useState([])
    const [datalist, setDataList] = useState({
        totaldata: []
    })
    const [header, setHeader] = useState('')
    const [approverscorecard, setApproverScoreCard] = useState({
        country: "",
        country_name: '',
        legel_entity: "",
        legel_entity_name: "",
        domain: "",
        domain_id: null,
        user: "",
        user_id: null,
        legalEntityLabel: '',
        domainLabel: '',
        countryLabel: '',
        userLabel: ''
    })

    const [selectSearchValue, setSelectSearchValue] = useState({
        country: '',
        legalEntity: '',
        domain: '',
        user: ''
    });

    const setcancelFilter = () => {
        setFilterModalVisible(false);
        setFilterTaskAcc(false);
    };
    console.log(assigneetabledatas, 'assigneetabledatas');
    const onshow = () => {

        setAddFormSubmit(true)
        if (validator.current.allValid() || (approverscorecard.domain_id != '' && approverscorecard.user_id != '')) {
            setExitCollapse(true);
            setFilterModalVisible(false);
            setFilterTaskAcc(false);
            setTableShow(true)
            setKey(0)
            setApproverScoreCard({
                ...approverscorecard,
                countryLabel: approverscorecard.country_name,
                legalEntityLabel: approverscorecard.legel_entity,
                domainLabel: approverscorecard.domain,
                userLabel: approverscorecard.user
            })
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetApproverScoreCard",
                        {
                            "c_id": Number(approverscorecard.country),
                            "le_id": entityid,
                            "d_id": Number(approverscorecard.domain_id),
                            "u_id": Number(approverscorecard.user_id)
                        }
                    ]
                }
            ]
            Approvertablereport({
                payload: payload,
                paramid: paramid
            })
        }
    }
    const showmore1 = () => {
        setShowMore(true)
        setCounts(counts + 100)
        const payload = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "GetApproverViewUserData",
                    {
                        "task_status": type1 === 'Unit_overdue' || type1 === 'User_overdue' ? "Overdue" : type1 === 'Unit_inProgress' || type1 === 'User_inProgress' ? 'Inprogress' : '',
                        "country_id": Number(approverscorecard.country),
                        "legal_entity_id": entityid,
                        "domain_id": Number(approverscorecard.domain_id),
                        "approver_user_id": Number(approverscorecard.user_id),
                        "assignee_user_id": Number(assigneeId),
                        "from_count": counts
                    }
                ]
            }
        ]
        console.log(payload, 'payload4555555');
        getAssigneeWiseData({
            payload: payload,
            paramid: paramid
        })
    }
    useEffect(() => {
        validator.current.showMessages()
    }, [])
    useEffect(() => {
        if (isAuth && entityid !== "null" && entityid != '') {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetApproverScoreCardFilters",
                        {
                            "le_id": entityid
                        }
                    ]
                }
            ]
            getdata({
                payload: payload,
                paramid: paramid
            })
        }
    }, [authtoken, isAuth, entityid])
    useEffect(() => {
        if (localStorage.getItem('SelectedEntityid') != null) {
            setCurrentEntity(localStorage.getItem('SelectedEntityid'))
            const country = sessionParsedValue.country_info
            setCountryData(country)
        }
    }, [localStorage.getItem('SelectedEntityid')])
    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') !== "All Legal Entity") {
            let temp = localStorage.getItem("SelectedEntity")
            let tempcountry = _.filter(sessionArr, { le_name: temp })
            setApproverScoreCard({
                ...approverscorecard,
                country: tempcountry && tempcountry.length > 0 ? tempcountry[0].c_id : '',
                country_name: tempcountry && tempcountry.length > 0 ? tempcountry[0].c_name : '',
                legel_entity: localStorage.getItem('SelectedEntity')
            })
        }
    }, [localStorage.getItem('SelectedEntity') !== "All Legal Entity"])


    useEffect(() => {
        let array = [];
        let finalarray = [];
        let datavalue = [];
        let group = [];
        let array1 = []
        let array2 = []

        // setTempeditdata(editdata)
        if (dataList1 && dataList1.length > 0) {

            const ab = dataList1
                .map(function (item) {
                    // if (item.task_group_name !== null) {
                    //   array1.push(item.act_name);
                    // }
                    // else
                    if (array.indexOf(item.level_1_statutory_name) < 0) array.push(item.level_1_statutory_name);
                });
            // array = array1.concat(array2)
            array.map((data, i) => {
                console.log(data, 'data6599999999');
                let temp = [];
                let temp2 = []
                let temp3 = []
                let entityArray = [];
                let datavalue = [];
                let checkarray = []
                if (data) {
                    dataList1
                        .length && dataList1
                            .map((child, childIndex) => {

                                if (data === child.level_1_statutory_name) {

                                    entityArray = {
                                        compliance_task: child.compliance_task,
                                        due_date: child.due_date,
                                        duration_day: child.duration_day,
                                        durations_type: child.durations_type,
                                        frequency: child.frequency,
                                        level_1_statutory_name: child.level_1_statutory_name,
                                        repeat_every: child.repeat_every,
                                        repeats_type: child.repeats_type,
                                        start_date: child.start_date,
                                        unit_name: child.unit_name

                                    }
                                    temp.push(entityArray);
                                }




                            });

                }
                console.log(temp, 'temp2127');
                console.log(temp2, "temptemptemp123")

                datavalue = {
                    index: i,
                    parent: data,
                    child: temp,

                };

                finalarray.push(datavalue);
            });
            Settitledata(finalarray);
        }


    }, [
        dataList1

    ]);


    useEffect(() => {
        if (assigneetabledatas && assigneetabledatas.approver_user_details_list &&
            assigneetabledatas.approver_user_details_list.length > 0) {
            if (dataList1 && dataList1.length > 0) {
                if (showmore == true) {
                    let temp = dataList1
                    // temp.concat(assigneetabledatas.approver_user_details_list)
                    setDataList1(temp.concat(assigneetabledatas.approver_user_details_list))
                    // setDataList([dataList,statutoryUnitList && statutoryUnitList.statutoryListByUnit])
                }
            } else {
                setDataList1(assigneetabledatas.approver_user_details_list)
            }
        }
    }, [assigneetabledatas.approver_user_details_list])


    useEffect(() => {
        if (reportlist.getapproverdata) {
            setDataList({
                ...datalist,
                totaldata: reportlist && reportlist.getapproverdata && reportlist.getapproverdata.user_domains
            })
        }
    }, [reportlist.getapproverdata])
    useEffect(() => {
        if (reportlist.tabledata) {
            setTableData(reportlist && reportlist.tabledata && reportlist.tabledata.approver_score_card_list)
        }
    }, [reportlist.tabledata])


    useEffect(() => {
        if (datalist.totaldata) {
            const domaindata = datalist.totaldata.map((item) => {
                return item.d_name
            })
            const newDomainData = _.uniqBy(datalist.totaldata, function (e) {
                return e.d_name;
            });
            const domain = [...new Set(domaindata)];
            console.log(newDomainData, 'domaindata')

            const userdata = datalist.totaldata.map((item) => {
                return item.u_name
            })
            const user = [...new Set(userdata)];
            const userid = datalist.totaldata.map((item) => {
                return item.u_id
            })
            const userids = [...new Set(userid)];
            const domainid = datalist.totaldata.map((item) => {
                return item.d_id
            })
            const domainids = [...new Set(domainid)];
            let temp = []
            let entityArray = []
            for (let i in user) {
                entityArray = {
                    "d_name": domain[0],
                    "u_name": user[i],
                    "u_id": userids[i],
                    "d_id": domainids[0],
                    "is_active": true,
                    "le_id": entityid
                }
                temp.push(entityArray)
            }
            setApproverData(temp)
            setDomain(newDomainData)
        }
    }, [datalist.totaldata, entityid])
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        setIsModalOpen1(false);
    };
    const showCompletedModal = () => {
        setCompletedModal(true);
    };
    const handleCompletedCancel = () => {
        setCompletedModal(false);
    };
    const columns = [
        {
            title: type === 'Unit_inProgress' || type === 'Unit_overdue' ? 'Vendors' : 'Users',
            ellipsis: true,
            width: '50px',
            align: 'center',
            dataIndex: type === 'Unit_inProgress' || type === 'Unit_overdue' || type === 'Unit_completed' ? 'unit' : 'user_name',
            key: type === 'Unit_inProgress' || type === 'Unit_overdue' || type === 'Unit_completed' ? 'unit' : 'user_name',
        },
        {
            title: type === 'Unit_inProgress' || type === 'Unit_inProgress' ? 'Inprogress' : type === 'Unit_completed' || type === 'User_completed' ? "Completed" : type === 'Unit_overdue' || type === 'User_overdue' ? "Over due" : "",
            ellipsis: true,
            width: '60px',
            align: 'center',
            dataIndex: type === 'Unit_inProgress' || type === 'User_inProgress' ? 'inprogress_count' : type === 'Unit_completed' || type === 'User_completed' ? "complied_count" : type === 'Unit_overdue' || type === 'User_overdue' ? "overdue_count" : "",
            key: type === 'Unit_inProgress' || type === 'User_inProgress' ? 'inprogress_count' : type === 'Unit_completed' || type === 'User_completed' ? "complied_count" : type === 'Unit_overdue' || type === 'User_overdue' ? "overdue_count" : "",
        },

    ];
    useEffect(() => {
        if (tabledata) {
            if (type != '') {
                if (type === 'Unit_inProgress') {
                    if (tabledata && tabledata[0] && tabledata[0].approver_overdue_user_wise && tabledata[0].approver_overdue_user_wise[0] && tabledata[0].approver_overdue_user_wise[0].approver) {
                        let approver = tabledata && tabledata[0].approver_inprogress_unit_wise.filter((item) => {
                            return item.approver === "Approver"
                        })
                        setFilteredArray(approver)
                    }
                    else {
                        setFilteredArray(tabledata && tabledata[0].approver_inprogress_unit_wise)
                    }
                }
                else if (type === 'User_inProgress') {
                    if (tabledata && tabledata[0] && tabledata[0].approver_inprogress_user_wise && tabledata[0].approver_inprogress_user_wise[0] && tabledata[0].approver_inprogress_user_wise[0].approver) {
                        let approver = tabledata && tabledata[0].approver_inprogress_user_wise.filter((item) => {
                            return item.approver === "Approver"
                        })
                        setFilteredArray(approver)
                    }
                    else {
                        setFilteredArray(tabledata && tabledata[0].approver_inprogress_user_wise)
                    }
                }
                else if (type === 'Unit_completed') {
                    if (tabledata && tabledata[0] && tabledata[0].approver_completed_unit_wise && tabledata[0].approver_completed_unit_wise[0] && tabledata[0].approver_completed_unit_wise[0].approver) {
                        let approver = tabledata && tabledata[0].approver_completed_unit_wise.filter((item) => {
                            return item.approver === "Approver"
                        })
                        setFilteredArray(approver)
                    }
                    else {
                        setFilteredArray(tabledata && tabledata[0].approver_completed_unit_wise)
                    }
                }
                else if (type === 'User_completed') {
                    if (tabledata && tabledata[0] && tabledata[0].approver_completed_user_wise && tabledata[0].approver_completed_user_wise[0] && tabledata[0].approver_completed_user_wise[0].approver) {
                        let approver = tabledata && tabledata[0].approver_completed_user_wise.filter((item) => {
                            return item.approver === "Approver"
                        })
                        setFilteredArray(approver)
                    }
                    else {
                        setFilteredArray(tabledata && tabledata[0].approver_completed_user_wise)
                    }
                }
                else if (type === 'Unit_overdue') {
                    if (tabledata && tabledata[0] && tabledata[0].approver_overdue_unit_wise && tabledata[0].approver_overdue_unit_wise[0] && tabledata[0].approver_overdue_unit_wise[0].approver) {
                        let approver = tabledata && tabledata[0].approver_overdue_unit_wise.filter((item) => {
                            return item.approver === "Approver"
                        })
                        setFilteredArray(approver)
                    }
                    else {
                        setFilteredArray(tabledata && tabledata[0].approver_overdue_unit_wise)
                    }

                }
                else if (type === 'User_overdue') {
                    if (tabledata && tabledata[0] && tabledata[0].approver_overdue_user_wise && tabledata[0].approver_overdue_user_wise[0] && tabledata[0].approver_overdue_user_wise[0].approver) {
                        let approver = tabledata && tabledata[0].approver_overdue_user_wise.filter((item) => {
                            return item.approver === "Approver"
                        })
                        setFilteredArray(approver)
                    }
                    else {
                        setFilteredArray(tabledata && tabledata[0].approver_overdue_user_wise)
                    }

                }
            }
        }

    }, [type, tabledata])
    useEffect(() => {
        if (filteredArray.length > 0) {
            setDataSource(filteredArray)
            if (type === 'Unit_inProgress') {
                let temp = []
                for (let i in filteredArray) {
                    temp.push(Number(filteredArray[i].inprogress_count))
                }
                completedInProgressSum = temp.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
            }
            else if (type === 'User_inProgress') {
                let temp = []
                for (let i in filteredArray) {
                    temp.push(Number(filteredArray[i].inprogress_count))
                }
                completedUserInProgress = temp.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
            }
            else if (type === 'Unit_completed') {
                let temp = []
                for (let i in filteredArray) {
                    temp.push(Number(filteredArray[i].complied_count))
                }
                delayedSum = temp.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
            }
            else if (type === 'User_completed') {
                let temp = []
                for (let i in filteredArray) {
                    temp.push(Number(filteredArray[i].complied_count))
                }
                delayedUserSum = temp.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
            }
            else if (type === 'Unit_overdue') {
                let temp = []
                for (let i in filteredArray) {
                    temp.push(Number(filteredArray[i].overdue_count))
                }
                completedOverdueSum = temp.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
            }
            else if (type === 'User_overdue') {
                let temp = []
                for (let i in filteredArray) {
                    temp.push(Number(filteredArray[i].overdue_count))
                }
                completedUserOverdue = temp.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
            }
        }
    }, [filteredArray])
    useEffect(() => {
        if (filteredArray) {
            if (type === 'Unit_inProgress') {
                const newData = {
                    // key: count
                    unit: <b>Total</b>,
                    inprogress_count: completedInProgressSum,
                    total: '0'
                }
                setDataSource([...filteredArray, newData])
                setCount(count + 1)
            }
            else if (type === 'User_inProgress') {
                const newData = {
                    // key: count
                    user_name: <b>Total</b>,
                    inprogress_count: completedUserInProgress,
                    total: '0'
                }
                setDataSource([...filteredArray, newData])
                setCount(count + 1)
            }
            else if (type === 'Unit_completed') {
                const newData = {
                    // key: count
                    unit: <b>Total</b>,
                    complied_count: delayedSum,
                    total: '0'
                }
                setDataSource([...filteredArray, newData])
                setCount(count + 1)
            }
            else if (type === 'User_completed') {
                const newData = {
                    // key: count
                    user_name: <b>Total</b>,
                    complied_count: delayedUserSum,
                    total: '0'
                }
                setDataSource([...filteredArray, newData])
                setCount(count + 1)
            }
            else if (type === 'Unit_overdue') {
                const newData = {
                    // key: count
                    unit: <b>Total</b>,
                    overdue_count: completedOverdueSum,
                    total: '0'
                }
                setDataSource([...filteredArray, newData])
                setCount(count + 1)
            }
            else if (type === 'User_overdue') {
                const newData = {
                    // key: count
                    user_name: <b>Total</b>,
                    overdue_count: completedUserOverdue,
                    total: '0'
                }
                setDataSource([...filteredArray, newData])
                setCount(count + 1)
            }
        }
    }, [filteredArray, type])

    const showModal1 = () => {
        setIsModalOpen1(true);
    };
    const handleCancel1 = () => {
        setCounts(100)
        setIsModalOpen1(false);
    };
    const showCompletedModal1 = () => {
        setCompletedModal(true);
    };
    const handleCompletedCancel1 = () => {
        setCompletedModal(false);
        setCompletedModal1(false)
        setIsModalOpen1(false)
    };
    const columns1 = [
        {
            title: type1 === 'Unit_inProgress' || type1 === 'Unit_overdue' ? 'Vendors' : 'Users',
            ellipsis: true,
            width: '50px',
            align: 'center',
            dataIndex: type1 === 'Unit_inProgress' || type1 === 'Unit_overdue' || type1 === 'Unit_completed' ? 'unit' : 'user_name',
            key: type1 === 'Unit_inProgress' || type1 === 'Unit_overdue' || type1 === 'Unit_completed' ? 'unit' : 'user_name',
        },
        {
            title: type1 === 'Unit_inProgress' || type1 === 'Unit_inProgress' ? 'Inprogress' : type1 === 'Unit_completed' || type1 === 'User_completed' ? "Completed" : type1 === 'Unit_overdue' || type1 === 'User_overdue' ? "Over due" : "",
            ellipsis: true,
            width: '60px',
            align: 'center',
            // dataIndex: type1 === 'Unit_inProgress' || type1 === 'User_inProgress' ? 'inprogress_count' : type1 === 'Unit_completed' || type1 === 'User_completed' ? "complied_count" : type1 === 'Unit_overdue' || type1 === 'User_overdue' ? "overdue_count" : "",
            // key: type1 === 'Unit_inProgress' || type1 === 'User_inProgress' ? 'inprogress_count' : type1 === 'Unit_completed' || type1 === 'User_completed' ? "complied_count" : type1 === 'Unit_overdue' || type1 === 'User_overdue' ? "overdue_count" : "",
            render: (text, row) => {
                console.log(row, 'row5555599999999');
                if (row.total) {
                    return <span>{type1 === 'Unit_inProgress' || type1 === 'User_inProgress' ? <span >{row.inprogress_count}</span> : type1 === 'Unit_completed' || type1 === 'User_completed' ? <span >{row.complied_count}</span> : type1 === 'Unit_overdue' || type1 === 'User_overdue' ? <span>{row.overdue_count}</span> : ""
                    }</span>
                } else {
                    return (
                        header === 'Vendor' ? <span>{type1 === 'Unit_inProgress' || type1 === 'User_inProgress' ? <span>{row.inprogress_count}</span> : type1 === 'Unit_completed' || type1 === 'User_completed' ? <span>{row.complied_count}</span> : type1 === 'Unit_overdue' || type1 === 'User_overdue' ? <span>{row.overdue_count}</span> : ""
                        }</span> :
                            <span onClick={() => {
                                const payload = [
                                    authtoken,
                                    {
                                        "session_token": authtoken,
                                        "request": [
                                            "GetApproverViewUserData",
                                            {
                                                "task_status": type1 === 'Unit_overdue' || type1 === 'User_overdue' ? "Overdue" : type1 === 'Unit_inProgress' || type1 === 'User_inProgress' ? 'Inprogress' : '',
                                                "country_id": Number(approverscorecard.country),
                                                "legal_entity_id": entityid,
                                                "domain_id": Number(approverscorecard.domain_id),
                                                "approver_user_id": Number(approverscorecard.user_id),
                                                "assignee_user_id": row.assignee_user_id,
                                                "from_count": 0
                                            }
                                        ]
                                    }
                                ]
                                console.log(payload, 'payload4555555');

                                getAssigneeWiseData({
                                    payload: payload,
                                    paramid: paramid
                                })
                                setIsModalOpen1(true);
                                setAssigneeId(row.assignee_user_id)
                                setDataList1([])
                                Settitledata([])
                                setUserdetail(row.user_name)
                                setShowMore(false)
                                setShowMoreCount(type1 === 'User_inProgress' ? row.inprogress_count : type1 === 'Unit_completed' || type1 === 'User_completed' ? row.complied_count : type1 === 'Unit_overdue' || type1 === 'User_overdue' ? row.overdue_count : 0)
                            }}>{type1 === 'Unit_inProgress' || type1 === 'User_inProgress' ? <a href='javascript:;'>{row.inprogress_count}</a> : type1 === 'Unit_completed' || type1 === 'User_completed' ? <a href='javascript:;'>{row.complied_count}</a> : type1 === 'Unit_overdue' || type1 === 'User_overdue' ? <a href='javascript:;'>{row.overdue_count}</a> : ""
                                }</span>
                    )
                }
            }
        },

    ];

    useEffect(() => {
        if (tabledata) {
            if (type1 != '') {
                if (type1 === 'Unit_inProgress') {
                    if (tabledata && tabledata[1] && tabledata[1].approver_overdue_user_wise && tabledata[1].approver_overdue_user_wise[0] && tabledata[1].approver_overdue_user_wise[0].approver) {
                        let approver = tabledata && tabledata[1].approver_inprogress_unit_wise.filter((item) => {
                            return item.approver === "Assignee"
                        })
                        setFilteredArray1(approver)
                    }
                    else {
                        setFilteredArray1(tabledata && tabledata[1].approver_inprogress_unit_wise)
                    }
                }
                else if (type1 === 'User_inProgress') {
                    if (tabledata && tabledata[1] && tabledata[1].approver_inprogress_user_wise && tabledata[1].approver_inprogress_user_wise[0] && tabledata[1].approver_inprogress_user_wise[0].approver) {
                        let approver = tabledata && tabledata[1].approver_inprogress_user_wise.filter((item) => {
                            return item.approver === "Assignee"
                        })
                        setFilteredArray1(approver)
                    }
                    else {
                        setFilteredArray1(tabledata && tabledata[1].approver_inprogress_user_wise)
                    }
                }
                else if (type1 === 'Unit_completed') {
                    if (tabledata && tabledata[1] && tabledata[1].approver_completed_unit_wise && tabledata[1].approver_completed_unit_wise[0] && tabledata[1].approver_completed_unit_wise[0].approver) {
                        let approver = tabledata && tabledata[1].approver_completed_unit_wise.filter((item) => {
                            return item.approver === "Assignee"
                        })
                        setFilteredArray1(approver)
                    }
                    else {
                        setFilteredArray1(tabledata && tabledata[1].approver_completed_unit_wise)
                    }
                }
                else if (type1 === 'User_completed') {
                    if (tabledata && tabledata[1] && tabledata[1].approver_completed_user_wise && tabledata[1].approver_completed_user_wise[0] && tabledata[1].approver_completed_user_wise[0].approver) {
                        let approver = tabledata && tabledata[1].approver_completed_user_wise.filter((item) => {
                            return item.approver === "Assignee"
                        })
                        setFilteredArray1(approver)
                    }
                    else {
                        setFilteredArray1(tabledata && tabledata[1].approver_completed_user_wise)
                    }
                }
                else if (type1 === 'Unit_overdue') {
                    if (tabledata && tabledata[1] && tabledata[1].approver_overdue_unit_wise && tabledata[1].approver_overdue_unit_wise[0] && tabledata[1].approver_overdue_unit_wise[0].approver) {
                        let approver = tabledata && tabledata[1].approver_overdue_unit_wise.filter((item) => {
                            return item.approver === "Assignee"
                        })
                        setFilteredArray1(approver)
                    }
                    else {
                        setFilteredArray1(tabledata && tabledata[1].approver_overdue_unit_wise)
                    }

                }
                else if (type1 === 'User_overdue') {
                    if (tabledata && tabledata[1] && tabledata[1].approver_overdue_user_wise && tabledata[1].approver_overdue_user_wise[0] && tabledata[1].approver_overdue_user_wise[0].approver) {
                        let approver = tabledata && tabledata[1].approver_overdue_user_wise.filter((item) => {
                            return item.approver === "Assignee"
                        })
                        setFilteredArray1(approver)
                    }
                    else {
                        setFilteredArray1(tabledata && tabledata[1].approver_overdue_user_wise)
                    }

                }
            }
        }

    }, [type1, tabledata])

    useEffect(() => {
        if (filteredArray1.length > 0) {
            setDataSource1(filteredArray1)
            if (type1 === 'Unit_inProgress') {
                let temp = []
                for (let i in filteredArray1) {
                    temp.push(Number(filteredArray1[i].inprogress_count))
                }
                completedInProgressSum = temp.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
            }
            else if (type1 === 'User_inProgress') {
                let temp = []
                for (let i in filteredArray1) {
                    temp.push(Number(filteredArray1[i].inprogress_count))
                }
                completedUserInProgress = temp.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
            }
            else if (type1 === 'Unit_completed') {
                let temp = []
                for (let i in filteredArray1) {
                    temp.push(Number(filteredArray1[i].complied_count))
                }
                delayedSum = temp.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
            }
            else if (type1 === 'User_completed') {
                let temp = []
                for (let i in filteredArray1) {
                    temp.push(Number(filteredArray1[i].complied_count))
                }
                delayedUserSum = temp.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
            }
            else if (type1 === 'Unit_overdue') {
                let temp = []
                for (let i in filteredArray1) {
                    temp.push(Number(filteredArray1[i].overdue_count))
                }
                completedOverdueSum = temp.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
            }
            else if (type1 === 'User_overdue') {
                let temp = []
                for (let i in filteredArray1) {
                    temp.push(Number(filteredArray1[i].overdue_count))
                }
                completedUserOverdue = temp.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
            }
        }
    }, [filteredArray1])
    useEffect(() => {
        if (filteredArray1) {
            if (type1 === 'Unit_inProgress') {
                const newData = {
                    // key: count
                    unit: <b>Total</b>,
                    inprogress_count: completedInProgressSum,
                    total: '0'
                }
                setDataSource1([...filteredArray1, newData])
                setCount1(count1 + 1)
            }
            else if (type1 === 'User_inProgress') {
                const newData = {
                    // key: count
                    user_name: <b>Total</b>,
                    inprogress_count: completedUserInProgress,
                    total: '0'
                }
                setDataSource1([...filteredArray1, newData])
                setCount1(count1 + 1)
            }
            else if (type1 === 'Unit_completed') {
                const newData = {
                    // key: count
                    unit: <b>Total</b>,
                    complied_count: delayedSum,
                    total: '0'
                }
                setDataSource1([...filteredArray1, newData])
                setCount1(count1 + 1)
            }
            else if (type1 === 'User_completed') {
                const newData = {
                    // key: count
                    user_name: <b>Total</b>,
                    complied_count: delayedUserSum,
                    total: '0'
                }
                setDataSource1([...filteredArray1, newData])
                setCount1(count1 + 1)
            }
            else if (type1 === 'Unit_overdue') {
                const newData = {
                    // key: count
                    unit: <b>Total</b>,
                    overdue_count: completedOverdueSum,
                    total: '0'
                }
                setDataSource1([...filteredArray1, newData])
                setCount1(count1 + 1)
            }
            else if (type1 === 'User_overdue') {
                const newData = {
                    // key: count
                    user_name: <b>Total</b>,
                    overdue_count: completedUserOverdue,
                    total: '0'
                }
                setDataSource1([...filteredArray1, newData])
                setCount1(count1 + 1)
            }
        }
    }, [filteredArray1, type1])


    const homescreen = () => {
        historynew.push(homepage);
        // window.location.href = homepage
        document.getElementById('homehover').style.color = "blue"
    }

    const handlePasting = (e, regex) => {

        var regex = regex;
        var key = e.clipboardData.getData('text')
        if (!regex.test(key)) {
            e.preventDefault();
            Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
            return false;
        }
        // e.clipboardData.getData('text')
        // Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
        // e.preventDefault();
        // return false;
    }


    const [fullscreen, setfullscreen] = useState(false);

    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange',() => handleKeyDown(dispatch), false);
            document.addEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch), false);
            return () => {
                document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch));
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode(dispatch);
            setfullscreen(true);
        } else {
            fullscreenexit(dispatch);
            setfullscreen(false);
        }
    }

    const filterHandle = () => {
        setFilterTaskAcc(true);
        setFilterModalVisible(true);
    };

    useEffect(() => {
        if (approverscorecard.country != '' && approverscorecard.legel_entity != '' && domain && domain.length == 1) {
            setDomainValue(true)
            setApproverScoreCard({
                ...approverscorecard,
                domain_id: domain[0].d_id,
                domain: domain[0].d_name,
            })

        } else {
            setDomainValue(false)
            setDomainFilter(domain)

        }
    }, [domain])

    return (
        <>
            <div id='page-wrapper' className="page-wrapper">
                {tableshow == true ?
                    <div className='back-to-top'>
                        <ScrollButton />
                    </div> : ''}
                <div className="page-titles pb-0 pt-2 page-title-sticky">
                    <div className="row">
                        <div className="col-lg-8 col-md-6 col-12 align-self-center">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 d-flex align-items-center">
                                    <li className="breadcrumb-item">
                                        <span className='pointer' id='homehover' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        <span style={{ fontSize: '14px', fontWeight: "bold" }}>Report</span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: '15px', fontWeight: 'bold' }}>
                                        <span style={{ fontSize: "15px", fontWeight: "bold" }}>Score Cards</span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                        <span>Approver Score Card</span>
                                    </li>
                                </ol>
                            </nav>

                        </div>
                        <div className="col-lg-4 text-end">
                            <Tooltip title="Advance Filter"><Button
                                className="mx-1"
                                type="primary"
                                shape="round"
                                style={{
                                    display: filterTaskAcc ? "none" : "initial",
                                }}
                                onClick={filterHandle}
                            >
                                <FilterOutlined /> Filter
                            </Button></Tooltip>

                            <button style={{ float: 'right' }} onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black ms-2 full-mode fullscreenradius" >
                                <Tooltip placement="left" title={is_Fullscreen == true ? "Exit Full Screen Mode" : "Full Screen Mode"}><FullscreenOutlined /></Tooltip>
                            </button>
                        </div>
                    </div>
                </div>
                <Modal className={'add-service-prv ' + localStorage.getItem('currentTheme')} title={header === 'Vendor' ? 'Vendor Wise Count' : 'Assignee Wise Count'} open={isModalOpen} onCancel={handleCancel} footer={false}>
                    <div className={localStorage.getItem('currentTheme')} maskClosable={false}>
                        <Table
                            className='scorecardtable'
                            columns={columns}
                            dataSource={dataSource}
                            bordered
                            // scroll={{ x: 1500 }}
                            pagination={false} />
                    </div>

                </Modal>
                <Modal className={'add-service-prv ' + localStorage.getItem('currentTheme')} title={header === 'Vendor' ? 'Vendor Wise Count' : 'Assignee Wise Count'} open={isModalOpen1} onCancel={handleCancel} footer={false} maskClosable={false}>
                    <div className={localStorage.getItem('currentTheme')}>
                        <Table
                            className='scorecardtable'
                            columns={columns}
                            dataSource={dataSource}
                            bordered
                            // scroll={{ x: 1500 }}
                            pagination={false} />
                    </div>

                </Modal>

                <Modal className={'add-service-prv ' + localStorage.getItem('currentTheme')} title={header === 'Vendor' ? 'Vendor Wise Count' : 'Assignee Wise Count'} open={completedModal} onCancel={handleCompletedCancel1} footer={false} maskClosable={false}>
                    <div className={localStorage.getItem('currentTheme')}>
                        <Table
                            className='userprivclass'
                            columns={columns1}
                            dataSource={dataSource1}
                            bordered
                            // scroll={{ x: 1500 }}
                            pagination={false} />
                    </div>

                </Modal>
                <Modal title={userdetail} className={'add-service-prv ' + localStorage.getItem('currentTheme')} open={isModalOpen1} onCancel={handleCancel1} footer={false} maskClosable={false}>
                    <Fragment  >
                        <div
                            className={
                                localStorage.getItem("currentTheme") +
                                " ant-table-wrapper"
                            }
                            style={{ overflowY: 'scroll', height: '300px' }}    >
                            <div className="ant-table ant-table-bordered">
                                <div className="ant-table-container">
                                    <div className="ant-table-content  userprivclass1">
                                        <table>
                                            <colgroup>
                                                <col style={{ width: "10px" }}></col>
                                            </colgroup>
                                            {/*  style={{ position: 'sticky', zIndex: '1', top: '154px', background: 'white' }} */}
                                            <thead className="ant-table-thead">
                                                <tr>



                                                    <th
                                                        className={
                                                            fullscreen == false
                                                                ? "ant-table-cell"
                                                                : "fullscreenclass ant-table-cell"
                                                        }
                                                        style={{ width: "10px" }}
                                                    >
                                                        {" "}
                                                        #{" "}
                                                    </th>
                                                    <th
                                                        className={
                                                            fullscreen == false
                                                                ? "ant-table-cell"
                                                                : "fullscreenclass ant-table-cell"
                                                        }
                                                        style={{ width: "300px" }}
                                                    >
                                                        Vendor Name
                                                    </th>
                                                    <th
                                                        className={
                                                            fullscreen == false
                                                                ? "ant-table-cell"
                                                                : "fullscreenclass ant-table-cell"
                                                        }
                                                        style={{ width: "300px" }}
                                                    >
                                                        Compliance Task
                                                    </th>
                                                    <th
                                                        className={
                                                            fullscreen == false
                                                                ? "ant-table-cell"
                                                                : "fullscreenclass ant-table-cell"
                                                        }
                                                        style={{ width: "50px" }}
                                                    >
                                                        Compliance Frequency
                                                    </th>
                                                    <th
                                                        className={
                                                            fullscreen == false
                                                                ? "ant-table-cell"
                                                                : "fullscreenclass ant-table-cell"
                                                        }
                                                        style={{ width: "90px" }}
                                                    >
                                                        Periodicity
                                                    </th>
                                                    <th
                                                        className={
                                                            fullscreen == false
                                                                ? "ant-table-cell"
                                                                : "fullscreenclass ant-table-cell"
                                                        }
                                                        style={{ width: "150px" }}
                                                    >
                                                        <div>
                                                            Start Date
                                                        </div>
                                                    </th>
                                                    <th
                                                        className={
                                                            fullscreen == false
                                                                ? "ant-table-cell"
                                                                : "fullscreenclass ant-table-cell"
                                                        }
                                                        style={{ width: "100px" }}
                                                    >
                                                        Due Date
                                                    </th>

                                                </tr>
                                            </thead>




                                            {titledata &&
                                                titledata.map((itemdata, i) => {
                                                    console.log(itemdata.child2, 'itemdata.child2');

                                                    // let data = _.find(
                                                    //     itemdata.child,
                                                    //     { unit_id: item[0].unit_id }
                                                    // )


                                                    return (
                                                        <Fragment key={i}>
                                                            <tr className="ant-table-row ant-table-row-level-0">
                                                                <td
                                                                    colSpan={12}
                                                                    className="ant-table-cell"
                                                                >
                                                                    <b>
                                                                        {itemdata.parent}
                                                                    </b>
                                                                </td>
                                                            </tr>
                                                            {itemdata.child.length > 0 ?
                                                                itemdata.child.map(
                                                                    (items) => {


                                                                        return (
                                                                            <tr className="ant-table-row ant-table-row-level-0">

                                                                                <td className="ant-table-cell">
                                                                                    <center>
                                                                                        {index + 1}
                                                                                    </center>
                                                                                </td>
                                                                                <td className="ant-table-cell">
                                                                                    {items.unit_name}
                                                                                </td>
                                                                                <td className="ant-table-cell">
                                                                                    <center>
                                                                                        {
                                                                                            items.compliance_task
                                                                                        }
                                                                                    </center>
                                                                                </td>
                                                                                <td className="ant-table-cell">
                                                                                    {
                                                                                        items.frequency
                                                                                    }
                                                                                </td>
                                                                                <td className="ant-table-cell">
                                                                                    <center>
                                                                                        {
                                                                                            `Repeats Every ${items.repeat_every}${items.repeats_type}`
                                                                                        }
                                                                                    </center>
                                                                                </td>
                                                                                <td className="ant-table-cell">
                                                                                    {
                                                                                        items.start_date
                                                                                    }
                                                                                </td>
                                                                                <td className="ant-table-cell">
                                                                                    {
                                                                                        items.due_date
                                                                                    }
                                                                                </td>

                                                                                <span hidden>
                                                                                    {
                                                                                        (index =
                                                                                            index + 1)
                                                                                    }
                                                                                </span>
                                                                            </tr>
                                                                        );
                                                                    }

                                                                ) : ''}

                                                        </Fragment>

                                                    )


                                                })


                                            }

                                        </table>


                                    </div>
                                </div>
                            </div>
                            {showMoreCount == dataList1.length ? '' :
                                <div className='text-center'>
                                    <Button className="btn btn-success" shape='round' onClick={showmore1} >Show More Records</Button>
                                </div>}
                        </div>
                    </Fragment>

                </Modal>
                <div className="container-fluid pt-1">
                    <div className="row">
                        <div className="col-12">
                            <div className="card-body" id='only-restricted' style={{ padding: "10px 3px" }}>
                                <div className={localStorage.getItem('currentTheme')}>

                                    <Collapse className='report-collapse' defaultActiveKey={["1"]} style={{ display: exitCollapse ? "none" : "block" }}>
                                        <Panel header="Approver Score Card" key={key}>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <label ><b>Country : </b> <span style={{ color: "red" }}>*</span></label>
                                                    <div>
                                                        {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                            <Select
                                                                allowClear={true}
                                                                style={{ width: '100%' }}
                                                                placeholder='Enter Country'
                                                                onDrop={(e) => {
                                                                    e.preventDefault();
                                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                    return false;
                                                                }}
                                                                // onPaste={(e) => {
                                                                //     handlePasting(e, /^[a-z]+$/gi)
                                                                // }}
                                                                onPaste={onPasteToaster}
                                                                searchValue={selectSearchValue.country}
                                                                onSearch={(value) => {
                                                                    let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                                                    setSelectSearchValue({
                                                                        ...selectSearchValue,
                                                                        country: result
                                                                    });
                                                                }}
                                                                onChange={(value, data) => {
                                                                    setDomain([])
                                                                    setDomainFilter([])
                                                                    setDomainValue(false)
                                                                    setCurrentEntity('')
                                                                    if (value !== undefined) {
                                                                        let le = _.filter(sessionArr, { c_id: Number(value) })
                                                                        setEntity(le)
                                                                    }
                                                                    setApproverScoreCard({
                                                                        ...approverscorecard,
                                                                        country: value != undefined ? value : '',
                                                                        country_name: data ? data.children : '',
                                                                        legel_entity: "",
                                                                        legel_entity_name: "",
                                                                        domain: "",
                                                                        domain_id: null,
                                                                        user: "",
                                                                        user_id: null
                                                                    })
                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                value={approverscorecard.country || undefined}
                                                            >
                                                                {uniq_country_data && uniq_country_data.length > 0 && uniq_country_data.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.c_id}>
                                                                            {item.c_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                            : <p>{approverscorecard.country_name}</p>}
                                                        {validator.current.message(
                                                            'country',
                                                            approverscorecard.country,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Country Required',
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                                <div className='col-md-4 text-wrap-report'>
                                                    <label><b>Legal Entity: </b> <span style={{ color: "red" }}>*</span></label>
                                                    <div>
                                                        {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?

                                                            <Select
                                                                style={{ width: '100%' }}
                                                                allowClear={true}
                                                                placeholder='Enter Legal Entity'
                                                                onDrop={(e) => {
                                                                    e.preventDefault();
                                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                    return false;
                                                                }}
                                                                // onPaste={(e) => {
                                                                //     handlePasting(e, /^[a-z 0-9 ()]+$/gi)
                                                                // }}
                                                                onPaste={onPasteToaster}
                                                                searchValue={selectSearchValue.legalEntity}
                                                                onSearch={(value) => {
                                                                    let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                                    setSelectSearchValue({
                                                                        ...selectSearchValue,
                                                                        legalEntity: result
                                                                    });
                                                                }}
                                                                onChange={(value, data) => {
                                                                    setDomain([])
                                                                    setDomainFilter([])
                                                                    setDomainValue(false)
                                                                    setCurrentEntity(value != undefined ? value : localStorage.getItem('SelectedEntityid'))
                                                                    setApproverScoreCard({
                                                                        ...approverscorecard,
                                                                        legel_entity: data != undefined ? data.children : '',
                                                                        domain: "",
                                                                        domain_id: null,
                                                                        user: "",
                                                                        user_id: null
                                                                    })
                                                                }}
                                                                value={approverscorecard.legel_entity || undefined}
                                                                disabled={approverscorecard.country == '' ? true : false}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            >
                                                                {entity && entity.length > 0 && entity.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.le_id}>
                                                                            {item.le_name}
                                                                        </Option>
                                                                    );
                                                                })}</Select>
                                                            : <p>{localStorage.getItem('SelectedEntity')}</p>}

                                                        {validator.current.message(
                                                            'legalentity',
                                                            approverscorecard.legel_entity,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Legal Entity Required',
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label ><b>Domain : </b><span style={{ color: "red" }}>*</span></label>
                                                    <div>
                                                        {domainValue == false || domainFilter && domainFilter.length > 1 ?
                                                            <>
                                                                <Select
                                                                    style={{ width: '100%' }}
                                                                    allowClear={true}
                                                                    // onPaste={(e) => {
                                                                    //     handlePasting(e, /^[a-z - .]+$/gi)
                                                                    // }}
                                                                    onPaste={onPasteToaster}
                                                                    searchValue={selectSearchValue.domain}
                                                                    onSearch={(value) => {
                                                                        let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                                                        setSelectSearchValue({
                                                                            ...selectSearchValue,
                                                                            domain: result
                                                                        });
                                                                    }}
                                                                    onDrop={(e) => {
                                                                        e.preventDefault();
                                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                        return false;
                                                                    }}
                                                                    onChange={(value, data) => {
                                                                        setApproverScoreCard({
                                                                            ...approverscorecard,
                                                                            domain_id: value != undefined ? value : '',
                                                                            domain: data != undefined ? data.children : '',
                                                                            user: "",
                                                                            user_id: null
                                                                        })
                                                                    }}
                                                                    placeholder="Enter Domain"
                                                                    showSearch
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                    value={approverscorecard.domain || undefined}
                                                                    disabled={approverscorecard.legel_entity == '' ? true : false}
                                                                >
                                                                    {domainFilter && domainFilter.length > 0 && domainFilter.map((domainList) => {
                                                                        return (<Option key={domainList.d_id}>
                                                                            {domainList.d_name}
                                                                        </Option>
                                                                        )
                                                                    })}


                                                                </Select>
                                                                {validator.current.message(
                                                                    'domain',
                                                                    approverscorecard.domain,
                                                                    'required',
                                                                    {
                                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                        messages: {
                                                                            required: 'Domian Required',
                                                                        }
                                                                    })}
                                                            </>
                                                            : <p className='cut-text-word' style={{ width: '100%', marginTop: "5px" }}>{domain && domain.length > 0 && domain[0].d_name}</p>}
                                                    </div>
                                                </div>
                                                <div className='col-md-4 mt-1'>
                                                    <label ><b>User: </b> <span style={{ color: "red" }}>*</span></label>
                                                    <div>
                                                        <Select
                                                            style={{ width: '100%' }}
                                                            allowClear={true}
                                                            // onPaste={(e) => {
                                                            //     handlePasting(e, /^[a-z 0-9 -]+$/gi)
                                                            // }}
                                                            onPaste={onPasteToaster}
                                                            searchValue={selectSearchValue.user}
                                                            onSearch={(value) => {
                                                                let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                                setSelectSearchValue({
                                                                    ...selectSearchValue,
                                                                    user: result
                                                                });
                                                            }}
                                                            onDrop={(e) => {
                                                                e.preventDefault();
                                                                // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                return false;
                                                            }}
                                                            onChange={(value, data) => {
                                                                setApproverScoreCard({
                                                                    ...approverscorecard,
                                                                    user_id: value != undefined ? value : '',
                                                                    user: data != undefined ? data.children : ''
                                                                })
                                                            }}
                                                            placeholder="Enter User"
                                                            value={approverscorecard.user || undefined}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            disabled={approverscorecard.domain == '' ? true : false}
                                                        >
                                                            {approverdata && approverdata.length > 0 && approverdata.map((item, i) => {
                                                                return (
                                                                    <Option key={item.u_id}>
                                                                        {item.u_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {validator.current.message(
                                                            'user',
                                                            approverscorecard.user,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'User Required',
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'text-center popupbtncolour ' + localStorage.getItem('currentTheme')}>
                                                <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')}
                                                    icon={<EyeOutlined />} onClick={onshow}
                                                >
                                                    Show
                                                </Button>
                                            </div>
                                        </Panel>
                                    </Collapse>

                                    <Modal
                                        onCancel={setcancelFilter}
                                        footer={false}
                                        title="Approver Score Card"
                                        className={
                                            "add-service-prv cons-report " +
                                            localStorage.getItem("currentTheme")
                                        }
                                        open={filterModalVisible}
                                        maskClosable={false}
                                    >
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label ><b>Country : </b> <span style={{ color: "red" }}>*</span></label>
                                                <div>
                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                        <Select
                                                            allowClear={true}
                                                            style={{ width: '100%' }}
                                                            placeholder='Enter Country'
                                                            onDrop={(e) => {
                                                                e.preventDefault();
                                                                // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                return false;
                                                            }}
                                                            // onPaste={(e) => {
                                                            //     handlePasting(e, /^[a-z]+$/gi)
                                                            // }}
                                                            onPaste={onPasteToaster}
                                                            searchValue={selectSearchValue.country}
                                                            onSearch={(value) => {
                                                                let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                                                setSelectSearchValue({
                                                                    ...selectSearchValue,
                                                                    country: result
                                                                });
                                                            }}
                                                            onChange={(value, data) => {
                                                                setDomain([])
                                                                setDomainFilter([])
                                                                setDomainValue(false)
                                                                setCurrentEntity('')
                                                                if (value !== undefined) {
                                                                    let le = _.filter(sessionArr, { c_id: Number(value) })
                                                                    setEntity(le)
                                                                }
                                                                setApproverScoreCard({
                                                                    ...approverscorecard,
                                                                    country: value != undefined ? value : '',
                                                                    country_name: data ? data.children : '',
                                                                    legel_entity: "",
                                                                    legel_entity_name: "",
                                                                    domain: "",
                                                                    domain_id: null,
                                                                    user: "",
                                                                    user_id: null
                                                                })
                                                            }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            value={approverscorecard.country || undefined}
                                                            getPopupContainer={trigger => trigger.parentNode}
                                                        >
                                                            {uniq_country_data && uniq_country_data.length > 0 && uniq_country_data.map((item, i) => {
                                                                return (
                                                                    <Option key={item.c_id}>
                                                                        {item.c_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        : <p>{approverscorecard.country_name}</p>}
                                                    {validator.current.message(
                                                        'country',
                                                        approverscorecard.country,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Country Required',
                                                            }
                                                        })}
                                                </div>
                                            </div>
                                            <div className='col-md-4 text-wrap-report'>
                                                <label><b>Legal Entity: </b> <span style={{ color: "red" }}>*</span></label>
                                                <div>
                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?

                                                        <Select
                                                            style={{ width: '100%' }}
                                                            allowClear={true}
                                                            placeholder='Enter Legal Entity'
                                                            onDrop={(e) => {
                                                                e.preventDefault();
                                                                // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                return false;
                                                            }}
                                                            // onPaste={(e) => {
                                                            //     handlePasting(e, /^[a-z 0-9 ()]+$/gi)
                                                            // }}
                                                            onPaste={onPasteToaster}
                                                            searchValue={selectSearchValue.legalEntity}
                                                            onSearch={(value) => {
                                                                let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                                setSelectSearchValue({
                                                                    ...selectSearchValue,
                                                                    legalEntity: result
                                                                });
                                                            }}
                                                            onChange={(value, data) => {
                                                                setDomain([])
                                                                setDomainFilter([])
                                                                setDomainValue(false)
                                                                setCurrentEntity(value != undefined ? value : localStorage.getItem('SelectedEntityid'))
                                                                setApproverScoreCard({
                                                                    ...approverscorecard,
                                                                    legel_entity: data != undefined ? data.children : '',
                                                                    domain: "",
                                                                    domain_id: null,
                                                                    user: "",
                                                                    user_id: null
                                                                })
                                                            }}
                                                            value={approverscorecard.legel_entity || undefined}
                                                            disabled={approverscorecard.country == '' ? true : false}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            getPopupContainer={trigger => trigger.parentNode}>
                                                            {entity && entity.length > 0 && entity.map((item, i) => {
                                                                return (
                                                                    <Option key={item.le_id}>
                                                                        {item.le_name}
                                                                    </Option>
                                                                );
                                                            })}</Select>
                                                        : <p>{localStorage.getItem('SelectedEntity')}</p>}

                                                    {validator.current.message(
                                                        'legalentity',
                                                        approverscorecard.legel_entity,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Legal Entity Required',
                                                            }
                                                        })}
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <label ><b>Domain : </b><span style={{ color: "red" }}>*</span></label>
                                                <div>
                                                    {domainValue == false || domainFilter && domainFilter.length > 1 ?
                                                        <>
                                                            <Select
                                                                style={{ width: '100%' }}
                                                                allowClear={true}
                                                                // onPaste={(e) => {
                                                                //     handlePasting(e, /^[a-z - .]+$/gi)
                                                                // }}
                                                                onPaste={onPasteToaster}
                                                                searchValue={selectSearchValue.legalEntity}
                                                                onSearch={(value) => {
                                                                    let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                                                    setSelectSearchValue({
                                                                        ...selectSearchValue,
                                                                        legalEntity: result
                                                                    });
                                                                }}
                                                                onDrop={(e) => {
                                                                    e.preventDefault();
                                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                    return false;
                                                                }}
                                                                onChange={(value, data) => {
                                                                    setApproverScoreCard({
                                                                        ...approverscorecard,
                                                                        domain_id: value != undefined ? value : '',
                                                                        domain: data != undefined ? data.children : '',
                                                                        user: "",
                                                                        user_id: null
                                                                    })
                                                                }}
                                                                placeholder="Enter Domain"
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                value={approverscorecard.domain || undefined}
                                                                disabled={approverscorecard.legel_entity == '' ? true : false}
                                                                getPopupContainer={trigger => trigger.parentNode}>

                                                                {domainFilter && domainFilter.length > 0 && domainFilter.map((domainList) => {
                                                                    return (<Option key={domainList.d_id}>
                                                                        {domainList.d_name}
                                                                    </Option>
                                                                    )
                                                                })}


                                                            </Select>
                                                            {validator.current.message(
                                                                'domain',
                                                                approverscorecard.domain,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Domian Required',
                                                                    }
                                                                })}
                                                        </>
                                                        : <p className='cut-text-word' style={{ width: '100%', marginTop: "5px" }}>{domain && domain.length > 0 && domain[0].d_name}</p>}
                                                </div>
                                            </div>
                                            <div className='col-md-4 mt-1'>
                                                <label ><b>User: </b> <span style={{ color: "red" }}>*</span></label>
                                                <div>
                                                    <Select
                                                        style={{ width: '100%' }}
                                                        allowClear={true}
                                                        // onPaste={(e) => {
                                                        //     handlePasting(e, /^[a-z - .]+$/gi)
                                                        // }}
                                                        onPaste={onPasteToaster}
                                                        searchValue={selectSearchValue.user}
                                                        onSearch={(value) => {
                                                            let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                            setSelectSearchValue({
                                                                ...selectSearchValue,
                                                                user: result
                                                            });
                                                        }}
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        onChange={(value, data) => {
                                                            setApproverScoreCard({
                                                                ...approverscorecard,
                                                                user_id: value != undefined ? value : '',
                                                                user: data != undefined ? data.children : ''
                                                            })
                                                        }}
                                                        placeholder="Enter User"
                                                        value={approverscorecard.user || undefined}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        disabled={approverscorecard.domain == '' ? true : false}
                                                        getPopupContainer={trigger => trigger.parentNode}>
                                                        {approverdata && approverdata.length > 0 && approverdata.map((item, i) => {
                                                            return (
                                                                <Option key={item.u_id}>
                                                                    {item.u_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                    {validator.current.message(
                                                        'user',
                                                        approverscorecard.user,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'User Required',
                                                            }
                                                        })}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'text-center mt-1 popupbtncolour ' + localStorage.getItem('currentTheme')}>
                                            <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')}
                                                icon={<EyeOutlined />} onClick={onshow}
                                            >
                                                Show
                                            </Button>
                                        </div>
                                    </Modal>
                                </div>
                                {tableshow == true ?
                                    <Card className='rounded-3'>

                                        {/* <tr className='ant-table-row ant-table-row-level-0'>
                                                <td colSpan={12} className='ant-table-cell'><b style={{ fontSize: "18px", marginLeft: "385px"}}>Approver Details</b></td>
                                            </tr> */}
                                        <div className='col-md-12 mt-1 mb-1 ml-2 filter-detial-sticky' >
                                            <div className='row'>
                                                <div className='col-md-3 text-wrap-report'>
                                                    <label><b>Legal Entity :</b> {localStorage.getItem('SelectedEntity') === 'All Legal Entity' ? approverscorecard.legalEntityLabel : localStorage.getItem('SelectedEntity')}</label>
                                                </div>
                                                <div className='col-md-3 text-center'>
                                                    <label><b>Country :</b> {approverscorecard.countryLabel}</label>
                                                </div>
                                                <div className='col-md-4 text-center' >
                                                    <label><b>Domain :</b> {approverscorecard.domainLabel}</label>
                                                </div>
                                                <div className='col-md-2 text-end'>
                                                    <label><b>User :</b> {approverscorecard.userLabel}</label>
                                                </div>


                                            </div>
                                        </div>
                                        <b>Approver Details</b>
                                        <div className={localStorage.getItem('currentTheme')}>
                                        <div className='ant-table-wrapper domaincardtotal'>

                                                <div className='ant-table ant-table-bordered ant-table-fixed-header'>
                                                    <div className='ant-table-content scorecardtable'>
                                                        <table>
                                                            <thead className="ant-table-thead table-head-stick">
                                                                <tr>
                                                                    <th className='ant-table-cell w10px'>#</th>
                                                                    <th className='ant-table-cell custom-width1'>Task Status</th>
                                                                    <th className='ant-table-cell custom-width1'>Count</th>
                                                                    <th className='ant-table-cell custom-width1'><center>Vendor Wise</center></th>
                                                                    <th className='ant-table-cell custom-width1'>Assigne Wise</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className='ant-table-tbody'>
                                                                <tr>
                                                                    <td className='ant-table-cell'><center>1</center></td>
                                                                    <td className='ant-table-cell' ><center>Inprogress</center></td>
                                                                    <td className='ant-table-cell'><center>{tabledata && tabledata[0].inprogress_count}</center></td>
                                                                    <td className='ant-table-cell'><center><Button shape='round' type="primary" disabled={tabledata && tabledata[0].inprogress_count == 0 ? true : false}
                                                                        onClick={() => {
                                                                            setType('Unit_inProgress')
                                                                            setHeader('Vendor')
                                                                            showModal()
                                                                        }}
                                                                    >View</Button></center></td>
                                                                    <td className='ant-table-cell'><center><Button shape='round' type="primary" disabled={tabledata && tabledata[0].inprogress_count == 0 ? true : false}
                                                                        onClick={() => {
                                                                            setType('User_inProgress')
                                                                            setHeader('Assignee')
                                                                            showModal()
                                                                        }}>View</Button></center></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='ant-table-cell'><center>2</center></td>
                                                                    <td className='ant-table-cell'><center>Over due</center></td>
                                                                    <td className='ant-table-cell'><center>{tabledata && tabledata[0].overdue_count}</center></td>
                                                                    <td className='ant-table-cell'><center><Button shape='round' type="primary" disabled={tabledata && tabledata[0].overdue_count == 0 ? true : false}
                                                                        onClick={() => {
                                                                            setType('Unit_overdue')
                                                                            setHeader('Vendor')
                                                                            showCompletedModal()
                                                                        }}
                                                                    >View</Button></center></td>
                                                                    <td className='ant-table-cell'><center><Button shape='round' type="primary" disabled={tabledata && tabledata[0].overdue_count == 0 ? true : false}
                                                                        onClick={() => {
                                                                            setType('User_overdue')
                                                                            setHeader('Assignee')
                                                                            showCompletedModal()
                                                                        }}
                                                                    >View</Button></center></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='ant-table-cell'><center>3</center></td>
                                                                    <td className='ant-table-cell'><center>Completed</center></td>
                                                                    <td className='ant-table-cell'><center>{tabledata && tabledata[0].completed_count}</center></td>
                                                                    <td className='ant-table-cell'><center><Button shape='round' type="primary" disabled={tabledata && tabledata[0].completed_count == 0 ? true : false}
                                                                        onClick={() => {
                                                                            setType('Unit_completed')
                                                                            setHeader('Vendor')
                                                                            showModal()
                                                                        }}
                                                                    >View</Button></center></td>
                                                                    <td className='ant-table-cell'><center><Button shape='round' type="primary" disabled={tabledata && tabledata[0].completed_count == 0 ? true : false}
                                                                        onClick={() => {
                                                                            setType('User_completed')
                                                                            setHeader('Assignee')
                                                                            showModal()
                                                                        }}>View</Button></center></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='ant-table-cell'><center></center></td>
                                                                    <td className='ant-table-cell'><center><b>Total</b></center></td>
                                                                    <td className='ant-table-cell'><center>{(tabledata && tabledata[0].inprogress_count) + (tabledata && tabledata[0].overdue_count) + (tabledata && tabledata[0].completed_count)}</center></td>
                                                                    <td className='ant-table-cell'><center></center></td>
                                                                    <td className='ant-table-cell'><center></center></td>
                                                                </tr>
                                                            </tbody>

                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <Divider />
                                        {/* <tr className='ant-table-row ant-table-row-level-0'>
                                                <td colSpan={12} className='ant-table-cell'><b style={{ fontSize: "18px", marginLeft: "385px" }}>Assignee Details</b></td>
                                            </tr> */}
                                        <b style={{ marginTop: '3%', marginBottom: '3%' }}>Assignee Details</b>
                                        <div className={localStorage.getItem('currentTheme')}>
                                        <div className='ant-table-wrapper domaincardtotal'>

                                                <div className='ant-table ant-table-bordered ant-table-fixed-header'>
                                                    <div className='ant-table-content scorecardtable'>
                                                        <table className='table-fixed' >
                                                            <thead className='ant-table-thead'>
                                                                <tr>
                                                                    <th className='ant-table-cell w10px'>#</th>
                                                                    <th title='Compliance Task' className='ant-table-cell custom-width1'>Task Status</th>
                                                                    <th className='ant-table-cell custom-width1'>Count</th>
                                                                    <th className='ant-table-cell custom-width1'><center>Vendor Wise</center></th>
                                                                    <th className='ant-table-cell custom-width1'>Assigne Wise</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className='ant-table-tbody'>
                                                                <tr>
                                                                    <td className='ant-table-cell'><center>1</center></td>
                                                                    <td className='ant-table-cell'><center>Inprogress</center></td>
                                                                    <td className='ant-table-cell'><center>{tabledata && tabledata[1].inprogress_count}</center></td>
                                                                    <td className='ant-table-cell'><center><Button shape='round' type="primary" disabled={tabledata && tabledata[1].inprogress_count == 0 ? true : false}
                                                                        onClick={() => {
                                                                            setType1('Unit_inProgress')
                                                                            setHeader('Vendor')
                                                                            showCompletedModal1()
                                                                        }}
                                                                    >View</Button></center></td>
                                                                    <td className='ant-table-cell'><center><Button shape='round' type="primary" disabled={tabledata && tabledata[1].inprogress_count == 0 ? true : false}
                                                                        onClick={() => {
                                                                            setType1('User_inProgress')
                                                                            setHeader('Assignee')
                                                                            showCompletedModal1()
                                                                        }}>View</Button></center></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='ant-table-cell'><center>2</center></td>
                                                                    <td className='ant-table-cell'><center>Over due</center></td>
                                                                    <td className='ant-table-cell'><center>{tabledata && tabledata[1].overdue_count}</center></td>
                                                                    <td className='ant-table-cell'><center><Button shape='round' type="primary" disabled={tabledata && tabledata[1].overdue_count == 0 ? true : false}
                                                                        onClick={() => {
                                                                            setType1('Unit_overdue')
                                                                            setHeader('Vendor')
                                                                            showCompletedModal1()
                                                                        }}
                                                                    >View</Button></center></td>
                                                                    <td className='ant-table-cell'><center><Button shape='round' type="primary" disabled={tabledata && tabledata[1].overdue_count == 0 ? true : false}
                                                                        onClick={() => {
                                                                            setType1('User_overdue')
                                                                            setHeader('Assignee')
                                                                            showCompletedModal1()
                                                                        }}
                                                                    >View</Button></center></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='ant-table-cell'><center></center></td>
                                                                    <td className='ant-table-cell'><center><b>Total</b></center></td>
                                                                    <td className='ant-table-cell'><center><p>{(tabledata && tabledata[1].inprogress_count) + (tabledata && tabledata[1].overdue_count) + (tabledata && tabledata[1].completed_count)}</p></center></td>
                                                                    <td className='ant-table-cell'><center></center></td>
                                                                    <td className='ant-table-cell'><center></center></td>
                                                                </tr>
                                                            </tbody>

                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                    : false}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default connect(mapStateToProps, {
    getdata,
    Approvertablereport,
    getAssigneeWiseData
})(ApproverScoreCard);