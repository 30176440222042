import { Fragment, useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { AudioOutlined, PlusOutlined, CheckOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2';
import { Modal, Input, Table, Button, Radio, Space, Tooltip, Tabs, Select, Card, Tag, Popover, Checkbox, Row, Col } from 'antd';
import { getUsermanagementList, updatestatus, replaceExistingUser, activeStatus, disableStatusChange, getById, replaceNewUser, LegalAdmindisableStatusChange, haveCompliance, resendEmail, getUsermanagementDetails } from './../../Store/Action/Master/UserManagement'
import {
    InfoCircleOutlined, LikeOutlined, DislikeOutlined, UpCircleOutlined, UpOutlined, ArrowLeftOutlined,
    StopOutlined, SearchOutlined, PlayCircleOutlined, CloseCircleOutlined, RetweetOutlined, PlusCircleOutlined, CloseOutlined, FullscreenOutlined, MailOutlined, EyeOutlined, FilterOutlined, EditOutlined
} from '@ant-design/icons';
import moment from 'moment';
import SimpleReactValidator from 'simple-react-validator';
import { Toaster } from '../../Libs/Toaster'
import { GetRemarksData } from './../../Store/Action/Transcations/Taskrecall'
import AddUserManagement from './AddUserManagement';
import TextArea from 'antd/lib/input/TextArea';
import ScrollToTop from "react-scroll-to-top";
import FeatherIcon from 'feather-icons-react';
import { fullscreenmode, fullscreenexit, handleKeyDown } from "./../../Libs/fullscreenmode";
import ScrollButton from '../Scrollbar';
import Usermanagement from './Usermanagement';
import { HomeRedirect } from './../../Libs/country'
const homepage = HomeRedirect()


const _ = require("lodash");
const { Option, OptGroup } = Select;
const mapStateToProps = (state) => ({
    usermanagementList: state.UserManagement.userManagement,
    filterrecall: state.recallfilters.recallfilters
})
const ReplaceUser = ({
    getUsermanagementList,
    replaceExistingUser,
    resendEmail,
    getUsermanagementDetails,
    GetRemarksData,
    updatestatus,
    getById,
    activeStatus,
    addChange,
    replaceNewUser,
    setExistingUser,
    existingUser,
    setAddChange,
    setExistingUserData,
    existingUserData,
    userfullscreen1,
    disableStatusChange,
    LegalAdmindisableStatusChange,
    haveCompliance,
    filterrecall: { recallremarks },
    usermanagementList: { userManagementList, UserLegalDetails, userManagementDetails, GetUserDetails }
}) => {
    console.log(existingUserData, 'existingUserData');
    console.log(GetUserDetails, 'GetUserDetails');
    console.log(userManagementDetails, 'userManagementDetails');
    const location = useLocation();
    const validator = useRef(new SimpleReactValidator());
    const [bgTooltip, setBgToolTip] = useState([])
    const [singleProduct, setsingleProduct] = useState([])
    console.log(singleProduct, 'singleProduct');
    const [vendorbusinessGroupValue, setVendorbusinessGroupValue] = useState([])
    const [EditCompfieVendor, setEditCompfieVendor] = useState({
        category: [],
        seatingUnit: [],
        legalEnity: [],
        userGroup: [],
        domain: []
    })
    const paramid = location.pathname;
    const historynew = useHistory();
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const { TabPane } = Tabs;
    const [payloadEntityEdit, setpayloadEntityEdit] = useState([])
    const [businessGroupValue, setbusinessGroupValue] = useState([])
    const [productCount, setProductCount] = useState([])
    const formValidator = useRef(new SimpleReactValidator());
    const formValidator1 = useRef(new SimpleReactValidator());
    const [payloadVendorEntityEdit, setpayloadVendorEntityEdit] = useState([])
    const [unitAccess, setunitAccess] = useState(false);
    const [vendorAccess, setVendorAccess] = useState(false);
    const entityFormvalidator = useRef(new SimpleReactValidator());
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [payloadLitigationEntityEdit, setpayloadLitigationEntityEdit] = useState([])
    const [lititigationbusinessGroupValue, setlititigationbusinessGroupValue] = useState([])
    const [AddFormSubmit1, setAddFormSubmit1] = useState(false);
    const [userCategoryId, setUserCategoryId] = useState()
    const [categoryValues, setCategoryValues] = useState([])
    const [payloadEditunit, setPayloadEditunit] = useState([])
    const [existingTableData, setExistingTableData] = useState([])
    const [existingId, setExistingId] = useState(0)
    const [litigationEntityValue, setLitigationEntityValue] = useState([])
    console.log(litigationEntityValue, 'litigationEntityValue');
    const [vendoreEntityValue, setVendoreEntityValue] = useState([])
    console.log(existingTableData, 'existingTableData');
    console.log(vendoreEntityValue, 'vendoreEntityValue');
    const [vendorBgTooltip, setVendorBgToolTip] = useState([])
    const [existingTableRecord, setExistingTableRecord] = useState([])
    console.log(existingTableRecord, 'existingTableRecord');
    const [litigationBgTooltip, setLitigationBgTooltip] = useState([])
    const [vendorcatTooltip, setVedorCatTooltip] = useState([])
    const [vendorEntityTooltip, setVendorEntityToolTip] = useState([])
    const [litigationEntityTooltip, setLitigationEntityTooltip] = useState([])
    const [domainTooltip, setDomainToolTip] = useState([])
    const [vendorDomainTooltip, setVendorDomainToolTip] = useState([])
    const [litigationDomainTooltip, setLitigationDomainTooltip] = useState([])
    const [divisionTooltip, setDivisionToolTip] = useState([])
    const [vendordivisionTooltip, setVendorDivisionToolTip] = useState([])
    const [catTooltip, setCatTooltip] = useState([])
    const [payloadEditDomain, setPayloaEditdDomain] = useState([])
    const [payloadEditlitigationDomain, setpayloadEditlitigationDomain] = useState([])
    console.log(payloadEditlitigationDomain, 'payloadEditlitigationDomain');
    const [payloadEditVendorDomain, setPayloaEditVendordDomain] = useState([])
    const [vendorClosedAll, setVendorClosedAll] = useState([])
    const [domainValue, setdomainValue] = useState([])
    console.log(domainValue, 'domainValuedomainValuedomainValuedomainValue');
    const [litigationdomainValue, setlitigationdomainValue] = useState([])
    const [vendordomainValue, setvendordomainValues] = useState([])
    const [vendorDivisionValue, setvendorDivisionValue] = useState([])
    console.log(vendorDivisionValue, 'vendorDivisionValue');
    const [DivisionValue, setDivisionValue] = useState([])
    console.log(DivisionValue, 'DivisionValue');
    const [VendorcategoryValues, setVendorCategoryValues] = useState([])
    const [closedAll, setclosedAll] = useState([]);
    const [details, setDetails] = useState({
        le_id: '',
        user_id: ''
    })
    const [tabValue, setTabValue] = useState({
        compfie: false,
        compfie_litigation: false,
        compfie_vendor: false
    })
    console.log(tabValue, 'tabValue');
    const [replaceproduct, setReplaceProduct] = useState({
        compfie: false,
        compie_litigation: false,
        compfie_vendor: false
    })
    console.log(replaceproduct, 'replaceproduct');
    const [replaceUserValue, setReplaceUserValue] = useState({
        compfie: "",
        compfie_litigation: '',
        compfie_vendor: ''
    })
    console.log(replaceproduct, 'replaceproduct');
    const [compfieVendorValue, setCompfieVendorValue] = useState({
        category: null,
        seating_Unit: null,
        user_Group: null,
        user_level: '1',
        entity: '',
        serviceProvider: null,
        checked_value: '',
        legal_Entity: [],
        Domain: [],
        Division: '',

        // domain:'',

    })
    console.log(compfieVendorValue, 'compfieVendorValue');
    const [newUser, setNewUser] = useState(false)
    const [compfieLitigation, setCompfieLitigation] = useState({
        category: null,
        user: '',
        legal_Entity: [],
        Domain: [],
        businessGroup: '',
        serviceProvider: ''
    })
    console.log(compfieLitigation, 'compfieLitigation');
    const [userType, setUserType] = useState("")
    const [businessData, setBusinessData] = useState([])
    const [userCategoryData, setUserCategoryData] = useState([])
    const [usergroupData, setUserGroupData] = useState([])
    const [vendorusergroupData, setvendorUserGroupData] = useState([])
    const [serviceProviderData, setServiceProviderData] = useState([])
    const [vendoruserCategoryData, setVendorUserCategoryData] = useState([])
    const [vendorserviceProviderData, setVendorServiceProviderData] = useState([])
    const [litionuserCategoryData, setLitigationUserCategoryData] = useState([])
    const [entityData, setEntityData] = useState([])
    const [domaindata, setDomainData] = useState([])
    const [divisionData, setDivisionData] = useState([])
    const [categoryData, setCategoryData] = useState([])
    const [litigationbusinessData, setLitigationBusinessData] = useState([])
    const [litigationentityData, setLitigationEntityData] = useState([])
    console.log(litigationentityData, 'litigationentityData');
    const [litigationdomaindata, setLitigationDomainData] = useState([])
    const [vendorbusinessData, setVendorBusinessData] = useState([])
    const [vendorentityData, setVendorEntityData] = useState([])
    const [vendordomaindata, setVendorDomainData] = useState([])
    const [vendordivisionData, setVendorDivisionData] = useState([])
    const [vendorcategoryData, setVendorCategoryData] = useState([])
    const [scroll, setScroll] = useState(false)
    const [fullscreen, setfullscreen] = useState(false);
    const [usermanagementDetails, setuserMAnagementDeails] = useState([])
    const [EntitySubmit, setEntityFormSubmit] = useState(false);
    const [remarks, setRemarks] = useState('')
    const [filterarray, setFilterArray] = useState([])
    const [usermagementListData, setUsermanagementListData] = useState([])
    console.log(filterarray, 'filterarray');
    const selectedEntity = localStorage.getItem('SelectedEntity')
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    // const entityid = localStorage.getItem('SelectedEntityid');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [disableType, setDisableType] = useState("")
    const [remarksModal, setRemarksModal] = useState(false);
    const currentdate = new Date();
    const [date, setDate] = useState(moment(currentdate).format('DD-MMM-YYYY'))
    const [show, setShow] = useState(false)
    const [filterModal, setFilterModal] = useState(false)
    console.log(filterModal, "filterModal");
    const [userDetails, setUserDetails] = useState({
        emp_name: '',
        emp_contact_country_code: '',
        emp_contact_code: '',
        emp_contact_no: '',
        emp_id: '',
        mobile_no_country_code: '',
        mobile_no: '',
        email: '',
        remarks: '',
        emp_code: '',
        otp: 0

    })
    const [category, setCategory] = useState({
        compfie: '',
        compie_litigation: '',
        compfie_vendor: ''
    })

    const [compfie, setCompfie] = useState({
        category: null,
        seating_Unit: null,
        user_Group: '',
        user_level: '1',
        entity: '',
        serviceProvider: '',
        checked_value: '',
        legal_Entity: [],
        Domain: [],
        domain_name: "",
        Division: '',
        businessGroup: ''

        // domain:'',

    })
    console.log(compfie, 'compfie1284655');
    const [showList, setShowList] = useState(false)
    const [replace, setReplace] = useState(false)
    const [filterText, setFilterText] = useState("");
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true,
        pageSizeOptions: ['10', '30', '50']
    })
    const [resetPaginationToggle, setResetPaginationToggle] = useState(
        false
    );
    const [tab, setTab] = useState()
    const [user, setUser] = useState(false);
    const [entityValue, setEntityValue] = useState([])
    const [usermangementData, setUserMagementData] = useState([])
    // const [EntityValue, setEntityValue] = useState()
    const [main, setmain] = useState({
        country: '',
        entity: '',
        business: ''
    })
    const [categoryValue, setCategoryValue] = useState({
        compfie: '',
        vendor: ''
    })
    console.log(categoryValue, 'categoryValue');
    const [type, setType] = useState("");
    const [AddType, setAddType] = useState("list")
    const [finalResult, SetfinalResult] = useState({
        Resultdata: []
    })
    const [legalEntity, setLegalEntity] = useState()

    const [finallegalEntity, setFinallegalentity] = useState()

    const sessionValue = JSON.parse(localStorage.getItem('sessionValue'))
    const entity_info = sessionValue.entity_info
    const [countryData, setCountryData] = useState([])
    const country_info = sessionValue.country_info
    const [passwordata, Setpassword] = useState({
        passvalue: '',
        sp_id: '',
        user_id: '',
        product_id: '',
        le_id: ''
    })
    const [modalData, setModalData] = useState({
        password: '',
        reason: '',
        user_id: ''

    })
    const [active, setActive] = useState({
        u_id: "",
        emp_name: "",
    })
    const [modalVisible, setModaVisible] = useState(false)
    const [business, setBusiness] = useState(false)
    const [legal, setLegal] = useState(false)
    const [Entity, setEntity] = useState({})
    const [payloadEditClosedunit, setPayloadClosedEditunit] = useState([])
    const [filteredArray, setFilteredArray] = useState([])
    console.log(filteredArray, 'filteredArray');
    const [allUnitData, setAllUnitData] = useState([])
    const [allvendorData, setAllVendorData] = useState([])
    const [filteredArrayState, setFilteredArrayState] = useState([])
    const [page, setPage] = useState(1);
    const [entityTooltip, setEntityToolTip] = useState([])
    const [remarksvalue, Setremarksvalue] = useState();
    const RemarksColumn = [
        {
            title: '#',
            dataIndex: false,
            width: '18px',
            align: 'center',
            render: (text, record, index) => {
                return (page - 1) * 10 + index + 1
            },

        },
        {
            title: 'Description',
            dataIndex: 'r_description',
            key: 'r_description',
            width: '100px',
            ellipsis: true,
            render: (text, record) => <a onClick={() => {
                setRemarks(text)
                setRemarksModal(false)
            }}>{record.r_description}</a>,

        },
    ];
    useEffect(() => {
        validator.current.showMessages()

    }, [])
    const [baseData, setBaseData] = useState([]);
    useEffect(() => {
        if (userType == 'existing') {
            console.log(usermagementListData, 'userManagementList');
            let temp = []
            let temp2 = []
            if (existingUserData && existingUserData.u_cat_id !== null) {
                for (let i in usermagementListData) {
                    if (
                        ((usermagementListData[i].u_cat_id == existingUserData.u_cat_id) || (usermagementListData[i].u_cat_id == null))
                        &&
                        (usermagementListData[i].is_active == true)
                        && (usermagementListData[i].is_disable == 0)
                        // && 
                        // (usermagementListData[i].unblock_days !== 0)

                        // ((moment(usermagementListData[i] && usermagementListData[i].cont_to).isSameOrAfter(date)) ||
                        //     (usermagementListData[i] && usermagementListData[i].cont_to == null))

                    ) {
                        temp.push(usermagementListData[i])
                    }
                }
            }
            // columns={((moment(data && data.v_cont_to).isSameOrAfter(date)) && (moment(data && data.cont_to).isSameOrAfter(date))) ? columns : (moment(data && data.cont_to).isSameOrAfter(date)) ? columns2 : moment(date).isBefore(data && data.v_cont_to) ? columns3 : false}
            if (existingUserData && existingUserData.u_type_id !== null) {
                for (let i in usermagementListData) {
                    if (
                        ((usermagementListData[i].u_type_id == existingUserData.u_type_id) || (usermagementListData[i].u_type_id == null))
                        && (usermagementListData[i].is_active == true)
                        && (usermagementListData[i].is_disable == 0)

                        // &&
                        // ((moment(usermagementListData[i] && usermagementListData[i].l_cont_to).isSameOrAfter(date)) || ((usermagementListData[i] && usermagementListData[i].l_cont_to == null)))
                    ) {
                        temp.push(usermagementListData[i])
                    }
                }


            }
            if (existingUserData && existingUserData.vndr_user_category !== null) {
                for (let i in usermagementListData) {
                    if (((usermagementListData[i].vndr_user_category == existingUserData.vndr_user_category) || (usermagementListData[i].vndr_user_category == null)) && (usermagementListData[i].is_active == true) &&
                        (usermagementListData[i].is_disable == 0)
                        //  && 
                        // (moment(usermagementListData[i] && usermagementListData[i].v_cont_to).isSameOrAfter(date)) || ((usermagementListData[i] && usermagementListData[i].v_cont_to == null))
                    ) {
                        temp.push(usermagementListData[i])
                    }
                }


            }
            console.log(temp, 'temp');
            if (temp.length > 0) {
                for (let i in temp) {
                    if (temp[i].user_id !== existingUserData.user_id) {
                        if (temp[i].user_name !== null) {
                            temp2.push(temp[i])
                        }
                    }
                }
            }
            console.log(temp2, 'temp2');
            if (temp2 && temp2.length > 0) {
                let uniqueObjArray = [...new Map(temp2 && temp2.length > 0 && temp2.map((item) => [item["user_id"], item])).values()];

                console.log(uniqueObjArray, 'temp585548');
                setExistingTableData(uniqueObjArray)
            }
            else {
                setExistingTableData([])
            }
        }
    }, [userType])
    useEffect(() => {
        if (existingTableData && existingTableData.length > 0) {
            console.log('snehaaaaaaaaaaaa');
            //     // let filterDatas = _.filter(existingTableData, (replaceproduct.compfie === true ? { u_cat_id: null } : ''), (replaceproduct.compie_litigation === true ? { u_type_id: null } : ''), (replaceproduct.compfie_vendor === true ? { u_type_id: null } : ''))
            //     let filterData = _.filter(existingTableData, (replaceproduct.compfie === true ? { u_cat_id: (replaceUserValue.compfie,null)} : ''), (replaceproduct.compie_litigation === true ? { u_type_id: replaceUserValue.compfie_litigation } : ''), (replaceproduct.compfie_vendor === true ? { u_type_id: replaceUserValue.compfie_vendor } : ''))
            // //   let temp=filterDatas.concat(filterData)
            //     console.log(filterData, 'filterData');
            let temp2 = []
            let temp = []

            if (replaceproduct.compfie == true) {

                if (temp.length > 0) {
                    temp2 = []
                    for (let i in temp) {

                        if ((replaceUserValue.compfie == temp[i].u_cat_id) || (temp[i].u_cat_id == null)) {

                            temp2.push(temp[i])
                        }
                    }
                    temp = temp2
                }
                else {
                    for (let i in existingTableData) {
                        if (existingTableData.length > 0) {

                            if ((replaceUserValue.compfie == existingTableData[i].u_cat_id) || (existingTableData[i].u_cat_id == null)) {
                                temp.push(existingTableData[i])
                            }
                        }
                    }
                }

            }
            if (replaceproduct.compie_litigation == true) {
                if (temp.length > 0) {
                    temp2 = []
                    console.log(temp, 'temp');
                    for (let i in temp) {
                        console.log((replaceUserValue.compfie_litigation), '(replaceUserValue.compfie_litigation)');
                        console.log(temp[i].u_type_id, '(replaceUserValue.compfie_litigation == temp[i].u_type_id) || (temp[i].u_type_id == null)');

                        if (((replaceUserValue.compfie_litigation == temp[i].u_type_id) || (temp[i].u_type_id == null))) {
                            temp2.push(temp[i])
                        }
                    }
                    temp = temp2


                }
                else {
                    for (let i in existingTableData) {
                        if (existingTableData.length > 0) {
                            if (replaceproduct.compie_litigation == true) {
                                if ((replaceUserValue.compfie_litigation == existingTableData[i].u_type_id) || (existingTableData[i].u_type_id == null)) {
                                    temp.push(existingTableData[i])
                                }
                            }
                        }
                    }
                }
            }
            if (replaceproduct.compfie_vendor == true) {
                if (temp.length > 0) {
                    temp2 = []
                    for (let i in temp) {
                        if ((replaceUserValue.compfie_vendor == temp[i].vndr_user_category) || (temp[i].vndr_user_category == null)) {
                            temp2.push(temp[i])
                        }
                    }
                    temp = temp2

                }
                else {
                    for (let i in existingTableData) {
                        if (existingTableData.length > 0) {
                            if (replaceproduct.compfie_vendor == true) {
                                if ((replaceUserValue.compfie_vendor == existingTableData[i].vndr_user_category) || (existingTableData[i].vndr_user_category == null)) {
                                    temp.push(existingTableData[i])
                                }
                            }
                        }
                    }
                }
            }
            setExistingTableRecord(temp2.length > 0 ? temp2 : temp)
            console.log(temp, 'temp555');
            console.log(temp2, 'temp');
        }
    }, [existingTableData, replaceproduct.compfie, replaceproduct.compfie_vendor, replaceproduct.compie_litigation, replaceUserValue])
    useEffect(() => {
        let temp = []
        if (entityValue.length > 0) {
            for (let i in entityValue) {
                let data = _.filter(entityData, { le_id: entityValue[i] })
                console.log(data, 'data9999999');
                if (data && data.length > 0) {
                    temp.push(data[0].le_name)
                }

            }
            if (temp.length > 0) {
                setEntityToolTip(temp.join(','))
            }

        }
    }, [entityValue, entityData])
    useEffect(() => {
        let temp = []

        if (GetUserDetails && GetUserDetails.ul_user_units && GetUserDetails.ul_user_units.length > 0) {
            for (let i in GetUserDetails && GetUserDetails.ul_user_units) {
                temp.push({
                    le_id: GetUserDetails && GetUserDetails.ul_user_units[i].le_id,
                    u_id: GetUserDetails && GetUserDetails.ul_user_units[i].u_unt_id
                })
            }

            setAllUnitData(temp)
        }
        if (GetUserDetails && GetUserDetails.vend_unitlist && GetUserDetails.vend_unitlist.length > 0) {
            // if (category.compfie_vendor == '7') {
            for (let i in GetUserDetails && GetUserDetails.vend_unitlist) {
                temp.push({
                    le_id: GetUserDetails && GetUserDetails.vend_unitlist[i].le_id,
                    u_id: GetUserDetails && GetUserDetails.vend_unitlist[i].u_unt_id
                })
            }
            // }

            setAllVendorData(temp)
        }






    }, [userManagementDetails && userManagementDetails.um_legal_units, category.compfie_vendor, compfie.category, GetUserDetails && GetUserDetails.vend_unitlist
    ])
    useEffect(() => {
        let temp = []


        if (GetUserDetails && GetUserDetails.ul_user_units && GetUserDetails.ul_user_units.length > 0) {
            for (let i in GetUserDetails && GetUserDetails.ul_user_units) {
                temp.push({
                    le_id: GetUserDetails && GetUserDetails.ul_user_units[i].le_id,
                    u_id: GetUserDetails && GetUserDetails.ul_user_units[i].u_unt_id
                })
            }

            setAllUnitData(temp)
        }
        if (GetUserDetails && GetUserDetails.vend_unitlist && GetUserDetails.vend_unitlist.length > 0) {
            // if (category.compfie_vendor == '7') {
            for (let i in GetUserDetails && GetUserDetails.vend_unitlist) {
                temp.push({
                    le_id: GetUserDetails && GetUserDetails.vend_unitlist[i].le_id,
                    u_id: GetUserDetails && GetUserDetails.vend_unitlist[i].u_unt_id
                })
            }
            // }

            setAllVendorData(temp)
        }

    }, [userManagementDetails && userManagementDetails.um_legal_units, category.compfie_vendor, compfie.category, GetUserDetails && GetUserDetails.vend_unitlist
    ])
    console.log(allUnitData, 'allUnitData');
    useEffect(() => {
        if (compfie.category == '2' || compfie.category == '7' || compfie.category == "View Only" || compfie.category == "View OnlyResticted") {
            if (allUnitData && allUnitData.length > 0) {
                if (((allUnitData.length) == (userManagementDetails && userManagementDetails.um_legal_units.length))) {
                    setunitAccess(true)
                }

            }
            if (GetUserDetails && GetUserDetails.ul_user_units && GetUserDetails.ul_user_units.length == 0) {
                setunitAccess(true)
            }
        }
    }, [allUnitData, GetUserDetails, compfie.category])
    useEffect(() => {
        if (compfieVendorValue.category || compfieVendorValue.category == '7' || compfieVendorValue.category == '2' || compfieVendorValue.category == "View Only" || compfieVendorValue.category == "View OnlyResticted") {
            if (allvendorData && allvendorData.length > 0) {
                if (((allvendorData.length) == (userManagementDetails && userManagementDetails.um_vndr_legal_units.length))) {
                    setVendorAccess(true)
                }

            }
            if (GetUserDetails && GetUserDetails.vend_unitlist && GetUserDetails.vend_unitlist.length == 0) {
                setVendorAccess(true)
            }
        }
    }, [allvendorData, GetUserDetails])
    const userDetailsOnSubmit = () => {
        setAddFormSubmit(true);
        if (validator.current.allValid()) {


            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    // "request": [
                    //     "SaveClientUser",
                    //     {
                    //         "u_cat_id": compfie.category != null ? Number(compfie.category) : null,
                    //         "l_cat_type_id": compfieLitigation.category != null ? Number(compfieLitigation.category) : null,
                    //         "vend_u_cat_id": compfieVendorValue.category != null ? Number(compfieVendorValue.category) : null,
                    //         "u_g_id": compfie.user_Group != null ? Number(compfie.user_Group) : null,
                    //         "vend_u_g_id": compfieVendorValue.user_Group != null ? Number(compfieVendorValue.user_Group) : null,
                    //         "email_id": userDetails.email,
                    //         "emp_name": userDetails.emp_name,
                    //         "emp_code": userDetails.emp_id,
                    //         "cont_no": "--",
                    //         "mob_no": `${userDetails.mobile_no_country_code}-${userDetails.mobile_no}`,
                    //         "u_level": ((compfie.user_level != "null") || (compfie.user_level != null)) ? Number(compfie.user_level) : null,
                    //         "vend_u_level": ((compfieVendorValue.user_level != null) && (tabValue.compfie_vendor == true)) ? Number(compfieVendorValue.user_level) : null,
                    //         // "s_unit": null,
                    //         // "vend_s_unit": compfieVendorValue.seating_Unit != null ? Number(compfieVendorValue.seating_Unit) : null,
                    //         "is_sp": compfie.serviceProvider != '' ? true : false,
                    //         "sp_id": compfie.serviceProvider != '' ? Number(compfie.serviceProvider) : null,
                    //         "user_entity_ids": ((tabValue.compfie == true) && (entityValue.length > 0)) ? entityValue : [],
                    //         "l_user_entity_ids": ((tabValue.compfie_litigation == true) && (litigationEntityValue.length > 0)) ? litigationEntityValue : [],
                    //         "vend_user_entity_ids": ((tabValue.compfie_vendor == true) && (vendoreEntityValue && vendoreEntityValue.length > 0)) ? vendoreEntityValue : [],
                    //         "user_domain_ids": compfie.Domain,
                    //         "l_user_domain_ids": compfieLitigation.Domain,
                    //         "vend_user_domain_ids": compfieVendorValue.Domain,
                    //         "user_unit_ids": unitAccess == true ? userUnits : userUnits.concat(userClosedUnits),
                    //         "vndr_user_unit_ids": vendorUnitDetails.concat(vendorClosedUnitDetails),
                    //         "checked_value": "0",
                    //         "otp_remarks": "OTP feature has been switched off",
                    //         "cmpf_prdt_access": tabValue.compfie == true ? 1 : 0,
                    //         "ltmg_prdt_access": tabValue.compfie_litigation == true ? 1 : 0,
                    //         "vndr_prdt_access": tabValue.compfie_vendor == true ? 1 : 0
                    //     }
                    // ]

                    "request": [
                        "ReplaceToNewUser",
                        {
                            "u_id": existingUserData && existingUserData.user_id,
                            "u_cat_id": compfie.category != null ? Number(compfie.category) : null,
                            "l_cat_type_id": compfieLitigation.category != null ? Number(compfieLitigation.category) : null,
                            "vend_u_cat_id": compfieVendorValue.category != null ? Number(compfieVendorValue.category) : null,
                            "u_g_id": compfie.user_Group != null ? Number(compfie.user_Group) : null,
                            "vend_u_g_id": compfieVendorValue.user_Group != null ? Number(compfieVendorValue.user_Group) : null,
                            "email_id": userDetails.email,
                            "emp_name": userDetails.emp_name,
                            "emp_code": userDetails.emp_id,
                            "cont_no": "--",
                            "mob_no": `${userDetails.mobile_no_country_code}-${userDetails.mobile_no}`,
                            "u_level": ((compfie.user_level != "null") || (compfie.user_level != null)) ? Number(compfie.user_level) : null,
                            "vend_u_level": ((compfieVendorValue.user_level != null) && (tabValue.compfie_vendor == true)) ? Number(compfieVendorValue.user_level) : null,
                            "is_sp": compfie.serviceProvider == null ? false : true,
                            "sp_id": compfie.serviceProvider === (categoryValue && categoryValue.serviceProvider.length && categoryValue.serviceProvider[0].u_sp_name) ? Number(categoryValue && categoryValue.serviceProvider.length && categoryValue.serviceProvider[0].u_sp_id) : compfie.serviceProvider == null ? null : Number(compfie.serviceProvider),
                            "user_entity_ids": payloadEntityEdit.length > 0 ? payloadEntityEdit : [],
                            "l_user_entity_ids": payloadLitigationEntityEdit.length > 0 ? payloadLitigationEntityEdit : [],
                            "vend_user_entity_ids": payloadVendorEntityEdit.length > 0 ? payloadVendorEntityEdit : [],
                            "user_domain_ids": payloadEditDomain.length > 0 ? payloadEditDomain : [],
                            "l_user_domain_ids": payloadEditlitigationDomain.length > 0 ? payloadEditlitigationDomain : [],
                            "vend_user_domain_ids": payloadEditVendorDomain.length > 0 ? payloadEditVendorDomain : [],
                            "user_unit_ids": payloadEditunit.length > 0 ? payloadEditunit : [],

                            "vndr_user_unit_ids": payloadEditClosedunit.length > 0 ? payloadEditClosedunit : [],
                            "checked_value": "0",
                            "otp_remarks": "OTP feature has been switched off",
                            "cmpf_prdt_access": tabValue.compfie == true && replaceproduct.compfie == true ? 1 : 0,
                            "ltmg_prdt_access": tabValue.compfie_litigation == true && replaceproduct.compfie_litigation == true ? 1 : 0,
                            "vndr_prdt_access": tabValue.compfie_vendor == true && replaceproduct.compfie_vendor == true ? 1 : 0,
                            "cmpf_replace": tabValue.compfie == true && replaceproduct.compfie == true ? true : false,
                            "ltmg_replace": tabValue.compfie_litigation == true && replaceproduct.compfie_litigation == true ? true : false,
                            "vndr_replace": tabValue.compfie_vendor == true && replaceproduct.compfie_vendor == true ? true : false,
                            "rpl_from_emp_name": "",
                            "rpl_from_emp_code": ""
                        }
                    ]

                }
            ]
            console.log(payload, 'payload2127');
            replaceNewUser({
                payload: payload,
                paramid: paramid
            })



        }


        // setCategoryFormSubmit(true)
        // console.log('aishuuuu');
        // if (AddType == 'add') {


        //     if (validator.current.allValid()) {
        //         if (Formvalidator.current.allValid()) {

        //             const payload = [
        //                 authtoken,
        //                 {
        //                     "session_token": authtoken,
        //                     "request": [
        //                         "SaveClientUser",
        //                         {
        //                             "u_cat_id": compfie.category != null ? Number(compfie.category) : null,
        //                             "l_cat_type_id": compfieLitigation.category != null ? Number(compfieLitigation.category) : null,
        //                             "vend_u_cat_id": compfieVendorValue.category != null ? Number(compfieVendorValue.category) : null,
        //                             "u_g_id": compfie.user_Group != null ? Number(compfie.user_Group) : null,
        //                             "vend_u_g_id": compfieVendorValue.user_Group != null ? Number(compfieVendorValue.user_Group) : null,
        //                             "email_id": userDetails.email,
        //                             "emp_name": userDetails.emp_name,
        //                             "emp_code": userDetails.emp_id,
        //                             "cont_no": "--",
        //                             "mob_no": `${userDetails.mobile_no_country_code}-${userDetails.mobile_no}`,
        //                             "u_level": ((compfie.user_level != "null") || (compfie.user_level != null)) ? Number(compfie.user_level) : null,
        //                             "vend_u_level": ((compfieVendorValue.user_level != null) && (tabValue.compfie_vendor == true)) ? Number(compfieVendorValue.user_level) : null,
        //                             // "s_unit": null,
        //                             // "vend_s_unit": compfieVendorValue.seating_Unit != null ? Number(compfieVendorValue.seating_Unit) : null,
        //                             "is_sp": compfie.serviceProvider != '' ? true : false,
        //                             "sp_id": compfie.serviceProvider != '' ? Number(compfie.serviceProvider) : null,
        //                             "user_entity_ids": ((tabValue.compfie == true) && (entityValue.length > 0)) ? entityValue : [],
        //                             "l_user_entity_ids": ((tabValue.compfie_litigation == true) && (litigationEntityValue.length > 0)) ? litigationEntityValue : [],
        //                             "vend_user_entity_ids": ((tabValue.compfie_vendor == true) && (vendoreEntityValue && vendoreEntityValue.length > 0)) ? vendoreEntityValue : [],
        //                             "user_domain_ids": compfie.Domain,
        //                             "l_user_domain_ids": compfieLitigation.Domain,
        //                             "vend_user_domain_ids": compfieVendorValue.Domain,
        //                             "user_unit_ids": unitAccess == true ? userUnits : userUnits.concat(userClosedUnits),
        //                             "vndr_user_unit_ids": vendorUnitDetails.concat(vendorClosedUnitDetails),
        //                             "checked_value": "0",
        //                             "otp_remarks": "OTP feature has been switched off",
        //                             "cmpf_prdt_access": tabValue.compfie == true ? 1 : 0,
        //                             "ltmg_prdt_access": tabValue.compfie_litigation == true ? 1 : 0,
        //                             "vndr_prdt_access": tabValue.compfie_vendor == true ? 1 : 0
        //                         }
        //                     ]


        //                 }
        //             ]
        //             addUserManagementDetails({
        //                 payload: payload,
        //                 paramid: paramid
        //             })
        //         }
        //         else {
        //             Toaster.warning("Please  Enter All Values")
        //         }
        //     }

        //     else {
        //         Toaster.warning("Please  Enter All Values")
        //     }

        // }

        // if (AddType == 'edit') {

        //     if (validator.current.allValid()) {
        //         if (Formvalidator.current.allValid()) {
        //             const payloadEdit = [
        //                 authtoken,
        //                 {
        //                     "session_token": authtoken,

        //                     "request": [
        //                         "UpdateClientUser",
        //                         {
        //                             "u_id": Number(GetUserDetails && GetUserDetails.ul_userDetails[0].user_id),
        //                             "u_cat_id": compfie && compfie.category == (categoryValue && categoryValue.category.length && categoryValue.category[0].u_c_name) ? Number(categoryValue && categoryValue.category[0].u_c_id) : compfie.category == null ? null : Number(compfie.category),
        //                             "l_cat_type_id": compfieLitigation.category != null ? Number(compfieLitigation.category) : null,
        //                             "vend_u_cat_id": compfieVendorValue.category === (EditCompfieVendor && EditCompfieVendor.category.length && EditCompfieVendor.category[0].u_c_name) ? Number(EditCompfieVendor && EditCompfieVendor.category.length && EditCompfieVendor.category[0].u_c_id) : compfieVendorValue.category == null ? null : Number(compfieVendorValue.category),
        //                             "u_g_id": compfie && compfie.user_Group === (categoryValue && categoryValue.userGroup.length > 0 && categoryValue.userGroup[0].u_g_name) ? Number(categoryValue && categoryValue.userGroup && categoryValue.userGroup.length && categoryValue.userGroup[0].u_g_id) : compfie && compfie.user_Group == null ? null : Number(compfie && compfie.user_Group),
        //                             "vend_u_g_id": compfieVendorValue.user_Group === (EditCompfieVendor && EditCompfieVendor.userGroup.length && EditCompfieVendor.userGroup[0].u_g_name) ? Number(EditCompfieVendor && EditCompfieVendor.userGroup[0].u_g_id) : compfieVendorValue.user_Group == null ? null : Number(compfieVendorValue.user_Group),
        //                             "email_id": userDetails.email,
        //                             "emp_name": userDetails.emp_name,
        //                             "emp_code": userDetails.emp_id,
        //                             "cont_no": "--",
        //                             "mob_no": `${userDetails.mobile_no_country_code}-${userDetails.mobile_no}`,
        //                             "u_level": ((compfie.user_level != "null") || (compfie.user_level != null)) ? Number(compfie.user_level) : null,
        //                             "vend_u_level": ((compfieVendorValue.user_level != null) && (tabValue.compfie_vendor == true)) ? Number(compfieVendorValue.user_level) : null,
        //                             "is_sp": compfie.serviceProvider == null ? false : true,
        //                             "sp_id": compfie.serviceProvider === (categoryValue && categoryValue.serviceProvider.length && categoryValue.serviceProvider[0].u_sp_name) ? Number(categoryValue && categoryValue.serviceProvider.length && categoryValue.serviceProvider[0].u_sp_id) : compfie.serviceProvider == null ? null : Number(compfie.serviceProvider),

        //                             "l_user_entity_ids":
        //                                 localStorage.getItem("SelectedEntity") !== 'All Legal Entity' ? payloadLitigationEntityEdit : ((tabValue.compfie_litigation == true) && (litigationEntityValue.length > 0)) ? litigationEntityValue : [],
        //                             "vend_user_entity_ids":
        //                                 localStorage.getItem("SelectedEntity") !== 'All Legal Entity' ? payloadVendorEntityEdit :
        //                                     ((tabValue.compfie_vendor == true) && (vendoreEntityValue && vendoreEntityValue.length > 0)) ? vendoreEntityValue : [],
        //                             "l_user_domain_ids":
        //                                 localStorage.getItem("SelectedEntity") !== 'All Legal Entity' ? payloadEditlitigationDomain :
        //                                     ((tabValue.compfie_litigation == true) && (compfieLitigation.Domain.length)) ? compfieLitigation.Domain : [],
        //                             "vend_user_domain_ids":
        //                                 localStorage.getItem("SelectedEntity") !== 'All Legal Entity' ? payloadEditVendorDomain :
        //                                     ((tabValue.compfie_vendor == true) && (compfieVendorValue.Domain.length > 0)) ? compfieVendorValue.Domain : [],
        //                             "user_domain_ids":
        //                                 localStorage.getItem("SelectedEntity") !== 'All Legal Entity' ? payloadEditDomain :
        //                                     ((tabValue.compfie == true) && (compfie.Domain.length)) ? compfie.Domain : [],
        //                             "user_unit_ids": localStorage.getItem("SelectedEntity") !== 'All Legal Entity' ? payloadEditunit : unitAccess == true ? userUnits : userUnits.concat(userClosedUnits),
        //                             "vndr_user_unit_ids": localStorage.getItem("SelectedEntity") !== 'All Legal Entity' ? payloadEditClosedunit : vendorAccess == true ? vendorUnitDetails : vendorUnitDetails.concat(vendorClosedUnitDetails),
        //                             "user_entity_ids": localStorage.getItem("SelectedEntity") !== 'All Legal Entity' ? payloadEntityEdit : ((tabValue.compfie == true) && (entityValue.length > 0)) ? entityValue : [],

        //                             "c_user": compfie.category != null ? true : false,
        //                             "v_user": compfieVendorValue.category != null ? true : false,
        //                             "l_user": compfieLitigation.category != null ? true : false,
        //                             "checked_value": "0",
        //                             "otp_remarks": "OTP feature has been switched off",
        //                             "cmpf_prdt_access": tabValue.compfie == true ? 1 : 0,
        //                             "ltmg_prdt_access": compfieLitigation.category != null == true ? 1 : 0,
        //                             "vndr_prdt_access": compfieVendorValue.category != null ? 1 : 0,
        //                         }
        //                     ]

        //                 }
        //             ]
        //             console.log(payloadEdit, 'payloadEdit');
        //             console.log(compfieVendorValue, 'compfieVendorValue');
        //             edituserManagementDetails({
        //                 payload: payloadEdit,
        //                 paramid: paramid
        //             })

        //         }
        //         else {
        //             Toaster.warning("Please  Enter All Values")
        //         }
        //     }

        //     else {
        //         Toaster.warning("Please  Enter All Values")
        //     }

        // }

    }
    const ReplaceExistingUser = () => {
        if (existingId !== 0) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "ReplaceToLiveUser",
                        {
                            "usr_from": existingUserData && existingUserData.user_id,
                            "rpl_user_to": existingId,
                            "cmpf_replace": replaceproduct.compfie == true ? true : false,
                            "ltmg_replace": replaceproduct.compie_litigation == true ? true : false,
                            "vndr_replace": replaceproduct.compfie_vendor == true ? true : false,
                            "all_pdt_replace": ((replaceproduct.compfie == true) && (replaceproduct.compie_litigation == true) && (replaceproduct.compfie_vendor == true)) ? true : singleProduct.length == 1 ? true : false
                        }
                    ]

                }
            ]
            console.log(payload, 'payload2127');
            replaceExistingUser({
                payload: payload,
                paramid: paramid
            })
        }
        else {
            Toaster.error("Please select the user")
        }
    }

    useEffect(() => {
        let temp = []
        if (businessGroupValue.length > 0) {
            for (let i in businessGroupValue) {
                let data = _.filter(businessData, { bg_id: Number(businessGroupValue[i]) })
                console.log(data, 'data9999999');
                if (data && data.length > 0) {
                    temp.push(data[0].bg_name)
                }

            }
            if (temp.length > 0) {
                setBgToolTip(temp.join(','))
            }

        }
    }, [businessGroupValue, businessData])
    useEffect(() => {
        let temp = []
        if (lititigationbusinessGroupValue.length > 0) {
            for (let i in lititigationbusinessGroupValue) {
                let data = _.filter(litigationbusinessData, { bg_id: Number(lititigationbusinessGroupValue[i]) })
                console.log(data, 'data9999999');
                if (data && data.length > 0) {
                    temp.push(data[0].bg_name)
                }

            }
            if (temp.length > 0) {
                setLitigationBgTooltip(temp.join(','))
            }

        }
    }, [lititigationbusinessGroupValue, litigationbusinessData])
    useEffect(() => {
        let temp = []
        if (vendorbusinessGroupValue.length > 0) {
            for (let i in vendorbusinessGroupValue) {
                let data = _.filter(vendorbusinessData, { bg_id: Number(vendorbusinessGroupValue[i]) })
                console.log(data, 'data9999999');
                if (data && data.length > 0) {
                    temp.push(data[0].bg_name)
                }

            }
            if (temp.length > 0) {
                setVendorBgToolTip(temp.join(','))
            }

        }
    }, [vendorbusinessGroupValue, vendorbusinessData])
    useEffect(() => {
        let temp = []
        if (litigationEntityValue.length > 0) {
            for (let i in litigationEntityValue) {
                let data = _.filter(litigationentityData, { le_id: litigationEntityValue[i] })
                console.log(data, 'data9999999');
                if (data && data.length > 0) {
                    temp.push(data[0].le_name)
                }

            }
            if (temp.length > 0) {
                setLitigationEntityTooltip(temp.join(','))
            }

        }
    }, [litigationEntityValue, litigationentityData])
    useEffect(() => {
        let temp = []
        if (vendoreEntityValue.length > 0) {
            for (let i in vendoreEntityValue) {
                let data = _.filter(vendorentityData, { le_id: vendoreEntityValue[i] })
                console.log(data, 'data9999999');
                if (data && data.length > 0) {
                    temp.push(data[0].le_name)
                }

            }
            if (temp.length > 0) {
                setVendorEntityToolTip(temp.join(','))
            }

        }
    }, [vendoreEntityValue, vendorentityData])
    useEffect(() => {
        let temp = []
        if (domainValue.length > 0) {
            for (let i in domainValue) {
                let data = _.filter(domaindata, { u_dm_id: Number(domainValue[i]) })
                console.log(data, 'data9999999');
                if (data && data.length > 0) {
                    temp.push(data[0].u_dm_name)
                }

            }
            if (temp.length > 0) {
                setDomainToolTip(temp.join(','))
            }

        }
    }, [domainValue, domaindata])
    useEffect(() => {
        let temp = []
        if (litigationdomainValue.length > 0) {
            for (let i in litigationdomainValue) {
                let data = _.filter(litigationdomaindata, { u_dm_id: Number(litigationdomainValue[i]) })
                console.log(data, 'data9999999');
                if (data && data.length > 0) {
                    temp.push(data[0].u_dm_name)
                }

            }
            if (temp.length > 0) {
                setLitigationDomainTooltip(temp.join(','))
            }

        }
    }, [litigationdomainValue, litigationdomaindata])
    useEffect(() => {
        let temp = []
        if (vendordomainValue.length > 0) {
            for (let i in vendordomainValue) {
                let data = _.filter(vendordomaindata, { u_dm_id: Number(vendordomainValue[i]) })
                console.log(data, 'data9999999');
                if (data && data.length > 0) {
                    temp.push(data[0].u_dm_name)
                }

            }
            if (temp.length > 0) {
                setVendorDomainToolTip(temp.join(','))
            }

        }
    }, [vendordomainValue, vendordomaindata])
    useEffect(() => {
        let temp = []
        if (DivisionValue.length > 0) {
            for (let i in DivisionValue) {
                let data = _.filter(divisionData, { d_id: Number(DivisionValue[i]) })
                console.log(data, 'data9999999');
                if (data && data.length > 0) {
                    temp.push(data[0].d_name)
                }

            }
            if (temp.length > 0) {
                setDivisionToolTip(temp.join(','))
            }

        }
    }, [DivisionValue, divisionData])
    useEffect(() => {
        let temp = []
        if (vendorDivisionValue.length > 0) {
            for (let i in vendorDivisionValue) {
                let data = _.filter(vendordivisionData, { d_id: Number(vendorDivisionValue[i]) })
                console.log(data, 'data9999999');
                if (data && data.length > 0) {
                    temp.push(data[0].d_name)
                }

            }
            if (temp.length > 0) {
                setVendorDivisionToolTip(temp.join(','))
            }

        }
    }, [vendorDivisionValue, vendordivisionData])
    useEffect(() => {
        let temp = []
        if (categoryValues.length > 0) {
            for (let i in categoryValues) {
                let data = _.filter(categoryData, { cat_id: Number(categoryValues[i]) })
                console.log(data, 'data9999999');
                if (data && data.length > 0) {
                    temp.push(data[0].cat_name)
                }

            }
            if (temp.length > 0) {
                setCatTooltip(temp.join(','))
            }

        }
    }, [categoryValues, categoryData])
    useEffect(() => {
        let temp = []
        if (VendorcategoryValues.length > 0) {
            for (let i in VendorcategoryValues) {
                let data = _.filter(vendorcategoryData, { cat_id: Number(VendorcategoryValues[i]) })
                console.log(data, 'data9999999');
                if (data && data.length > 0) {
                    temp.push(data[0].cat_name)
                }

            }
            if (temp.length > 0) {
                setVedorCatTooltip(temp.join(','))
            }

        }
    }, [VendorcategoryValues, vendorcategoryData])


    // const contents = (items) => {
    //     return <table style={{ width: "80px", height: '100px' }} class="table table-striped" >
    //         <tbody>
    //             <tc>
    //                 <th>Concurrer</th>
    //                 <td><b>{items.concurrence_person}</b></td>
    //             </tc>
    //             <tc>
    //                 <th>Approver</th>
    //                 <td><b>{items.approval_person}</b></td>
    //             </tc>
    //         </tbody>
    //     </table>
    // };
    useEffect(() => {
        if (GetUserDetails && GetUserDetails.ul_user_units.length) {

            let temparr = []
            let temparr2 = []
            let closedTemp = []
            let ClosedTemparr = []
            let vendor = []
            let vendorClosed = []
            let categoryTemp = []
            let categoryTemparr = []
            let divisionarr = []
            let divisionArr = []
            let tem = []
            let tem2 = []



            if (GetUserDetails && GetUserDetails.ul_user_units.length > 0) {
                for (let i in GetUserDetails && GetUserDetails.ul_user_units) {
                    tem.push({
                        le_id: GetUserDetails && GetUserDetails.ul_user_units[i].le_id,
                        u_id: GetUserDetails && GetUserDetails.ul_user_units[i].u_unt_id
                    })
                }
            }
            setPayloadEditunit(tem)
            if (GetUserDetails && GetUserDetails.vend_unitlist.length > 0) {
                let temp = []
                for (let i in GetUserDetails && GetUserDetails.vend_unitlist) {
                    temp.push({
                        le_id: GetUserDetails && GetUserDetails.vend_unitlist[i].le_id,
                        u_id: GetUserDetails && GetUserDetails.vend_unitlist[i].u_unt_id
                    })
                }
                setPayloadClosedEditunit(temp)
            }






        }
    }, [GetUserDetails && GetUserDetails.ul_user_units, GetUserDetails && GetUserDetails.vend_unitlist])
    useEffect(() => {
        if (recallremarks) {
            let temp = [];
            for (let i = 0; i < recallremarks.length; i++) {
                if (recallremarks[i].r_description != '') {
                    temp.push(recallremarks[i])
                }
                Setremarksvalue(temp)
            }
        }

    }, [recallremarks])
    useEffect(() => {
        if (entity_info && entity_info.length > 0) {
            const unique = _.uniqBy(entity_info, function (e) {

                return e.c_id;

            });
            setCountryData(unique)

        }
    }, [])

    useEffect(() => {
        if (existingUserData) {
            let temp = []
            setReplaceProduct({
                ...replaceproduct,
                compfie: ((existingUserData && existingUserData.u_cat_id !== null) && (existingUserData.is_disable == 0) && (moment(existingUserData.cont_to).isSameOrAfter(date) || (existingUserData.cont_to == null)) && (existingUserData.u_type_id == null) && (existingUserData.vndr_user_category == null)) ? true : false,
                compie_litigation: ((existingUserData && existingUserData.u_type_id !== null) && (existingUserData.is_disable == 0) && (moment(existingUserData.l_cont_to).isSameOrAfter(date) || (existingUserData.l_cont_to == null)) && (existingUserData.u_cat_id == null) && (existingUserData.vndr_user_category == null)) ? true : false,
                compfie_vendor: ((existingUserData && existingUserData.vndr_user_category !== null) && (existingUserData.is_disable == 0) && (moment(existingUserData.v_cont_to).isSameOrAfter(date) || (existingUserData.v_cont_to == null)) && (existingUserData.u_type_id == null) && (existingUserData.u_cat_id == null)) ? true : false
            })
            setReplaceUserValue({
                ...replaceUserValue,
                compfie: ((existingUserData && existingUserData.u_cat_id !== null) && (existingUserData.is_disable == 0) && (moment(existingUserData.cont_to).isSameOrAfter(date) || (existingUserData.cont_to == null)) && (existingUserData.u_type_id == null) && (existingUserData.vndr_user_category == null)) ? existingUserData.u_cat_id : '',
                compfie_litigation: ((existingUserData && existingUserData.u_type_id !== null) && (existingUserData.is_disable == 0) && (moment(existingUserData.l_cont_to).isSameOrAfter(date) || (existingUserData.l_cont_to == null)) && (existingUserData.u_cat_id == null) && (existingUserData.vndr_user_category == null)) ? existingUserData.u_type_id : '',
                compfie_vendor: ((existingUserData && existingUserData.vndr_user_category !== null) && (existingUserData.is_disable == 0) && (moment(existingUserData.v_cont_to).isSameOrAfter(date) || (existingUserData.v_cont_to == null)) && (existingUserData.u_type_id == null) && (existingUserData.u_cat_id == null)) ? existingUserData.vndr_user_category : ''
            })
            if ((existingUserData && existingUserData.u_cat_id !== null) && (existingUserData.is_disable == 0) && (moment(existingUserData.cont_to).isSameOrAfter(date) || (existingUserData.cont_to == null)) && (existingUserData.u_type_id == null) && (existingUserData.vndr_user_category == null)) {
                temp.push(true)
            }
            if (((existingUserData && existingUserData.u_type_id !== null) && (existingUserData.is_disable == 0) && (moment(existingUserData.l_cont_to).isSameOrAfter(date) || (existingUserData.l_cont_to == null)) && (existingUserData.u_cat_id == null) && (existingUserData.vndr_user_category == null))) {
                temp.push(true)
            }
            if (((existingUserData && existingUserData.vndr_user_category !== null) && (existingUserData.is_disable == 0) && (moment(existingUserData.v_cont_to).isSameOrAfter(date) || (existingUserData.v_cont_to == null)) && (existingUserData.u_type_id == null) && (existingUserData.u_cat_id == null))) {
                temp.push(true)
            }
            console.log(temp, 'temp');
            setsingleProduct(temp)
        }
    }, [existingUserData])



    console.log(countryData, 'countryData');
    useEffect(() => {
        if (userManagementDetails) {
            setUserCategoryData(userManagementDetails.um_user_category)
            setUserGroupData(userManagementDetails.um_user_group)
            setServiceProviderData(userManagementDetails.um_service_providers)
            setEntityData(userManagementDetails.um_legal_entity)
            setDomainData(userManagementDetails.um_legal_domain)
            setBusinessData(userManagementDetails.um_business_group)
            setDivisionData(userManagementDetails.um_cmpf_group_division)
            setCategoryData(userManagementDetails.um_group_category)
            setLitigationDomainData(userManagementDetails.ltmg_um_legal_domain)
            setVendorCategoryData(userManagementDetails.um_vndr_user_category)
            setVendorServiceProviderData(userManagementDetails.um_service_providers)
            setLitigationEntityData(userManagementDetails.um_ltmg_legal_entity)
            setLitigationBusinessData(userManagementDetails.um_ltmg_business_group)
            setVendorEntityData(userManagementDetails.um_vndr_legal_entity)
            setVendorBusinessData(userManagementDetails.um_vndr_business_group)
            setVendorDomainData(userManagementDetails.vndr_um_legal_domain)
            setVendorDivisionData(userManagementDetails.um_vndr_group_division)
            setVendorCategoryData(userManagementDetails.um_group_category)
            setvendorUserGroupData(userManagementDetails.um_user_group)

        }
    }, [userManagementDetails])

    const modalCancel = () => {
        setFilterModal(false)
    }


    // const scrollup = () => {
    //     window.scrollTo(0, 0)
    // }

    // useEffect(() => {
    //     window.addEventListener('scroll', handleScroll);
    // }, []);

    // const handleScroll = () => {
    //     if (window.scrollY >= 213) {
    //         setScroll(true)
    //     }
    //     else {
    //         setScroll(false)
    //     }
    // }


    const showModal = () => {
        setIsModalVisible(true);
    };
    const businessmodel = () => {
        setBusiness(true)
    }
    const legalmodel = () => {
        setLegal(true)
    }
    useEffect(() => {
        formValidator.current.showMessages()
        formValidator1.current.showMessages()
        entityFormvalidator.current.showMessages()
    }, [])
    const OnSubmited = () => {
        let datas = []
        let activeData = []
        setAddFormSubmit(true)
        if (userCategoryId && userCategoryId.u_cat_id == 3) {
            let data = _.filter(usermangementData, { u_cat_id: Number(userCategoryId.u_cat_id) })

            if (data && data.length > 0) {
                datas = _.filter(data, { le_id: userCategoryId.le_id })
                if (datas && datas.length > 0) {
                    activeData = _.filter(datas, { is_disable: 0 })
                }
                console.log(activeData, 'activeData');
            }
        }
        if (userCategoryId.u_cat_id != 3) {
            if (formValidator.current.allValid()) {
                setModaVisible(false)
                if (disableType == 'disable') {
                    haveCompliance({
                        payload:
                            [
                                authtoken,
                                {
                                    "session_token": authtoken,
                                    "request": [
                                        "HaveCompliances",
                                        {
                                            "le_id": modalData.le_id,
                                            "user_id": modalData.user_id,
                                            "password": modalData.password,
                                            "product_id": "",

                                        }

                                    ]
                                }
                            ],
                        paramid: paramid,
                        entityid: entityid,
                        type: disableType,
                        user_id: modalData.user_id,
                        remarks: remarks,
                        authtoken: authtoken,
                        password: modalData.password,
                        setModaVisible: setModaVisible
                    })
                }
                else {
                    const payload1 = [

                        authtoken,

                        {

                            "session_token": authtoken,

                            "request": [

                                "BlockUser",

                                {

                                    "user_id": modalData.user_id,

                                    "is_blocked": false,

                                    "active_status": true,

                                    "remarks": remarks,

                                    "password": modalData.password



                                }


                            ]


                        }

                    ]
                    disableStatusChange({
                        payload: payload1,
                        paramid: paramid,
                        setModaVisible: setModaVisible
                    })
                }
                setRemarks("")
                Setpassword({
                    ...passwordata,
                    passvalue: ""

                })
                setModalData({
                    ...modalData,
                    password: ""
                })
                setAddFormSubmit(false)

            }
        }
        else {
            if (formValidator.current.allValid()) {
                setModaVisible(false)
                if (datas && datas.length == 1) {
                    if (disableType == 'disable') {
                        haveCompliance({
                            payload:
                                [
                                    authtoken,
                                    {
                                        "session_token": authtoken,
                                        "request": [
                                            "HaveCompliances",
                                            // "SaveUnitClosureData",
                                            // {
                                            //     "password": modalData.password,
                                            //     "closed_remarks": remarks,
                                            //     "unit_id": Number(modalData.unit_id),
                                            //     "grp_mode": modalData.reason=='closed'?"close":"reactive",
                                            //     "legal_entity_id": entityid
                                            // }
                                            {
                                                "le_id": modalData.le_id,
                                                "user_id": modalData.user_id,
                                                "password": modalData.password,
                                                "product_id": ""
                                            }

                                        ]
                                    }
                                ],
                            paramid: paramid,
                            entityid: entityid,
                            type: disableType,
                            user_id: modalData.user_id,
                            remarks: remarks,
                            authtoken: authtoken,
                            password: modalData.password,
                            setModaVisible: setModaVisible
                        })
                    }
                    else {
                        const payload1 = [

                            authtoken,

                            {

                                "session_token": authtoken,

                                "request": [

                                    "BlockUser",

                                    {

                                        "user_id": modalData.user_id,

                                        "is_blocked": false,

                                        "active_status": true,

                                        "remarks": remarks,

                                        "password": modalData.password



                                    }


                                ]


                            }

                        ]
                        disableStatusChange({
                            payload: payload1,
                            paramid: paramid,
                            setModaVisible: setModaVisible
                        })
                    }
                }
                else if (activeData && activeData.length == 1) {
                    if (disableType == 'disable') {
                        haveCompliance({
                            payload:
                                [
                                    authtoken,
                                    {
                                        "session_token": authtoken,
                                        "request": [
                                            "HaveCompliances",
                                            // "SaveUnitClosureData",
                                            // {
                                            //     "password": modalData.password,
                                            //     "closed_remarks": remarks,
                                            //     "unit_id": Number(modalData.unit_id),
                                            //     "grp_mode": modalData.reason=='closed'?"close":"reactive",
                                            //     "legal_entity_id": entityid
                                            // }
                                            {
                                                "le_id": modalData.le_id,
                                                "user_id": modalData.user_id,
                                                "password": modalData.password,
                                                "product_id": ""
                                            }

                                        ]
                                    }
                                ],
                            paramid: paramid,
                            entityid: entityid,
                            type: disableType,
                            user_id: modalData.user_id,
                            remarks: remarks,
                            authtoken: authtoken,
                            password: modalData.password,
                            setModaVisible: setModaVisible
                        })
                    }
                    else {
                        const payload1 = [

                            authtoken,

                            {

                                "session_token": authtoken,

                                "request": [

                                    "BlockUser",

                                    {

                                        "user_id": modalData.user_id,

                                        "is_blocked": disableType == 'disable' ? true : false,

                                        "active_status": true,

                                        "remarks": remarks,

                                        "password": modalData.password



                                    }


                                ]


                            }

                        ]
                        LegalAdmindisableStatusChange({
                            payload: payload1,
                            paramid: paramid,
                            setModaVisible: setModaVisible,
                            type: disableType
                        })
                    }
                }
                else {
                    const payload1 = [

                        authtoken,

                        {

                            "session_token": authtoken,

                            "request": [

                                "BlockUser",

                                {

                                    "user_id": modalData.user_id,

                                    "is_blocked": disableType == 'disable' ? true : false,

                                    "active_status": true,

                                    "remarks": remarks,

                                    "password": modalData.password



                                }


                            ]


                        }

                    ]
                    LegalAdmindisableStatusChange({
                        payload: payload1,
                        paramid: paramid,
                        setModaVisible: setModaVisible,
                        type: disableType
                    })
                }

                setRemarks("")
                Setpassword({
                    ...passwordata,
                    passvalue: ""

                })
                setModalData({
                    ...modalData,
                    password: ""
                })
                setAddFormSubmit(false)

            }
        }

    }
    const showRemarksModal = () => {
        GetRemarksData({
            payload:
                [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetRemarksData",
                            {}
                        ]
                    }
                ],
            paramid: paramid
        })
        setRemarksModal(true);
    };




    const handleCancel = () => {
        setAddFormSubmit(false)
        setIsModalVisible(false);

        setBusiness(false)
        setLegal(false)
        setModaVisible(false)
        setRemarksModal(false)
    };
    useEffect(() => {
        let finalArray = [];
        let countrylistdata = [];
        let countrylisttempdata = [];
        // for (let countrylist in country_info) {

        let entityArray = []
        let tempArray = []
        let sumValue = 0;
        let country_id
        let country_name
        for (let entitylist in entity_info) {
            country_id = entity_info[entitylist].c_id
            country_name = entity_info[entitylist].c_name
            let entitylist_cid = entity_info[entitylist].c_id
            if (entitylist_cid === country_id) {
                sumValue = entity_info[entitylist].u_count + sumValue
                entityArray = {
                    'bg_id': entity_info[entitylist].bg_id,
                    'bg_name': entity_info[entitylist].bg_name,
                    'c_id': entity_info[entitylist].c_id,
                    'c_name': entity_info[entitylist].c_name,
                    'le_id': entity_info[entitylist].le_id,
                    'le_name': entity_info[entitylist].le_name,
                    'u_count': entity_info[entitylist].u_count,
                }
                tempArray.push(entityArray)
            }
        }
        finalArray[country_name] = tempArray
        countrylistdata = {
            'c_id': country_id,
            'c_name': country_name,
            'allEntitySumNew': sumValue,
            'child': tempArray
        }
        countrylisttempdata.push(countrylistdata)
        SetfinalResult({
            ...finalResult,
            Resultdata: countrylisttempdata
        })


        // }

    }, [])

    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "UserManagementList",
                        {}
                    ]
                }
            ]
            getUsermanagementList({
                payload: payload,
                paramid: paramid
            })
        }
    }, [isAuth])
    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "UserManagementPrerequisite",
                        {}
                    ]
                }
            ]
            getUsermanagementDetails({
                payload: payload,
                paramid: paramid
            })
        }
    }, [isAuth])

    const { Search } = Input;

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (e, confirm, dataIndex, setSelectedKeys) => {
        setSelectedKeys(e.target.value ? [e.target.value] : [])
        confirm({ closeDropdown: false });
        console.log(dataIndex, 'SEARCH VALUE')
        console.log(e.target.value, 'e.target.value');
        setSearchText(e.target.value);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };


    const clearFocus = (searchInput) => {
        setTimeout(() => searchInput.current?.select(), 100);
    }

    const getColumnSearchProps = (dataIndex, placeholder) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div className='d-flex' style={{ position: 'relative' }}>
                <Input className='form-control'
                    ref={searchInput}
                    placeholder={'Search ' + placeholder}
                    value={selectedKeys[0]}
                    autoFocus={'on'}
                    // onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onChange={(e) => handleSearch(e, confirm, dataIndex, setSelectedKeys)}
                />
                <CloseCircleOutlined className='table-filter-reset'
                    onClick={() => {
                        handleReset(clearFilters,
                            handleSearch, confirm, dataIndex, setSelectedKeys)
                        clearFocus(searchInput)
                    }
                    } />

            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    //     // color: filtered ? '#1890ff' : undefined,
                    //     fontWeight: 'bold',
                    //     // backgroundColor:'blue',
                    //     fontSize: '15px',
                    //     color: 'black'
                }}
            />
            // <FileSearchOutlined 
            // style={{
            //     // color: filtered ? '#1890ff' : undefined,
            //     fontWeight:'bold',
            //     // backgroundColor:'blue',
            //     // fontSize:'20px',
            //     color:'black'
            // }}
            // />
            // <i class="bi bi-search"></i>
        ),
        onFilter: (value, record) =>
            record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text
    });

    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', handleKeyDown, false);
            document.addEventListener('webkitfullscreenchange', handleKeyDown, false);
            return () => {
                document.addEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('webkitfullscreenchange', handleKeyDown);
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode();
            setfullscreen(true);
        } else {
            fullscreenexit();
            setfullscreen(false);
        }
    }

    useEffect(() => {
        console.log(userfullscreen1, 'userfullscreen1');
        if (userfullscreen1 != undefined && (userfullscreen1 != '' || userfullscreen1 == true)) {
            fullscreenMode();
        }
    }, [userfullscreen1])

    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});
    const tablehandleChange = (pagination, filters, sorter) => {
        setSortedInfo(sorter);
        setFilteredInfo(filters);
    };


    useEffect(() => {
        if ((addChange == true) && (fullscreen == false)) {
            fullscreenMode()
        }
    }, [addChange])




    const columns = [
        {
            title: 'User',
            dataIndex: false,
           
            width: '18px',
            render: (text, record, index) => {
                return <span title='Click Here to select user' style={{ color: existingId == record.user_id ? 'red' : '' }} onClick={() => {
                    if (replaceproduct.compfie == true || replaceproduct.compfie_vendor == true || replaceproduct.compie_litigation == true) {
                        existingUsers(record.user_id)
                    }
                    else if (singleProduct.length == 1) {
                        existingUsers(record.user_id)
                    }
                    else {
                        Toaster.error("Please select atleast one Product")
                    }

                }}>{record.emp_name}</span>
            }
        },
        {
            title: 'Email ID',
            dataIndex: false,

            width: '18px',
            render: (text, record, index) => {
                return <span title='Click Here to select user' style={{ color: existingId == record.user_id ? 'red' : '' }} onClick={() => {
                    if (replaceproduct.compfie == true || replaceproduct.compfie_vendor == true || replaceproduct.compie_litigation == true) {
                        existingUsers(record.user_id)
                    }
                    else if (singleProduct.length == 1) {
                        existingUsers(record.user_id)
                    }
                    else {
                        Toaster.error("Please select atleast one Product")
                    }
                }}>{record.email_id}</span>
            }
        },
        {
            title: 'User ID',
            dataIndex: false,
            width: '18px',
            render: (text, record, index) => {
                return <span title='Click Here to select user' style={{ color: existingId == record.user_id ? 'red' : '' }} onClick={() => {
                    if (replaceproduct.compfie == true || replaceproduct.compfie_vendor == true || replaceproduct.compie_litigation == true) {
                        existingUsers(record.user_id)
                    }
                    else if (singleProduct.length == 1) {
                        existingUsers(record.user_id)
                    }
                    else {
                        Toaster.error("Please select atleast one Prouct")
                    }

                }}>{record.user_name}</span>
            }
        },

        ...((((existingUserData && existingUserData.u_cat_id !== null) && (existingUserData.is_disable == 0) && (moment(existingUserData.cont_to).isSameOrAfter(date)))) ?
            [{
                title: 'Compfie Category',
                dataIndex: false,
                align: 'center',
                width: '18px',

                render: (text, record, index) => {
                    return <span title='Click Here to select user' style={{ color: existingId == record.user_id ? 'red' : '' }} onClick={() => {
                        if (replaceproduct.compfie == true || replaceproduct.compfie_vendor == true || replaceproduct.compie_litigation == true) {
                            existingUsers(record.user_id)
                        }
                        else if (singleProduct.length == 1) {
                            existingUsers(record.user_id)
                        }
                        else {
                            Toaster.error("Please select atleast one Prouct")
                        }
                    }}>{record && record.u_cat_id == 2 ? "View Only" : record && record.u_cat_id == 3 ? "Legal Entity Admin" : record && record.u_cat_id == 4 ? "Domain Admin" : record && record.u_cat_id == 5 ? "Client Executive" : record && record.u_cat_id == 6 ? "Service Provider" : record && record.u_cat_id == 7 ? "View Only Resticted" : record && record.u_cat_id == 1 ? "Litigation User" : '-'}</span>
                }
            }] : []),
        ...(((existingUserData && existingUserData.u_type_id !== null) && (existingUserData.is_disable == 0) && ((moment(existingUserData.l_cont_to).isSameOrAfter(date)) || ((existingUserData.l_cont_to == null)))) ?
            [{
                title: 'Compfie  Litigation Category',
                dataIndex: false,
                align: 'center',
                width: '18px',
                render: (text, record, index) => {
                    return <span title='Click Here to select user' style={{ color: existingId == record.user_id ? 'red' : '' }} onClick={() => {
                        if (replaceproduct.compfie == true || replaceproduct.compfie_vendor == true || replaceproduct.compie_litigation == true) {
                            existingUsers(record.user_id)
                        }
                        else if (singleProduct.length == 1) {
                            existingUsers(record.user_id)
                        }
                        else {
                            Toaster.error("Please select atleast one Prouct")
                        }

                    }}>{record && record.u_type_id == 2 ? "Creator" : record && record.u_type_id == 3 ? 'user' : '-'}</span>
                }
            }] : []),
        ...(((existingUserData && existingUserData.vndr_user_category !== null) && (existingUserData.is_disable == 0) && ((moment(existingUserData.v_cont_to).isSameOrAfter(date)) || ((existingUserData.v_cont_to == null)))) ?
            [{
                title: 'Compfie Vendor Category',
                dataIndex: false,
                align: 'center',
                width: '18px',
                render: (text, record, index) => {
                    return <span title='Click Here to select user' style={{ color: existingId == record.user_id ? 'red' : '' }} onClick={() => {
                        if (replaceproduct.compfie == true || replaceproduct.compfie_vendor == true || replaceproduct.compie_litigation == true) {
                            existingUsers(record.user_id)
                        }
                        else if (singleProduct.length == 1) {
                            existingUsers(record.user_id)
                        }
                        else {
                            Toaster.error("Please select atleast one Prouct")
                        }

                    }}>{record && record.vndr_user_category == 2 ? "View Only" : record && record.vndr_user_category == 3 ? "Legal Entity Admin" : record && record.vndr_user_category == 4 ? "Domain Admin" : record && record.vndr_user_category == 5 ? "Client Executive" : record && record.vndr_user_category == 6 ? "Service Provider" : record && record.vndr_user_category == 7 ? "View Only Resticted" : record && record.vndr_user_category == 1 ? "Litigation User" : '-'}</span>
                }
            }] : []),




    ];
    const columns1 = [
        {
            title: 'User',
            dataIndex: false,
            
            width: '18px',
            render: (text, record, index) => {
                return <span title='Click Here to select user' style={{ color: existingId == record.user_id ? 'red' : '' }} onClick={() => {
                    if (replaceproduct.compfie == true || replaceproduct.compfie_vendor == true || replaceproduct.compie_litigation == true) {
                        existingUsers(record.user_id)
                    }
                    else if (singleProduct.length == 1) {
                        existingUsers(record.user_id)
                    }
                    else {
                        Toaster.error("Please select atleast one Prouct")
                    }

                }}>{record.emp_name}</span>
            }
        },
        {
            title: 'Email ID',
            dataIndex: false,
           
            width: '18px',
            render: (text, record, index) => {
                return <span title='Click Here to select user' style={{ color: existingId == record.user_id ? 'red' : '' }} onClick={() => {
                    if (replaceproduct.compfie == true || replaceproduct.compfie_vendor == true || replaceproduct.compie_litigation == true) {
                        existingUsers(record.user_id)
                    }
                    else if (singleProduct.length == 1) {
                        existingUsers(record.user_id)
                    }
                    else {
                        Toaster.error("Please select atleast one Prouct")
                    }

                }}>{record.email_id}</span>
            }
        },
        {
            title: 'User ID',
            dataIndex: false,
          
            width: '18px',
            render: (text, record, index) => {
                return <span title='Click Here to select user' style={{ color: existingId == record.user_id ? 'red' : '' }} onClick={() => {
                    if (replaceproduct.compfie == true || replaceproduct.compfie_vendor == true || replaceproduct.compie_litigation == true) {
                        existingUsers(record.user_id)
                    }
                    else if (singleProduct.length == 1) {
                        existingUsers(record.user_id)
                    }
                    else {
                        Toaster.error("Please select atleast one Prouct")
                    }

                }}>{record.user_name}</span>
            }
        },
        ...((replaceproduct.compfie == false) ? [] :
            [{
                title: 'Compfie Category',
                dataIndex: false,
                align: 'center',
                width: '18px',

                render: (text, record, index) => {
                    return <span title='Click Here to select user' style={{ color: existingId == record.user_id ? 'red' : '' }} onClick={() => {
                        if (replaceproduct.compfie == true || replaceproduct.compfie_vendor == true || replaceproduct.compie_litigation == true) {
                            existingUsers(record.user_id)
                        }
                        else if (singleProduct.length == 1) {
                            existingUsers(record.user_id)
                        }
                        else {
                            Toaster.error("Please select atleast one Prouct")
                        }
                    }}>{record && record.u_cat_id == 2 ? "View Only" : record && record.u_cat_id == 3 ? "Legal Entity Admin" : record && record.u_cat_id == 4 ? "Domain Admin" : record && record.u_cat_id == 5 ? "Client Executive" : record && record.u_cat_id == 6 ? "Service Provider" : record && record.u_cat_id == 7 ? "View Only Resticted" : record && record.u_cat_id == 1 ? "Litigation User" : '-'}</span>
                }
            }]),
        ...((replaceproduct.compie_litigation == false) ? [] :
            [{
                title: 'Compfie  Litigation Category',
                dataIndex: false,
                align: 'center',
                width: '18px',
                render: (text, record, index) => {
                    return <span title='Click Here to select user' style={{ color: existingId == record.user_id ? 'red' : '' }} onClick={() => {
                        if (replaceproduct.compfie == true || replaceproduct.compfie_vendor == true || replaceproduct.compie_litigation == true) {
                            existingUsers(record.user_id)
                        }
                        else if (singleProduct.length == 1) {
                            existingUsers(record.user_id)
                        }
                        else {
                            Toaster.error("Please select atleast one Prouct")
                        }

                    }}>{record && record.u_type_id == 2 ? "Creator" : record && record.u_type_id == 3 ? 'user' : '-'}</span>
                }
            }]),
        ...((replaceproduct.compfie_vendor == false) ? [] :
            [{
                title: 'Compfie Vendor Category',
                dataIndex: false,
                align: 'center',
                width: '18px',
                render: (text, record, index) => {
                    return <span title='Click Here to select user' style={{ color: existingId == record.user_id ? 'red' : '' }} onClick={() => {
                        if (replaceproduct.compfie == true || replaceproduct.compfie_vendor == true || replaceproduct.compie_litigation == true) {
                            existingUsers(record.user_id)
                        }
                        else if (singleProduct.length == 1) {
                            existingUsers(record.user_id)
                        }
                        else {
                            Toaster.error("Please select atleast one Prouct")
                        }

                    }}>{record && record.vndr_user_category == 2 ? "View Only" : record && record.vndr_user_category == 3 ? "Legal Entity Admin" : record && record.vndr_user_category == 4 ? "Domain Admin" : record && record.vndr_user_category == 5 ? "Client Executive" : record && record.vndr_user_category == 6 ? "Service Provider" : record && record.vndr_user_category == 7 ? "View Only Resticted" : record && record.vndr_user_category == 1 ? "Litigation User" : '-'}</span>
                }
            }]),


    ];


    useEffect(() => {


        if (GetUserDetails && GetUserDetails.ul_userDetails && GetUserDetails.ul_userDetails.length > 0) {
            let userCategory = _.filter(userManagementDetails && userManagementDetails.um_user_category.length > 0 && userManagementDetails.um_user_category, { u_c_id: GetUserDetails && GetUserDetails.ul_userDetails[0].u_cat_id })
            let SeatingUnit = _.filter(userManagementDetails && userManagementDetails.um_legal_units, { u_unt_id: GetUserDetails && GetUserDetails.ul_userDetails[0].seating_unit_id })
            let UserGroup = _.filter(userManagementDetails && userManagementDetails.um_user_group, { u_g_id: GetUserDetails && GetUserDetails.ul_userDetails[0].u_g_id })
            let ServiceProvider = _.filter(userManagementDetails && userManagementDetails.um_service_providers, { u_sp_id: GetUserDetails && GetUserDetails.ul_userDetails[0].sp_id })
            let entityData = []
            let EntityData2 = []
            if (localStorage.getItem("SelectedEntity") == 'All Legal Entity') {
                let LegalEntity = GetUserDetails && GetUserDetails.ul_legal_entities && GetUserDetails && GetUserDetails.ul_legal_entities.length && GetUserDetails && GetUserDetails.ul_legal_entities.map((item) => {
                    return item.le_id
                })
                // setEditEntity(LegalEntity)
                setEntityValue(LegalEntity)
            }
            else {
                let data = _.filter(userManagementDetails && userManagementDetails.um_legal_entity, { le_id: entityid })
                console.log(data, 'dataaaaaaaaa')
                if (data && data.length > 0) {
                    for (let l in data) {

                        entityData.push(`${data[l].le_id}`)

                    }
                    // setEditEntity(entityData)
                    setEntityValue(entityData)
                }

            }
            let LegalEntity = GetUserDetails && GetUserDetails.ul_legal_entities && GetUserDetails && GetUserDetails.ul_legal_entities.length && GetUserDetails && GetUserDetails.ul_legal_entities.map((item) => {
                return item.le_id
            })
            setpayloadEntityEdit(LegalEntity)
            let tempp = []
            let tempp2 = []
            let temp5 = []
            let templ = []
            if (localStorage.getItem("SelectedEntity") == 'All Legal Entity') {
                console.log('aishuuu');
                let value = GetUserDetails && GetUserDetails.ul_legal_entities.length > 0 && GetUserDetails && GetUserDetails.ul_legal_entities.map((item) => {
                    // if (item.bg_id != null) {
                    return item.bg_id
                    // }


                })
                // setLegalBg(value)
                GetUserDetails && GetUserDetails.ul_legal_entities.length > 0 && GetUserDetails && GetUserDetails.ul_legal_entities.map((item) => {
                    // if (item.bg_id != null) {
                    //     return `${item.bg_id}`
                    // }
                    let data = _.filter(businessData, { bg_id: item.bg_id })
                    console.log(data, 'dataaaaaaa');
                    if (data && data.length > 0) {
                        tempp.push(data[0])
                    }

                })
                console.log(tempp, 'tempp777777');
                if (tempp && tempp.length > 0) {
                    for (let l in tempp) {
                        if (tempp[l].bg_id != null) {
                            tempp2.push(`${tempp[l].bg_id}`)
                        }
                    }
                }

                setbusinessGroupValue(tempp2)
            } else {
                let data = _.filter(GetUserDetails && GetUserDetails.ul_legal_entities, { le_id: entityid })
                console.log(data, 'dataaaaaaaaa')
                if (data && data.length > 0) {
                    for (let l in data) {
                        if (data[l].bg_id != null) {
                            tempp2.push(`${data[l].bg_id}`)
                        }
                    }
                }
                setbusinessGroupValue(tempp2)
            }


            let LitigationLegalEntity = GetUserDetails && GetUserDetails.ul_ltg_legal_entities && GetUserDetails && GetUserDetails.ul_ltg_legal_entities.length > 0 && GetUserDetails && GetUserDetails.ul_ltg_legal_entities.map((item) => {
                return item.le_id
            })
            console.log(LitigationLegalEntity, 'LitigationLegalEntity');
            if (((LitigationLegalEntity && LitigationLegalEntity.length == 0) || (LitigationLegalEntity == false) || (LitigationLegalEntity == 0))) {
                setLitigationEntityValue([])
                setpayloadLitigationEntityEdit([])
            }
            else {
                setLitigationEntityValue(LitigationLegalEntity)
                setpayloadLitigationEntityEdit(LitigationLegalEntity)

            }

            let litigationbg = GetUserDetails && GetUserDetails.ul_ltg_legal_entities.length && GetUserDetails.ul_ltg_legal_entities.map((item) => {
                if (item.bg_id != null) {
                    return `${item.bg_id}`
                }

            })

            // let litigationbg1 = GetUserDetails && GetUserDetails.ul_ltg_legal_entities.length && GetUserDetails.ul_ltg_legal_entities.map((item) => {

            //     return item.bg_id


            // })
            // setLitigationLegalBg(litigationbg1)
            // let templ = []
            for (let l in litigationbg) {
                if ((litigationbg[l] != undefined) && (litigationbg[l] != null)) {
                    templ.push(litigationbg[l])
                }
            }
            // console.log(vendorEntityData, 'vendorEntityData');
            setlititigationbusinessGroupValue(templ)
            // setLitigationEntityValue(LitigationLegalEntity)
            let VendorLegalEntity = GetUserDetails && GetUserDetails.ul_vend_legal_entities && GetUserDetails && GetUserDetails.ul_vend_legal_entities.length && GetUserDetails && GetUserDetails.ul_vend_legal_entities.map((item) => {
                return item.le_id
            })
            console.log(VendorLegalEntity, 'VendorLegalEntity8888888');

            if (((VendorLegalEntity && VendorLegalEntity.length == 0) || (VendorLegalEntity == false) || (VendorLegalEntity == 0))) {
                setpayloadVendorEntityEdit([])
                setVendoreEntityValue([])
            }
            else {
                setVendoreEntityValue(VendorLegalEntity)
                setpayloadVendorEntityEdit(VendorLegalEntity)
            }
            // if (VendorLegalEntity != 0) {
            //     setpayloadVendorEntityEdit(VendorLegalEntity)
            // }
            // else {
            //     setpayloadVendorEntityEdit([])
            // }
            let vendorBg = GetUserDetails && GetUserDetails.ul_vend_legal_entities.length && GetUserDetails.ul_vend_legal_entities.map((item) => {
                if (item.bg_id != null) {
                    return `${item.bg_id}`
                }
            })
            // let vendorBg1 = GetUserDetails && GetUserDetails.ul_vend_legal_entities.length && GetUserDetails.ul_vend_legal_entities.map((item) => {

            //     return item.bg_id

            // })
            // setVendorLegalBg(vendorBg1)
            // let temp5 = []
            for (let l in vendorBg) {
                if ((vendorBg[l] != undefined) && (vendorBg[l] != null)) {
                    temp5.push(vendorBg[l])
                }
            }

            setVendorbusinessGroupValue(temp5)

            if (localStorage.getItem("SelectedEntity") == 'All Legal Entity') {
                let division = GetUserDetails && GetUserDetails.ul_user_units.length > 0 && GetUserDetails.ul_user_units.map((item) => {
                    if (item.div_id != null) {
                        console.log(item.div_id, 'item.div_id');
                        return `${item.div_id}`
                    }

                })


                let temp = []
                for (let l in division) {
                    if (division[l] != undefined) {
                        temp.push(division[l])
                    }
                }
                setDivisionValue([...new Set(temp)])
            }
            else {
                let EntityData = _.filter(GetUserDetails && GetUserDetails.ul_user_units.length > 0 && GetUserDetails.ul_user_units, { le_id: entityid })
                if (EntityData && EntityData.length > 0) {
                    let division = EntityData && EntityData.length > 0 && EntityData.map((item) => {
                        if (item.div_id != null) {
                            console.log(item.div_id, 'item.div_id');
                            return `${item.div_id}`
                        }

                    })


                    let temp = []
                    for (let l in division) {
                        if (division[l] != undefined) {
                            temp.push(division[l])
                        }
                    }
                    setDivisionValue([...new Set(temp)])
                }
            }

            // setDivisionValue(temp)
            if (localStorage.getItem("SelectedEntity") == 'All Legal Entity') {
                let temp2 = []
                let vendordivision = GetUserDetails && GetUserDetails.vend_unitlist.length > 0 && GetUserDetails.vend_unitlist.map((item) => {
                    if (item.div_id != null) {
                        console.log(item.div_id, 'item.div_id');
                        return `${item.div_id}`
                    }

                })
                for (let k in vendordivision) {
                    if (vendordivision[k] != undefined) {
                        temp2.push(vendordivision[k])
                    }
                }
                setvendorDivisionValue([...new Set(temp2)])
            }

            else {
                let EntityData = _.filter(GetUserDetails && GetUserDetails.vend_unitlist.length > 0 && GetUserDetails.vend_unitlist, { le_id: entityid })
                let temp2 = []
                let vendordivision = EntityData && EntityData.length > 0 && EntityData.map((item) => {
                    if (item.div_id != null) {
                        console.log(item.div_id, 'item.div_id');
                        return `${item.div_id}`
                    }

                })
                for (let k in vendordivision) {
                    if (vendordivision[k] != undefined) {
                        temp2.push(vendordivision[k])
                    }
                }
                setvendorDivisionValue([...new Set(temp2)])

            }
            if (localStorage.getItem("SelectedEntity") == 'All Legal Entity') {
                let categorydata = GetUserDetails && GetUserDetails.ul_user_units.length > 0 && GetUserDetails.ul_user_units.map((item) => {
                    if (item.cat_id != null) {
                        console.log(item.cat_id, 'item.cat_id');
                        return `${item.cat_id}`
                    }

                })
                let temparr1 = []
                for (let l in categorydata) {
                    if (categorydata[l] != undefined) {
                        temparr1.push(categorydata[l])
                    }
                }
                setCategoryValues([...new Set(temparr1)])
            }
            else {
                let EntityData = _.filter(GetUserDetails && GetUserDetails.ul_user_units.length > 0 && GetUserDetails.ul_user_units, { le_id: entityid })
                let categorydata = EntityData && EntityData.length > 0 && EntityData.map((item) => {
                    if (item.cat_id != null) {
                        console.log(item.cat_id, 'item.cat_id');
                        return `${item.cat_id}`
                    }

                })
                let temparr1 = []
                for (let l in categorydata) {
                    if (categorydata[l] != undefined) {
                        temparr1.push(categorydata[l])
                    }
                }
                setCategoryValues([...new Set(temparr1)])
            }
            if (localStorage.getItem("SelectedEntity") == 'All Legal Entity') {
                let vendorcategorydata = GetUserDetails && GetUserDetails.vend_unitlist.length > 0 && GetUserDetails.vend_unitlist.map((item) => {
                    if (item.cat_id != null) {
                        console.log(item.cat_id, 'item.cat_id');
                        return `${item.cat_id}`
                    }

                })
                let temparr2 = []
                for (let l in vendorcategorydata) {
                    if (vendorcategorydata[l] != undefined) {
                        temparr2.push(vendorcategorydata[l])
                    }
                }
                setVendorCategoryValues([...new Set(temparr2)])
            }
            else {
                let EntityData = _.filter(GetUserDetails && GetUserDetails.vend_unitlist.length > 0 && GetUserDetails.vend_unitlist, { le_id: entityid })
                if (EntityData && EntityData.length > 0) {
                    let vendorcategorydata = EntityData && EntityData.length > 0 && EntityData.map((item) => {
                        if (item.cat_id != null) {
                            console.log(item.cat_id, 'item.cat_id');
                            return `${item.cat_id}`
                        }

                    })
                    let temparr2 = []
                    for (let l in vendorcategorydata) {
                        if (vendorcategorydata[l] != undefined) {
                            temparr2.push(vendorcategorydata[l])
                        }
                    }
                    setVendorCategoryValues([...new Set(temparr2)])
                }
            }
            let VendoruserCategory = _.filter(userManagementDetails && userManagementDetails.um_user_category, { u_c_id: GetUserDetails && GetUserDetails.ul_userDetails[0].vndr_user_category })
            let VendorSeatingUnit = _.filter(userManagementDetails && userManagementDetails.um_legal_units, { u_unt_id: GetUserDetails && GetUserDetails.ul_userDetails[0].vndr_seating_unit })
            let VendorUserGroup = _.filter(userManagementDetails && userManagementDetails.um_user_group, { u_g_id: GetUserDetails && GetUserDetails.ul_userDetails[0].vndr_user_group_id })
            setCategory({
                ...category,
                compfie: userCategory && userCategory.length > 0 && userCategory[0].u_c_id,
                compfie_vendor: VendoruserCategory && VendoruserCategory.length > 0 && VendoruserCategory[0].u_c_id

            })

            setCategoryValue({
                ...categoryValue,
                category: userCategory,
                // domain: Domain,
                serviceProvider: ServiceProvider,
                // legalEnity: LegalEntity,
                userGroup: UserGroup,
                seatingUnit: SeatingUnit
            })
            setEditCompfieVendor({
                ...EditCompfieVendor,
                category: VendoruserCategory,
                // domain: VendorDomains,
                seatingUnit: VendorSeatingUnit,
                legalEnity: VendorLegalEntity,
                userGroup: VendorUserGroup
            })
            setCompfie({
                ...compfie,
                category: GetUserDetails && GetUserDetails.ul_userDetails.length > 0 && GetUserDetails.ul_userDetails[0].u_cat_id == null ? null : `${GetUserDetails && GetUserDetails.ul_userDetails.length > 0 && GetUserDetails.ul_userDetails[0].u_cat_id}`,
                seating_Unit: SeatingUnit.length ? `${SeatingUnit[0].u_unt_code}-${SeatingUnit[0].u_unt_name}` : null,
                user_level: GetUserDetails && GetUserDetails.ul_userDetails[0].user_level == null ? null : `${GetUserDetails && GetUserDetails.ul_userDetails[0].user_level}`,
                user_Group: GetUserDetails && GetUserDetails.ul_userDetails[0].u_g_id == null ? null : `${GetUserDetails && GetUserDetails.ul_userDetails[0].u_g_id}`,
                serviceProvider: ServiceProvider && ServiceProvider.length ? ServiceProvider && ServiceProvider.length && ServiceProvider[0].u_sp_name : null,
                // legal_Entity: LegalEntity && LegalEntity.length ? LegalEntity[0].le_name : [],
                // Domain: Domain && Domain.length ? Domain && Domain.length && Domain[0].u_dm_name : []
            })
            setCompfieLitigation({
                ...compfieLitigation,
                category: GetUserDetails && GetUserDetails.ul_userDetails[0].usr_type_id,
                user: 'Inhouse User',
                // legal_Entity: LitigationLegalEntity && LitigationLegalEntity.length ? LitigationLegalEntity[0].le_name : [],
                // Domain: LitigationDomain.length ? LitigationDomain && LitigationDomain[0].u_dm_name : []
            })
            console.log(GetUserDetails && GetUserDetails.ul_userDetails[0].u_cat_id, 'GetUserDetails && GetUserDetails.ul_userDetails[0].vndr_user_category');
            setCompfieVendorValue({
                ...compfieVendorValue,
                category: GetUserDetails && GetUserDetails.ul_userDetails[0].vndr_user_category == null ? null : `${GetUserDetails && GetUserDetails.ul_userDetails[0].vndr_user_category}`,
                seating_Unit: VendorSeatingUnit && VendorSeatingUnit.length ? `${VendorSeatingUnit[0].u_unt_code}-${VendorSeatingUnit[0].u_unt_name}` : null,
                user_level: GetUserDetails && GetUserDetails.ul_userDetails[0].vndr_user_level == null ? null : `${GetUserDetails && GetUserDetails.ul_userDetails[0].vndr_user_level}`,
                user_Group: GetUserDetails && GetUserDetails.ul_userDetails[0].vndr_user_group_id == null ? null : `${GetUserDetails && GetUserDetails.ul_userDetails[0].vndr_user_group_id}`,
                // legal_Entity: VendorLegalEntity && VendorLegalEntity.length && VendorLegalEntity[0].le_name,
                serviceProvider: ServiceProvider && ServiceProvider.length ? ServiceProvider && ServiceProvider.length && ServiceProvider[0].u_sp_name : null,

                // Domain: VendorDomain && VendorDomain.length ? VendorDomain && VendorDomain.length && VendorDomain[0].u_dm_name : []
            })



        }


    }, [GetUserDetails])

    useEffect(() => {

        if (existingUserData) {
            let temp = []
            setTabValue({
                ...tabValue,
                compfie: existingUserData && existingUserData.u_cat_id !== null ? true : false,
                compfie_litigation: existingUserData && existingUserData.u_type_id !== null ? true : false,
                compfie_vendor: existingUserData && existingUserData.vndr_user_category !== null ? true : false,
            })
            if (existingUserData && existingUserData.u_cat_id !== null) {
                temp.push(existingUserData && existingUserData.u_cat_id)
            }
            if (existingUserData && existingUserData.u_type_id !== null) {
                temp.push(existingUserData && existingUserData.u_type_id)
            }
            if (existingUserData && existingUserData.vndr_user_category !== null) {
                temp.push(existingUserData && existingUserData.vndr_user_category)
            }
            setProductCount(temp)
        }


    }, [existingUserData])
    useEffect(() => {
        if (GetUserDetails && GetUserDetails.ul_user_domains.length > 0) {
            let temparr = []
            let temparr2 = []
            let closedTemp = []
            let ClosedTemparr = []
            let vendor = []
            let vendorClosed = []
            let categoryTemp = []
            let categoryTemparr = []
            let divisionarr = []
            let divisionArr = []
            let tem = []
            let tem2 = []

            for (let s in entityValue) {

                if (GetUserDetails && GetUserDetails.ul_user_domains
                    .length > 0) {
                    let data = _.filter(GetUserDetails && GetUserDetails.ul_user_domains.length && GetUserDetails.ul_user_domains, { le_id: entityValue[s] })
                    console.log(data, 'datadatadata');
                    console.log(GetUserDetails && GetUserDetails.ul_user_domains, 'GetUserDetails && GetUserDetails.ul_user_domains');
                    if (data && data.length > 0) {
                        for (let j in data) {
                            tem.push(data[j])
                        }
                    }
                }

            }


            // setdomainEntity(tem)

            if (tem && tem.length > 0) {
                let data = tem && tem.length > 0 && tem.map((item) => {
                    return `${item.u_dm_id}`
                })
                setdomainValue(data)
            }


            let temarray = []
            for (let s in payloadEntityEdit) {

                if (GetUserDetails && GetUserDetails.ul_user_domains.length > 0) {
                    let data = _.filter(GetUserDetails && GetUserDetails.ul_user_domains.length && GetUserDetails.ul_user_domains, { le_id: payloadEntityEdit[s] })
                    console.log(data, 'datadatadata');
                    console.log(GetUserDetails && GetUserDetails.ul_user_domains, 'GetUserDetails && GetUserDetails.ul_user_domains');
                    if (data && data.length > 0) {
                        for (let j in data) {
                            temarray.push(data[j])
                        }
                    }
                }

            }


            // setdomainEntity(tem)
            console.log(temarray, 'tem555555');
            if (temarray && temarray.length > 0) {
                let data = temarray && temarray.length > 0 && temarray.map((item) => {

                    temparr.push({
                        le_id: item.le_id,
                        d_id: item.u_dm_id
                    })
                })
                console.log(temparr, 'mukilan');
                setPayloaEditdDomain(temparr)
            }



        }
        if (GetUserDetails && GetUserDetails.ul_ltg_user_domains.length) {
            let temparr = []
            let temparr2 = []
            let closedTemp = []
            let ClosedTemparr = []
            let vendor = []
            let vendorClosed = []
            let categoryTemp = []
            let categoryTemparr = []
            let divisionarr = []
            let divisionArr = []
            let tem = []
            let tem2 = []



            for (let s in litigationEntityValue) {

                if (GetUserDetails && GetUserDetails.ul_ltg_user_domains.length > 0) {
                    let data = _.filter(GetUserDetails && GetUserDetails.ul_ltg_user_domains.length && GetUserDetails.ul_ltg_user_domains, { le_id: litigationEntityValue[s] })

                    if (data && data.length > 0) {
                        for (let j in data) {
                            tem.push(data[j])
                        }
                    }
                }

            }

            // setlitigationdomainEntity(tem)

            let datas = litigationdomaindata && litigationdomaindata.length > 0 && litigationdomaindata.map((item) => {
                return item.u_dm_id
            })

            if (tem && tem.length > 0) {
                let data = tem && tem.length > 0 && tem.map((item) => {
                    if (datas.includes(item.u_dm_id)) {
                        return `${item.u_dm_id}`
                    }
                })
                setlitigationdomainValue(data)
            }


            let temarray = []
            for (let s in payloadLitigationEntityEdit) {

                if (GetUserDetails && GetUserDetails.ul_ltg_user_domains.length > 0) {
                    let data = _.filter(GetUserDetails && GetUserDetails.ul_ltg_user_domains.length && GetUserDetails.ul_ltg_user_domains, { le_id: payloadLitigationEntityEdit[s] })
                    console.log(data, 'datadatadata');
                    console.log(GetUserDetails && GetUserDetails.ul_ltg_user_domains, 'GetUserDetails && GetUserDetails.ul_user_domains');
                    if (data && data.length > 0) {
                        for (let j in data) {
                            temarray.push(data[j])
                        }
                    }
                }

            }


            // setdomainEntity(tem)

            if (temarray && temarray.length > 0) {
                let data = temarray && temarray.length > 0 && temarray.map((item) => {

                    temparr.push({
                        le_id: item.le_id,
                        d_id: item.u_dm_id
                    })
                })
                setpayloadEditlitigationDomain(temparr)
            }


        }
        if (GetUserDetails && GetUserDetails.ul_vend_user_domains.length) {
            let temparr = []
            let temparr2 = []
            let closedTemp = []
            let ClosedTemparr = []
            let vendor = []
            let vendorClosed = []
            let categoryTemp = []
            let categoryTemparr = []
            let divisionarr = []
            let divisionArr = []
            let tem = []
            let tem2 = []
            for (let s in vendoreEntityValue) {

                if (GetUserDetails && GetUserDetails.ul_vend_user_domains.length > 0) {
                    let data = _.filter(GetUserDetails && GetUserDetails.ul_vend_user_domains.length && GetUserDetails.ul_vend_user_domains, { le_id: vendoreEntityValue[s] })

                    if (data && data.length > 0) {
                        for (let j in data) {
                            tem.push(data[j])
                        }
                    }
                }

            }

            // setvendordomainEntity(tem)
            let datas = vendordomaindata && vendordomaindata.length > 0 && vendordomaindata.map((item) => {
                return item.u_dm_id
            })
            if (tem && tem.length > 0) {
                let data = tem && tem.length > 0 && tem.map((item) => {
                    if (datas.includes(item.u_dm_id)) {
                        return `${item.u_dm_id}`
                    }
                    // else {
                    //     return null
                    // }
                })
                setvendordomainValues(data)
            }



            let temarray = []
            for (let s in payloadVendorEntityEdit) {

                if (GetUserDetails && GetUserDetails.ul_vend_user_domains.length > 0) {
                    let data = _.filter(GetUserDetails && GetUserDetails.ul_vend_user_domains.length && GetUserDetails.ul_vend_user_domains, { le_id: payloadEntityEdit[s] })
                    console.log(data, 'datadatadata');
                    console.log(GetUserDetails && GetUserDetails.ul_vend_user_domains, 'GetUserDetails && GetUserDetails.ul_user_domains');
                    if (data && data.length > 0) {
                        for (let j in data) {
                            temarray.push(data[j])
                        }
                    }
                }

            }


            // setdomainEntity(tem)

            if (temarray && temarray.length > 0) {
                let data = temarray && temarray.length > 0 && temarray.map((item) => {

                    temparr.push({
                        le_id: item.le_id,
                        d_id: item.u_dm_id
                    })
                })
                setPayloaEditVendordDomain(temparr)
            }




        }

    }, [GetUserDetails && GetUserDetails.ul_user_domains, entityValue, litigationEntityValue, vendoreEntityValue])
    const resentEmailRegistration = (record) => {
        const payload = [
            authtoken,
            {
                "session_token": authtoken,

                "request": [
                    "ResendRegistrationEmail",
                    {
                        "user_id": record.user_id
                    }
                ]
            }
        ]
        resendEmail({
            payload: payload,
            paramid: paramid
        })

    }


    const editUser = (user_id, record) => {
        setDetails({
            ...details,
            le_id: record.le_id,
            user_id: record.user_id
        })
        setAddType("edit")
        setShowList(true)
        const payload = [
            authtoken,
            {
                "session_token": authtoken,

                "request": [
                    "UserManagementEditView",
                    {
                        "user_id": user_id
                    }
                ]
            }
        ]
        getById({
            payload: payload,
            paramid: paramid
        })


    }
    console.log(disableType, 'disableTypedisableType');
    const DisableById = (user_id, type, le_id, record) => {
        setDisableType(type)
        setUserCategoryId({
            u_cat_id: record.u_cat_id,
            le_id: record.le_id
        })
        type == "disable" ?
            Swal.fire({
                title: 'Are you sure? ',
                icon: 'info',
                text: ' You Want to Disable the User',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                customClass: localStorage.getItem("currentTheme") == 'theme_four' ? "customSWal" : 'customSWal2',
                confirmButtonColor: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 '

            }).then((result) => {
                if (result.isConfirmed) {
                    setModaVisible(true)
                    setAddFormSubmit1(false)
                    setModalData({
                        ...modalData,
                        user_id: user_id,
                        le_id: le_id
                    })
                }
            }) : Swal.fire({
                title: 'Are you sure? ',
                icon: 'info',
                text: ' You Want to Enable the User',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                customClass: localStorage.getItem("currentTheme") == 'theme_four' ? "customSWal" : 'customSWal2',
                confirmButtonColor: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 '
            }).then((result) => {
                if (result.isConfirmed) {
                    setModaVisible(true)
                    setAddFormSubmit1(false)
                    setModalData({
                        ...modalData,
                        user_id: user_id,
                        le_id: le_id
                    })
                }
            });
    };

    const existingUsers = (id) => {
        setDisableType(type)
        Swal.fire({
            title: 'Are you sure? ',
            icon: 'info',
            text: 'Are you replace the user',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            customClass: localStorage.getItem("currentTheme") == 'theme_four' ? "customSWal" : 'customSWal2',
            confirmButtonColor: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 '
        }).then((result) => {
            if (result.isConfirmed) {
                // setUser(record.user_id)
                setExistingId(id)
            }
            else {
                setExistingId('')
            }
        });
    };




    useEffect(() => {
        if (entity_info) {
            setLegalEntity(entity_info)
        }
    }, [])

    useEffect(() => {

        let LegalEntityData = _.find(UserLegalDetails && UserLegalDetails, { le_id: entityid })
        setEntity(LegalEntityData)
    }, [entityid, UserLegalDetails])
    console.log(userManagementList, 'userManagementList5555');
    useEffect(() => {
        if (userManagementList && userManagementList.length > 0 && selectedEntity == 'All Legal Entity') {
            // let temp = []
            // for (let i in userManagementList) {
            //     for (let j in UserLegalDetails) {
            //         if (userManagementList[i].le_id == UserLegalDetails[j].le_id) {
            //             let obj = {
            //                 "email_id": userManagementList[i].email_id,
            //                 "emp_code": userManagementList[i].emp_code,
            //                 "emp_name": userManagementList[i].emp_name,
            //                 "is_active": userManagementList[i].is_active,
            //                 "is_disable": userManagementList[i].is_disable,
            //                 "le_id": userManagementList[i].le_id,
            //                 "mob_no": userManagementList[i].mob_no,
            //                 "u_cat_id": userManagementList[i].u_cat_id,
            //                 "u_type_id": userManagementList[i].u_type_id,
            //                 "unblock_days": userManagementList[i].unblock_days,
            //                 "user_id": userManagementList[i].user_id,
            //                 "user_name": userManagementList[i].user_name,
            //                 "cont_from": UserLegalDetails[j].cont_from,
            //                 "cont_to": UserLegalDetails[j].cont_to,
            //                 "l_cont_from": UserLegalDetails[j].l_cont_from,
            //                 "l_cont_to": UserLegalDetails[j].l_cont_to,
            //                 "v_cont_from": UserLegalDetails[j].v_cont_from,
            //                 "v_cont_to": UserLegalDetails[j].v_cont_to,
            //                 "vndr_user_category": userManagementList[i].vndr_user_category

            //             }
            //             temp.push(obj)

            //         }
            //     }
            // }
            // setUserMagementData(temp)
        }
        else if (usermanagementDetails && usermanagementDetails.length > 0 && selectedEntity !== 'All Legal Entity') {
            let temp = []
            let LegalEntityData = _.find(UserLegalDetails && UserLegalDetails, { le_id: entityid })
            for (let i in usermanagementDetails) {
                let obj = {
                    "email_id": usermanagementDetails[i].email_id,
                    "emp_code": usermanagementDetails[i].emp_code,
                    "emp_name": usermanagementDetails[i].emp_name,
                    "is_active": usermanagementDetails[i].is_active,
                    "is_disable": usermanagementDetails[i].is_disable,
                    "le_id": usermanagementDetails[i].le_id,
                    "mob_no": usermanagementDetails[i].mob_no,
                    "u_cat_id": usermanagementDetails[i].u_cat_id,
                    "u_type_id": usermanagementDetails[i].u_type_id,
                    "unblock_days": usermanagementDetails[i].unblock_days,
                    "user_id": usermanagementDetails[i].user_id,
                    "user_name": usermanagementDetails[i].user_name,
                    "cont_from": usermanagementDetails.cont_from,
                    "cont_to": usermanagementDetails.cont_to,
                    "l_cont_from": usermanagementDetails.l_cont_from,
                    "l_cont_to": usermanagementDetails.l_cont_to,
                    "v_cont_from": usermanagementDetails.v_cont_from,
                    "v_cont_to": usermanagementDetails.v_cont_to,
                    "vndr_user_category": usermanagementDetails[i].vndr_user_category

                }
                temp.push(obj)
            }
            setUserMagementData(temp)

        }
    }, [UserLegalDetails, userManagementList, usermanagementDetails])
    console.log(usermangementData, 'usermangementData123');
    console.log(userManagementList, 'userManagementList');

    useEffect(() => {
        if (userManagementList.length > 0) {
            let temp = []
            for (let i in userManagementList) {
                for (let j in userManagementList[i].le_id) {
                    console.log('aishuuuuu');
                    let obj = {
                        "email_id": userManagementList[i].email_id,
                        "emp_code": userManagementList[i].emp_code,
                        "emp_name": userManagementList[i].emp_name,
                        "is_active": userManagementList[i].is_active,
                        "is_disable": userManagementList[i].is_disable,
                        "le_id": userManagementList[i].le_id[j],
                        "mob_no": userManagementList[i].mob_no,
                        "u_cat_id": userManagementList[i].u_cat_id,
                        "u_type_id": userManagementList[i].u_type_id,
                        "unblock_days": userManagementList[i].unblock_days,
                        "user_id": userManagementList[i].user_id,
                        "user_name": userManagementList[i].user_name,
                        "cont_from": UserLegalDetails[j].cont_from,
                        "cont_to": UserLegalDetails[j].cont_to,
                        "l_cont_from": UserLegalDetails[j].l_cont_from,
                        "l_cont_to": UserLegalDetails[j].l_cont_to,
                        "v_cont_from": UserLegalDetails[j].v_cont_from,
                        "v_cont_to": UserLegalDetails[j].v_cont_to,
                        "vndr_user_category": userManagementList[i].vndr_user_category

                    }
                    temp.push(obj)
                }
            }
            console.log(temp, 'temp2324444444444444');
            setUsermanagementListData(temp)
        }
    }, [userManagementList])








    useEffect(() => {
        if (usermagementListData && usermagementListData.length > 0 && selectedEntity == 'All Legal Entity') {
            let uniqueObjArray = [...new Map(usermagementListData && usermagementListData.length > 0 && usermagementListData.map((item) => [item["le_id"], item])).values()];

            const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                return item.le_id
            })

            let tempArr = []
            for (let i in uniqueIds) {
                let filteredArr = _.filter(usermagementListData, { le_id: uniqueIds[i] })

                tempArr.push(filteredArr)
            }
            setFilteredArray(tempArr)

        }

    }, [usermagementListData])
    useEffect(() => {
        if (usermagementListData && usermagementListData.length > 0 && selectedEntity !== 'All Legal Entity') {
            // let data = _.uniqBy(userManagementList, function (e) {
            //     return e.user_id;
            // });
            // console.log(data, 'datadatadatadatadata');

            let temp = _.filter(usermagementListData, { le_id: entityid })
            console.log(temp, 'temptemptemptemp');
            setuserMAnagementDeails(temp)




        }

    }, [usermagementListData])
    useEffect(() => {
        if (localStorage.getItem('SelectedEntityid') != null) {
            setCurrentEntity(localStorage.getItem('SelectedEntityid'))
        }
    }, [localStorage.getItem('SelectedEntityid')])

    const homescreen = () => {
        historynew.push(homepage);        
        document.getElementById('homehover').style.color = "blue"
        // window.location.href = '/dashboard'
    }

    return (
        <>
            {/* <ScrollToTop smooth color="red" component={<Tooltip title="Back to top"><UpOutlined /></Tooltip>} /> */}
            <div className='back-to-top'>
                <ScrollButton />
            </div>
            {existingUser == true ?
                <div id='page-wrapper' className="page-wrapper">
                    <div className="page-titles pb-2 pt-2">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 align-self-center">
                                {/* <nav aria-label="breadcrumb">
                                {AddType == 'add' ?
                                    <ol className="breadcrumb mb-0 d-flex align-items-center">
                                        <li className="breadcrumb-item">
                                            <span className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                        </li>
                                        <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                            <span style={{ fontSize: '16px' }}>Master</span>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large' }}>
                                            <span>Add user Management</span>
                                        </li>
                                    </ol> : <ol className="breadcrumb mb-0 d-flex align-items-center">
                                        <li className="breadcrumb-item">
                                            <span className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                        </li>
                                        <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                            <span style={{ fontSize: '16px' }}>Master</span>
                                        </li>
                                        <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                            <span >User Management</span>
                                          
                                        </li>
                                    </ol>}
                            </nav> */}

                            </div>
                            <div
                                className="
                            col-lg-6 col-md-6 col-sm-6 col-xs-6
                d-none d-md-flex
                align-items-center
                justify-content-end">

                                <div className="wrapper" style={{ marginRight: '-191px' }}>

                                    {/* <Button type="primary" shape="round" style={{ marginRight: '10px' }}
                                    icon={<FilterOutlined />} size='default' onClick={() => {
                                        setFilterModal(true)
                                    }} >
                                    Filter
                                </Button> */}

                                    <Tooltip title='Back'><Button type="primary" shape="round" style={{ marginRight: '10px' }}
                                        icon={<ArrowLeftOutlined />} size='default' onClick={() => {
                                            setExistingUser(false)
                                        }} >
                                        Back
                                    </Button></Tooltip>


                                    <button onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black  full-mode fullscreenradius" style={{ marginRight: '195px' }} >
                                        <Tooltip placement="left" title={sessionStorage.getItem('isFullScreen') == 'true' ? "Exit Full Screen Mode" : "Full Screen Mode"}><FullscreenOutlined /></Tooltip>
                                    </button>


                                </div>

                            </div>
                        </div>



                        <Card style={{ marginTop: "6px", borderRadius: "15px" }} className='userCard'>
                            {newUser == false ?
                                <Card className='userCard vendorCard' title="Replace User" style={{ borderRadius: "15px" }} headStyle={{ fontWeight: 'bold', borderTopRightRadius: "15px", borderTopLeftRadius: '15px', height: '10px', background: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ', color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }}>
                                    <Card style={{ borderRadius: '11px' }}>
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <label><b>Employee Code:</b> {existingUserData && existingUserData.emp_code}</label>
                                                </div>
                                                <div className="col-md-4">
                                                    <label><b>Employee Name:</b> {existingUserData && existingUserData.emp_name}</label>
                                                </div>
                                                <div className="col-md-4">
                                                    <label><b>Email Id:</b> {existingUserData && existingUserData.email_id}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                    <Card style={{ marginTop: '1%', borderRadius: '11px' }}>
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label><b>Replace User With: <span style={{ "color": "red" }}>*</span></b></label>&nbsp;&nbsp;
                                                    <Radio.Group
                                                        onChange={(e) => {
                                                            setUserType(e.target.value)
                                                        }}
                                                    //  value={value}
                                                    >
                                                        <Radio value={"new"}>New User</Radio>
                                                        <Radio value={"existing"}>Existing User</Radio>
                                                    </Radio.Group>

                                                </div>
                                                <div className="col-md-6">
                                                    <label><b>Products:</b> <span style={{ "color": "red" }}>*</span></label>&nbsp;&nbsp;
                                                    {((existingUserData && existingUserData.u_cat_id !== null) && (existingUserData.is_disable == 0) && (moment(existingUserData.cont_to).isSameOrAfter(date))) ?
                                                        <Checkbox checked={replaceproduct.compfie == true ? true : singleProduct.length == 1 ? true : false} disabled={userType == '' ? true : false} onChange={(e) => {
                                                            if (singleProduct.length == 1) {
                                                                setReplaceProduct({
                                                                    ...replaceproduct,
                                                                    compfie: true,

                                                                })
                                                            }
                                                            else {
                                                                if (e.target.checked == true) {
                                                                    setReplaceProduct({
                                                                        ...replaceproduct,
                                                                        compfie: true,

                                                                    })
                                                                    setReplaceUserValue({
                                                                        ...replaceUserValue,
                                                                        compfie: existingUserData.u_cat_id,

                                                                    })
                                                                }
                                                                else {
                                                                    setReplaceProduct({
                                                                        ...replaceproduct,
                                                                        compfie: false,

                                                                    })
                                                                    setExistingId(0)
                                                                    setReplaceUserValue({
                                                                        ...replaceUserValue,
                                                                        compfie: '',

                                                                    })
                                                                }
                                                            }

                                                        }}>Compfie {existingUserData && existingUserData.u_cat_id == 2 ? "(View Only)" : existingUserData && existingUserData.u_cat_id == 3 ? "(Legal Entity Admin)" : existingUserData && existingUserData.u_cat_id == 4 ? "(Domain Admin)" : existingUserData && existingUserData.u_cat_id == 5 ? "(Client Executive)" : existingUserData && existingUserData.u_cat_id == 6 ? "(Service Provider)" : existingUserData && existingUserData.u_cat_id == 7 ? "(View Only Resticted)" : existingUserData && existingUserData.u_cat_id == 1 ? "(Litigation User)" : '(Not Access)'}</Checkbox> : false}
                                                    {((existingUserData && existingUserData.vndr_user_category !== null) && (existingUserData.is_disable == 0) && ((moment(existingUserData.v_cont_to).isSameOrAfter(date)) || ((existingUserData.v_cont_to == null)))) ?
                                                        <Checkbox checked={replaceproduct.compfie_vendor == true ? true : singleProduct.length == 1 ? true : false} disabled={userType == '' ? true : false} onChange={(e) => {
                                                            if (singleProduct.length == 1) {
                                                                setReplaceProduct({
                                                                    ...replaceproduct,
                                                                    compfie_vendor: true,

                                                                })
                                                            }
                                                            else {
                                                                if (e.target.checked == true) {
                                                                    setReplaceProduct({
                                                                        ...replaceproduct,
                                                                        compfie_vendor: true,

                                                                    })
                                                                    setReplaceUserValue({
                                                                        ...replaceUserValue,
                                                                        compfie_vendor: existingUserData.vndr_user_category,

                                                                    })
                                                                }
                                                                else {
                                                                    setReplaceProduct({
                                                                        ...replaceproduct,
                                                                        compfie_vendor: false,

                                                                    })
                                                                    setExistingId(0)
                                                                    setReplaceUserValue({
                                                                        ...replaceUserValue,
                                                                        compfie_vendor: '',

                                                                    })
                                                                }
                                                            }

                                                        }}>Compfie Vendor {existingUserData && existingUserData.vndr_user_category == 2 ? "(View Only)" : existingUserData && existingUserData.vndr_user_category == 3 ? "(Legal Entity Admin)" : existingUserData && existingUserData.vndr_user_category == 4 ? "(Domain Admin)" : existingUserData && existingUserData.vndr_user_category == 5 ? "(Client Executive)" : existingUserData && existingUserData.vndr_user_category == 6 ? "(Service Provider)" : existingUserData && existingUserData.vndr_user_category == 7 ? "(View Only Resticted)" : existingUserData && existingUserData.vndr_user_category == 1 ? "(Litigation User)" : '(Not Access)'}</Checkbox> : false}
                                                    {((existingUserData && existingUserData.u_type_id !== null) && (existingUserData.is_disable == 0) && ((moment(existingUserData.l_cont_to).isSameOrAfter(date)) || (existingUserData.l_cont_to == null))) ?
                                                        <Checkbox checked={replaceproduct.compie_litigation == true ? true : singleProduct.length == 1 ? true : false} disabled={userType == '' ? true : false} onChange={(e) => {
                                                            if (singleProduct.length == 1) {
                                                                setReplaceProduct({
                                                                    ...replaceproduct,
                                                                    compie_litigation: true,

                                                                })
                                                            }

                                                            else {
                                                                if (e.target.checked == true) {
                                                                    setReplaceProduct({
                                                                        ...replaceproduct,
                                                                        compie_litigation: true,

                                                                    })
                                                                    setReplaceUserValue({
                                                                        ...replaceUserValue,
                                                                        compfie_litigation: existingUserData.u_type_id,

                                                                    })
                                                                }
                                                                else {
                                                                    setReplaceProduct({
                                                                        ...replaceproduct,
                                                                        compie_litigation: false,

                                                                    })
                                                                    setExistingId(0)
                                                                    setReplaceUserValue({
                                                                        ...replaceUserValue,
                                                                        compfie_litigation: '',

                                                                    })
                                                                }
                                                            }


                                                        }}>Compfie Litigation {existingUserData && existingUserData.u_type_id == 2 ? "(Creator)" : existingUserData && existingUserData.u_type_id == 3 ? '(user)' : ''}</Checkbox> : false}
                                                </div>

                                            </div>
                                        </div>
                                    </Card>
                                    <div className="div">
                                        {userType == '' ? false : userType == 'new' ? <Button type="primary" shape="round" style={{ marginTop: '10px', marginLeft: '42%' }}
                                            icon={<PlusCircleOutlined />} size='default' onClick={() => {
                                                if (((replaceproduct.compfie == true) || (replaceproduct.compfie_vendor == true) || (replaceproduct.compie_litigation == true))) {
                                                    setNewUser(true)
                                                    const Editpayload = [
                                                        authtoken,
                                                        {
                                                            "session_token": authtoken,
                                                            "request": [
                                                                "UserManagementEditView",
                                                                {
                                                                    "user_id": existingUserData && existingUserData.user_id
                                                                }
                                                            ]

                                                        }
                                                    ]
                                                    getById({
                                                        payload: Editpayload,
                                                        paramid: paramid,

                                                    })
                                                }
                                                else {
                                                    Toaster.error("Please select atleast one Product")
                                                }
                                            }} >
                                            Create New User
                                        </Button>
                                            :
                                            <>
                                                <Table id='service-user-priv'
                                                    locale={{ emptyText: <b style={{ color: 'black' }}>No Records Found</b> }} pagination={false} className={localStorage.getItem("currentTheme")} dataSource={((replaceproduct.compfie == true) || (replaceproduct.compie_litigation == true) || (replaceproduct.compfie_vendor == true)) ? existingTableRecord : existingTableData} columns={existingTableRecord.length > 0 ? columns1 : columns} style={{ marginTop: '1%' }} />
                                                {existingId !== 0 ?
                                                    <Button shape="round" className='addbutton' style={{ marginLeft: '45%', background: "#198754", borderColor: "#198754", marginTop: '1%' }}
                                                        icon={<PlayCircleOutlined />} size='default'
                                                        onClick={ReplaceExistingUser
                                                        }
                                                    >
                                                        Submit
                                                    </Button> : ''}
                                            </>


                                        }
                                    </div>
                                </Card> : <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card servcardround">
                                            <div className="card-body" style={{ padding: "5px 15px" }}>
                                                <div className="col-md-12" id={localStorage.getItem('currentTheme') + 'ed'}>


                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <div className="form-group">

                                                                    <label htmlFor=""><b>Employee Name</b></label>  <span style={{ "color": "red" }}>*</span>
                                                                    <div className="position-relative">
                                                                        <Input type="text" autocomplete="off" className='form-control' name="emp_name" placeholder='Enter Employee Name'
                                                                            onChange={(e) => {
                                                                                setUserDetails({
                                                                                    ...userDetails,
                                                                                    emp_name: e.target.value
                                                                                })
                                                                            }}
                                                                            id="" value={userDetails.emp_name || undefined}
                                                                            onKeyPress={(event) => {
                                                                                if (!/^[A-Za-z  ]+$/.test(event.key)) {
                                                                                    event.preventDefault();
                                                                                }
                                                                            }}
                                                                            maxlength='50'
                                                                            style={{
                                                                                marginTop: '5px',
                                                                                //  borderColor: errorField1 === true && userDetails.emp_name !== '' ? '#87d068' : errorField1 === false && userDetails.emp_name !== '' ? '#fc4b6c' : ''
                                                                            }} />
                                                                        {/* {userDetails.emp_name !== '' && errorField1 === true ?
                                                                <i style={{ top: '7px', right: '7px' }} className="ri-check-line position-absolute text-success fs-5 fw-bold"></i> :
                                                                userDetails.emp_name !== '' && errorField1 === false ?
                                                                    <i style={{ top: '7px', right: '7px' }} className="ri-close-line position-absolute text-danger fs-5 fw-bold"></i> : ''
                                                            } */}
                                                                    </div>
                                                                </div>
                                                                {validator.current.message(
                                                                    'emp_name',
                                                                    userDetails.emp_name,
                                                                    ['required', { regex: /^[A-Za-z ]+$/ }],
                                                                    {
                                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                        messages: {
                                                                            required: 'Employee Name Required',
                                                                            regex: 'Enter valid name'
                                                                        }
                                                                    })}
                                                            </div>
                                                            <div className='col-md-4'>
                                                                <div className="form-group">
                                                                    <label htmlFor=""><b>Employee ID</b></label>  <span style={{ "color": "red" }}>*</span>
                                                                    <div className="position-relative">
                                                                        <Input type="text" maxlength='20' autocomplete="off" placeholder='Enter Employee ID' className='form-control'
                                                                            onChange={(e) => {
                                                                                setUserDetails({
                                                                                    ...userDetails,
                                                                                    emp_id: e.target.value
                                                                                })
                                                                            }}
                                                                            // onChange={EmpIdOnChange} 

                                                                            name="emp_id" id="" value={userDetails.emp_id}
                                                                            style={{
                                                                                marginTop: '5px',
                                                                                //    borderColor: errorField2 === true && userDetails.emp_id != '' ? '#87d068' : errorField2 === false && userDetails.emp_id != '' ? '#fc4b6c' : '' 
                                                                            }} />
                                                                        {/* {userDetails.emp_id !== '' && errorField2 === true ?
                                                                <i style={{ top: '7px', right: '7px' }} className="ri-check-line position-absolute text-success fs-5 fw-bold"></i> :
                                                                userDetails.emp_id !== '' && errorField2 === false ?
                                                                    <i style={{ top: '7px', right: '7px' }} className="ri-close-line position-absolute text-danger fs-5 fw-bold"></i> : ''
                                                            } */}
                                                                    </div>
                                                                </div>
                                                                {validator.current.message(
                                                                    'emp_id',
                                                                    userDetails.emp_id,
                                                                    'required',
                                                                    {
                                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                        messages: {
                                                                            required: 'Employee ID Required',
                                                                        }
                                                                    })}
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label htmlFor=""><b>Email ID</b></label>  <span style={{ "color": "red" }}>*</span>
                                                                    <div className="position-relative">
                                                                        <Input type="text" autocomplete="off"
                                                                            // onChange={EmailOnChange}
                                                                            onChange={(e) => {
                                                                                setUserDetails({
                                                                                    ...userDetails,
                                                                                    email: e.target.value
                                                                                })
                                                                            }}

                                                                            style={{
                                                                                marginTop: '5px',
                                                                                //  borderColor: errorField === true && userDetails.email !== '' ? '#87d068' : errorField === false && userDetails.email !== '' ? '#fc4b6c' : ''
                                                                            }}
                                                                            placeholder='Enter Email ID' className='form-control' name="email" id="" value={userDetails.email} />
                                                                        {/* {errorField === true && userDetails.email !== '' ? <i style={{ top: '7px', right: '7px' }} className="ri-check-line position-absolute text-success fs-5 fw-bold"></i> : errorField === false && userDetails.email !== '' ? <i style={{ top: '7px', right: '7px' }} className="ri-close-line position-absolute text-danger fs-5 fw-bold"></i> : ''} */}
                                                                    </div>
                                                                </div>
                                                                {validator.current.message(
                                                                    'email',
                                                                    userDetails.email,
                                                                    ['required', { regex: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/ }],
                                                                    {
                                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                        messages: {
                                                                            required: 'Email Id Required',
                                                                            regex: 'Enter valid email'
                                                                        }
                                                                    })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 mt-2">
                                                        <div className="row">


                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label htmlFor=""><b>Mobile No</b></label>  <span style={{ "color": "red" }}>*</span>
                                                                    {/* <Input.Group size="default" style={{ marginTop: "5px" }} > */}
                                                                    <Row gutter={10} style={{ marginTop: "5px" }}>
                                                                        <Col span={5}>
                                                                            <Input type="text" className='form-control' autocomplete="off" placeholder='+91'
                                                                                // onKeyDown={blockInvalidChar}
                                                                                onChange={(e) => {
                                                                                    setUserDetails({
                                                                                        ...userDetails,
                                                                                        mobile_no_country_code: e.target.value
                                                                                    })
                                                                                }}
                                                                                value={userDetails.mobile_no_country_code} name='mobile_no_country_code'
                                                                                // onChange={countryOnChange}
                                                                                onKeyPress={(event) => {
                                                                                    if (!/[0-9 +]/.test(event.key)) {
                                                                                        event.preventDefault();
                                                                                    }
                                                                                }}
                                                                                maxlength='4'
                                                                                style={{
                                                                                    // borderColor: errorField3 === true && userDetails.mobile_no_country_code !== '' ? '#87d068' : errorField3 === false && userDetails.mobile_no_country_code !== '' ? '#fc4b6c' : ''
                                                                                }}
                                                                            />
                                                                            [+91]

                                                                            {validator.current.message(
                                                                                'mobile_no_country_code',
                                                                                userDetails.mobile_no_country_code,
                                                                                ['required', { regex: /^\+(\d{1}\-)?(\d{1,4})$/ }],
                                                                                {
                                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                                    messages: {
                                                                                        required: 'Enter Country Code for Mobile No.',
                                                                                        regex: 'Please enter valid country code'
                                                                                    }
                                                                                })}
                                                                        </Col>

                                                                        <Col span={19}>
                                                                            <div className="position-relative">
                                                                                <Input type="text" max={10} className='form-control' autocomplete="off"
                                                                                    // onKeyDown={blockInvalidChar} 
                                                                                    placeholder='9876543210' value={userDetails.mobile_no} name='mobile_no'
                                                                                    //  onChange={mobileOnChange}
                                                                                    onChange={(e) => {
                                                                                        setUserDetails({
                                                                                            ...userDetails,
                                                                                            mobile_no: e.target.value
                                                                                        })
                                                                                    }}
                                                                                    onKeyPress={(event) => {
                                                                                        if (!/[0-9]/.test(event.key)) {
                                                                                            event.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                    maxlength='10'
                                                                                    style={{
                                                                                        // borderColor: errorField4 === true && userDetails.mobile_no !== '' ? '#87d068' : errorField4 === false && userDetails.mobile_no !== '' ? '#fc4b6c' : '' 
                                                                                    }}
                                                                                />
                                                                                [9876543210]
                                                                                {/* {userDetails.mobile_no !== '' && errorField4 === true ?
                                                                        <i style={{ top: '5px', right: '7px' }} className="ri-check-line position-absolute text-success fs-5 fw-bold"></i> :
                                                                        userDetails.mobile_no !== '' && errorField4 === false ?
                                                                            <i style={{ top: '5px', right: '7px' }} className="ri-close-line position-absolute text-danger fs-5 fw-bold"></i> : ''
                                                                    } */}
                                                                            </div>
                                                                            {validator.current.message(
                                                                                'mobile_no',
                                                                                userDetails.mobile_no,
                                                                                ['required', { regex: /^[0-9\b]+$/ }, `min:10`, `max:10`],
                                                                                {
                                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                                    messages: {
                                                                                        required: 'Mobile Number Required',
                                                                                        min: 'Mobile Number should be 10 Numbers',
                                                                                        max: 'Mobile Number should be 10 Numbers'
                                                                                    }
                                                                                })}
                                                                        </Col>
                                                                    </Row>
                                                                    {/* </Input.Group> */}

                                                                </div>
                                                            </div>

                                                            <div className='col-md-4'>
                                                                <div className="form-group">
                                                                    <label htmlFor=""><b>Contact No</b></label>
                                                                    <Input.Group size="default" style={{ marginTop: "5px" }} >
                                                                        <Row gutter={8}>
                                                                            <Col span={5}>
                                                                                <Input type="text" className='form-control' autocomplete="off" placeholder='+00' name='emp_contact_country_code' value={userDetails.emp_contact_country_code}
                                                                                    // onChange={onUserDetailOnChange}
                                                                                    onKeyPress={(event) => {
                                                                                        if (!/[0-9 +]/.test(event.key)) {
                                                                                            event.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                    onChange={(e) => {
                                                                                        setUserDetails({
                                                                                            ...userDetails,
                                                                                            emp_contact_country_code: e.target.value
                                                                                        })
                                                                                    }}
                                                                                    maxlength='4'
                                                                                />
                                                                                &nbsp;[+91]
                                                                                {validator.current.message(
                                                                                    'emp_contact_country_code',
                                                                                    userDetails.emp_contact_country_code,
                                                                                    ((userDetails.emp_contact_code == '') && (userDetails.emp_contact_no == '')) ? [{ regex: /^\+(\d{1}\-)?(\d{1,4})$/ }] : ['required', { regex: /^\+(\d{1}\-)?(\d{1,4})$/ }],
                                                                                    {
                                                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                                        messages: {
                                                                                            regex: 'Enter valid country code',
                                                                                            required: 'Please Enter Country Code for Contact No'
                                                                                        }
                                                                                    })}
                                                                            </Col>
                                                                            <Col span={5}>
                                                                                <Input type="text" className='form-control' autocomplete="off" placeholder='000' name='emp_contact_code' value={userDetails.emp_contact_code}
                                                                                    //  onChange={onUserDetailOnChange}
                                                                                    onKeyPress={(event) => {
                                                                                        if (!/[0-9]/.test(event.key)) {
                                                                                            event.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                    onChange={(e) => {
                                                                                        setUserDetails({
                                                                                            ...userDetails,
                                                                                            emp_contact_code: e.target.value
                                                                                        })
                                                                                    }}
                                                                                    maxlength='3'
                                                                                />
                                                                                [452]
                                                                                {validator.current.message(
                                                                                    'emp_contact_code',
                                                                                    userDetails.emp_contact_code,
                                                                                    ((userDetails.emp_contact_country_code == '') && (userDetails.emp_contact_no == '')) ? [{ regex: /^(\+\d{1,3}[- ]?)?\d{3}$/ }] : ['required', { regex: /^(\+\d{1,3}[- ]?)?\d{3}$/ }],
                                                                                    {
                                                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                                        messages: {
                                                                                            regex: 'Enter valid input',
                                                                                            required: 'Please Enter Area Code for Contact No'
                                                                                        }
                                                                                    })}
                                                                            </Col>
                                                                            <Col span={14}>
                                                                                <Input type="text" autocomplete="off" className='form-control' placeholder='1234567890' name='emp_contact_no' value={userDetails.emp_contact_no}
                                                                                    //  onChange={onUserDetailOnChange}
                                                                                    onKeyPress={(event) => {
                                                                                        if (!/[0-9]/.test(event.key)) {
                                                                                            event.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                    onChange={(e) => {
                                                                                        setUserDetails({
                                                                                            ...userDetails,
                                                                                            emp_contact_no: e.target.value
                                                                                        })
                                                                                    }}
                                                                                    maxlength='10'
                                                                                />
                                                                                [1234567890]
                                                                                {validator.current.message(
                                                                                    'emp_contact_no',
                                                                                    userDetails.emp_contact_no,
                                                                                    ((userDetails.emp_contact_country_code == '') && (userDetails.emp_contact_code == '')) ? [{ regex: /^(\+\d{1,3}[- ]?)?\d{10}$/ }] : ['required', { regex: /^(\+\d{1,3}[- ]?)?\d{10}$/ }],
                                                                                    {
                                                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                                        messages: {
                                                                                            regex: 'Enter valid mobile number',
                                                                                            required: 'Please Enter Contact Number'
                                                                                        }
                                                                                    })}
                                                                            </Col>
                                                                        </Row>
                                                                    </Input.Group>

                                                                </div>
                                                            </div>
                                                            {/* <div className="col-md-4">


                                                                <div className="col-md-3">

                                                                    <label htmlFor=""><b>Remarks</b></label> <span style={{ "color": "red" }}>*</span>


                                                                    <Input type="text" autocomplete="off" className='form-control' onChange={onUserDetailOnChange}
                                                                        // disabled={true}
                                                                        // value='OTP feature has been switched off'
                                                                        name="remarks" id="" style={{ marginTop: '5px', width: '100%' }} />
                                                                    <span className="ms-1 text-info d-flex 
                                align-items-center justify-content-center" style={{ float: 'right' }}
                                                                    >
                                                                        <PlusCircleOutlined />
                                                                    </span>


                                                                </div>
                                                                <div className="col-md-1">
                                                                    {/* <div className="form-group"> */}
                                                            {/* <label htmlFor=""><b>OTP</b> </label> <span style={{ "color": "red" }}>*</span> <br />

                                                                    <input type="radio" /> */}
                                                            {/* </div> */}
                                                            {/* {validator.current.message(
                                                                            'otp',
                                                                            userDetails.otp,
                                                                            'required',
                                                                            {
                                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                                messages: {
                                                                                    required: 'OTP Required',
                                                                                }
                                                                            })} */}
                                                            {/* </div>

                                                            </div> */}
                                                            {userDetails.otp == 1 ?
                                                                <>
                                                                    <div className="col-md-1">
                                                                        <label htmlFor=""><b>OTP</b> </label> <span style={{ "color": "red" }}>*</span> <br />
                                                                        <Radio.Group
                                                                            //  onChange={onChange} 
                                                                            value={userDetails.otp} name='otp' style={{ marginTop: "6px" }} >
                                                                            <Radio value={1}>On</Radio>
                                                                            {/* <Radio value={0} >Off</Radio> */}
                                                                        </Radio.Group>
                                                                    </div>
                                                                    <div className="col-md-3">
                                                                        <label htmlFor=""><b>Remarks</b></label> <span style={{ "color": "red" }}>*</span>
                                                                        <Input type="text" autocomplete="off" placeholder='Enter Remarks' className='form-control'
                                                                            // onChange={onUserDetailOnChange}
                                                                            // disabled={true}
                                                                            value={userDetails.remarks}
                                                                            name="remarks" id="" style={{ marginTop: '5px', width: '100%' }} />
                                                                        <span className="ms-1 text-info d-flex 
align-items-center justify-content-center" style={{ float: 'right' }}
                                                                        //  onClick={() => { showRemarksModal() }}
                                                                        >
                                                                            <PlusCircleOutlined />
                                                                        </span>
                                                                    </div>
                                                                </>
                                                                : false}

                                                            <div className={"col-md-12"} style={{ marginTop: "15px" }} >
                                                                <Tabs type="card"
                                                                    className={`${localStorage.getItem("currentTheme") + 's5'}`}
                                                                    onChange={(key) => {
                                                                        setTab(key)
                                                                    }}

                                                                    //  onChange={() => {
                                                                    //     setCategory({
                                                                    //         compfie: '',
                                                                    //         compie_litigation: '',
                                                                    //         compfie_vendor: ''
                                                                    //     })
                                                                    // }}
                                                                    activeKey={tab}

                                                                >
                                                                    {tabValue.compfie == true && replaceproduct.compfie == true ?
                                                                        <TabPane

                                                                            // disabled={tabValue.compfie == false ? true : false}
                                                                            // style={{ backgroundColor: 'green' }}
                                                                            tab={
                                                                                <span style={{ padding: "0px 30px", fontSize: "16px" }}>


                                                                                    <FeatherIcon icon="check-circle" className={'pointer'} style={{ color: 'green', marginBottom: '-1%' }} size='19'

                                                                                    />

                                                                                    <span style={{ marginBottom: '2%' }}><b> Compfie</b></span>
                                                                                </span>}

                                                                            key="1">
                                                                            <div className="col-md-12">
                                                                                <div className="row">
                                                                                    <div className="col-md-4">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor=""><b>Category</b> <span style={{ "color": "red" }}>*</span> </label>
                                                                                            <Select
                                                                                                style={{ width: '100%', marginTop: '5px' }}
                                                                                                // showSearch
                                                                                                // optionFilterProp="children"
                                                                                                // disabled={unit.legal_entity === "" ? true : false}
                                                                                                // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                                                allowClear={true}
                                                                                                size="default"
                                                                                                placeholder="Enter Category"
                                                                                                disabled={true}
                                                                                                // onChange={categoryOnChange}

                                                                                                value={compfie.category}
                                                                                            // disabled={tabValue.compfie == false || ((AddType == 'edit') && (GetUserDetails && GetUserDetails.ul_userDetails[0].is_access_ct == 1)) ? true : false}
                                                                                            >
                                                                                                {userManagementDetails && userManagementDetails.um_user_category && userManagementDetails.um_user_category.length > 0 && userManagementDetails.um_user_category.map((item, i) => {

                                                                                                    return (
                                                                                                        <Option key={item.u_c_id}>
                                                                                                            {item.u_c_name}
                                                                                                        </Option>


                                                                                                    )


                                                                                                })}


                                                                                            </Select>


                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="col-md-4">
                                                                                        <label htmlFor=""><b>User Group</b> <span style={{ "color": "red" }}>*</span> </label><br />
                                                                                        <Select
                                                                                            allowClear={false}
                                                                                            size="default"
                                                                                            placeholder="Enter User Group"
                                                                                            disabled={true}
                                                                                            value={compfie.user_Group || undefined}
                                                                                            style={{ width: '100%', marginTop: '5px' }}
                                                                                        // disabled={tabValue.compfie == false ? true : false}
                                                                                        >
                                                                                            {usergroupData && usergroupData.map((item, i) => {
                                                                                                return (
                                                                                                    <Option key={item.u_g_id}>
                                                                                                        {item.u_g_name}
                                                                                                    </Option>
                                                                                                );
                                                                                            })}

                                                                                        </Select>
                                                                                        {/* {tabValue.compfie == true ? (Formvalidator.current.message(
                                                                                'usergroup',
                                                                                compfie.user_Group,
                                                                                ['required'],
                                                                                {
                                                                                    className: `invalid-feedback ${categoryFormSubmit ? 'show' : 'hide'}`,
                                                                                    messages: {
                                                                                        required: 'User Group Required',
                                                                                        // regex: 'Please enter valid mobile number'
                                                                                    }
                                                                                })) : ''} */}
                                                                                        {/* <select name="cars" className='form-control' id="cars">
                                                                            <option value="volvo">None Selected</option>
                                                                          </select> */}
                                                                                    </div>



                                                                                    <div className="col-md-4">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor=""><b>User Level</b> <span style={{ "color": "red" }}>*</span> </label>
                                                                                            <Select className='form-control' placeholder='Enter User Level'
                                                                                                value={compfie.user_level ? compfie.user_level : undefined}
                                                                                                allowClear={true}
                                                                                                disabled={true}
                                                                                                style={{ marginTop: "5px", width: '100%' }}

                                                                                            >
                                                                                                <Option value="1">Level 1 (Low)</Option>
                                                                                                <Option value="2">Level 2</Option>
                                                                                                <Option value="3">Level 3</Option>
                                                                                                <Option value="4">Level 4</Option>
                                                                                                <Option value="5">Level 5</Option>
                                                                                                <Option value="6">Level 6</Option>
                                                                                                <Option value="7">Level 7</Option>
                                                                                                <Option value="8">Level 8</Option>
                                                                                                <Option value="9">Level 9</Option>
                                                                                                <Option value="10">Level 10 (High)</Option>
                                                                                            </Select>
                                                                                        </div>
                                                                                    </div>

                                                                                    {category.compfie == '6' ? <div className="col-md-4">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor=""><b>Service Provider</b> <span style={{ "color": "red" }}>*</span> </label><br />
                                                                                            <Select
                                                                                                // showSearch
                                                                                                // optionFilterProp="children"
                                                                                                // disabled={unit.legal_entity === "" ? true : false}
                                                                                                // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                                                allowClear={true}
                                                                                                size="default"
                                                                                                placeholder="Enter Service Provider"
                                                                                                // onChange={categoryOnChange}
                                                                                                disabled={true}


                                                                                                value={compfie.serviceProvider || undefined}
                                                                                                style={{ width: '100%', marginTop: '5px' }}
                                                                                            // disabled={tabValue.compfie == false ? true : false}
                                                                                            >
                                                                                                {userManagementDetails && userManagementDetails.um_service_providers.map((item, i) => {
                                                                                                    return (
                                                                                                        <Option key={item.u_sp_id}>
                                                                                                            {item.u_sp_name}
                                                                                                        </Option>
                                                                                                    );
                                                                                                })}
                                                                                            </Select>
                                                                                            {/* {tabValue.compfie == true ? (Formvalidator.current.message(
                                                                                            'serviceProvider',
                                                                                            compfie.serviceProvider,
                                                                                            ['required'],
                                                                                            {
                                                                                                className: `invalid-feedback ${categoryFormSubmit ? 'show' : 'hide'}`,
                                                                                                messages: {
                                                                                                    required: 'Service Provider Required',
                                                                                                    // regex: 'Please enter valid mobile number'
                                                                                                }
                                                                                            })) : ''} */}
                                                                                        </div>
                                                                                    </div> : false}


                                                                                </div>


                                                                            </div>
                                                                            {/* <Divider /> */}
                                                                            <h5 style={{ marginTop: "15px" }}><b>Settings</b></h5>
                                                                            <div className="col-md-12">
                                                                                <div className="row">
                                                                                    <div className="col-md-4" style={{ marginBottom: "5px" }}>
                                                                                        <label htmlFor=""><b>Business Group</b></label><br />
                                                                                        <Tooltip title={bgTooltip.length > 0 ? bgTooltip : ''}>
                                                                                            <Select
                                                                                                style={{ marginTop: '5px' }}
                                                                                                name="units"
                                                                                                id="units"
                                                                                                disabled={true}
                                                                                                className='form-control'
                                                                                                // disabled={compfie.category == null ? true : false}
                                                                                                placeholder="Enter Business Group"

                                                                                                // disabled={tabValue.compfie == false ? true : false}
                                                                                                // disabled={domainValue && domainValue.length <= 0 ? true : false}
                                                                                                mode="multiple"
                                                                                                value={businessGroupValue || undefined}
                                                                                                maxTagCount='responsive'
                                                                                            >
                                                                                                {businessData && businessData.length > 0 &&
                                                                                                    businessData.map((item) => {

                                                                                                        return (

                                                                                                            <Option key={item.bg_id}>
                                                                                                                {item.bg_name}
                                                                                                            </Option>

                                                                                                        )

                                                                                                    }

                                                                                                    )}

                                                                                            </Select>
                                                                                        </Tooltip>
                                                                                    </div>
                                                                                    <div className="col-md-4" style={{ marginBottom: "5px" }}>

                                                                                        <label htmlFor=""><b>Legal Entity</b> <span style={{ "color": "red" }}>*</span> </label><br />

                                                                                        <Tooltip title={entityTooltip.length > 0 ? entityTooltip : ''}>
                                                                                            <Select
                                                                                                style={{ marginTop: '5px' }}
                                                                                                // disabled={compfie.category == null ? true : false}
                                                                                                placeholder='Enter Legal Entity'
                                                                                                name="units"
                                                                                                id="units"
                                                                                                className='form-control'
                                                                                                disabled={true}
                                                                                                // onDeselect={(e) => {
                                                                                                //     setActiveUnit(false)
                                                                                                //     if (AddType == 'edit') {
                                                                                                //         console.log(e, 'eeeeeeeeeee');
                                                                                                //         const Editpayload = [
                                                                                                //             authtoken,
                                                                                                //             {
                                                                                                //                 "session_token": authtoken,
                                                                                                //                 "request": [
                                                                                                //                     "HaveCompliancesLE",
                                                                                                //                     {
                                                                                                //                         "le_id": e,
                                                                                                //                         "user_id": details.user_id,
                                                                                                //                         "product_id": "1"
                                                                                                //                     }
                                                                                                //                 ]

                                                                                                //             }
                                                                                                //         ]
                                                                                                //         selectHaveCompliance({
                                                                                                //             payload: Editpayload,
                                                                                                //             paramid: paramid,

                                                                                                //         })
                                                                                                //         if (entityValue && entityValue.value.length == 0) {
                                                                                                //             setdomainValue([])
                                                                                                //         }
                                                                                                //     }

                                                                                                // }}
                                                                                                // onChange={(option, value) => compfieEntityHandelChange(option, value)}
                                                                                                mode="multiple"
                                                                                                value={entityValue || undefined}
                                                                                                maxTagCount='responsive'
                                                                                            >
                                                                                                {entityData && entityData.length > 0 &&
                                                                                                    entityData.map((item) => {

                                                                                                        return (

                                                                                                            <Option key={item.le_id}>
                                                                                                                {item.le_name}
                                                                                                            </Option>

                                                                                                        )

                                                                                                    }

                                                                                                    )}

                                                                                            </Select>

                                                                                        </Tooltip>
                                                                                    </div>
                                                                                    {category.compfie == '5' || category.compfie == '4' || category.compfie == '6' || category.compfie == '2' || category.compfie == '7' ?
                                                                                        <div className="col-md-4" style={{ marginBottom: "5px" }}>
                                                                                            <label htmlFor=""><b>Domain</b> <span style={{ "color": "red" }}>*</span> </label><br />
                                                                                            <Tooltip title={domainTooltip.length > 0 ? domainTooltip : ''}>
                                                                                                <Select
                                                                                                    style={{ marginTop: '5px' }}

                                                                                                    placeholder='Enter Domain'
                                                                                                    name="units"
                                                                                                    id="units"
                                                                                                    disabled={true}
                                                                                                    className='form-control'

                                                                                                    mode="multiple"
                                                                                                    value={domainValue || undefined}
                                                                                                    maxTagCount='responsive'
                                                                                                >
                                                                                                    {domaindata && domaindata.length > 0 &&
                                                                                                        domaindata.map((item) => {

                                                                                                            return (

                                                                                                                <Option key={item.u_dm_id}>
                                                                                                                    {item.u_dm_name}
                                                                                                                </Option>

                                                                                                            )

                                                                                                        }

                                                                                                        )}
                                                                                                    {/* {domainData && domainData.length > 1 ? <> <Option key="all" value="all" style={{ display: domainselectcheckbox }}>---SELECT ALL---</Option>
                                                                                            <Option key="unselect" value="unselect" style={{ display: domainunselectcheckbox }}>--UNSELECT ALL--</Option></> : false}



                                                                                        {DomainListData && DomainListData.length > 0 &&
                                                                                            DomainListData.map((finalList_unitlist) => {
                                                                                                if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist && finalList_unitlist.options.length > 0)
                                                                                                    return (
                                                                                                        <OptGroup label={finalList_unitlist.label}>
                                                                                                            {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                                                                                <Option value={`${subUnitData.le_id}-${subUnitData.u_dm_id}`}>{subUnitData.u_dm_name}</Option>

                                                                                                            ))}
                                                                                                        </OptGroup>
                                                                                                    )
                                                                                            })} */}
                                                                                                </Select>

                                                                                            </Tooltip>
                                                                                        </div>
                                                                                        : ""}


                                                                                </div>
                                                                            </div>

                                                                            {category.compfie == '2' || category.compfie == '7' ?
                                                                                <div className="col-md-12">
                                                                                    {/* <div className="form-group"> */}
                                                                                    <div style={{ display: 'flex' }}>
                                                                                        <label htmlFor=""><b>All Units Accessible</b></label><br />
                                                                                        <input type="checkbox" name="" id="" style={{ width: "40px", height: "20px" }}
                                                                                            checked={unitAccess == true ? true : false}
                                                                                            disabled={true} />
                                                                                    </div>
                                                                                    {/* </div> */}
                                                                                </div> :
                                                                                <div className="col-md-12">
                                                                                    <div className="row">
                                                                                        {category.compfie == '5' || category.compfie == '6' ?

                                                                                            <div className="col-md-4" >
                                                                                                <label htmlFor=""><b>Division</b></label><br />
                                                                                                <Tooltip placement='topRight' title={divisionTooltip.length > 0 ? divisionTooltip : ''}>
                                                                                                    <Select
                                                                                                        style={{ marginTop: '5px' }}
                                                                                                        name="units"
                                                                                                        id="units"
                                                                                                        className='form-control'
                                                                                                        placeholder="Enter Division"
                                                                                                        disabled={true}

                                                                                                        mode="multiple"

                                                                                                        value={DivisionValue || undefined}
                                                                                                        maxTagCount='responsive'
                                                                                                    >
                                                                                                        {divisionData && divisionData.length > 0 &&
                                                                                                            divisionData.map((item) => {

                                                                                                                return (

                                                                                                                    <Option key={item.d_id}>
                                                                                                                        {item.d_name}
                                                                                                                    </Option>

                                                                                                                )

                                                                                                            }

                                                                                                            )}
                                                                                                        {/* {divisionData && divisionData.length > 1 ?
                                                                                                <>
                                                                                                    <Option key="all" value="all" style={{ display: divisionselectcheckbox }}>---SELECT ALL---</Option>
                                                                                                    <Option key="unselect" value="unselect" style={{ display: divisionunselectcheckbox }}>--UNSELECT ALL--</Option>
                                                                                                </> : false}





                                                                                            {unitListData && unitListData.length > 0 &&
                                                                                                unitListData.map((finalList_unitlist) => {
                                                                                                    if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist && finalList_unitlist.options.length > 0)
                                                                                                        return (
                                                                                                            <OptGroup label={finalList_unitlist.label}>
                                                                                                                {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                                                                                    <Option value={subUnitData.d_id}>{subUnitData.d_name}</Option>

                                                                                                                ))}
                                                                                                            </OptGroup>
                                                                                                        )
                                                                                                })} */}
                                                                                                    </Select>
                                                                                                </Tooltip>
                                                                                            </div>
                                                                                            :
                                                                                            ''}




                                                                                        {category.compfie == '5' || category.compfie == '6' ?
                                                                                            <div className="col-md-4">
                                                                                                <label htmlFor=""><b>Category</b></label><br />

                                                                                                <Tooltip title={catTooltip.length > 0 ? catTooltip : ''}>
                                                                                                    <Select
                                                                                                        style={{ marginTop: '5px' }}
                                                                                                        // disabled={DivisionValue && DivisionValue.length <= 0 ? true : false}
                                                                                                        placeholder='Enter Category'
                                                                                                        name="units"
                                                                                                        id="units"
                                                                                                        disabled={true}
                                                                                                        className='form-control'

                                                                                                        mode="multiple"
                                                                                                        value={categoryValues || undefined}
                                                                                                        maxTagCount='responsive'
                                                                                                    >
                                                                                                        {categoryData && categoryData.length > 0 &&
                                                                                                            categoryData.map((item) => {

                                                                                                                return (

                                                                                                                    <Option key={item.cat_id}>
                                                                                                                        {item.cat_name}
                                                                                                                    </Option>

                                                                                                                )

                                                                                                            }

                                                                                                            )}
                                                                                                        {/* {categoryDatas && categoryDatas.length > 1 ?
                                                                                                    <>
                                                                                                        <Option key="all" value="all" style={{ display: categoryselectcheckbox }}>---SELECT ALL---</Option>
                                                                                                        <Option key="unselect" value="unselect" style={{ display: categoryunselectcheckbox }}>--UNSELECT ALL--</Option>
                                                                                                    </> : false}

                                                                                                {categoryList && categoryList.length > 0 &&
                                                                                                    categoryList.map((finalList_unitlist) => {
                                                                                                        if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist && finalList_unitlist.options.length > 0)
                                                                                                            return (
                                                                                                                <OptGroup label={finalList_unitlist.label}>
                                                                                                                    {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                                                                                        <Option value={subUnitData.cat_id}>{subUnitData.cat_name}</Option>

                                                                                                                    ))}
                                                                                                                </OptGroup>
                                                                                                            )
                                                                                                    })} */}
                                                                                                    </Select>
                                                                                                </Tooltip>
                                                                                            </div> : ''}
                                                                                    </div>
                                                                                </div>
                                                                            }

                                                                        </TabPane> : false}
                                                                    {tabValue.compfie_litigation == true && replaceproduct.compie_litigation == true ?
                                                                        <TabPane
                                                                            // disabled={tabValue.compfie_litigation == false ? true : false}
                                                                            tab={
                                                                                <span style={{ padding: "0px 30px", fontSize: "16px" }}>
                                                                                    <FeatherIcon icon="check-circle" className={'pointer'} style={{ color: 'green', marginBottom: '-1%' }} size='19'

                                                                                    />


                                                                                    <span style={{ marginBottom: '2%' }}><b> Compfie Litigation</b></span>
                                                                                </span>
                                                                            }
                                                                            key="2">
                                                                            <div className="col-md-12">
                                                                                <div className="row">
                                                                                    <div className="col-md-4">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor=""><b>User Category</b> <span style={{ "color": "red" }}>*</span> </label>
                                                                                            <Select className='form-control'
                                                                                                placeholder="Enter User Group"
                                                                                                value={compfieLitigation.category} disabled={true}
                                                                                                allowClear={true}

                                                                                            >

                                                                                                <Option key=''>Select</Option>
                                                                                                <Option key={2} value={2}>Creator</Option>
                                                                                                <Option key={3} value={3}>User</Option>
                                                                                            </Select>
                                                                                            {/* <select name="user_category" className='form-control' disabled={tabValue.compfie_litigation == false ? true : false} value={compfieLitigation.category} style={{ marginTop: '5px' }} onChange={(e) => {
                                                                                    setCompfieLitigation({
                                                                                        ...compfieLitigation,
                                                                                        category: e.target.value
                                                                                    })
                                                                                }} placeholder="Enter User Group">
                                                                                    <option value="">Select</option>
                                                                                    <option value="2">Creator</option>
                                                                                    <option value="3">User</option>
                                                                                </select> */}

                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor=""><b>User</b> <span style={{ "color": "red" }}>*</span></label>
                                                                                            <Select className='form-control'
                                                                                                placeholder="Enter User"
                                                                                                style={{ marginTop: '5px' }}
                                                                                                allowClear={true}
                                                                                                disabled={true}
                                                                                                // disabled={tabValue.compfie_litigation == false ? true : false}
                                                                                                value={compfieLitigation.user ? compfieLitigation.user : undefined}
                                                                                            // onChange={(data) => {
                                                                                            //     setActiveUnit(false)
                                                                                            //     if (data !== undefined) {
                                                                                            //         setCompfieLitigation({
                                                                                            //             ...compfieLitigation,
                                                                                            //             user: data
                                                                                            //         })
                                                                                            //     }
                                                                                            //     else {
                                                                                            //         setCompfieLitigation({
                                                                                            //             ...compfieLitigation,
                                                                                            //             user: ""
                                                                                            //         })
                                                                                            //     }


                                                                                            //     if (data == "Service Provider User") {
                                                                                            //         let vendorCategory = _.filter(userManagementDetails && userManagementDetails.um_user_category.length > 0 && userManagementDetails.um_user_category, { u_c_name: data })
                                                                                            //         setVendorCategory(vendorCategory)
                                                                                            //     }
                                                                                            //     //   setVendorCategories(category)
                                                                                            // }}
                                                                                            >
                                                                                                <Option value={null}>Select</Option>
                                                                                                <Option value='Inhouse User'>Inhouse User</Option>
                                                                                                <Option value='Service Provider User'>Service Provider User</Option>
                                                                                            </Select>

                                                                                        </div>
                                                                                    </div>

                                                                                    {compfieLitigation.user == 'Service Provider User' ? <div className="col-md-4">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor=""><b>Service Provider</b> <span style={{ "color": "red" }}>*</span> </label><br />
                                                                                            <Select
                                                                                                disabled={true}
                                                                                                allowClear={true}
                                                                                                size="default"
                                                                                                placeholder="Enter Service Provider"
                                                                                                // onChange={categoryOnChange}

                                                                                                // value={filter.seller}
                                                                                                style={{ width: '100%', marginTop: '5px' }}
                                                                                            // disabled={tabValue.compfie_litigation == false ? true : false}
                                                                                            >
                                                                                                {/* {userManagementDetails && userManagementDetails.um_service_providers.map((item, i) => {
                                                                                        return (
                                                                                            <Option key={item.u_sp_id}>
                                                                                                {item.u_sp_name}
                                                                                            </Option>
                                                                                        );
                                                                                    })} */}
                                                                                            </Select>
                                                                                            {/* {tabValue.compfie_litigation == true ? (Formvalidator.current.message(
                                                                                    'mobile_no',
                                                                                    compfieLitigation.serviceProvider,
                                                                                    'required',
                                                                                    {
                                                                                        className: `invalid-feedback ${categoryFormSubmit ? 'show' : 'hide'}`,
                                                                                        messages: {
                                                                                            required: 'Service Provider Required',
                                                                                            // regex: 'Please enter valid mobile number'
                                                                                        }
                                                                                    })) : ''} */}
                                                                                        </div>
                                                                                    </div>

                                                                                        : ''}
                                                                                </div>
                                                                            </div>{/* <Divider /> */}
                                                                            <h5 style={{ marginTop: "15px" }}><b>Settings</b></h5>
                                                                            <div className="col-md-12">
                                                                                <div className="row">
                                                                                    <div className="col-md-4" style={{ marginBottom: "5px" }}>
                                                                                        <label htmlFor=""><b>Business Group</b></label><br />
                                                                                        <Tooltip title={litigationBgTooltip.length > 0 ? litigationBgTooltip : ''}>
                                                                                            <Select

                                                                                                style={{ marginTop: '5px' }}
                                                                                                name="units"
                                                                                                id="units"
                                                                                                className='form-control'
                                                                                                placeholder="Enter Business Group"
                                                                                                disabled={true}
                                                                                                // disabled={compfieLitigation.category == null ? true : false}
                                                                                                // disabled={tabValue.compfie_litigation == false ? true : false}

                                                                                                // disabled={domainValue && domainValue.length <= 0 ? true : false}
                                                                                                mode="multiple"
                                                                                                value={lititigationbusinessGroupValue || undefined}
                                                                                                maxTagCount='responsive'
                                                                                            >
                                                                                                {litigationbusinessData && litigationbusinessData.length > 0 &&
                                                                                                    litigationbusinessData.map((item) => {

                                                                                                        return (

                                                                                                            <Option key={item.bg_id}>
                                                                                                                {item.bg_name}
                                                                                                            </Option>

                                                                                                        )

                                                                                                    }

                                                                                                    )}
                                                                                            </Select>
                                                                                        </Tooltip>
                                                                                    </div>
                                                                                    <div className="col-md-4" style={{ marginBottom: "5px" }}>

                                                                                        <label htmlFor=""><b>Legal Entity</b> <span style={{ "color": "red" }}>*</span> </label><br />

                                                                                        <Tooltip title={litigationEntityTooltip.length > 0 ? litigationEntityTooltip : ''}>
                                                                                            <Select
                                                                                                style={{ marginTop: '5px' }}
                                                                                                disabled={true}
                                                                                                placeholder='Enter Legal Entity'
                                                                                                name="units"
                                                                                                id="units"
                                                                                                className='form-control'

                                                                                                mode="multiple"
                                                                                                value={litigationEntityValue || undefined}
                                                                                                maxTagCount='responsive'
                                                                                            // tagRender={litigationEntityValue.length > 1 ? true : false}

                                                                                            >
                                                                                                {litigationentityData && litigationentityData.length > 0 &&
                                                                                                    litigationentityData.map((item) => {

                                                                                                        return (

                                                                                                            <Option key={item.le_id}>
                                                                                                                {item.le_name}
                                                                                                            </Option>

                                                                                                        )

                                                                                                    }

                                                                                                    )}



                                                                                            </Select>
                                                                                        </Tooltip>
                                                                                    </div>
                                                                                    <div className="col-md-4" style={{ marginBottom: "5px" }}>
                                                                                        <label htmlFor=""><b>Domain</b> < span style={{ "color": "red" }}>*</span> </label><br />
                                                                                        {/* <select name="cars" className='form-control' id="cars">
                                                                                <option value="volvo">None Selected</option>
                                                                            </select> */}
                                                                                        {/* <Select
                                                                                disabled={tabValue.compfie_litigation == false ? true : false}
                                                                                // showSearch
                                                                                // optionFilterProp="children"
                                                                                // disabled={unit.legal_entity === "" ? true : false}
                                                                                // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                                allowClear={false}
                                                                                size="default"
                                                                                mode='multiple'
                                                                                placeholder="Enter Unit"
                                                                                onChange={(data) => handleChange(data)
                                                                                }
                                                                                defaultValue={compfieLitigation.Domain}
                                                                                style={{ width: '65%' }}
                                                                            >
                                                                                {domain && domain.map((item, i) => {
                                                                                    return (
                                                                                        <Option key={item.u_dm_id}>
                                                                                            {item.u_dm_name}
                                                                                        </Option>
                                                                                    );
                                                                                })}
                                                                            </Select> */}


                                                                                        {/* <MultiSelect
                                                                            style={{ width: '100%', marginTop: '5px' }}
                                                                            // selectedValues={GetUserDetails&&GetUserDetails.ul_user_units.length&&GetUserDetails.ul_user_units[0].u_unt_id}
                                                                            options={LitigationDomainoptions}
                                                                            value={LitigationDomainValue}
                                                                            onChange={setLitigationDomainValue}
                                                                            labelledBy="Select"
                                                                            disabled={tabValue.compfie_litigation == false ? true : false}

                                                                        /> */}
                                                                                        <Tooltip title={litigationDomainTooltip.length > 0 ? litigationDomainTooltip : ''}>
                                                                                            <Select
                                                                                                style={{ marginTop: '5px' }}
                                                                                                // disabled={litigationEntityValue && litigationEntityValue.length <= 0 ? true : false}
                                                                                                placeholder='Enter Domain'
                                                                                                name="units"
                                                                                                id="units"
                                                                                                className='form-control'
                                                                                                disabled={true}
                                                                                                mode="multiple"
                                                                                                value={litigationdomainValue.includes(undefined) == true ? undefined : litigationdomainValue}
                                                                                                maxTagCount='responsive'
                                                                                            >
                                                                                                {litigationdomaindata && litigationdomaindata.length > 0 &&
                                                                                                    litigationdomaindata.map((item) => {

                                                                                                        return (

                                                                                                            <Option key={item.u_dm_id}>
                                                                                                                {item.u_dm_name}
                                                                                                            </Option>

                                                                                                        )

                                                                                                    }

                                                                                                    )}
                                                                                                {/* {litigationDomainData && litigationDomainData.length > 1 ? <>
                                                                                <Option key="all" value="all" style={{ display: litigationdomainselectcheckbox }}>---SELECT ALL---</Option>
                                                                                <Option key="unselect" value="unselect" style={{ display: litigationdomainunselectcheckbox }}>--UNSELECT ALL--</Option>
                                                                            </> : false} */}

                                                                                                {/* {litigationDomainData && litigationDomainData.map((item, i) => {
                                                                     if (litigationEntityData && litigationEntityData.length > 0) {
                                                                         let data = _.find(litigationEntityData, { le_id: item.le_id })
                                                                         return (

                                                                             <OptGroup label={data && data.le_name}>
                                                                                 <Option key={item.u_dm_id}>
                                                                                     {item.u_dm_name}
                                                                                 </Option>
                                                                             </OptGroup>
                                                                         );
                                                                     }
                                                                     else {
                                                                         return (

                                                                             <Option key={item.u_dm_id}>
                                                                                 {item.u_dm_name}
                                                                             </Option>
                                                                         );
                                                                     }

                                                                 })} */}
                                                                                                {/* {LitigationDomainList && LitigationDomainList.length > 0 &&
                                                                                LitigationDomainList.map((finalList_unitlist) => {
                                                                                    if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist && finalList_unitlist.options.length > 0)
                                                                                        return (
                                                                                            <OptGroup label={finalList_unitlist.label}>
                                                                                                {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                                                                    <Option value={`${subUnitData.le_id}-${subUnitData.u_dm_id}`}>{subUnitData.u_dm_name}</Option>

                                                                                                ))}
                                                                                            </OptGroup>
                                                                                        )
                                                                                })} */}
                                                                                            </Select>
                                                                                        </Tooltip>
                                                                                    </div>
                                                                                    <div className="col-md-6"></div>
                                                                                </div>
                                                                            </div>
                                                                            <div>


                                                                            </div>

                                                                        </TabPane> : false}

                                                                    {tabValue.compfie_vendor == true && replaceproduct.compfie_vendor == true ?
                                                                        <TabPane
                                                                            // disabled={tabValue.compfie_vendor == false ? true : false}
                                                                            tab={
                                                                                <span style={{ padding: "0px 30px", fontSize: "16px" }}>
                                                                                    <FeatherIcon icon="check-circle" className={'pointer'} style={{ color: 'green', marginBottom: '-1%' }} size='19'
                                                                                    />


                                                                                    <span style={{ marginBottom: '2%' }}><b> Compfie Vendor</b></span>
                                                                                </span>
                                                                            }
                                                                            key="3">

                                                                            <div className="col-md-12">
                                                                                <div className="row">
                                                                                    <div className="col-md-4">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor=""><b>Category</b> <span style={{ "color": "red" }}>*</span> </label>
                                                                                            <br />
                                                                                            <Select
                                                                                                disabled={true}
                                                                                                // disabled={tabValue.compfie_vendor == false || ((AddType == 'edit') && (GetUserDetails && GetUserDetails.ul_userDetails[0].is_access_vndr == 1)) ? true : false}
                                                                                                allowClear={true}
                                                                                                size="default"
                                                                                                placeholder="Enter Category"
                                                                                                // onChange={categoryOnChange}

                                                                                                value={compfieVendorValue.category || undefined}
                                                                                                style={{ width: '100%', marginTop: '5px' }}
                                                                                            >
                                                                                                {userManagementDetails && userManagementDetails.um_user_category && userManagementDetails.um_user_category.length > 0 && userManagementDetails.um_user_category.map((item, i) => {
                                                                                                    return (
                                                                                                        <Option key={item.u_c_id}>
                                                                                                            {item.u_c_name}
                                                                                                        </Option>
                                                                                                    );
                                                                                                })}
                                                                                            </Select>


                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="col-md-4">
                                                                                        <label htmlFor=""><b>User Group</b></label><br />
                                                                                        <Select
                                                                                            // disabled={tabValue.compfie_vendor == false ? true : false}
                                                                                            // showSearch
                                                                                            // optionFilterProp="children"
                                                                                            // disabled={unit.legal_entity === "" ? true : false}
                                                                                            // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                                            allowClear={true}
                                                                                            size="default"
                                                                                            placeholder="Enter User Group"
                                                                                            disabled={true}
                                                                                            value={compfieVendorValue.user_Group || undefined}
                                                                                            style={{ width: '100%', marginTop: '5px' }}
                                                                                        >
                                                                                            {vendorusergroupData && vendorusergroupData.length > 0 && vendorusergroupData.map((item, i) => {
                                                                                                return (
                                                                                                    <Option key={item.u_g_id}>
                                                                                                        {item.u_g_name}
                                                                                                    </Option>
                                                                                                );
                                                                                            })}
                                                                                        </Select>

                                                                                    </div>

                                                                                    {category.compfie_vendor == '5' || category.compfie_vendor == '4' || category.compfie_vendor == '3' ? <div className="col-md-4">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor=""><b>User Level</b> <span style={{ "color": "red" }}>*</span> </label>
                                                                                            {/* <select name="" id="" className='form-control' onChange={(e) => {
                                                                                                setCompfieVendorValue({
                                                                                                    ...compfieVendorValue,
                                                                                                    user_level: e.target.value
                                                                                                })


                                                                                            }}
                                                                                                value={compfieVendorValue.user_level}
                                                                                                style={{ width: '100%', marginTop: '5px' }}  >
                                                                                                <option value="1">Level 1(Low)</option>
                                                                                                <option value="2">Level 2</option>
                                                                                                <option value="3">Level 3</option>
                                                                                                <option value="4">Level 4</option>
                                                                                                <option value="5">Level 5</option>
                                                                                                <option value="6">Level 6</option>
                                                                                                <option value="7">Level 7</option>
                                                                                                <option value="8">Level 8</option>
                                                                                                <option value="9">Level 9</option>
                                                                                                <option value="10">Level 10(High)</option>

                                                                                            </select> */}


                                                                                            <Select className='form-control' placeholder='Enter Userlevel'
                                                                                                allowClear={true}
                                                                                                disabled={true}
                                                                                                value={compfieVendorValue.user_level ? compfieVendorValue.user_level : undefined} style={{ marginTop: "5px", width: '100%' }}
                                                                                            >
                                                                                                <Option value="1">Level 1 (Low)</Option>
                                                                                                <Option value="2">Level 2</Option>
                                                                                                <Option value="3">Level 3</Option>
                                                                                                <Option value="4">Level 4</Option>
                                                                                                <Option value="5">Level 5</Option>
                                                                                                <Option value="6">Level 6</Option>
                                                                                                <Option value="7">Level 7</Option>
                                                                                                <Option value="8">Level 8</Option>
                                                                                                <Option value="9">Level 9</Option>
                                                                                                <Option value="10">Level 10 (High)</Option>
                                                                                            </Select>

                                                                                        </div>
                                                                                    </div> : ''}


                                                                                    {category.compfie_vendor == '6' ?

                                                                                        <div className="col-md-4">
                                                                                            <div className="form-group">
                                                                                                <label htmlFor=""><b>Service Provider</b></label> <span style={{ "color": "red" }}>*</span> <br />
                                                                                                <Select
                                                                                                    // showSearch
                                                                                                    // optionFilterProp="children"
                                                                                                    // disabled={unit.legal_entity === "" ? true : false}
                                                                                                    // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                                                    allowClear={true}
                                                                                                    size="default"
                                                                                                    placeholder="Enter Service Provider"
                                                                                                    // onChange={categoryOnChange}
                                                                                                    disabled={true}
                                                                                                    value={compfieVendorValue.serviceProvider || undefined}
                                                                                                    style={{ width: '100%', marginTop: '5px' }}
                                                                                                // disabled={tabValue.compfie_vendor == false ? true : false}
                                                                                                >
                                                                                                    {userManagementDetails && userManagementDetails.um_service_providers.map((item, i) => {
                                                                                                        return (
                                                                                                            <Option key={item.u_sp_id}>
                                                                                                                {item.u_sp_name}
                                                                                                            </Option>
                                                                                                        );
                                                                                                    })}
                                                                                                </Select>
                                                                                                {/* {tabValue.compfie_vendor == true ? (Formvalidator.current.message(
                                                                                        'serviceProvider',
                                                                                        compfieVendorValue.serviceProvider,
                                                                                        ['required'],
                                                                                        {
                                                                                            className: `invalid-feedback ${categoryFormSubmit ? 'show' : 'hide'}`,
                                                                                            messages: {
                                                                                                required: 'Service Provider Required',
                                                                                                // regex: 'Please enter valid mobile number'
                                                                                            }
                                                                                        })) : ''} */}
                                                                                            </div>
                                                                                        </div>

                                                                                        : ''}
                                                                                </div>
                                                                            </div>
                                                                            {/* <Divider /> */}
                                                                            <h5 style={{ marginTop: "15px" }}>Settings</h5>

                                                                            <div className="col-md-12" >
                                                                                <div className="row">
                                                                                    <div className="col-md-4" style={{ marginBottom: "5px" }}>
                                                                                        <label htmlFor=""><b>Business Group</b></label><br />
                                                                                        <Tooltip title={vendorBgTooltip.length > 0 ? vendorBgTooltip : ''}>
                                                                                            <Select
                                                                                                style={{ marginTop: '5px' }}
                                                                                                name="units"
                                                                                                id="units"
                                                                                                className='form-control'
                                                                                                placeholder="Enter Business Group"
                                                                                                // disabled={compfieVendorValue.category == null ? true : false}
                                                                                                // disabled={domainValue && domainValue.length <= 0 ? true : false}
                                                                                                mode="multiple"
                                                                                                value={vendorbusinessGroupValue || undefined}
                                                                                                maxTagCount='responsive'
                                                                                                disabled={true}
                                                                                            >
                                                                                                {vendorbusinessData && vendorbusinessData.length > 0 &&
                                                                                                    vendorbusinessData.map((item) => {

                                                                                                        return (

                                                                                                            <Option key={item.bg_id}>
                                                                                                                {item.bg_name}
                                                                                                            </Option>

                                                                                                        )

                                                                                                    }

                                                                                                    )}
                                                                                                {/* {vendorbusinessGroupData && vendorbusinessGroupData.length > 1 ?
                                                                                        <>
                                                                                            <Option key="all" value="all" style={{ display: vendorbusinessGroupselectcheckbox }}>---SELECT ALL---</Option>
                                                                                            <Option key="unselect" value="unselect" style={{ display: vendorbusinessGroupUnselectcheckbox }}>--UNSELECT ALL--</Option>
                                                                                        </> : false}


                                                                                    {vendorbusinessGroupData && vendorbusinessGroupData.length > 0 &&
                                                                                        vendorbusinessGroupData.map((item) => {
                                                                                            let data = _.find(EntityData, { le_id: item.le_id })
                                                                                            return (

                                                                                                <Option key={item.bg_id}>
                                                                                                    {item.bg_name}
                                                                                                </Option>

                                                                                            )

                                                                                        }

                                                                                        )} */}
                                                                                            </Select>
                                                                                        </Tooltip>
                                                                                        {/* <select name="cars" className='form-control' id="cars">
                                                                        <option value="volvo">None Selected</option>
                                                                    </select> */}
                                                                                    </div>

                                                                                    <div className="col-md-4">
                                                                                        <label htmlFor=""><b>Legal Entity</b> <span style={{ "color": "red" }}>*</span></label> <br />
                                                                                        {/* <Select
                                                                                        disabled={tabValue.compfie_vendor == false ? true : false}
                                                                                        // showSearch
                                                                                        // optionFilterProp="children"
                                                                                        // disabled={unit.legal_entity === "" ? true : false}
                                                                                        // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                                        allowClear={false}
                                                                                        size="default"
                                                                                        placeholder="Enter Unit"

                                                                                        onChange={(data) => {
                                                                                            setCompfieVendorValue({
                                                                                                ...compfieVendorValue,
                                                                                                legal_Entity: data
                                                                                            })

                                                                                            let division = _.filter(userManagementDetails && userManagementDetails.um_cmpf_group_division, { le_id: data })
                                                                                            setDivision(division)
                                                                                            let domain = _.filter(userManagementDetails && userManagementDetails.um_legal_domain, { le_id: data })
                                                                                            setDomain(domain)
                                                                                        }}
                                                                                        value={compfieVendorValue.legal_Entity}
                                                                                        style={{ width: '65%' }}
                                                                                    >
                                                                                        {EntityData && EntityData.map((item, i) => {
                                                                                            return (
                                                                                                <Option key={item.le_id}>
                                                                                                    {item.le_name}
                                                                                                </Option>
                                                                                            );
                                                                                        })}
                                                                                    </Select> */}

                                                                                        {/* <Select
                                                                                 
                                                                                        allowClear={false}
                                                                                        size="default"
                                                                                        placeholder="Enter Entity"
                                                                                        mode="multiple"
                                                                                        onChange={(data) => handledChangeVendor(data)}
                                                                                        defaultValue={compfieVendorValue.legal_Entity}
                                                                                        disabled={tabValue.compfie_vendor == false ? true : false}
                                                                                      
                                                                                        onChange={(data) => handledChangeVendor(data)
                                                                                        }
                                                                                        
                                                                                        style={{ width: '65%' }}
                                                                                    >

                                                                                        {EntityData && EntityData.map((item, i) => {
                                                                                            return (
                                                                                                <Option key={item.le_id}>
                                                                                                    {item.le_name}
                                                                                                </Option>
                                                                                            );
                                                                                        })}
                                                                                    </Select> */}
                                                                                        {/* <MultiSelect
                                                                                    style={{ width: '100%', marginTop: '5px' }}
                                                                                    // selectedValues={GetUserDetails&&GetUserDetails.ul_user_units.length&&GetUserDetails.ul_user_units[0].u_unt_id}
                                                                                    options={VendorEntityOption}
                                                                                    value={VendorEntity}
                                                                                    onChange={setVendorEntity}
                                                                                    labelledBy="Enter Legal Entity"
                                                                                    disabled={tabValue.compfie_vendor == false ? true : false}

                                                                                /> */}
                                                                                        <Tooltip title={vendorEntityTooltip.length > 0 ? vendorEntityTooltip : ''}>
                                                                                            <Select
                                                                                                style={{ marginTop: '5px' }}
                                                                                                // disabled={compfieVendorValue.category == null ? true : false}
                                                                                                placeholder='Enter Legal Entity'
                                                                                                name="units"
                                                                                                id="units"
                                                                                                disabled={true}
                                                                                                className='form-control'

                                                                                                mode="multiple"
                                                                                                value={vendoreEntityValue || undefined}
                                                                                                maxTagCount='responsive'
                                                                                            >
                                                                                                {vendorentityData && vendorentityData.length > 0 &&
                                                                                                    vendorentityData.map((item) => {

                                                                                                        return (

                                                                                                            <Option key={item.le_id}>
                                                                                                                {item.le_name}
                                                                                                            </Option>

                                                                                                        )

                                                                                                    }

                                                                                                    )}
                                                                                                {/* {vendorEntityData && vendorEntityData.length > 1 ? <>
                                                                                        <Option key="all" value="all" style={{ display: VendorEntityselectcheckbox }}>---SELECT ALL---</Option>
                                                                                        <Option key="unselect" value="unselect" style={{ display: VendorEntityunselectcheckbox }}>--UNSELECT ALL--</Option>
                                                                                    </> : false} */}
                                                                                                {/* 
                                                                                {vendorEntityData && vendorEntityData.length > 0 && vendorEntityData.map((item, i) => {
                                                                                    if (vendorbusinessGroupData && vendorbusinessGroupData.length > 0) {
                                                                                        let data = _.find(vendorbusinessGroupData, { bg_id: item.bg_id })
                                                                                        return (
                                                                                            <OptGroup label={data && data.bg_name}>
                                                                                                <Option key={item.le_id}>
                                                                                                    {item.le_name}
                                                                                                </Option>
                                                                                            </OptGroup>
                                                                                        );
                                                                                    }
                                                                                    else {
                                                                                        return (
                                                                                            <Option key={item.le_id}>
                                                                                                {item.le_name}
                                                                                            </Option>
                                                                                        );
                                                                                    }

                                                                                })} */}


                                                                                                {/* {vendorEntityList && vendorEntityList.length > 0 ?
                                                                                        vendorEntityList && vendorEntityList.length > 0 &&
                                                                                        vendorEntityList.map((finalList_unitlist) => {
                                                                                            if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist && finalList_unitlist.options.length > 0)
                                                                                                return (
                                                                                                    <OptGroup label={finalList_unitlist.label}>
                                                                                                        {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                                                                            <Option value={subUnitData.le_id}>{subUnitData.le_name}</Option>

                                                                                                        ))}
                                                                                                    </OptGroup>
                                                                                                )
                                                                                        }) : <> {vendorEntityData && vendorEntityData.length > 0 ? <OptGroup label="Others">
                                                                                        </OptGroup> : false}{vendorEntityData && vendorEntityData.map((item, i) => {
                                                                                            return (

                                                                                                <Option key={item.le_id}>
                                                                                                    {item.le_name}
                                                                                                </Option>

                                                                                            );
                                                                                        })}</>}
 */}



                                                                                            </Select>
                                                                                        </Tooltip>
                                                                                        {/* {tabValue.compfie_vendor == true ? (Formvalidator.current.message(
                                                                                'legal_Entity',
                                                                                compfieVendorValue.legal_Entity,
                                                                                ['required'],
                                                                                {
                                                                                    className: `invalid-feedback ${categoryFormSubmit ? 'show' : 'hide'}`,
                                                                                    messages: {
                                                                                        required: 'Legal Entity Required',
                                                                                        // regex: 'Please enter valid mobile number'
                                                                                    }
                                                                                })) : ''} */}
                                                                                        {/* <select name="cars" className='form-control' id="cars">
                                                                        <option value="volvo">None Selected</option>
                                                                    </select> */}
                                                                                    </div>



                                                                                    {category.compfie_vendor == '5' || category.compfie_vendor == '4' || category.compfie_vendor == '6' || category.compfie_vendor == '2' || category.compfie_vendor == '7' ?

                                                                                        <div className="col-md-4">
                                                                                            <label htmlFor=""><b>Domain</b> <span style={{ "color": "red" }}>*</span></label><br />
                                                                                            <Tooltip title={vendorDomainTooltip.length > 0 ? vendorDomainTooltip : ''}>
                                                                                                <Select
                                                                                                    style={{ marginTop: '5px' }}
                                                                                                    disabled={true}
                                                                                                    placeholder='Enter Domain'
                                                                                                    name="units"
                                                                                                    id="units"
                                                                                                    className='form-control'


                                                                                                    mode="multiple"
                                                                                                    value={vendordomainValue.includes(undefined) == true ? undefined : vendordomainValue}
                                                                                                    maxTagCount='responsive'
                                                                                                >
                                                                                                    {vendordomaindata && vendordomaindata.length > 0 &&
                                                                                                        vendordomaindata.map((item) => {

                                                                                                            return (

                                                                                                                <Option key={item.u_dm_id}>
                                                                                                                    {item.u_dm_name}
                                                                                                                </Option>

                                                                                                            )

                                                                                                        }

                                                                                                        )}

                                                                                                </Select>
                                                                                            </Tooltip>
                                                                                        </div>
                                                                                        : ''}
                                                                                </div>

                                                                            </div>
                                                                            <div className="col-md-12">
                                                                                <div className="row">


                                                                                    {category.compfie_vendor == '5' || category.compfie_vendor == '6' ?
                                                                                        <div className="col-md-4">
                                                                                            <label htmlFor=""><b>Division</b>  </label><br />
                                                                                            {/* <select name="cars" className='form-control' id="cars">
                                                                                <option value="volvo">None Selected</option>
                                                                            </select> */}
                                                                                            {/* <Select
                                                                                              
                                                                                                allowClear={false}
                                                                                                size="default"
                                                                                                placeholder="Enter Unit"
                                                                                                onChange={(data) => handleChanged(data)
                                                                                                }
                                                                                                mode='multiple'
                                                                                                defaultValue={compfieVendorValue.Domain}

                                                                                                style={{ width: '65%' }}
                                                                                            >
                                                                                                {domain && domain.map((item, i) => {
                                                                                                    return (
                                                                                                        <Option key={item.u_dm_id}>
                                                                                                            {item.u_dm_name}
                                                                                                        </Option>
                                                                                                    );
                                                                                                })}
                                                                                            </Select> */}
                                                                                            <Tooltip placement='topRight' title={vendordivisionTooltip.length > 0 ? vendordivisionTooltip : ''}>
                                                                                                <Select
                                                                                                    style={{ marginTop: '5px' }}
                                                                                                    name="units"
                                                                                                    id="units"
                                                                                                    className='form-control'
                                                                                                    placeholder="Enter Division"
                                                                                                    mode="multiple"
                                                                                                    disabled={true}
                                                                                                    value={vendorDivisionValue || undefined}
                                                                                                    maxTagCount='responsive'
                                                                                                >
                                                                                                    {vendordivisionData && vendordivisionData.length > 0 &&
                                                                                                        vendordivisionData.map((item) => {

                                                                                                            return (

                                                                                                                <Option key={item.d_id}>
                                                                                                                    {item.d_name}
                                                                                                                </Option>

                                                                                                            )

                                                                                                        }

                                                                                                        )}


                                                                                                    {/* {vendorListData && vendorListData.length > 0 &&
                                                                                            vendorListData.map((finalList_unitlist) => {
                                                                                                if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist && finalList_unitlist.options.length > 0)
                                                                                                    return (
                                                                                                        <OptGroup label={finalList_unitlist.label}>
                                                                                                            {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                                                                                <Option value={subUnitData.d_id}>{subUnitData.d_name}</Option>

                                                                                                            ))}
                                                                                                        </OptGroup>
                                                                                                    )
                                                                                            })} */}
                                                                                                </Select>
                                                                                            </Tooltip>
                                                                                        </div> : ""}
                                                                                    {category.compfie_vendor == '5' || category.compfie_vendor == '6' ?
                                                                                        <div className="col-md-4">
                                                                                            <label htmlFor=""><b>Category</b></label><br />
                                                                                            {/* <Select
                                                                                               
                                                                                                allowClear={false}
                                                                                                size="default"
                                                                                                placeholder="Enter Unit"

                                                                                                onChange={(data) => {
                                                                                                    setCompfieVendorValue({
                                                                                                        ...compfieVendorValue,
                                                                                                        categoryValue: data
                                                                                                    })
                                                                                                }}
                                                                                                value={compfieVendorValue.categoryValue}
                                                                                                style={{ width: '65%' }}
                                                                                                disabled={AddType == 'edit' ? true : false}
                                                                                            >
                                                                                                {categories && categories.map((item, i) => {
                                                                                                    return (
                                                                                                        <Option key={item.cat_id}>
                                                                                                            {item.cat_name}
                                                                                                        </Option>
                                                                                                    );
                                                                                                })}
                                                                                            </Select> */}
                                                                                            <Tooltip title={vendorcatTooltip.length > 0 ? vendorcatTooltip : ''}>
                                                                                                <Select
                                                                                                    style={{ marginTop: '5px' }}

                                                                                                    placeholder='Enter Category'
                                                                                                    name="units"
                                                                                                    id="units"
                                                                                                    disabled={true}
                                                                                                    className='form-control'

                                                                                                    mode="multiple"
                                                                                                    value={VendorcategoryValues || undefined}
                                                                                                    maxTagCount='responsive'
                                                                                                >
                                                                                                    {vendorcategoryData && vendorcategoryData.length > 0 &&
                                                                                                        vendorcategoryData.map((item) => {

                                                                                                            return (

                                                                                                                <Option key={item.cat_id}>
                                                                                                                    {item.cat_name}
                                                                                                                </Option>

                                                                                                            )

                                                                                                        }

                                                                                                        )}

                                                                                                    {/* {categoryDatas && categoryDatas.length > 0 && categoryDatas.map((item, i) => {
                                                                                    if (vendorEntityData && vendorEntityData.length > 0) {
                                                                                        let data = _.find(vendorEntityData, { le_id: item.le_id })
                                                                                        return (
                                                                                            <OptGroup label={data && data.le_name}>
                                                                                                <Option key={item.cat_id}>
                                                                                                    {item.cat_name}
                                                                                                </Option>
                                                                                            </OptGroup>
                                                                                        );
                                                                                    }
                                                                                    else {
                                                                                        return (
                                                                                            <Option key={item.cat_id}>
                                                                                                {item.cat_name}
                                                                                            </Option>
                                                                                        );
                                                                                    }

                                                                                })} */}
                                                                                                    {/* {vendorcategoryList && vendorcategoryList.length > 0 &&
                                                                                            vendorcategoryList.map((finalList_unitlist) => {
                                                                                                if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist && finalList_unitlist.options.length > 0)
                                                                                                    return (
                                                                                                        <OptGroup label={finalList_unitlist.label}>
                                                                                                            {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                                                                                <Option value={subUnitData.cat_id}>{subUnitData.cat_name}</Option>

                                                                                                            ))}
                                                                                                        </OptGroup>
                                                                                                    )
                                                                                            })} */}
                                                                                                </Select>
                                                                                            </Tooltip>
                                                                                            {/* <MultiSelect

                                                                                            // selectedValues={GetUserDetails&&GetUserDetails.ul_user_units.length&&GetUserDetails.ul_user_units[0].u_unt_id}
                                                                                            options={categoryoptions}
                                                                                            value={compfieCategory}
                                                                                            onChange={setcompfieCategory}
                                                                                            labelledBy="Select"
                                                                                            style={{ width: '100%', marginTop: '5px' }}
                                                                                        /> */}
                                                                                        </div>
                                                                                        : ''}
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-12">
                                                                                {category.compfie_vendor == '2' || category.compfie_vendor == '7' ?
                                                                                    <div className="col-md-6">
                                                                                        <div className="row">
                                                                                            <div className="col-md-5 order-0" style={{ display: 'flex' }}>

                                                                                                {/* <div className="form-group"> */}
                                                                                                <label htmlFor=""><b>All Vendor Accessible</b></label>
                                                                                                <input type="checkbox" name="" id="" style={{ width: "40px", height: "20px" }}
                                                                                                    disabled={true} checked={vendorAccess == true ? true : false}


                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                        {/* </div> */}

                                                                                    </div> : false}
                                                                            </div>
                                                                        </TabPane> : false}



                                                                </Tabs>
                                                                <Button shape="round" className='addbutton' style={{ background: "#198754", float: "right", borderColor: "#198754", marginTop: '1%' }}
                                                                    icon={<PlayCircleOutlined />} size='default'
                                                                    onClick={userDetailsOnSubmit
                                                                    }
                                                                >
                                                                    Submit
                                                                </Button>
                                                            </div>

                                                            {/* <div className="col-md-4 mt-2">
                                                                <div className="form-group">

                                                                    <label htmlFor=""><b>Remarks</b></label> <span style={{ "color": "red" }}>*</span>
                                                                    <div className="d-flex align-items-end">

                                                                        <Input type="text" autocomplete="off" className='form-control' onChange={onUserDetailOnChange}
                                                                            disabled={true}
                                                                            value='OTP feature has been switched off'
                                                                            name="remarks" id="" style={{ marginTop: '5px' }} />
                                                                        <span className="ms-1 text-info d-flex 
                                align-items-center justify-content-center" style={{ float: 'right' }}
                                                                        >
                                                                            <PlusCircleOutlined />
                                                                        </span>
                                                                    </div>
                                                                </div>

                                                            </div> */}

                                                        </div>
                                                    </div>





                                                </div>
                                            </div> </div> </div>
                                </div>}
                        </Card>


                    </div>
                </div > : <Usermanagement />}

        </>

    )
}
export default connect(mapStateToProps, {
    getUsermanagementList,
    updatestatus,
    activeStatus,
    GetRemarksData,
    disableStatusChange,
    haveCompliance,
    getUsermanagementDetails,
    LegalAdmindisableStatusChange,
    getById,
    replaceNewUser,
    resendEmail,
    replaceExistingUser
})(ReplaceUser)