import React from 'react'
import { Tooltip, Collapse, Select, Input, DatePicker, Card, Drawer, Button, Table, Progress, Modal, Pagination } from 'antd';
import DataTable from "react-data-table-component";
import { useState, useEffect, useRef, Fragment } from 'react';
import { DoubleLeftOutlined, PlayCircleOutlined, EyeOutlined, ExportOutlined, FullscreenOutlined, UpOutlined, FilterOutlined, FileTextOutlined, ExclamationCircleTwoTone, InfoCircleOutlined, InfoCircleTwoTone } from '@ant-design/icons';
import { getFilterValues, getUnitData, getTaskwiseReportData, getTaskWiseExportData, getRemarksFilter, getdownloaddocument } from '../../../Store/Action/Report/TaskWiseReport';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import SimpleReactValidator from "simple-react-validator";
import moment from 'moment';
import { fullscreenmode, fullscreenexit, handleKeyDown } from './../../../Libs/fullscreenmode';
import { validationType, validateSelect, onPasteToaster } from './../../../Libs/inputValidation';
import useStickyHeight from "../../../Libs/useStickyHeight"
import ScrollToTop from "react-scroll-to-top";
import ScrollButton from '../../Scrollbar';
import { Toaster } from '../../../Libs/Toaster';
import { CountrySplit, HomeRedirect } from './../../../Libs/country'
import { Downloadfile } from '../../../Store/Action/Transcations/ComplianceApproval';
import {
    getInterimrecords
} from "../../../../src/Store/Action/Report/ConsolidatedReports";
const uniq_country_data = CountrySplit()
const homepage = HomeRedirect()

const mapStateToProps = (state) => ({
    filterValue: state.TaskWiseReport.TaskWiseReports,
    interimdata: state.ConsolidatedReports.ConsolidatedReports.interimData
})
const TaskWiseReport = (({
    getFilterValues,
    filterValue,
    getTaskwiseReportData,
    getTaskWiseExportData,
    getInterimrecords,
    getUnitData,
    getRemarksFilter,
    getdownloaddocument,
    Downloadfile,
    interimdata

}) => {
    useStickyHeight();
    const historynew = useHistory();
    console.log(filterValue, "filterValue");
    const _ = require("lodash");
    let [index, setIndex] = useState(0)
    const [totalCount, setTotal] = useState(50)
    const validator = useRef(new SimpleReactValidator());
    const is_Fullscreen = useSelector((state) => state.Login.is_fullscreen);
    const dispatch = useDispatch();
    const [current, setCurrent] = useState(1);
    const [pageState, setpageState] = useState(false);
    const [pageSize, setPageSize] = useState(25)
    const [paginationArray, setPaginationArray] = useState([])
    const [countrydata, setCountryData] = useState(null)
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [tableshow, setTableShow] = useState(false)
    const [statkey, setStatKey] = useState("0")
    const [key, setKey] = useState("1")
    const [titledata, Settitledata] = useState([])
    const [domainValue, setDomainValue] = useState(false)
    const [domainFilter, setDomainFilter] = useState([])
    console.log(titledata, 'titledata')
    const [filteredArray, setFilteredArray] = useState([])
    const [entity, setEntity] = useState([])
    const [filteredArrayState, setfilteredArrayState] = useState([])
    const [exitCollapse, setExitCollapse] = useState(false);
    const [filterTaskAcc, setFilterTaskAcc] = useState(true);
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const [uploadModal, setUploadModal] = useState(false)
    const [modalData, setModalData] = useState({})
    const [documentName, setDocumentName] = useState([])
    const [approvalDocName, setApprovalDocName] = useState([])
    const [concurDocName, setConcurDocName] = useState([])
    const [commonArray, setCommonArray] = useState([])
    console.log(commonArray, 'commonArray')
    let [sno, setSNo] = useState(0)
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true
    })
    const [exportButton, setExportButton] = useState(false)
    const [consolidatedData, setConsolidatedData] = useState({
        country: "",
        country_history: '',
        category: "",
        user_type: "All",
        legel_entity: "",
        entity_name: '',
        legalentity_history: '',
        unit: '',
        user: "",
        domain: "",
        doamin_history: '',
        act: "",
        act_history: '',
        from_date: "",
        task_category: "",
        compliance_task: "",
        task_history: '',
        to_date: "",
        task_sub_category: "",
        compliance_frequency: "",
        compliance_task_status: "All",
        division: "",
        domainName: "",
        countryName: '',
        complianceName: "",
        countryLabel: '',
        legalEntityLabel: '',
        domainLabel: '',
        actLabel: '',
        complianceTaskLabel: ''

    })
    console.log(consolidatedData, 'consolidatedData')
    const [open, setOpen] = useState(false);
    const location = useLocation();
    const paramid = location.pathname;
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const sessionvalue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionvalue)
    const sessionArr = sessionParsedValue.entity_info
    const country_info = sessionParsedValue.country_info
    const [domain, setDomain] = useState([])
    const [finalResult, SetfinalResult] = useState({
        Resultdata: []
    })
    const [division, setDivision] = useState([])
    const [checkboxValue, setCheckBoxValue] = useState([1, 2, 7, 8, 9, 10, 31, 34])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [ComplianceTask, setComplianceTask] = useState([])
    const [complianceData, setComplianceData] = useState("")
    console.log(filteredArrayState, 'filteredArrayState');
    const [Act, setAct] = useState([])
    const [Acts, setActs] = useState([])
    const [complianceTaskStatus, setcomplianceTaskStatus] = useState([])
    const [user, setuser] = useState([])
    const [userType, setUserType] = useState([])
    console.log(user, 'useruseruser')
    const [frequency, setfrequency] = useState([])
    const [category, setCategoryList] = useState([])
    const [disableButton, setDisableButton] = useState(false)
    const [unitcode, setUnitcode] = useState([])
    const [units, setUnits] = useState([])
    const { Panel } = Collapse;
    const { Option } = Select;
    const [fullscreen, setfullscreen] = useState(false);
    const [isModalOpenChild, setModalOpenChild] = useState(false);
    const [selectSearchValue, setSelectSearchValue] = useState({
        country: '',
        legalEntity: '',
        domain: '',
        actRules: '',
        complianceTask: '',
        user: '',
        division: '',
        category: '',
        vendor: '',
    });

    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
            document.addEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch), false);
            return () => {
                document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch));
            };
        }
    }, [fullscreen]);
    const modalOnChane = () => {
        setUploadModal(false)
        setDocumentName([])
        setApprovalDocName([])
        setConcurDocName([])
    }

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode(dispatch);
            setfullscreen(true);
        } else {
            fullscreenexit(dispatch);
            setfullscreen(false);
        }
    }

    useEffect(() => {
        if (localStorage.getItem("SelectedEntity") !== "All Legal Entity") {
            let temp = localStorage.getItem("SelectedEntity")
            let tempcountry = _.filter(sessionArr, { le_name: temp })
            setConsolidatedData({
                ...consolidatedData,
                country: tempcountry && tempcountry.length > 0 ? tempcountry[0].c_id : '',
                countryName: tempcountry && tempcountry.length > 0 ? tempcountry[0].c_name : '',
                country_history: tempcountry && tempcountry.length > 0 ? tempcountry[0].c_name : ''
            })
        }
    }, [localStorage.getItem("SelectedEntity") !== "All Legal Entity"])

    const filterHandle = () => {
        setFilterTaskAcc(true);
        setFilterModalVisible(true);
    };
    const setcancelFilter = () => {
        setFilterModalVisible(false);
        setFilterTaskAcc(false);
    };

    useEffect(() => {

        if (filterValue && filterValue.TaskWiseReportList && filterValue.TaskWiseReportList.task_compliances && filterValue.TaskWiseReportList.task_compliances.length > 0) {

            var array = filterValue && filterValue.TaskWiseReportList.task_compliances.slice((current - 1) * pageSize, (current * pageSize))
            setPaginationArray(array)
        }
        else {
            setPaginationArray([])
        }

    }, [filterValue && filterValue.TaskWiseReportList.task_compliances, pageSize, current])


    const showModal = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid() || (consolidatedData.domain != '' && consolidatedData.act != '' && consolidatedData.compliance_task != '')) {
            setIsModalOpen(true);
        }
    };

    const handleOk = () => {
        const remarks = checkboxValue.includes(34);
        if (remarks) {
            const myArray = checkboxValue;
            const index = myArray.indexOf(34);
            const x = myArray.splice(index, 1);
            let status = myArray.includes(0);
            if (!status) {
                myArray.unshift(0);
            }
            setCheckBoxValue(myArray);
        }

        setAddFormSubmit(true)
        setExportButton(true)
        if (validator.current.allValid() || (consolidatedData.domain != '' && consolidatedData.act != '' && consolidatedData.compliance_task != '')) {
            // setTableShow(true)
            // setKey(0)
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetTaskWiseReport",
                        {
                            "country_id": Number(consolidatedData.country),
                            "legal_entity_id": entityid,
                            "domain_id": Number(consolidatedData.domain),
                            "division_id": consolidatedData.division ? Number(consolidatedData.division) : 0,
                            "category_id": consolidatedData.category ? Number(consolidatedData.category) : 0,
                            "unit_id": consolidatedData.unit ? Number(consolidatedData.unit) : 0,
                            // "statutory_mapping": consolidatedData.act,
                            // "comp_tsk_id": Number(consolidatedData.compliance_task),
                            "statutory_mapping": consolidatedData.act,
                            "comp_tsk_id": consolidatedData.compliance_task ? Number(consolidatedData.compliance_task) : 0,
                            "user_type": consolidatedData.user_type ? consolidatedData.user_type : null,
                            "user_id": consolidatedData.user ? Number(consolidatedData.user) : 0,
                            "due_from_date": consolidatedData.from_date ? moment(consolidatedData.from_date).format('DD-MMM-YYYY') : "",
                            "due_to_date": consolidatedData.to_date ? moment(consolidatedData.to_date._d).format('DD-MMM-YYYY') : "",
                            "task_status": consolidatedData.compliance_task_status ? consolidatedData.compliance_task_status : "All",
                            "csv": true,
                            "from_count": 0,
                            "page_count": 0,
                            "list_check": checkboxValue
                        }
                    ]
                }
            ]
            getTaskWiseExportData({
                payload: payload,
                paramid: paramid
            })
        }
        setIsModalOpen(false);
        setCheckBoxValue([1, 2, 7, 8, 9, 10, 31, 34])
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setCheckBoxValue([1, 2, 7, 8, 9, 10, 31, 34])
    };
    // const disablecondition = (e) => {
    //     var currentToDate = moment(consolidatedData.from_date).add(3, 'months')
    //     if (currentToDate < moment(e)) {
    //         setDisableButton(true)
    //     } else {
    //         setDisableButton(false)
    //     }
    // }

    const onshow = () => {
        setAddFormSubmit(true)

        if (validator.current.allValid() || (consolidatedData.domain != '' && consolidatedData.act != '' && consolidatedData.compliance_task != '')) {
            Settitledata([])
            setfilteredArrayState([])
            setConsolidatedData({
                ...consolidatedData,
                countryLabel: consolidatedData.countryName,
                domainLabel: consolidatedData.domainName,
                legalEntityLabel: consolidatedData.entity_name,
                actLabel: consolidatedData.act,
                complianceTaskLabel: consolidatedData.complianceName
            })
            setExitCollapse(true);
            setFilterTaskAcc(false);
            setFilterModalVisible(false);
            setIndex(0);
            setCurrent(1);
            setpageState(false)
            setPageSize(25)
            setTableShow(true)
            setKey(0)
            setStatKey(1)
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetTaskWiseReport",
                        {
                            "country_id": Number(consolidatedData.country),
                            "legal_entity_id": entityid,
                            "domain_id": Number(consolidatedData.domain),
                            "division_id": consolidatedData.division ? Number(consolidatedData.division) : 0,
                            "category_id": consolidatedData.category ? Number(consolidatedData.category) : 0,
                            "unit_id": consolidatedData.unit ? Number(consolidatedData.unit) : 0,
                            // "statutory_mapping": "The Payment of Gratuity Act, 1973  >>  The Kerala Payment of Gratuity Rules, 1973",
                            // "comp_tsk_id": 184,
                            "statutory_mapping": consolidatedData.act,
                            "comp_tsk_id": consolidatedData.compliance_task ? Number(consolidatedData.compliance_task) : 0,
                            "user_type": consolidatedData.user_type ? consolidatedData.user_type : null,
                            "user_id": consolidatedData.user ? Number(consolidatedData.user) : 0,
                            "due_from_date": consolidatedData.from_date ? moment(consolidatedData.from_date).format('DD-MMM-YYYY') : "",
                            "due_to_date": consolidatedData.to_date ? moment(consolidatedData.to_date._d).format('DD-MMM-YYYY') : "",
                            "task_status": consolidatedData.compliance_task_status ? consolidatedData.compliance_task_status : "All",
                            "csv": false,
                            "from_count": 0,
                            "page_count": 0,
                            "list_check": 0
                        }
                    ]
                }
            ]
            getTaskwiseReportData({
                payload: payload,
                paramid: paramid
            })
        }
    }

    useEffect(() => {
        if (filterValue.TaskWiseReportList.task_compliances && filterValue.TaskWiseReportList.task_compliances.length > 0) {
            let uniqueObjArray = [...new Map(filterValue && filterValue.TaskWiseReportList && filterValue.TaskWiseReportList.task_compliances && filterValue.TaskWiseReportList.task_compliances.length > 0 && filterValue.TaskWiseReportList.task_compliances.map((item) => [item["unit_id"], item])).values()];
            const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                return item.unit_id
            })
            let tempArr = []
            for (let i in uniqueIds) {
                let filteredArr = _.filter(filterValue && filterValue.TaskWiseReportList && filterValue.TaskWiseReportList.task_compliances.length && filterValue.TaskWiseReportList.task_compliances, { unit_id: uniqueIds[i] })

                tempArr.push(filteredArr)
            }
            setFilteredArray([...filteredArray, tempArr])

        }

    }, [filterValue && filterValue.TaskWiseReportList.task_compliances])

    const onClose = () => {
        setOpen(false);
    };
    const ComplianceTaskOnChange = (data, value) => {
        let taskData = _.find(ComplianceTask, { compliance_id: Number(data) })
        setComplianceData(taskData && taskData.compliance_description)
        if (data !== undefined) {
            setConsolidatedData({
                ...consolidatedData,
                compliance_task: data,
                complianceName: value.children,
                task_history: value.children
            })
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetTaskWiseActReportFilters",
                        {
                            "country_id": Number(consolidatedData.country),
                            "legal_entity_id": entityid,
                            "domain_id": Number(consolidatedData.domain),
                            "comp_tsk_id": Number(data)
                        }
                    ]
                }
            ]
            getUnitData({
                payload: payload,
                paramid: paramid
            })
        }
        else {
            setConsolidatedData({
                ...consolidatedData,
                compliance_task: '',
                complianceName: '',
            })
        }
    }

    useEffect(() => {
        if (filteredArray.length) {
            let temp = []
            for (let i in filteredArray) {
                temp = filteredArray[i]
            }
            setfilteredArrayState([...filteredArrayState, ...temp])
        }

    }, [filteredArray])
    const genextra = () => {
        return <div onClick={(event) => {
            // If you don't want click extra trigger collapse, you can prevent this:
            event.stopPropagation();
        }}>

            <Button type='primary' onClick={() => {
                setOpen(true)
            }}>
                <i class="bi bi-chevron-double-left"></i>
            </Button>


        </div>
    }


    useEffect(() => {
        if (entityid === null) {

            setCurrentEntity(consolidatedData.legel_entity)
        }
    }, [entityid])

    const onChange = (key) => {
        console.log(key);
    };

    useEffect(() => {
        if (localStorage.getItem('SelectedEntityid') != 'null') {
            setCurrentEntity(localStorage.getItem('SelectedEntityid'))
            const country = sessionParsedValue.country_info
            console.log(country, 'country')
            setCountryData(country)
            setConsolidatedData({
                ...consolidatedData,
                country: country[0].c_id,
                countryName: country[0].c_name,
                legel_entity: localStorage.getItem('SelectedEntityid'),
                country_history: country[0].c_name
            })
        }
    }, [localStorage.getItem('SelectedEntityid')])

    useEffect(() => {
        if (isAuth) {
            if (entityid !== '') {
                if (entityid != 'null') {
                    let temp = localStorage.getItem("SelectedEntity")
                    let tempcountry = _.filter(sessionArr, { le_name: temp })
                    const payload = [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "GetTaskWiseReportFilters",
                                {
                                    "country_id": consolidatedData.country ? Number(consolidatedData.country) : tempcountry && tempcountry[0].c_id,
                                    "legal_entity_id": entityid
                                }
                            ]
                        }
                    ]
                    getFilterValues({
                        payload: payload,
                        paramid: paramid
                    })
                }
            }
        }
    }, [isAuth, authtoken, paramid, entityid])
    useEffect(() => {
        validator.current.showMessages()
    }, [])

    const handlePasting = (e, regex) => {
        var regex = regex;
        var key = e.clipboardData.getData('text')
        if (!regex.test(key)) {
            e.preventDefault();
            Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
            return false;
        }
    }


    /*  useEffect(() => {
          var isDuplicate = function () {
              var helloWorld = paginationArray;
              var doubleValue = [];
              let childAray = []
              helloWorld = helloWorld.sort((a, b) => { return a - b });
              for (let i = 0; i < helloWorld.length; i++) {
                  childAray = _.filter(helloWorld, { compliance_history_id})
                  // if(doubleValue && doubleValue.length > 0){
                  //     let childAray = _.find(helloWorld, { compliance_history_id: parseInt(helloWorld[++i].compliance_history_id) })
                  //     // childAray = doubleValue.some(function (el) { return el.compliance_history_id === parseInt(helloWorld[++i].compliance_history_id); });
                  //     console.log(childAray,'childAraychildAray')
                  // }                
                  // // console.log(childAray,'childAraychildAray')
                  // doubleValue.push(childAray)
                  // if (helloWorld[i].compliance_history_id == helloWorld[++i].compliance_history_id) {
                  //     helloWorld[i].category_name = helloWorld[i]
                  //     doubleValue.push(helloWorld[i])
                  //     console.log(helloWorld[i],'compliance_history_id');
                  // } else {
                  //     helloWorld[i].category_name = []
                  // }
                  // console.log(helloWorld, 'doubleValue');
              }
  
              // let dupes = {};
              // const array = [
              //     { id: "1", name: "A", address: "A" },
              //     { id: "2", name: "A", address: "B" },
              //     { id: "3", name: "A", address: "C" },
              //     { id: "4", name: "B", address: "A" },
              //     { id: "5", name: "B", address: "B" },
              //     { id: "6", name: "C", address: "A" },
              //     { id: "7", name: "C", address: "B" },
              //     { id: "8", name: "C", address: "C" }];
              // array.forEach((item, index) => {
              //     dupes[item.name] = dupes[item.name] || [];
              //     dupes[item.name].push(index);
              // });
              // for (let name in dupes) console.log(name + '->indexes->' + dupes[name] + '->count->' + dupes[name].length)
  
          };
          isDuplicate();
  
  
      }, [paginationArray])*/


    useEffect(() => {
        // setTempeditdata(editdata)

        if (paginationArray && paginationArray.length > 0) {
            let expected_arr = [];
            for (let ijk = 0; ijk < paginationArray.length; ijk++) {
                let check_child = _.findIndex(expected_arr, { "compliance_history_id": paginationArray[ijk].compliance_history_id });
                if (check_child >= 0) {
                    expected_arr[check_child].child.push(paginationArray[ijk]);
                } else {
                    paginationArray[ijk].child = [];
                    expected_arr.push(paginationArray[ijk])
                }
            }
            Settitledata(expected_arr)

        }

    }, [paginationArray])

    const checkBoxOnChange = (e) => {
        let temp = checkboxValue
        let tempIds = []
        if (temp.includes(Number(e.target.value)) == true) {
            for (let i in temp) {
                if (temp[i] == Number(e.target.value)) {
                    temp.splice(i, 1)
                }
            }
            setCheckBoxValue([...temp])
        } else {
            tempIds.push(Number(e.target.value))
            setCheckBoxValue([...checkboxValue, ...tempIds])
        }
    }

    const selectAllCheckBox = (checked) => {
        var ele = document.getElementsByName("chk");
        let tempAllIds = [];
        if (checked == true) {
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == "checkbox") {
                    ele[i].checked = true;
                    tempAllIds.push(Number(ele[i].value));
                }
            }
            setCheckBoxValue(tempAllIds);
        } else {
            var ele = document.getElementsByName("chk");
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == "checkbox") ele[i].checked = false;
            }
            setCheckBoxValue([]);
        }
    };
    useEffect(() => {
        if (filterValue) {
            setUserType(filterValue && filterValue.unitData && filterValue.unitData.compliance_user_type)
            // setComplianceTask(filterValue && filterValue.Data && filterValue.Data.compliance_task_wise_list)
            setDomain(filterValue && filterValue.Data && filterValue.Data.domains)
            if (filterValue && filterValue.Data && filterValue.Data.tsk_act_legal_entity && filterValue.Data.tsk_act_legal_entity.length > 0) {
                let uniqueObjArray = [...new Map(filterValue && filterValue.Data && filterValue.Data.tsk_act_legal_entity && filterValue.Data.tsk_act_legal_entity.length > 0 && filterValue.Data.tsk_act_legal_entity.map((item) => [item["statutory_mapping"], item])).values()];
                setAct(uniqueObjArray)
            } else {
                setAct([])
            }
            setfrequency(filterValue && filterValue.Data && filterValue.Data.compliance_frequency)
            setcomplianceTaskStatus(filterValue && filterValue.unitData && filterValue.unitData.compliance_task_status)
            setUnitcode(filterValue && filterValue.Data && filterValue.Data.unit_code_label_list)
            if (filterValue && filterValue.unitData && filterValue.unitData.tsk_unit_legal_entity) {
                let unitUnique = _.uniqBy(filterValue.unitData.tsk_unit_legal_entity, function (e) {
                    return e.unit_id;
                });
                setUnits(unitUnique)
            }
            /*User Loading Initally*/
            if (filterValue && filterValue.unitData && filterValue.unitData.tsk_compliance_users) {
                let approverValue = []
                let approverValue1 = []
                for (let i = 0; i < filterValue.unitData.tsk_compliance_users.length; i++) {

                    if (_.findIndex(approverValue, { user_ida: filterValue.unitData.tsk_compliance_users[i].approval_person }) < 0) {
                        // console.log(filterValue.unitData.tsk_compliance_users[i].assignee_name,'filterValue.unitData.tsk_compliance_users')
                        approverValue.push({
                            'user_valuea': filterValue.unitData.tsk_compliance_users[i].approver_name,
                            'user_ida': filterValue.unitData.tsk_compliance_users[i].approval_person
                        })
                    }
                    if (filterValue.unitData.tsk_compliance_users[i].concurrence_person && _.findIndex(approverValue, { user_idc: filterValue.unitData.tsk_compliance_users[i].concurrence_person }) < 0) {
                        approverValue.push({
                            'user_valuec': filterValue.unitData.tsk_compliance_users[i].concurrer_name,
                            'user_idc': filterValue.unitData.tsk_compliance_users[i].concurrence_person
                        })
                    }
                    if (filterValue.unitData.tsk_compliance_users[i].assignee && _.findIndex(approverValue, { user_idas: filterValue.unitData.tsk_compliance_users[i].assignee }) < 0) {
                        approverValue.push({
                            'user_valueas': filterValue.unitData.tsk_compliance_users[i].assignee_name,
                            'user_idas': filterValue.unitData.tsk_compliance_users[i].assignee
                        })
                    }
                    approverValue1.push({
                        'user_valueas': filterValue.unitData.tsk_compliance_users[i].assignee_name,
                        'user_idas': filterValue.unitData.tsk_compliance_users[i].assignee
                    })
                }
                /*Remove Duplicate*/
                let unique = approverValue.map((index) => {
                    if (typeof index.user_valuea !== "undefined") {
                        return { "user_id": index.user_ida, "user_value": index.user_valuea }
                    }
                    if (typeof index.user_valueas !== "undefined") {
                        return { "user_id": index.user_idas, "user_value": index.user_valueas }
                    }
                    if (typeof index.user_valuec !== "undefined") {
                        return { "user_id": index.user_idc, "user_value": index.user_valuec }
                    }
                    console.log(index, key, "approverValue")
                })
                console.log(unique, "approverValue uniq")
                /*Remove Duplicate*/
                setuser(unique)

            }
            /*User Loading Initally*/
        }


    }, [filterValue])

    console.log(filterValue, 'filterValue');

    useEffect(() => {
        if (pageState === true) {
            setIndex(pageSize * (current - 1))
        }
    }, [pageState, pageSize, current])

    const homescreen = () => {
        historynew.push(homepage);
        // window.location.href = homepage
        document.getElementById('homehover').style.color = "blue"
    }

    const [modalDataState, setModalDataState] = useState([])

    const getChildDetails = (getrecords) => {
        setModalOpenChild(true)
        setModalDataState(getrecords)
        console.log(getrecords, 'getrecords')
    }

    const handleOkChild = () => {
        setModalOpenChild(false)
        setModalDataState([])
    };

    const handleCancelChild = () => {
        setModalOpenChild(false)
        setModalDataState([])
    };

    const [interimModal, setInterimModal] = useState(false)
    const [datas, setDatas] = useState({
        le_id: '',
        start_date: '',
        country_id: '',
        domain_id: '',
        unit_id: ''
    })
    const handleClose = () => {
        setInterimModal(false);

    };

    const interimcolumns = [
        {
            title: "#",
            // ellipsis: true,
            align: 'center',
            width: "2%",
            render: (data, record, index) => {
                return index + 1
            }
        },
        {
            title: "Documents",
            ellipsis: true,
            width: "20%",
            dataIndex: "documents",
            key: "documents",
            render: (data, record) => {
                let file_name = data.split('||')
                return file_name && file_name.map((item) => {
                    return <><span className='pointer' onClick={() => {
                        const downloadpayload =
                            [
                                authtoken,
                                {
                                    "session_token": authtoken,
                                    "request": [
                                        "DownloadInterimFile",
                                        {
                                            "le_id": entityid,
                                            "c_id": datas.country_id,
                                            "d_id": datas.domain_id,
                                            "u_id": datas.unit_id,
                                            "start_date": datas.start_date,
                                            "file_name": item
                                        }
                                    ]
                                }
                            ]
                        console.log(downloadpayload, 'downloadpayload');
                        Downloadfile({
                            payload: downloadpayload,
                            paramid: paramid
                        });
                    }}><Link>{item}</Link><br /></span></>
                })
            }
        },
        {
            title: "Remarks",
            ellipsis: true,
            width: "15%",
            dataIndex: "remarks",
            key: "remarks",
        },
        {
            title: "Updated On",
            ellipsis: true,
            width: "10%",
            dataIndex: "activity_on",
            key: "activity_on",
        },
    ]

    const [isRemarksModal, setRemarksModal] = useState(false)
    const [downloadState, setDownloadState] = useState([])
    const getRemarksList = (getrecord) => {
        setRemarksModal(true)
        setDownloadState(getrecord)
        const remarkspayload = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "GetComplianceRemarksHistoryData",
                    {
                        "legal_entity_id": consolidatedData.legel_entity,
                        "unit_id": parseInt(getrecord.unit_id),
                        "compliance_history_id": parseInt(getrecord.compliance_history_id)
                    }
                ]
            }
        ]
        getRemarksFilter({
            payload: remarkspayload,
            paramid: paramid
        })
    }

    const columnData = [
        {
            title: "User Name",
            dataIndex: "assignee_name",
            key: "assignee_name",
        },
        {
            title: "Activity Status",
            dataIndex: "activity_status",
            key: "activity_status",
        },
        {
            title: "Activity Date",
            dataIndex: "activity_date",
            key: "activity_date",
        },
        {
            title: "Uploaded Document",
            dataIndex: "uploaded_document",
            key: "uploaded_document",
        },
        {
            title: "Date",
            dataIndex: "completion_date",
            key: "completion_date",
        }
    ];

    const remarksColumn = [
        {
            title: "Activity Date",
            dataIndex: "activity_on",
            key: "activity_on",
        },
        {
            title: "User Type",
            dataIndex: "user_category_id",
            key: "user_category_id",
            render: (text, record) => {
                if (record.user_category_id == 5 || record.user_category_id == 6) {
                    return 'Assignee'
                } else if ((record.user_category_id == 3 || record.user_category_id == 4) && (record.action == 'Rectified' || record.action == 'Concurrence Rejected' || record.action == 'Concurred')) {
                    return 'Concurrer'
                } else if (record.user_category_id == 1 && (record.action == 'RectifyApproval' || record.action == 'Approval Rejected' || record.action == 'Approved') ||
                    (record.user_category_id == 3 || record.user_category_id == 4) && (record.action == 'Approval Rejected' || record.action == 'RectifyApproval' || record.action == 'Approved')) {
                    return 'Approver'
                }

            }
        },
        {
            title: "Reviewer Report",
            dataIndex: "documents",
            key: "documents",
            render: (text, record) => {
                if (text != null) {
                    return <Link onClick={(e) => downloadInteral(record)}>{text}</Link>
                }
            }
        },
        {
            title: "Remarks",
            dataIndex: "remarks",
            key: "remarks",
        },
    ]

    const handleOkRemarks = () => {
        setRemarksModal(false)
    };

    const handleCancelRemarks = () => {
        setRemarksModal(false)
    };

    const downloaddocument = (unit_id, start_date, document_name) => {
        const pay = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "DownloadFile",
                    {
                        "le_id": entityid,
                        "c_id": parseInt(consolidatedData.country),
                        "d_id": parseInt(consolidatedData.domain),
                        "u_id": unit_id,
                        "start_date": start_date,
                        "file_name": document_name
                    }
                ]
            }
        ]
        getdownloaddocument({
            payload: pay,
            paramid: paramid
        })
    }

    const downloadInteral = (record) => {
        console.log(record, 'record')
        const pay = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "DownloadFile",
                    {
                        "le_id": entityid,
                        "c_id": parseInt(consolidatedData.country),
                        "d_id": parseInt(consolidatedData.domain),
                        "u_id": record.unit_id,
                        "start_date": downloadState.start_date,
                        "file_name": record.documents
                    }
                ]
            }
        ]
        getdownloaddocument({
            payload: pay,
            paramid: paramid
        })
    }

    useEffect(() => {
        if (consolidatedData.country != '' && consolidatedData.legel_entity != '' && domain && domain.length == 1) {
            setDomainValue(true)
            setConsolidatedData({
                ...consolidatedData,
                domain: domain[0].d_id,
                domainName: domain[0].d_name,
                doamin_history: domain[0].d_name
            })
            let filteredAct = _.filter(Act, { domain_id: Number(domain[0].d_id) })
            setActs(filteredAct)
            getDomainBasedRecords(domain[0].d_id)

        } else {
            setDomainValue(false)
            setDomainFilter(domain)

        }
    }, [consolidatedData.legel_entity, domain])

    const getDownloadRecords = (records) => {
        console.log(records, 'recordsrecords')
        setUploadModal(true)
        setModalData(records)
        let documentTemp = []
        let concurTemp = []
        let approvalTemp = []



        let specialChar = /[|]/;

        // let documentResult = records.document_name.replaceAll("||", ", ||");
        documentTemp.push(specialChar.test(records.document_name) ? records.document_name.split("||") : records.document_name)
        //let concurResult = records.concur_document_name.replaceAll("||", ", ||");
        concurTemp.push(specialChar.test(records.concur_document_name) ? records.concur_document_name.split("||") : records.concur_document_name)
        // let approvalResult = records.approval_document_names.replaceAll("||", ", ||");
        approvalTemp.push(specialChar.test(records.approval_document_names) ? records.approval_document_names.split("||") : records.approval_document_names)

        setDocumentName([].concat.apply([], documentTemp.filter(Boolean))) //remove null value and merge array of array to single array
        setApprovalDocName([].concat.apply([], approvalTemp.filter(Boolean)))
        setConcurDocName([].concat.apply([], concurTemp.filter(Boolean)))
    }

    console.log(documentName, 'documentName')
    console.log(approvalDocName, 'approvalDocName')
    console.log(concurDocName, 'concurDocName')
    useEffect(() => {
        const maxLength = Math.max(documentName.length, approvalDocName.length, concurDocName.length);
        let entityArray = []
        for (let i = 0; i < maxLength; i++) {
            entityArray.push({
                assignee: documentName[i] || null,
                approver: approvalDocName[i] || null,
                concur: concurDocName[i] || null
            });
        }
        setCommonArray(entityArray)

    }, [documentName, approvalDocName, concurDocName])

    const getUserTypeBasedRecords = (userTypeId) => {
        console.log(userTypeId, 'userTypeId')
        console.log(filterValue.unitData.tsk_compliance_users, 'userTypeId filterValue.unitData.tsk_compliance_users')
        if (filterValue && filterValue.unitData && filterValue.unitData.tsk_compliance_users) {
            if (userTypeId == 1) {
                let assigneeValue = []
                for (let i = 0; i < filterValue.unitData.tsk_compliance_users.length; i++) {
                    if (filterValue.unitData.tsk_compliance_users[i].assignee != null) {
                        assigneeValue.push({
                            'user_value': filterValue.unitData.tsk_compliance_users[i].assignee_name,
                            'user_id': filterValue.unitData.tsk_compliance_users[i].assignee
                        })
                    }

                }
                /*Remove Duplicate*/
                let unique = _.uniqBy(assigneeValue, function (e) {
                    return e.user_id;
                });
                /*Remove Duplicate*/
                setuser(unique)
            } else if (userTypeId == 2) {
                let concurrerValue = []
                for (let i = 0; i < filterValue.unitData.tsk_compliance_users.length; i++) {
                    if (filterValue.unitData.tsk_compliance_users[i].concurrence_person != null) {
                        concurrerValue.push({
                            'user_value': filterValue.unitData.tsk_compliance_users[i].concurrer_name,
                            'user_id': filterValue.unitData.tsk_compliance_users[i].concurrence_person
                        })
                    }

                }
                /*Remove Duplicate*/
                let unique = _.uniqBy(concurrerValue, function (e) {
                    return e.user_id;
                });
                /*Remove Duplicate*/
                setuser(unique)
            } else if (userTypeId == 3) {
                let approverValue = []
                for (let i = 0; i < filterValue.unitData.tsk_compliance_users.length; i++) {
                    if (filterValue.unitData.tsk_compliance_users[i].approver_name != null) {
                        approverValue.push({
                            'user_value': filterValue.unitData.tsk_compliance_users[i].approver_name,
                            'user_id': filterValue.unitData.tsk_compliance_users[i].approval_person
                        })
                    }
                }
                /*Remove Duplicate*/
                let unique = _.uniqBy(approverValue, function (e) {
                    return e.user_id;
                });
                /*Remove Duplicate*/
                setuser(unique)
            } else {
                let approverValue = []
                let approverValue1 = []
                for (let i = 0; i < filterValue.unitData.tsk_compliance_users.length; i++) {

                    if (_.findIndex(approverValue, { user_ida: filterValue.unitData.tsk_compliance_users[i].approval_person }) < 0) {
                        approverValue.push({
                            'user_valuea': filterValue.unitData.tsk_compliance_users[i].approver_name,
                            'user_ida': filterValue.unitData.tsk_compliance_users[i].approval_person
                        })
                    }
                    if (filterValue.unitData.tsk_compliance_users[i].concurrence_person && _.findIndex(approverValue, { user_idc: filterValue.unitData.tsk_compliance_users[i].concurrence_person }) < 0) {
                        approverValue.push({
                            'user_valuec': filterValue.unitData.tsk_compliance_users[i].concurrer_name,
                            'user_idc': filterValue.unitData.tsk_compliance_users[i].concurrence_person
                        })
                    }
                    if (filterValue.unitData.tsk_compliance_users[i].assignee && _.findIndex(approverValue, { user_idas: filterValue.unitData.tsk_compliance_users[i].assignee }) < 0) {
                        approverValue.push({
                            'user_valueas': filterValue.unitData.tsk_compliance_users[i].assignee_name,
                            'user_idas': filterValue.unitData.tsk_compliance_users[i].assignee
                        })
                    }
                    approverValue1.push({
                        'user_valueas': filterValue.unitData.tsk_compliance_users[i].assignee_name,
                        'user_idas': filterValue.unitData.tsk_compliance_users[i].assignee
                    })
                }
                /*Remove Duplicate*/
                let unique = approverValue.map((index) => {
                    if (typeof index.user_valuea !== "undefined") {
                        return { "user_id": index.user_ida, "user_value": index.user_valuea }
                    }
                    if (typeof index.user_valueas !== "undefined") {
                        return { "user_id": index.user_idas, "user_value": index.user_valueas }
                    }
                    if (typeof index.user_valuec !== "undefined") {
                        return { "user_id": index.user_idc, "user_value": index.user_valuec }
                    }
                    console.log(index, key, "approverValue")
                })
                /*Remove Duplicate*/
                setuser(unique)
            }
        }
    }

    const [selectAllText, setSelectAllText] = useState('Select All')

    useEffect(() => {
        if (checkboxValue.length == 34) {
            setSelectAllText('Unselect All')
        } else {
            setSelectAllText('Select All')
        }
    }, [checkboxValue])

    const getDomainBasedRecords = (domainId) => {
        if (domainId != undefined) {
            let filteredAct = _.filter(Act, { domain_id: Number(domainId) })
            setActs(filteredAct)
            let filteredComplianceStatus = _.filter(filterValue && filterValue.unitData && filterValue.unitData.compliance_task_status, { domain_id: Number(domainId) })
            setComplianceTask(filteredComplianceStatus)
            setDivision(filterValue && filterValue.Data && filterValue.Data.divisions_list)
            setCategoryList(filterValue && filterValue.Data && filterValue.Data.categories_list)


        } else {
            setActs([])
            setComplianceTask([])
            setDivision([])
            setCategoryList([])
        }

    }

    const getDivisionBasedRecords = (divisionId) => {
        if (divisionId != undefined) {
            let categoryRecords = _.filter(filterValue && filterValue.Data && filterValue.Data.categories_list, { div_id: parseInt(divisionId) });
            setCategoryList(categoryRecords)
            let units = _.filter(filterValue && filterValue.unitData && filterValue.unitData.tsk_unit_legal_entity, { division_id: parseInt(divisionId), domain_id: parseInt(consolidatedData.domain) });
            let unitUnique = _.uniqBy(units, function (e) {
                return e.unit_id;
            });
            setUnits(unitUnique)

        } else {
            let unitUnique = _.uniqBy(filterValue.unitData.tsk_unit_legal_entity, function (e) {
                return e.unit_id;
            });
            setUnits(unitUnique)
            setCategoryList(filterValue && filterValue.Data && filterValue.Data.categories_list)
        }
    }

    const getCategoryBasedRecords = (categoryId) => {
        if (categoryId != undefined) {
            if (consolidatedData.division != '') {
                let units = _.filter(filterValue && filterValue.unitData && filterValue.unitData.tsk_unit_legal_entity, { category_id: parseInt(categoryId), division_id: parseInt(consolidatedData.division), domain_id: parseInt(consolidatedData.domain) });
                let unitUnique = _.uniqBy(units, function (e) {
                    return e.unit_id;
                });
                setUnits(unitUnique)
            } else {
                let units = _.filter(filterValue && filterValue.unitData && filterValue.unitData.tsk_unit_legal_entity, { category_id: parseInt(categoryId), domain_id: parseInt(consolidatedData.domain) });
                let unitUnique = _.uniqBy(units, function (e) {
                    return e.unit_id;
                });
                setUnits(unitUnique)
            }

        } else {
            if (consolidatedData.division != '') {
                let units = _.filter(filterValue && filterValue.unitData && filterValue.unitData.tsk_unit_legal_entity, { division_id: parseInt(consolidatedData.division), domain_id: parseInt(consolidatedData.domain) });
                let unitUnique = _.uniqBy(units, function (e) {
                    return e.unit_id;
                });
                setUnits(unitUnique)
            } else {
                let units = _.filter(filterValue && filterValue.unitData && filterValue.unitData.tsk_unit_legal_entity, { domain_id: parseInt(consolidatedData.domain) });
                let unitUnique = _.uniqBy(units, function (e) {
                    return e.unit_id;
                });
                setUnits(unitUnique)
            }

        }

    }

    return (
        <div id='page-wrapper' className="page-wrapper">
            {tableshow == true ?
                <div className='back-to-top'>
                    <ScrollButton />
                </div> : ''}
            <div className="page-titles pb-2 pt-1 page-title-sticky">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <span className='pointer' id='homehover' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <span style={{ fontSize: '14px', fontWeight: "bold" }}>Report</span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: '15px', fontWeight: 'bold' }}>
                                    <span style={{ fontSize: "15px", fontWeight: "bold" }}>Compliance Reports</span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                    <span>Task Wise Report</span>
                                </li>
                            </ol>
                        </nav>

                    </div>
                    <div className="col-lg-6 text-end">
                        <Tooltip title={'Advance Filter'}><Button
                            className="mx-1"
                            type="primary"
                            shape="round"
                            style={{
                                display: filterTaskAcc ? "none" : "initial",
                            }}
                            onClick={filterHandle}
                        >
                            <FilterOutlined /> Filter
                        </Button></Tooltip>
                        <button onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black ms-2 full-mode fullscreenradius" >
                            <Tooltip placement="left" title={is_Fullscreen == true ? "Exit Full Screen Mode" : "Full Screen Mode"}><FullscreenOutlined /></Tooltip>
                        </button>
                    </div>
                </div>
            </div>
            <div className="container-fluid pt-0">
                <div className="row">
                    <div className="col-lg-12">
                        <div className={localStorage.getItem("currentTheme") + ' card-body1-statuatory'} style={{ position: 'relative' }}>

                            <Collapse className='report-collapse' style={{ display: exitCollapse ? "none" : "block" }} accordion defaultActiveKey={["1"]} onChange={onChange}>
                                <Panel header="Task Wise Report" key={key} >
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Country : </b> <span style={{ color: "red" }}>*</span></label>


                                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?

                                                    <div className="form-group">

                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Enter Country"
                                                            value={consolidatedData.countryName || undefined}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            onDrop={(e) => {
                                                                e.preventDefault();
                                                                // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                return false;
                                                            }}
                                                            // onPaste={(e) => {
                                                            //     handlePasting(e, /^[a-z ]+$/gi)
                                                            // }}
                                                            onPaste={onPasteToaster}
                                                            searchValue={selectSearchValue.country}
                                                            onSearch={(value) => {
                                                                let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                                                setSelectSearchValue({
                                                                    ...selectSearchValue,
                                                                    country: result
                                                                });
                                                            }}
                                                            onChange={(data, value) => {
                                                                setDomainValue(false)
                                                                setDomainFilter([])
                                                                setDomain([])
                                                                if (data !== undefined) {
                                                                    let le = _.filter(sessionArr, { c_id: Number(data) })
                                                                    setEntity(le)
                                                                    setConsolidatedData({
                                                                        ...consolidatedData,
                                                                        country: data,
                                                                        country_history: value.children,
                                                                        countryName: value.children,
                                                                        category: "",
                                                                        user_type: "All",
                                                                        legel_entity: "",
                                                                        legalentity_history: '',
                                                                        unit: '',
                                                                        user: "",
                                                                        domain: "",
                                                                        doamin_history: '',
                                                                        act: "",
                                                                        act_history: '',
                                                                        from_date: "",
                                                                        task_category: "",
                                                                        compliance_task: "",
                                                                        task_history: '',
                                                                        to_date: "",
                                                                        task_sub_category: "",
                                                                        compliance_frequency: "",
                                                                        compliance_task_status: "All",
                                                                        division: "",
                                                                        domainName: "",
                                                                        complianceName: ""
                                                                    })
                                                                }
                                                                else {
                                                                    setConsolidatedData({
                                                                        ...consolidatedData,
                                                                        country: '',
                                                                        countryName: '',
                                                                        country_history: '',
                                                                        category: "",
                                                                        user_type: "All",
                                                                        legel_entity: "",
                                                                        legalentity_history: '',
                                                                        unit: '',
                                                                        user: "",
                                                                        domain: "",
                                                                        doamin_history: '',
                                                                        act: "",
                                                                        from_date: "",
                                                                        task_category: "",
                                                                        compliance_task: "",
                                                                        to_date: "",
                                                                        task_sub_category: "",
                                                                        compliance_frequency: "",
                                                                        compliance_task_status: "All",
                                                                        division: "",
                                                                        domainName: "",
                                                                        complianceName: "",
                                                                        act_history: '',
                                                                        task_history: ''
                                                                    })
                                                                }
                                                            }
                                                            }
                                                            style={{ width: '100%', marginTop: '2px' }}>
                                                            {uniq_country_data && uniq_country_data.length > 0 && uniq_country_data.map((item, i) => {
                                                                return (
                                                                    <Option key={item.c_id}>
                                                                        {item.c_name}
                                                                    </Option>
                                                                );

                                                            })}
                                                        </Select>

                                                        {validator.current.message(
                                                            'Country',
                                                            consolidatedData.country,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Country Required',
                                                                }
                                                            })}

                                                    </div> :
                                                    <p>{consolidatedData.countryName}</p>
                                                }
                                            </div>
                                            <div className='col-md-4 text-wrap-report'>
                                                <label><b>Legal Entity :  </b><span style={{ color: "red" }}>*</span></label>
                                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?

                                                    <div className="form-group">

                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            value={consolidatedData.legel_entity || undefined}
                                                            placeholder="Enter Legal Entity"
                                                            onDrop={(e) => {
                                                                e.preventDefault();
                                                                // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                return false;
                                                            }}
                                                            // onPaste={(e) => {
                                                            //     handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                            // }}
                                                            onPaste={onPasteToaster}
                                                            searchValue={selectSearchValue.legalEntity}
                                                            onSearch={(value) => {
                                                                let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                                setSelectSearchValue({
                                                                    ...selectSearchValue,
                                                                    legalEntity: result
                                                                });
                                                            }}
                                                            onChange={(data, value) => {
                                                                setDomainValue(false)
                                                                setDomainFilter([])
                                                                setDomain([])
                                                                if (data !== undefined) {
                                                                    setConsolidatedData({
                                                                        ...consolidatedData,
                                                                        legel_entity: data,
                                                                        entity_name: value.children,
                                                                        legalentity_history: value.children,
                                                                        category: "",
                                                                        user_type: "All",
                                                                        unit: '',
                                                                        user: "",
                                                                        domain: "",
                                                                        act: "",
                                                                        from_date: "",
                                                                        task_category: "",
                                                                        compliance_task: "",
                                                                        to_date: "",
                                                                        task_sub_category: "",
                                                                        compliance_frequency: "",
                                                                        compliance_task_status: "All",
                                                                        division: "",
                                                                        domainName: "",
                                                                        complianceName: "",
                                                                        doamin_history: '',
                                                                        act_history: '',
                                                                        task_history: ''
                                                                    })
                                                                    setCurrentEntity(data)
                                                                }
                                                                else {
                                                                    setConsolidatedData({

                                                                        ...consolidatedData,
                                                                        legel_entity: '',
                                                                        entity_name: '',
                                                                        legalentity_history: '',
                                                                        category: "",
                                                                        user_type: "All",
                                                                        unit: '',
                                                                        user: "",
                                                                        domain: "",
                                                                        act: "",
                                                                        from_date: "",
                                                                        task_category: "",
                                                                        compliance_task: "",
                                                                        to_date: "",
                                                                        task_sub_category: "",
                                                                        compliance_frequency: "",
                                                                        compliance_task_status: "All",
                                                                        division: "",
                                                                        domainName: "",
                                                                        complianceName: "",
                                                                        doamin_history: '',
                                                                        act_history: '',
                                                                        task_history: ''
                                                                    })
                                                                    setCurrentEntity('')
                                                                }
                                                            }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            //value={Settings.le_id}
                                                            style={{ width: '100%', marginTop: '2px' }}
                                                            disabled={consolidatedData.country == '' ? true : false}

                                                        >

                                                            {entity && entity.length > 0 && entity.map((item, i) => {
                                                                return (
                                                                    <Option key={item.le_id}>
                                                                        {item.le_name}
                                                                    </Option>
                                                                );
                                                            })}


                                                        </Select>

                                                        {validator.current.message(
                                                            'legalEntity',
                                                            consolidatedData.legel_entity,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Legal Entity Required',
                                                                }
                                                            })}

                                                    </div> :
                                                    <p>{localStorage.getItem('SelectedEntity')}</p>
                                                }

                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Domain : </b> <span style={{ color: "red" }}>*</span></label>
                                                {domainValue == false || domainFilter && domainFilter.length > 1 ?
                                                    <>
                                                        <Select
                                                            allowClear={true}
                                                            disabled={((localStorage.getItem('SelectedEntity') === "All Legal Entity") && consolidatedData.legel_entity == '') ? true : false}
                                                            size="default"
                                                            value={consolidatedData.domainName || undefined}
                                                            placeholder="Enter Domain Name"
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            onDrop={(e) => {
                                                                e.preventDefault();
                                                                // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                return false;
                                                            }}
                                                            // onPaste={(e) => {
                                                            //     handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                            // }}
                                                            onPaste={onPasteToaster}
                                                            searchValue={selectSearchValue.domain}
                                                            onSearch={(value) => {
                                                                let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                                                setSelectSearchValue({
                                                                    ...selectSearchValue,
                                                                    domain: result
                                                                });
                                                            }}
                                                            onChange={(data, value) => {
                                                                getDomainBasedRecords(data)
                                                                if (data !== undefined) {
                                                                    setConsolidatedData({
                                                                        ...consolidatedData,
                                                                        domain: data,
                                                                        domainName: value.children,
                                                                        doamin_history: value.children,
                                                                        category: "",
                                                                        user_type: "All",
                                                                        unit: '',
                                                                        user: "",
                                                                        act: "",
                                                                        from_date: "",
                                                                        task_category: "",
                                                                        compliance_task: "",
                                                                        to_date: "",
                                                                        task_sub_category: "",
                                                                        compliance_frequency: "",
                                                                        compliance_task_status: "All",
                                                                        division: "",
                                                                        complianceName: "",
                                                                        act_history: '',
                                                                        task_history: ''

                                                                    })
                                                                }
                                                                else {

                                                                    setActs([])
                                                                    setComplianceTask([])
                                                                    setConsolidatedData({
                                                                        ...consolidatedData,
                                                                        domain: '',
                                                                        domainName: '',
                                                                        doamin_history: '',
                                                                        category: "",
                                                                        user_type: "All",
                                                                        unit: '',
                                                                        user: "",
                                                                        act: "",
                                                                        from_date: "",
                                                                        task_category: "",
                                                                        compliance_task: "",
                                                                        to_date: "",
                                                                        task_sub_category: "",
                                                                        compliance_frequency: "",
                                                                        compliance_task_status: "All",
                                                                        division: "",
                                                                        complianceName: "",
                                                                        act_history: '',
                                                                        task_history: ''
                                                                    })
                                                                }
                                                            }

                                                            }
                                                            style={{ width: '100%', marginTop: '2px' }}

                                                        >
                                                            {domainFilter && domainFilter.length > 0 && domainFilter.map((item, i) => {
                                                                return (
                                                                    <Option key={item.d_id}>
                                                                        {item.d_name}
                                                                    </Option>
                                                                );
                                                            })}

                                                        </Select>
                                                        {validator.current.message(
                                                            'domain',
                                                            consolidatedData.domain,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Domain Required',
                                                                }
                                                            })}
                                                    </>
                                                    : <p className='cut-text-word' style={{ width: '100%', marginTop: "5px" }}>{domain && domain.length > 0 && domain[0].d_name}</p>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "5px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'>

                                                <label><b>Act/Rules : </b><span style={{ color: "red" }}>*</span></label>
                                                <Select
                                                    value={consolidatedData.act || undefined}
                                                    disabled={consolidatedData.domain == '' ? true : false}
                                                    allowClear={true}
                                                    size="default"
                                                    placeholder="Enter Act"
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    // onPaste={(e) => {
                                                    //     handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                    // }}
                                                    onPaste={onPasteToaster}
                                                    searchValue={selectSearchValue.actRules}
                                                    onSearch={(value) => {
                                                        let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                        setSelectSearchValue({
                                                            ...selectSearchValue,
                                                            actRules: result
                                                        });
                                                    }}
                                                    onChange={(data, value) => {
                                                        if (data !== undefined) {
                                                            // let filteredActObj = _.find(filterValue && filterValue.Data && filterValue.Data.tsk_act_legal_entity, { compliance_id: Number(data) })
                                                            let filteredComplianceStatus = _.filter(filterValue && filterValue.Data && filterValue.Data.compliance_task_wise_list, { statutory_mapping: data })
                                                            setComplianceTask(filteredComplianceStatus)
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                act: value.children,
                                                                act_history: value.children,
                                                                category: "",
                                                                user_type: "All",
                                                                unit: '',
                                                                user: "",
                                                                from_date: "",
                                                                task_category: "",
                                                                compliance_task: "",
                                                                to_date: "",
                                                                task_sub_category: "",
                                                                compliance_frequency: "",
                                                                compliance_task_status: "All",
                                                                division: "",
                                                                complianceName: "",
                                                                task_history: ''
                                                            })
                                                        }
                                                        else {
                                                            setComplianceTask([])
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                act: '',
                                                                category: "",
                                                                user_type: "All",
                                                                unit: '',
                                                                user: "",
                                                                from_date: "",
                                                                task_category: "",
                                                                compliance_task: "",
                                                                to_date: "",
                                                                task_sub_category: "",
                                                                compliance_frequency: "",
                                                                compliance_task_status: "All",
                                                                division: "",
                                                                complianceName: "",
                                                                task_history: '',
                                                                act_history: ''
                                                            })
                                                            setComplianceTask([])
                                                        }
                                                    }
                                                    }
                                                    style={{ width: '100%', marginTop: '2px' }}

                                                >
                                                    {Acts && Acts.length > 0 && Acts.map((item, i) => {
                                                        return (
                                                            <Option key={item.statutory_mapping}>
                                                                {item.statutory_mapping}
                                                            </Option>
                                                        );
                                                    })}

                                                </Select>

                                                {validator.current.message(
                                                    'Act',
                                                    consolidatedData.act,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Act Required',
                                                        }
                                                    })}
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Compliance Task : </b><span style={{ color: "red" }}>*</span></label>
                                                <Select
                                                    allowClear={true}
                                                    disabled={consolidatedData.act == '' ? true : false}
                                                    value={consolidatedData.compliance_task || undefined}
                                                    size="default"
                                                    placeholder="Enter Compliance Task"
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    onPaste={(e) => {
                                                        handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                    }}
                                                    onChange={(data, value) => {
                                                        ComplianceTaskOnChange(data, value)
                                                    }}
                                                    style={{ width: '100%', marginTop: '2px' }}

                                                >
                                                    {ComplianceTask && ComplianceTask.length > 0 && ComplianceTask.map((item, i) => {
                                                        return (
                                                            <Option key={item.compliance_id}>
                                                                {item.compliance_task}
                                                            </Option>
                                                        );
                                                    })}

                                                </Select>
                                                {validator.current.message(
                                                    'domain',
                                                    consolidatedData.compliance_task,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Compliance Task Required',
                                                        }
                                                    })}



                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Division : </b></label>
                                                <Select
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    allowClear={true}
                                                    size="default"
                                                    disabled={consolidatedData.compliance_task == '' ? true : false}
                                                    value={consolidatedData.division || undefined}
                                                    placeholder="Enter Division Name"
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    // onPaste={(e) => {
                                                    //     handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                    // }}
                                                    onPaste={onPasteToaster}
                                                    searchValue={selectSearchValue.division}
                                                    onSearch={(value) => {
                                                        let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                        setSelectSearchValue({
                                                            ...selectSearchValue,
                                                            division: result
                                                        });
                                                    }}
                                                    onChange={(data) => {
                                                        getDivisionBasedRecords(data)
                                                        if (data !== undefined) {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                division: data,
                                                                category: '',
                                                                unit: ''
                                                            })

                                                        }
                                                        else {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                division: '',
                                                                category: '',
                                                                unit: ''
                                                            })

                                                        }
                                                    }
                                                    }
                                                    style={{ width: '100%', marginTop: '2px' }}

                                                >
                                                    {division && division.length > 0 && division.map((item, i) => {
                                                        return (
                                                            <Option key={item.div_id}>
                                                                {item.div_name}
                                                            </Option>
                                                        );
                                                    })}

                                                </Select>
                                            </div>


                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "5px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Category : </b></label>
                                                <Select
                                                    value={consolidatedData.category || undefined}
                                                    disabled={consolidatedData.compliance_task == '' ? true : false}
                                                    allowClear={true}
                                                    size="default"
                                                    placeholder="Enter Category Name"
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    // onPaste={(e) => {
                                                    //     handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                    // }}
                                                    onPaste={onPasteToaster}
                                                    searchValue={selectSearchValue.category}
                                                    onSearch={(value) => {
                                                        let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                        setSelectSearchValue({
                                                            ...selectSearchValue,
                                                            category: result
                                                        });
                                                    }}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    onChange={(data) => {
                                                        getCategoryBasedRecords(data)
                                                        if (data !== undefined) {

                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                category: data,
                                                                unit: ''
                                                            })
                                                        }
                                                        else {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                category: '',
                                                                unit: ''
                                                            })
                                                        }
                                                        // let unitData = _.filter(units && units.length > 0 && units, { category_id: Number(data) })
                                                        // setUnits(unitData)
                                                    }
                                                    }
                                                    // value={compfie.user_Group}
                                                    style={{ width: '100%', marginTop: '2px' }}

                                                >
                                                    {category && category.length > 0 && category.map((item, i) => {
                                                        return (
                                                            <Option key={item.cat_id}>
                                                                {item.cat_name}
                                                            </Option>
                                                        );
                                                    })}

                                                </Select>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Vendor : </b></label>
                                                <Select
                                                    value={consolidatedData.unit || undefined}
                                                    disabled={consolidatedData.compliance_task == '' ? true : false}
                                                    allowClear={true}
                                                    size="default"
                                                    placeholder="Enter Vendor"
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    // onPaste={(e) => {
                                                    //     handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                    // }}
                                                    onPaste={onPasteToaster}
                                                    searchValue={selectSearchValue.vendor}
                                                    onSearch={(value) => {
                                                        let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                        setSelectSearchValue({
                                                            ...selectSearchValue,
                                                            vendor: result
                                                        });
                                                    }}
                                                    onChange={(data) => {
                                                        if (data !== undefined) {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                unit: data
                                                            })
                                                        }
                                                        else {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                unit: ''
                                                            })
                                                        }
                                                    }
                                                    }
                                                    style={{ width: '100%', marginTop: '2px' }}

                                                >
                                                    {units && units.length > 0 && units.map((item, i) => {
                                                        return (
                                                            <Option key={item.unit_id}>
                                                                {`${item.unit_code}-${item.unit_name}`}
                                                            </Option>
                                                        );
                                                    })}

                                                </Select>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>User Type : </b></label>
                                                <Select
                                                    value={consolidatedData.user_type || undefined}
                                                    disabled={consolidatedData.domain == '' ? true : false}
                                                    allowClear={false}
                                                    size="default"
                                                    placeholder="Enter UserType"
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    onPaste={(e) => {
                                                        handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                    }}
                                                    // showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    onChange={(data) => {
                                                        getUserTypeBasedRecords(data)
                                                        if (data !== undefined) {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                user_type: data
                                                            })
                                                        }
                                                        else {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                user_type: ''
                                                            })
                                                        }
                                                    }
                                                    }
                                                    // value={compfie.user_Group}
                                                    style={{ width: '100%', marginTop: '2px' }}

                                                >
                                                    <Option key={'All'}>All</Option>
                                                    {userType && userType.length > 0 && userType.map((item, i) => {
                                                        return (
                                                            <Option key={item.user_type_id}>
                                                                {item.user_type}
                                                            </Option>
                                                        );
                                                    })}

                                                </Select>
                                            </div>


                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "5px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>User : </b></label>
                                                <Select
                                                    value={consolidatedData.user || undefined}

                                                    allowClear={true}
                                                    disabled={consolidatedData.compliance_task == '' ? true : false}
                                                    size="default"
                                                    placeholder="Enter User"
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    // onPaste={(e) => {
                                                    //     handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                    // }}
                                                    onPaste={onPasteToaster}
                                                    searchValue={selectSearchValue.user}
                                                    onSearch={(value) => {
                                                        let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                        setSelectSearchValue({
                                                            ...selectSearchValue,
                                                            user: result
                                                        });
                                                    }}
                                                    onChange={(data) => {
                                                        if (data !== undefined) {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                user: data
                                                            })
                                                        }
                                                        else {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                user: ''
                                                            })
                                                        }
                                                    }
                                                    }
                                                    style={{ width: '100%', marginTop: '2px' }}

                                                >
                                                    {user && user.length > 0 && user.map((item, i) => {
                                                        return (
                                                            <Option key={item.user_id}>
                                                                {item.user_value}
                                                            </Option>
                                                        );
                                                    })}

                                                </Select>
                                            </div>

                                            <div className='col-md-4'>
                                                <label><b>From Date : </b> </label>
                                                <DatePicker format={'DD-MMM-YYYY'} allowClear={true} placement={'bottomLeft'} placeholder='DD-MMM-YYYY' style={{ width: '100%', marginTop: '2px' }}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    onPaste={(e) => {
                                                        handlePasting(e, /^[a-z ]+$/gi)
                                                    }}
                                                    onKeyDown={(e) => {
                                                        e.preventDefault();
                                                        return false
                                                    }}
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    value={consolidatedData.from_date ? moment(consolidatedData.from_date) : ''}
                                                    onChange={(date, dateString) => {
                                                        if (dateString == '') {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                from_date: '',
                                                                to_date: ''
                                                            })
                                                        } else {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                from_date: dateString,
                                                                to_date: moment(dateString).add(3, 'months')
                                                            })
                                                        }

                                                    }}

                                                />

                                                {/* <input type="date" className='form-control' style={{ width: '300px' }} /> */}
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>To Date : </b> </label>
                                                <DatePicker
                                                    disabled={consolidatedData.from_date == '' ? true : false}

                                                    format={'DD-MMM-YYYY'}
                                                    allowClear={true} placeholder='DD-MMM-YYYY' style={{ width: '100%', marginTop: '2px' }}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    onPaste={(e) => {
                                                        handlePasting(e, /^[a-z ]+$/gi)
                                                    }}
                                                    onKeyDown={(e) => {
                                                        e.preventDefault();
                                                        return false
                                                    }}
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    onChange={(date, dateString) => {
                                                        console.log(date, 'datedate')
                                                        if (date != null) {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                to_date: moment(dateString)
                                                            })
                                                        } else {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                to_date: ''
                                                            })
                                                        }
                                                        // disablecondition(dateString)

                                                    }}
                                                    value={consolidatedData.to_date || undefined}
                                                    disabledDate={(current) => {
                                                        return moment(consolidatedData.from_date).add(-1, 'days') >= current ||
                                                            moment(consolidatedData.from_date).add(365, 'days') < current;
                                                    }}
                                                />

                                                {/* <input type="date" className='form-control' style={{ width: '300px' }} /> */}
                                            </div>

                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "5px" }}>
                                        <div className='row'>

                                            <div className='col-md-4'>
                                                <label><b>Compliance Task Status : </b></label>
                                                <Select
                                                    disabled={consolidatedData.domain == '' ? true : false}
                                                    value={consolidatedData.compliance_task_status || undefined}
                                                    // showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    onPaste={(e) => {
                                                        handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                    }}
                                                    onChange={(value) => {
                                                        if (value !== undefined) {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                compliance_task_status: value
                                                            })
                                                        }
                                                        else {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                compliance_task_status: ''
                                                            })
                                                        }
                                                    }}
                                                    // placeholder="Enter Domain Name"
                                                    style={{ width: '100%', marginTop: '2px' }}

                                                >
                                                    <Option key="All">All</Option>
                                                    {complianceTaskStatus && complianceTaskStatus.length > 0 && complianceTaskStatus.map((item, i) => {
                                                        return (
                                                            <Option key={item.task_status}>
                                                                {item.task_status}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </div>


                                        </div>
                                    </div>
                                    <div className='text-center mt-2 popupbtncolour'>
                                        {/* <button className="btn btn-secondary" style={{ marginLeft: "59px", marginRight: "10px", width: "100px" }} onClick={onshow}>Show</button>
                                                <button className="btn btn-success" style={{ width: "100px" }} >Export</button> */}
                                        <Button disabled={disableButton} type="primary" shape="round" className={'addbutton me-1 ' + localStorage.getItem('currentTheme')} onClick={onshow}
                                            icon={<EyeOutlined />}       >
                                            Show
                                        </Button>
                                        <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')}
                                            icon={<ExportOutlined />} onClick={showModal}>
                                            Export
                                        </Button>

                                    </div>

                                </Panel>
                            </Collapse>

                            <Modal
                                onCancel={setcancelFilter}
                                footer={false}
                                title="Task Wise Report"
                                className={
                                    "add-service-prv cons-report " +
                                    localStorage.getItem("currentTheme")
                                }
                                open={filterModalVisible}
                                maskClosable={false}
                            >
                                <div className='col-md-12'>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <label><b>Country : </b> <span style={{ color: "red" }}>*</span></label>


                                            {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?

                                                <div className="form-group">

                                                    <Select
                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        allowClear={true}
                                                        size="default"
                                                        placeholder="Enter Country"
                                                        value={consolidatedData.country || undefined}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        // onPaste={(e) => {
                                                        //     handlePasting(e, /^[a-z ]+$/gi)
                                                        // }}
                                                        onPaste={onPasteToaster}
                                                        searchValue={selectSearchValue.country}
                                                        onSearch={(value) => {
                                                            let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                                            setSelectSearchValue({
                                                                ...selectSearchValue,
                                                                country: result
                                                            });
                                                        }}
                                                        onChange={(data, value) => {
                                                            setDomainValue(false)
                                                            setDomain([])
                                                            setDomainFilter([])
                                                            if (data !== undefined) {
                                                                let le = _.filter(sessionArr, { c_id: Number(data) })
                                                                setEntity(le)
                                                                setConsolidatedData({
                                                                    ...consolidatedData,
                                                                    country: data,
                                                                    country_history: data,
                                                                    countryName: value.children,
                                                                    category: "",
                                                                    user_type: "All",
                                                                    legel_entity: "",
                                                                    legalentity_history: '',
                                                                    unit: '',
                                                                    user: "",
                                                                    domain: "",
                                                                    doamin_history: '',
                                                                    act: "",
                                                                    act_history: '',
                                                                    from_date: "",
                                                                    task_category: "",
                                                                    compliance_task: "",
                                                                    task_history: '',
                                                                    to_date: "",
                                                                    task_sub_category: "",
                                                                    compliance_frequency: "",
                                                                    compliance_task_status: "All",
                                                                    division: "",
                                                                    domainName: "",
                                                                    complianceName: ""
                                                                })
                                                            }
                                                            else {
                                                                setConsolidatedData({
                                                                    ...consolidatedData,
                                                                    country: '',
                                                                    countryName: '',
                                                                    category: "",
                                                                    user_type: "All",
                                                                    legel_entity: "",
                                                                    unit: '',
                                                                    user: "",
                                                                    domain: "",
                                                                    act: "",
                                                                    from_date: "",
                                                                    task_category: "",
                                                                    compliance_task: "",
                                                                    to_date: "",
                                                                    task_sub_category: "",
                                                                    compliance_frequency: "",
                                                                    compliance_task_status: "All",
                                                                    division: "",
                                                                    domainName: "",
                                                                    complianceName: "",
                                                                })
                                                            }
                                                        }
                                                        }
                                                        style={{ width: '100%', marginTop: '2px' }}>
                                                        {uniq_country_data && uniq_country_data.length > 0 && uniq_country_data.map((item, i) => {
                                                            return (
                                                                <Option key={item.c_id}>
                                                                    {item.c_name}
                                                                </Option>
                                                            );

                                                        })}
                                                    </Select>

                                                    {validator.current.message(
                                                        'Country',
                                                        consolidatedData.country,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Country Required',
                                                            }
                                                        })}

                                                </div> :
                                                <p>{consolidatedData.countryName}</p>
                                            }
                                        </div>
                                        <div className='col-md-4 text-wrap-report'>
                                            <label><b>Legal Entity :  </b><span style={{ color: "red" }}>*</span></label>
                                            {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?

                                                <div className="form-group">

                                                    <Select
                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        allowClear={true}
                                                        size="default"
                                                        value={consolidatedData.legel_entity || undefined}
                                                        placeholder="Enter Legal Entity"
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        // onPaste={(e) => {
                                                        //     handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                        // }}
                                                        onPaste={onPasteToaster}
                                                        searchValue={selectSearchValue.legalEntity}
                                                        onSearch={(value) => {
                                                            let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                            setSelectSearchValue({
                                                                ...selectSearchValue,
                                                                legalEntity: result
                                                            });
                                                        }}
                                                        onChange={(data, value) => {
                                                            setDomainValue(false)
                                                            setDomain([])
                                                            setDomainFilter([])
                                                            if (data !== undefined) {
                                                                setConsolidatedData({

                                                                    ...consolidatedData,
                                                                    legel_entity: data,
                                                                    entity_name: value.children,
                                                                    legalentity_history: '',
                                                                    category: "",
                                                                    user_type: "All",
                                                                    unit: '',
                                                                    user: "",
                                                                    domain: "",
                                                                    act: "",
                                                                    from_date: "",
                                                                    task_category: "",
                                                                    compliance_task: "",
                                                                    to_date: "",
                                                                    task_sub_category: "",
                                                                    compliance_frequency: "",
                                                                    compliance_task_status: "All",
                                                                    division: "",
                                                                    domainName: "",
                                                                    complianceName: "",
                                                                    doamin_history: '',
                                                                    act_history: '',
                                                                    task_history: ''
                                                                })
                                                                setCurrentEntity(data)
                                                            }
                                                            else {
                                                                setConsolidatedData({

                                                                    ...consolidatedData,
                                                                    legel_entity: '',
                                                                    entity_name: '',
                                                                    category: "",
                                                                    user_type: "All",
                                                                    unit: '',
                                                                    user: "",
                                                                    domain: "",
                                                                    act: "",
                                                                    from_date: "",
                                                                    task_category: "",
                                                                    compliance_task: "",
                                                                    to_date: "",
                                                                    task_sub_category: "",
                                                                    compliance_frequency: "",
                                                                    compliance_task_status: "All",
                                                                    division: "",
                                                                    domainName: "",
                                                                    complianceName: "",
                                                                })
                                                                setCurrentEntity('')
                                                            }
                                                        }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        //value={Settings.le_id}
                                                        style={{ width: '100%', marginTop: '2px' }}
                                                        disabled={consolidatedData.country == '' ? true : false}

                                                    >

                                                        {entity && entity.length > 0 && entity.map((item, i) => {
                                                            return (
                                                                <Option key={item.le_id}>
                                                                    {item.le_name}
                                                                </Option>
                                                            );
                                                        })}


                                                    </Select>

                                                    {validator.current.message(
                                                        'legalEntity',
                                                        consolidatedData.legel_entity,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Legal Entity Required',
                                                            }
                                                        })}

                                                </div> :
                                                <p>{localStorage.getItem('SelectedEntity')}</p>
                                            }

                                        </div>
                                        <div className='col-md-4'>
                                            <label><b>Domain : </b> <span style={{ color: "red" }}>*</span></label>
                                            {domainValue == false || domainFilter && domainFilter.length > 1 ?
                                                <>
                                                    <Select
                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        allowClear={true}
                                                        disabled={((localStorage.getItem('SelectedEntity') === "All Legal Entity") && consolidatedData.legel_entity == '') ? true : false}
                                                        size="default"
                                                        value={consolidatedData.domainName || undefined}
                                                        placeholder="Enter Domain Name"
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        // onPaste={(e) => {
                                                        //     handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                        // }}
                                                        onPaste={onPasteToaster}
                                                        searchValue={selectSearchValue.domain}
                                                        onSearch={(value) => {
                                                            let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                                            setSelectSearchValue({
                                                                ...selectSearchValue,
                                                                domain: result
                                                            });
                                                        }}
                                                        onChange={(data, value) => {
                                                            getDomainBasedRecords(data)
                                                            if (data !== undefined) {
                                                                setConsolidatedData({
                                                                    ...consolidatedData,
                                                                    domain: data,
                                                                    domainName: value.children,
                                                                    doamin_history: value.children,
                                                                    category: "",
                                                                    user_type: "All",
                                                                    unit: '',
                                                                    user: "",
                                                                    act: "",
                                                                    from_date: "",
                                                                    task_category: "",
                                                                    compliance_task: "",
                                                                    to_date: "",
                                                                    task_sub_category: "",
                                                                    compliance_frequency: "",
                                                                    compliance_task_status: "All",
                                                                    division: "",
                                                                    complianceName: "",

                                                                })
                                                            }
                                                            else {

                                                                setActs([])
                                                                setComplianceTask([])
                                                                setConsolidatedData({
                                                                    ...consolidatedData,
                                                                    domain: '',
                                                                    domainName: '',
                                                                    doamin_history: '',
                                                                    category: "",
                                                                    user_type: "All",
                                                                    unit: '',
                                                                    user: "",
                                                                    act: "",
                                                                    from_date: "",
                                                                    task_category: "",
                                                                    compliance_task: "",
                                                                    to_date: "",
                                                                    task_sub_category: "",
                                                                    compliance_frequency: "",
                                                                    compliance_task_status: "All",
                                                                    division: "",
                                                                    complianceName: "",
                                                                })
                                                            }
                                                        }

                                                        }
                                                        style={{ width: '100%', marginTop: '2px' }}

                                                    >
                                                        {domainFilter && domainFilter.length > 0 && domainFilter.map((item, i) => {
                                                            return (
                                                                <Option key={item.d_id}>
                                                                    {item.d_name}
                                                                </Option>
                                                            );
                                                        })}

                                                    </Select>
                                                    {validator.current.message(
                                                        'domain',
                                                        consolidatedData.domain,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Domain Required',
                                                            }
                                                        })}
                                                </>
                                                : <p className='cut-text-word' style={{ width: '100%', marginTop: "5px" }}>{domain && domain.length > 0 && domain[0].d_name}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-12' style={{ marginTop: "5px" }}>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <label><b>Act/Rules : </b><span style={{ color: "red" }}>*</span></label>
                                            <Select
                                                getPopupContainer={trigger => trigger.parentNode}
                                                value={consolidatedData.act || undefined}
                                                disabled={consolidatedData.domain == '' ? true : false}
                                                allowClear={true}
                                                size="default"
                                                placeholder="Enter Act"
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                onDrop={(e) => {
                                                    e.preventDefault();
                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                    return false;
                                                }}
                                                // onPaste={(e) => {
                                                //     handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                // }}
                                                onPaste={onPasteToaster}
                                                searchValue={selectSearchValue.actRules}
                                                onSearch={(value) => {
                                                    let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                    setSelectSearchValue({
                                                        ...selectSearchValue,
                                                        actRules: result
                                                    });
                                                }}
                                                onChange={(data, value) => {
                                                    if (data !== undefined) {
                                                        // let filteredActObj = _.find(filterValue && filterValue.Data && filterValue.Data.tsk_act_legal_entity, { compliance_id: Number(data) })
                                                        let filteredComplianceStatus = _.filter(filterValue && filterValue.Data && filterValue.Data.compliance_task_wise_list, { statutory_mapping: data })
                                                        setComplianceTask(filteredComplianceStatus)
                                                        setConsolidatedData({
                                                            ...consolidatedData,
                                                            act: value.children,
                                                            act_history: value.children,
                                                            category: "",
                                                            user_type: "All",
                                                            unit: '',
                                                            user: "",
                                                            from_date: "",
                                                            task_category: "",
                                                            compliance_task: "",
                                                            to_date: "",
                                                            task_sub_category: "",
                                                            compliance_frequency: "",
                                                            compliance_task_status: "All",
                                                            division: "",
                                                            complianceName: "",
                                                        })
                                                    }
                                                    else {
                                                        setComplianceTask([])
                                                        setConsolidatedData({
                                                            ...consolidatedData,
                                                            act: '',
                                                            category: "",
                                                            user_type: "All",
                                                            unit: '',
                                                            user: "",
                                                            from_date: "",
                                                            task_category: "",
                                                            compliance_task: "",
                                                            to_date: "",
                                                            task_sub_category: "",
                                                            compliance_frequency: "",
                                                            compliance_task_status: "All",
                                                            division: "",
                                                            complianceName: "",
                                                            task_history: '',
                                                        })
                                                        setComplianceTask([])
                                                    }
                                                }
                                                }
                                                style={{ width: '100%', marginTop: '2px' }}

                                            >
                                                {Acts && Acts.length > 0 && Acts.map((item, i) => {
                                                    return (
                                                        <Option key={item.statutory_mapping}>
                                                            {item.statutory_mapping}
                                                        </Option>
                                                    );
                                                })}

                                            </Select>

                                            {validator.current.message(
                                                'Act',
                                                consolidatedData.act,
                                                'required',
                                                {
                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: 'Act Required',
                                                    }
                                                })}
                                        </div>
                                        <div className='col-md-4'>
                                            <label><b>Compliance Task : </b><span style={{ color: "red" }}>*</span></label>
                                            <Select
                                                getPopupContainer={trigger => trigger.parentNode}
                                                allowClear={true}
                                                disabled={consolidatedData.act == '' ? true : false}
                                                value={consolidatedData.compliance_task || undefined}
                                                size="default"
                                                placeholder="Enter Compliance Task"
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                onDrop={(e) => {
                                                    e.preventDefault();
                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                    return false;
                                                }}
                                                onPaste={(e) => {
                                                    handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                }}
                                                onChange={(data, value) => {
                                                    ComplianceTaskOnChange(data, value)
                                                }}
                                                style={{ width: '100%', marginTop: '2px' }}

                                            >
                                                {ComplianceTask && ComplianceTask.length > 0 && ComplianceTask.map((item, i) => {
                                                    return (
                                                        <Option key={item.compliance_id}>
                                                            {item.compliance_task}
                                                        </Option>
                                                    );
                                                })}

                                            </Select>
                                            {validator.current.message(
                                                'domain',
                                                consolidatedData.compliance_task,
                                                'required',
                                                {
                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: 'Compliance Task Required',
                                                    }
                                                })}



                                        </div>
                                        <div className='col-md-4'>
                                            <label><b>Division : </b></label>
                                            <Select
                                                getPopupContainer={trigger => trigger.parentNode}
                                                allowClear={true}
                                                size="default"
                                                disabled={consolidatedData.compliance_task == '' ? true : false}
                                                value={consolidatedData.division || undefined}
                                                placeholder="Enter Division Name"
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                onDrop={(e) => {
                                                    e.preventDefault();
                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                    return false;
                                                }}
                                                // onPaste={(e) => {
                                                //     handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                // }}
                                                onPaste={onPasteToaster}
                                                searchValue={selectSearchValue.division}
                                                onSearch={(value) => {
                                                    let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                    setSelectSearchValue({
                                                        ...selectSearchValue,
                                                        division: result
                                                    });
                                                }}
                                                onChange={(data) => {
                                                    getDivisionBasedRecords(data)
                                                    if (data !== undefined) {
                                                        setConsolidatedData({
                                                            ...consolidatedData,
                                                            division: data,
                                                            category: '',
                                                            unit: ''
                                                        })

                                                    }
                                                    else {
                                                        setConsolidatedData({
                                                            ...consolidatedData,
                                                            division: '',
                                                            category: '',
                                                            unit: ''
                                                        })


                                                    }
                                                }
                                                }
                                                style={{ width: '100%', marginTop: '2px' }}

                                            >
                                                {division && division.length > 0 && division.map((item, i) => {
                                                    return (
                                                        <Option key={item.div_id}>
                                                            {item.div_name}
                                                        </Option>
                                                    );
                                                })}

                                            </Select>
                                        </div>




                                    </div>
                                </div>
                                <div className='col-md-12' style={{ marginTop: "5px" }}>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <label><b>Category : </b></label>
                                            <Select
                                                getPopupContainer={trigger => trigger.parentNode}
                                                value={consolidatedData.category || undefined}
                                                disabled={consolidatedData.compliance_task == '' ? true : false}
                                                allowClear={true}
                                                size="default"
                                                placeholder="Enter Category Name"
                                                onDrop={(e) => {
                                                    e.preventDefault();
                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                    return false;
                                                }}
                                                // onPaste={(e) => {
                                                //     handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                // }}
                                                onPaste={onPasteToaster}
                                                searchValue={selectSearchValue.category}
                                                onSearch={(value) => {
                                                    let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                    setSelectSearchValue({
                                                        ...selectSearchValue,
                                                        category: result
                                                    });
                                                }}
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                onChange={(data) => {
                                                    getCategoryBasedRecords(data)
                                                    if (data !== undefined) {

                                                        setConsolidatedData({
                                                            ...consolidatedData,
                                                            category: data,
                                                            unit: '',
                                                        })
                                                    }
                                                    else {
                                                        setConsolidatedData({
                                                            ...consolidatedData,
                                                            category: '',
                                                            unit: ''
                                                        })
                                                    }
                                                    // let unitData = _.filter(units && units.length > 0 && units, { category_id: Number(data) })
                                                    // setUnits(unitData)
                                                }
                                                }
                                                // value={compfie.user_Group}
                                                style={{ width: '100%', marginTop: '2px' }}

                                            >
                                                {category && category.length > 0 && category.map((item, i) => {
                                                    return (
                                                        <Option key={item.cat_id}>
                                                            {item.cat_name}
                                                        </Option>
                                                    );
                                                })}

                                            </Select>
                                        </div>
                                        <div className='col-md-4'>
                                            <label><b>Vendor : </b></label>
                                            <Select
                                                getPopupContainer={trigger => trigger.parentNode}
                                                value={consolidatedData.unit || undefined}
                                                disabled={consolidatedData.compliance_task == '' ? true : false}
                                                allowClear={true}
                                                size="default"
                                                placeholder="Enter Vendor"
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                onDrop={(e) => {
                                                    e.preventDefault();
                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                    return false;
                                                }}
                                                // onPaste={(e) => {
                                                //     handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                // }}
                                                onPaste={onPasteToaster}
                                                searchValue={selectSearchValue.vendor}
                                                onSearch={(value) => {
                                                    let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                    setSelectSearchValue({
                                                        ...selectSearchValue,
                                                        vendor: result
                                                    });
                                                }}
                                                onChange={(data) => {
                                                    if (data !== undefined) {
                                                        setConsolidatedData({
                                                            ...consolidatedData,
                                                            unit: data
                                                        })
                                                    }
                                                    else {
                                                        setConsolidatedData({
                                                            ...consolidatedData,
                                                            unit: ''
                                                        })
                                                    }
                                                }
                                                }
                                                style={{ width: '100%', marginTop: '2px' }}

                                            >
                                                {units && units.length > 0 && units.map((item, i) => {
                                                    return (
                                                        <Option key={item.unit_id}>
                                                            {`${item.unit_code}-${item.unit_name}`}
                                                        </Option>
                                                    );
                                                })}

                                            </Select>
                                        </div>
                                        <div className='col-md-4'>
                                            <label><b>User Type : </b></label>
                                            <Select
                                                getPopupContainer={trigger => trigger.parentNode}
                                                value={consolidatedData.user_type || undefined}
                                                disabled={consolidatedData.domain == '' ? true : false}
                                                allowClear={false}
                                                size="default"
                                                placeholder="Enter UserType"
                                                onDrop={(e) => {
                                                    e.preventDefault();
                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                    return false;
                                                }}
                                                onPaste={(e) => {
                                                    handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                }}
                                                // showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                onChange={(data) => {
                                                    getUserTypeBasedRecords(data)
                                                    if (data !== undefined) {
                                                        setConsolidatedData({
                                                            ...consolidatedData,
                                                            user_type: data
                                                        })
                                                    }
                                                    else {
                                                        setConsolidatedData({
                                                            ...consolidatedData,
                                                            user_type: ''
                                                        })
                                                    }
                                                }
                                                }
                                                // value={compfie.user_Group}
                                                style={{ width: '100%', marginTop: '2px' }}

                                            >
                                                <Option key={'All'}>All</Option>
                                                {userType && userType.length > 0 && userType.map((item, i) => {
                                                    return (
                                                        <Option key={item.user_type}>
                                                            {item.user_type}
                                                        </Option>
                                                    );
                                                })}

                                            </Select>
                                        </div>



                                    </div>
                                </div>
                                <div className='col-md-12' style={{ marginTop: "5px" }}>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <label><b>User : </b></label>
                                            <Select
                                                getPopupContainer={trigger => trigger.parentNode}
                                                value={consolidatedData.user || undefined}

                                                allowClear={true}
                                                disabled={consolidatedData.compliance_task == '' ? true : false}
                                                size="default"
                                                placeholder="Enter User"
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                onDrop={(e) => {
                                                    e.preventDefault();
                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                    return false;
                                                }}
                                                // onPaste={(e) => {
                                                //     handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                // }}
                                                onPaste={onPasteToaster}
                                                searchValue={selectSearchValue.user}
                                                onSearch={(value) => {
                                                    let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                    setSelectSearchValue({
                                                        ...selectSearchValue,
                                                        user: result
                                                    });
                                                }}
                                                onChange={(data) => {
                                                    if (data !== undefined) {
                                                        setConsolidatedData({
                                                            ...consolidatedData,
                                                            user: data
                                                        })
                                                    }
                                                    else {
                                                        setConsolidatedData({
                                                            ...consolidatedData,
                                                            user: ''
                                                        })
                                                    }
                                                }
                                                }
                                                style={{ width: '100%', marginTop: '2px' }}

                                            >
                                                {user && user.length > 0 && user.map((item, i) => {
                                                    return (
                                                        <Option key={item.user_id}>
                                                            {item.user_value}
                                                        </Option>
                                                    );
                                                })}

                                            </Select>
                                        </div>
                                        <div className='col-md-4'>
                                            <label><b>From Date : </b> </label>
                                            <DatePicker
                                                format={'DD-MMM-YYYY'} allowClear={true} placement={'bottomLeft'} placeholder='DD-MMM-YYYY' style={{ width: '100%', marginTop: '2px' }}
                                                onDrop={(e) => {
                                                    e.preventDefault();
                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                    return false;
                                                }}
                                                onPaste={(e) => {
                                                    handlePasting(e, /^[a-z ]+$/gi)
                                                }}
                                                onKeyDown={(e) => {
                                                    e.preventDefault();
                                                    return false
                                                }}
                                                getPopupContainer={trigger => trigger.parentNode}
                                                value={consolidatedData.from_date ? moment(consolidatedData.from_date) : ''}
                                                onChange={(date, dateString) => {
                                                    if (dateString == '') {
                                                        setConsolidatedData({
                                                            ...consolidatedData,
                                                            from_date: '',
                                                            to_date: ''
                                                        })
                                                    } else {
                                                        setConsolidatedData({
                                                            ...consolidatedData,
                                                            from_date: dateString,
                                                            to_date: moment(dateString).add(3, 'months')
                                                        })
                                                    }

                                                }}

                                            />

                                            {/* <input type="date" className='form-control' style={{ width: '300px' }} /> */}
                                        </div>
                                        <div className='col-md-4'>
                                            <label><b>To Date : </b> </label>
                                            <DatePicker
                                                disabled={consolidatedData.from_date == '' ? true : false}

                                                format={'DD-MMM-YYYY'}
                                                allowClear={true}
                                                placeholder='DD-MMM-YYYY'
                                                style={{ width: '100%', marginTop: '2px' }}
                                                onDrop={(e) => {
                                                    e.preventDefault();
                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                    return false;
                                                }}
                                                onPaste={(e) => {
                                                    handlePasting(e, /^[a-z ]+$/gi)
                                                }}
                                                onKeyDown={(e) => {
                                                    e.preventDefault();
                                                    return false
                                                }}
                                                getPopupContainer={trigger => trigger.parentNode}
                                                onChange={(date, dateString) => {
                                                    console.log(date, 'datedate')
                                                    if (date != null) {
                                                        setConsolidatedData({
                                                            ...consolidatedData,
                                                            to_date: moment(dateString)
                                                        })
                                                    } else {
                                                        setConsolidatedData({
                                                            ...consolidatedData,
                                                            to_date: ''
                                                        })
                                                    }
                                                    // disablecondition(dateString)

                                                }}
                                                value={consolidatedData.to_date || undefined}
                                                disabledDate={(current) => {
                                                    return moment(consolidatedData.from_date).add(-1, 'days') >= current ||
                                                        moment(consolidatedData.from_date).add(365, 'days') < current;
                                                }}
                                            />

                                            {/* <input type="date" className='form-control' style={{ width: '300px' }} /> */}
                                        </div>

                                        <div className='col-md-4'>
                                            <label><b>Compliance Task Status : </b></label>
                                            <Select
                                                getPopupContainer={trigger => trigger.parentNode}
                                                disabled={consolidatedData.domain == '' ? true : false}
                                                value={consolidatedData.compliance_task_status || undefined}
                                                // showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                onDrop={(e) => {
                                                    e.preventDefault();
                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                    return false;
                                                }}
                                                onPaste={(e) => {
                                                    handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                }}
                                                onChange={(value) => {
                                                    if (value !== undefined) {
                                                        setConsolidatedData({
                                                            ...consolidatedData,
                                                            compliance_task_status: value
                                                        })
                                                    }
                                                    else {
                                                        setConsolidatedData({
                                                            ...consolidatedData,
                                                            compliance_task_status: ''
                                                        })
                                                    }
                                                }}
                                                // placeholder="Enter Domain Name"
                                                style={{ width: '100%', marginTop: '2px' }}

                                            >
                                                <Option key="All">All</Option>
                                                {complianceTaskStatus && complianceTaskStatus.length && complianceTaskStatus.map((item, i) => {
                                                    return (
                                                        <Option key={item.task_status}>
                                                            {item.task_status}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-12' style={{ marginTop: "5px" }}>
                                    <div className='row'>




                                    </div>
                                </div>
                                <div className='text-center mt-2 popupbtncolour'>
                                    <Button type="primary"
                                        className={
                                            "addbutton " +
                                            localStorage.getItem("currentTheme")
                                        }
                                        shape="round"
                                        onClick={onshow}
                                        icon={<EyeOutlined />}  >
                                        Show
                                    </Button>&nbsp;&nbsp;
                                    <Button
                                        type="primary"
                                        shape="round"
                                        className={'addbutton ' + localStorage.getItem('currentTheme')}
                                        onClick={showModal}
                                        icon={<ExportOutlined />} >
                                        Export
                                    </Button>
                                </div>
                                {/* <div className='text-center mt-2 popupbtncolour'>
                                    <Button type="primary"
                                            className={
                                                "addbutton " +
                                                localStorage.getItem("currentTheme")
                                            }
                                            shape="round"
                                            onClick={onshow}
                                            icon={<EyeOutlined />}  >
                                            Show
                                        </Button>
                                    <Button type="primary"
                                        className={
                                            "addbutton " +
                                            localStorage.getItem("currentTheme")
                                        }
                                        shape="round"
                                        icon={<ExportOutlined />} onClick={showModal}>
                                        Export
                                    </Button>

                                </div> */}
                            </Modal>



                            {tableshow == true ?
                                <Card className='rounded-3'>
                                    <div className='col-md-12 mt-0 mb-2 filter-detial-sticky' >
                                        <div className='row'>
                                            <div className='col-md-4 text-wrap-report'>
                                                <label style={{ wordBreak: 'break-all' }}><b>Legal Entity : </b>{localStorage.getItem('SelectedEntity') === 'All Legal Entity' ? consolidatedData.legalEntityLabel : localStorage.getItem('SelectedEntity')}</label>
                                            </div>
                                            <div className='col-md-4 text-center'>
                                                <label><b>Country : </b>{consolidatedData.countryLabel}</label>
                                            </div>
                                            <div className='col-md-4 text-end'>
                                                <label><b>Domain : </b>{consolidatedData.domainLabel}</label>
                                            </div>


                                        </div>
                                        <div className="col-md-12 mt-1">
                                            <div className="row">
                                                <div className='col-md-7 mt-1'>
                                                    <label><b>Act : </b>{consolidatedData.actLabel}</label>
                                                </div>
                                                <div className='col-md-5 mt-1'>
                                                    <label><b>Compliance Task </b>: {consolidatedData.complianceTaskLabel ? consolidatedData.complianceTaskLabel : ''}<Tooltip placement='bottomLeft' title={complianceData}>
                                                        <ExclamationCircleTwoTone className='ms-1' />
                                                    </Tooltip>{ComplianceTask && ComplianceTask.length > 0 && ComplianceTask[0].criticality_name != null ?
                                                        <span className='badgecolour ms-1' style={{ lineHeight: '18px' }}>{ComplianceTask && ComplianceTask[0].criticality_name}</span> : false}</label>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div >
                                        <div className='ant-table ant-table-bordered  ant-table-fixed-header'>
                                            <div className='ant-table-container'>
                                                <div className='ant-table-content userprivclass1'>

                                                    <table>
                                                        <colgroup>
                                                            <col style={{ width: '10px' }}></col>
                                                        </colgroup>
                                                        {/* antable-head */}
                                                        <thead className="ant-table-thead table-head-stick" >
                                                            <tr>
                                                                <th className='ant-table-cell' style={{ width: '20px' }}>#</th>

                                                                <th className='ant-table-cell' style={{ width: '100px' }}>Frequency</th>
                                                                <th className='ant-table-cell' style={{ width: '100px' }}><center>Due Date</center></th>
                                                                <th className='ant-table-cell' style={{ width: '120px' }}>Compliance Task Status</th>
                                                                <th className='ant-table-cell' style={{ width: '120px' }}><center>User Name</center></th>
                                                                <th className='ant-table-cell' style={{ width: '120px' }}><center>Activity Status</center></th>
                                                                <th className='ant-table-cell' style={{ width: '120px' }}><center>Activity Date</center></th>
                                                                <th className='ant-table-cell' style={{ width: '100px' }}><center>Uploaded Document</center></th>
                                                                <th className='ant-table-cell' style={{ width: '100px' }} title='Task Completion Date/Document issued Date'>Task Completion Date/Document issued Date</th>
                                                            </tr>
                                                        </thead>
                                                        {filteredArrayState && filteredArrayState.length > 0 ?
                                                            filteredArrayState.map((item, i) => {
                                                                console.log(item, 'kdsitem')
                                                                return (
                                                                    <tbody className='ant-table-tbody' key={i}>
                                                                        <tr className="bg-lightdark">
                                                                            <td style={{ paddingLeft: '0 !important', paddingRight: '0 !important' }} colSpan="100%">
                                                                                <div className='bg-white shadow-sm border-radius-reports'>
                                                                                    <div className="col-md-12">
                                                                                        <div className='row m-2'>
                                                                                            <div className='col-md-3' >
                                                                                                <label><b>Vendor :</b> {item[0].unit_name}</label>
                                                                                            </div>
                                                                                            <div className='col-md-3' >
                                                                                                <label><b>Division :</b> {item[0].division_name}</label>
                                                                                            </div>
                                                                                            <div className='col-md-3' >
                                                                                                <label><b>Category :</b> {item[0].category_name}</label>
                                                                                            </div>
                                                                                            <div className='col-md-3' >
                                                                                                <label><b>Time Zone :</b> {item[0].tz_name}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        {titledata && titledata.length > 0 && titledata.map((itemdata, i) => {
                                                                            if (itemdata.unit_id == item[0].unit_id) {
                                                                                let temp
                                                                                if (itemdata.document_name) {
                                                                                    let result = itemdata.document_name.replaceAll("||", ", ||");
                                                                                    temp = result.split("||")
                                                                                    console.log(temp, 'itemdata.child')
                                                                                }
                                                                                return <Fragment key={i}>

                                                                                    <tr>
                                                                                        <td className='ant-table-cell'>{index + 1}</td>
                                                                                        <td className='ant-table-cell'>{itemdata.frequency_name}</td>
                                                                                        <td className='ant-table-cell'><center>{itemdata.due_date}</center></td>
                                                                                        <td className='ant-table-cell'><center>{itemdata.task_status}</center></td>
                                                                                        {itemdata.child && itemdata.child.length > 0 ?
                                                                                            <td className='ant-table-cell'><center><Link onClick={(e) => { getChildDetails(itemdata.child) }}>{itemdata.assignee_name}</Link></center></td> :
                                                                                            <td className='ant-table-cell'><center>{itemdata.assignee_name}</center></td>
                                                                                        }
                                                                                        <td className='ant-table-cell text-center d-flex align-items-center justify-content-center'>{itemdata.activity_status}&nbsp;
                                                                                            <span className='ms-1 text-primary'>
                                                                                                {itemdata.history_count = 1 && itemdata.remarks_count == 1 ? <Tooltip title='Click to View Remarks History'>
                                                                                                    <i className='ri-information-fill' onClick={(e) => getRemarksList(itemdata)} /></Tooltip> : false}</span>
                                                                                            <span className='ms-1 text-primary'>{itemdata.interim_count != 0 ?
                                                                                                <Tooltip title='Click to View Interim History'>
                                                                                                    <i className='ri-information-fill' style={{ color: '#05A4FA' }}
                                                                                                        onClick={() => {
                                                                                                            setDatas({
                                                                                                                le_id: itemdata.legal_entity_id ? itemdata.legal_entity_id : consolidatedData.legel_entity,
                                                                                                                start_date: itemdata.start_date,
                                                                                                                country_id: itemdata.country_id ? itemdata.country_id : Number(consolidatedData.country),
                                                                                                                domain_id: itemdata.domain_id ? itemdata.domain_id : Number(consolidatedData.domain),
                                                                                                                unit_id: itemdata.unit_id
                                                                                                            })
                                                                                                            const payload = [
                                                                                                                authtoken,
                                                                                                                {
                                                                                                                    session_token: authtoken,
                                                                                                                    request: [
                                                                                                                        "GetInterimComplianceDetails",
                                                                                                                        {
                                                                                                                            "legal_entity_id": entityid,
                                                                                                                            "compliance_history_id": itemdata.compliance_history_id
                                                                                                                        }
                                                                                                                    ]
                                                                                                                },
                                                                                                            ];
                                                                                                            getInterimrecords({
                                                                                                                payload: payload,
                                                                                                                paramid: paramid,
                                                                                                            });
                                                                                                            setInterimModal(true)
                                                                                                        }
                                                                                                        }
                                                                                                    />
                                                                                                </Tooltip> : ''}</span>
                                                                                        </td>
                                                                                        <td className='ant-table-cell'><center>{itemdata.activity_date}</center></td>
                                                                                        {/* <td ><center><Link onClick={(e) => {
                                                                                            // downloaddocument(itemdata) 
                                                                                        }}>{
                                                                                                // temp && temp.length > 0 && temp
                                                                                                temp && temp.length > 0 && temp.map((itemcheck) => {
                                                                                                    return <span onClick={() => {
                                                                                                        downloaddocument(itemdata.unit_id, itemdata.start_date, itemcheck)
                                                                                                    }}>{itemcheck}</span>
                                                                                                })
                                                                                            }</Link></center></td> */}
                                                                                        <td className='ant-table-cell'><center>
                                                                                            {itemdata.document_name || itemdata.approval_document_names || itemdata.concur_document_name ?
                                                                                                <a href='javascript::'><FileTextOutlined style={{ fontSize: '20px' }} onClick={(e) => getDownloadRecords(itemdata)} /></a>
                                                                                                : false}
                                                                                        </center></td>
                                                                                        <td className='ant-table-cell'><center>{itemdata.completion_date != null ? itemdata.completion_date : '-'}</center></td>
                                                                                        <span hidden>{index = index + 1}</span>

                                                                                    </tr>
                                                                                </Fragment>
                                                                            }

                                                                        })
                                                                        }
                                                                    </tbody>
                                                                )
                                                            }) :
                                                            <tr>
                                                                <td colSpan="100%" style={{ paddingTop: '15px', textAlign: 'center', fontSize: '14px', fontWeight: 'bold', paddingBottom: '15px' }}>No Records Found</td>
                                                            </tr>
                                                        }
                                                    </table>
                                                    {/* <br /> */}
                                                    <Modal
                                                        title="Mapping Lists"
                                                        className={"remark-header modelradius add-service-prv " + localStorage.getItem("currentTheme")}
                                                        open={isModalOpenChild}
                                                        footer={null}
                                                        onOk={handleOkChild}
                                                        onCancel={handleCancelChild}
                                                        maskClosable={false}
                                                    >
                                                        <Table
                                                            className={localStorage.getItem("currentTheme")}
                                                            size={"small"}
                                                            columns={columnData}
                                                            dataSource={modalDataState}
                                                            bordered
                                                            pagination={false}
                                                        />
                                                    </Modal>

                                                    <Modal
                                                        title="Remarks History"
                                                        className={"remark-header modelradius add-service-prv " + localStorage.getItem("currentTheme")}
                                                        open={isRemarksModal}
                                                        footer={null}
                                                        onOk={handleOkRemarks}
                                                        onCancel={handleCancelRemarks}
                                                        maskClosable={false}
                                                    >
                                                        <Table
                                                            className={localStorage.getItem("currentTheme")}
                                                            size={"small"}
                                                            columns={remarksColumn}
                                                            dataSource={filterValue.remarks && filterValue.remarks.remarks_history && filterValue.remarks.remarks_history.length > 0 && filterValue.remarks.remarks_history}
                                                            bordered
                                                            pagination={false}
                                                        />
                                                    </Modal>


                                                    <Modal
                                                        open={interimModal}
                                                        className={" add-service-prv " + localStorage.getItem('currentTheme')}
                                                        title="Interim Status History"
                                                        footer={null}
                                                        onCancel={handleClose}
                                                        maskClosable={false}
                                                    >
                                                        <Table
                                                            style={{ marginTop: "2%" }}
                                                            className={localStorage.getItem("currentTheme")}
                                                            columns={interimcolumns}
                                                            dataSource={interimdata && interimdata}
                                                            bordered
                                                            pagination={false}
                                                        />
                                                    </Modal>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="row mt-3">
                                        {index != 0 ?
                                            <div className='col-md-6' >
                                                <label>Showing {pageSize * (current - 1) + 1} to {index} of {filterValue && filterValue.TaskWiseReportList.total_count} entries</label>
                                            </div> : ''}

                                        <div className='col-md-6 text-right'>
                                            <Pagination
                                                current={current}
                                                showSizeChanger={true}
                                                // showQuickJumper={true}
                                                onShowSizeChange={() => {
                                                    setCurrent(1)
                                                    // setpageState(true)
                                                }}
                                                pageSizeOptions={[25, 50, 100]} hideOnSinglePage={filterValue && filterValue.count > 25 ? false : true} defaultPageSize={25}
                                                onChange={(page, pageSizes) => {
                                                    setpageState(true)
                                                    setCurrent(pageSize !== pageSizes ? 1 : page);
                                                    setPageSize(pageSizes)
                                                }} total={filterValue && filterValue.count}
                                            // showTotal={(total) => `Showing 1 to ${index} of ${tableData.total_count} entries`}
                                            />
                                        </div>
                                    </div>
                                </Card>
                                : false}
                            {/* <Modal title="Select Fields" className={'add-service-prv ' + localStorage.getItem('currentTheme')} visible={isModalOpen} footer={null} onOk={handleOk} onCancel={handleCancel} width={100}>
                                <div className='row'>
                                    <div className='col-lg-3'><input id="select_all" width="100%" type="checkbox" autocomplete="off"
                                        checked={checkboxValue.length === 34 ? true : false}
                                        onClick={(e) => {
                                            let checked = e.target.checked
                                            selectAllCheckBox(checked)
                                        }} /> Select All</div>
                                </div>
                                <br />
                                <div className='row'>
                                    <div className='col-lg-3'><input id="column_1" name='chk' type="checkbox" checked disabled="disabled" value='1' onChange={(e) => (e) => checkBoxOnChange(e)} /> Unit Code</div>
                                    <div className='col-lg-3'><input id="column_2" name='chk' type="checkbox" checked disabled="disabled" value='2' onChange={(e) => checkBoxOnChange(e)} /> Unit Name</div>
                                    <div className='col-lg-3'><input id="column_7" name='chk' type="checkbox" value='3' onChange={(e) => checkBoxOnChange(e)} /> Unit Code 1</div>
                                    <div className='col-lg-3'><input id="column_7" name='chk' type="checkbox" value='4' onChange={(e) => checkBoxOnChange(e)} /> Unit Code 2</div>

                                </div>
                                <br />
                                <div className='row'>
                                    <div className='col-lg-3'><input id="column_8" name='chk' type="checkbox" value='5' onChange={(e) => checkBoxOnChange(e)} /> Unit Code 3 </div>
                                    <div className='col-lg-3'><input id="column_9" name='chk' type="checkbox" value='6' onChange={(e) => checkBoxOnChange(e)} /> Unit Code 4 </div>
                                    <div className='col-lg-3'><input id="column_10" name='chk' type="checkbox" value='7' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} />Act / Rules</div>
                                    <div className='col-lg-3'><input id="column_29" name='chk' type="checkbox" value='8' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> Frequency</div>
                                </div>
                                <br />
                                <div className='row'>
                                    <div className='col-lg-3'><input id="column_32" name='chk' type="checkbox" value='9' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> Task Category</div>
                                    <div className='col-lg-3'><input id="column_3" name='chk' type="checkbox" value='10' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> Task sub Category </div>
                                    <div className='col-lg-3'><input id="column_4" name='chk' type="checkbox" value='11' onChange={(e) => checkBoxOnChange(e)} /> Periodicity </div>
                                    <div className='col-lg-3'><input id="column_5" name='chk' type="checkbox" value='12' onChange={(e) => checkBoxOnChange(e)} /> Criticality</div>
                                </div>
                                <br />
                                <div className='row'>
                                    <div className='col-lg-3'><input id="column_14" name='chk' type="checkbox" value='13' onChange={(e) => checkBoxOnChange(e)} /> Division</div>
                                    <div className='col-lg-3'><input id="column_15" name='chk' type="checkbox" value='14' onChange={(e) => checkBoxOnChange(e)} /> Category</div>
                                    <div className='col-lg-3'><input id="column_16" name='chk' type="checkbox" value='15' onChange={(e) => checkBoxOnChange(e)} /> Assigned By</div>
                                    <div className='col-lg-3'><input id="column_17" name='chk' type="checkbox" value='16' onChange={(e) => checkBoxOnChange(e)} /> Assignee To</div>
                                </div>
                                <br />
                                <div className='row'>
                                    <div className='col-lg-3'><input id="column_18" name='chk' type="checkbox" value='17' onChange={(e) => checkBoxOnChange(e)} /> Assigned Date</div>
                                    <div className='col-lg-3'><input id="column_19" name='chk' type="checkbox" value='18' onChange={(e) => checkBoxOnChange(e)} /> Assignee</div>
                                    <div className='col-lg-3'><input id="column_20" name='chk' type="checkbox" value='19' onChange={(e) => checkBoxOnChange(e)} /> Completed On</div>
                                    <div className='col-lg-3'><input id="column_21" name='chk' type="checkbox" value='20' onChange={(e) => checkBoxOnChange(e)} /> Concurrer </div>
                                </div>
                                <br />
                                <div className='row'>
                                    <div className='col-lg-3'><input id="column_22" name='chk' type="checkbox" value='21' onChange={(e) => checkBoxOnChange(e)} /> Concurrer On </div>
                                    <div className='col-lg-3'><input id="column_23" name='chk' type="checkbox" value='22' onChange={(e) => checkBoxOnChange(e)} /> Approver </div>
                                    <div className='col-lg-3'><input id="column_24" name='chk' type="checkbox" value='23' onChange={(e) => checkBoxOnChange(e)} /> Approver On </div>
                                    <div className='col-lg-3'><input id="column_25" name='chk' type="checkbox" value='24' onChange={(e) => checkBoxOnChange(e)} /> Activity Status</div>
                                </div>
                                <br />
                                <div className='row'>
                                    <div className='col-lg-3'><input id="column_26" name='chk' type="checkbox" value='25' onChange={(e) => checkBoxOnChange(e)} /> Start Date</div>
                                    <div className='col-lg-3'><input id="column_27" name='chk' type="checkbox" value='26' onChange={(e) => checkBoxOnChange(e)} /> Due Date</div>
                                    <div className='col-lg-3'><input id="column_28" name='chk' type="checkbox" value='27' onChange={(e) => checkBoxOnChange(e)} />Extend Due Date </div>
                                    <div className='col-lg-3'><input id="column_28" name='chk' type="checkbox" value='28' onChange={(e) => checkBoxOnChange(e)} /> Task Completion Date / Document Issued Date </div>

                                </div>
                                <br />
                                <div className='row'>
                                    <div className='col-lg-3'><input id="column_31" name='chk' type="checkbox" value='29' onChange={(e) => checkBoxOnChange(e)} /> Month</div>
                                    <div className='col-lg-3'><input id="column_31" name='chk' type="checkbox" value='30' onChange={(e) => checkBoxOnChange(e)} /> Validity Date</div>
                                    <div className='col-lg-3'><input id="column_31" name='chk' type="checkbox" value='31' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> Statutory Status</div>
                                    <div className='col-lg-3'><input id="column_31" name='chk' type="checkbox" value='32' onChange={(e) => checkBoxOnChange(e)} /> Duration</div>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-3'><input id="column_31" name='chk' type="checkbox" value='33' onChange={(e) => checkBoxOnChange(e)} /> Document Reference Number</div>
                                    <div className='col-lg-3'><input id="column_31" name='chk' type="checkbox" value='34' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> Remarks</div>

                                </div>
                                <div className="form-actions text-center popupbtncolour">
                                    <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')}
                                        icon={<PlayCircleOutlined />} size='default' onClick={handleOk}> Submit
                                    </Button>
                                </div>

                            </Modal> */}
                            <Drawer title="Select Fields" width={500} className={'add-service-prv export-checkbox resize-drawer ' + localStorage.getItem('currentTheme')} open={isModalOpen} footer={null} onClose={handleCancel} onCancel={handleCancel} maskClosable={false}>
                                <div className='d-flex flex-wrap'>
                                    <input id="select_all" width="100%" type="checkbox" autocomplete="off"
                                        checked={checkboxValue.length === 34 ? true : false}
                                        onClick={(e) => {
                                            let checked = e.target.checked
                                            selectAllCheckBox(checked)
                                        }} /> <label for='select_all'>{selectAllText}</label>

                                    <input id="column_1" name='chk' type="checkbox" disabled="disabled" value='1' checked onChange={(e) => (e) => checkBoxOnChange(e)} /><label className='cursornotallow' for='column_1'>  Vendor Code</label>
                                    <input id="column_2" name='chk' type="checkbox" disabled="disabled" value='2' checked onChange={(e) => checkBoxOnChange(e)} /><label className='cursornotallow' for='column_2'>  Vendor Name</label>
                                    <input id="column_8" name='chk' type="checkbox" disabled value='8' checked onChange={(e) => checkBoxOnChange(e)} /><label className='cursornotallow' for='column_8'>  Act / Rules </label>
                                    <input id="column_10" name='chk' type="checkbox" disabled="disabled" value='10' checked onChange={(e) => checkBoxOnChange(e)} /><label className='cursornotallow' for='column_10'>  Frequency</label>
                                    <input id="column_7" name='chk' type="checkbox" value='7' disabled="disabled" checked onChange={(e) => checkBoxOnChange(e)} /><label className='cursornotallow' for='column_7'>Location </label>
                                    <input id="column_9" name='chk' type="checkbox" value='9' disabled="disabled" checked onChange={(e) => checkBoxOnChange(e)} /><label className='cursornotallow' for='column_9'>  Compliance Task</label>
                                    <input id="column_31" name='chk' type="checkbox" value='31' disabled="disabled" checked onChange={(e) => checkBoxOnChange(e)} /><label className='cursornotallow' for='column_31'>  Statutory Status</label>
                                    <input id="column_34" checked disabled="disabled" name='chk' type="checkbox" value='34' onChange={(e) => checkBoxOnChange(e)} /><label className='cursornotallow' for='column_34'>   Remarks </label>

                                    <input id="column_3" name='chk' type="checkbox" checked={checkboxValue.includes(3)} value='3' onChange={(e) => checkBoxOnChange(e)} /><label for='column_3'>  Vendor Code 1</label>
                                    <input id="column_4" name='chk' type="checkbox" checked={checkboxValue.includes(4)} value='4' onChange={(e) => checkBoxOnChange(e)} /><label for='column_4'>  Vendor Code 2 </label>
                                    <input id="column_5" name='chk' type="checkbox" checked={checkboxValue.includes(5)} value='5' onChange={(e) => checkBoxOnChange(e)} /><label for='column_5'>  Vendor Code 3 </label>
                                    <input id="column_6" name='chk' type="checkbox" checked={checkboxValue.includes(6)} value='6' onChange={(e) => checkBoxOnChange(e)} /><label for='column_6'>  Vendor Code 4</label>
                                    <input id="column_11" name='chk' type="checkbox" checked={checkboxValue.includes(11)} value='11' onChange={(e) => checkBoxOnChange(e)} /><label for='column_11'> Periodicity</label>
                                    <input id="column_12" name='chk' type="checkbox" checked={checkboxValue.includes(12)} value='12' onChange={(e) => checkBoxOnChange(e)} /><label for='column_12'>  Criticality</label>
                                    <input id="column_13" name='chk' type="checkbox" checked={checkboxValue.includes(13)} value='13' onChange={(e) => checkBoxOnChange(e)} /><label for='column_13'>  Division</label>
                                    <input id="column_14" name='chk' type="checkbox" checked={checkboxValue.includes(14)} value='14' onChange={(e) => checkBoxOnChange(e)} /><label for='column_14'>  Category</label>
                                    <input id="column_15" name='chk' type="checkbox" checked={checkboxValue.includes(15)} value='15' onChange={(e) => checkBoxOnChange(e)} /><label for='column_15'>  Assigned by </label>
                                    <input id="column_16" name='chk' type="checkbox" checked={checkboxValue.includes(16)} value='16' onChange={(e) => checkBoxOnChange(e)} /><label for='column_16'>  Assigned To </label>
                                    <input id="column_17" name='chk' type="checkbox" checked={checkboxValue.includes(17)} value='17' onChange={(e) => checkBoxOnChange(e)} /><label for='column_17'>  Assigned Date </label>
                                    <input id="column_18" name='chk' type="checkbox" checked={checkboxValue.includes(18)} value='18' onChange={(e) => checkBoxOnChange(e)} /><label for='column_18'>  Assignee </label>
                                    <input id="column_19" name='chk' type="checkbox" checked={checkboxValue.includes(19)} value='19' onChange={(e) => checkBoxOnChange(e)} /><label for='column_19'>  Completed on </label>
                                    <input id="column_20" name='chk' type="checkbox" checked={checkboxValue.includes(20)} value='20' onChange={(e) => checkBoxOnChange(e)} /><label for='column_20'>  Concurrer </label>
                                    <input id="column_21" name='chk' type="checkbox" checked={checkboxValue.includes(21)} value='21' onChange={(e) => checkBoxOnChange(e)} /><label for='column_21'>  Concurred_on </label>
                                    <input id="column_22" name='chk' type="checkbox" checked={checkboxValue.includes(22)} value='22' onChange={(e) => checkBoxOnChange(e)} /><label for='column_22'> Approver</label>
                                    <input id="column_23" name='chk' type="checkbox" checked={checkboxValue.includes(23)} value='23' onChange={(e) => checkBoxOnChange(e)} /><label for='column_23'>   Approved_on  </label>
                                    <input id="column_24" name='chk' type="checkbox" checked={checkboxValue.includes(24)} value='24' onChange={(e) => checkBoxOnChange(e)} /><label for='column_24'> Activity Status</label>
                                    <input id="column_25" name='chk' type="checkbox" checked={checkboxValue.includes(25)} value='25' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_25'>  Start Date </label>
                                    <input id="column_26" name='chk' type="checkbox" checked={checkboxValue.includes(26)} value='26' onChange={(e) => checkBoxOnChange(e)} /><label for='column_26'>  Due Date</label>
                                    <input id="column_27" name='chk' type="checkbox" checked={checkboxValue.includes(27)} value='27' onChange={(e) => checkBoxOnChange(e)} /><label for='column_27'> Extend Due Date</label>
                                    <input id="column_28" name='chk' type="checkbox" checked={checkboxValue.includes(28)} value='28' onChange={(e) => checkBoxOnChange(e)} /><label for='column_28'>   Task Completion Date / Document Issued Date</label>
                                    <input id="column_29" name='chk' type="checkbox" checked={checkboxValue.includes(29)} value='29' onChange={(e) => checkBoxOnChange(e)} /><label for='column_29'> Month</label>
                                    <input id="column_30" name='chk' type="checkbox" checked={checkboxValue.includes(30)} value='30' onChange={(e) => checkBoxOnChange(e)} /><label for='column_30'>   Validity Date</label>
                                    <input id="column_32" name='chk' type="checkbox" checked={checkboxValue.includes(32)} value='32' onChange={(e) => checkBoxOnChange(e)} /><label for='column_32'>  Duration </label>
                                    <input id="column_33" name='chk' type="checkbox" checked={checkboxValue.includes(33)} value='33' onChange={(e) => checkBoxOnChange(e)} /><label for='column_33'> Document Reference Number </label>


                                </div>
                                <div className="form-actions popupbtncolour text-center">
                                    <Button type="primary" shape="round" className={'addbutton mt-3 ' + localStorage.getItem('currentTheme')}
                                        icon={<PlayCircleOutlined />} size='default' onClick={handleOk}> Submit
                                    </Button>

                                </div>
                            </Drawer>
                        </div>
                    </div>
                </div>
            </div>

            <Modal open={uploadModal}
                title="Uploaded Documents"
                className={" add-service-prv " + localStorage.getItem('currentTheme')}
                footer={null}
                onCancel={modalOnChane}
                maskClosable={false}
            >
                <div className={localStorage.getItem("currentTheme") + ' ant-table-wrapper'}>
                    <div className='ant-table ant-table-bordered ant-table-fixed-header'>
                        <div className='ant-table-container'>
                            <div className='ant-table-content  userprivclass1'></div>
                            <table>
                                <colgroup>
                                    <col style={{ width: '10px' }}></col>
                                </colgroup>
                                <thead className="ant-table-thead table-head-stick" >
                                    <tr>
                                        <th className={'ant-table-cell'} style={{ width: '10px' }}>#</th>
                                        <th className={'ant-table-cell'} style={{ width: '20px' }}>Assignee</th>
                                        <th className={'ant-table-cell'} style={{ width: '300px' }}>Concurrer</th>
                                        <th className={'ant-table-cell'} style={{ width: '30px' }}>Approver</th>

                                    </tr>
                                </thead>
                                <tbody className='ant-table-tbody'>
                                    {commonArray && commonArray.length > 0 && commonArray.map((commonList) => {
                                        return (
                                            <tr>
                                                <td className={'ant-table-cell'}>{sno + 1}</td>
                                                <td className={'ant-table-cell'}><Link onClick={() => {
                                                    downloaddocument(modalData.unit_id, modalData.start_date, commonList.assignee)
                                                }}>{commonList.assignee ? commonList.assignee : ''}</Link></td>
                                                <td className={'ant-table-cell'}><Link onClick={() => {
                                                    downloaddocument(modalData.unit_id, modalData.start_date, commonList.concur)
                                                }}>{commonList.concur ? commonList.concur : ''}</Link></td>
                                                <td className={'ant-table-cell'}><Link onClick={() => {
                                                    downloaddocument(modalData.unit_id, modalData.start_date, commonList.approver)
                                                }}>{commonList.approver ? commonList.approver : ''}</Link></td>
                                                <span hidden>
                                                    {
                                                        (sno = sno + 1)
                                                    }
                                                </span>
                                            </tr>
                                        )
                                    })}
                                    {/*  <tr>
                                        <td>{sno + 1}</td>
                                       <td><center>
                                            {
                                                documentName && documentName.length > 0 && documentName.map((itemcheckassignee) => {
                                                    return (<><Link onClick={() => {
                                                        downloaddocument(modalData.unit_id, modalData.start_date, itemcheckassignee)
                                                    }}>{modalData.doc_ref_num ? modalData.doc_ref_num : itemcheckassignee}</Link><br /></>)
                                                })
                                            }

                                        </center></td>
                                        <td><center>
                                            {
                                                concurDocName && concurDocName.length > 0 && concurDocName.map((itemcheckconcur) => {
                                                    return <><Link onClick={() => {
                                                        downloaddocument(modalData.unit_id, modalData.start_date, itemcheckconcur)
                                                    }}>{modalData.doc_ref_num ? modalData.doc_ref_num : itemcheckconcur}</Link><br /></>
                                                })
                                            }
                                        </center></td>

                                        <td><center>
                                            {
                                                approvalDocName && approvalDocName.length > 0 && approvalDocName.map((itemcheckappr) => {
                                                    return <><Link onClick={() => {
                                                        downloaddocument(modalData.unit_id, modalData.start_date, itemcheckappr)
                                                    }}>{modalData.doc_ref_num ? modalData.doc_ref_num : itemcheckappr}</Link><br /></>
                                                })
                                            }
                                        </center></td>

                                    </tr>*/}


                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>

    )
})
export default connect(mapStateToProps, {
    getFilterValues,
    getUnitData,
    getTaskwiseReportData,
    getTaskWiseExportData,
    getRemarksFilter,
    getdownloaddocument,
    getInterimrecords,
    Downloadfile
})(TaskWiseReport);