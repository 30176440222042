import React, { useEffect, useState, useRef, Fragment } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  Route,
  Switch,
  BrowserRouter,
  Link,
  useLocation,
  useHistory
} from "react-router-dom";
import {
  Collapse,
  Input,
  Modal,
  Table,
  List,
  Row,
  Select,
  Tooltip,
  Card,
  Button,
  Popover,
  Drawer,
  Space,
  Popconfirm,
  Checkbox,
  Form,
} from "antd";
import {
  PlusOutlined,
  PlusSquareTwoTone,
  FullscreenOutlined,
  InfoCircleOutlined,
  SearchOutlined,
  FilterFilled,
  PlayCircleOutlined,
  PlusCircleOutlined,
  EyeOutlined,
  ExclamationCircleOutlined,
  UpOutlined,
  FilterOutlined,
  ExclamationCircleTwoTone,
  FilterTwoTone,
  UserAddOutlined,
  CloseCircleOutlined,
  ArrowUpOutlined,
  InfoOutlined
} from "@ant-design/icons";
import SimpleReactValidator from "simple-react-validator";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import {
  ApproveTaskRecallUnitList,
  getApproveTaskRecallList,
  ApproveTaskRecallSubmit, historyrecord, interimrecord
} from "./../../Store/Action/Transcations/ApproveTaskRecall";
import DataTable from "react-data-table-component";
import { GetRemarksData } from "./../../Store/Action/Transcations/Taskrecall";
import moment from "moment/moment";
import { Toaster } from "../../Libs/Toaster";
import ScrollToTop from "react-scroll-to-top";
import { fullscreenmode, fullscreenexit, handleKeyDown, setFocus } from "./../../Libs/fullscreenmode";
import { validationType, validateSelect, onPasteToaster } from './../../Libs/inputValidation';
import ScrollButton from "../Scrollbar";
import Stickicon from "./Stickyicon";
import { HomeRedirect } from './../../Libs/country'
const homepage = HomeRedirect()

const mapStateToProps = (state) => ({
  list: state.ApproveTaskRecallCompliance.AssignTaskRecall,
  filterrecall: state.recallfilters.recallfilters,
  historydata: state.ApproveTaskRecallCompliance.AssignTaskRecall.history_details,
  interimdata: state.ApproveTaskRecallCompliance.AssignTaskRecall.interim_details
});

const ApproveTaskRecall = ({
  ApproveTaskRecallUnitList,
  getApproveTaskRecallList,
  ApproveTaskRecallSubmit,
  GetRemarksData,
  filterrecall: { recallremarks },
  list,
  historydata,
  interimdata,
  historyrecord, interimrecord
}) => {
  console.log(list, "listlist");

  const [open, setOpen] = useState(false);
  // const onClose = () => {
  //     setOpen(false);
  // };
  const style = {
    normal: {
      cursor: "pointer",
    },
    hover: {
      cursor: "pointer",
      color: "blue",
    },
  };
  // const [hover, setHover] = useState(false)
  const historynew = useHistory();
  const [domainValue, setDomainValue] = useState(false)
  const [domainFilter, setDomainFilter] = useState([])
  const location = useLocation();
  const is_Fullscreen = useSelector((state) => state.Login.is_fullscreen);
  const dispatch = useDispatch();
  const paramid = location.pathname;
  const { Option } = Select;
  let temparray = [];
  const { TextArea } = Input;
  let [counts, setcounts] = useState(0);
  const serviceProviderRef1 = useRef(null);
  const [showdata, Setshowdata] = useState({
    domain: "",
    unit: "",
    frequency: 0,
    frequeny_name: 'All',
    asignee: "",
    legal_entity: "",
    assignee_id: ''
  });
  console.log(showdata, 'showdata')
  const [recalldata, Setrecalldata] = useState({
    action: "",
    remarks: "",
  });
  const [finalchild, Setfinalchild] = useState([]);
  const [historymodal, sethistorymodal] = useState(false)
  const [interimmodal, setinterimmodal] = useState(false);

  const [childlist, Setchildlist] = useState([]);

  const [entires, setEntires] = useState(0);

  const [temparr, setTemparr] = useState([]);
  const [assignee, setAssignee] = useState([])
  console.log(assignee, "assignee456");
  const [key, setKey] = useState("1");

  const isAuth = localStorage.getItem("isAuthenticated");
  const authtoken = localStorage.getItem("authToken");
  const _ = require("lodash");
  // const entityid = localStorage.getItem('SelectedEntityid');
  const sessionValue = localStorage.getItem("sessionValue");
  const sessionParsedValue = JSON.parse(sessionValue);
  const sessionArr = sessionParsedValue.entity_info;
  const [mapFilter, setMapFilter] = useState([]);
  const [checkedRow, setCheckedRow] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [filterTaskAcc, setFilterTaskAcc] = useState(true);
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [exitCollapse, setExitCollapse] = useState(false);


  const [temp, setTemp] = useState([]);

  const [titleDynamic, setTitleDynamic] = useState([]);

  const [checkedRowData, setCheckedRowData] = useState([
    {
      compliance_id: "",
      compliance_history_id: "",
      unit_idl: "",
      download_doc: [],
      reviewer_doc: [],
      interm_doc: [],
      interim_docs_size: "",
      reviwer_docs_size: "",
      document_size: "",
      recall_status: "",
      recall_id: "",
    },
  ]);
  const [AddFormSubmit2, setAddFormSubmit2] = useState(false);
  const [mergedArray, setMergedArray] = useState([]);
  const [filteredArray, setFilteredArray] = useState([]);
  const [filteredArrayState, setfilteredArrayState] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showValueModal, setShowValueModal] = useState(false);
  const [acccordionTrue, setAccordionTrue] = useState(false);
  const [passwordmodal, Setpasswordmodal] = useState(false);
  const [FormRecall, setFormRecall] = useState(false);
  const [FormShow, setFormShow] = useState(false);
  const formValidator = useRef(new SimpleReactValidator());
  const recallSubmit = useRef(new SimpleReactValidator());
  const formValidator2 = useRef(new SimpleReactValidator());
  const [isComplainceModalVisible, setIsComplainceModalVisible] =
    useState(false);
  const [remarksModal, setRemarksModal] = useState(false);
  const [assigneename, setAssigneename] = useState([]);

  const [updatedArrayShowmore, setUpdatedArrayShowmore] = useState([]);
  const [updatedArrayshow, setUpdatedArrayShow] = useState([]);
  const [updatedArray, setUpdatedArray] = useState([]);
  const wrapperRef = useRef(null);
  const [selectSearchValue, setSelectSearchValue] = useState({
    legalEntity: '',
    domain: '',
    act: '',
    vendor: '',
    assignee: ''
  })

  const [filterValues, setFilterValues] = useState(
    {
      All: '',
      periodical: '',
      flexi_review: '',
      review: '',
      on_occurrence: '',
      one_time: ''
    }
  )

  const [checked, setChecked] = useState({
    All: false,
    periodical: false,
    flexi_review: false,
    review: false,
    on_occurrence: false,
    one_time: false

  })
  const [filterValues1, setFilterValues1] = useState([]

  )
  console.log(filterValues1, 'filterValues1');

  const [showDropDown, setshowDropDown] = useState(false);

  const dataSource = [
    {
      key: "1",
      compliance_task:
        "The Contract labour(Regulation Act 1997)The tamilNadu Contact Labour(Regulations)Rule 1975",
      assignee: "AS121-Assignee",
      compliance_status: "Compiled",
      due_date: "30-April-2022",
      completion_date: "28-Apri",
    },
  ];
  const [searchText, setSearchText] = useState("");
  const [all, setAll] = useState([]);
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [remarksvalue, Setremarksvalue] = useState();
  const [tempFilterArray, setTempFilterArray] = useState([]);
  const [totalRecord, settotalRecord] = useState([]);

  const [dataTableProperties, setDataTableProperties] = useState({
    fixedHeader: true,
    pagesize: 10,
    sizechanger: true,
    pageSizeOptions: ["10", "30", "50"],
  });
  const [page, setPage] = useState(1);
  const [searchedValue, setSearchedValue] = useState({
    complianceTasks: '',
    complianceStatus: '',
    dueDate: '',
    completionDate: ''
  })

  // AutoFocus in search field
  const [searchedUpdatedArrayFilter, setSearchedUpdatedArrayFilter] = useState(false)

  const [searchingValue, setSearchingValue] = useState([]) 
  const myRef1 = useRef(null);
  const myRef2 = useRef(null);
  const myRef3 = useRef(null);
  const myRef4 = useRef(null);

  const setFocus = (ref) => {
    setTimeout(() => {
      ref.current.focus();
    })
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const setcancelFilter = () => {
    setFilterModalVisible(false);
    setFilterTaskAcc(false);
  };
  useEffect(() => {
    if (recallremarks) {
      let temp = [];
      for (let i = 0; i < recallremarks.length; i++) {
        if (recallremarks[i].r_description != "") {
          temp.push(recallremarks[i]);
        }
        Setremarksvalue(temp);
      }
    }
  }, [recallremarks]);

  //  Full screen mode //
  const [fullscreen, setfullscreen] = useState(false);

  useEffect(() => {
    if (fullscreen == true) {
      document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
      document.addEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch), false);
      return () => {
        document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
        document.removeEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
        document.removeEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch));
      };
    }
  }, [fullscreen]);
  const handlePasting = (e, regex) => {
    var regex = regex;
    var key = e.clipboardData.getData('text')
    if (!regex.test(key)) {
      e.preventDefault();
      Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
      return false;
    }
  }
  const fullscreenMode = () => {
    if (fullscreen == false) {
      fullscreenmode(dispatch);
      setfullscreen(true);
    } else {
      fullscreenexit(dispatch);
      setfullscreen(false);
    }
  }

  const filterHandle = () => {
    setFilterTaskAcc(true);
    setFilterModalVisible(true);
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          autoFocus={'on'}
          placeholder="Search"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}

        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <FilterFilled
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex] != null
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => text,
  });

  useEffect(() => {
    if (list.approverecallcompliance) {
      let arrayTemp = [];
      for (let i in list.approverecallcompliance) {
        arrayTemp.push(list.approverecallcompliance[i]);
      }
      var merged = [].concat.apply([], arrayTemp);
      setUpdatedArray([...merged]);
      setUpdatedArrayShow([...merged]);
    }
  }, [list.approverecallcompliance]);

  // useEffect(() => {
  //     if (recallcomplianceshowmore) {
  //         let arrayTemp = [];
  //         for (let i in recallcomplianceshowmore) {
  //             arrayTemp.push(recallcomplianceshowmore[i])
  //         }
  //         var merged = [].concat.apply([], arrayTemp);
  //         setUpdatedArrayShowmore([...merged])

  //     }
  // }, [recallcomplianceshowmore])

  useEffect(() => {
    if (updatedArrayShowmore.length > 0) {
      setUpdatedArray([]);
      setUpdatedArray([...updatedArrayshow, ...updatedArrayShowmore]);
    }
  }, [updatedArrayShowmore]);

  const [entityid, setCurrentEntity] = useState("");
  console.log(entityid, "entityid567");

  useEffect(() => {
    if (localStorage.getItem("SelectedEntityid") != null) {
      setCurrentEntity(localStorage.getItem("SelectedEntityid"));
    }
  }, [localStorage.getItem("SelectedEntityid")]);
  useEffect(() => {
    if (temp) {
      let tempARR = [];

      for (let i in temp) {
        tempARR.push(temp[i].child);
      }
      Setchildlist(tempARR);
    }
  }, [temp]);
  useEffect(() => {
    if (finalchild && finalchild.length > 0) {
      setEntires(finalchild.length);
    }
  }, [finalchild]);
  useEffect(() => {
    let tempArr = [];
    for (let k in childlist) {
      tempArr.push(childlist[k]);
      Setfinalchild(tempArr);
    }
  }, [childlist]);
  const columns = [
    {
      title: "#",
      render: (row, index, record) => {
        return (
          <>
            <p>{counts + 1}</p>
            <span hidden>{(counts = counts + 1)}</span>
            {record && record.compliance_frequency == "Review" ? (
              <Tooltip title={record.compliance_frequency}>
                <p>
                  <i style={{ color: "orange" }} class="bi bi-square-fill"></i>
                </p>
              </Tooltip>
            ) : (
              <Tooltip title={record.compliance_frequency}>
                <p>
                  <i style={{ color: "green" }} class="bi bi-square-fill"></i>
                </p>
              </Tooltip>
            )}
          </>
        );
      },
      width: "10px",
      sortable: true,
      align: "center",
      reorder: true,
      filters: [
        {
          text: (
            <span>
              <i style={{ color: "orange" }} className="bi bi-square-fill"></i>{" "}
              Review
            </span>
          ),
          value: "Review",
        },
        {
          text: (
            <span>
              <i style={{ color: "green" }} className="bi bi-square-fill"></i>{" "}
              Periodical
            </span>
          ),
          value: "Periodical",
        },
        {
          text: (
            <span>
              <i style={{ color: "#F78453" }} className="bi bi-square-fill"></i>{" "}
              Flexi Review
            </span>
          ),
          value: "Review",
        },
        {
          text: (
            <span>
              <i style={{ color: "yellow" }} className="bi bi-square-fill"></i>{" "}
              On Occurance
            </span>
          ),
          value: "On Occurrence",
        },
        {
          text: (
            <span>
              <i style={{ color: "#F21625" }} className="bi bi-square-fill"></i>{" "}
              One Time
            </span>
          ),
          value: "One Time",
        },
      ],
      onFilter: (value, record) => record.compliance_frequency == value,
    },
    {
      title: (
        <Fragment>
          {" "}
          <span>
            <input
              type="checkbox"
              title="Click here to approve all"
              name="allchecked"
            />
          </span>
        </Fragment>
      ),
      dataIndex: false,
      key: false,
      // ellipsis: true,
      width: "10px",
      align: "center",
      render: (text, record) => {
        return (
          <Fragment>
            <input
              type="checkbox"
              title="Click here to Task Recall"
              name={"checked" + record.parentId}
              onClick={(e) => {
                let checked = e.target.checked;
                addSelectedDataValues(checked, record.compliance_history_id);
              }}
            />
          </Fragment>
        );
      },
    },
    {
      title: "Compliance Task",
      ...getColumnSearchProps("compliance_name"),

      // selector: (row) => <><p>{row.statu}</p><br></br><p>{row.compliance_name}</p></>,
      render: (row, record) => {
        const content = (
          <table
            style={{
              width: "600px",
              display: "block",
              height: "250px",
              overflowY: "scroll",
            }}
            class="table table-striped"
          >
            <tbody>
              <tr>
                <th>Vendor Name</th>
                <td>{record.unit_name}</td>
              </tr>
              <tr>
                <th>Address</th>
                <td>{record.address}</td>
              </tr>
              <tr>
                <th>Division</th>
                <td>{record.division_name}</td>
              </tr>
              <tr>
                <th>Category</th>
                <td>{record.category_name}</td>
              </tr>
              <tr>
                <th>Act/Rules</th>
                <td>{record.statu}</td>
              </tr>
              <tr>
                <th>Compliance Description</th>
                <td>{record.compliance_description}</td>
              </tr>
              <tr>
                <th>Penal Consequences</th>
                <td>{record.penal_consequences}</td>
              </tr>
            </tbody>
          </table>
        );

        return (
          <>
            <span
              className="mt-3"
              onClick={() => {
                showValue();
                // setOpen(true)
                setValueModal({
                  compliance_history_id: record.compliance_history_id,
                  unitid: record.unit_id,
                  unit: record.unit_name,
                  compliance_task: record.compliance_name,
                  compliance_frequency: record.compliance_frequency,
                  document_issued_date: record.completion_date,
                  document_reference_number: record.document_reference_number,
                  next_due_date: record.next_due_date,
                  address: record.address,
                  document_size: record.document_size,
                  compliance_description: record.compliance_description,
                  uploaded_document: record.upload_docs,
                  interim_details: record.interim_count,
                  remarks: record.assignee_remarks,
                  history_details: record.remarks_count
                });
              }}
              style={{ cursor: "pointer" }}
            >
              {record.statu}
            </span>
            <div className="approve-task-reacall-tooltip">
              <div>
                <Tooltip
                  placement="top"
                  overlayStyle={{ maxWidth: "420px" }}
                  title={
                    <table
                      style={{
                        width: "100%",
                        borderCollapse: "collapse",
                        border: "1px solid #636262",
                        tableLayout: "auto",
                      }}
                    >
                      <tbody>
                        <tr>
                          <th style={{ border: "1px solid #636262" }}>
                            Vendor Name
                          </th>
                          <td style={{ border: "1px solid #636262" }}>
                            {record.unit_name}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ border: "1px solid #636262" }}>
                            Address
                          </th>
                          <td style={{ border: "1px solid #636262" }}>
                            {record.address}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ border: "1px solid #636262" }}>
                            Division
                          </th>
                          <td style={{ border: "1px solid #636262" }}>
                            {record.division_name}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ border: "1px solid #636262" }}>
                            Category
                          </th>
                          <td style={{ border: "1px solid #636262" }}>
                            {record.category_name}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ border: "1px solid #636262" }}>
                            Act/Rules
                          </th>
                          <td style={{ border: "1px solid #636262" }}>
                            {record.statu}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ border: "1px solid #636262" }}>
                            Compliance Description
                          </th>
                          <td style={{ border: "1px solid #636262" }}>
                            {record.compliance_description}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ border: "1px solid #636262" }}>
                            Penal Consequences
                          </th>
                          <td style={{ border: "1px solid #636262" }}>
                            {record.penal_consequences}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  }
                >
                  {" "}
                  <ExclamationCircleOutlined />
                </Tooltip>
                &nbsp;{record.compliance_name}
              </div>
            </div>
          </>
        );
      },
      sortable: true,
      width: "465px",
      reorder: true,
    },
    // {
    //     title: "Assignee",
    //     dataIndex: 'assignee_name',
    //     key: 'assignee_name',
    //     sortable: true,
    //     width: '25px',
    //     reorder: true,
    //     ellipsis: true,
    //     ...getColumnSearchProps('assignee_name'),

    // },
    {
      title: "Compliance Status",
      dataIndex: "task_status",
      key: "task_status",
      sortable: true,
      width: "20px",
      reorder: true,
      ...getColumnSearchProps("task_status"),
    },
    {
      title: "Due Date",
      dataIndex: "due_date",
      key: "due_date",
      sortable: true,
      align: "center",
      width: "25px",
      reorder: true,
      // ellipsis: true,
      ...getColumnSearchProps("due_date"),
    },
    {
      title: "Completion Date",
      dataIndex: "completion_date",
      key: "completion_date",
      sortable: true,
      width: "25px",
      align: "center",
      reorder: true,
      // ellipsis: true,
      ...getColumnSearchProps("completion_date"),
    },
  ];

  const [valueModal, setValueModal] = useState({
    unit: "",
    unitid: '',
    address: "",
    compliance_history_id: "",
    compliance_task: "",
    compliance_frequency: "",
    uploaded_document: "",
    document_issued_date: "",
    document_reference_number: "",
    validity_date: "",
    next_due_date: "",
    status: "",
    remarks: "",
    action: "",
    document_size: "",
    compliance_description: "",
    interim_details: "",
    history_details: ''
  });

  const [password, Setpassword] = useState({
    passwordvalue: "",
  });

  const Remarkscolumn = [
    {
      title: "S.No.",
      dataIndex: false,
      width: "30px",
      align: "center",
      render: (text, record, index) => {
        return (page - 1) * 10 + index + 1;
      },
    },
    {
      title: "Description",
      dataIndex: "r_description",
      key: "r_description",
      width: "200px",
      ellipsis: true,
      render: (text, record) => (
        <a
          onClick={() => {
            Setrecalldata({ ...recalldata, remarks: record.r_description });
            setRemarksModal(false);
          }}
        >
          {record.r_description}
        </a>
      ),
    },
  ];
  const showValue = () => {
    setShowValueModal(true);
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const passwordcancel = () => {
    Setpasswordmodal(false);
    setAddFormSubmit2(false);
  };

  useEffect(() => {
    if (list.approverecallcompliance !== null) {
      settotalRecord(list.approverecallcompliance.length);
      let totalList = [];
      let arr = [];
      let countrylisttempdata = [];
      let tempSetArray = [];
      let parId = [];
      list.approverecallcompliance &&
        list.approverecallcompliance.length > 0 &&
        list.approverecallcompliance.map(function (user) {
          if (arr.indexOf(user.unit_name) < 0) arr.push(user.unit_name);
        });

      arr.map((pmenu, index) => {
        let entityArray = [];
        let tempArray = [];

        if (pmenu) {
          list.approverecallcompliance &&
            list.approverecallcompliance.map((subMenuList, childIndex) => {
              if (pmenu === subMenuList.unit_name) {
                entityArray = {
                  compliance_history_id: subMenuList.compliance_history_id,
                  compliance_name: subMenuList.compliance_name,
                  compliance_frequency: subMenuList.compliance_frequency,
                  domain_name: subMenuList.domain_name,
                  domain_id: subMenuList.domain_id,
                  interim_count: subMenuList.interim_count,
                  unit_id: subMenuList.unit_id,
                  duration_type: subMenuList.duration_type,
                  validity_settings_days: subMenuList.validity_settings_days,
                  assigned_on: subMenuList.assigned_on,
                  start_date: subMenuList.start_date,
                  due_date: moment(subMenuList.due_date).format("DD-MMM-YYYY"),
                  reviwer_docs: subMenuList.reviwer_docs,
                  validity_date: subMenuList.validity_date,
                  next_due_date: subMenuList.next_due_date,
                  assignee_name: subMenuList.assignee_name,
                  document_size: subMenuList.document_size,
                  approved_on: subMenuList.approved_on,
                  format_file_name: subMenuList.format_file_name,
                  unit_name: subMenuList.unit_name,
                  remarks_count: subMenuList.remarks_count,
                  address: subMenuList.address,
                  compliance_description: subMenuList.compliance_description,
                  statu: subMenuList.statu,
                  remarks: subMenuList.remarks,
                  occurrence_remarks: subMenuList.occurrence_remarks,
                  compliance_id: subMenuList.compliance_id,
                  download_url: subMenuList.download_url,
                  file_names: subMenuList.file_names,
                  history_count: subMenuList.history_count,
                  tz_date: subMenuList.tz_date,
                  tz_name: subMenuList.tz_name,
                  comp_history_count: subMenuList.comp_history_count,
                  system_remarks: subMenuList.system_remarks,
                  division_name: subMenuList.division_name,
                  category_name: subMenuList.category_name,
                  document_reference_number:
                    subMenuList.document_reference_number,
                  upload_docs: subMenuList.upload_docs,
                  recall_status: subMenuList.recall_status,
                  penal_consequences: subMenuList.penal_consequences,
                  interim_docs: subMenuList.interim_docs,
                  interm_newdoc: subMenuList.interm_newdoc,
                  reviwer_newdoc: subMenuList.reviwer_newdoc,
                  country_id: subMenuList.country_id,
                  recall_id: subMenuList.recall_id,
                  interim_docs_size: subMenuList.interim_docs_size,
                  reviwer_docs_size: subMenuList.reviwer_docs_size,
                  completion_date: moment(subMenuList.completion_date).format(
                    "DD-MMM-YYYY"
                  ),
                  approve_status: subMenuList.approve_status,
                  assignee_remarks: subMenuList.assignee_remarks,
                  extn_due_date: subMenuList.extn_due_date,
                  task_status: subMenuList.task_status,
                  compfielaw_url: subMenuList.compfielaw_url,
                  parentId: index,
                  childId: childIndex,
                };
                tempArray.push(entityArray);
              }
            });
        }
        let checkarray = []
        let listArray_One_time = _.filter(tempArray, { compliance_frequency: "One Time" });
        if (listArray_One_time && listArray_One_time.length > 0) {
          for (let i in listArray_One_time) {
            checkarray.push(listArray_One_time[i])
          }
        }
        let listArray_periodical = _.filter(tempArray, { compliance_frequency: "Periodical" });
        if (listArray_periodical && listArray_periodical.length > 0) {
          for (let i in listArray_periodical) {
            checkarray.push(listArray_periodical[i])
          }
        }
        let listArray_Review = _.filter(tempArray, { compliance_frequency: "Review" });
        if (listArray_Review && listArray_Review.length > 0) {
          for (let i in listArray_Review) {
            checkarray.push(listArray_Review[i])
          }
        }
        let listArray_Flexi_Review = _.filter(tempArray, { compliance_frequency: "Flexi Review" });
        if (listArray_Flexi_Review && listArray_Flexi_Review.length > 0) {
          for (let i in listArray_Flexi_Review) {
            checkarray.push(listArray_Flexi_Review[i])
          }
        }
        let listArray_On_Occurrence = _.filter(tempArray, { compliance_frequency: "On Occurrence" });
        if (listArray_On_Occurrence && listArray_On_Occurrence.length > 0) {
          for (let i in listArray_On_Occurrence) {
            checkarray.push(listArray_On_Occurrence[i])
          }
        }
        totalList = {
          index: index,
          parentData: pmenu,
          child: checkarray,
        };
        titleDynamic[index] = pmenu;
        countrylisttempdata.push(totalList);
      });
      setTitleDynamic([...titleDynamic]);
      var merged = [].concat.apply([], countrylisttempdata);
      setTemp([...temp, ...merged]);
      //  setData(tempSetArray)
      //getData()
    }
  }, [list.approverecallcompliance]);

  useEffect(() => {
    if (list && list.assignee && list.assignee.length > 0) {
      let uniqueObjArray = [
        ...new Map(
          list.assignee &&
          list.assignee.length > 0 &&
          list.assignee.map((item) => [item["user_id"], item])
        ).values(),
      ];
      const uniqueIds =
        uniqueObjArray &&
        uniqueObjArray.map((item) => {
          return item
        });
      console.log(uniqueIds, "uniqueIdsuniqueIds");
      setAssignee(uniqueIds)
    }
  }, [list.assignee]);

  useEffect(() => {
    if (updatedArray && updatedArray.length > 0) {
      let uniqueObjArray = [
        ...new Map(
          updatedArray &&
          updatedArray.length > 0 &&
          updatedArray.map((item) => [item["unit_id"], item])
        ).values(),
      ];
      const uniqueIds =
        uniqueObjArray &&
        uniqueObjArray.map((item) => {
          return item.unit_id;
        });
      let tempArr = [];
      for (let i in uniqueIds) {
        let filteredArr = _.filter(
          updatedArray && updatedArray.length > 0 && updatedArray,
          { unit_id: uniqueIds[i] }
        );
        tempArr.push(filteredArr);
      }
      setFilteredArray([tempArr]);
    } else {
      setFilteredArray([]);
    }
  }, [updatedArray]);



  useEffect(() => {
    let tempArr = [];
    if (updatedArray && updatedArray.length > 0) {
      filteredArrayState &&
        filteredArrayState.length > 0 &&
        filteredArrayState.map((item, i) => {
          let uniqueObjArray = [
            ...new Map(
              item &&
              item.length > 0 &&
              item.map((items) => [items["assignee_name"], items])
            ).values(),
          ];
          const uniqueIds =
            uniqueObjArray.length > 0 &&
            uniqueObjArray.map((itemData) => {
              return itemData.assignee_name;
            });
          for (let i in uniqueIds) {
            let filteredArr = _.find(item && item.length > 0 && item, {
              assignee_name: uniqueIds[i],
            });
            tempArr.push(filteredArr);
          }
        });
      setAssigneename(tempArr);
    }
  }, [filteredArrayState]);

  useEffect(() => {
    if (updatedArray !== null) {
      let totalList = [];
      let arr = [];
      let countrylisttempdata = [];
      let tempSetArray = [];
      let parId = [];
      assigneename &&
        assigneename.length > 0 &&
        assigneename.map(function (user) {
          if (arr.indexOf(user.assignee_name) < 0)
            arr.push({
              assignee_name: user.assignee_name,
              unit_name: user.unit_name,
            });
        });

      arr.map((pmenu, index) => {
        // deepscan-disable-line ARRAY_CALLBACK_RETURN_MISSING
        let entityArray = [];
        let tempArray = [];
        let unit_name = [];
        if (pmenu) {
          updatedArray &&
            updatedArray.map((subMenuList, childIndex) => {
              if (
                pmenu.assignee_name === subMenuList.assignee_name &&
                pmenu.unit_name === subMenuList.unit_name
              ) {
                entityArray = {
                  address: subMenuList.address,
                  approve_status: subMenuList.approve_status,
                  approved_on: subMenuList.approved_on,
                  assignee_remarks: subMenuList.assignee_remarks,
                  assigned_on: subMenuList.assigned_on,
                  assignee_name: subMenuList.assignee_name,
                  category_name: subMenuList.category_name,
                  compfielaw_url: subMenuList.compfielaw_url,
                  completion_date: subMenuList.completion_date,
                  compliance_description: subMenuList.compliance_description,
                  compliance_frequency: subMenuList.compliance_frequency,
                  compliance_history_id: subMenuList.compliance_history_id,
                  compliance_id: subMenuList.compliance_id,
                  compliance_name: subMenuList.compliance_name,
                  country_id: subMenuList.country_id,
                  division_name: subMenuList.division_name,
                  document_reference_number:
                    subMenuList.document_reference_number,
                  document_size: subMenuList.document_size,
                  domain_id: subMenuList.domain_id,
                  domain_name: subMenuList.domain_name,
                  download_url: subMenuList.download_url,
                  due_date: subMenuList.due_date,
                  duration_type: subMenuList.duration_type,
                  extn_due_date: subMenuList.extn_due_date,
                  file_names: subMenuList.file_names,
                  format_file_name: subMenuList.format_file_name,
                  history_count: subMenuList.history_count,
                  interim_count: subMenuList.interim_count,
                  interim_docs: subMenuList.interim_docs,
                  interim_docs_size: subMenuList.interim_docs_size,
                  interm_newdoc: subMenuList.interm_newdoc,
                  next_due_date: subMenuList.next_due_date,
                  occurrence_remarks: subMenuList.occurrence_remarks,
                  penal_consequences: subMenuList.penal_consequences,
                  recall_id: subMenuList.recall_id,
                  recall_status: subMenuList.recall_status,
                  remarks: subMenuList.remarks,
                  remarks_count: subMenuList.remarks_count,
                  reviwer_docs: subMenuList.reviwer_docs,
                  reviwer_docs_size: subMenuList.reviwer_docs_size,
                  reviwer_newdoc: subMenuList.reviwer_newdoc,
                  start_date: subMenuList.start_date,
                  statu: subMenuList.statu,
                  system_remarks: subMenuList.system_remarks,
                  task_status: subMenuList.task_status,
                  tz_date: subMenuList.tz_date,
                  tz_name: subMenuList.tz_name,
                  unit_id: subMenuList.unit_id,
                  unit_name: subMenuList.unit_name,
                  upload_docs: subMenuList.upload_docs,
                  validity_date: subMenuList.validity_date,
                  validity_settings_days: subMenuList.validity_settings_days,
                  parentId: index,
                  childId: childIndex,
                  indexNo: childIndex + 1,
                };
                tempArray.push(entityArray);
                unit_name.push(subMenuList.unit_name);
              }
            });
        }
        let checkarray = []
        let listArray_One_time = _.filter(tempArray, { compliance_frequency: "One Time" });
        if (listArray_One_time && listArray_One_time.length > 0) {
          for (let i in listArray_One_time) {
            checkarray.push(listArray_One_time[i])
          }
        }
        let listArray_periodical = _.filter(tempArray, { compliance_frequency: "Periodical" });
        if (listArray_periodical && listArray_periodical.length > 0) {
          for (let i in listArray_periodical) {
            checkarray.push(listArray_periodical[i])
          }
        }
        let listArray_Review = _.filter(tempArray, { compliance_frequency: "Review" });
        if (listArray_Review && listArray_Review.length > 0) {
          for (let i in listArray_Review) {
            checkarray.push(listArray_Review[i])
          }
        }
        let listArray_Flexi_Review = _.filter(tempArray, { compliance_frequency: "Flexi Review" });
        if (listArray_Flexi_Review && listArray_Flexi_Review.length > 0) {
          for (let i in listArray_Flexi_Review) {
            checkarray.push(listArray_Flexi_Review[i])
          }
        }
        let listArray_On_Occurrence = _.filter(tempArray, { compliance_frequency: "On Occurrence" });
        if (listArray_On_Occurrence && listArray_On_Occurrence.length > 0) {
          for (let i in listArray_On_Occurrence) {
            checkarray.push(listArray_On_Occurrence[i])
          }
        }
        totalList = {
          index: index,
          parentData: pmenu,
          child: checkarray,
        };
        titleDynamic[index] = pmenu;
        countrylisttempdata.push(totalList);
        tempSetArray.push(tempArray);
        parId.push(unit_name);
      });
      setTitleDynamic([...titleDynamic]);
      var merged = [].concat.apply([], tempSetArray);
      setTemp(countrylisttempdata);
      setMergedArray(merged);
      setTempFilterArray([...new Set([].concat.apply([], parId))]);
    }
  }, [updatedArray, assigneename]);

  const frequencyfilter = (data) => {
    setAll(data);
    let filterTable;
    if (data == "All") {
      filterTable = updatedArray;
      settotalRecord(filterTable.length);
    } else {
      filterTable = updatedArray.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(data.toLowerCase())
        )
      );
      settotalRecord(filterTable.length);
    }
    let totalList = [];
    let arr = [];
    let countrylisttempdata = [];
    let tempSetArray = [];
    let parId = [];
    if (filterTable && filterTable.length > 0) {
      assigneename &&
        assigneename.length > 0 &&
        assigneename.map(function (user) {
          if (arr.indexOf(user.assignee_name) < 0)
            arr.push({
              assignee_name: user.assignee_name,
              unit_name: user.unit_name,
            });
        });

      arr.map((pmenu, index) => {
        // deepscan-disable-line ARRAY_CALLBACK_RETURN_MISSING
        let entityArray = [];
        let tempArray = [];
        let unit_name = [];
        if (pmenu) {
          filterTable &&
            filterTable.map((subMenuList, childIndex) => {
              if (
                pmenu.assignee_name === subMenuList.assignee_name &&
                pmenu.unit_name === subMenuList.unit_name
              ) {
                entityArray = {
                  address: subMenuList.address,
                  approve_status: subMenuList.approve_status,
                  approved_on: subMenuList.approved_on,
                  assigned_on: subMenuList.assigned_on,
                  assignee_name: subMenuList.assignee_name,
                  assignee_remarks: subMenuList.assignee_remarks,
                  category_name: subMenuList.category_name,
                  compfielaw_url: subMenuList.compfielaw_url,
                  completion_date: subMenuList.completion_date,
                  compliance_description: subMenuList.compliance_description,
                  compliance_frequency: subMenuList.compliance_frequency,
                  compliance_history_id: subMenuList.compliance_history_id,
                  compliance_id: subMenuList.compliance_id,
                  compliance_name: subMenuList.compliance_name,
                  country_id: subMenuList.country_id,
                  division_name: subMenuList.division_name,
                  document_reference_number:
                    subMenuList.document_reference_number,
                  document_size: subMenuList.document_size,
                  domain_id: subMenuList.domain_id,
                  domain_name: subMenuList.domain_name,
                  download_url: subMenuList.download_url,
                  due_date: subMenuList.due_date,
                  duration_type: subMenuList.duration_type,
                  extn_due_date: subMenuList.extn_due_date,
                  file_names: subMenuList.file_names,
                  format_file_name: subMenuList.format_file_name,
                  history_count: subMenuList.history_count,
                  interim_count: subMenuList.interim_count,
                  interim_docs: subMenuList.interim_docs,
                  interim_docs_size: subMenuList.interim_docs_size,
                  interm_newdoc: subMenuList.interm_newdoc,
                  next_due_date: subMenuList.next_due_date,
                  occurrence_remarks: subMenuList.occurrence_remarks,
                  penal_consequences: subMenuList.penal_consequences,
                  recall_id: subMenuList.recall_id,
                  recall_status: subMenuList.recall_status,
                  remarks: subMenuList.remarks,
                  remarks_count: subMenuList.remarks_count,
                  reviwer_docs: subMenuList.reviwer_docs,
                  reviwer_docs_size: subMenuList.reviwer_docs_size,
                  reviwer_newdoc: subMenuList.reviwer_newdoc,
                  start_date: subMenuList.start_date,
                  statu: subMenuList.statu,
                  system_remarks: subMenuList.system_remarks,
                  task_status: subMenuList.task_status,
                  tz_date: subMenuList.tz_date,
                  tz_name: subMenuList.tz_name,
                  unit_id: subMenuList.unit_id,
                  unit_name: subMenuList.unit_name,
                  upload_docs: subMenuList.upload_docs,
                  validity_date: subMenuList.validity_date,
                  validity_settings_days: subMenuList.validity_settings_days,
                  parentId: index,
                  childId: childIndex,
                  indexNo: childIndex + 1,
                };
                tempArray.push(entityArray);
                unit_name.push(subMenuList.unit_name);
              }
            });
        }
        totalList = {
          index: index,
          parentData: pmenu,
          child: tempArray,
        };
        titleDynamic[index] = pmenu;
        countrylisttempdata.push(totalList);
        tempSetArray.push(tempArray);
        parId.push(unit_name);
      });

      setTitleDynamic([...titleDynamic]);
      var merged = [].concat.apply([], tempSetArray);
      setTemp(countrylisttempdata);
      setMergedArray(merged);
      setTempFilterArray([...new Set([].concat.apply([], parId))]);
    } else {
      setMergedArray([]);
      setTemp([]);
      setTitleDynamic([]);
      setTempFilterArray([]);
    }
  };

  const compliancetaskfilter = (e, type) => {
    // let filterTable;
    // if (type == "Compliance_Task") {
    //   filterTable = updatedArray.filter((o) => {
    //     if (all == "All") {
    //       if (o["statu"].toLowerCase().includes(e.toLowerCase())) {
    //         return true;
    //       }
    //     } else {
    //       if (
    //         o["statu"].toLowerCase().includes(e.toLowerCase()) &&
    //         o["compliance_frequency"].toLowerCase().includes(all.toLowerCase())
    //       ) {
    //         return true;
    //       }
    //     }
    //   });
    // } else if (type == "Assignee") {
    //   filterTable = updatedArray.filter((o) => {
    //     if (all == "All") {
    //       if (o["assignee_name"].toLowerCase().includes(e.toLowerCase())) {
    //         return true;
    //       }
    //     } else {
    //       if (
    //         o["assignee_name"].toLowerCase().includes(e.toLowerCase()) &&
    //         o["compliance_frequency"].toLowerCase().includes(all.toLowerCase())
    //       ) {
    //         return true;
    //       }
    //     }
    //   });
    // } else if (type == "Compliance_Status") {
    //   filterTable = updatedArray.filter((o) => {
    //     if (all == "All") {
    //       if (o["task_status"].toLowerCase().includes(e.toLowerCase())) {
    //         return true;
    //       }
    //     } else {
    //       if (
    //         o["task_status"].toLowerCase().includes(e.toLowerCase()) &&
    //         o["compliance_frequency"].toLowerCase().includes(all.toLowerCase())
    //       ) {
    //         return true;
    //       }
    //     }
    //   });
    // } else if (type == "Due_Date") {
    //   filterTable = updatedArray.filter((o) => {
    //     if (all == "All") {
    //       if (o["due_date"].toLowerCase().includes(e.toLowerCase())) {
    //         return true;
    //       }
    //     } else {
    //       if (
    //         o["due_date"].toLowerCase().includes(e.toLowerCase()) &&
    //         o["compliance_frequency"].toLowerCase().includes(all.toLowerCase())
    //       ) {
    //         return true;
    //       }
    //     }
    //   });
    // } else if (type == "Completion_Date") {
    //   filterTable = updatedArray.filter((o) => {
    //     if (all == "All") {
    //       if (o["completion_date"].toLowerCase().includes(e.toLowerCase())) {
    //         return true;
    //       }
    //     } else {
    //       if (
    //         o["completion_date"].toLowerCase().includes(e.toLowerCase()) &&
    //         o["compliance_frequency"].toLowerCase().includes(all.toLowerCase())
    //       ) {
    //         return true;
    //       }
    //     }
    //   });
    // }

    let filterTable = updatedArray;

    if (searchingValue['compliance_task']) {
      filterTable = filterTable.filter((item) => {
        if ((item["statu"].toLowerCase().includes(searchingValue['compliance_task'].toLowerCase())) || (item["compliance_name"].toLowerCase().includes(searchingValue['compliance_task'].toLowerCase()))) {
          return true;
        }
      })
    }
    if (searchingValue['compliance_status']) {
      filterTable = filterTable.filter((item) => {
        if ((item["task_status"].toLowerCase().includes(searchingValue['compliance_status'].toLowerCase()))) {
          return true;
        }
      })
    }
    if (searchingValue['due_date']) {
      filterTable = filterTable.filter((item) => {
        if ((item["due_date"].toLowerCase().includes(searchingValue['due_date'].toLowerCase()))) {
          return true;
        }
      })
    }
    if (searchingValue['completion_date']) {
      filterTable = filterTable.filter((item) => {
        if ((item["completion_date"].toLowerCase().includes(searchingValue['completion_date'].toLowerCase()))) {
          return true;
        }
      })
    }

    if (searchedUpdatedArrayFilter) {
      const res = [];
      const keys = Object.keys(filterValues);
      keys.forEach(key => {
        if (filterValues[key] === true) {
          if (key == 'on_occurrence') {
            key = 'on occurrence';
          }
          if (key == 'one_time') {
            key = 'one time';
          }
          res.push(key);
        }
      });
      let temp = [];
      let a;
      if (res[0] == "All" || res.length == 0) {
        filterTable = updatedArray;
        settotalRecord(filterTable.length);
      } else {

        for (let i in res) {
          a = filterTable.filter((o) =>
            Object.keys(o).some((k) =>
              String(o[k]).toLowerCase().includes(res[i].toLowerCase())
            )
          );
          Array.prototype.push.apply(temp, a);
        }

        filterTable = temp;
        settotalRecord(filterTable.length);
      }
    }

    let totalList = [];
    let arr = [];
    let countrylisttempdata = [];
    let tempSetArray = [];
    let parId = [];
    if (filterTable && filterTable.length > 0) {
      assigneename &&
        assigneename.length > 0 &&
        assigneename.map(function (user) {
          if (arr.indexOf(user.assignee_name) < 0)
            arr.push({
              assignee_name: user.assignee_name,
              unit_name: user.unit_name,
            });
        });

      arr.map((pmenu, index) => {
        // deepscan-disable-line ARRAY_CALLBACK_RETURN_MISSING
        let entityArray = [];
        let tempArray = [];
        let unit_name = [];
        if (pmenu) {
          filterTable &&
            filterTable.map((subMenuList, childIndex) => {
              if (
                pmenu.assignee_name === subMenuList.assignee_name &&
                pmenu.unit_name === subMenuList.unit_name
              ) {
                entityArray = {
                  address: subMenuList.address,
                  approve_status: subMenuList.approve_status,
                  approved_on: subMenuList.approved_on,
                  assigned_on: subMenuList.assigned_on,
                  assignee_name: subMenuList.assignee_name,
                  category_name: subMenuList.category_name,
                  compfielaw_url: subMenuList.compfielaw_url,
                  completion_date: subMenuList.completion_date,
                  compliance_description: subMenuList.compliance_description,
                  compliance_frequency: subMenuList.compliance_frequency,
                  compliance_history_id: subMenuList.compliance_history_id,
                  compliance_id: subMenuList.compliance_id,
                  compliance_name: subMenuList.compliance_name,
                  country_id: subMenuList.country_id,
                  division_name: subMenuList.division_name,
                  document_reference_number:
                    subMenuList.document_reference_number,
                  document_size: subMenuList.document_size,
                  domain_id: subMenuList.domain_id,
                  domain_name: subMenuList.domain_name,
                  download_url: subMenuList.download_url,
                  due_date: subMenuList.due_date,
                  duration_type: subMenuList.duration_type,
                  extn_due_date: subMenuList.extn_due_date,
                  file_names: subMenuList.file_names,
                  format_file_name: subMenuList.format_file_name,
                  history_count: subMenuList.history_count,
                  interim_count: subMenuList.interim_count,
                  interim_docs: subMenuList.interim_docs,
                  interim_docs_size: subMenuList.interim_docs_size,
                  interm_newdoc: subMenuList.interm_newdoc,
                  next_due_date: subMenuList.next_due_date,
                  occurrence_remarks: subMenuList.occurrence_remarks,
                  penal_consequences: subMenuList.penal_consequences,
                  recall_id: subMenuList.recall_id,
                  recall_status: subMenuList.recall_status,
                  remarks: subMenuList.remarks,
                  remarks_count: subMenuList.remarks_count,
                  reviwer_docs: subMenuList.reviwer_docs,
                  reviwer_docs_size: subMenuList.reviwer_docs_size,
                  reviwer_newdoc: subMenuList.reviwer_newdoc,
                  start_date: subMenuList.start_date,
                  statu: subMenuList.statu,
                  system_remarks: subMenuList.system_remarks,
                  task_status: subMenuList.task_status,
                  tz_date: subMenuList.tz_date,
                  tz_name: subMenuList.tz_name,
                  unit_id: subMenuList.unit_id,
                  unit_name: subMenuList.unit_name,
                  upload_docs: subMenuList.upload_docs,
                  validity_date: subMenuList.validity_date,
                  validity_settings_days: subMenuList.validity_settings_days,
                  parentId: index,
                  childId: childIndex,
                  indexNo: childIndex + 1,
                };
                tempArray.push(entityArray);
                unit_name.push(subMenuList.unit_name);
              }
            });
        }
        totalList = {
          index: index,
          parentData: pmenu,
          child: tempArray,
        };
        titleDynamic[index] = pmenu;
        countrylisttempdata.push(totalList);
        tempSetArray.push(tempArray);
        parId.push(unit_name);
      });

      setTitleDynamic([...titleDynamic]);
      var merged = [].concat.apply([], tempSetArray);
      setTemp(countrylisttempdata);
      setMergedArray(merged);
      setTempFilterArray([...new Set([].concat.apply([], parId))]);
    } else {
      setMergedArray([]);
      setTemp([]);
      setTitleDynamic([]);
      setTempFilterArray([]);
    }
  };

  useEffect(() => {
    if (filteredArray.length > 0) {
      {
        filteredArray.map((item) => {
          return setMapFilter(item);
        });
      }
    } else {
      setMapFilter([]);
    }
  }, [filteredArray]);

  useEffect(() => {
    if (filteredArray.length > 0) {
      let temp = [];
      for (let i in filteredArray) {
        temp = filteredArray[i];
      }
      setfilteredArrayState([...temp]);
    }
  }, [filteredArray]);

  useEffect(() => {
    if (updatedArray && updatedArray.length > 0) {
      let uniqueObjArray = [
        ...new Map(
          updatedArray &&
          updatedArray.length > 0 &&
          updatedArray.map((item) => [item["unit_id"], item])
        ).values(),
      ];
      const uniqueIds =
        uniqueObjArray &&
        uniqueObjArray.map((item) => {
          return item.unit_id;
        });
      let tempArr = [];
      for (let i in uniqueIds) {
        let filteredArr = _.filter(
          updatedArray && updatedArray.length > 0 && updatedArray,
          { unit_id: uniqueIds[i] }
        );
        tempArr.push(filteredArr);
      }
      setFilteredArray([tempArr]);
    } else {
      setFilteredArray([]);
    }
  }, [updatedArray]);

  const gettooltipfun = (record) => {
    const content = (
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          border: "1px solid #636262",
          tableLayout: "auto",
        }}
      >
        <tbody>
          <tr>
            <th style={{ border: "1px solid #636262" }}>
              <b>Vendor Name</b>
            </th>
            <td style={{ border: "1px solid #636262" }}>
              <b>{record.unit_name}</b>
            </td>
          </tr>
          <tr>
            <th style={{ border: "1px solid #636262" }}>
              <b>Address</b>
            </th>
            <td style={{ border: "1px solid #636262" }}>
              <b>{record.address}</b>
            </td>
          </tr>
          <tr>
            <th style={{ border: "1px solid #636262" }}>
              <b>Division</b>
            </th>
            <td style={{ border: "1px solid #636262" }}>
              <b>{record.division_name}</b>
            </td>
          </tr>
          <tr>
            <th style={{ border: "1px solid #636262" }}>
              <b>Category</b>
            </th>
            <td style={{ border: "1px solid #636262" }}>
              <b>{record.category_name}</b>
            </td>
          </tr>
          <tr>
            <th style={{ border: "1px solid #636262" }}>
              <b>Act/Rules</b>
            </th>
            <td style={{ border: "1px solid #636262" }}>
              <b>{record.statu}</b>
            </td>
          </tr>
          <tr>
            <th style={{ border: "1px solid #636262" }}>
              <b>Compliance Description</b>
            </th>
            <td style={{ border: "1px solid #636262" }}>
              <b>{record.compliance_description}</b>
            </td>
          </tr>
          <tr>
            <th style={{ border: "1px solid #636262" }}>
              <b>Penal Consequences</b>
            </th>
            <td style={{ border: "1px solid #636262" }}>
              <b>{record.penal_consequences}</b>
            </td>
          </tr>
        </tbody>
      </table>
    );
    return content;
  };


  const submitpassword = (e) => {
    setAddFormSubmit2(true);

    e.preventDefault();
    if (formValidator2.current.allValid()) {
      let temp = [];
      for (let i in temparr) {
        temp.push({
          compliance_id: temparr[i].compliance_id,
          compliance_history_id: temparr[i].compliance_history_id,
          unit_idl: temparr[i].unit_id,
          download_doc: temparr[i].download_url,
          reviewer_doc: temparr[i].reviwer_newdoc,
          interm_doc: temparr[i].interm_newdoc,
          interim_docs_size: Number(temparr[i].interim_docs_size),
          reviwer_docs_size: Number(temparr[i].reviwer_docs_size),
          document_size: temparr[i].document_size,
          recall_status: temparr[i].recall_status,
          recall_id: temparr[i].recall_id,
        });
      }

      ApproveTaskRecallSubmit({
        payload: [
          authtoken,
          {
            session_token: authtoken,
            request: [
              "UpdateTaskRecallStatus",
              {
                le_id: entityid,
                check_list: temp,
                action: recalldata.action,
                remarks: recalldata.remarks,
                password: password.passwordvalue,
                pwd_validate: true,
              },
            ],
          },
        ],
        paramid: paramid,
      });
      Setpasswordmodal(false);
    }
  };

  const showRemarksModal = () => {
    GetRemarksData({
      payload: [
        authtoken,
        {
          session_token: authtoken,
          request: ["GetRemarksData", {}],
        },
      ],
      paramid: paramid,
    });
    setRemarksModal(true);
  };
  const Active = () => {
    if (temparr.length > 0) {
      setFormRecall(true);
    } else {
      toast.error("Please Select Compliance to recall");
    }
    if (recallSubmit.current.allValid()) {
      if (temparr.length !== 0) {
        Swal.fire({
          title: "Are you sure?",
          icon: 'info',
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        }).then((result) => {
          if (result.isConfirmed) {
            Setpasswordmodal(true);
            setTimeout(() => {
              setFocus(serviceProviderRef1)
            }, 500);
          }
        });
      }
    }
  };
  // const data = [
  //     'Racing car sprays burning fuel into crowd.',
  //     'Japanese princess to wed commoner.',
  // ];
  const { Panel } = Collapse;
  const { Search } = Input;

  // const showModal = () => {
  //     setIsModalVisible(true);
  // };

  const handleOk = () => {
    setIsModalVisible(false);
    setIsComplainceModalVisible(false);
    setRemarksModal(false);
    setShowValueModal(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsComplainceModalVisible(false);
    setRemarksModal(false);
    setShowValueModal(false);
  };
  useEffect(() => {
    formValidator.current.showMessages();
    recallSubmit.current.showMessages();
    formValidator2.current.showMessages();
  }, []);
  useEffect(() => {
    if (isAuth) {
      if (entityid !== "") {
        const payload = [
          authtoken,
          {
            session_token: authtoken,
            request: [
              "TaskRecallFilters",
              {
                le_id: entityid,
              },
            ],
          },
        ];
        if (entityid != "null") {
          ApproveTaskRecallUnitList({
            payload: payload,
            paramid: paramid,
          });
        }
        Setshowdata({
          ...showdata,
          domain: "",
          unit: "",
          frequency: "",
          asignee: "",
        });
      }
    }
  }, [isAuth, entityid]);

  useEffect(() => {
    if (showdata.domain) {
      const assigneefilter = _.filter(list.assigneedata, { domain_id: parseInt(showdata.domain) });
      const ids = assigneefilter.map(o => o.username)
      const filtered = assigneefilter.filter(({ username }, index) => !ids.includes(username, index + 1))
      Setshowdata({
        ...showdata,
        assigneedata: filtered

      })
    }
  }, [showdata.domain])

  const showComplianceModal = () => {
    setIsComplainceModalVisible(true);
  };

  const gettaskrecalllist = () => {
    setFormShow(true);
    setTemp([]);
    setKey("0");
    setAssigneename([]);
    setfilteredArrayState([]);
    setUpdatedArray([]);
    setAll("All");
    if (formValidator.current.allValid() || showdata.domain != '') {
      setExitCollapse(true);
      setFilterModalVisible(false);
      setFilterTaskAcc(false);
      setAccordionTrue(true);
      getApproveTaskRecallList({
        payload: [
          authtoken,
          {
            session_token: authtoken,
            request: [
              "GetTaskRecallList",
              {
                le_id: entityid,
                // "unit_id": Number(showdata.unit),
                unit_id: parseInt(showdata.unit),
                d_id: Number(showdata.domain),
                frequency_id: Number(showdata.frequency),
                compl_date: "February-2022",
                parent_id: null,
                user_id: parseInt(showdata.asignee),
                start_count: 0,
                task_status: "Approver Task Recall",
              },
            ],
          },
        ],
        paramid: paramid,
      });
    }
  };
  useEffect(() => {
    let temparr = [];
    for (let i in checkedRow) {
      temparr.push({
        compliance_id: checkedRow[i].compliance_id,
        compliance_history_id: checkedRow[i].compliance_history_id,
        unit_idl: checkedRow[i].unit_id,
        download_doc: [],
        reviewer_doc: checkedRow[i].reviewer_doc,
        interim_docs_size: checkedRow[i].interim_docs_size,
        reviwer_docs_size: checkedRow[i].reviwer_docs_size,
        document_size: checkedRow[i].document_size,
        recall_status: checkedRow[i].recall_status,
        recall_id: checkedRow[i].recall_id,
      });
    }
    setCheckedRowData(temparr);
  }, [checkedRow]);

  useEffect(() => {
    if (
      list &&
      list.approverecallcompliance &&
      list.approverecallcompliance.length > 0
    ) {
      let uniqueObjArray = [
        ...new Map(
          list &&
          list.approverecallcompliance &&
          list.approverecallcompliance.length &&
          list.approverecallcompliance.map((item) => [item["unit_id"], item])
        ).values(),
      ];
      const uniqueIds =
        uniqueObjArray &&
        uniqueObjArray.map((item) => {
          return item.unit_id;
        });
      let tempArr = [];
      for (let i in uniqueIds) {
        let filteredArr = _.filter(list && list.approverecallcompliance, {
          unit_id: uniqueIds[i],
        });
        tempArr.push(filteredArr);
        setFilteredArray([...filteredArray, tempArr]);
      }
    }
  }, [list && list.approverecallcompliance]);
  const addAllSelectedDataValues = (checked) => {
    if (mergedArray.length > 0) {
      let tempArray = temparr;
      var ele = document.getElementsByName("checked");
      if (checked === true) {
        setCheckedRow(true);
        for (let i in mergedArray) {
          tempArray.push(mergedArray[i]);
        }
        /*Remove Duplicate*/
        let compliance_id = tempArray.map((item) => {
          return item.compliance_id;
        });
        var difference = tempArray.filter(
          (x) => compliance_id.indexOf(x) === -1
        );
        let finaldata = [...new Set(difference)];
        /*Remove Duplicate*/

        setTemparr([...finaldata]);
        for (var i = 0; i < ele.length; i++) {
          if (ele[i].type === "checkbox") ele[i].checked = true;
        }
      } else {
        setCheckedRow(false);
        tempArray = [];
        setTemparr([...tempArray]);
        for (var j = 0; j < ele.length; j++) {
          if (ele[j].type === "checkbox") ele[j].checked = false;
        }
      }
    } else {
      var eleclass = document.getElementsByClassName("allChecked");
      eleclass[0].checked = false;
      Toaster.error("No Compliance Available");
    }
  };
  const addSelectedDataValues = (checked, compliance_history_id, parentId) => {
    var ele = document.getElementsByName("allchecked");
    var eleclass = document.getElementsByClassName("allChecked");
    let array;
    let array1;
    let array2;
    let tempArray = temparr;
    if (checkedRow === true) {
      if (checked === true) {
        // array = _.find(recallcompliance, { compliance_history_id: compliance_history_id });
        // tempArray.push(array)
        array1 = _.filter(temp, { index: parentId });
        array = _.find(mergedArray, { index: parentId });
        tempArray.push(array);
        array2 = _.filter(tempArray, { index: parentId });
        if (array2.length === array1[0].child.length) {
          eleclass[0].checked = true;
        }
      } else {
        for (let i in tempArray) {
          if (tempArray[i].compliance_history_id === compliance_history_id) {
            tempArray.splice(i, 1);
          }
          if (updatedArray.length !== temparr.length) {
            setCheckedRow(false);
            eleclass[0].checked = false;
          }
        }
      }
      setTemparr([...tempArray]);
    } else {
      if (checked === true) {
        array = _.find(mergedArray, {
          compliance_history_id: compliance_history_id,
        });
        tempArray.push(array);
        if (tempArray.length === mergedArray.length) {
          eleclass[0].checked = true;
        }
      } else {
        for (let i in tempArray) {
          if (tempArray[i].compliance_history_id === compliance_history_id) {
            tempArray.splice(i, 1);
          }
        }
        eleclass[0].checked = false;
      }
      setTemparr([...tempArray]);
    }
  };
  const homescreen = () => {
    historynew.push(homepage);
    // window.location.href = homepage
    document.getElementById('homehover').style.color = "blue"
  };
  const onFinish = () => {
    const res = []
    const upres = []
    const keys = Object.keys(filterValues)
    let name = []
    keys.forEach(key => {
      if (filterValues[key] === true) {
        if (key == 'on_occurrence') {
          key = 'on occurrence'
          name = 'On Occurrence'
        }
        if (key == 'one_time') {
          key = 'one time'
          name = 'One Time'
        }
        if (key == 'periodical') {
          key = 'periodical'
          name = 'Periodical'
        }
        if (key == 'review') {
          key = 'review'
          name = 'Review'
        }
        if (key == 'flexi_review') {
          key = 'flexi_review'
          name = 'Flexi Review'
        }
        res.push(key)
        console.log(key, 'keykey')
        upres.push(
          name.toUpperCase().replace(/_/g, " ")
        )
        setFilterValues1(res)
      }
      // filterValues[key] === true && upres.push(
      //   key.toUpperCase().replace(/_/g, " ")
      // )

      // all.toString().charAt(0).toUpperCase() + all.toString().slice(1)
      // if(key == )
    })
    if (upres.length > 0 && upres.length != 5) {
      console.log(upres, 'upres')
      setAll(upres.join(', '))
    } else {
      setAll('All')
    }
    let filterTable;
    let temp = []
    let a
    if (res[0] == "All" || res.length == 0) {
      filterTable = updatedArray;
      settotalRecord(filterTable.length);
    } else {
      for (let i in res) {
        a = updatedArray.filter((o) =>
          Object.keys(o).some((k) =>
            String(o[k]).toLowerCase().includes(res[i].toLowerCase())
          )
        );

        Array.prototype.push.apply(temp, a);


      }
      filterTable = temp

      settotalRecord(filterTable.length);
    }

    if (searchingValue['compliance_task']) {
      filterTable = filterTable.filter((item) => {
        if ((item["statu"].toLowerCase().includes(searchingValue['compliance_task'].toLowerCase())) || (item["compliance_name"].toLowerCase().includes(searchingValue['compliance_task'].toLowerCase()))) {
          return true;
        }
      })
    }
    if (searchingValue['domain']) {
      filterTable = filterTable.filter((item) => {
        if ((item["domain_name"].toLowerCase().includes(searchingValue['domain'].toLowerCase()))) {
          return true;
        }
      })
    }
    if (searchingValue['start_date']) {
      filterTable = filterTable.filter((item) => {
        if ((item["start_date"].toLowerCase().includes(searchingValue['start_date'].toLowerCase()))) {
          return true;
        }
      })
    }
    if (searchingValue['due_date']) {
      filterTable = filterTable.filter((item) => {
        if ((item["due_date"].toLowerCase().includes(searchingValue['due_date'].toLowerCase()))) {
          return true;
        }
      })
    }
    if (searchingValue['time_line']) {
      filterTable = filterTable.filter((item) => {
        if ((item["delayed_by"].replace(/\s\s+/g, ' ').toLowerCase().includes(searchingValue['time_line'].toLowerCase()))) {
          return true;
        }
      })
    }

    let totalList = [];
    let arr = [];
    let countrylisttempdata = [];
    let tempSetArray = [];
    let parId = [];
    if (filterTable && filterTable.length > 0) {
      assigneename &&
        assigneename.length > 0 &&
        assigneename.map(function (user) {
          if (arr.indexOf(user.assignee_name) < 0)
            arr.push({
              assignee_name: user.assignee_name,
              unit_name: user.unit_name,
            });
        });

      arr.map((pmenu, index) => {
        // deepscan-disable-line ARRAY_CALLBACK_RETURN_MISSING
        let entityArray = [];
        let tempArray = [];
        let unit_name = [];
        if (pmenu) {
          filterTable &&
            filterTable.map((subMenuList, childIndex) => {
              if (
                pmenu.assignee_name === subMenuList.assignee_name &&
                pmenu.unit_name === subMenuList.unit_name
              ) {
                entityArray = {
                  address: subMenuList.address,
                  approve_status: subMenuList.approve_status,
                  approved_on: subMenuList.approved_on,
                  assigned_on: subMenuList.assigned_on,
                  assignee_name: subMenuList.assignee_name,
                  category_name: subMenuList.category_name,
                  assignee_remarks: subMenuList.assignee_remarks,
                  compfielaw_url: subMenuList.compfielaw_url,
                  completion_date: subMenuList.completion_date,
                  compliance_description: subMenuList.compliance_description,
                  compliance_frequency: subMenuList.compliance_frequency,
                  compliance_history_id: subMenuList.compliance_history_id,
                  compliance_id: subMenuList.compliance_id,
                  compliance_name: subMenuList.compliance_name,
                  country_id: subMenuList.country_id,
                  division_name: subMenuList.division_name,
                  document_reference_number:
                    subMenuList.document_reference_number,
                  document_size: subMenuList.document_size,
                  domain_id: subMenuList.domain_id,
                  domain_name: subMenuList.domain_name,
                  download_url: subMenuList.download_url,
                  due_date: subMenuList.due_date,
                  duration_type: subMenuList.duration_type,
                  extn_due_date: subMenuList.extn_due_date,
                  file_names: subMenuList.file_names,
                  format_file_name: subMenuList.format_file_name,
                  history_count: subMenuList.history_count,
                  interim_count: subMenuList.interim_count,
                  interim_docs: subMenuList.interim_docs,
                  interim_docs_size: subMenuList.interim_docs_size,
                  interm_newdoc: subMenuList.interm_newdoc,
                  next_due_date: subMenuList.next_due_date,
                  occurrence_remarks: subMenuList.occurrence_remarks,
                  penal_consequences: subMenuList.penal_consequences,
                  recall_id: subMenuList.recall_id,
                  recall_status: subMenuList.recall_status,
                  remarks: subMenuList.remarks,
                  remarks_count: subMenuList.remarks_count,
                  reviwer_docs: subMenuList.reviwer_docs,
                  reviwer_docs_size: subMenuList.reviwer_docs_size,
                  reviwer_newdoc: subMenuList.reviwer_newdoc,
                  start_date: subMenuList.start_date,
                  statu: subMenuList.statu,
                  system_remarks: subMenuList.system_remarks,
                  task_status: subMenuList.task_status,
                  tz_date: subMenuList.tz_date,
                  tz_name: subMenuList.tz_name,
                  unit_id: subMenuList.unit_id,
                  unit_name: subMenuList.unit_name,
                  upload_docs: subMenuList.upload_docs,
                  validity_date: subMenuList.validity_date,
                  validity_settings_days: subMenuList.validity_settings_days,
                  parentId: index,
                  childId: childIndex,
                  indexNo: childIndex + 1,
                };
                tempArray.push(entityArray);
                unit_name.push(subMenuList.unit_name);
              }
            });
        }
        totalList = {
          index: index,
          parentData: pmenu,
          child: tempArray,
        };
        titleDynamic[index] = pmenu;
        countrylisttempdata.push(totalList);
        tempSetArray.push(tempArray);
        parId.push(unit_name);
      });

      setTitleDynamic([...titleDynamic]);
      var merged = [].concat.apply([], tempSetArray);
      setTemp(countrylisttempdata);
      setMergedArray(merged);
      setTempFilterArray([...new Set([].concat.apply([], parId))]);
      setSearchedUpdatedArrayFilter(true)

      // setChecked({
      //   All: false,
      //   periodical: false,
      //   flexi: false,
      //   review: false,
      //   on_occurrence: false,
      //   one_time: false

      // })
    } else {
      setMergedArray([]);
      setTemp([]);
      setTitleDynamic([]);
      setTempFilterArray([]);
      setSearchedUpdatedArrayFilter(false)

    }
    setshowDropDown(false)
  }
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setshowDropDown(false)
    }
  };

  const viewinterim = (data) => {
    const payload = [
      authtoken,
      {
        "session_token": authtoken,
        "request": [
          "GetInterimComplianceDetails",
          {
            "legal_entity_id": showdata.legal_entity,
            "compliance_history_id": data.compliance_history_id
          }
        ]
      }
    ]
    interimrecord({
      payload: payload,
      paramid: paramid
    })
    setinterimmodal(true);
  }

  const viewhistory = (data) => {
    const payload = [
      authtoken,
      {
        "session_token": authtoken,
        "request": [
          "GetComplianceRemarksHistoryData",
          {
            "legal_entity_id": showdata.legal_entity,
            "unit_id": data.unitid,
            "compliance_history_id": data.compliance_history_id
          }
        ]
      }
    ]
    historyrecord({
      payload: payload,
      paramid: paramid
    })
    sethistorymodal(true);
  }

  const history_columns = [
    {
      title: '#',
      key: 'index',
      align: "center",
      width: "30px",
      render: (text, record, index) => {
        return (
          index + 1
        )
      },
    },
    {
      title: 'Activity Date',
      key: 'activity_on',
      align: "center",
      width: "50px",
      dataIndex: 'activity_on',
    },
    {
      title: 'User Type',
      key: 'action',
      align: "center",
      width: "50px",
      dataIndex: 'action',
      render: (text, record) => {
        if (record.user_category_id == 5 || record.user_category_id == 6) {
          return 'Assignee'
        } else if ((record.user_category_id == 3 || record.user_category_id == 4) && (record.action == 'Rectified' || record.action == 'Concurrence Rejected' || record.action == 'Concurred')) {
          return 'Concurrer'
        } else if (record.user_category_id == 1 && (record.action == 'RectifyApproval' || record.action == 'Approval Rejected' || record.action == 'Approved') ||
          (record.user_category_id == 3 || record.user_category_id == 4) && (record.action == 'Approval Rejected' || record.action == 'RectifyApproval' || record.action == 'Approved')) {
          return 'Approver'
        }

      }
    },
    {
      title: 'Reviewer Report',
      key: 'documents',
      align: "center",
      width: "50px",
      dataIndex: 'documents',
    },
    {
      title: 'Remarks',
      key: 'remarks',
      align: "center",
      width: "50px",
      dataIndex: 'remarks',
    },
  ]

  const interim_columns = [
    {
      title: '#',
      key: 'index',
      align: "center",
      width: "30px",
      render: (text, record, index) => {
        return (
          index + 1
        )
      },
    },
    {
      title: 'Documents',
      key: 'documents',
      align: "center",
      width: "50px",
      dataIndex: 'documents',
    },
    {
      title: 'Remarks',
      key: 'remarks',
      align: "center",
      width: "50px",
      dataIndex: 'remarks',
    },
    {
      title: 'Updated On',
      key: 'activity_on',
      align: "center",
      width: "50px",
      dataIndex: 'activity_on',
    },
  ]

  const historycancel = () => {
    sethistorymodal(false);
  }

  const interimcancel = () => {
    setinterimmodal(false);
  }


  const resetfilter = () => {
    setFilterValues({
      ...filterValues,
      All: '',
      periodical: '',
      flexi_review: '',
      review: '',
      on_occurrence: '',
      one_time: ''
    })
    setChecked({
      ...checked,
      All: false,
      periodical: false,
      flexi_review: false,
      review: false,
      on_occurrence: false,
      one_time: false
    })
  }

  useEffect(() => {
    if (showdata.legal_entity != '' && list.domain && list.domain.length == 1) {
      setDomainValue(true)
      Setshowdata({
        ...showdata,
        domain: list.domain[0].d_id,
      })

    } else {
      setDomainValue(false)
      setDomainFilter(list.domain)

    }
  }, [list.domain])

  return (
    <>
      <div id="page-wrapper" className="page-wrapper">
        <div className='back-to-top'>
          <ScrollButton />
        </div>
        <div className="page-titles pb-0 pt-0 page-title-sticky">
          <div className="row">
            <div className="col-lg-8 col-md-6 col-12 align-self-center">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0 d-flex align-items-center">
                  <li className="breadcrumb-item">
                    <span className="pointer" id='homehover' onClick={homescreen}>
                      <i className="ri-home-3-line fs-5"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                    <span style={{ fontSize: '16px' }}>Transaction</span>
                  </li>
                  <li
                    className="breadcrumb-item active"
                    aria-current="page"
                    style={{ fontSize: "large", fontWeight: "bold" }}
                  >
                    <span>
                      Approve Task Recall
                    </span>
                  </li>
                </ol>
              </nav>
              {/* <h2 className="mb-0 fw-bold">Approve Task Recall</h2> */}
            </div>
            <div
              className="col-lg-4 col-md-6 d-none d-md-flex align-items-center justify-content-end py-1"
              style={{ paddingRight: "14px" }}
            >
              {/* <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF" }}
                        icon={<ArrowLeftOutlined />} size='default' onClick={(e) => { previous(e) }}>
                        Back
                      </Button> */}
              <Tooltip title='Advanced Filter' placement="top">   <Button
                type="primary"
                shape="round"
                style={{
                  marginRight: "10px",
                  display: filterTaskAcc ? "none" : "block",
                }}
                onClick={filterHandle}
              >
                <FilterOutlined /> Filter
              </Button></Tooltip>

              <button
                onClick={(e) => {
                  fullscreenMode();
                }}
                className="bg-transparent text-black ms-2 full-mode fullscreenradius"
              >
                <Tooltip
                  placement="left"
                  title={is_Fullscreen == true ? "Exit Full Screen Mode" : "Full Screen Mode"}
                >
                  <FullscreenOutlined />
                </Tooltip>
              </button>
            </div>
          </div>
        </div>
        <div className="container-fluid pt-1">
          <div className="row">
            <div className="col-lg-12">
              <div
                className={
                  "card1 card-body1-statuatory " +
                  localStorage.getItem("currentTheme")
                }
              >
                <Collapse
                  className="report-collapse"
                  accordion
                  defaultActiveKey={["1"]}
                  style={{ display: exitCollapse ? "none" : "block" }}

                >
                  <Panel header="Approve Task Recall" key={'1'}>
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="">
                              <b>Legal Entity : </b>
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <br />
                            {localStorage.getItem("SelectedEntity") ===
                              "All Legal Entity" ? (
                              <div className="form-group">
                                <Select
                                  allowClear={true}
                                  size="default"
                                  // onPaste={(e) => {
                                  //   handlePasting(e, /^[a-z 0-9 ( )]+$/gi)
                                  // }}
                                    onPaste={onPasteToaster}
                                    searchValue={selectSearchValue.legalEntity}
                                    onSearch={(value) => {
                                      let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                      setSelectSearchValue({
                                        ...selectSearchValue,
                                        legalEntity: result
                                      });
                                    }}
                                  placeholder="Select Legal Entity"
                                  onChange={(data, value) => {
                                    setDomainFilter([])
                                    setDomainValue(false)
                                    if (data !== undefined) {
                                      setCurrentEntity(data);
                                      Setshowdata({
                                        ...showdata,
                                        legal_entity: data,
                                        domain: "",
                                        unit: "",
                                        frequency: "",
                                        frequeny_name: '',
                                        asignee: "",
                                      });
                                      setFormShow(false);
                                    }
                                    else {
                                      setCurrentEntity("");
                                      Setshowdata({
                                        ...showdata,
                                        legal_entity: "",
                                        domain: "",
                                        unit: "",
                                        frequency: "",
                                        frequeny_name: '',
                                        asignee: "",
                                      });
                                      setAssigneename([]);
                                      setfilteredArrayState([]);
                                      setUpdatedArray([]);
                                      setTemp([])
                                    }
                                  }}
                                  showSearch
                                  optionFilterProp="children"
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                  value={showdata.legal_entity || undefined}
                                  style={{ width: "100%", marginTop: "5px" }}
                                >
                                  {sessionArr &&
                                    sessionArr.length > 0 &&
                                    sessionArr.map((item, i) => {
                                      return (
                                        <Option key={item.le_id}>
                                          {item.le_name}
                                        </Option>
                                      );
                                    })}
                                </Select>
                                {formValidator.current.message(
                                  "legal_entity",
                                  showdata.legal_entity,
                                  "required",
                                  {
                                    className: `invalid-feedback ${FormShow ? "show" : "hide"
                                      }`,
                                    messages: {
                                      required: "Legal Entity Required",
                                    },
                                  }
                                )}
                              </div>
                            ) : (
                              <p title={localStorage.getItem('SelectedEntity')} className='text-wrap-report' style={{ width: "100%", marginTop: "5px" }}>{localStorage.getItem('SelectedEntity')}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="">
                              <b>Domain : </b>{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <br />
                            {domainValue == false || domainFilter && domainFilter.length > 1 ?
                              <>
                                <Select
                                  disabled={
                                    entityid == "" ||
                                      entityid == null ||
                                      entityid == "null"
                                      ? true
                                      : false
                                  }
                                  allowClear={true}
                                  // onPaste={(e) => {
                                  //   handlePasting(e, /^[A-Z0-9a-z]+$/gi)
                                  // }}
                                  onPaste={onPasteToaster}
                                  searchValue={selectSearchValue.domain}
                                  onSearch={(value) => {
                                    let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                    setSelectSearchValue({
                                      ...selectSearchValue,
                                      domain: result
                                    });
                                  }}
                                  size="default"
                                  placeholder="Select Domain"
                                  onChange={(data) => {
                                    if (data !== undefined) {
                                      Setshowdata({
                                        ...showdata,
                                        domain: data,
                                        unit: "",
                                        frequency: "",
                                        frequeny_name: '',
                                        asignee: "",
                                      });
                                    } else {
                                      Setshowdata({
                                        ...showdata,
                                        domain: "",
                                        unit: "",
                                        frequency: "",
                                        frequeny_name: '',
                                        asignee: "",
                                      });
                                    }
                                  }}
                                  // onSearch={onSearch}
                                  showSearch
                                  optionFilterProp="children"
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                  value={showdata.domain || undefined}
                                  style={{ width: "100%", marginTop: "5px" }}
                                >
                                  {domainFilter &&
                                    domainFilter.length > 0 &&
                                    domainFilter.map((item, i) => {
                                      return (
                                        <Option key={item.d_id}>
                                          {item.d_name}
                                        </Option>
                                      );
                                    })}
                                </Select>
                                {formValidator.current.message(
                                  "domain",
                                  showdata.domain,
                                  ["required"],
                                  {
                                    className: `invalid-feedback ${FormShow ? "show" : "hide"
                                      }`,
                                    messages: {
                                      required: "Domain Required",
                                    },
                                  }
                                )}
                              </>
                              : <p className='cut-text-word' style={{ width: '100%', marginTop: "5px" }}>{list.domain && list.domain.length > 0 && list.domain[0].d_name}</p>}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="">
                              <b>Vendor : </b>{" "}
                            </label>
                            <br />
                            <Select
                              // disabled={entityid != "" ||  entityid != null ? false : true}
                              disabled={
                                showdata.domain == "" ||
                                  showdata.domain == null ||
                                  showdata.domain == "null"
                                  ? true
                                  : false
                              }
                              allowClear={true}
                              size="default"
                              placeholder="Select Vendor"
                              // onPaste={(e) => {
                              //   handlePasting(e, /^[A-Z0-9a-z _ .,-]+$/gi)
                              // }}
                              onPaste={onPasteToaster}
                              searchValue={selectSearchValue.vendor}
                              onSearch={(value) => {
                                let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                setSelectSearchValue({
                                  ...selectSearchValue,
                                  vendor: result
                                });
                              }}
                              onChange={(data) => {
                                if (data !== undefined) {
                                  Setshowdata({
                                    ...showdata,
                                    unit: data,
                                    frequency: "",
                                    frequeny_name: '',
                                    asignee: "",
                                  });
                                } else {
                                  Setshowdata({
                                    ...showdata,
                                    unit: "",
                                    frequency: "",
                                    frequeny_name: '',
                                    asignee: "",
                                  });
                                }
                              }}
                              // onSearch={onSearch}
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              value={showdata.unit || undefined}
                              style={{ width: "100%", marginTop: "5px" }}
                            >
                              {list &&
                                list.unitdata.length > 0 &&
                                list.unitdata.map((item, i) => {
                                  return (
                                    <Option key={item.unit_id}>
                                      {item.unit_name}
                                    </Option>
                                  );
                                })}
                            </Select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12" style={{ marginTop: "10px" }}>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="">
                              <b>Frequency : </b>
                            </label>
                            <br />
                            <Select
                              allowClear={true}
                              size="default"
                              placeholder="Select Frequency"
                              onChange={(data, value) => {
                                if (data !== undefined) {
                                  Setshowdata({
                                    ...showdata,
                                    frequency: data,
                                    frequeny_name: value.children
                                  });
                                } else {
                                  Setshowdata({
                                    ...showdata,
                                    frequency: "",
                                    frequeny_name: ''
                                  });
                                }
                              }}
                              // onSearch={onSearch}
                              // showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              value={showdata.frequency || undefined}
                              style={{ width: "100%", marginTop: "5px" }}
                            >
                              <Option key={0}>All</Option>
                              {list &&
                                list.frequency.length > 0 &&
                                list.frequency.map((item, i) => {
                                  return (
                                    <Option key={item.frequency_id}>
                                      {item.frequency_name}
                                    </Option>
                                  );
                                })}
                            </Select>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="">
                              <b>Assignee : </b>
                            </label>
                            <br />
                            <Select
                              disabled={
                                showdata.domain == "" ||
                                  showdata.domain == null ||
                                  showdata.domain == "null"
                                  ? true
                                  : false
                              }
                              allowClear={true}
                              size="default"
                              placeholder="Select Assignee"
                              // onPaste={(e) => {
                              //   handlePasting(e, /^[A-Z0-9a-z -]+$/gi)
                              // }}
                              onPaste={onPasteToaster}
                              searchValue={selectSearchValue.assignee}
                              onSearch={(value) => {
                                let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                setSelectSearchValue({
                                  ...selectSearchValue,
                                  assignee: result
                                });
                              }}
                              onChange={(data, value) => {
                                console.log(value, "valuevalue");
                                console.log(data, "datadata");
                                if (data !== undefined) {

                                  Setshowdata({
                                    ...showdata,
                                    asignee: data,
                                  });
                                } else {
                                  Setshowdata({
                                    ...showdata,
                                    asignee: ''
                                  });
                                }
                              }}
                              // onSearch={onSearch}
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              value={showdata.username || undefined}
                              style={{ width: "100%", marginTop: "5px" }}
                            >
                              {assignee &&
                                assignee.length > 0 &&
                                assignee.map((item, i) => {
                                  return (
                                    <Option key={item.user_id}>
                                      {item.username}
                                    </Option>
                                  );
                                })}
                            </Select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mt-3 popupbtncolour text-center">
                      <Button
                        type="primary"
                        shape="round"
                        className={
                          "addbutton " + localStorage.getItem("currentTheme")
                        }

                        size="default"
                        icon={<EyeOutlined />}
                        onClick={() => {
                          gettaskrecalllist();
                        }}
                      >
                        Show
                      </Button>
                    </div>
                  </Panel>
                </Collapse>
                <Modal title="Approve Task Recall"
                  className={
                    "modal-service-priv add-service-prv " +
                    localStorage.getItem("currentTheme")
                  }
                  footer={false}
                  open={filterModalVisible}
                  onCancel={setcancelFilter}
                  maskClosable={false}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="">
                          <b>Legal Entity : </b>
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <br />
                        {localStorage.getItem("SelectedEntity") ===
                          "All Legal Entity" ? (
                          <div className="form-group">
                            <Select
                              getPopupContainer={trigger => trigger.parentNode}
                              allowClear={true}
                              size="default"
                              placeholder="Select Legal Entity"
                              // onPaste={(e) => {
                              //   handlePasting(e, /^[a-z 0-9 ( )]+$/gi)
                              // }}
                                onPaste={onPasteToaster}
                                searchValue={selectSearchValue.legalEntity}
                                onSearch={(value) => {
                                  let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                  setSelectSearchValue({
                                    ...selectSearchValue,
                                    legalEntity: result
                                  });
                                }}
                              onChange={(data, value) => {
                                setDomainFilter([])
                                setDomainValue(false)
                                if (data !== undefined) {
                                  setCurrentEntity(data);
                                  Setshowdata({
                                    ...showdata,
                                    legal_entity: data,
                                    domain: "",
                                    unit: "",
                                    frequency: "",
                                    frequeny_name: '',
                                    asignee: "",
                                  });
                                  setFormShow(false);
                                } else {
                                  setCurrentEntity("");
                                  Setshowdata({
                                    ...showdata,
                                    legal_entity: "",
                                    domain: "",
                                    unit: "",
                                    frequency: "",
                                    frequeny_name: '',
                                    asignee: "",
                                  });
                                  setAssigneename([]);
                                  setfilteredArrayState([]);
                                  setUpdatedArray([]);
                                  setTemp([])
                                }
                              }}
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              value={showdata.legal_entity}
                              style={{ width: "100%", marginTop: "5px" }}
                            >
                              {sessionArr &&
                                sessionArr.length > 0 &&
                                sessionArr.map((item, i) => {
                                  return (
                                    <Option key={item.le_id}>
                                      {item.le_name}
                                    </Option>
                                  );
                                })}
                            </Select>
                            {formValidator.current.message(
                              "legal_entity",
                              showdata.legal_entity,
                              "required",
                              {
                                className: `invalid-feedback ${FormShow ? "show" : "hide"
                                  }`,
                                messages: {
                                  required: "Legal Entity Required",
                                },
                              }
                            )}
                          </div>
                        ) : (
                          <p title={localStorage.getItem('SelectedEntity')} className='text-wrap-report' style={{ width: "100%", marginTop: "5px" }}>{localStorage.getItem('SelectedEntity')}</p>

                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="">
                          <b>Domain : </b>{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <br />
                        {domainValue == false || domainFilter && domainFilter.length > 1 ?
                          <>
                            <Select
                              getPopupContainer={trigger => trigger.parentNode}
                              // disabled={entityid != "" ? false : true}
                              disabled={
                                entityid == "" ||
                                  entityid == null ||
                                  entityid == "null"
                                  ? true
                                  : false
                              }
                              allowClear={true}
                              // onPaste={(e) => {
                              //   handlePasting(e, /^[a-z 0-9]+$/gi)
                              // }}
                              onPaste={onPasteToaster}
                              searchValue={selectSearchValue.domain}
                              onSearch={(value) => {
                                let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                setSelectSearchValue({
                                  ...selectSearchValue,
                                  domain: result
                                });
                              }}
                              size="default"
                              placeholder="Select Domain"
                              onChange={(data) => {
                                if (data !== undefined) {
                                  Setshowdata({
                                    ...showdata,
                                    domain: data,
                                    unit: "",
                                    frequency: "",
                                    frequeny_name: '',
                                    asignee: "",
                                  });
                                } else {
                                  Setshowdata({
                                    ...showdata,
                                    domain: "",
                                    unit: "",
                                    frequency: "",
                                    frequeny_name: '',
                                    asignee: "",
                                  });
                                }
                              }}
                              // onSearch={onSearch}
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              value={showdata.domain || undefined}
                              style={{ width: "100%", marginTop: "5px" }}
                            >
                              {domainFilter &&
                                domainFilter.length > 0 &&
                                domainFilter.map((item, i) => {
                                  return (
                                    <Option key={item.d_id}>
                                      {item.d_name}
                                    </Option>
                                  );
                                })}
                            </Select>
                            {formValidator.current.message(
                              "domain",
                              showdata.domain,
                              ["required"],
                              {
                                className: `invalid-feedback ${FormShow ? "show" : "hide"
                                  }`,
                                messages: {
                                  required: "Domain Required",
                                },
                              }
                            )}
                          </>
                          : <p className='cut-text-word' style={{ width: '100%', marginTop: "5px" }}>{list.domain && list.domain.length > 0 && list.domain[0].d_name}</p>}
                      </div>
                    </div>
                    <div className="col-md-6 mt-1">
                      <div className="form-group">
                        <label htmlFor="">
                          <b>Vendor : </b>{" "}
                        </label>
                        <br />
                        <Select
                          getPopupContainer={trigger => trigger.parentNode}
                          // disabled={entityid != "" ||  entityid != null ? false : true}
                          // onPaste={(e) => {
                          //   handlePasting(e, /^[A-Z0-9a-z _ .,-]+$/gi)
                          // }}
                          onPaste={onPasteToaster}
                          searchValue={selectSearchValue.vendor}
                          onSearch={(value) => {
                            let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                            setSelectSearchValue({
                              ...selectSearchValue,
                              vendor: result
                            });
                          }}
                          disabled={
                            showdata.domain == "" ||
                              showdata.domain == null ||
                              showdata.domain == "null"
                              ? true
                              : false
                          }
                          allowClear={true}
                          size="default"
                          placeholder="Select Vendor"
                          onChange={(data) => {
                            if (data !== undefined) {
                              Setshowdata({
                                ...showdata,
                                unit: data,
                                frequency: "",
                                frequeny_name: '',
                                asignee: "",
                              });
                            } else {
                              Setshowdata({
                                ...showdata,
                                unit: "",
                                frequency: "",
                                frequeny_name: '',
                                asignee: "",
                              });
                            }
                          }}
                          // onSearch={onSearch}
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          value={showdata.unit || undefined}
                          style={{ width: "100%", marginTop: "5px" }}
                        >
                          {list &&
                            list.unitdata.length > 0 &&
                            list.unitdata.map((item, i) => {
                              return (
                                <Option key={item.unit_id}>
                                  {item.unit_name}
                                </Option>
                              );
                            })}
                        </Select>
                      </div>
                    </div>
                    <div className="col-md-6 mt-1">
                      <div className="form-group">
                        <label htmlFor="">
                          <b>Frequency : </b>
                        </label>
                        <br />
                        <Select
                          getPopupContainer={trigger => trigger.parentNode}
                          allowClear={true}
                          size="default"
                          placeholder="Select Frequency"
                          onChange={(data, value) => {
                            if (data !== undefined) {
                              Setshowdata({
                                ...showdata,
                                frequency: data,
                                frequeny_name: value.children
                              });
                            } else {
                              Setshowdata({
                                ...showdata,
                                frequency: "",
                                frequeny_name: ''
                              });
                            }
                          }}
                          // onSearch={onSearch}
                          // showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          value={showdata.frequency || undefined}
                          style={{ width: "100%", marginTop: "5px" }}
                        >
                          <Option key={0}>All</Option>
                          {list &&
                            list.frequency.length > 0 &&
                            list.frequency.map((item, i) => {
                              return (
                                <Option key={item.frequency_id}>
                                  {item.frequency_name}
                                </Option>
                              );
                            })}
                        </Select>
                      </div>
                    </div>
                    <div className="col-md-6 mt-1">
                      <div className="form-group">
                        <label htmlFor="">
                          <b>Assignee : </b>
                        </label>
                        <br />
                        <Select
                          // disabled={entityid != "" ? false : true}
                          getPopupContainer={trigger => trigger.parentNode}
                          disabled={
                            showdata.domain == "" ||
                              showdata.domain == null ||
                              showdata.domain == "null"
                              ? true
                              : false
                          }
                          allowClear={true}
                          size="default"
                          placeholder="Select Assignee"
                          // onPaste={(e) => {
                          //   handlePasting(e, /^[A-Z0-9a-z -]+$/gi)
                          // }}
                          onPaste={onPasteToaster}
                          searchValue={selectSearchValue.assignee}
                          onSearch={(value) => {
                            let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                            setSelectSearchValue({
                              ...selectSearchValue,
                              assignee: result
                            });
                          }}
                          onChange={(data, value) => {
                            console.log(value, "valuevalue");
                            console.log(data, "datadata");
                            if (data !== undefined) {

                              Setshowdata({
                                ...showdata,
                                asignee: data,
                              });
                            }
                          }}
                          // onSearch={onSearch}
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          value={showdata.asignee || undefined}
                          style={{ width: "100%", marginTop: "5px" }}
                        >
                          {assignee &&
                            assignee.length > 0 &&
                            assignee.map((item, i) => {
                              return (
                                <Option key={item.user_id}>
                                  {item.username}
                                </Option>
                              );
                            })}
                        </Select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mt-3 popupbtncolour text-center">

                    <Button
                      type="primary"
                      shape="round"
                      className={
                        "addbutton " + localStorage.getItem("currentTheme")
                      }
                      style={{
                        background: "#0096FF",
                        borderColor: "#0096FF",
                      }}
                      size="default"
                      icon={<EyeOutlined />}
                      onClick={() => {
                        gettaskrecalllist();
                      }}
                    >
                      Show
                    </Button>
                  </div>
                </Modal>
                {acccordionTrue == true ? (
                  <div className="mt-2 mx-2" id="only-restricted">
                    <Collapse
                      className="report-collapse"
                      defaultActiveKey={["1"]}
                      accordion
                    >
                      <Panel
                        header={"Compliance Table"}
                        extra={
                          <>
                            <span style={{ paddingRight: "80px" }}>
                              Selected Frequency : {all.toString()}

                            </span>
                            <span style={{ paddingRight: "80px" }}>
                              Total Compliance : {totalRecord}
                            </span>
                          </>
                        }
                        key="1"
                      >
                        <Fragment>
                          <div className="ant-table-wrapper service-provider-table">
                            <div className="ant-table ant-table-bordered ant-table-fixed-header">
                              <div className="ant-table-container">
                                <div className="ant-table-content taskrecalltable ">
                                  <table className="table-fixed">
                                    <thead className="ant-table-thead">
                                      <tr>
                                        <th className="ant-table-cell" style={{ width: "5px" }}>
                                          <div className="d-flex justify-content-center align-items-center">
                                            <span className="me-1"># </span>
                                            <div className="position-relative" ref={wrapperRef}>
                                              <button onClick={(e) => { setshowDropDown(current => !current) }}>
                                                <FilterFilled />
                                              </button>
                                              {showDropDown && (
                                                <Form onFinish={onFinish} className='bg-white position-absolute text-start shadow-lg' style={{ borderRadius: '15px', top: '32px', width: '165px' }}>

                                                  <Form.Item className="mt-1" name={'periodical'} >
                                                    &nbsp;<Checkbox id={'periodical'} value={'periodical'} checked={checked.periodical} onChange={(e) => {
                                                      e.target.checked ? setChecked({
                                                        ...checked,
                                                        periodical: true
                                                      }) : setChecked({
                                                        ...checked,
                                                        periodical: false
                                                      })

                                                      setFilterValues({
                                                        ...filterValues,
                                                        periodical: e.target.checked
                                                      })
                                                    }} />&nbsp; <label className="fw-normal user-select-none" htmlFor="periodical"><i style={{ color: 'green' }} className="bi bi-square-fill"></i> Periodical</label>
                                                  </Form.Item>
                                                  <Form.Item name={'flexi_review'}>
                                                    &nbsp;<Checkbox id="flexi_review" value={'flexi review'} checked={checked.flexi_review} onChange={(e) => {
                                                      e.target.checked ? setChecked({
                                                        ...checked,
                                                        flexi_review: true
                                                      }) : setChecked({
                                                        ...checked,
                                                        flexi_review: false
                                                      })

                                                      setFilterValues({
                                                        ...filterValues,
                                                        flexi_review: e.target.checked
                                                      })
                                                    }} /> &nbsp;
                                                    <label className="fw-normal user-select-none" htmlFor="flexi_review"><i style={{ color: "pink" }} className="bi bi-square-fill"></i> Flexi Review</label>
                                                  </Form.Item>
                                                  {/* <Form.Item name={'flexi_review'}>
                                                  &nbsp;<Checkbox id="flexi_review" value={'flexi_review'} checked={checked.flexi_review} onChange={(e) => {
                                                    e.target.checked ? setChecked({
                                                      ...checked,
                                                      flexi_review: true
                                                    }) : setChecked({
                                                      ...checked,
                                                      flexi_review: false
                                                    })

                                                    setFilterValues({
                                                      ...filterValues,
                                                      flexi_review: e.target.checked
                                                    })
                                                  }} /> &nbsp;
                                                  <label className="fw-normal user-select-none" htmlFor="flexi_review"><i style={{ color: "pink" }} className="bi bi-square-fill"></i> Flexi Review</label>

                                                </Form.Item> */}
                                                  <Form.Item name={'Review'}>
                                                    &nbsp;<Checkbox id="Review" value={'Review'} checked={checked.review} onChange={(e) => {
                                                      e.target.checked ? setChecked({
                                                        ...checked,
                                                        review: true
                                                      }) : setChecked({
                                                        ...checked,
                                                        review: false
                                                      })

                                                      setFilterValues({
                                                        ...filterValues,
                                                        review: e.target.checked
                                                      })
                                                    }} /> &nbsp;<label className="fw-normal user-select-none" htmlFor="Review"><i style={{ color: "orange" }} className="bi bi-square-fill"></i> Review</label>
                                                  </Form.Item>
                                                  <Form.Item name={'On Occurrence'}>
                                                    &nbsp;<Checkbox id="On-Occurrence" value={'On Occurrence'} checked={checked.on_occurrence} onChange={(e) => {
                                                      e.target.checked ? setChecked({
                                                        ...checked,
                                                        on_occurrence: true
                                                      }) : setChecked({
                                                        ...checked,
                                                        on_occurrence: false
                                                      })

                                                      setFilterValues({
                                                        ...filterValues,
                                                        on_occurrence: e.target.checked
                                                      })
                                                    }} /> &nbsp;<label className="fw-normal user-select-none" htmlFor="On-Occurrence"><i style={{ color: "yellow" }} className="bi bi-square-fill"></i> On Occurrence</label>
                                                  </Form.Item>
                                                  <Form.Item className="mb-1" name={' One Time'}>
                                                    &nbsp;<Checkbox id="One-Time" value={'One Time'} checked={checked.one_time} onChange={(e) => {
                                                      e.target.checked ? setChecked({
                                                        ...checked,
                                                        one_time: true
                                                      }) : setChecked({
                                                        ...checked,
                                                        one_time: false
                                                      })

                                                      setFilterValues({
                                                        ...filterValues,
                                                        one_time: e.target.checked
                                                      })
                                                    }} /> &nbsp;<label className="fw-normal user-select-none" htmlFor="One-Time"><i style={{ color: "red" }} className="bi bi-square-fill"></i> One Time</label>
                                                  </Form.Item>
                                                  <Form.Item className="submitButtonFrm" >
                                                    <Button style={{ borderRadius: '10px' }} className="statistic" type="primary" htmlType="submit">OK</Button>
                                                    <a className="link ms-2" onClick={() => { resetfilter() }}>Reset</a>
                                                  </Form.Item>
                                                </Form>
                                              )}
                                            </div>
                                          </div>
                                        </th>
                                        <th
                                          className="ant-table-cell"
                                          style={{ width: "20px" }}
                                        >
                                          <input
                                            type="checkbox"
                                            title="Click here to approve all"
                                            name="allchecked"
                                            className={"allChecked"}
                                            onClick={(e) => {
                                              let checked = e.target.checked;
                                              addAllSelectedDataValues(checked);
                                            }}
                                          />
                                        </th>
                                        <th
                                        className="ant-table-cell"
                                        style={{ width: "610px" }}
                                      >
                                        <div className="d-flex justify-content-between align-items-center">
                                          <p
                                            className="p-0 mb-0"
                                            style={{
                                              marginLeft: "36%",
                                              display: "inline",
                                              color: searchingValue['compliance_task'] ? 'red' : ''
                                            }}
                                          >
                                            Compliance Task
                                          </p>{" "}
                                          <Popconfirm
                                            afterVisibleChange={() => {
                                              setFocus(myRef1)
                                            }}
                                            placement="bottom"
                                            title={
                                              // <Input
                                              //   ref={myRef1}
                                              //   placeholder="Compliance Task"
                                              //   autoFocus={'on'}
                                              //   onKeyUp={(e) => {
                                              //     compliancetaskfilter(
                                              //       e.target.value,
                                              //       "Compliance_Task"
                                              //     );
                                              //   }}
                                              // />
                                              <div className='position-relative'>
                                                <Input
                                                  ref={myRef1}
                                                  placeholder="Compliance Task"
                                                  autoFocus={'on'}
                                                  onChange={(e) => {
                                                    setSearchedValue({
                                                      ...searchedValue,
                                                      complianceTasks: e.target.value
                                                    });
                                                    searchingValue['compliance_task'] = e.target.value

                                                  }}
                                                  value={searchedValue.complianceTasks || undefined}
                                                  onKeyUp={(e) => {
                                                    compliancetaskfilter(
                                                      e.target.value,
                                                      "Compliance_Task",
                                                    );
                                                  }}
                                                />
                                                <CloseCircleOutlined onClick={() => {
                                                  myRef1.current.value = "";
                                                  setSearchedValue({
                                                    ...searchedValue,
                                                    complianceTasks: ''
                                                  });
                                                  searchingValue['compliance_task'] = ''
                                                  compliancetaskfilter(
                                                    '',
                                                    "Compliance_Task",
                                                    'compliance_task'

                                                  );
                                                  // inputRef.current.input.value = "";
                                                  // console.log(inputRef.current.,'inputRef');
                                                }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                              </div>
                                            }
                                          >
                                            <SearchOutlined
                                              style={{ float: 'right', marginTop: '4px' }}
                                            />
                                          </Popconfirm>
                                        </div>
                                        {/* <Input placeholder='Compliance Task' onKeyUp={(e) => {
                                                                            compliancetaskfilter(e.target.value, 'Compliance_Task')
                                                                        }} /> */}
                                      </th>
                                      {/* <th className='ant-table-cell' style={{ width: '10%' }}>Assignee <Input placeholder='Assignee' onKeyUp={(e) => {
                                                                            compliancetaskfilter(e.target.value, 'Assignee')
                                                                        }} /></th> */}
                                      <th
                                        className="ant-table-cell"
                                        style={{ width: "90px" }}
                                      >
                                        <div className="d-flex justify-content-between align-items-center">
                                          <p
                                            className="mb-0"
                                            style={{
                                              marginLeft: "2px",
                                              display: "inline",
                                              color: searchingValue['compliance_status'] ? 'red' : ''

                                            }}
                                          >
                                            Compliance Status
                                          </p>
                                          <Popconfirm
                                            afterVisibleChange={() => {
                                              setFocus(myRef2)
                                            }}
                                            placement="bottom"
                                            title={
                                              // <Input
                                              // ref={myRef2}
                                              //   placeholder="Compliance Status"
                                              //   autoFocus={'on'}
                                              //   onKeyUp={(e) => {
                                              //     compliancetaskfilter(
                                              //       e.target.value,
                                              //       "Compliance_Status"
                                              //     );
                                              //   }}
                                              // />
                                              <div className='position-relative'>
                                                <Input
                                                  ref={myRef2}
                                                  placeholder="Compliance Status"
                                                  autoFocus={'on'}
                                                  onChange={(e) => {
                                                    setSearchedValue({
                                                      ...searchedValue,
                                                      complianceStatus: e.target.value
                                                    });
                                                    searchingValue['compliance_status'] = e.target.value
                                                  }}
                                                  value={searchedValue.complianceStatus || undefined}
                                                  onKeyUp={(e) => {
                                                    compliancetaskfilter(
                                                      e.target.value,
                                                      "Compliance_Status",
                                                    );
                                                  }}
                                                />
                                                <CloseCircleOutlined onClick={() => {
                                                  myRef2.current.value = "";
                                                  setSearchedValue({
                                                    ...searchedValue,
                                                    complianceStatus: ''
                                                  });
                                                  searchingValue['compliance_status'] = ''

                                                  compliancetaskfilter(
                                                    '',
                                                    "Compliance_Status",
                                                    'compliance_status'

                                                  );
                                                  // inputRef.current.input.value = "";
                                                  // console.log(inputRef.current.,'inputRef');
                                                }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                              </div>
                                            }
                                          >
                                            <SearchOutlined
                                              style={{ float: 'right', marginTop: '4px' }}
                                            />
                                          </Popconfirm>
                                        </div>
                                        {/* <Input placeholder='Compliance Status' onKeyUp={(e) => {
                                                                            compliancetaskfilter(e.target.value, 'Compliance_Status')
                                                                        }} /> */}
                                      </th>
                                      <th
                                        className="ant-table-cell"
                                        style={{ width: "80px" }}
                                      >
                                        <div className="d-flex justify-content-between align-items-center">
                                          <p className="mb-0"
                                            style={{
                                              marginLeft: "20%",
                                              display: "inline",
                                              color: searchingValue['due_date'] ? 'red' : ''
                                            }}
                                          >
                                            Due Date
                                          </p>{" "}
                                          <Popconfirm
                                            afterVisibleChange={() => {
                                              setFocus(myRef3)
                                            }}
                                            placement="bottom"
                                            title={
                                              // <Input
                                              // ref={myRef3}
                                              //   placeholder="Due Date"
                                              //   autoFocus={'on'}
                                              //   onKeyUp={(e) => {
                                              //     compliancetaskfilter(
                                              //       e.target.value,
                                              //       "Due_Date"
                                              //     );
                                              //   }}
                                              // />
                                              <div className='position-relative'>
                                                <Input
                                                  ref={myRef3}
                                                  placeholder="Due Date"
                                                  autoFocus={'on'}
                                                  onChange={(e) => {
                                                    setSearchedValue({
                                                      ...searchedValue,
                                                      dueDate: e.target.value
                                                    });
                                                    searchingValue['due_date'] = e.target.value
                                                  }}
                                                  value={searchedValue.dueDate || undefined}
                                                  onKeyUp={(e) => {
                                                    compliancetaskfilter(
                                                      e.target.value,
                                                      "Due_Date",
                                                    );
                                                  }}
                                                />
                                                <CloseCircleOutlined onClick={() => {
                                                  myRef3.current.value = "";
                                                  setSearchedValue({
                                                    ...searchedValue,
                                                    dueDate: ''
                                                  });
                                                  searchingValue['due_date'] = ''
                                                  compliancetaskfilter(
                                                    '',
                                                    "Due_Date",
                                                    'due_date'
                                                  );
                                                  // inputRef.current.input.value = "";
                                                  // console.log(inputRef.current.,'inputRef');
                                                }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                              </div>
                                            }
                                          >
                                            <SearchOutlined
                                              style={{ float: 'right', marginTop: '4px' }}
                                            />
                                          </Popconfirm>
                                        </div>

                                        {/* <Input placeholder='Due Date' onKeyUp={(e) => {
                                                                            compliancetaskfilter(e.target.value, 'Due_Date')
                                                                        }} /> */}
                                      </th>
                                      <th
                                        className="ant-table-cell"
                                        style={{ width: "90px" }}
                                      >
                                        <div className="d-flex justify-content-between align-items-center c_date">
                                          <p className="mb-0"
                                            style={{
                                              marginLeft: "12%",
                                              display: "inline",
                                              color: searchingValue['completion_date'] ? 'red' : ''
                                            }}
                                          >
                                            Completion Date
                                          </p>
                                          <Popconfirm
                                            afterVisibleChange={() => {
                                              setFocus(myRef4)
                                            }}
                                            placement="bottomRight"
                                            title={
                                              // <Input
                                              // ref={myRef4}
                                              //   placeholder="Completion Date"
                                              //   autoFocus={'on'}
                                              //   onKeyUp={(e) => {
                                              //     compliancetaskfilter(
                                              //       e.target.value,
                                              //       "Completion_Date"
                                              //     );
                                              //   }}
                                              // />
                                              <div className='position-relative'>
                                                <Input
                                                  ref={myRef4}
                                                  placeholder="Completion Date"
                                                  autoFocus={'on'}
                                                  onChange={(e) => {
                                                    setSearchedValue({
                                                      ...searchedValue,
                                                      completionDate: e.target.value
                                                    });
                                                    searchingValue['completion_date'] = e.target.value
                                                  }}
                                                  value={searchedValue.completionDate || undefined}
                                                  onKeyUp={(e) => {
                                                    compliancetaskfilter(
                                                      e.target.value,
                                                      "Completion_Date",
                                                    );
                                                  }}
                                                />
                                                <CloseCircleOutlined onClick={() => {
                                                  myRef4.current.value = "";
                                                  setSearchedValue({
                                                    ...searchedValue,
                                                    completionDate: ''
                                                  });
                                                  searchingValue['completion_date'] = ''
                                                  compliancetaskfilter(
                                                    '',
                                                    "Completion_Date",
                                                    'completion_date'

                                                  );
                                                  // inputRef.current.input.value = "";
                                                  // console.log(inputRef.current.,'inputRef');
                                                }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                              </div>
                                            }
                                          >
                                            <SearchOutlined
                                              style={{ float: 'right', marginTop: '4px' }}
                                            />
                                          </Popconfirm>
                                        </div>

                                        {/* <Input placeholder='Completion Date' onKeyUp={(e) => {
                                                                            compliancetaskfilter(e.target.value, 'Completion_Date')
                                                                        }} /> */}
                                      </th>
                                      </tr>
                                    </thead>
                                    <tbody className="ant-table-tbody">
                                      {filteredArrayState &&
                                        filteredArrayState.length > 0 ? (
                                        filteredArrayState.map((list, ii) => {
                                          return (
                                            <Fragment key={ii}>
                                              {tempFilterArray.includes(
                                                list[0].unit_name
                                              ) == true ? (
                                                <tr className="bg-lightdark">
                                                  <td colSpan={7}>
                                                    <div
                                                      className="ant-table-title"
                                                      style={{ padding: "0" }}
                                                    >
                                                      {list[0].unit_name}
                                                    </div>
                                                  </td>
                                                </tr>
                                              ) : (
                                                ""
                                              )}
                                              {temp && temp.length > 0 ? (
                                                temp.map((itemdata, i) => {
                                                  let data = _.find(
                                                    itemdata.child,
                                                    {
                                                      unit_name:
                                                        list[0].unit_name,
                                                    }
                                                  );
                                                  if (data != undefined) {
                                                    if (
                                                      data.assignee_name ==
                                                      itemdata.parentData
                                                        .assignee_name
                                                    ) {
                                                      return (
                                                        <Fragment key={i}>
                                                          <tr className="ant-table-row ant-table-row-level-0" >
                                                            <td colSpan={12} className="ant-table-cell">
                                                              <b>
                                                                {itemdata.parentData.assignee_name}
                                                              </b>
                                                            </td>
                                                          </tr>
                                                          {itemdata.child.map((items, l) => {
                                                            return (<tr className="ant-table-row ant-table-row-level-0"
                                                              key={l} >
                                                              <td className="ant-table-cell" style={{ cursor: "pointer" }}
                                                                onClick={() => {
                                                                  showValue();
                                                                  setValueModal({
                                                                    compliance_history_id: items.compliance_history_id,
                                                                    unit: items.unit_name,
                                                                    unitid: items.unit_id,
                                                                    compliance_task: items.compliance_name,
                                                                    compliance_frequency: items.compliance_frequency,
                                                                    document_issued_date: items.completion_date,
                                                                    document_reference_number: items.document_reference_number,
                                                                    next_due_date: items.next_due_date,
                                                                    address: items.address,
                                                                    compliance_description: items.compliance_description,
                                                                    uploaded_document: items.upload_docs,
                                                                    interim_details: items.interim_count,
                                                                    remarks: items.assignee_remarks,
                                                                    history_details: items.remarks_count
                                                                  });
                                                                }}>
                                                                <center>{counts + 1}
                                                                  <span hidden >{(counts = counts + 1)}</span>
                                                                  {items.compliance_frequency ==
                                                                    "Periodical" ? (
                                                                    <p>
                                                                      <i style={{
                                                                        color:
                                                                          "green",
                                                                      }}
                                                                        title={
                                                                          items.compliance_frequency
                                                                        }
                                                                        className="bi bi-square-fill"
                                                                      ></i>
                                                                    </p>
                                                                  ) : items.compliance_frequency ==
                                                                    "FlexiReview" ? (
                                                                    <p>
                                                                      <i style={{ color: "pink" }}
                                                                        title={
                                                                          items.compliance_frequency
                                                                        }
                                                                        className="bi bi-square-fill"
                                                                      ></i>
                                                                    </p>
                                                                  ) : items.compliance_frequency ==
                                                                    "Review" ? (
                                                                    <p>
                                                                      <i
                                                                        style={{
                                                                          color:
                                                                            "orange",
                                                                        }}
                                                                        title={
                                                                          items.compliance_frequency
                                                                        }
                                                                        className="bi bi-square-fill"
                                                                      ></i>
                                                                    </p>
                                                                  ) : items.compliance_frequency ==
                                                                    "On Occurrence" ? (
                                                                    <p>
                                                                      <i
                                                                        style={{
                                                                          color:
                                                                            "yellow",
                                                                        }}
                                                                        title={
                                                                          items.compliance_frequency
                                                                        }
                                                                        className="bi bi-square-fill"
                                                                      ></i>
                                                                    </p>
                                                                  ) : (
                                                                    <p>
                                                                      <i
                                                                        style={{
                                                                          color:
                                                                            "red",
                                                                        }}
                                                                        title={
                                                                          items.compliance_frequency
                                                                        }
                                                                        className="bi bi-square-fill"
                                                                      ></i>
                                                                    </p>
                                                                  )}
                                                                </center>
                                                              </td>
                                                              <td>
                                                                <Fragment>
                                                                  <center>
                                                                    <input type="checkbox" title="Click here to Task Recall" name={"checked"}
                                                                      onClick={(e) => {
                                                                        let checked = e.target.checked;
                                                                        addSelectedDataValues(
                                                                          checked,
                                                                          items.compliance_history_id,
                                                                          items.parentId
                                                                        );
                                                                      }}
                                                                    />
                                                                  </center>
                                                                </Fragment>
                                                              </td>
                                                              <td style={{ cursor: "pointer" }}
                                                                onClick={() => {
                                                                  showValue();
                                                                  setValueModal({
                                                                    compliance_history_id: items.compliance_history_id,
                                                                    unit: items.unit_name,
                                                                    unitid: items.unit_id,
                                                                    compliance_task: items.compliance_name,
                                                                    compliance_frequency: items.compliance_frequency,
                                                                    document_issued_date: items.completion_date,
                                                                    document_reference_number: items.document_reference_number,
                                                                    next_due_date: items.next_due_date,
                                                                    address: items.address,
                                                                    compliance_description: items.compliance_description,
                                                                    uploaded_document: items.upload_docs,
                                                                    interim_details: items.interim_count,
                                                                    remarks: items.assignee_remarks,
                                                                    history_details: items.remarks_count
                                                                  });
                                                                }}>
                                                                <>
                                                                  {/* <span style={{ cursor: "pointer" }}
                                                                      onClick={() => {
                                                                        showValue();
                                                                        setValueModal({
                                                                          compliance_history_id: items.compliance_history_id,
                                                                          unit: items.unit_name,
                                                                          unitid: items.unit_id,
                                                                          compliance_task: items.compliance_name,
                                                                          compliance_frequency: items.compliance_frequency,
                                                                          document_issued_date: items.completion_date,
                                                                          document_reference_number: items.document_reference_number,
                                                                          next_due_date: items.next_due_date,
                                                                          address: items.address,
                                                                          compliance_description: items.compliance_description,
                                                                          uploaded_document: items.upload_docs,
                                                                          interim_details: items.interim_count,
                                                                          remarks: items.assignee_remarks,
                                                                          history_details: items.remarks_count
                                                                        });
                                                                      }}> */}
                                                                  {/* {items.compliance_frequency == "Periodical" ? (
                                                                        <span style={{ color: "green" }}>{items.statu}</span>
                                                                      ) : items.compliance_frequency ==
                                                                        "FlexiReview" ? (
                                                                        <span style={{ color: "#F4BDD5" }}>{items.statu}</span>
                                                                      ) : items.compliance_frequency ==
                                                                        "Review" ? (
                                                                        <span style={{ color: "#F78453" }}>{items.statu}</span>
                                                                      ) : items.compliance_frequency ==
                                                                        "On Occurrence" ? (
                                                                        <span style={{ color: "#420075" }}>{items.statu}</span>
                                                                      ) : (
                                                                        <span style={{ color: "#F21625" }}>{items.statu}</span>
                                                                      )} */}
                                                                  {items.statu}
                                                                  {/* </span> */}
                                                                  <div className="approve-task-reacall-tooltip">
                                                                    <div className="mb-0">
                                                                      <Tooltip placement="topRight" overlayStyle={{ maxWidth: "420px", }}
                                                                        title={gettooltipfun(items)}>
                                                                        {" "}
                                                                        <ExclamationCircleTwoTone />
                                                                        {/* gettooltipfun(items)
                                                                          }> <ExclamationCircleTwoTone/> */}
                                                                      </Tooltip>
                                                                      &nbsp;
                                                                      {/* <Popover content={gettooltipfun(items)} title="">
                                                                        <Button style={{ border: 0 }}><i className="bi-info-circle-fill"></i></Button>
                                                                        </Popover> */}
                                                                      {/* {items.compliance_frequency == "Periodical" ? (
                                                                          <span style={{ color: "green" }}>{items.compliance_name}</span>
                                                                        ) : items.compliance_frequency ==
                                                                          "FlexiReview" ? (
                                                                          <span style={{ color: "#F4BDD5" }}>{items.compliance_name}</span>
                                                                        ) : items.compliance_frequency ==
                                                                          "Review" ? (
                                                                          <span style={{ color: "#F78453" }}>{items.compliance_name}</span>
                                                                        ) : items.compliance_frequency ==
                                                                          "On Occurrence" ? (
                                                                          <span style={{ color: "#420075" }}>{items.compliance_name}</span>
                                                                        ) : (
                                                                          <span style={{ color: "#F21625" }}>{items.compliance_name}</span>
                                                                        )} */}
                                                                      {
                                                                        items.compliance_name
                                                                      }<br />Assigned on : {items.assigned_on}

                                                                    </div>
                                                                  </div>

                                                                </>
                                                              </td>
                                                              {/* <td>
                                                                                                                {items.assignee_name}
                                                                                                            </td> */}
                                                              <td style={{ cursor: "pointer" }}
                                                                onClick={() => {
                                                                  showValue();
                                                                  setValueModal({
                                                                    compliance_history_id: items.compliance_history_id,
                                                                    unit: items.unit_name,
                                                                    unitid: items.unit_id,
                                                                    compliance_task: items.compliance_name,
                                                                    compliance_frequency: items.compliance_frequency,
                                                                    document_issued_date: items.completion_date,
                                                                    document_reference_number: items.document_reference_number,
                                                                    next_due_date: items.next_due_date,
                                                                    address: items.address,
                                                                    compliance_description: items.compliance_description,
                                                                    uploaded_document: items.upload_docs,
                                                                    interim_details: items.interim_count,
                                                                    remarks: items.assignee_remarks,
                                                                    history_details: items.remarks_count
                                                                  });
                                                                }}> {items.task_status}
                                                              </td>
                                                              <td style={{ cursor: "pointer" }}
                                                                onClick={() => {
                                                                  showValue();
                                                                  setValueModal({
                                                                    compliance_history_id: items.compliance_history_id,
                                                                    unit: items.unit_name,
                                                                    unitid: items.unit_id,
                                                                    compliance_task: items.compliance_name,
                                                                    compliance_frequency: items.compliance_frequency,
                                                                    document_issued_date: items.completion_date,
                                                                    document_reference_number: items.document_reference_number,
                                                                    next_due_date: items.next_due_date,
                                                                    address: items.address,
                                                                    compliance_description: items.compliance_description,
                                                                    uploaded_document: items.upload_docs,
                                                                    interim_details: items.interim_count,
                                                                    remarks: items.assignee_remarks,
                                                                    history_details: items.remarks_count
                                                                  });
                                                                }}>
                                                                <center>
                                                                  {moment(items.due_date).format("DD-MMM-YYYY")}
                                                                </center>

                                                              </td>
                                                              <td style={{ cursor: "pointer" }}
                                                                onClick={() => {
                                                                  showValue();
                                                                  setValueModal({
                                                                    compliance_history_id: items.compliance_history_id,
                                                                    unit: items.unit_name,
                                                                    unitid: items.unit_id,
                                                                    compliance_task: items.compliance_name,
                                                                    compliance_frequency: items.compliance_frequency,
                                                                    document_issued_date: items.completion_date,
                                                                    document_reference_number: items.document_reference_number,
                                                                    next_due_date: items.next_due_date,
                                                                    address: items.address,
                                                                    compliance_description: items.compliance_description,
                                                                    uploaded_document: items.upload_docs,
                                                                    interim_details: items.interim_count,
                                                                    remarks: items.assignee_remarks,
                                                                    history_details: items.remarks_count
                                                                  });
                                                                }}>
                                                                <center>{moment(items.completion_date).format("DD-MMM-YYYY")}</center>
                                                              </td>
                                                            </tr>
                                                            );
                                                          }
                                                          )}
                                                        </Fragment>
                                                      );
                                                    }
                                                  }
                                                })
                                              ) : ii == 0 ? (
                                                <tr>
                                                  <td
                                                    colSpan={7}
                                                    style={{
                                                      textAlign: "center",
                                                      fontSize: "14px",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    No Compliance Available
                                                  </td>
                                                </tr>
                                              ) : (
                                                false
                                              )}
                                            </Fragment>
                                          );
                                        })
                                      ) : (
                                        <tr>
                                          <td
                                            colSpan={7}
                                            style={{
                                              textAlign: "center",
                                              fontSize: "14px",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            No Compliance Available
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Fragment>
                        {/* )
                                            }) : <p style={{ textAlign: 'center' }} >No Compliance Available</p>
                                            } */}
                      </Panel>
                    </Collapse>
                    {temp && temp.length > 0 ? (
                      <>
                        {/* <div>
                                                <div className="col-md-12" style={{ marginTop: '10px' }}>
                                                    <div className="row">
                                                        <label htmlFor="">Total Compliance : {list.approverecallcompliance.length}</label>

                                                    </div>
                                                </div>
                                            </div> */}
                        <div className="card p-3 col-md-12 mt-3">
                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group">
                                <label htmlFor="">
                                  Action:{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <Select
                                  name="action"
                                  className="select2 form-control custom-select bg-white"
                                  id="action"
                                  onChange={(e) => {
                                    Setrecalldata({
                                      ...recalldata,
                                      action: e,
                                    });
                                  }}
                                  defaultValue={"Please Select"}
                                >
                                  <Option value=""> Please Select </Option>
                                  <Option value="1">
                                    {" "}
                                    Rectify & Retain Document{" "}
                                  </Option>
                                  <Option value="2">
                                    {" "}
                                    Rectify & Remove Document{" "}
                                  </Option>
                                  <Option value="3"> Cancel Recall </Option>
                                </Select>
                                {recallSubmit.current.message(
                                  "action",
                                  recalldata.action,
                                  ["required"],
                                  {
                                    className: `invalid-feedback ${FormRecall ? "show" : "hide"
                                      }`,
                                    messages: {
                                      required:
                                        "Please select Action to Recall",
                                    },
                                  }
                                )}
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label htmlFor="">
                                  Remarks:{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <div class="d-flex align-items-end">
                                  <TextArea
                                    name="remarks"
                                    className="form-control"
                                    placeholder="Enter Remarks"
                                    onKeyPress={(event) => {
                                      if (! /^[A-Z0-9a-z _ .,-]+$/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                    value={
                                      recalldata.remarks
                                        ? recalldata.remarks || undefined
                                        : ""
                                    }
                                    id="remarks"
                                    cols="5"
                                    rows="1"
                                    onChange={(e) => {
                                      Setrecalldata({
                                        ...recalldata,
                                        remarks: e.target.value,
                                      });
                                    }}
                                    maxLength={500}
                                  ></TextArea>
                                  <span
                                    className="ms-1 text-info  d-flex align-items-center justify-content-center"
                                    onClick={() => {
                                      showRemarksModal();
                                    }}
                                  >
                                    <PlusCircleOutlined style={{ cursor: 'pointer' }} />
                                  </span>
                                </div>
                                {recallSubmit.current.message(
                                  "remarks",
                                  recalldata.remarks,
                                  ["required", { regex: /^[A-Z0-9a-z _ .,-]+$/ }],
                                  {
                                    className: `invalid-feedback ${FormRecall ? "show" : "hide"
                                      }`,
                                    messages: {
                                      required: "Remarks Required",
                                      regex: "Enter Valid Remarks"
                                    },
                                  }
                                )}
                              </div>
                            </div>
                            {/* <Modal title="Remarks List" visible={remarksModal} onOk={handleOk} onCancel={handleCancel}>
                                                    <DataTable columns={Remarkscolumn}
                                                        data={recallremarks}
                                                        // defaultSortFieldId={1}
                                                        pagination />
                                                </Modal> */}
                            <Modal
                              title="Remarks List"
                              className={
                                "add-service-prv " +
                                localStorage.getItem("currentTheme")
                              }
                              open={remarksModal}
                              footer={null}
                              onOk={handleOk}
                              onCancel={handleCancel}
                              maskClosable={false}
                            >
                              <Table
                                className="userprivclass"
                                columns={Remarkscolumn}
                                dataSource={
                                  remarksvalue &&
                                  remarksvalue.length > 0 &&
                                  remarksvalue
                                }
                                bordered
                                pagination={false}
                              />
                            </Modal>
                            <div className="col-md-3">
                              <div className="form-group">
                                {/* <Link to="" style={{ marginLeft: '40%' }} onClick={()=>{setAccordionTrue(true)}} className="btn btn-light-primary text-primary btn-lg px-4 fs-4">Show</Link> */}
                                {/* <a href="javascript:;" onClick={Active} style={{ marginLeft: '10%' }} className="btn btn-light-primary text-primary btn-lg px-4 fs-4">Approve</a> */}
                                <Button
                                  type="primary"
                                  shape="round"
                                  className="addbutton"
                                  style={{
                                    background: "#0096FF",
                                    borderColor: "#0096FF",
                                    // marginLeft: "10%",
                                    marginTop: "8%",
                                    // float: "right",
                                  }}
                                  size="default"
                                  onClick={Active}
                                  icon={<UserAddOutlined />}
                                >
                                  Approve
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}

                {/* <Modal title="Domain" visible={isComplainceModalVisible} onOk={handleOk} onCancel={handleCancel}>
                                <p>The Contract labour(Regulation Act 1997)The tamilNadu Contact Labour(Regulations)Rule 1975</p>
                            </Modal> */}
                <Modal
                  className={localStorage.getItem("currentTheme") + ' add-service-prv'}
                  title=""
                  open={open}
                  cancelText={false}
                  //onCancel={onClose}
                  closable={false}
                  footer={null}
                  maskClosable={false}
                >
                  {/* <Drawer title="Domain" visible={showValueModal} footer={false} onCancel={handleCancel} width={40} > */}

                  {/* <Card style={{padding:0}}> */}

                  <div className="col-md-12 d-flex justify-content-between" >
                    <div className="d-flex">
                      <div>
                        <button
                          type="button"
                          class="btn btn-light-primary text-primary btn-circle btn-xl me-3 mb-1 pe-none"
                        >
                          <i
                            class="fas fa-list-alt"
                          ></i>
                        </button>

                      </div>
                      <div>
                        <p className='mb-1'>Vendor :</p>
                        <h4 className='fw-bolder'> {valueModal.unit}&nbsp;<Tooltip title={valueModal.address} >
                          <ExclamationCircleTwoTone />
                        </Tooltip></h4>
                      </div>
                    </div>

                    <div className="text-right">
                      <CloseCircleOutlined onClick={onClose} style={{ fontSize: '20px' }} />
                    </div>
                  </div>
                  <div className="container py-2">
                    <div className="row">
                      <div className="col-4 border rounded-start p-2 bg-light mb-2">
                        <p className="mb-1">Compliance Task :</p>
                        <h5>{valueModal.compliance_task}&nbsp;<Tooltip placement='bottom' title={valueModal.compliance_description} >
                          <ExclamationCircleTwoTone />
                        </Tooltip></h5>
                      </div>

                      <div className="col-4 border p-2 bg-light mb-2">
                        <p className="mb-1">Compliance Frequency :</p>
                        <h5>{valueModal.compliance_frequency}</h5>
                      </div>
                      <div className="col-4 border rounded-end p-2 bg-light mb-2">
                        <p className="mb-1">Uploaded File Size :</p>
                        <h5>   {valueModal.document_size}</h5>
                      </div>
                      <div className="col-12 border rounded-start p-2 bg-light mb-2">
                        <p className="mb-1"style={{ wordBreak: 'break-all' }}>Attached Documents :</p>
                        {valueModal.uploaded_document}
                      </div>
                      <div className="col-md-6 border rounded-end p-2 bg-light mb-2">
                        <p className="mb-1">Task Completion Date/Document Issued Date : <span style={{ "color": "red" }}> *</span></p>
                        <h5>{valueModal.document_issued_date}</h5>
                      </div>
                      <div className="col-md-6 d-flex align-items-center border rounded-start p-2 bg-light mb-2" >
                        <p className="mb-1">Document Reference Number :</p>
                        <h5>{valueModal.document_reference_number}</h5>
                      </div>
                      <div className="col-4 border rounded-start p-2 bg-light mb-2">
                        <p className="mb-1">Remarks History :</p>
                        {valueModal.history_details != 0 ?
                          <span className="btn btn-sm btn-light-success
                        text-success btn-circle d-flex align-items-center justify-content-center" style={{ marginLeft: '100px' }}>
                            <Tooltip title='click here to View Remarks History' ><InfoOutlined style={{ fontSize: '25px' }} onClick={() => { viewhistory(valueModal) }} /></Tooltip></span> : ''}
                      </div>
                      <div className="col-4 border p-2 bg-light mb-2">
                        <p className="mb-1">Interim Details : </p>
                        <h5>  {valueModal.interim_details != 0 ?
                          <span className="btn btn-sm btn-light-danger
                        text-danger btn-circle d-flex align-items-center justify-content-center" style={{ marginLeft: '100px' }}>
                            <Tooltip title='click here to view Interim Details' >
                              <ArrowUpOutlined style={{ fontSize: '25px' }} onClick={() => { viewinterim(valueModal) }} /></Tooltip></span> : ''}</h5>
                      </div>
                      <div className="col-4 border rounded-end p-2 bg-light mb-2">
                        <p className="mb-1">Recall Remarks :</p>
                        <h5>{valueModal.remarks}</h5>
                      </div>
                    </div>
                  </div>
                  <div className="row">

                  </div>
                </Modal>


                <Modal
                  autoFocus={false}
                  open={passwordmodal}
                  footer={null}
                  onCancel={passwordcancel}
                  className={"newStyleModalPassword "}
                  maskClosable={false}
                >
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-6">
                        <label className="control-label">Password: </label>
                        <span style={{ color: "red" }}>*</span>
                        <input
                          ref={serviceProviderRef1}
                          autoFocus={true}
                          style={{ marginTop: "10px" }}
                          type="password"
                          id="password"
                          name="passwordvalue"
                          className="form-control"
                          placeholder="Enter password"
                          onChange={(e) => {
                            Setpassword({
                              ...password,
                              passwordvalue: e.target.value,
                            });
                          }}
                        // value={password.passwordvalue}
                        />
                        {formValidator2.current.message(
                          "Password",
                          password.passwordvalue,
                          ["required"],
                          {
                            className: `invalid-feedback ${AddFormSubmit2 ? "show" : "hide"
                              }`,
                            messages: {
                              regex: "Password Required",
                            },
                          }
                        )}
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className={"form-actions text-center " + localStorage.getItem('currentTheme')} >
                    <Button
                      type="primary"
                      shape="round"
                      className="addbutton"
                      style={{ background: "#198754", borderColor: "#198754" }}
                      icon={<PlayCircleOutlined />}
                      size="default"
                      onClick={submitpassword}
                    >
                      Submit
                    </Button>

                  </div>
                </Modal>
                <Modal open={historymodal}
                  title="Remarks History"
                  className={" add-service-prv " + localStorage.getItem('currentTheme')}
                  footer={null}
                  onCancel={historycancel} maskClosable={false}>
                  {console.log(historydata, 'historydata')}
                  <Table className={`stats ${localStorage.getItem("currentTheme")}`}
                    columns={history_columns}
                    dataSource={historydata && historydata.remarks_history}
                    pagination={false}
                  />
                </Modal>

                <Modal open={interimmodal}
                  title="Interim Details"
                  className={" add-service-prv " + localStorage.getItem('currentTheme')}
                  footer={null}
                  onCancel={interimcancel} maskClosable={false}>
                  <Table className={`stats ${localStorage.getItem("currentTheme")}`}
                    columns={interim_columns}
                    dataSource={interimdata && interimdata}
                    pagination={false}
                  />
                </Modal>

              </div>
            </div>
          </div>
        </div>
        {acccordionTrue === true &&
          filteredArrayState &&
          filteredArrayState.length > 0 ? (
          <Stickicon />
        ) : (
          false
        )}
      </div>
    </>
  );
};

export default connect(mapStateToProps, {
  ApproveTaskRecallUnitList,
  getApproveTaskRecallList,
  GetRemarksData,
  ApproveTaskRecallSubmit,
  historyrecord, interimrecord
})(ApproveTaskRecall);
