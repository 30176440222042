import React from 'react'
import { Collapse, Select, Input, Card, Drawer, Button, Table, Modal, Pagination, Tooltip } from 'antd';
import { useState, useEffect, useRef, Fragment } from 'react';
import { useLocation, Link, useHistory } from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";
import { connect, useDispatch, useSelector } from 'react-redux';
import { UnitListFilters, UnitListshow, UnitListexport, } from '../../../Store/Action/Report/CV_VendorList';
import { toast } from 'react-toastify';
import { PlayCircleOutlined, FilterOutlined, FullscreenOutlined, EyeOutlined, ExportOutlined, UpOutlined } from '@ant-design/icons';
import ScrollToTop from "react-scroll-to-top";
import { fullscreenmode, fullscreenexit, handleKeyDown } from './../../../Libs/fullscreenmode';
import { validationType, validateSelect, onPasteToaster } from './../../../Libs/inputValidation';
import useStickyHeight from "../../../Libs/useStickyHeight";
import ScrollButton from '../../Scrollbar';
import { Toaster } from '../../../Libs/Toaster';
import { CountrySplit, HomeRedirect } from './../../../Libs/country'
const homepage = HomeRedirect()
const uniq_country_data = CountrySplit()

const mapStateToProps = (state) => ({
    filerunitdatas: state.UnitlistReport.unitlistreports.unitlist,
    tabledatas: state.UnitlistReport.unitlistreports.tabledata
})

const VendorList = ({
    UnitListFilters,
    UnitListshow,
    UnitListexport,
    filerunitdatas,
    tabledatas
}) => {

    console.log(tabledatas, 'tabledatas');
    useStickyHeight();
    const historynew = useHistory();
    const { Panel } = Collapse;
    const { Option } = Select;
    const location = useLocation();
    const is_Fullscreen = useSelector((state) => state.Login.is_fullscreen);
    const dispatch = useDispatch();
    const paramid = location.pathname;
    const _ = require("lodash");
    const sessionValue = localStorage.getItem('sessionValue')
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const sessionParsedValue = JSON.parse(sessionValue)
    const sessionArr = sessionParsedValue.entity_info
    console.log(sessionArr, 'sessionArr')
    const businessArr = _.uniqBy(sessionParsedValue.entity_info, function (e) {
        return e.bg_id
    })
    console.log(businessArr, 'businessArr')
    const country_info = sessionParsedValue.country_info
    const validator = useRef(new SimpleReactValidator());
    const [open, setOpen] = useState(false);
    const [entity, setEntity] = useState([])
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [tableshow, setTableShow] = useState(false)
    const [key, setKey] = useState("1")
    const [countrydata, setCountryData] = useState(null)
    const [legalstate, Setlegalstate] = useState(false)
    const [filerdatas, setfilerdatas] = useState([])
    const [checkboxValue, setCheckBoxValue] = useState([1, 2, 3, 4, 5, 6, 11])
    const [tabledata, setTableData] = useState([])
    console.log(tabledata, 'tabledata');
    const [finaldata, Setfinaldata] = useState([])
    console.log(finaldata, 'finaldata');
    const [titledata, Settitledata] = useState()
    console.log(titledata, 'titledata');
    const [productfilter, setProductfilter] = useState([])
    const [pagecount, setPagecount] = useState(false)
    const [show, Setshow] = useState(0)
    let [count, setCount] = useState(0)
    const [fcount, setfcount] = useState(0)
    const [tablecount, setTablecount] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [filteredArray, setFilteredArray] = useState([])
    const [resultArray, setResultArray] = useState([])
    const [filteredArrayState, setfilteredArrayState] = useState([])
    const [collapseFilter, setCollapseFilter] = useState(false)
    const [filterModalVisible, setFilterModalVisible] = useState(false);

    const [current, setCurrent] = useState(1);
    const [pageState, setpageState] = useState(false);
    const [pageSize, setPageSize] = useState(25)
    const [exitCollapse, setExitCollapse] = useState(false);
    const [filterTaskAcc, setFilterTaskAcc] = useState(true);

    const [finallegalEntity, setFinallegalentity] = useState();
    const [selectSearchValue, setSelectSearchValue] = useState({
        country: '',
        legalEntity: '',
        BusinessGroup: '',
        domain: '',
        actRules: '',
        complianceTask: '',
        user: '',
        division: '',
        category: '',
        vendor: '',
        orgType: ''
    });

    const [unitlist, setUnitlist] = useState({
        country: "",
        country_id: '',
        legal_entity_id: "",
        legal_entity_name: localStorage.getItem('SelectedEntity') === "All Legal Entity" ? '' : localStorage.getItem('SelectedEntity'),
        domain: "",
        division: "",
        category: "",
        unit: "",
        business_group_id: '',
        domain_id: null,
        division_id: null,
        category_id: null,
        unit_id: null,
        domain_org_id: '',
        domain_org_name: '',
        product_status: '',
        product_status_id: '',
        unit_status_id: '',
        unit_status: '',
        bg_id: '',
        bg_name: '',
        legalEntityLabel: localStorage.getItem('SelectedEntity') === "All Legal Entity" ? '' : localStorage.getItem('SelectedEntity'),
        domainLabel: '',
        countryLabel: ''
    })

    console.log(unitlist, 'unitlistunitlist');

    const onChange = (key) => {
        console.log(key);
    };
    const onClose = () => {
        setOpen(false);
    };

    // useEffect(() => {
    //     if (tabledatas) {
    //         for (let i in tabledatas.unit_list_report) {
    //             for (var prop in tabledatas.unit_list_report[i]) {
    //                 if (tabledatas.unit_list_report[i].hasOwnProperty(prop))
    //                     return false;
    //                     console.log('falseeeee');
    //             }
    //         }
    //         console.log('trueeee');
    //         return true;
    //     }
    // },[tabledatas])


    useEffect(() => {
        if (pageState === true) {
            setCount(pageSize * (current - 1))
        }
    }, [pageState, pageSize, current])

    useEffect(() => {
        if (tabledatas) {
            const results = tabledatas && tabledatas.unit_list_report && tabledatas.unit_list_report.length > 0 && tabledatas.unit_list_report.filter(element => {
                if (Object.keys(element).length !== 0) {
                    return true;
                }
                return false;
            });
            setResultArray(results)
        }
    }, [tabledatas])

    useEffect(() => {
        if (finaldata && finaldata && finaldata.length > 0) {
            let uniqueObjArray = [...new Map(finaldata && finaldata.length && finaldata.map((item) => [item["category_name"], item])).values()];

            const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                return item.category_name
            })

            let tempArr = []
            for (let i in uniqueIds) {
                let filteredArr = _.filter(finaldata && finaldata && finaldata.length && finaldata, { category_name: uniqueIds[i] })

                tempArr.push(filteredArr)
            }
            setFilteredArray([...filteredArray, tempArr])

        }

    }, [finaldata])
    useEffect(() => {
        if (unitlist.legal_entity_name == localStorage.getItem('SelectedEntity')) {
            Setlegalstate(true)
        }
    }, [unitlist.legal_entity_name])

    // useEffect(() => {
    //     if (filerunitdatas) {
    //         if (filerunitdatas && filerunitdatas.domains_organisations_list && filerunitdatas.domains_organisations_list.length > 0 &&
    //             filerunitdatas.domains_organisations_list.length == 1) {
    //             setUnitlist({
    //                 ...unitlist,
    //                 domain: filerunitdatas.domains_organisations_list[0].domain_name,
    //                 domain_id: filerunitdatas.domains_organisations_list[0].domain_id
    //             })
    //         }
    //     }
    // }, [filerunitdatas])

    useEffect(() => {
        if (filteredArray && filteredArray.length > 0) {
            filteredArray && filteredArray.map((item, i) => {
                return (
                    setfilteredArrayState(item)
                )
            })
        }
    }, [filteredArray])
    useEffect(() => {

        let array = [];
        let finalarray = [];

        let datavalue = [];
        if (finaldata && finaldata.length > 0) {
            const ab = finaldata && finaldata.length && finaldata.map(function (item) {
                if ((array.indexOf(item.category_name) < 0))
                    if (item.category_name != '') {
                        array.push(item.category_name)
                    } else {
                        if (array.indexOf('-') < 0)
                            array.push('-')
                    }
            })

            array.map((data, i) => {
                let temp = [];
                let entityArray = [];
                let datavalue = [];
                if (data) {

                    finaldata && finaldata.length && finaldata.map((child, childIndex) => {

                        if (data == child.category_name) {
                            entityArray = {
                                "address": child.address,
                                "category_name": child.category_name,
                                "closed_date": child.closed_date,
                                "d_i_names": child.d_i_names,
                                "division_name": child.division_name,
                                "geography_name": child.geography_name,
                                "logo_url": child.logo_url,
                                "postal_code": child.postal_code,
                                "product_status": child.product_status,
                                "tz_name": child.tz_name,
                                "unit_code": child.unit_code,
                                "unit_id": child.unit_id,
                                "unit_name": child.unit_name,
                                "unit_status": child.unit_status,
                                'childId': childIndex,
                                'parentId': i
                            }
                            temp.push(entityArray)
                        } else if (data == '-') {
                            if (child.category_name == '') {
                                entityArray = {
                                    "address": child.address,
                                    "category_name": child.category_name,
                                    "closed_date": child.closed_date,
                                    "d_i_names": child.d_i_names.join(', '),
                                    "division_name": child.division_name,
                                    "geography_name": child.geography_name,
                                    "logo_url": child.logo_url,
                                    "postal_code": child.postal_code,
                                    "product_status": child.product_status,
                                    "tz_name": child.tz_name,
                                    "unit_code": child.unit_code,
                                    "unit_id": child.unit_id,
                                    "unit_name": child.unit_name,
                                    "unit_status": child.unit_status,
                                    'childId': childIndex,
                                    'parentId': i
                                }
                                temp.push(entityArray)
                            }
                        }
                    })
                }

                datavalue = {
                    index: i,
                    parent: data,
                    child: temp
                }
                finalarray.push(datavalue)
            })
            Settitledata(finalarray)

        }

    }, [finaldata])

    useEffect(() => {
        validator.current.showMessages()
    }, [])
    useEffect(() => {

        if (tabledatas) {

            setTableData(tabledatas && tabledatas.unit_list_report)
        }
    }, [tabledatas && tabledatas.unit_list_report])

    useEffect(() => {
        if (filerunitdatas && unitlist.legal_entity_id) {
            let temp = []
            let temparr = []
            if (sessionParsedValue.login_response.cf_access == true) {
                temp.push(1)
            }
            if (sessionParsedValue.login_response.ltmg_access == true) {
                temp.push(2)
            }
            if (sessionParsedValue.login_response.vndr_access == true) {
                temp.push(3)
            }
            for (let i in temp) {
                let unitlistdata = _.find(filerunitdatas && filerunitdatas.product_status_list, { product_status_id: temp[i] })
                if (unitlistdata) {
                    temparr.push(unitlistdata)
                }
            }
            setProductfilter(temparr)
        } else {
            setProductfilter([])
        }

    }, [filerunitdatas, unitlist.legal_entity_id])


    useEffect(() => {
        if (entityid != null) {
            let temp = localStorage.getItem("SelectedEntity")
            let tempcountry = _.filter(sessionArr, { le_name: temp })
            setUnitlist({
                ...unitlist,
                country_id: tempcountry && tempcountry.length > 0 ? tempcountry[0].c_id : '',
                country: tempcountry && tempcountry.length > 0 ? tempcountry[0].c_name : ''
            })
        }
    }, [entityid != null])

    useEffect(() => {

        if (tabledata && tabledata.length > 0) {

            let data = tabledata[0]

            if (data) {

                let data1 = Object.values(data)
                let data2 = Object.keys(data)

                let temp = []
                data1 && data1.length > 0 && data1.map((item) => {

                    temp.push(...item)
                })


                Setfinaldata(temp)
            }

        }
    }, [tabledata])
    useEffect(() => {
        if (localStorage.getItem('SelectedEntityid') != null) {
            setCurrentEntity(localStorage.getItem('SelectedEntityid'))
            const country = sessionParsedValue.country_info
            setCountryData(country)
        }
    }, [localStorage.getItem('SelectedEntityid')])

    useEffect(() => {
        if (localStorage.getItem("SelectedEntity") !== "All Legal Entity") {
            let temp = localStorage.getItem("SelectedEntity")
            let tempcountry = _.filter(sessionArr, { le_name: temp })
            setUnitlist({
                ...unitlist,
                country_id: tempcountry && tempcountry.length > 0 ? tempcountry[0].c_id : '',
                country: tempcountry && tempcountry.length > 0 ? tempcountry[0].c_name : ''
            })
        }
    }, [localStorage.getItem("SelectedEntity") !== "All Legal Entity"])

    useEffect(() => {
        if (isAuth) {
            if (legalstate === true) {
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            'GetUnitListReportFilters', {
                                'country_id': Number(unitlist.country_id),
                                'business_group_id': unitlist.bg_id ? unitlist.bg_id : 0,
                                'legal_entity_id': entityid
                            }
                        ]
                    }
                ]
                if (entityid != "null") {
                    UnitListFilters({
                        payload: payload,
                        paramid: paramid
                    })
                }
                Setlegalstate(false)
            }
        }
    }, [isAuth, entityid, legalstate])
    const onshow = () => {
        setAddFormSubmit(true)

        if (validator.current.allValid()) {
            setPagecount(false)
            setCount(0)
            setCurrent(1);
            setpageState(false)
            setPageSize(25)
            Settitledata([])
            setfilteredArrayState([])
            setFilterModalVisible(false)
            setTableShow(true)
            setExitCollapse(true);
            setFilterTaskAcc(false);
            setKey(0)
            setUnitlist({
                ...unitlist,
                countryLabel: unitlist.country,
                domainLabel: unitlist.domain,
                legalEntityLabel: unitlist.legal_entity_name
            })
            const payload1 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetUnitListReport",
                        {
                            "country_id": Number(unitlist.country_id),
                            "business_group_id": unitlist.bg_id ? unitlist.bg_id : 0,
                            "legal_entity_id": entityid,
                            "division_id": unitlist.division_id ? Number(unitlist.division_id) : 0,
                            "category_id": unitlist.category_id ? Number(unitlist.category_id) : 0,
                            "unit_id": unitlist.unit_id ? Number(unitlist.unit_id) : 0,
                            "domain_id": unitlist.domain_id ? Number(unitlist.domain_id) : 0,
                            "organisation_id": unitlist.domain_org_id ? Number(unitlist.domain_org_id) : 0,
                            "product_status": unitlist.product_status ? unitlist.product_status : "All",
                            "unit_status": unitlist.unit_status ? unitlist.unit_status : "All",
                            "csv": false,
                            "from_count": 0,
                            "page_count": 25,
                            "list_check": null
                        }
                    ]
                }
            ]
            console.log(payload1, 'unitlist.domain_idlllllll');
            UnitListshow({
                payload: payload1,
                paramid: paramid
            })
            Setshow(fcount + 1)
        }
    }
    useEffect(() => {
        if (pageState == true) {
            const payload1 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetUnitListReport",
                        {
                            "country_id": Number(unitlist.country_id),
                            "business_group_id": unitlist.bg_id ? unitlist.bg_id : 0,
                            "legal_entity_id": entityid,
                            "division_id": unitlist.division_id ? Number(unitlist.division_id) : 0,
                            "category_id": unitlist.category_id ? Number(unitlist.category_id) : 0,
                            "unit_id": unitlist.unit_id ? Number(unitlist.unit_id) : 0,
                            "domain_id": unitlist.domain_id ? Number(unitlist.domain_id) : 0,
                            "organisation_id": unitlist.domain_org_id ? Number(unitlist.domain_org_id) : 0,
                            "product_status": unitlist.product_status ? unitlist.product_status : "All",
                            "unit_status": unitlist.unit_status ? Number(unitlist.unit_status) : "All",
                            "csv": false,
                            "from_count": Math.max(((current - 1) * pageSize), 0) + 1,
                            "page_count": current != 0 ? current * pageSize : pageSize,
                            "list_check": null
                        }
                    ]
                }
            ]
            UnitListshow({
                payload: payload1,
                paramid: paramid
            })
        }
    }, [current, pageSize, pageState])
    const showModal = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            setIsModalOpen(true);
        }
    };
    const handleOk = () => {
        const myArray = checkboxValue.sort((a,b) => a - b) 
        let status = myArray.includes(0);
        if (!status) {
            myArray.unshift(0);
        }

        setCheckBoxValue(myArray);
        setIsModalOpen(true);
        if (validator.current.allValid()) {
            const payload2 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetUnitListReport",
                        {
                            "country_id": Number(unitlist.country_id),
                            "business_group_id": unitlist.bg_id ? unitlist.bg_id : 0,
                            "legal_entity_id": entityid,
                            "division_id": unitlist.division_id ? Number(unitlist.division_id) : 0,
                            "category_id": unitlist.category_id ? Number(unitlist.category_id) : 0,
                            "unit_id": unitlist.unit_id ? Number(unitlist.unit_id) : 0,
                            "domain_id": unitlist.domain_id ? Number(unitlist.domain_id) : 0,
                            "organisation_id": unitlist.domain_org_id ? Number(unitlist.domain_org_id) : 0,
                            "product_status": unitlist.product_status ? unitlist.product_status : "All",
                            "unit_status": unitlist.unit_status ? Number(unitlist.unit_status) : "All",
                            "csv": true,
                            "from_count": 0,
                            "page_count": 25,
                            "list_check": checkboxValue

                        }
                    ]
                }
            ]
            UnitListexport({
                payload: payload2,
                paramid: paramid
            })
        }
        // setCheckBoxValue([1, 2, 3, 4, 5, 6, 11])
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const checkBoxOnChange = (e) => {
        let tempIds = []
        let temp = checkboxValue
        if (temp.includes(Number(e.target.value)) == true) {
            for (let i in temp) {
                if (temp[i] == Number(e.target.value)) {
                    temp.splice(i, 1)
                }
            }
            setCheckBoxValue([...temp])
        } else {
            tempIds.push(Number(e.target.value))
            setCheckBoxValue([...checkboxValue, ...tempIds])
        }
    }

    const selectAllCheckBox = (checked) => {
        var ele = document.getElementsByName('chk');
        let tempAllIds = []
        if (checked == true) {
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == 'checkbox') {
                    ele[i].checked = true;
                    tempAllIds.push(Number(ele[i].value))
                }
            }
            setCheckBoxValue(tempAllIds)
        }
        else {
            var ele = document.getElementsByName('chk');
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == 'checkbox')
                    ele[i].checked = false;

            }
            setCheckBoxValue([1, 2, 3, 4, 5, 6, 11])
        }
    }

    const [fullscreen, setfullscreen] = useState(false);

    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
            document.addEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch), false);
            return () => {
                document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch));
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode(dispatch);
            setfullscreen(true);
        } else {
            fullscreenexit(dispatch);
            setfullscreen(false);
        }
    }

    const homescreen = () => {
        historynew.push(homepage);
        // window.location.href = homepage
        document.getElementById('homehover').style.color = "blue"
    }

    const handlePasting = (e, regex) => {

        var regex = regex;
        var key = e.clipboardData.getData('text')
        if (!regex.test(key)) {
            e.preventDefault();
            // Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
            return false;
        }
        // e.clipboardData.getData('text')
        // Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
        // e.preventDefault();
        // return false;
    }

    const filterHandle = () => {
        setFilterTaskAcc(true);
        setFilterModalVisible(true);
    };


    const setcancelFilter = () => {
        setFilterModalVisible(false);
        setFilterTaskAcc(false);
    };

    return (
        <>
            <div id='page-wrapper' className="page-wrapper">
                {/* <ScrollToTop smooth color="red" component={<Tooltip title="Back to top"><UpOutlined /></Tooltip>} /> */}
                {document.body.scrollHeight > document.body.clientHeight ?
                    <div className='back-to-top'>
                        <ScrollButton />
                    </div> : ''}
                <div className="page-titles pb-0 pt-2 page-title-sticky">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12 align-self-center">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 d-flex align-items-center">
                                    <li className="breadcrumb-item">
                                        <span className='pointer' id='homehover' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        <span style={{ fontSize: '14px', fontWeight: "bold" }}>Report</span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: "15px", fontWeight: "bold" }}>
                                        <span>Vendor Reports</span>
                                    </li>

                                    <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                        <span>Vendor List</span>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-lg-6 text-end">
                            {/* <div style={{ display: "initial" }}>
                                <Button className="mx-1" shape="round" disabled>
                                    Total Vendors : {filteredArrayState?.length}
                                </Button>
                                <Button className="mx-1 fw-2" shape="round" disabled>
                                    Total Acts : {titledata ? titledata?.length : '0'}
                                </Button>
                            </div> */}
                            <Tooltip title='Advance Filter'></Tooltip> <Button
                                type="primary"
                                shape="round"
                                style={{
                                    display: filterTaskAcc ? "none" : "initial",
                                }}
                                onClick={filterHandle}
                            >
                                <FilterOutlined /> Filter
                            </Button>
                            <button onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black ms-2 full-mode fullscreenradius" >
                                <Tooltip placement="left" title={is_Fullscreen == true ? "Exit Full Screen Mode" : "Full Screen Mode"}><FullscreenOutlined /></Tooltip>
                            </button>
                        </div>
                    </div>
                </div>

                <Modal
                    onCancel={setcancelFilter}
                    footer={false}
                    title="Vendor List"
                    className={
                        "add-service-prv cons-report " +
                        localStorage.getItem("currentTheme")
                    }
                    open={filterModalVisible}
                    maskClosable={false}
                >
                    <div className='col-md-12' >
                        <div className='row'  >
                            <div className='col-md-4'>
                                <label><b>Country : </b> <span style={{ color: "red" }}>*</span></label>
                                <div className="form-group">
                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                        <div className="form-group">
                                            <Select
                                                getPopupContainer={trigger => trigger.parentNode}
                                                placeholder='Enter Country'
                                                allowClear={true}
                                                size="default"
                                                onDrop={(e) => {
                                                    e.preventDefault();
                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                    return false;
                                                }}
                                                // onPaste={(e) => {
                                                //     handlePasting(e, /^[a-z]+$/gi)
                                                // }}
                                                onPaste={onPasteToaster}
                                                searchValue={selectSearchValue.country}
                                                onSearch={(value) => {
                                                    let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                                    setSelectSearchValue({
                                                        ...selectSearchValue,
                                                        country: result
                                                    });
                                                }}
                                                onChange={(value, data) => {
                                                    if (value != undefined) {
                                                        let le = _.filter(sessionArr, { c_id: Number(value) })
                                                        setEntity(le)
                                                        setUnitlist({
                                                            ...unitlist,
                                                            country_id: value ? value : '',
                                                            country: data ? data.children : '',
                                                            legal_entity_id: '',
                                                            legal_entity_name: '',
                                                            domain: '',
                                                            division: "",
                                                            category: "",
                                                            unit: "",
                                                            business_group_id: '',
                                                            domain_id: null,
                                                            division_id: null,
                                                            category_id: null,
                                                            unit_id: null,
                                                            domain_org_id: '',
                                                            domain_org_name: '',
                                                            product_status: '',
                                                            product_status_id: '',
                                                            unit_status_id: '',
                                                            unit_status: '',
                                                        })
                                                    }
                                                    else {
                                                        setEntity([])
                                                        setUnitlist({
                                                            ...unitlist,
                                                            country_id: '',
                                                            country: '',
                                                            legal_entity_id: '',
                                                            legal_entity_name: '',
                                                            domain: '',
                                                            division: "",
                                                            category: "",
                                                            unit: "",
                                                            business_group_id: '',
                                                            domain_id: null,
                                                            division_id: null,
                                                            category_id: null,
                                                            unit_id: null,
                                                            domain_org_id: '',
                                                            domain_org_name: '',
                                                            product_status: '',
                                                            product_status_id: '',
                                                            unit_status_id: '',
                                                            unit_status: '',

                                                        })
                                                    }
                                                }}
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                style={{ width: '100%', marginTop: '5px' }}
                                                value={unitlist.country || undefined}
                                            >
                                                {uniq_country_data && uniq_country_data.length && uniq_country_data.map((item, i) => {
                                                    return (
                                                        <Option key={item.c_id}>
                                                            {item.c_name}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                            {validator.current.message(
                                                'country',
                                                unitlist.country,
                                                'required',
                                                {
                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: 'Country Required',
                                                    }
                                                })}
                                        </div> :
                                        <p>{unitlist.country}</p>
                                    }
                                </div>
                            </div>
                            <div className='col-md-4 text-wrap-report'>
                                <label><b>Legal Entity: </b><span style={{ color: "red" }}>*</span></label>
                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                    <div className="form-group">
                                        <Select
                                            getPopupContainer={trigger => trigger.parentNode}
                                            allowClear={true}
                                            size="default"
                                            placeholder="Enter Legal Entity"
                                            onDrop={(e) => {
                                                e.preventDefault();
                                                // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                return false;
                                            }}
                                            // onPaste={(e) => {
                                            //     handlePasting(e, /^[a-z 0-9 ()]+$/gi)
                                            // }}
                                            onPaste={onPasteToaster}
                                            searchValue={selectSearchValue.legalEntity}
                                            onSearch={(value) => {
                                                let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                setSelectSearchValue({
                                                    ...selectSearchValue,
                                                    legalEntity: result
                                                });
                                            }}
                                            onChange={(data, value) => {
                                                if (data != undefined) {
                                                    Setlegalstate(true)
                                                    setCurrentEntity(data)
                                                    setUnitlist({
                                                        ...unitlist,
                                                        legal_entity_id: data ? data : '',
                                                        legal_entity_name: value ? value.children : '',
                                                        domain: '',
                                                        division: "",
                                                        category: "",
                                                        unit: "",
                                                        business_group_id: '',
                                                        domain_id: null,
                                                        division_id: null,
                                                        category_id: null,
                                                        unit_id: null,
                                                        domain_org_id: '',
                                                        domain_org_name: '',
                                                        product_status: '',
                                                        product_status_id: '',
                                                        unit_status_id: '',
                                                        unit_status: '',
                                                    })
                                                }
                                                else {
                                                    Setlegalstate(false)
                                                    setCurrentEntity('')
                                                    setUnitlist({
                                                        ...unitlist,
                                                        legal_entity_id: '',
                                                        legal_entity_name: '',
                                                        domain: "",
                                                        division: "",
                                                        category: "",
                                                        unit: "",
                                                        business_group_id: '',
                                                        domain_id: null,
                                                        division_id: null,
                                                        category_id: null,
                                                        unit_id: null,
                                                        domain_org_id: '',
                                                        domain_org_name: '',
                                                        product_status: '',
                                                        product_status_id: '',
                                                        unit_status_id: '',
                                                        unit_status: '',
                                                    })
                                                }
                                            }}
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                            style={{ width: '100%', marginTop: '5px' }}

                                            value={unitlist.legal_entity_name || undefined}
                                            disabled={unitlist.country == '' ? true : false}>
                                            {finallegalEntity && finallegalEntity.length > 0 ? finallegalEntity.map((item, i) => {
                                                return (
                                                    <Option key={item.le_id}>
                                                        {item.le_name}
                                                    </Option>
                                                );
                                            }) : entity && entity.length && entity.map((item, i) => {

                                                return (
                                                    <Option key={item.le_id}>
                                                        {item.le_name}
                                                    </Option>
                                                );
                                            })}
                                        </Select>
                                        {validator.current.message(
                                            'legal_entity_id',
                                            unitlist.legal_entity_id,
                                            'required',
                                            {
                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                messages: {
                                                    required: 'Legal Entity Required',
                                                }
                                            })}
                                    </div> :
                                    <p>{localStorage.getItem('SelectedEntity')}</p>
                                }
                            </div>

                            <div className='col-md-4'>
                                <label><b>Domain:</b> </label>
                                {/* {filerunitdatas.domains_organisations_list && filerunitdatas.domains_organisations_list.length > 0 && filerunitdatas.domains_organisations_list.length == 1 ?
                                    <span className='p-3'>
                                        {filerunitdatas.domains_organisations_list && filerunitdatas.domains_organisations_list.length > 0 && filerunitdatas.domains_organisations_list[0].domain_name}
                                    </span> : */}
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    allowClear={true}
                                    size="default"
                                    placeholder="Enter Domain"
                                    value={unitlist.domain || undefined}
                                    disabled={unitlist.legal_entity_name == '' ? true : false}
                                    style={{ width: '100%', marginTop: '5px' }}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    // onPaste={(e) => {
                                    //     handlePasting(e, /^[a-z - .]+$/gi)
                                    // }}
                                    onDrop={(e) => {
                                        e.preventDefault();
                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                        return false;
                                    }}
                                    onPaste={onPasteToaster}
                                    searchValue={selectSearchValue.domain}
                                    onSearch={(value) => {
                                        let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                        setSelectSearchValue({
                                            ...selectSearchValue,
                                            domain: result
                                        });
                                    }}
                                    onChange={(data, value) => {
                                        if (data != undefined) {
                                            setUnitlist({
                                                ...unitlist,
                                                domain_id: data ? data : '',
                                                domain: value ? value.children : ''
                                            })
                                        }
                                        else {
                                            setUnitlist({
                                                ...unitlist,
                                                domain_id: '',
                                                domain: ''
                                            })
                                        }
                                    }}>

                                    {filerunitdatas.domains_organisations_list && filerunitdatas.domains_organisations_list.length > 0 &&
                                        filerunitdatas.domains_organisations_list.map((item) => {
                                            return (
                                                <Option key={item.domain_id}>{item.domain_name}</Option>

                                            )
                                        })}

                                </Select>
                                {/* } */}

                            </div>
                        </div>
                    </div>
                    <div className='col-md-12' style={{ marginTop: "5px" }}>
                        <div className='row'>
                            <div className='col-md-4'>
                                <label><b>Business Group: </b>  </label>
                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                    <div className="form-group">
                                        <Select
                                            allowClear={true}
                                            size="default"
                                            getPopupContainer={trigger => trigger.parentNode}

                                            placeholder="Enter Business Group"
                                            onDrop={(e) => {
                                                e.preventDefault();
                                                // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                return false;
                                            }}
                                            // onPaste={(e) => {
                                            //     handlePasting(e, /^[a-z 0-9]+$/gi)
                                            // }}
                                            onPaste={onPasteToaster}
                                            searchValue={selectSearchValue.BusinessGroup}
                                            onSearch={(value) => {
                                                let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                setSelectSearchValue({
                                                    ...selectSearchValue,
                                                    BusinessGroup: result
                                                });
                                            }}
                                            onChange={(data, value) => {
                                                if (data != undefined) {
                                                    let filteredEntity = _.filter(sessionArr, { bg_id: Number(data) })
                                                    setFinallegalentity(filteredEntity)
                                                    setUnitlist({
                                                        ...unitlist,
                                                        bg_id: data ? data : '',
                                                        bg_name: value ? value.children : ''
                                                    })
                                                }
                                                else {
                                                    setUnitlist({
                                                        ...unitlist,
                                                        bg_id: '',
                                                        bg_name: ''
                                                    })
                                                    setFinallegalentity([])
                                                }
                                            }}
                                            value={unitlist.bg_name ? unitlist.bg_name : undefined}
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                            //  value={userDetails.legal_entity_id}
                                            style={{ width: '100%', marginTop: '5px' }}
                                        >
                                            {businessArr && businessArr.length && businessArr.map((item, i) => {

                                                if (item.bg_name !== null) {
                                                    return (
                                                        <Option key={item.bg_id}>
                                                            {item.bg_name}
                                                        </Option>
                                                    );
                                                }
                                            })}
                                        </Select>

                                    </div> :
                                    <p> - </p>
                                }

                            </div>
                            <div className='col-md-4'>
                                <label><b>Category:</b></label>
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    onDrop={(e) => {
                                        e.preventDefault();
                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                        return false;
                                    }}
                                    // onPaste={(e) => {
                                    //     handlePasting(e, /^[a-z 0-9]+$/gi)
                                    // }}
                                    onPaste={onPasteToaster}
                                    searchValue={selectSearchValue.category}
                                    onSearch={(value) => {
                                        let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                        setSelectSearchValue({
                                            ...selectSearchValue,
                                            category: result
                                        });
                                    }}
                                    onChange={(data, value) => {
                                        if (data != undefined) {
                                            setUnitlist({
                                                ...unitlist,
                                                category_id: data ? data : '',
                                                category: value ? value.children : ''
                                            })
                                        }
                                        else {
                                            setUnitlist({
                                                ...unitlist,
                                                category_id: '',
                                                category: ''
                                            })
                                        }
                                    }}
                                    allowClear={true}
                                    placeholder="Enter Category"
                                    disabled={unitlist.legal_entity_name == '' ? true : false}
                                    style={{ width: '100%', marginTop: '5px' }}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}


                                >

                                    {filerunitdatas.categories && filerunitdatas.categories.length > 0 &&
                                        filerunitdatas.categories.map((item) => (

                                            <Option key={item.category_id}>{item.category_name}</Option>
                                        ))}                                                       </Select>
                            </div>
                            <div className='col-md-4'>
                                <label><b>Division:</b></label>
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    allowClear={true}
                                    size="default"
                                    placeholder="Enter Division"
                                    disabled={unitlist.legal_entity_name == '' ? true : false}
                                    onDrop={(e) => {
                                        e.preventDefault();
                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                        return false;
                                    }}
                                    // onPaste={(e) => {
                                    //     handlePasting(e, /^[a-z 0-9]+$/gi)
                                    // }}
                                    onPaste={onPasteToaster}
                                    searchValue={selectSearchValue.division}
                                    onSearch={(value) => {
                                        let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                        setSelectSearchValue({
                                            ...selectSearchValue,
                                            division: result
                                        });
                                    }}
                                    onChange={(data, value) => {
                                        if (data != undefined) {
                                            setUnitlist({
                                                ...unitlist,
                                                division_id: data ? data : '',
                                                division: value ? value.children : ''

                                            })
                                        }
                                        else {
                                            setUnitlist({
                                                ...unitlist,
                                                division_id: '',
                                                division: ''

                                            })
                                        }
                                    }}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                                    style={{ width: '100%', marginTop: '5px' }}
                                >

                                    {filerunitdatas.divisions && filerunitdatas.divisions.length > 0 &&
                                        filerunitdatas.divisions.map((item) => (
                                            <Option key={item.division_id}>{item.division_name}</Option>
                                        ))}

                                </Select>


                            </div>

                        </div>
                    </div>
                    <div className='col-md-12' style={{ marginTop: "5px" }}>
                        <div className='row'>
                            <div className='col-md-4'>
                                <label><b>Organization Type:</b></label>
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    onChange={(data, value) => {
                                        if (data != undefined) {

                                            setUnitlist({
                                                ...unitlist,
                                                domain_org_id: data ? data : '',
                                                domain_org_name: value ? value.children : ''
                                            })
                                        }
                                        else {
                                            setUnitlist({
                                                ...unitlist,
                                                domain_org_id: '',
                                                domain_org_name: ''
                                            })
                                        }
                                    }}
                                    onPaste={onPasteToaster}
                                    searchValue={selectSearchValue.orgType}
                                    onSearch={(value) => {
                                        let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                        setSelectSearchValue({
                                            ...selectSearchValue,
                                            orgType: result
                                        });
                                    }}
                                    allowClear={true}
                                    placeholder="Enter Organization"
                                    style={{ width: '100%', marginTop: '5px' }}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                                    value={unitlist.domain_org_name || undefined}

                                >
                                    {filerunitdatas && filerunitdatas.domains_organisations_list && filerunitdatas.domains_organisations_list.length > 0 &&
                                        filerunitdatas.domains_organisations_list.map((item) => {

                                            return <Option key={item.organisation_id}>{item.organisation_name}</Option>
                                        })}
                                </Select>
                            </div>
                            <div className='col-md-4'>
                                <label><b>Vendor:</b></label>
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    allowClear={true}
                                    size="default"
                                    placeholder="Enter Vendor"
                                    disabled={unitlist.legal_entity_name == '' ? true : false}
                                    onDrop={(e) => {
                                        e.preventDefault();
                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                        return false;
                                    }}
                                    // onPaste={(e) => {
                                    //     handlePasting(e, /^[a-z 0-9 -]+$/gi)
                                    // }}
                                    onPaste={onPasteToaster}
                                    searchValue={selectSearchValue.vendor}
                                    onSearch={(value) => {
                                        let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                        setSelectSearchValue({
                                            ...selectSearchValue,
                                            vendor: result
                                        });
                                    }}
                                    onChange={(data, value) => {
                                        if (data != undefined) {

                                            setUnitlist({
                                                ...unitlist,
                                                unit_id: data ? data : '',
                                                unit: value ? value.children : ''
                                            })
                                        }
                                        else {
                                            setUnitlist({
                                                ...unitlist,
                                                unit_id: '',
                                                unit: ''
                                            })
                                        }
                                    }}
                                    style={{ width: '100%', marginTop: '5px' }}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                                    value={unitlist.unit || undefined}
                                >

                                    {filerunitdatas && filerunitdatas.units_list && filerunitdatas.units_list.length > 0 &&
                                        filerunitdatas.units_list.map((item) => {

                                            return <Option key={item.unit_id}>{`${item.unit_code}-${item.unit_name}`}</Option>
                                        })}
                                </Select>
                            </div>
                            <div className='col-md-4'>
                                <label><b>Product:</b></label>
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    allowClear={true}
                                    size="default"
                                    placeholder="Enter Product"
                                    defaultValue="ALL"

                                    onChange={(data, value) => {
                                        if (data != undefined) {

                                            setUnitlist({
                                                ...unitlist,
                                                product_status_id: data ? data : '',
                                                product_status: value ? value.children : ''
                                            })
                                        }
                                        else {
                                            setUnitlist({
                                                ...unitlist,
                                                product_status_id: '',
                                                product_status: ''
                                            })
                                        }
                                    }}

                                    style={{ width: '100%', marginTop: '5px' }}



                                >
                                    <Option key={0}>ALL</Option>
                                    {productfilter && productfilter.length > 0 &&
                                        productfilter.map((item) => {

                                            return <Option key={item.product_status_id}>{item.product_status}</Option>
                                        })}

                                </Select>

                            </div>

                        </div>
                    </div>
                    <div className='col-md-12' style={{ marginTop: "5px" }}>
                        <div className='row'>
                            <div className='col-md-4'>

                                <label><b>Status:</b> </label>
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    allowClear={true}
                                    size="default"
                                    placeholder="Enter Status"
                                    defaultValue="ALL"
                                    onChange={(data, value) => {
                                        if (data != undefined) {
                                            setUnitlist({
                                                ...unitlist,
                                                unit_status_id: data ? data : '',
                                                unit_status: value ? value.children : ''
                                            })
                                        }
                                        else {
                                            setUnitlist({
                                                ...unitlist,
                                                unit_status_id: '',
                                                unit_status: ''
                                            })
                                        }
                                    }}

                                    style={{ width: '100%', marginTop: '5px' }}


                                >
                                    <Option key='-1'>ALL</Option>
                                    {filerunitdatas && filerunitdatas.unit_status_list && filerunitdatas.unit_status_list.length > 0 &&
                                        filerunitdatas.unit_status_list.map((item) => {

                                            return <Option key={item.unit_status_id}>{item.unit_status}</Option>
                                        })}

                                </Select>


                                <div className='col-md-4'></div>
                            </div>
                            {/* <div className='col-md-4 popupbtncolour' style={{ marginLeft: '5%', marginTop: 'auto' }}>
                                <Button type="primary" shape="round" className={
                                "addbutton " + localStorage.getItem("currentTheme")
                            } style={{ background: "#0096FF", borderColor: "#0096FF", marginRight: "10px", marginTop: '5px' }}
                                    icon={<EyeOutlined />} onClick={onshow}
                                >
                                    Show
                                </Button>
                                <Button type="primary" shape="round" className={
                                "addbutton " + localStorage.getItem("currentTheme")
                            } style={{ background: "#32a852", borderColor: "#32a852", marginTop: '5px' }}
                                    icon={<ExportOutlined />} onClick={showModal}
                                >
                                    Export
                                </Button>
                            </div> */}
                        </div>

                    </div>
                    <div className='col-md-12' style={{ marginTop: "5px" }}>
                        <div className='row'>

                            <div className='col-md-4 popupbtncolour' style={{ marginLeft: '32%' }}>

                                <Button type="primary" shape="round" className={
                                    "addbutton " + localStorage.getItem("currentTheme")
                                } style={{ background: "#0096FF", borderColor: "#0096FF", marginLeft: "23%", marginRight: "10px", marginTop: '5px' }}
                                    icon={<EyeOutlined />} onClick={onshow}
                                >
                                    Show
                                </Button>
                                <Button type="primary" shape="round" className={
                                    "addbutton " + localStorage.getItem("currentTheme")
                                } style={{ background: "#32a852", borderColor: "#32a852", marginTop: '5px' }}
                                    icon={<ExportOutlined />} onClick={showModal}
                                >
                                    Export
                                </Button>
                            </div>
                        </div>
                    </div>

                </Modal>
                {/* <Drawer title="Select Fields" visible={isModalOpen} footer={null} onOk={handleOk} onCancel={handleCancel} width={100} className={
                    "add-service-prv cons-report " +
                    localStorage.getItem("currentTheme")
                }>
                    <div className='d-flex flex-wrap'>
                       <input id="select_all" width="100%" type="checkbox"
                            autocomplete="off"
                            checked={checkboxValue.length === 17 ? true : false}
                            onClick={(e) => {
                                let checked = e.target.checked
                                selectAllCheckBox(checked)
                            }} /><label for='select_all'>  Select All </label>
                  
                        <input id="column_1" name='chk' type="checkbox" checked disabled="disabled" value='1' onChange={(e) => (e) => checkBoxOnChange(e)} /><label for='column_1'>  Business Group</label>
                        <input id="column_2" name='chk' type="checkbox" checked disabled="disabled" value='2' onChange={(e) => checkBoxOnChange(e)} /><label for='column_2'> Legal Entity </label>
                        <input id="column_3" name='chk' type="checkbox" value='3' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /><label for='column_3'>  Division</label>
                        <input id="column_4" name='chk' type="checkbox" value='4' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /><label for='column_4'>  Category </label>

                  
                        <input id="column_5" name='chk' type="checkbox" value='5' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /><label for='column_5'>  Vendor Code</label>
                        <input id="column_6" name='chk' type="checkbox" value='6' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /><label for='column_6'>  Vendor Name </label>
                        <input id="column_7" name='chk' type="checkbox" value='7' onChange={(e) => checkBoxOnChange(e)} /><label for='column_7'> Vendor Code 1</label>
                        <input id="column_8" name='chk' type="checkbox" value='8' onChange={(e) => checkBoxOnChange(e)} /><label for='column_8'>  Vendor Code 2</label>
                   
                        <input id="column_9" name='chk' type="checkbox" value='9' onChange={(e) => checkBoxOnChange(e)} /><label for='column_9'> Vendor Code 3</label>
                        <input id="column_10" name='chk' type="checkbox" value='10' onChange={(e) => checkBoxOnChange(e)} /><label for='column_10'> Vendor Code 4</label>
                        <input id="column_11" name='chk' type="checkbox" value='11' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /><label for='column_11'> Location</label>
                        <input id="column_12" name='chk' type="checkbox" value='12' onChange={(e) => checkBoxOnChange(e)} /><label for='column_12'>Domain</label>
                 
                        <input id="column_13" name='chk' type="checkbox" value='13' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_13'> Organization Type</label>
                        <input id="column_14" name='chk' type="checkbox" value='14' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_14'> Postal Code</label>
                        <input id="column_15" name='chk' type="checkbox" value='15' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_15'> Product</label>
                        <input id="column_16" name='chk' type="checkbox" value='16' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_16'> Status</label>
                  
                        <div className='col-lg-3'><input id="column_17" name='chk' type="checkbox" value='17' onChange={(e) => checkBoxOnChange(e)} /> Date</div>

                
                    </div>
                    <div className="form-actions text-center">
                        <div className='popupbtncolour'>
                            <Button type="primary" shape="round" className='addbutton'
                                style={{ background: "#198754", borderColor: "#198754" }}
                                icon={<PlayCircleOutlined />} size='default' onClick={handleOk}> Submit
                            </Button>
                        </div>
                    </div>

                </Drawer> */}
                <Drawer title="Select Fields" className={'add-service-prv export-checkbox ' + localStorage.getItem('currentTheme')} open={isModalOpen} footer={null} onCancel={handleCancel} onClose={handleCancel} width={600} maskClosable={false}>
                    <div className='d-flex flex-wrap'>
                        <input id="select_all" width="100%" type="checkbox"
                            autocomplete="off"
                            checked={checkboxValue.length === 17 ? true : false}
                            onClick={(e) => {
                                let checked = e.target.checked
                                selectAllCheckBox(checked)
                            }} /><label for='select_all'>  Select All </label>

                        <input id="column_1" name='chk' type="checkbox" checked disabled="disabled" value='1' onChange={(e) => (e) => checkBoxOnChange(e)} /><label for='column_1'>  Business Group</label>
                        <input id="column_2" name='chk' type="checkbox" checked disabled="disabled" value='2' onChange={(e) => checkBoxOnChange(e)} /><label for='column_2'> Legal Entity </label>
                        <input id="column_3" name='chk' type="checkbox" value='3' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /><label for='column_3'>  Division</label>
                        <input id="column_4" name='chk' type="checkbox" value='4' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /><label for='column_4'>  Category </label>


                        <input id="column_5" name='chk' type="checkbox" value='5' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /><label for='column_5'>  Vendor Code</label>
                        <input id="column_6" name='chk' type="checkbox" value='6' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /><label for='column_6'>  Vendor Name </label>

                        <input id="column_11" name='chk' type="checkbox" value='11' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /><label for='column_11'> Location</label>
                        <input id="column_7" name='chk' type="checkbox" value='7' onChange={(e) => checkBoxOnChange(e)} /><label for='column_7'> Vendor Code 1</label>
                        <input id="column_8" name='chk' type="checkbox" value='8' onChange={(e) => checkBoxOnChange(e)} /><label for='column_8'>  Vendor Code 2</label>

                        <input id="column_9" name='chk' type="checkbox" value='9' onChange={(e) => checkBoxOnChange(e)} /><label for='column_9'> Vendor Code 3</label>
                        <input id="column_10" name='chk' type="checkbox" value='10' onChange={(e) => checkBoxOnChange(e)} /><label for='column_10'> Vendor Code 4</label>
                        <input id="column_12" name='chk' type="checkbox" value='12' onChange={(e) => checkBoxOnChange(e)} /><label for='column_12'>Domain</label>

                        <input id="column_13" name='chk' type="checkbox" value='13' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_13'> Organization Type</label>
                        <input id="column_14" name='chk' type="checkbox" value='14' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_14'> Postal Code</label>
                        <input id="column_15" name='chk' type="checkbox" value='15' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_15'> Product</label>
                        <input id="column_16" name='chk' type="checkbox" value='16' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_16'> Status</label>

                        <input id="column_17" name='chk' type="checkbox" value='17' onChange={(e) => checkBoxOnChange(e)} /><label for='column_17'>Date</label>


                    </div>

                    <div className="form-actions popupbtncolour text-center">
                        <Button type="primary" shape="round" className={'addbutton mt-3 ' + localStorage.getItem('currentTheme')}
                            icon={<PlayCircleOutlined />} size='default' onClick={handleOk}> Submit
                        </Button>
                    </div>

                </Drawer>
                <div className="container-fluid pt-1 mb-0">
                    <div className="row">
                        <div className="col-12">
                            <div className="card-body" style={{ position: 'relative', padding: "15px 5px" }}>
                                <div className={localStorage.getItem("currentTheme") + ' card-body1-statuatory'}>
                                    <Collapse accordion defaultActiveKey={["1"]} className='report-collapse' onChange={onChange} style={{ display: exitCollapse ? "none" : "block" }} >
                                        <Panel header="Vendor List" key={key} extra={tableshow == true ? '' : false}>
                                            <div className='col-md-12' >
                                                <div className='row'  >
                                                    <div className='col-md-4'>
                                                        <label><b>Country : </b> <span style={{ color: "red" }}>*</span></label>
                                                        <div className="form-group">
                                                            {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                                <div className="form-group">
                                                                    <Select
                                                                        placeholder='Enter Country'
                                                                        allowClear={true}
                                                                        size="default"
                                                                        onDrop={(e) => {
                                                                            e.preventDefault();
                                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                            return false;
                                                                        }}
                                                                        // onPaste={(e) => {
                                                                        //     handlePasting(e, /^[a-z]+$/gi)
                                                                        // }}
                                                                        onPaste={onPasteToaster}
                                                                        searchValue={selectSearchValue.country}
                                                                        onSearch={(value) => {
                                                                            let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                                                            setSelectSearchValue({
                                                                                ...selectSearchValue,
                                                                                country: result
                                                                            });
                                                                        }}
                                                                        onChange={(value, data) => {
                                                                            if (value != undefined) {
                                                                                let le = _.filter(sessionArr, { c_id: Number(value) })
                                                                                setEntity(le)
                                                                                setUnitlist({
                                                                                    ...unitlist,
                                                                                    country_id: value ? value : '',
                                                                                    country: data ? data.children : '',
                                                                                    legal_entity_id: '',
                                                                                    legal_entity_name: '',
                                                                                    domain: '',
                                                                                    division: "",
                                                                                    category: "",
                                                                                    unit: "",
                                                                                    business_group_id: '',
                                                                                    domain_id: null,
                                                                                    division_id: null,
                                                                                    category_id: null,
                                                                                    unit_id: null,
                                                                                    domain_org_id: '',
                                                                                    domain_org_name: '',
                                                                                    product_status: '',
                                                                                    product_status_id: '',
                                                                                    unit_status_id: '',
                                                                                    unit_status: '',
                                                                                })
                                                                            }
                                                                            else {
                                                                                setEntity([])
                                                                                setUnitlist({
                                                                                    ...unitlist,
                                                                                    country_id: '',
                                                                                    country: '',
                                                                                    legal_entity_id: '',
                                                                                    legal_entity_name: '',
                                                                                    domain: '',
                                                                                    division: "",
                                                                                    category: "",
                                                                                    unit: "",
                                                                                    business_group_id: '',
                                                                                    domain_id: null,
                                                                                    division_id: null,
                                                                                    category_id: null,
                                                                                    unit_id: null,
                                                                                    domain_org_id: '',
                                                                                    domain_org_name: '',
                                                                                    product_status: '',
                                                                                    product_status_id: '',
                                                                                    unit_status_id: '',
                                                                                    unit_status: '',

                                                                                })
                                                                            }
                                                                        }}
                                                                        showSearch
                                                                        optionFilterProp="children"
                                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                        style={{ width: '100%', marginTop: '5px' }}
                                                                        value={unitlist.country || undefined}
                                                                    >
                                                                        {uniq_country_data && uniq_country_data.length && uniq_country_data.map((item, i) => {
                                                                            return (
                                                                                <Option key={item.c_id}>
                                                                                    {item.c_name}
                                                                                </Option>
                                                                            );
                                                                        })}
                                                                    </Select>
                                                                    {validator.current.message(
                                                                        'country',
                                                                        unitlist.country,
                                                                        'required',
                                                                        {
                                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                            messages: {
                                                                                required: 'Country Required',
                                                                            }
                                                                        })}
                                                                </div> :
                                                                <p>{unitlist.country}</p>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4 text-wrap-report'>
                                                        <label><b>Legal Entity: </b><span style={{ color: "red" }}>*</span></label>

                                                        {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                            <div className="form-group">
                                                                <Select
                                                                    allowClear={true}
                                                                    size="default"
                                                                    placeholder="Enter Legal Entity"
                                                                    onDrop={(e) => {
                                                                        e.preventDefault();
                                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                        return false;
                                                                    }}
                                                                    // onPaste={(e) => {
                                                                    //     handlePasting(e, /^[a-z 0-9 ()]+$/gi)
                                                                    // }}
                                                                    onPaste={onPasteToaster}
                                                                    searchValue={selectSearchValue.legalEntity}
                                                                    onSearch={(value) => {
                                                                        let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                                        setSelectSearchValue({
                                                                            ...selectSearchValue,
                                                                            legalEntity: result
                                                                        });
                                                                    }}
                                                                    onChange={(data, value) => {
                                                                        if (data != undefined) {
                                                                            Setlegalstate(true)
                                                                            setCurrentEntity(data)
                                                                            setUnitlist({
                                                                                ...unitlist,
                                                                                legal_entity_id: data ? data : '',
                                                                                legal_entity_name: value ? value.children : '',
                                                                                domain: '',
                                                                                division: "",
                                                                                category: "",
                                                                                unit: "",
                                                                                business_group_id: '',
                                                                                domain_id: null,
                                                                                division_id: null,
                                                                                category_id: null,
                                                                                unit_id: null,
                                                                                domain_org_id: '',
                                                                                domain_org_name: '',
                                                                                product_status: '',
                                                                                product_status_id: '',
                                                                                unit_status_id: '',
                                                                                unit_status: '',
                                                                            })
                                                                        }
                                                                        else {
                                                                            Setlegalstate(false)
                                                                            setCurrentEntity('')
                                                                            setUnitlist({
                                                                                ...unitlist,
                                                                                legal_entity_id: '',
                                                                                legal_entity_name: '',
                                                                                domain: "",
                                                                                division: "",
                                                                                category: "",
                                                                                unit: "",
                                                                                business_group_id: '',
                                                                                domain_id: null,
                                                                                division_id: null,
                                                                                category_id: null,
                                                                                unit_id: null,
                                                                                domain_org_id: '',
                                                                                domain_org_name: '',
                                                                                product_status: '',
                                                                                product_status_id: '',
                                                                                unit_status_id: '',
                                                                                unit_status: '',
                                                                            })
                                                                        }
                                                                    }}
                                                                    showSearch
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                    style={{ width: '100%', marginTop: '5px' }}

                                                                    value={unitlist.legal_entity_name || undefined}
                                                                    disabled={unitlist.country == '' ? true : false}
                                                                >
                                                                    {finallegalEntity && finallegalEntity.length > 0 ? finallegalEntity.map((item, i) => {
                                                                        return (
                                                                            <Option key={item.le_id}>
                                                                                {item.le_name}
                                                                            </Option>
                                                                        );
                                                                    }) : entity && entity.length && entity.map((item, i) => {

                                                                        return (
                                                                            <Option key={item.le_id}>
                                                                                {item.le_name}
                                                                            </Option>
                                                                        );
                                                                    })}
                                                                </Select>
                                                                {validator.current.message(
                                                                    'legal_entity_id',
                                                                    unitlist.legal_entity_id,
                                                                    'required',
                                                                    {
                                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                        messages: {
                                                                            required: 'Legal Entity Required',
                                                                        }
                                                                    })}
                                                            </div> :
                                                            <p>{localStorage.getItem('SelectedEntity')}</p>
                                                        }
                                                    </div>

                                                    <div className='col-md-4'>
                                                        <label><b>Domain:</b> </label>
                                                        {/* {filerunitdatas.domains_organisations_list && filerunitdatas.domains_organisations_list.length > 0 && filerunitdatas.domains_organisations_list.length == 1 ?
                                                            <span className='d-block mt-2'>
                                                                {filerunitdatas.domains_organisations_list && filerunitdatas.domains_organisations_list.length > 0 && filerunitdatas.domains_organisations_list[0].domain_name}
                                                            </span> : */}
                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Enter Domain"
                                                            value={unitlist.domain || undefined}
                                                            disabled={unitlist.legal_entity_name == '' ? true : false}
                                                            style={{ width: '100%', marginTop: '5px' }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            // onPaste={(e) => {
                                                            //     handlePasting(e, /^[a-z - .]+$/gi)
                                                            // }}
                                                            onPaste={onPasteToaster}
                                                            searchValue={selectSearchValue.domain}
                                                            onSearch={(value) => {
                                                                let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                                                setSelectSearchValue({
                                                                    ...selectSearchValue,
                                                                    domain: result
                                                                });
                                                            }}
                                                            onDrop={(e) => {
                                                                e.preventDefault();
                                                                // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                return false;
                                                            }}
                                                            onChange={(data, value) => {
                                                                if (data != undefined) {
                                                                    setUnitlist({
                                                                        ...unitlist,
                                                                        domain_id: data ? data : '',
                                                                        domain: value ? value.children : ''
                                                                    })
                                                                }
                                                                else {
                                                                    setUnitlist({
                                                                        ...unitlist,
                                                                        domain_id: '',
                                                                        domain: ''
                                                                    })
                                                                }
                                                            }}>

                                                            {filerunitdatas.domains_organisations_list && filerunitdatas.domains_organisations_list.length > 0 &&
                                                                filerunitdatas.domains_organisations_list.map((item) => (

                                                                    <Option key={item.domain_id}>{item.domain_name}</Option>
                                                                ))}
                                                        </Select>
                                                        {/* } */}

                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-12' style={{ marginTop: "5px" }}>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <label><b>Business Group: </b>  </label>
                                                        {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                            <div className="form-group">
                                                                <Select
                                                                    allowClear={true}
                                                                    size="default"

                                                                    placeholder="Enter Business Group"
                                                                    onDrop={(e) => {
                                                                        e.preventDefault();
                                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                        return false;
                                                                    }}
                                                                    // onPaste={(e) => {
                                                                    //     handlePasting(e, /^[a-z 0-9]+$/gi)
                                                                    // }}
                                                                    onPaste={onPasteToaster}
                                                                    searchValue={selectSearchValue.BusinessGroup}
                                                                    onSearch={(value) => {
                                                                        let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                                        setSelectSearchValue({
                                                                            ...selectSearchValue,
                                                                            BusinessGroup: result
                                                                        });
                                                                    }}
                                                                    onChange={(data, value) => {
                                                                        if (data != undefined) {
                                                                            let filteredEntity = _.filter(sessionArr, { bg_id: Number(data) })
                                                                            setFinallegalentity(filteredEntity)
                                                                            setUnitlist({
                                                                                ...unitlist,
                                                                                bg_id: data ? data : '',
                                                                                bg_name: value ? value.children : ''
                                                                            })
                                                                        }
                                                                        else {
                                                                            setUnitlist({
                                                                                ...unitlist,
                                                                                bg_id: '',
                                                                                bg_name: ''
                                                                            })
                                                                            setFinallegalentity([])
                                                                        }
                                                                    }}
                                                                    value={unitlist.bg_name ? unitlist.bg_name : undefined}
                                                                    showSearch
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                    //  value={userDetails.legal_entity_id}
                                                                    style={{ width: '100%', marginTop: '5px' }}
                                                                >
                                                                    {businessArr && businessArr.length && businessArr.map((item, i) => {

                                                                        if (item.bg_name !== null) {
                                                                            return (
                                                                                <Option key={item.bg_id}>
                                                                                    {item.bg_name}
                                                                                </Option>
                                                                            );
                                                                        }
                                                                    })}
                                                                </Select>

                                                            </div> :
                                                            <p> - </p>
                                                        }

                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label><b>Category:</b></label>
                                                        <Select
                                                            onChange={(data, value) => {
                                                                if (data != undefined) {
                                                                    setUnitlist({
                                                                        ...unitlist,
                                                                        category_id: data ? data : '',
                                                                        category: value ? value.children : ''
                                                                    })
                                                                }
                                                                else {
                                                                    setUnitlist({
                                                                        ...unitlist,
                                                                        category_id: '',
                                                                        category: ''
                                                                    })
                                                                }
                                                            }}
                                                            onDrop={(e) => {
                                                                e.preventDefault();
                                                                // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                return false;
                                                            }}
                                                            // onPaste={(e) => {
                                                            //     handlePasting(e, /^[a-z 0-9]+$/gi)
                                                            // }}
                                                            onPaste={onPasteToaster}
                                                            searchValue={selectSearchValue.category}
                                                            onSearch={(value) => {
                                                                let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                                setSelectSearchValue({
                                                                    ...selectSearchValue,
                                                                    category: result
                                                                });
                                                            }}
                                                            allowClear={true}
                                                            placeholder="Enter Category"
                                                            disabled={unitlist.legal_entity_name == '' ? true : false}
                                                            style={{ width: '100%', marginTop: '5px' }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}


                                                        >

                                                            {filerunitdatas.categories && filerunitdatas.categories.length > 0 &&
                                                                filerunitdatas.categories.map((item) => (

                                                                    <Option key={item.category_id}>{item.category_name}</Option>
                                                                ))}                                                       </Select>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label><b>Division:</b></label>
                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Enter Division"
                                                            disabled={unitlist.legal_entity_name == '' ? true : false}
                                                            onChange={(data, value) => {
                                                                if (data != undefined) {
                                                                    setUnitlist({
                                                                        ...unitlist,
                                                                        division_id: data ? data : '',
                                                                        division: value ? value.children : ''

                                                                    })
                                                                }
                                                                else {
                                                                    setUnitlist({
                                                                        ...unitlist,
                                                                        division_id: '',
                                                                        division: ''

                                                                    })
                                                                }
                                                            }}
                                                            onDrop={(e) => {
                                                                e.preventDefault();
                                                                // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                return false;
                                                            }}
                                                            // onPaste={(e) => {
                                                            //     handlePasting(e, /^[a-z 0-9]+$/gi)
                                                            // }}
                                                            onPaste={onPasteToaster}
                                                            searchValue={selectSearchValue.division}
                                                            onSearch={(value) => {
                                                                let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                                setSelectSearchValue({
                                                                    ...selectSearchValue,
                                                                    division: result
                                                                });
                                                            }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                                                            style={{ width: '100%', marginTop: '5px' }}
                                                        >

                                                            {filerunitdatas.divisions && filerunitdatas.divisions.length > 0 &&
                                                                filerunitdatas.divisions.map((item) => (
                                                                    <Option key={item.division_id}>{item.division_name}</Option>
                                                                ))}

                                                        </Select>


                                                    </div>

                                                </div>
                                            </div>
                                            <div className='col-md-12' style={{ marginTop: "5px" }}>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <label><b>Organization Type:</b></label>
                                                        <Select
                                                            onChange={(data, value) => {
                                                                if (data != undefined) {

                                                                    setUnitlist({
                                                                        ...unitlist,
                                                                        domain_org_id: data ? data : '',
                                                                        domain_org_name: value ? value.children : ''
                                                                    })
                                                                }
                                                                else {
                                                                    setUnitlist({
                                                                        ...unitlist,
                                                                        domain_org_id: '',
                                                                        domain_org_name: ''
                                                                    })
                                                                }
                                                            }}
                                                            onPaste={onPasteToaster}
                                                            searchValue={selectSearchValue.orgType}
                                                            onSearch={(value) => {
                                                                let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                                                setSelectSearchValue({
                                                                    ...selectSearchValue,
                                                                    orgType: result
                                                                });
                                                            }}
                                                            allowClear={true}
                                                            placeholder="Enter Organization"
                                                            style={{ width: '100%', marginTop: '5px' }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                                                            value={unitlist.domain_org_name || undefined}

                                                        >
                                                            {filerunitdatas && filerunitdatas.domains_organisations_list && filerunitdatas.domains_organisations_list.length > 0 &&
                                                                filerunitdatas.domains_organisations_list.map((item) => {

                                                                    return <Option key={item.organisation_id}>{item.organisation_name}</Option>
                                                                })}
                                                        </Select>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label><b>Vendor:</b></label>
                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Enter Vendor"
                                                            disabled={unitlist.legal_entity_name == '' ? true : false}
                                                            onDrop={(e) => {
                                                                e.preventDefault();
                                                                // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                return false;
                                                            }}
                                                            // onPaste={(e) => {
                                                            //     handlePasting(e, /^[a-z 0-9 -]+$/gi)
                                                            // }}
                                                            onPaste={onPasteToaster}
                                                            searchValue={selectSearchValue.vendor}
                                                            onSearch={(value) => {
                                                                let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                                setSelectSearchValue({
                                                                    ...selectSearchValue,
                                                                    vendor: result
                                                                });
                                                            }}
                                                            onChange={(data, value) => {
                                                                if (data != undefined) {

                                                                    setUnitlist({
                                                                        ...unitlist,
                                                                        unit_id: data ? data : '',
                                                                        unit: value ? value.children : ''
                                                                    })
                                                                }
                                                                else {
                                                                    setUnitlist({
                                                                        ...unitlist,
                                                                        unit_id: '',
                                                                        unit: ''
                                                                    })
                                                                }
                                                            }}
                                                            style={{ width: '100%', marginTop: '5px' }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                                                            value={unitlist.unit || undefined}
                                                        >

                                                            {filerunitdatas && filerunitdatas.units_list && filerunitdatas.units_list.length > 0 &&
                                                                filerunitdatas.units_list.map((item) => {

                                                                    return <Option key={item.unit_id}>{`${item.unit_code}-${item.unit_name}`}</Option>
                                                                })}
                                                        </Select>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label><b>Product:</b></label>
                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Enter Product"
                                                            defaultValue="ALL"

                                                            onChange={(data, value) => {
                                                                if (data != undefined) {

                                                                    setUnitlist({
                                                                        ...unitlist,
                                                                        product_status_id: data ? data : '',
                                                                        product_status: value ? value.children : ''
                                                                    })
                                                                }
                                                                else {
                                                                    setUnitlist({
                                                                        ...unitlist,
                                                                        product_status_id: '',
                                                                        product_status: ''
                                                                    })
                                                                }
                                                            }}

                                                            style={{ width: '100%', marginTop: '5px' }}



                                                        >
                                                            <Option key={0}>ALL</Option>
                                                            {productfilter && productfilter.length > 0 &&
                                                                productfilter.map((item) => {

                                                                    return <Option key={item.product_status_id}>{item.product_status}</Option>
                                                                })}

                                                        </Select>

                                                    </div>

                                                </div>
                                            </div>
                                            <div className='col-md-12' style={{ marginTop: "5px" }}>
                                                <div className='row'>
                                                    <div className='col-md-4'>

                                                        <label><b>Status:</b> </label>
                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Enter Status"
                                                            defaultValue="ALL"
                                                            onChange={(data, value) => {
                                                                if (data != undefined) {
                                                                    setUnitlist({
                                                                        ...unitlist,
                                                                        unit_status_id: data ? data : '',
                                                                        unit_status: value ? value.children : ''
                                                                    })
                                                                }
                                                                else {
                                                                    setUnitlist({
                                                                        ...unitlist,
                                                                        unit_status_id: '',
                                                                        unit_status: ''
                                                                    })
                                                                }
                                                            }}

                                                            style={{ width: '100%', marginTop: '5px' }}


                                                        >
                                                            <Option key='-1'>ALL</Option>
                                                            {filerunitdatas && filerunitdatas.unit_status_list && filerunitdatas.unit_status_list.length > 0 &&
                                                                filerunitdatas.unit_status_list.map((item) => {

                                                                    return <Option key={item.unit_status_id}>{item.unit_status}</Option>
                                                                })}

                                                        </Select>


                                                        <div className='col-md-4'></div>
                                                    </div>
                                                    {/* <div className='col-md-4 popupbtncolour' style={{ marginLeft: '5%', marginTop: 'auto' }}>
                                <Button type="primary" shape="round" className={
                                "addbutton " + localStorage.getItem("currentTheme")
                            } style={{ background: "#0096FF", borderColor: "#0096FF", marginRight: "10px", marginTop: '5px' }}
                                    icon={<EyeOutlined />} onClick={onshow}
                                >
                                    Show
                                </Button>
                                <Button type="primary" shape="round" className={
                                "addbutton " + localStorage.getItem("currentTheme")
                            } style={{ background: "#32a852", borderColor: "#32a852", marginTop: '5px' }}
                                    icon={<ExportOutlined />} onClick={showModal}
                                >
                                    Export
                                </Button>
                            </div> */}
                                                </div>

                                            </div>
                                            <div className='col-md-12' style={{ marginTop: "5px" }}>
                                                <div className='row'>

                                                    <div className='col-md-4 popupbtncolour' style={{ marginLeft: '32%' }}>

                                                        <Button type="primary" shape="round" className={
                                                            "addbutton " + localStorage.getItem("currentTheme")
                                                        } style={{ background: "#0096FF", borderColor: "#0096FF", marginLeft: "23%", marginRight: "10px", marginTop: '5px' }}
                                                            icon={<EyeOutlined />} onClick={onshow}
                                                        >
                                                            Show
                                                        </Button>
                                                        <Button type="primary" shape="round" className={
                                                            "addbutton " + localStorage.getItem("currentTheme")
                                                        } style={{ background: "#32a852", borderColor: "#32a852", marginTop: '5px' }}
                                                            icon={<ExportOutlined />} onClick={showModal}
                                                        >
                                                            Export
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Panel>
                                    </Collapse>
                                </div>


                                {tableshow == true ?
                                    <>
                                        <div className='col-md-12 mt-1 filter-detial-sticky' >
                                            <div className='row'>
                                                <div className='col-md-5 text-wrap-report'>
                                                    <label><b>Legal Entity : </b>{unitlist.legalEntityLabel}</label>
                                                </div>
                                                <div className='col-md-3'>
                                                    <label><b>Country : </b>{unitlist.countryLabel}</label>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Domain : </b>{unitlist.domainLabel}</label>
                                                </div>


                                            </div>
                                        </div>

                                        <div className={localStorage.getItem('currentTheme')}>
                                            <div className='ant-table-wrapper'>
                                                <div className='ant-table ant-table-bordered ant-table-fixed-header'>
                                                    <div className='ant-table-container'>
                                                        <div className='ant-table-content userprivclass1'>
                                                            <table>
                                                                <colgroup>
                                                                    <col style={{ width: '10px' }}></col>
                                                                </colgroup>
                                                                <thead className='ant-table-thead table-head-stick'>
                                                                    <tr>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: "20px" }}> # </th>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: "100px" }}>Vendor Code</th>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: "100px" }}>Vendor Name</th>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: "100px" }}>Time Zone</th>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: "140px" }}><center>Domain-Organization Type</center></th>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: "100px" }}>Location</th>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: "100px" }}><center>Address</center></th>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: "100px" }}><center>Product</center></th>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: "80px" }}><center>Status</center></th>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: "80px" }}><center>Date</center></th>

                                                                    </tr>
                                                                </thead>

                                                                {resultArray && resultArray.length > 0 ?
                                                                    filteredArrayState && filteredArrayState.length > 0 && filteredArrayState.map((item, i) => {

                                                                        return (
                                                                            <tbody className='ant-table-tbody'>
                                                                                {/* <tr className="bg-lightdark">
                                                                            <td colSpan="100%">
                                                                                <table className='white' style={{ tableLayout: 'auto' }}>
                                                                                    <tbody className='ant-table-tbody'><tr className='ant-table-row ant-table-row-level-0'>
                                                                                        <td className='ant-table-cell w6'><b>Unit :</b></td>
                                                                                        <td className='ant-table-cell w15' title={item[0].unit_name}>{item[0].unit_name}</td>
                                                                                        <td className='ant-table-cell w6'><b></b></td>
                                                                                        <td className='ant-table-cell w10'><span className="division-name"></span></td>
                                                                                        <td className='ant-table-cell w6'><b>Category</b></td>
                                                                                        <td className='ant-table-cell w40'><span className="category-name">{item[0].category_name}</span></td>
                                                                                    </tr>
                                                                                        <tr className='ant-table-row ant-table-row-level-0'>
                                                                                            <td className='ant-table-cell w6'><b>Division :</b></td>
                                                                                            <td className='ant-table-cell w15' >{item[0].division_name}</td>
                                                                                            <td className='ant-table-cell w6'><b></b></td>
                                                                                            <td className='ant-table-cell w10'><span className="division-name"></span></td>
                                                                                            <td className='ant-table-cell w6'><b>Time Zone</b></td>
                                                                                            <td className='ant-table-cell w40'><span className="category-name">{item[0].tz_name}</span></td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </td>
                                                                        </tr> */}

                                                                                {titledata && titledata.map((itemdata, i) => {

                                                                                    let data = _.find(itemdata.child, { category_name: item[0].category_name })

                                                                                    if (data != undefined) {

                                                                                        if (data.category_name == itemdata.parent) {
                                                                                            return <Fragment>
                                                                                                <tr className='ant-table-row ant-table-row-level-0'>
                                                                                                    <td colSpan={6} className='ant-table-cell' ><b style={{ backgroundColor: "lightyellow" }}>{"Division : " + itemdata.child[0].division_name}</b></td>
                                                                                                    <td colSpan={6} className='ant-table-cell' ><b style={{ backgroundColor: "lightyellow" }}>{"Category : " + itemdata.parent}</b></td>
                                                                                                </tr>
                                                                                                {itemdata.child.map((items) => {
                                                                                                    if (item[0].category_name == items.category_name) {
                                                                                                        return <tr>
                                                                                                            <td className='ant-table-cell'><centre>{count + 1}</centre></td>
                                                                                                            <td className='ant-table-cell'>{items.unit_code}</td>
                                                                                                            <td className='ant-table-cell'>{items.unit_name}</td>
                                                                                                            <td className='ant-table-cell'>{items.tz_name}</td>
                                                                                                            <td className='ant-table-cell'>{items.d_i_names}</td>
                                                                                                            <td className='ant-table-cell'>{items.geography_name}</td>
                                                                                                            <td className='ant-table-cell'>{items.address}{items.postal_code ? "," + items.postal_code : ''}</td>
                                                                                                            <td className='ant-table-cell'>{items.product_status}</td>
                                                                                                            <td className='ant-table-cell'>{items.unit_status}</td>
                                                                                                            <td className='ant-table-cell'><center>-</center></td>
                                                                                                            <span hidden>{count = count + 1}</span>
                                                                                                        </tr>
                                                                                                    }
                                                                                                })
                                                                                                }
                                                                                            </Fragment>
                                                                                        } else if (itemdata.parent == '-') {
                                                                                            if (data.category_name == '') {
                                                                                                return <Fragment>
                                                                                                    <tr className='ant-table-row ant-table-row-level-0'>
                                                                                                        <td colSpan={6} className='ant-table-cell'><b style={{ backgroundColor: "lightyellow" }}>{"Division:" + itemdata.child[0].division_name}</b></td>
                                                                                                        <td colSpan={6} className='ant-table-cell'><b style={{ backgroundColor: "lightyellow" }}>{"Category:" + itemdata.parent}</b></td>
                                                                                                    </tr>
                                                                                                    {itemdata.child.map((items) => {
                                                                                                        console.log(items.d_i_names, 'items.d_i_namesytfytfyt');
                                                                                                        if (item[0].category_name == items.category_name) {
                                                                                                            return <tr>
                                                                                                                <td className='ant-table-cell'>{count + 1}</td>
                                                                                                                <td className='ant-table-cell'>{items.unit_code}</td>
                                                                                                                <td className='ant-table-cell'>{items.unit_name}</td>
                                                                                                                <td className='ant-table-cell'>{items.tz_name}</td>
                                                                                                                <td className='ant-table-cell'><center>{items.d_i_names}</center></td>
                                                                                                                <td className='ant-table-cell'><center>{items.geography_name}</center></td>
                                                                                                                <td className='ant-table-cell'><center>{items.address}{items.postal_code ? "," + items.postal_code : ''}</center></td>
                                                                                                                <td className='ant-table-cell'><center>{items.product_status}</center></td>
                                                                                                                <td className='ant-table-cell'><center>{items.unit_status}</center></td>
                                                                                                                <td className='ant-table-cell'><center>-</center></td>
                                                                                                                <span hidden>{count = count + 1}</span>
                                                                                                            </tr>
                                                                                                        }
                                                                                                    })
                                                                                                    }
                                                                                                </Fragment>
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                })
                                                                                }
                                                                            </tbody>
                                                                        )
                                                                    }) :
                                                                    <tr>
                                                                        <td colSpan="100%" style={{ paddingTop: '15px', textAlign: 'center', fontSize: '14px', fontWeight: 'bold', paddingBottom: '15px' }}>No Records Found</td>
                                                                    </tr>
                                                                }
                                                            </table>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            {count != 0 ?
                                                <div className='col-md-6' >
                                                    <label>Showing {pageSize * (current - 1) + 1} to {count} of {tabledatas && tabledatas.total_count} entries</label>
                                                </div> : ''}

                                            <div className='col-md-6 text-right'>
                                                <Pagination
                                                    current={current}
                                                    showSizeChanger={true}
                                                    // showQuickJumper={true}
                                                    onShowSizeChange={() => {
                                                        setCurrent(1)
                                                        // setpageState(true)
                                                    }}
                                                    pageSizeOptions={[25, 50, 100]} hideOnSinglePage={tabledatas.total_count > 25 ? false : true} defaultPageSize={25}
                                                    onChange={(page, pageSizes) => {
                                                        setpageState(true)
                                                        setCurrent(pageSize !== pageSizes ? 1 : page);
                                                        setPageSize(pageSizes)
                                                    }} total={tabledatas.total_count}
                                                // showTotal={(total) => `Showing 1 to ${index} of ${tableData.total_count} entries`}
                                                />
                                            </div>
                                        </div>




                                    </>
                                    : ''
                                    //  <h4 style={{ textAlign: 'center', marginTop: '5%' }}>No Records Found</h4>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default connect(mapStateToProps, {
    UnitListFilters,
    UnitListshow,
    UnitListexport

})(VendorList);