import React, { Fragment } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux';
import { Collapse, Select, Input, DatePicker, Card, Modal, Button, Table, Tooltip, Pagination } from 'antd';
import moment from 'moment';
import { useState, useRef, useEffect } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";
import { toast } from 'react-toastify';
import { AuditTrailReportFilters, AuditTrailReportData, ExportAuditTrailReportData } from '../../../Store/Action/Report/AuditTrail';
import { AUDIT_TRAIL_REPORT_EXPORT_RESET } from '../../../Store/types/index'
import { unionBy, uniqBy } from 'lodash';
import { ExclamationCircleTwoTone, InfoCircleOutlined, ArrowRightOutlined, ArrowLeftOutlined, PlayCircleOutlined, DownloadOutlined, UpOutlined, FullscreenOutlined, EyeOutlined, ExportOutlined, FilterOutlined } from '@ant-design/icons';
import ScrollToTop from "react-scroll-to-top";
import { fullscreenmode, fullscreenexit, handleKeyDown } from './../../../Libs/fullscreenmode';
import useStickyHeight from "../../../Libs/useStickyHeight";
import { validationType, validateSelect, onPasteToaster } from './../../../Libs/inputValidation';
import ScrollButton from '../../Scrollbar';
import { Toaster } from '../../../Libs/Toaster';
import url, { getUrl } from './../../../Libs/URL'
import { HomeRedirect } from './../../../Libs/country'
const urls = getUrl()
const homepage = HomeRedirect()

const mapStateToProps = (state) => ({
    audittrailreports: state.AuditTrail.audittrailreport,
})

const AuditTrail = ({
    AuditTrailReportFilters,
    AuditTrailReportData,
    ExportAuditTrailReportData,
    audittrailreports: { audittrailfilter, audittrailshow, audittrailexport }
}) => {
    useStickyHeight();
    const historynew = useHistory();
    const location = useLocation();
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const dispatch = useDispatch();
    const is_Fullscreen = useSelector((state) => state.Login.is_fullscreen);
    // const entityid = localStorage.getItem('SelectedEntityid');
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const sessionArr = sessionParsedValue.entity_info
    const country_info = sessionParsedValue.country_info
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid').replaceAll('"', ''));
    const paramid = location.pathname;
    const _ = require("lodash");
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [tableshow, setTableShow] = useState(false)
    const [filteredArray, setFilteredArray] = useState([])
    const [filteredArrayState, setfilteredArrayState] = useState([])
    let [count, setcount] = useState(0)
    console.log(count, 'count');
    const [key, setKey] = useState("1")
    const [legalstate, Setlegalstate] = useState(false)
    const [current, setCurrent] = useState(1);
    const [pageState, setpageState] = useState(false);
    const [pageSize, setPageSize] = useState(25)
    const [totalcount, setTotalcount] = useState("")
    // const [disableButton, setDisableButton] = useState(false)
    const [formArray, setFormArray] = useState([])
    const [listData, setListData] = useState([])
    const [filterTaskAcc, setFilterTaskAcc] = useState(true);
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const [exitCollapse, setExitCollapse] = useState(false);
    const [unitdata, setUnitData] = useState({
        user: 0,
        user_name: '',
        legal_entity_id: "",
        legal_entity_name: localStorage.getItem('SelectedEntity') === "All Legal Entity" ? '' : localStorage.getItem('SelectedEntity'),
        from_date: "",
        to_date: "",
        form_id: 0,
        form_name: '',
        time_zone_id: '',
        time_zone_name: '',
        legalEntityLabel: localStorage.getItem('SelectedEntity') === "All Legal Entity" ? '' : localStorage.getItem('SelectedEntity'),
        fromLabel: '',
        toLabel: '',
        timeZoneLabel: ''
    })
    const validator = useRef(new SimpleReactValidator());
    const { Panel } = Collapse;
    const { Option } = Select;
    let newDate = new Date();
    const [selectSearchValue, setSelectSearchValue] = useState({
        user: '',
        legalEntity: '',
        formName: '',
        timezone: '',
    });

    console.log(pageSize, 'pageSize');
    console.log(current, 'currr');

    //  Full screen mode
    const [fullscreen, setfullscreen] = useState(false);

    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
            document.addEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch), false);
            return () => {
                document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch));
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode(dispatch);
            setfullscreen(true);
        } else {
            fullscreenexit(dispatch);
            setfullscreen(false);
        }
    }
    const setcancelFilter = () => {
        setFilterModalVisible(false);
        setFilterTaskAcc(false);
    };
    const filterHandle = () => {
        setFilterTaskAcc(true);
        setFilterModalVisible(true);
    };
    useEffect(() => {
        if (isAuth) {
            if (legalstate === true) {
                if (entityid != "null") {
                    AuditTrailReportFilters({
                        payload: [
                            authtoken,
                            {
                                "session_token": authtoken,
                                "request": [
                                    "GetAuditTrailReportFilters",
                                    {
                                        "legal_entity_id": entityid
                                    }
                                ]
                            }
                        ],
                        paramid: paramid
                    })
                }
                Setlegalstate(false)
            }
        }
    }, [isAuth, entityid, legalstate])
    useEffect(() => {
        if (audittrailshow && audittrailshow.audit_activities && audittrailshow.audit_activities.length > 0) {
            setListData(audittrailshow && audittrailshow.audit_activities && audittrailshow.audit_activities)
        }
    }, [audittrailshow && audittrailshow.audit_activities])
    useEffect(() => {
        validator.current.showMessages()
    }, [])


    useEffect(() => {
        if (filteredArray.length) {
            filteredArray && filteredArray.map((item, i) => {
                return (
                    setfilteredArrayState(item)
                )
            })
        }
    }, [filteredArray])

    useEffect(() => {
        if (pageState === true) {
            setcount(pageSize * (current - 1))
        }
    }, [pageState, pageSize, current])

    useEffect(() => {
        if (audittrailfilter && audittrailfilter.audit_forms_list && audittrailfilter.audit_forms_list.length > 0) {
            const uniqueArray = _.uniqBy(audittrailfilter.audit_forms_list, function (e) {
                return e.form_id;
            });
            setFormArray(uniqueArray)
        }

    }, [audittrailfilter])



    useEffect(() => {
        if (entityid != null) {
            setUnitData({
                ...unitdata,
                country: sessionArr[0].c_id
            })
        }
    }, [entityid != null])

    useEffect(() => {
        if (unitdata.legal_entity_name == localStorage.getItem('SelectedEntity')) {
            Setlegalstate(true)
        }
    }, [unitdata.legal_entity_name])

    useEffect(() => {
        if (audittrailexport && audittrailexport.link) {
            const link = audittrailexport.link.split('cv/');
            window.open(urls + '/' + `${link[1] ? link[1] : link}`);
            dispatch({
                type: AUDIT_TRAIL_REPORT_EXPORT_RESET
            });
        }

    }, [audittrailexport])
    useEffect(() => {
        if (audittrailshow && audittrailshow.total_count > 0) {

            setTotalcount(audittrailshow.total_count)
        }
    }, [audittrailshow])

    console.log(audittrailshow, 'audittrailshow');

    const onshow = () => {

        setAddFormSubmit(true)

        if (validator.current.allValid()) {
            setListData([])
            setcount(0);
            setCurrent(1);
            setpageState(false)
            setPageSize(25)
            setTotalcount('')
            setExitCollapse(true);
            setFilterModalVisible(false);
            setFilterTaskAcc(false);
            setTableShow(true)
            setKey(0)
            setUnitData({
                ...unitdata,
                legalEntityLabel: unitdata.legal_entity_name,
                fromLabel: unitdata.from_date,
                toLabel: unitdata.to_date,
                timeZoneLabel: unitdata.time_zone_name
            })
            AuditTrailReportData({
                payload: [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetAuditTrailReportData",
                            {
                                "legal_entity_id": entityid,
                                "user_id": Number(unitdata.user),
                                "form_id_optional": Number(unitdata.form_id),
                                "due_from_date": unitdata.from_date,
                                "due_to_date": unitdata.to_date,
                                "csv": false,
                                "from_count": 0,
                                "page_count": 25,
                                "check_count": true,
                                "tz_name": unitdata.time_zone_name ? unitdata.time_zone_name : 'UTC'
                            }
                        ]
                    }
                ],
                paramid: paramid
            })

        }
    }

    console.log(current, 'currentcurrent');
    console.log(pageSize, 'pageSizepageSize');

    useEffect(() => {
        if (pageState == true) {
            AuditTrailReportData({
                payload: [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetAuditTrailReportData",
                            {
                                "legal_entity_id": entityid,
                                "user_id": Number(unitdata.user),
                                "form_id_optional": Number(unitdata.form_id),
                                "due_from_date": unitdata.from_date,
                                "due_to_date": unitdata.to_date,
                                "csv": false,
                                "from_count": Math.max(((current - 1) * pageSize), 0),
                                "page_count": pageSize,
                                "check_count": true,
                                "tz_name": unitdata.time_zone_name ? unitdata.time_zone_name : 'UTC'


                            }
                        ]
                    }
                ],
                paramid: paramid
            })
        }
    }, [pageState, current, pageSize])
    const onexport = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            // setTableShow(true)
            setKey(0)
            ExportAuditTrailReportData({
                payload: [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetAuditTrailReportData",
                            {
                                "legal_entity_id": entityid,
                                "user_id": Number(unitdata.user),
                                "form_id_optional": Number(unitdata.form_id),
                                "due_from_date": unitdata.from_date,
                                "due_to_date": unitdata.to_date,
                                "csv": true,
                                "from_count": 0,
                                "page_count": 0,
                                "check_count": true,
                                "tz_name": unitdata.time_zone_name ? unitdata.time_zone_name : 'UTC'
                            }
                        ]
                    }
                ],
                paramid: paramid
            })

        }
    }

    const onChange = (key) => {
        // console.log(key);
    };

    const homescreen = () => {
        historynew.push(homepage);
        // window.location.href = homepage
        document.getElementById('homehover').style.color = "blue"
    }

    const handlePasting = (e, regex) => {

        var regex = regex;
        var key = e.clipboardData.getData('text')
        if (!regex.test(key)) {
            e.preventDefault();
            Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
            return false;
        }
        // e.clipboardData.getData('text')
        // Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
        // e.preventDefault();
        // return false;
    }

    console.log(audittrailfilter, 'audittrailfilter');

    return (
        <>
            {/* <ScrollToTop smooth color="red" component={<Tooltip title="Back to top"><UpOutlined /></Tooltip>} />
            {document.body.scrollHeight > document.body.clientHeight ?
                <div className='back-to-top'>
                    <ScrollButton />
                </div> : ''} */}
            {tableshow == true ?
                <div className='back-to-top'>
                    <ScrollButton />
                </div> : ''}
            <div id='page-wrapper' className="page-wrapper">
                <div className="page-titles pb-1 pt-1 page-title-sticky">
                    <div className="row">
                        <div className="col-lg-8 col-md-6 col-12 align-self-center">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 d-flex align-items-center">
                                    <li className="breadcrumb-item">
                                        <span className='pointer' id='homehover' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        <span style={{ fontSize: '14px', fontWeight: "bold" }}>Report</span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: '15px', fontWeight: 'bold' }}>
                                        <span style={{ fontSize: "15px", fontWeight: "bold" }}>Log Reports</span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                        <span>Audit Trail</span>
                                    </li>
                                </ol>
                            </nav>

                        </div>
                        <div className="col-lg-4 text-end">
                            <Tooltip title="Advance Filter"><Button
                                className="mt-1"
                                type="primary"
                                shape="round"
                                style={{
                                    display: filterTaskAcc ? "none" : "initial",
                                }}
                                onClick={filterHandle}
                            >
                                <FilterOutlined /> Filter
                            </Button></Tooltip>
                            <button style={{ float: 'right', marginTop: '5px' }} onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black ms-2 full-mode fullscreenradius" >
                                <Tooltip placement="left" title={is_Fullscreen == true ? "Exit Full Screen Mode" : "Full Screen Mode"}><FullscreenOutlined /></Tooltip>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="container-fluid pt-1">
                    <div className="row">
                        <div className="col-12">
                            <div className={"card-body userCard " + localStorage.getItem('currentTheme')} id='only-restricted' style={{ position: 'relative', padding: "10px 3px" }}>
                                <Collapse defaultActiveKey={["1"]} className='report-collapse' onChange={onChange} style={{ display: exitCollapse ? "none" : "block" }}>
                                    <Panel header=" Audit Trail" key={"1"}>
                                        <div className='col-md-12'>
                                            <div className='row'>
                                                <div className='col-md-4 text-wrap-report'>
                                                    <label style={{ "fontWeight": "bold" }}>Legal Entity: <span style={{ color: "red" }}>*</span></label>
                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                placeholder="Enter Legal Entity"
                                                                onDrop={(e) => {
                                                                    e.preventDefault();
                                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                    return false;
                                                                }}
                                                                // onPaste={(e) => {
                                                                //     handlePasting(e, /^[a-z 0-9 ()]+$/gi)
                                                                // }}
                                                                onPaste={onPasteToaster}
                                                                searchValue={selectSearchValue.legalEntity}
                                                                onSearch={(value) => {
                                                                    let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                                    setSelectSearchValue({
                                                                        ...selectSearchValue,
                                                                        legalEntity: result
                                                                    });
                                                                }}
                                                                onChange={(data, value) => {
                                                                    setListData([])
                                                                    Setlegalstate(true)
                                                                    setCurrentEntity(data != undefined ? data : localStorage.getItem('SelectedEntityid').replaceAll('"', ''))
                                                                    setUnitData({
                                                                        ...unitdata,
                                                                        legal_entity_id: data != undefined ? data : '',
                                                                        legal_entity_name: value != undefined ? value.children : '',
                                                                        from_date: '',
                                                                        to_date: '',
                                                                        form_name: '',
                                                                        time_zone_name: '',
                                                                        user_name: '',
                                                                        user: '',
                                                                        time_zone_id: '',
                                                                        form_id: ''


                                                                    })
                                                                    setAddFormSubmit(false)
                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                style={{ width: '100%', marginTop: '5px' }}
                                                                value={unitdata.legal_entity_name || undefined}
                                                            >
                                                                {sessionArr && sessionArr.length > 0 && sessionArr.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.le_id}>
                                                                            {item.le_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                            {validator.current.message(
                                                                'legal_entity_id',
                                                                unitdata.legal_entity_name,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Legal Entity Required',
                                                                    }
                                                                })}
                                                        </div> :
                                                        <p>{localStorage.getItem('SelectedEntity')}</p>
                                                    }
                                                </div>
                                                <div className='col-md-4'>
                                                    {/* value={unitdata.from_date ? moment(unitdata.from_date) : ''} */}
                                                    <label><b>From Date:</b> <span style={{ color: "red" }} >*</span></label>
                                                    <DatePicker className='form-control' format={'DD-MMM-YYYY'} placeholder='DD-MMM-YYYY'
                                                        value={unitdata.from_date ? moment(unitdata.from_date) : ''}
                                                        style={{ width: '100%', marginTop: '5px' }}
                                                        allowClear={true}
                                                        onKeyDown={(event) => {
                                                            event.preventDefault()
                                                        }}
                                                        disabledDate={(current) => {
                                                            return current.year() < 2012 || current > Date.now();
                                                        }}
                                                        onChange={(date, dateString) => {
                                                            setListData([])
                                                            if (date !== null) {
                                                                console.log(moment(new Date(dateString)).format('DD-MMM-YYYY'), 'test')
                                                                if (moment(new Date(dateString)).format('DD-MMM-YYYY') > unitdata.to_date) {
                                                                    if (unitdata.from_date !== '') {
                                                                        setUnitData({
                                                                            ...unitdata,
                                                                            from_date: moment(new Date(dateString)).format('DD-MMM-YYYY'),
                                                                            to_date: moment(new Date(dateString)).format('DD-MMM-YYYY')

                                                                        })
                                                                    }
                                                                    else {
                                                                        setUnitData({
                                                                            ...unitdata,
                                                                            from_date: moment(new Date(dateString)).format('DD-MMM-YYYY')
                                                                        })
                                                                    }
                                                                }
                                                                else {
                                                                    setUnitData({
                                                                        ...unitdata,
                                                                        from_date: moment(new Date(dateString)).format('DD-MMM-YYYY')
                                                                    })
                                                                }

                                                            }
                                                            else {
                                                                setUnitData({
                                                                    ...unitdata,
                                                                    from_date: ''
                                                                })
                                                            }
                                                        }}
                                                    />
                                                    {validator.current.message(
                                                        'from_date',
                                                        unitdata.from_date,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'From Date Required',
                                                            }
                                                        })}
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>To Date: </b><span style={{ color: "red" }}>*</span></label>
                                                    <DatePicker
                                                        disabled={unitdata.from_date == '' ? true : false}
                                                        disabledDate={(current) => {
                                                            return moment(unitdata.from_date) >= current || current > Date.now()
                                                        }}
                                                        onKeyDown={(event) => {
                                                            event.preventDefault()
                                                        }}
                                                        className='form-control'
                                                        format={'DD-MMM-YYYY'} placeholder='DD-MMM-YYYY'
                                                        value={unitdata.to_date ? moment(unitdata.to_date) : ''}
                                                        style={{ width: '100%', marginTop: '5px' }}
                                                        allowClear={true}
                                                        onChange={(date, dateString) => {
                                                            setListData([])
                                                            if (date !== null) {
                                                                // disablecondition(dateString)
                                                                setUnitData({
                                                                    ...unitdata,
                                                                    to_date: moment(dateString).format('DD-MMM-YYYY') > moment(unitdata.from_date).format('DD-MMM-YYYY') ? moment(dateString).format('DD-MMM-YYYY') : moment(unitdata.from_date).format('DD-MMM-YYYY')
                                                                })
                                                            }
                                                            else {
                                                                setUnitData({
                                                                    ...unitdata,
                                                                    to_date: ''
                                                                })
                                                            }
                                                        }}
                                                    />
                                                    {validator.current.message(
                                                        'to_date',
                                                        unitdata.to_date,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'To Date Required',
                                                            }
                                                        })}
                                                </div>

                                            </div>
                                        </div>
                                        <div className='col-md-12' style={{ marginTop: "10px" }}>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <label style={{ "fontWeight": "bold" }}>User:</label>
                                                    <Select allowClear={true}
                                                        value={unitdata.user_name || undefined}
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        // onPaste={(e) => {
                                                        //     handlePasting(e, /^[a-z 0-9 -]+$/gi)
                                                        // }}
                                                        onPaste={onPasteToaster}
                                                        searchValue={selectSearchValue.user}
                                                        onSearch={(value) => {
                                                            let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                            setSelectSearchValue({
                                                                ...selectSearchValue,
                                                                user: result
                                                            });
                                                        }}
                                                        onChange={(value, data) => {
                                                            setUnitData({
                                                                ...unitdata,
                                                                user: value != undefined ? value : '',
                                                                user_name: data != undefined ? data.children : '',
                                                                form_name: '',
                                                                form_id: ''
                                                            })
                                                        }}
                                                        placeholder="Enter User"
                                                        style={{ width: '100%', marginTop: '5px' }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                                                    >
                                                        {audittrailfilter && audittrailfilter.audit_users_list && audittrailfilter.audit_users_list.map((item, i) => {
                                                            return (
                                                                <Option key={item.user_id}>
                                                                    {item.user_name}
                                                                </Option>
                                                            )
                                                        })}
                                                    </Select>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label style={{ "fontWeight": "bold" }}>Form Name:</label>
                                                    <Select allowClear={true}
                                                        placeholder='Enter Form Name'
                                                        value={unitdata.form_name || undefined}
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        // onPaste={(e) => {
                                                        //     handlePasting(e, /^[a-z 0-9 -]+$/gi)
                                                        // }}
                                                        onPaste={onPasteToaster}
                                                        searchValue={selectSearchValue.formName}
                                                        onSearch={(value) => {
                                                            let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                                            setSelectSearchValue({
                                                                ...selectSearchValue,
                                                                formName: result
                                                            });
                                                        }}
                                                        onChange={(value, data) => {
                                                            setUnitData({
                                                                ...unitdata,
                                                                form_id: value != undefined ? value : '',
                                                                form_name: data != undefined ? data.children : ''
                                                            })
                                                        }}
                                                        style={{ width: '100%', marginTop: '5px' }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                                                    >
                                                        {formArray && formArray.length > 0 && formArray.map((item, i) => {
                                                            return (
                                                                <Option key={item.form_id}>
                                                                    {item.form_name}
                                                                </Option>
                                                            )
                                                        })}
                                                    </Select>
                                                </div>


                                                <div className='col-md-4'>
                                                    <label style={{ "fontWeight": "bold" }}>Display in Time Zone:</label>
                                                    <Select allowClear={true} placeholder='Enter TimeZone'
                                                        value={unitdata.time_zone_name || undefined}
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        onPaste={onPasteToaster}
                                                        searchValue={selectSearchValue.timezone}
                                                        onSearch={(value) => {
                                                            let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                                            setSelectSearchValue({
                                                                ...selectSearchValue,
                                                                timezone: result
                                                            });
                                                        }}
                                                        onChange={(value, data) => {
                                                            setUnitData({
                                                                ...unitdata,
                                                                time_zone_id: value != undefined ? value : '',
                                                                time_zone_name: data != undefined ? data.children : ''
                                                            })
                                                        }}
                                                        style={{ width: '100%', marginTop: '5px' }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                                                    >
                                                        {audittrailfilter && audittrailfilter.tz_list && audittrailfilter.tz_list.map((item, i) => {
                                                            return (
                                                                <Option key={item.tz_id}>
                                                                    {item.tz_name}
                                                                </Option>
                                                            )
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='text-center popupbtncolour mt-2 mb-1' >
                                            <Button type="primary" shape="round" className={'addbutton me-1 ' + localStorage.getItem('currentTheme')}
                                                style={{ background: "#0096FF", borderColor: "#0096FF" }}
                                                onClick={onshow} icon={<EyeOutlined />}
                                            >
                                                Show
                                            </Button>
                                            <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')}
                                                style={{ background: "#32a852", borderColor: "#32a852" }}
                                                onClick={onexport} icon={<ExportOutlined />}
                                            >
                                                Export
                                            </Button>
                                            {/* <button className="btn btn-secondary" style={{ marginLeft: "59px", marginRight: "10px", width: "100px" }} onClick={onshow}>Show</button>
                                                        <button className="btn btn-success" style={{ width: "100px" }} >Export</button> */}
                                        </div>
                                    </Panel>
                                </Collapse>
                                <Modal
                                    onCancel={setcancelFilter}
                                    footer={false}
                                    title="Audit Trail"
                                    className={
                                        "add-service-prv cons-report " +
                                        localStorage.getItem("currentTheme")
                                    }
                                    open={filterModalVisible}
                                    maskClosable={false}>
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className='col-md-4 text-wrap-report'>
                                                <label style={{ "fontWeight": "bold" }}>Legal Entity: <span style={{ color: "red" }}>*</span></label>
                                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Enter Legal Entity"
                                                            onDrop={(e) => {
                                                                e.preventDefault();
                                                                // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                return false;
                                                            }}
                                                            // onPaste={(e) => {
                                                            //     handlePasting(e, /^[a-z 0-9 ()]+$/gi)
                                                            // }}
                                                            onPaste={onPasteToaster}
                                                            searchValue={selectSearchValue.legalEntity}
                                                            onSearch={(value) => {
                                                                let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                                setSelectSearchValue({
                                                                    ...selectSearchValue,
                                                                    legalEntity: result
                                                                });
                                                            }}
                                                            onChange={(data, value) => {
                                                                // setListData([])
                                                                Setlegalstate(true)
                                                                setCurrentEntity(data != undefined ? data : localStorage.getItem('SelectedEntityid').replaceAll('"', ''))
                                                                setUnitData({
                                                                    ...unitdata,
                                                                    legal_entity_id: data != undefined ? data : '',
                                                                    legal_entity_name: value != undefined ? value.children : '',
                                                                    from_date: '',
                                                                    to_date: '',
                                                                    form_name: '',
                                                                    time_zone_name: '',
                                                                    user_name: ''
                                                                })

                                                                setAddFormSubmit(false)
                                                            }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%', marginTop: '5px' }}
                                                            value={unitdata.legal_entity_name || undefined}
                                                            getPopupContainer={trigger => trigger.parentNode}
                                                        >
                                                            {sessionArr && sessionArr.length > 0 && sessionArr.map((item, i) => {
                                                                return (
                                                                    <Option key={item.le_id}>
                                                                        {item.le_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {validator.current.message(
                                                            'legal_entity_id',
                                                            unitdata.legal_entity_name,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Legal Entity Required',
                                                                }
                                                            })}
                                                    </div> :
                                                    <p>{localStorage.getItem('SelectedEntity')}</p>
                                                }
                                            </div>
                                            <div className='col-md-4'>
                                                {/* value={unitdata.from_date ? moment(unitdata.from_date) : ''} */}
                                                <label><b>From Date:</b> <span style={{ color: "red" }} >*</span></label>
                                                <DatePicker className='form-control' format={'DD-MMM-YYYY'} placeholder='DD-MMM-YYYY'
                                                    value={unitdata.from_date ? moment(unitdata.from_date) : ''}
                                                    style={{ width: '100%', marginTop: '5px' }}
                                                    allowClear={true}
                                                    getPopupContainer={trigger => trigger.parentNode}

                                                    onKeyDown={(event) => {
                                                        event.preventDefault()
                                                    }}
                                                    disabledDate={(current) => {
                                                        return current.year() < 2012 || current > Date.now();
                                                    }}
                                                    onChange={(date, dateString) => {
                                                        // setListData([])
                                                        if (date !== null) {
                                                            console.log(moment(new Date(dateString)).format('DD-MMM-YYYY'), 'test')
                                                            if (moment(new Date(dateString)).format('DD-MMM-YYYY') > unitdata.to_date) {
                                                                if (unitdata.from_date !== '') {
                                                                    setUnitData({
                                                                        ...unitdata,
                                                                        from_date: moment(new Date(dateString)).format('DD-MMM-YYYY'),
                                                                        to_date: moment(new Date(dateString)).format('DD-MMM-YYYY')

                                                                    })
                                                                }
                                                                else {
                                                                    setUnitData({
                                                                        ...unitdata,
                                                                        from_date: moment(new Date(dateString)).format('DD-MMM-YYYY')
                                                                    })
                                                                }
                                                            }
                                                            else {
                                                                setUnitData({
                                                                    ...unitdata,
                                                                    from_date: moment(new Date(dateString)).format('DD-MMM-YYYY')
                                                                })
                                                            }

                                                        }
                                                        else {
                                                            setUnitData({
                                                                ...unitdata,
                                                                from_date: ''
                                                            })
                                                        }
                                                    }}
                                                />
                                                {validator.current.message(
                                                    'from_date',
                                                    unitdata.from_date,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'From Date Required',
                                                        }
                                                    })}
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>To Date: </b><span style={{ color: "red" }}>*</span></label>
                                                <DatePicker
                                                    disabled={unitdata.from_date == '' ? true : false}
                                                    disabledDate={(current) => {
                                                        return moment(unitdata.from_date) >= current || current > Date.now()
                                                    }}
                                                    getPopupContainer={trigger => trigger.parentNode}

                                                    onKeyDown={(event) => {
                                                        event.preventDefault()
                                                    }}
                                                    className='form-control'
                                                    format={'DD-MMM-YYYY'} placeholder='DD-MMM-YYYY'
                                                    value={unitdata.to_date ? moment(unitdata.to_date) : ''}
                                                    style={{ width: '100%', marginTop: '5px' }}
                                                    allowClear={true}
                                                    onChange={(date, dateString) => {
                                                        // setListData([])
                                                        if (date !== null) {
                                                            // disablecondition(dateString)
                                                            setUnitData({
                                                                ...unitdata,
                                                                to_date: moment(dateString).format('DD-MMM-YYYY') > moment(unitdata.from_date).format('DD-MMM-YYYY') ? moment(dateString).format('DD-MMM-YYYY') : moment(unitdata.from_date).format('DD-MMM-YYYY')
                                                            })
                                                        }
                                                        else {
                                                            setUnitData({
                                                                ...unitdata,
                                                                to_date: ''
                                                            })
                                                        }
                                                    }}
                                                />
                                                {validator.current.message(
                                                    'to_date',
                                                    unitdata.to_date,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'To Date Required',
                                                        }
                                                    })}
                                            </div>

                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label style={{ "fontWeight": "bold" }}>User:</label>
                                                <Select allowClear={true}
                                                    value={unitdata.user_name || undefined}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    // onPaste={(e) => {
                                                    //     handlePasting(e, /^[a-z 0-9 -]+$/gi)
                                                    // }}
                                                    onPaste={onPasteToaster}
                                                    searchValue={selectSearchValue.user}
                                                    onSearch={(value) => {
                                                        let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                        setSelectSearchValue({
                                                            ...selectSearchValue,
                                                            user: result
                                                        });
                                                    }}
                                                    onChange={(value, data) => {
                                                        setUnitData({
                                                            ...unitdata,
                                                            user: value != undefined ? value : '',
                                                            user_name: data != undefined ? data.children : '',
                                                            form_name: '',
                                                            form_id: ''

                                                        })
                                                    }}
                                                    placeholder="Enter User"
                                                    style={{ width: '100%', marginTop: '5px' }}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                >
                                                    {audittrailfilter && audittrailfilter.audit_users_list && audittrailfilter.audit_users_list.map((item, i) => {
                                                        return (
                                                            <Option key={item.user_id}>
                                                                {item.user_name}
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                            <div className='col-md-4'>
                                                <label style={{ "fontWeight": "bold" }}>Form Name:</label>
                                                <Select allowClear={true}
                                                    placeholder='Enter Form Name'
                                                    value={unitdata.form_name || undefined}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    // onPaste={(e) => {
                                                    //     handlePasting(e, /^[a-z 0-9 -]+$/gi)
                                                    // }}
                                                    onPaste={onPasteToaster}
                                                    searchValue={selectSearchValue.formName}
                                                    onSearch={(value) => {
                                                        let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                                        setSelectSearchValue({
                                                            ...selectSearchValue,
                                                            formName: result
                                                        });
                                                    }}
                                                    onChange={(value, data) => {
                                                        setUnitData({
                                                            ...unitdata,
                                                            form_id: value != undefined ? value : '',
                                                            form_name: data != undefined ? data.children : ''
                                                        })
                                                    }}
                                                    style={{ width: '100%', marginTop: '5px' }}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                >
                                                    {formArray && formArray.length > 0 && formArray.map((item, i) => {
                                                        return (
                                                            <Option key={item.form_id}>
                                                                {item.form_name}
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>


                                            <div className='col-md-4'>
                                                <label style={{ "fontWeight": "bold" }}>Display in Time Zone:</label>
                                                <Select allowClear={true} placeholder='Enter TimeZone'
                                                    value={unitdata.time_zone_name || undefined}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    onPaste={onPasteToaster}
                                                    searchValue={selectSearchValue.timezone}
                                                    onSearch={(value) => {
                                                        let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                                        setSelectSearchValue({
                                                            ...selectSearchValue,
                                                            timezone: result
                                                        });
                                                    }}
                                                    onChange={(value, data) => {
                                                        setUnitData({
                                                            ...unitdata,
                                                            time_zone_id: value != undefined ? value : '',
                                                            time_zone_name: data != undefined ? data.children : ''
                                                        })
                                                    }}
                                                    style={{ width: '100%', marginTop: '5px' }}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                >
                                                    {audittrailfilter && audittrailfilter.tz_list && audittrailfilter.tz_list.map((item, i) => {
                                                        return (
                                                            <Option key={item.tz_id}>
                                                                {item.tz_name}
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='text-center popupbtncolour mt-2' >
                                        <Button type="primary" shape="round" className={'addbutton me-1 ' + localStorage.getItem('currentTheme')}
                                            style={{ background: "#0096FF", borderColor: "#0096FF" }}
                                            onClick={onshow} icon={<EyeOutlined />}
                                        >
                                            Show
                                        </Button>
                                        <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')}
                                            style={{ background: "#32a852", borderColor: "#32a852" }}
                                            onClick={onexport} icon={<ExportOutlined />}
                                        >
                                            Export
                                        </Button>
                                        {/* <button className="btn btn-secondary" style={{ marginLeft: "59px", marginRight: "10px", width: "100px" }} onClick={onshow}>Show</button>
                                                        <button className="btn btn-success" style={{ width: "100px" }} >Export</button> */}
                                    </div>
                                </Modal>

                                {tableshow == true ?
                                    <Card className='rounded-3'>

                                        <div className="col-md-12 mt-0 mb-2 filter-detial-sticky extraFilter">
                                            <div className="row ml-2">
                                                <div className="col-md-3 text-wrap-report">
                                                    <label><b>Legal Entity :</b> {unitdata.legalEntityLabel}</label>
                                                </div>
                                                <div className="col-md-3">
                                                    <label><b>From Date :</b> {unitdata.fromLabel}</label>

                                                </div>
                                                <div className="col-md-3">
                                                    <label><b>To Date :</b> {unitdata.toLabel}</label>

                                                </div>
                                                <div className="col-md-3">
                                                    <label><b>Time Zone :</b> {unitdata.timeZoneLabel}</label>

                                                </div>
                                            </div>
                                        </div>

                                        <div className='ant-table-wrapper'>
                                            <div className='ant-table ant-table-bordered ant-table-fixed-header'>
                                                <div className='ant-table-container'>
                                                    <div className='ant-table-content audittrailtable'>
                                                        <table>
                                                            <colgroup>
                                                                <col style={{ width: '5px' }}></col>
                                                            </colgroup>
                                                            <thead className="ant-table-thead table-head-stick table-head-stick-extra">
                                                                <tr>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '2%', height: '1%' }}> # </th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '20px' }}>User</th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '60px' }}>Date & Time</th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '70px' }}>Form Name</th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '300px' }}><center>Action</center></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className='ant-table-tbody'>
                                                                {listData && listData.length > 0 ? listData.map((itemdata, i) => {
                                                                    let data = _.find(audittrailfilter.audit_forms_list, { form_id: itemdata.form_id })
                                                                    return <Fragment>
                                                                        <tr>
                                                                            <td className='ant-table-cell'><center>{count + 1}</center></td>
                                                                            <td className='ant-table-cell'>{itemdata.user_name}</td>
                                                                            <td className='ant-table-cell'><center>{itemdata.created_on}</center></td>
                                                                            <td className='ant-table-cell'>{data && data.form_name ? data.form_name : ''}</td>
                                                                            <td className='ant-table-cell'>{itemdata.action}</td>
                                                                            <span hidden>{count = count + 1}</span>
                                                                        </tr>
                                                                    </Fragment>
                                                                }) :
                                                                    <tr>
                                                                        <td colSpan="100%" style={{ paddingTop: '15px', textAlign: 'center', fontSize: '14px', fontWeight: 'bold', paddingBottom: '15px' }}>No Records Found</td>
                                                                    </tr>
                                                                }
                                                            </tbody>
                                                        </table>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row mt-3'>
                                            {count != 0 ?
                                                <>
                                                    <div className='col-md-6'>
                                                        <label>Showing {pageSize * (current - 1) + 1} to {count} of {Number(totalcount)} entries</label>
                                                    </div>
                                                    <div className='col-md-6 text-right'>
                                                        <Pagination
                                                            current={current}
                                                            showSizeChanger={true}
                                                            onShowSizeChange={() => {
                                                                setCurrent(1)
                                                            }}
                                                            pageSizeOptions={[25, 50, 100]} hideOnSinglePage={Number(totalcount) > 25 ? false : true} defaultPageSize={25}
                                                            onChange={(page, pageSizes) => {
                                                                console.log(pageSizes, 'pageSizespageSizes');
                                                                setpageState(true)
                                                                setCurrent(pageSize !== pageSizes ? 1 : page);
                                                                setPageSize(pageSizes)
                                                            }} total={Number(totalcount)}
                                                        />
                                                    </div></> : ''}
                                        </div>
                                    </Card> : ''

                                    // <h4 style={{ textAlign: 'center', marginTop: '5%' }}>No Records Found</h4>
                                }
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </>

    )
}
export default connect(mapStateToProps, {
    AuditTrailReportFilters,
    AuditTrailReportData,
    ExportAuditTrailReportData
})(AuditTrail);