import React from 'react';

const Stickicon = () => {


  return (
    <div className="p-4 py-2 mx-2 mb-0 rounded user-mapping bg-white">
      <div className="d-flex justify-content-between">
        <div></div>
        <div>
          <i style={{ color: "red" }} className="bi bi-square-fill"></i>{" "}
          <label>One Time</label>
        </div>
        <div>
          <i style={{ color: "green" }} className="bi bi-square-fill"></i>{" "}
          <label>Periodical</label>
        </div>
        <div>
          <i style={{ color: "orange" }} className="bi bi-square-fill"></i>{" "}
          <label>Review</label>
        </div>
        <div>
          <i style={{ color: "pink" }} className="bi bi-square-fill"></i>{" "}
          <label>Flexi Review</label>
        </div>
        <div>
          <i style={{ color: "yellow" }} className="bi bi-square-fill"></i>{" "}
          <label>On Occurrence</label>
        </div>
        <div></div>
      </div>
    </div>

    //   <div className="sticky-icon">
    //   <a href="#" className="Instagram"><i className="fab fa" ><b>O</b></i><b> One Time </b></a>
    //   <a href="#" className="Facebook"><i className="fab fa" ><b>P</b> </i><b> Periodical </b> </a>
    //   <a href="#" className="Google"><i className="fab fa" ><b>R</b> </i><b> Review  </b></a>
    //   <a href="#" className="Youtube"><i className="fab fa" ><b>F</b></i><b> Flexi Review  </b></a>
    //   <a href="#" className="Twitter"><i className="fab fa"><b>O</b> </i><b> On Occurrence</b> </a>
    // </div>
  );
}

export default Stickicon;
{/* <div className="card p-4 py-2 mb-0 rounded user-mapping1 bg-white">
          <div className="d-flex justify-content-between">
            <div></div>
            <div>
              <i style={{ color: "red" }} className="bi bi-square-fill"></i>{" "}
              <label>One time</label>
            </div>
            <div>
              <i style={{ color: "green" }} className="bi bi-square-fill"></i>{" "}
              <label>Periodical</label>
            </div>
            <div>
              <i style={{ color: "orange" }} className="bi bi-square-fill"></i>{" "}
              <label>Review</label>
            </div>
            <div>
              <i style={{ color: "pink" }} className="bi bi-square-fill"></i>{" "}
              <label>Flexi Review</label>
            </div>
            <div>
              <i style={{ color: "yellow" }} className="bi bi-square-fill"></i>{" "}
              <label>On Occurrence</label>
            </div>
            <div></div>
          </div>
        </div> */}