import React from 'react'
import { Fragment, useState, useEffect, useRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useHistory } from 'react-router-dom'
import { Modal, Input, Card, Collapse, Button, Table, Tooltip } from 'antd';
import _, { entries, forIn, indexOf } from 'lodash';
import DataTable from "react-data-table-component";
import { UserAddOutlined, EditOutlined, ArrowLeftOutlined, PlayCircleOutlined, UpOutlined, EyeOutlined, DownOutlined, FullscreenOutlined, FilterOutlined } from '@ant-design/icons';
import { settingsfilterlist, settingslist, addsettings } from './../../Store/Action/Transcations/Criticalitysettings'
import { Select } from 'antd';
import SimpleReactValidator from "simple-react-validator";
import { Toaster } from '../../Libs/Toaster'
import { toast } from 'react-toastify';
import ScrollToTop from "react-scroll-to-top";
import { fullscreenmode, fullscreenexit, handleKeyDown } from "./../../Libs/fullscreenmode";
import ScrollButton from '../Scrollbar';
import Stickicon from "./Stickyicon";
import { HomeRedirect } from './../../Libs/country'
const homepage = HomeRedirect()

const { Option } = Select;
const mapStateToProps = (state) => ({
    filterdatas: state.CriticalitySettings.criticalitysettings.filterdata,
    lists: state.CriticalitySettings.criticalitysettings.list
});

const CriticalitySetting = ({
    settingsfilterlist,
    settingslist,
    filterdatas,
    lists,
    addsettings

}) => {

    const historynew = useHistory();
    const [show, setShow] = useState(false)
    const [selectvalue, setSelectValue] = useState("")
    const [collapseSelectValue, setCollapseSelectValue] = useState([])
    console.log(collapseSelectValue, 'collapseSelectValue');
    const [indexvalues, setIndexValues] = useState(undefined)
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const validator = useRef(new SimpleReactValidator());
    const formValidator = useRef(new SimpleReactValidator());
    const { Panel } = Collapse;
    const { Search } = Input;
    const location = useLocation();
    const is_Fullscreen = useSelector((state) => state.Login.is_fullscreen);
    const dispatch = useDispatch();
    const paramid = location.pathname;
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const SelectedEntity = localStorage.getItem('SelectedEntity')
    // const entityid = localStorage.getItem('SelectedEntityid');
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const [remarks, setRemarks] = useState([])
    const [key, setKey] = useState("1")
    const sessionArr = sessionParsedValue.entity_info
    const [Settings, SetSettings] = useState({
        le_id: '',
        domain_id: '',
        statutory_nature: 'All',
        sm_record: '',
        bg_id: ''

    })
    const [criticalitylist, setCriticalityList] = useState([])
    const [legal, setLegal] = useState(false)
    const [compid, setCompId] = useState([])
    const [finallegalEntity, setFinallegalentity] = useState()
    const [statutorydata, setStatutorydata] = useState([])
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [remarksFormSubmit, setRemarksFormSubmit] = useState(false)
    const [category, setCategory] = useState([])
    const [titledata, Settitledata] = useState({
        displauvalue: ''
    })
    const [tempState, setTempState] = useState([])
    const [collapseindex, setCollapseIndex] = useState([])
    console.log(collapseindex, 'collapseindex');
    const [listChild, setListChild] = useState([])
    const [updateRemarks, setUpdateRemarks] = useState([])
    const [updateSelectValue, setUpdateSelectValue] = useState([])
    console.log(updateSelectValue, 'updateSelectValue')
    const [collapseChange, setCollapseChange] = useState('')
    console.log(collapseChange, 'collapseChange');
    const [count, setCount] = useState()
    const [entires, setEntries] = useState(0)
    const [data, setData] = useState();
    const [finalArray, setFinalArray] = useState([])
    console.log(finalArray, "finalArray");
    const [showMore, setShowMore] = useState(false)
    const [arrayLength, setArrayLength] = useState()
    const [totalValue, setTotalValue] = useState(0)
    const [childIndex, setChildIndex] = useState()
    const [businessGroup, setBusinessGroup] = useState({
        bg_id: '',
        bg_name: ''
    })
    const [editshow, seteditshow] = useState(false);
    const [listshow, setlistshow] = useState(true);
    const [checkdata, setCheckdata] = useState([])
    const [lastIndex, setLastIndex] = useState(0);
    console.log(lastIndex, "lastIndex");
    const [lastChildIndex, setLastChildIndex] = useState(0);
    const prevCountRef = useRef();
    const prevCountRefChild = useRef();
    const [tempStaticArray, setTempStaticArray] = useState([])
    const [filterTaskAcc, setFilterTaskAcc] = useState(true);
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const [exitCollapse, setExitCollapse] = useState(false);
    const [bggroup, setBggroup] = useState([])
    console.log(bggroup,'bggroup')
    useEffect(() => {
        if (sessionArr && sessionArr.length > 0) {
          let unique = _.uniqBy(sessionArr, function (e) {
            return e.bg_id;
          });
          setBggroup(unique)
        }
      }, [])
    //  Full screen mode
    const [fullscreen, setfullscreen] = useState(false);

    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange',() => handleKeyDown(dispatch), false);
            document.addEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch), false);
            return () => {
                document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch));
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode(dispatch);
            setfullscreen(true);
        } else {
            fullscreenexit(dispatch);
            setfullscreen(false);
        }
    }
    const handlePasting = (e, regex) => {
        var regex = regex;
        var key = e.clipboardData.getData('text')
        if (!regex.test(key)) {
            e.preventDefault();
            Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
            return false;
        }
    }

    const filterHandle = () => {
        setFilterTaskAcc(true);
        setFilterModalVisible(true);
    };
    const setcancelFilter = () => {
        setFilterModalVisible(false);
        setFilterTaskAcc(false);
    };
    useEffect(() => {
        if (localStorage.getItem('SelectedEntityid') != null) {
            setCurrentEntity(localStorage.getItem('SelectedEntityid'))
        }
    }, [localStorage.getItem('SelectedEntityid')])

    useEffect(() => {
        if (SelectedEntity != 'All Legal Entity') {
            let businessName = _.filter(sessionArr, { le_name: SelectedEntity })
            setBusinessGroup({
                ...businessGroup,
                bg_id: businessName && businessName.length > 0 && businessName[0].bg_id,
                bg_name: businessName && businessName.length > 0 && businessName[0].bg_name
            })
        }
    }, [SelectedEntity])
    const previous = () => {
        seteditshow(false);
        setlistshow(true);
    }

    const [hidden, setHidden] = useState(true)
    const [gettemp, setGetTemp] = useState([]);
    const hiddenfunction = (criticality, compliance_id, current) => {
        if (criticality == current) {
            gettemp[compliance_id] = true
        } else {
            gettemp[compliance_id] = false
        }
        setGetTemp([...gettemp])
    }

    const columns = [
        {
            title: '#',
            dataIndex: false,
            align: 'center',
            width: '17px',
            render: (text, record, index) => {
                console.log(record,"recodredddddd");
                return <div>{record.is_new ?
                    <span style={{ color: '#0000FF' }}>{record.childId + 1}
                        {record.frequency == 'Review' ? <p><i style={{ color: "orange" }} title={record.frequency} className="bi bi-square-fill"></i></p> :
                            record.frequency == 'Periodical' ? <p><i style={{ color: "green" }} title={record.frequency} className="bi bi-square-fill"></i></p> :
                                record.frequency == 'Flexi Review' ? <p><i style={{ color: "pink" }} title={record.frequency} className="bi bi-square-fill"></i></p> :
                                    record.frequency == 'On Occurrence' ? <p><i style={{ color: "yellow" }} title={record.frequency} className="bi bi-square-fill"></i></p> :
                                        <p><i style={{ color: "red" }} title={record.frequency} className="bi bi-square-fill"></i></p>
                        }</span>
                    :
                    <span>{record.childId + 1}
                        {record.frequency == 'Review' ? <p><i style={{ color: "orange" }} title={record.frequency} className="bi bi-square-fill"></i></p> :
                            record.frequency == 'Periodical' ? <p><i style={{ color: "green" }} title={record.frequency} className="bi bi-square-fill"></i></p> :
                                record.frequency == 'Flexi Review' ? <p><i style={{ color: "pink" }} title={record.frequency} className="bi bi-square-fill"></i></p> :
                                    record.frequency == 'On Occurrence' ? <p><i style={{ color: "yellow" }} title={record.frequency} className="bi bi-square-fill"></i></p> :
                                        <p><i style={{ color: "red" }} title={record.frequency} className="bi bi-square-fill"></i></p>
                        }</span>
                }
                </div>

            },
            filters: [
                {
                    text: <span><i style={{ color: "green" }} className="bi bi-square-fill"></i> Periodical</span>,
                    value: 'Periodical',
                },
                {
                    text: <span><i style={{ color: "pink" }} className="bi bi-square-fill"></i> Flexi Review</span>,
                    value: 'Flexi Review',
                },
                {
                    text: <span><i style={{ color: "orange" }} className="bi bi-square-fill"></i> Review</span>,
                    value: 'Review',
                },
                {
                    text: <span><i style={{ color: "yellow" }} className="bi bi-square-fill"></i> On Occurrence</span>,
                    value: 'On Occurrence',
                },
                {
                    text: <span><i style={{ color: "red" }} className="bi bi-square-fill"></i> One Time</span>,
                    value: 'One Time',
                },
            ],
            onFilter: (value, record) => record.frequency == value,
        },
        {
            title: "Statutory Provision",
            // ellipsis: true,
            width: '150px',
            dataIndex: false,
            key: false,
            render: (record) => {
                if (record.is_new) {
                    return <span style={{ color: '#0000FF' }}>{record.statutory_provision}</span>
                } else {
                    return record.statutory_provision
                }
            }
        },
        {
            title: "Compliance Task",
            // ellipsis: true,
            // selector: (row) => row.compliance_task,
            width: '160px',
            dataIndex: false,
            key: false,
            // ellipsis: true,
            render: (record) => {
                if (record.is_new) {
                    return <span style={{ color: '#0000FF' }}>{record.compliance_task}</span>
                } else {
                    return <span>{record.compliance_task}</span>
                }
            }
        },
        {
            title: "Penal Consequences",
            width: '180px',
            // ellipsis: true,
            dataIndex: false,
            key: false,
            render: (record) => {
                if (record.is_new) {
                    return <span style={{ color: '#0000FF' }}>{record.penal_consequences}</span>
                } else {
                    return record.penal_consequences
                }
            }
        },
        {
            title: "Applicable/ Total Units",
            width: '50px',
            dataIndex: false,
            key: false,
            align: 'center',
            render: (record) => {
                if (record.is_new) {
                    return <span style={{ color: '#0000FF' }}>{record.unit_id}</span>
                } else {
                    return record.unit_id
                }
            }
        },
        {
            title: <span title='Criticality-Knowledge'><UserAddOutlined /></span>,
            sortable: true,
            width: '20px',
            align: 'center',
            dataIndex: false,
            key: false,
            render: (record) => {
                if (record.is_new) {
                    return <span style={{ color: '#0000FF' }}>{record.criticality}</span>
                } else {
                    return record.criticality
                }
            }

        },
        {
            title: <span title='Criticality-Client'><EditOutlined /></span>,
            sortable: true,
            width: '5%',
            // fixed: 'right',
            render: (record, rowIndex) => {

                return (
                    <>
                        <Select style={{ width: '100%', height: "35px" }}
                            value={updateSelectValue[record.compliance_id]}
                            key={updateSelectValue[record.compliance_id]}
                            onChange={(e, i) => {
                                let data = _.find(finalArray, { index: record.parentId })
                                console.log(data, 'dataAV');
                                if (data && data.child.length == 1) {
                                    setCollapseChange('collapse')
                                    collapseSelectValue[record.parentId] = e
                                    setCollapseSelectValue([...collapseSelectValue])
                                    let temp = []
                                    temp.push(record.parentId)
                                    const uniqueChars = [...new Set([...collapseindex, ...temp])];
                                    setCollapseIndex(uniqueChars)
                                    setSelectValue(e)

                                }
                                if (record.compliance_id in updateSelectValue) {
                                    let index = updateSelectValue.indexOf(record)
                                    updateSelectValue[record.compliance_id] = [];
                                    if (index == -1) {
                                        updateSelectValue[record.compliance_id] = e;
                                    }
                                } else {
                                    updateSelectValue[record.compliance_id] = [];
                                    updateSelectValue[record.compliance_id] = e
                                }
                                setUpdateSelectValue([...updateSelectValue])
                                let tempArray = []
                                tempState && tempState.length > 0 && tempState.map((item, i) => {
                                    if (item.compliance_id === record.compliance_id) {
                                        tempState.splice(i, 1)
                                        setTempState([...tempState, ...tempArray])
                                    }
                                })
                                tempArray.push({
                                    "compliance_id": record.compliance_id,
                                    "criticality_id": updateSelectValue[record.compliance_id] === 'A' ? 1 : updateSelectValue[record.compliance_id] === 'B' ? 2 : 3,
                                    "remarks": record.criticality === updateSelectValue[record.compliance_id] ? '' : updateRemarks[record.compliance_id]
                                })
                                setTempState([...tempState, ...tempArray])
                                if (data && data.child.length != 1) {
                                    setCollapseChange('')
                                }
                            }}>
                            {record.is_new == true ?
                                <Option key="">Select</Option>
                                : ''}
                            {lists.criticality_list.map((item, i) => {
                                return (
                                    <Option key={item.criticality_name[0]}>
                                        {item.criticality_name[0]}
                                    </Option>
                                );
                            })}


                        </Select>
                    </>
                )

            },
            dataIndex: false,
            key: false,
        },
        {
            title: "Remarks",
            sortable: true,
            width: '100px',
            // fixed: 'right',
            render: (record, rowIndex) => {
                return (

                    <div>
                        {record.is_new == false ?
                            <>
                                <textarea name='remarks' style={{ width: "100%" }} className="form-control1 " placeholder='Enter Remarks' autoComplete={'off'}
                                    hidden={record.criticality === updateSelectValue[record.compliance_id]} //record.criticality === updateSelectValue[record.compliance_id] ? true : false
                                    defaultValue={updateRemarks[record.compliance_id]}
                                    key={updateRemarks[record.compliance_id]}
                                    onKeyPress={(event) => {
                                        if (!  /^[A-Z0-9a-z ` : ; ' " < > ? / ! @  # $ % & * ( + = )}{[ \]_ .,-]+$/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    maxLength='500'
                                    disabled={collapseChange === 'collapse' ? true : false}
                                    onPaste={(e) => {
                                        var regex = /^[A-Z0-9a-z ` : ; ' " < > ? / ! @  # $ % & * ( + = )}{[ \]_ .,-]+$/;
                                        var key = e.clipboardData.getData('text')
                                        if (!regex.test(key)) {
                                            Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
                                        }
                                        let temp = ''
                                        for (let i = 0; i < key.length; i++) {
                                            let a = key[i].replace(/^[~ ^ |]+$/, '');
                                            if (a) {
                                                temp += a
                                            }
                                            updateRemarks[record.compliance_id] = temp
                                        setUpdateRemarks([...updateRemarks])
                                        }
                                        
                                    }}
                                    onBlur={(e, i) => {
                                        let temp1 = []
                                        temp1.push(e.target.value)
                                        if (record.compliance_id in updateRemarks) {
                                            let index = updateRemarks.indexOf(record)
                                            updateRemarks[record.compliance_id] = [];
                                            if (index == -1) {
                                                updateRemarks[record.compliance_id] = e.target.value;
                                            }
                                        } else {
                                            updateRemarks[record.compliance_id] = [];
                                            updateRemarks[record.compliance_id] = e.target.value
                                        }
                                        setUpdateRemarks([...updateRemarks])
                                        let tempArray = []
                                        tempState && tempState.length > 0 && tempState.map((item, i) => {
                                            if (item.compliance_id === record.compliance_id) {
                                                tempState.splice(i, 1)
                                                setTempState([...tempState, ...tempArray])
                                            }
                                        })
                                        tempArray.push({
                                            "compliance_id": record.compliance_id,
                                            "criticality_id": updateSelectValue[record.compliance_id] === 'A' ? 1 : updateSelectValue[record.compliance_id] === 'B' ? 2 : 3,
                                            "remarks": record.criticality === updateSelectValue[record.compliance_id] ? '' : updateRemarks[record.compliance_id]
                                        })
                                        setTempState([...tempState, ...tempArray])

                                    }}
                                >
                                </textarea>
                                {((record.criticality != updateSelectValue[record.compliance_id])) ?
                                    formValidator.current.message(
                                        'remarks',
                                        updateRemarks[record.compliance_id],
                                        ['required', { regex:  /^[A-Z0-9a-z ` : ; ' " < > ? / ! @  # $ % & * ( + = )}{[ \]_ .,-]+$/ }],
                                        {
                                            className: `invalid-feedback ${remarksFormSubmit ? 'show' : 'hide'}`,
                                            messages: {
                                                required: 'Remarks Required',
                                                regex: 'Enter Valid Remarks'

                                            }
                                        }) : ''}
                            </>

                            : record.is_new == true && updateSelectValue[record.compliance_id] ?
                                <>
                                    <textarea name='remarks' style={{ width: "100%" }} className="form-control1 " placeholder='Enter Remarks' autoComplete={'off'}
                                        hidden={record.criticality === updateSelectValue[record.compliance_id]} //record.criticality === updateSelectValue[record.compliance_id] ? true : false
                                        defaultValue={updateRemarks[record.compliance_id]}
                                        key={updateRemarks[record.compliance_id]}
                                        maxLength='500'
                                        disabled={collapseChange === 'collapse' ? true : false}
                                        onKeyPress={(event) => {
                                            if (! /^[A-Z0-9a-z ` : ; ' " < > ? / ! @  # $ % & * ( + = )}{[ \]_ .,-]+$/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        onPaste={(e) => {
                                            var regex = /^[A-Z0-9a-z ` : ; ' " < > ? / ! @  # $ % & * ( + = )}{[ \]_ .,-]+$/;
                                            var key = e.clipboardData.getData('text')
                                            if (!regex.test(key)) {
                                                Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
                                            }
                                            let temp = ''
                                            for (let i = 0; i < key.length; i++) {
                                                let a = key[i].replace(/^[~ ^ |]+$/, '');
                                                if (a) {
                                                    temp += a
                                                }
                                                updateRemarks[record.compliance_id] = temp
                                            setUpdateRemarks([...updateRemarks])
                                            }
                                            
                                        }}
                                        onBlur={(e, i) => {
                                            let temp1 = []
                                            temp1.push(e.target.value)
                                            if (record.compliance_id in updateRemarks) {
                                                let index = updateRemarks.indexOf(record)
                                                updateRemarks[record.compliance_id] = [];
                                                if (index == -1) {
                                                    updateRemarks[record.compliance_id] = e.target.value;
                                                }
                                            } else {
                                                updateRemarks[record.compliance_id] = [];
                                                updateRemarks[record.compliance_id] = e.target.value
                                            }
                                            setUpdateRemarks([...updateRemarks])
                                            let tempArray = []
                                            tempState && tempState.length > 0 && tempState.map((item, i) => {
                                                if (item.compliance_id === record.compliance_id) {
                                                    tempState.splice(i, 1)
                                                    setTempState([...tempState, ...tempArray])
                                                }
                                            })
                                            tempArray.push({
                                                "compliance_id": record.compliance_id,
                                                "criticality_id": updateSelectValue[record.compliance_id] === 'A' ? 1 : updateSelectValue[record.compliance_id] === 'B' ? 2 : 3,
                                                "remarks": record.criticality === updateSelectValue[record.compliance_id] ? '' : updateRemarks[record.compliance_id]
                                            })
                                            setTempState([...tempState, ...tempArray])

                                        }}
                                    >
                                    </textarea>
                                    {((record.criticality != updateSelectValue[record.compliance_id])) ?
                                        formValidator.current.message(
                                            'remarks',
                                            updateRemarks[record.compliance_id],
                                            ['required', { regex:  /^[A-Z0-9a-z ` : ; ' " < > ? / ! @  # $ % & * ( + = )}{[ \]_ .,-]+$/ }],
                                            {
                                                className: `invalid-feedback ${remarksFormSubmit ? 'show' : 'hide'}`,
                                                messages: {
                                                    required: 'Remarks Required',
                                                    regex: 'Enter Valid Remarks'

                                                }
                                            }) : ''}
                                </> : ''}



                    </div>
                )
            },
            dataIndex: false,
            key: false
        },
    ];

    console.log(finalArray, 'listslistslists');
    const arr = [];
    let statelist = [];
    const handleOk = () => {
        setLegal(false)
    };
    const legalmodel = () => {
        setLegal(true)
    }
    const handleCancel = () => {
        setLegal(false)
    };

    const onChange = (key) => {
        console.log(key);
    };

    const domainchange = (e) => {
        if (filterdatas.nature_list.length > 0) {
            statelist = _.filter(filterdatas.nature_list, { domain_id: e });
            SetSettings({
                ...Settings,
                domain_id: e,
                statutory_nature: statelist
            })
        }
    }
    useEffect(() => {
        if (finalArray && finalArray.length > 0) {
            for (let i in finalArray) {
                collapseSelectValue[i] = 'Select'
            }
            setCollapseSelectValue([...collapseSelectValue])
        }
    }, [finalArray])

    const showresult = (e) => {
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            setExitCollapse(true);
            setFilterTaskAcc(false);

            setFilterModalVisible(false);
            setCheckdata([])
            setArrayLength()

            setTotalValue(0)
            Settitledata({
                ...titledata,
                displauvalue: []
            })
            setFinalArray([])
            if (validator.current.allValid() && isAuth) {
                setKey(1)
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetCriticalitySettings",
                            {
                                "le_id": entityid,
                                "domain_id": Settings.domain_id,
                                "statutory_nature": Settings.statutory_nature ? Settings.statutory_nature : "All",
                                "sm_record": 0
                            }
                        ]
                    }
                ]
                settingslist({
                    payload: payload,
                    paramid: paramid
                })
                setShow(true)
            }
        }
    }

    const showMoreresult = (e) => {
        setAddFormSubmit(true)
        setShowMore(true)
        if (validator.current.allValid() && isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetCriticalitySettings",
                        {
                            "le_id": entityid,
                            "domain_id": Settings.domain_id,
                            "statutory_nature": Settings.statutory_nature,
                            "sm_record": checkdata.length
                        }
                    ]
                }
            ]
            settingslist({
                payload: payload,
                paramid: paramid
            })
            setShow(true)
        }
    }

    console.log(updateSelectValue, 'updateSelectValue');
    const addform = () => {
        let temp = []
        for (let i in finalArray) {
            for (let j in finalArray[i].child) {
                console.log(finalArray[i].child[j].criticality, 'finalArray[i].child[j].criticality');
                console.log(updateSelectValue[finalArray[i].child[j].compliance_id], 'updateSelectValue[finalArray[i].child[j].childId');
                console.log(finalArray[i].child[j].criticality != updateSelectValue[finalArray[i].child[j].compliance_id], 'finalArray[i].child[j].criticality != updateSelectValue[finalArray[i].child[j].compliance_id]');
                if (finalArray[i].child[j].criticality != updateSelectValue[finalArray[i].child[j].compliance_id]) {
                    console.log(finalArray[i].child[j].criticality != updateSelectValue[finalArray[i].child[j].compliance_id], 'aishuu99999');
                    if (updateRemarks[finalArray[i].child[j].compliance_id] == '') {
                        console.log(updateRemarks[finalArray[i].child[j].compliance_id] == '', 'aishuuu55555');
                        temp.push(true)

                    }
                    else {
                        temp.push(false)
                    }
                    setRemarks(temp)
                }

            }
        }

        setRemarksFormSubmit(true)
        console.log(formValidator.current.allValid(), 'formValidator.current.allValid()');
    }
    console.log(remarks, 'remarks888888');
    useEffect(() => {
        if (entityid !== '') {
            if (remarks.length > 0) {
                if (remarks.includes(true) == false) {
                    console.log(formValidator.current.allValid(), 'formValidator.current.allValid()');
                    if (formValidator.current.allValid()) {
                        if (criticalitylist && criticalitylist.length > 0) {


                            const pay = [
                                authtoken,
                                {
                                    "session_token": authtoken,
                                    "request": [
                                        "SaveCriticalitySettings",
                                        {
                                            "le_id": entityid,
                                            "criticality_list": criticalitylist
                                        }
                                    ]
                                }
                            ]
                            addsettings({
                                payload: pay,
                                paramid: paramid
                            })
                        }
                        else {
                            toast.error('No Changes Done to Submit')
                         }
                    }

                }
                else {
                    toast.error('Enter Remarks')
                }
            }
        }
    }, [remarks])

    const genextra = (index, List) => {
        return <> <div onClick={(event) => {
            // If you don't want click extra trigger collapse, you can prevent this:
            event.stopPropagation();
        }} className="row">
            <div className={collapseindex && collapseindex.includes(List.index) ? 'col-md-6' : 'col-md-12'}>
                <div className="form-group">
                    <Select style={{ width: '100px', height: "100%", textAlign: 'left' }}
                        defaultValue={collapseSelectValue[index]}
                        key={collapseSelectValue[index]}
                        onChange={(e, i) => {
                            collapseSelectValue[index] = e
                            setCollapseSelectValue([...collapseSelectValue])
                            setSelectValue(e)
                            setListChild(List.child)
                            for (let i in List.child) {
                                if (List.child[i].compliance_id in updateSelectValue) {
                                    let index = updateSelectValue.indexOf(List.child[i].compliance_id)
                                    updateSelectValue[List.child[i].compliance_id] = [];
                                    if (index == -1) {
                                        updateSelectValue[List.child[i].compliance_id] = e;
                                    }
                                } else {
                                    updateSelectValue[List.child[i].compliance_id] = [];
                                    updateSelectValue[List.child[i].compliance_id] = e
                                }
                                setUpdateSelectValue([...updateSelectValue])
                            }
                            let temp = []
                            temp.push(index)
                            const uniqueChars = [...new Set([...collapseindex, ...temp])];
                            setCollapseIndex(uniqueChars)
                            setCollapseChange('collapse')
                        }}

                    >
                        <Option key="">Select</Option>
                        {lists.criticality_list.map((item, i) => {
                            return (
                                <>
                                    <Option key={item.criticality_name[0]}>
                                        {item.criticality_name[0]}
                                    </Option>
                                </>
                            );
                        })}
                    </Select>
                </div>
            </div>
            {collapseindex && collapseindex.includes(List.index) && selectvalue != "" ?
                <div className="col-md-6">
                    <div className="form-group">
                        <Input name='remarks' style={{ width: "100%" }} className="form-control" placeholder='Enter Remarks' autoComplete={'off'}
                            hidden={((List.child && List.child.length == 1) && (List.child[0].criticality == collapseSelectValue[index]))}
                            onKeyPress={(event) => {
                                if (! /^[A-Z0-9a-z ` : ; ' " < > ? / ! @  # $ % & * ( + = )}{[ \]_ .,-]+$/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            maxLength='500'
                            onChange={(e, i) => {
                                let tempArray = []
                                setListChild(List.child)
                                for (let i in List.child) {
                                    if (List.child[i].compliance_id in updateRemarks) {
                                        let index = updateRemarks.indexOf(List.child[i].compliance_id)
                                        updateRemarks[List.child[i].compliance_id] = [];
                                        if (index == -1) {
                                            updateRemarks[List.child[i].compliance_id] = e.target.value;
                                        }
                                    } else {
                                        updateRemarks[List.child[i].compliance_id] = [];
                                        updateRemarks[List.child[i].compliance_id] = e.target.value
                                    }
                                    setUpdateRemarks([...updateRemarks])
                                }
                            }}
                        >
                        </Input>
                    </div>
                </div> : ''}

        </div>
        </>
    }

    // const getData = () => {
    //     let countrylisttempdataNew = [];
    //     let arrNew = [];
    //     lists.criticality_statu.map(function (item) {
    //         if ((arrNew.indexOf(item.statutory_name) < 0))
    //             arrNew.push(item.statutory_name);
    //     });

    //     arrNew.map((pmenu, index) => {
    //         let entityArrayNew = [];
    //         let tempArraryNew = [];
    //         if (pmenu) {
    //             lists.criticality_statu.map((subMenuList, childIndex) => {
    //                 if (pmenu === subMenuList.statutory_name) {
    //                     entityArrayNew = {
    //                         'compliance_id': subMenuList.compliance_id,
    //                         'compliance_task': subMenuList.compliance_task,
    //                         'criticality': subMenuList.criticality,
    //                         'criticality_id': subMenuList.criticality_id,
    //                         'frequency': subMenuList.frequency,
    //                         'is_new': subMenuList.is_new,
    //                         'penal_consequences': subMenuList.penal_consequences,
    //                         'remarks': subMenuList.remarks,
    //                         'statutory_id': subMenuList.statutory_id,
    //                         'statutory_mapping': subMenuList.statutory_mapping,
    //                         'statutory_name': subMenuList.statutory_name,
    //                         'statutory_provision': subMenuList.statutory_provision,
    //                         'unit_id': subMenuList.unit_id,
    //                         'parentId': index,
    //                         'childId': childIndex,
    //                     }
    //                     tempArraryNew.push(entityArrayNew)
    //                     updateRemarks[subMenuList.compliance_id] = subMenuList.remarks
    //                     updateSelectValue[subMenuList.compliance_id] = subMenuList.criticality_id === 1 ? 'A' : subMenuList.criticality_id === 2 ? 'B' : 'C'
    //                 }
    //             })
    //         }
    //         countrylisttempdataNew.push(tempArraryNew)
    //     })
    //     var merged = [].concat.apply([], countrylisttempdataNew);
    //     setData(merged)
    //     setUpdateRemarks([...updateRemarks])
    //     setUpdateSelectValue([...updateSelectValue])
    // }

    useEffect(() => {
        validator.current.showMessages()
        formValidator.current.showMessages()
    }, [])


    useEffect(() => {
        if (lists && lists.criticality_statu) {
            setArrayLength([...lists && lists.criticality_statu]
            )
            setTotalValue(lists.r_count)
            let temp = checkdata
            for (let i in lists && lists.criticality_statu) {
                temp.push(lists && lists.criticality_statu[i])
            }
            setCheckdata([...temp])
        }

    }, [lists && lists.criticality_statu])
    console.log(checkdata, 'checkdata');
    useEffect(() => {
        if (entityid != '') {
            if (isAuth) {
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "CriticalitySettingsFilters",
                            {
                                "le_id": entityid
                            }
                        ]
                    }
                ]
                if ((entityid != "null")) {
                    if (entityid != undefined) {
                        settingsfilterlist({
                            payload: payload,
                            paramid: paramid
                        })
                    }
                }
            }
        }
    }, [isAuth, entityid])

    useEffect(() => {
        if (titledata.displauvalue.length > 0) {
            let temps = finalArray
            setCount(lists.r_count)
            if (showMore === true) {
                for (let i in titledata.displauvalue) {
                    temps.push(titledata.displauvalue[i])
                }
                setFinalArray([...temps]);
                setEntries(lists.criticality_statu.concat(arrayLength).length)
            }
            else {
                for (let i in titledata.displauvalue) {
                    temps.push(titledata.displauvalue[i])
                }
                setFinalArray([...temps]);
                setEntries(lists && lists.criticality_statu.length)
            }


        }


    }, [titledata.displauvalue])

    useEffect(() => {
        if (titledata.displauvalue.length > 0) {
            setLastIndex(titledata.displauvalue[titledata.displauvalue.length - 1].index)
            setLastChildIndex(titledata.displauvalue[titledata.displauvalue.length - 1].child.length - 1)
            prevCountRef.current = titledata.displauvalue[titledata.displauvalue.length - 1].index
            prevCountRefChild.current = titledata.displauvalue[titledata.displauvalue.length - 1].child.slice(-1)[0].childId//titledata.displauvalue[titledata.displauvalue.length - 1].child.length - 1
        }

    }, [titledata.displauvalue])

    useEffect(() => {
        let countrylisttempdata = [];
        let array = [];
        let finalarray = [];
        let datavalue = [];
        let finalStaticArr = [];
        if (lists.criticality_statu) {
            const ab = lists.criticality_statu.map(function (item) {
                if ((array.indexOf(item.statutory_name) < 0))
                    array.push(item.statutory_name)

            })
            if (titledata.displauvalue.length == 0) {
                array.map((data, i) => {
                    let array2 = [];
                    let entityArrayNew = [];
                    let staticArray = [];
                    let temp = [];
                    let tempStatic = [];
                    if (data) {
                        lists.criticality_statu && lists.criticality_statu.map((subMenuList, childIndex) => {
                            if (data === subMenuList.statutory_name) {
                                entityArrayNew = {
                                    'compliance_id': subMenuList.compliance_id,
                                    'compliance_task': subMenuList.compliance_task,
                                    'criticality': subMenuList.criticality,
                                    'criticality_id': subMenuList.criticality_id,
                                    'frequency': subMenuList.frequency,
                                    'is_new': subMenuList.is_new,
                                    'penal_consequences': subMenuList.penal_consequences,
                                    'remarks': subMenuList.remarks,
                                    'statutory_id': subMenuList.statutory_id,
                                    'statutory_mapping': subMenuList.statutory_mapping,
                                    'statutory_name': subMenuList.statutory_name,
                                    'statutory_provision': subMenuList.statutory_provision,
                                    'unit_id': subMenuList.unit_id,
                                    'parentId': i,
                                    'childId': childIndex,
                                }
                                if (subMenuList.is_new == false) {
                                    staticArray = {
                                        'compliance_id': subMenuList.compliance_id,
                                        'criticality_id': subMenuList.criticality_id,
                                        'remarks': subMenuList.remarks
                                    }
                                    tempStatic.push(staticArray)
                                }

                                temp.push(entityArrayNew)

                                updateRemarks[subMenuList.compliance_id] = subMenuList.remarks
                                if (subMenuList.is_new == true) {
                                    updateSelectValue[subMenuList.compliance_id] = ''
                                } else {
                                    updateSelectValue[subMenuList.compliance_id] = subMenuList.criticality_id === 1 ? 'A' : subMenuList.criticality_id === 2 ? 'B' : 'C'
                                }
                                // updateSelectValue[subMenuList.compliance_id] = subMenuList.criticality_id === 1 ? 'A' : subMenuList.criticality_id === 2 ? 'B' : 'C'
                            }
                        })
                    }
                    countrylisttempdata.push(temp)
                    finalStaticArr.push(tempStatic)
                    datavalue = {
                        index: i,
                        paraent: data,
                        child: temp
                    }
                    finalarray.push(datavalue)
                })
            } else {
                console.log(setLastIndex(prevState => (prevState + 1)), 'setLastIndex(prevState => (prevState+1))')
                console.log(titledata.displauvalue[titledata.displauvalue.length - 1].child.slice(-1)[0].childId, 'titledata.displauvalue[titledata.displauvalue.length - 1].index')
                console.log(titledata.displauvalue[titledata.displauvalue.length - 1].child.length - 1, 'children')
                var parentIndexId = lastIndex.lastParentIndex;
                let tempLastIndex = 0;
                tempLastIndex = prevCountRef.current;
                array.map((data, i) => {
                    let array2 = [];
                    let entityArrayNew = [];
                    let staticArrayNew = [];
                    let temp = [];
                    let tempStaticNew = [];
                    if (data) {
                        let tempLastChildIndex = 0;
                        tempLastChildIndex = prevCountRefChild.current

                        for (titledata.displauvalue.length in lists.criticality_statu) {

                            if (data === lists.criticality_statu[titledata.displauvalue.length].statutory_name) {
                                entityArrayNew = {
                                    'compliance_id': lists.criticality_statu[titledata.displauvalue.length].compliance_id,
                                    'compliance_task': lists.criticality_statu[titledata.displauvalue.length].compliance_task,
                                    'criticality': lists.criticality_statu[titledata.displauvalue.length].criticality,
                                    'criticality_id': lists.criticality_statu[titledata.displauvalue.length].criticality_id,
                                    'frequency': lists.criticality_statu[titledata.displauvalue.length].frequency,
                                    'is_new': lists.criticality_statu[titledata.displauvalue.length].is_new,
                                    'penal_consequences': lists.criticality_statu[titledata.displauvalue.length].penal_consequences,
                                    'remarks': lists.criticality_statu[titledata.displauvalue.length].remarks,
                                    'statutory_id': lists.criticality_statu[titledata.displauvalue.length].statutory_id,
                                    'statutory_mapping': lists.criticality_statu[titledata.displauvalue.length].statutory_mapping,
                                    'statutory_name': lists.criticality_statu[titledata.displauvalue.length].statutory_name,
                                    'statutory_provision': lists.criticality_statu[titledata.displauvalue.length].statutory_provision,
                                    'unit_id': lists.criticality_statu[titledata.displauvalue.length].unit_id,
                                    'parentId': tempLastIndex + 1,
                                    'childId': tempLastChildIndex + 1,
                                }
                                if (lists.criticality_statu[titledata.displauvalue.length].is_new == false) {
                                    staticArrayNew = {
                                        'compliance_id': lists.criticality_statu[titledata.displauvalue.length].compliance_id,
                                        'criticality_id': lists.criticality_statu[titledata.displauvalue.length].criticality_id,
                                        'remarks': lists.criticality_statu[titledata.displauvalue.length].remarks
                                    }
                                    tempStaticNew.push(staticArrayNew)
                                }
                                temp.push(entityArrayNew)

                                updateRemarks[lists.criticality_statu[titledata.displauvalue.length].compliance_id] = lists.criticality_statu[titledata.displauvalue.length].remarks
                                if (lists.criticality_statu[titledata.displauvalue.length].is_new == true) {
                                    updateSelectValue[lists.criticality_statu[titledata.displauvalue.length].compliance_id] = ''
                                } else {
                                    updateSelectValue[lists.criticality_statu[titledata.displauvalue.length].compliance_id] = lists.criticality_statu[titledata.displauvalue.length].criticality_id === 1 ? 'A' : lists.criticality_statu[titledata.displauvalue.length].criticality_id === 2 ? 'B' : 'C'
                                }

                            }
                            tempLastChildIndex++;
                            setLastChildIndex(tempLastChildIndex)
                        }

                    }

                    tempLastIndex++;
                    countrylisttempdata.push(temp)
                    finalStaticArr.push(tempStaticNew)
                    datavalue = {
                        index: tempLastIndex,
                        paraent: data,
                        child: temp
                    }
                    finalarray.push(datavalue)
                    setLastIndex(tempLastIndex)
                })
            }
            var merged = [].concat.apply([], countrylisttempdata);
            var mergerdNew = [].concat.apply([], finalStaticArr);
            setTempStaticArray(mergerdNew)
            setData(merged)
            setUpdateRemarks([...updateRemarks])
            setUpdateSelectValue([...updateSelectValue])
            Settitledata({
                ...titledata,
                displauvalue: [...finalarray]
            })
            //getData()
        }


    }, [lists])
    console.log(titledata, 'titledata');
    useEffect(() => {

        if (tempState && tempState.length > 0) {
            setCriticalityList(tempState)
        }
    }, [tempState])

    useEffect(() => {
        if (collapseChange === 'collapse') {
            console.log('inserting in')
            if (listChild && listChild.length > 0) {
                let tempArray = []
                for (let i in listChild) {
                    setChildIndex(i)
                    if (tempState && tempState.length > 0) {
                        for (let j in tempState) {
                            if (listChild[i].compliance_id === tempState[j].compliance_id) {
                                tempState.splice(j, 1)
                                setTempState([...tempState, ...tempArray])
                            }
                        }
                    }
                    tempArray.push({
                        "compliance_id": listChild[i].compliance_id,
                        "criticality_id": updateSelectValue[listChild[i].compliance_id] === 'A' ? 1 : updateSelectValue[listChild[i].compliance_id] === 'B' ? 2 : 3,
                        "remarks": listChild[i].criticality === updateSelectValue[listChild[i].compliance_id] ? '' : updateRemarks[listChild[i].compliance_id]
                    })
                }
                setTempState([...tempState, ...tempArray])
            }
        }
    }, [updateSelectValue, updateRemarks])

    useEffect(() => {
        if (finalArray) {
            const index = finalArray.map((item) => {
                return item.index
            })
            setIndexValues(index.join().split(','))
        }
    }, [finalArray])

    const homescreen = () => {
        historynew.push(homepage);
        // window.location.href = homepage
        document.getElementById('homehover').style.color = "blue"
    }

    return (
        <Fragment>
            <div>
                <div id='page-wrapper' className="page-wrapper">
                    {/* <ScrollToTop smooth color="red" component={<Tooltip title="Back to top"><UpOutlined /></Tooltip>} />
                    {document.body.scrollHeight > document.body.clientHeight ?
                        <div className='back-to-top'>
                            <ScrollButton />
                        </div> : ''} */}
                    <div className='back-to-top'>
                        <ScrollButton />
                    </div>
                    <div className="page-titles pb-2 pt-2 px-3">
                        <div className="row">
                            <div className="col-lg-8 col-md-6 col-12 align-self-center">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 d-flex align-items-center">
                                        <li className="breadcrumb-item">
                                            <span className='pointer' id='homehover'  onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                        </li>
                                        <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                            <span style={{ fontSize: '16px' }}>Transaction</span>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                            <span>Criticality Settings</span>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                            <div className="col-lg-4 text-end">
                                <Tooltip title='Advanced Filter' placement="top"><Button
                                    className="mx-1"
                                    type="primary"
                                    shape="round"
                                    style={{
                                        display: filterTaskAcc ? "none" : "initial",
                                    }}
                                    onClick={filterHandle}
                                >
                                    <FilterOutlined /> Filter
                                </Button></Tooltip>
                                <button
                                    onClick={(e) => {
                                        fullscreenMode();
                                    }}
                                    className="bg-transparent text-black ms-2 full-mode fullscreenradius"
                                >
                                    <Tooltip
                                        placement="left"
                                        title={is_Fullscreen == true ? "Exit Full Screen Mode" : "Full Screen Mode"}
                                    >
                                        <FullscreenOutlined />
                                    </Tooltip>
                                </button>
                            </div>
                        </div>
                    </div>


                    <div className="container-fluid pt-0 px-3">
                        <div className="row">
                            <div className="col-12">
                                <div >
                                    <div className={localStorage.getItem('currentTheme')}>
                                        {/* <div className="row"> */}
                                        <Collapse defaultActiveKey={["1"]} onChange={onChange} style={{ marginBottom: '5px', display: exitCollapse ? 'none' : 'block' }} >
                                            <Panel header={<b>Criticality Settings</b>} key={key}  >
                                                <div className='col-md-12'>
                                                    <div className='row'>
                                                        <div className='col-md-4'>
                                                            <label><b>Business Group : </b></label>
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <label><b>Legal Entity : </b><span style={{ color: "red" }}>*</span></label>
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <label><b>Domain : </b><span style={{ color: "red" }}>*</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-12'>
                                                    <div className='row'>
                                                        <div className='col-md-4'>
                                                            {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                                <div className="form-group">
                                                                    <Select
                                                                        allowClear={true}
                                                                        size="default"
                                                                        placeholder="Select Business Group"

                                                                        onPaste={(e) => {
                                                                            handlePasting(e, /^[a-z 0-9]+$/gi)
                                                                        }}
                                                                        onChange={(data, value) => {
                                                                            if (data !== undefined) {
                                                                                let filteredEntity = _.filter(sessionArr, { bg_id: Number(data) })
                                                                                setFinallegalentity(filteredEntity)
                                                                                SetSettings({
                                                                                    ...Settings,
                                                                                    le_id: '',
                                                                                    domain_id: '',
                                                                                    statutory_nature: 'All',
                                                                                    bg_id: data
                                                                                })
                                                                                setCurrentEntity()
                                                                            }
                                                                            else {
                                                                                setFinallegalentity([])
                                                                                SetSettings({
                                                                                    ...Settings,
                                                                                    le_id: '',
                                                                                    domain_id: '',
                                                                                    statutory_nature: 'All',
                                                                                    bg_id: ''
                                                                                })
                                                                                setCurrentEntity()
                                                                            }
                                                                        }}
                                                                        showSearch
                                                                        optionFilterProp="children"
                                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                        value={Settings.bg_id || undefined}
                                                                        style={{ width: '100%', marginTop: '5px' }}
                                                                    >
                                                                        {bggroup && bggroup.length > 0 && bggroup.map((item, i) => {
                                                                            if (item.bg_name !== null) {
                                                                                return (
                                                                                    <Option key={item.bg_id}>
                                                                                        {item.bg_name}
                                                                                    </Option>
                                                                                );
                                                                            }
                                                                        })}
                                                                    </Select>
                                                                </div> :
                                                                <p>{businessGroup.bg_name ? businessGroup.bg_name : '-'}</p>
                                                            }
                                                        </div>
                                                        <div className="col-md-4">
                                                            {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                                <div className="form-group">
                                                                    <Select
                                                                        allowClear={true}
                                                                        size="default"
                                                                        name='le_id'
                                                                        placeholder="Select Legal Entity"
                                                                        onPaste={(e) => {
                                                                            handlePasting(e, /^[a-z 0-9 ()]+$/gi)
                                                                        }}
                                                                        onChange={(data, value) => {
                                                                            if (data !== undefined) {
                                                                                setCurrentEntity(data)
                                                                                SetSettings({
                                                                                    ...Settings,
                                                                                    le_id: data,
                                                                                    domain_id: '',
                                                                                    statutory_nature: 'All'
                                                                                })
                                                                            }
                                                                            else {
                                                                                setCurrentEntity('')
                                                                                SetSettings({
                                                                                    ...Settings,
                                                                                    le_id: '',
                                                                                    domain_id: '',
                                                                                    statutory_nature: 'All'
                                                                                })
                                                                            }
                                                                        }}
                                                                        showSearch
                                                                        optionFilterProp="children"
                                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                        value={Settings.le_id || undefined}
                                                                        style={{ width: '100%', marginTop: '5px' }}
                                                                    >
                                                                        {finallegalEntity && finallegalEntity.length > 0 ? finallegalEntity.map((item, i) => {
                                                                            return (
                                                                                <Option key={item.le_id}>
                                                                                    {item.le_name}
                                                                                </Option>
                                                                            );
                                                                        }) : sessionArr && sessionArr.length > 0 && sessionArr.map((item, i) => {
                                                                            return (
                                                                                <Option key={item.le_id}>
                                                                                    {item.le_name}
                                                                                </Option>
                                                                            );
                                                                        })}
                                                                    </Select>
                                                                    {validator.current.message(
                                                                        'le_id',
                                                                        Settings.le_id,
                                                                        ['required'],
                                                                        {
                                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                            messages: {
                                                                                required: 'Legal Entity Required'
                                                                            }
                                                                        })}
                                                                </div> :
                                                                <p title={localStorage.getItem('SelectedEntity')} className='text-wrap-report' style={{ width: "100%", marginTop: "5px" }}>{localStorage.getItem('SelectedEntity')}</p>

                                                            }
                                                        </div>
                                                        <div className="col-md-4">
                                                            <Select
                                                                disabled={entityid == "" || entityid == null || entityid == 'null' ? true : false}
                                                                size="default"
                                                                className='domainid'
                                                                onPaste={(e) => {
                                                                    handlePasting(e, /^[a-z 0-9]+$/gi)
                                                                }}
                                                                placeholder="Select Domain"
                                                                onChange={(data) => {
                                                                    if (data !== undefined) {
                                                                        let statutory = _.filter(filterdatas.nature_list, { domain_id: Number(data) })
                                                                        setStatutorydata(statutory)
                                                                        SetSettings({
                                                                            ...Settings,
                                                                            domain_id: data
                                                                        })
                                                                    }
                                                                    else {
                                                                        setStatutorydata([])
                                                                        SetSettings({
                                                                            ...Settings,
                                                                            domain_id: ''
                                                                        })
                                                                    }
                                                                }}
                                                                showSearch
                                                                allowClear={true}
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                style={{ width: '100%', marginTop: '5px' }}
                                                                value={Settings.domain_id || undefined}
                                                            >
                                                                {filterdatas && filterdatas.domains_list.length > 0 && filterdatas.domains_list.map((item) => {
                                                                    return <Option value={item.d_id}>{item.d_name}</Option>
                                                                })}
                                                            </Select>
                                                            {validator.current.message(
                                                                'domainid',
                                                                Settings.domain_id,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Domain Required ',
                                                                    }
                                                                })}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-12' style={{ marginTop: "10px" }}>
                                                    <div className='row'>
                                                        {/* <div className='col-md-6'>
                                <label><b>Domain: </b><span style={{ color: "red" }}>*</span></label>
                            </div> */}
                                                        <div className='col-md-4'>
                                                            <label><b>Statutory Nature : </b></label>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className='col-md-12'>
                                                    <div className='row'>
                                                        <div className="col-md-4">
                                                            <Select
                                                                // defaultValue='All'
                                                                style={{ width: '100%', marginTop: '5px' }}
                                                                value={Settings.statutory_nature || undefined}
                                                                placeholder="Select Statutory Nature"

                                                                onChange={(data) => {
                                                                    SetSettings({
                                                                        ...Settings,
                                                                        statutory_nature: data
                                                                    })
                                                                    let CategoryData = _.filter(filterdatas && filterdatas.nature_list, { domain_id: Number(data) })
                                                                    setCategory(CategoryData)
                                                                }}>
                                                                <Option key='All'>All</Option>
                                                                {Settings.domain_id ?
                                                                    statutorydata.map((item) => {
                                                                        return (
                                                                            <Option key={item.statutory_nature}>{item.statutory_nature}</Option>
                                                                        )
                                                                    })

                                                                    :
                                                                    ''
                                                                }

                                                            </Select>

                                                        </div>
                                                        <div className={'col-md-12 mb-2 text-center popupbtncolour ' + localStorage.getItem('currentTheme')} style={{ marginTop: '10px' }}>
                                                            <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')} style={{ background: "#0096FF", borderColor: "#0096FF" }}
                                                                size='default' icon={<EyeOutlined />} onClick={showresult}>
                                                                Show
                                                            </Button>
                                                        </div>
                                                    </div>

                                                </div>
                                            </Panel>
                                        </Collapse>
                                        <Modal
                                            onCancel={setcancelFilter}
                                            footer={false}
                                            title="Criticality Settings"
                                            className={
                                                "add-service-prv modal-service-priv " +
                                                localStorage.getItem("currentTheme")
                                            }
                                            open={filterModalVisible}
                                            maskClosable={false}
                                        >

                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <label><b>Business Group : </b></label>
                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                        <div className="form-group">
                                                            <Select
                                                                getPopupContainer={trigger => trigger.parentNode}
                                                                allowClear={true}
                                                                size="default"
                                                                placeholder="Select Business Group"
                                                                onPaste={(e) => {
                                                                    handlePasting(e, /^[a-z 0-9]+$/gi)
                                                                }}
                                                                onChange={(data, value) => {
                                                                    if (data !== undefined) {
                                                                        let filteredEntity = _.filter(sessionArr, { bg_id: Number(data) })
                                                                        setFinallegalentity(filteredEntity)
                                                                        SetSettings({
                                                                            ...Settings,
                                                                            le_id: '',
                                                                            domain_id: '',
                                                                            statutory_nature: 'All',
                                                                            bg_id: data
                                                                        })
                                                                        setCurrentEntity()
                                                                    }
                                                                    else {
                                                                        setFinallegalentity([])
                                                                        SetSettings({
                                                                            ...Settings,
                                                                            le_id: '',
                                                                            domain_id: '',
                                                                            statutory_nature: 'All',
                                                                            bg_id: ''
                                                                        })
                                                                        setCurrentEntity()
                                                                    }
                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                value={Settings.bg_id || undefined}
                                                                style={{ width: '100%', marginTop: '5px' }}
                                                            >
                                                                {bggroup && bggroup.length > 0 && bggroup.map((item, i) => {
                                                                    if (item.bg_name !== null) {
                                                                        return (
                                                                            <Option key={item.bg_id}>
                                                                                {item.bg_name}
                                                                            </Option>
                                                                        );
                                                                    }
                                                                })}
                                                            </Select>
                                                        </div> :
                                                        <p>{businessGroup.bg_name ? businessGroup.bg_name : '-'}</p>
                                                    }
                                                </div>
                                                <div className='col-md-6'>
                                                    <label><b>Legal Entity : </b><span style={{ color: "red" }}>*</span></label>
                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                        <div className="form-group">
                                                            <Select
                                                                getPopupContainer={trigger => trigger.parentNode}
                                                                allowClear={true}
                                                                size="default"
                                                                name='le_id'
                                                                placeholder="Select Legal Entity"
                                                                onPaste={(e) => {
                                                                    handlePasting(e, /^[a-z 0-9 ( )]+$/gi)
                                                                }}
                                                                onChange={(data, value) => {
                                                                    if (data !== undefined) {
                                                                        setCurrentEntity(data)
                                                                        SetSettings({
                                                                            ...Settings,
                                                                            le_id: data,
                                                                            domain_id: '',
                                                                            statutory_nature: 'All'
                                                                        })
                                                                    }
                                                                    else {
                                                                        setCurrentEntity('')
                                                                        SetSettings({
                                                                            ...Settings,
                                                                            le_id: '',
                                                                            domain_id: '',
                                                                            statutory_nature: 'All'
                                                                        })
                                                                        setFinalArray([])
                                                                        setShow(false)
                                                                    }
                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                value={Settings.le_id || undefined}
                                                                style={{ width: '100%', marginTop: '5px' }}
                                                            >
                                                                {finallegalEntity && finallegalEntity.length > 0 ? finallegalEntity.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.le_id}>
                                                                            {item.le_name}
                                                                        </Option>
                                                                    );
                                                                }) : sessionArr && sessionArr.length > 0 && sessionArr.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.le_id}>
                                                                            {item.le_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                            {validator.current.message(
                                                                'le_id',
                                                                Settings.le_id,
                                                                ['required'],
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Legal Entity Required'
                                                                    }
                                                                })}
                                                        </div> :
                                                        <p title={localStorage.getItem('SelectedEntity')} className='text-wrap-report' style={{ width: "100%", marginTop: "5px" }}>{localStorage.getItem('SelectedEntity')}</p>

                                                    }
                                                </div>
                                                <div className='col-md-6 mt-1'>
                                                    <label><b>Domain : </b><span style={{ color: "red" }}>*</span></label>
                                                    <Select
                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        disabled={entityid == "" || entityid == null || entityid == 'null' ? true : false}
                                                        size="default"
                                                        className='domainid'
                                                        placeholder="Select Domain"
                                                        onPaste={(e) => {
                                                            handlePasting(e, /^[a-z 0-9]+$/gi)
                                                        }}
                                                        onChange={(data) => {
                                                            if (data !== undefined) {
                                                                let statutory = _.filter(filterdatas.nature_list, { domain_id: Number(data) })
                                                                setStatutorydata(statutory)
                                                                SetSettings({
                                                                    ...Settings,
                                                                    domain_id: data
                                                                })
                                                            }
                                                            else {
                                                                setStatutorydata([])
                                                                SetSettings({
                                                                    ...Settings,
                                                                    domain_id: ''
                                                                })
                                                            }
                                                        }}
                                                        showSearch
                                                        allowClear={true}
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        style={{ width: '100%', marginTop: '5px' }}
                                                        value={Settings.domain_id || undefined}
                                                    >
                                                        {filterdatas && filterdatas.domains_list.length > 0 && filterdatas.domains_list.map((item) => {
                                                            return <Option value={item.d_id}>{item.d_name}</Option>
                                                        })}
                                                    </Select>
                                                    {validator.current.message(
                                                        'domainid',
                                                        Settings.domain_id,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Domain Required ',
                                                            }
                                                        })}
                                                </div>
                                                <div className='col-md-6 mt-1'>
                                                    <label><b>Statutory Nature : </b></label>
                                                    <Select
                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        // defaultValue='All'
                                                        style={{ width: '100%', marginTop: '5px' }}
                                                        value={Settings.statutory_nature || undefined}
                                                        placeholder="Select Statutory Nature"
                                                        onChange={(data) => {
                                                            SetSettings({
                                                                ...Settings,
                                                                statutory_nature: data
                                                            })
                                                            let CategoryData = _.filter(filterdatas && filterdatas.nature_list, { domain_id: Number(data) })
                                                            setCategory(CategoryData)
                                                        }}>
                                                        <Option key='All'>All</Option>
                                                        {Settings.domain_id ?
                                                            statutorydata.map((item) => {
                                                                return (
                                                                    <Option key={item.statutory_nature}>{item.statutory_nature}</Option>
                                                                )
                                                            })

                                                            :
                                                            ''
                                                        }

                                                    </Select>

                                                </div>
                                            </div>
                                            {/* <div className='col-md-12'>
                                                    <div className='row'>
                                                        <div className='col-md-4'>
                                                            
                                                        </div>
                                                        <div className="col-md-4">
                                                           
                                                        </div>
                                                        <div className="col-md-4">
                                                            
                                                        </div>
                                                    </div>
                                                </div> */}
                                            {/* <div className='col-md-12' style={{ marginTop: "10px" }}>
                                                    <div className='row'>
                                                        {/* <div className='col-md-6'>
                                                                <label><b>Domain: </b><span style={{ color: "red" }}>*</span></label>
                                                            </div> */}


                                            {/* </div>
                                                </div> */}

                                            <div className={'col-md-12 text-center popupbtncolour ' + localStorage.getItem('currentTheme')} style={{ marginTop: '15px' }}>
                                                <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')} style={{ background: "#0096FF", borderColor: "#0096FF" }}
                                                    size='default' icon={<EyeOutlined />} onClick={showresult}>
                                                    Show
                                                </Button>
                                            </div>
                                        </Modal>
                                    </div>
                                </div>


                                {show === false ?
                                    <></>
                                    :

                                    finalArray && finalArray.length > 0 ?
                                        <div >
                                            {/* <div style={{ display: 'flex', justifyContent: 'space-evenly', padding: '6px' }}>
                                            <div>
                                                <i style={{ color: "black", }} className="bi bi-square-fill"></i> <label style={{ color: '#808080' }}> Compliance Criticality not assigned are marked in Black color</label>
                                            </div><div><i style={{ color: "blue" }} className="bi bi-square-fill"></i> <label style={{ color: '#808080' }}> Compliance Criticality to be assigned are marked in Blue color</label>
                                            </div>
                                        </div> */}
                                            <Collapse className='cric' style={{ width: '100%' }} defaultActiveKey={["0"]} >
                                                {finalArray && finalArray.length > 0 && finalArray.map((List, i) => {
                                                    console.log(List, 'ListCricc');
                                                    let isNewTrue = _.filter(List && List.child, { is_new: true })
                                                    let isNewFalse = _.filter(List && List.child, { is_new: false })
                                                    console.log(isNewFalse, 'isNewFalse');
                                                    return <Panel
                                                        header={List.paraent}
                                                        // header={<p>{List.paraent}<span style={{marginLeft:'50px',fontWeight:'bold'}}>Not Assigned : {isNewFalse && isNewFalse.length}</span><span style={{marginLeft:'50px',fontWeight:'bold',color:'blue'}}>Assigned : {isNewTrue && isNewTrue.length}</span></p>
                                                        key={i}

                                                        extra={genextra(List.index, List)}
                                                    >

                                                        <Table

                                                            size={'small'}
                                                            // className='userprivclass'
                                                            className={localStorage.getItem("currentTheme")}
                                                            columns={columns}
                                                            dataSource={List.child}
                                                            bordered
                                                            scroll={{ x: 1100 }}
                                                            pagination={false} />

                                                    </Panel>
                                                })}

                                            </Collapse>
                                            <div className='row mt-2 ps-1'>
                                                <div className='col-md-12'>
                                                    {/* <label style={{ marginLeft: "28px" }}>Showing <span>{checkdata.length}</span>  entries</label> */}
                                                    <label style={{ marginLeft: "5px" }}>Showing 1 to {checkdata.length} of {totalValue} entries</label>
                                                    <p style={{ marginLeft: "5px", color: '#0000FF' }}> * Compliance Criticality to be assigned are marked in Blue color</p>
                                                </div>
                                                <div className='row maringBottom'>
                                                    <div className='col-md-4'  >

                                                    </div>

                                                </div>
                                            </div>
                                            <div className='text-center'>
                                                {checkdata.length != count ?
                                                    <Button type="primary" shape="round" className='showbutton' style={{ background: "#0096FF", borderColor: "#0096FF" }}
                                                        size='default'
                                                        onClick={showMoreresult}
                                                    >Show More <i><DownOutlined /></i>
                                                    </Button>
                                                    :
                                                    <Button type="primary" shape="round" className='addbutton' style={{ background: "#198754", borderColor: "#198754", marginBottom: '50px' }}
                                                        icon={<PlayCircleOutlined />} size='default'
                                                        onClick={addform}
                                                    >Submit
                                                    </Button>
                                                    //         <div className="button2 mt-0" id={localStorage.getItem('currentTheme')} onClick={addform}>
                                                    //     <span></span>
                                                    //     <span></span>
                                                    //     <span></span>
                                                    //     <span></span>
                                                    //     Submit
                                                    // </div>

                                                }
                                                {/* </div> <Button type="primary" shape="round" className='addbutton' style={{ background: "#198754", borderColor: "#198754", marginBottom: '50px' }} 
                                                //     icon={<PlayCircleOutlined />} size='default'
                                                //     onClick={addform}
                                                // >Submit
                                                // </Button>*/}
                                            </div>


                                        </div> :
                                        <p style={{ marginTop: '10%', marginLeft: '43%', fontWeight: '500' }}>No Records Found</p>

                                }
                            </div>
                        </div>
                    </div>

                </div >
                {show === true ?
                    <Stickicon />
                    : ''}

            </div>
            <Modal title="Legal Entity" open={legal} onOk={handleOk} onCancel={handleCancel} maskClosable={false}>
                <Search
                    placeholder="Search"
                // onSearch={onSearch}
                />
            </Modal>
        </Fragment>

    )
}

export default connect(mapStateToProps, {
    settingsfilterlist,
    settingslist,
    addsettings
})(CriticalitySetting);