import React, { Fragment } from 'react'
import { Collapse, Select, Input, DatePicker, Card, Modal, Table, Button, Tooltip, Pagination } from 'antd';
import { useState, useRef, useEffect } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";
import { domainCardData, GetDomainCardData } from '../../../Store/Action/Report/DomainCard';
import { ExclamationCircleTwoTone, PlayCircleOutlined, EyeOutlined, FullscreenOutlined, UpOutlined, UpCircleOutlined, FilterOutlined } from '@ant-design/icons';
import ScrollToTop from "react-scroll-to-top";
import { connect, useDispatch, useSelector } from 'react-redux';
import { filter } from 'lodash';
import { fullscreenmode, fullscreenexit, handleKeyDown } from './../../../Libs/fullscreenmode';
import useStickyHeight from "../../../Libs/useStickyHeight";
import { validationType, validateSelect, onPasteToaster } from './../../../Libs/inputValidation';
import ScrollButton from '../../Scrollbar';
import { Toaster } from '../../../Libs/Toaster';
import { CountrySplit, HomeRedirect } from './../../../Libs/country'
const homepage = HomeRedirect()
const uniq_country_data = CountrySplit()

const mapStateToProps = (state) => ({
    domain_data: state.DomainCard.domainCardReport.domain_data,
    tableData: state.DomainCard.domainCardReport.getTableData
})

const DomainCard = ({
    domainCardData,
    GetDomainCardData,
    domain_data,
    tableData

}) => {
    useStickyHeight()
    const _ = require("lodash");
    const historynew = useHistory();
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const country_info = sessionParsedValue.country_info
    const sessionArr = sessionParsedValue.entity_info
    const [entity, setEntity] = useState([])
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const location = useLocation();
    const is_Fullscreen = useSelector((state) => state.Login.is_fullscreen);
    const dispatch = useDispatch();
    const paramid = location.pathname;
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const [tableshow, setTableShow] = useState(false)
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [key, setKey] = useState("1")
    let [index, setIndex] = useState(0)
    const [type, setType] = useState('')
    const [total, setTotal] = useState(0)

    const [domainName, setDomainName] = useState('')
    const [filteredArray, setFilteredArray] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [completedModal, setCompletedModal] = useState(false)
    const [dataSource, setDataSource] = useState([])
    const [domainCard, setDomainCard] = useState({
        country: '',
        countryName: '',
        legel_entity: '',
        le_id: '',
        category: '',
        categoryName: '',
        businessGroup: '',
        businessGroupName: '',
        division: '',
        divisionName: '',
        domain: '',
        domainName: '',
        legalEntityLabel : '',
        countryLabel: ''
    })
    const [divisonList, setDivisionList] = useState([])
    const [categoryList, setCategoryList] = useState([])
    const [domainList, setDomainList] = useState([])
    // const [totalCount,setInProgressCount] = useState()
    const SelectedEntity = localStorage.getItem('SelectedEntity')
    const validator = useRef(new SimpleReactValidator());
    const { Column, ColumnGroup } = Table;
    const { Panel } = Collapse;
    const { Option } = Select;
    const [newDataArray, setNewDataArray] = useState([])
    const [newTableArray, setNewTableArray] = useState([])
    const [totalArray, setTotalArray] = useState([])
    const [businessName, setBusinessName] = useState()
    const [newTableWithTotal, setNewTableWithTotal] = useState([])
    const [filterTaskAcc, setFilterTaskAcc] = useState(true);
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const [exitCollapse, setExitCollapse] = useState(false);
    // let inProgressCount;
    // let compliedCount;
    // let delayedCount;
    // let notCompliedCount;
    // let unAssignedCount;
    // let notOptedCount;
    // let notApplicableCount;
    // let totalCount;

    const [bggroup, setBggroup] = useState([]);
    const [selectSearchValue, setSelectSearchValue] = useState({
        country: '',
        legalEntity: '',
        BusinessGroup: '',
        domain: '',
        division: '',
        category: '',
    });

    useEffect(() => {
        if (sessionArr && sessionArr.length > 0) {
            let unique = _.uniqBy(sessionArr, function (e) {
                return e.bg_id;
            });
            setBggroup(unique)
        }
    }, [])
    const onChange = (key) => {
        console.log(key);
    };

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handlePasting = (e, regex) => {

        var regex = regex;
        var key = e.clipboardData.getData('text')
        if (!regex.test(key)) {
            e.preventDefault();
            Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
            return false;
        }
        // e.clipboardData.getData('text')
        // Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
        // e.preventDefault();
        // return false;
    }

    const onshow = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            setTableShow(true)
            setExitCollapse(true);
            setFilterModalVisible(false);
            setFilterTaskAcc(false);
            setKey(0)
            setDomainCard({
                ...domainCard,
                legalEntityLabel: domainCard.legel_entity,
                countryLabel: domainCard.countryName
            })
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetDomainScoreCard",
                        {
                            "c_id": Number(domainCard.country),
                            "bg_id": domainCard.businessGroup ? Number(domainCard.businessGroup) : null,
                            "le_id": entityid,
                            "d_id": domainCard.domain ? Number(domainCard.domain) : null,
                            "div_id": domainCard.division ? Number(domainCard.division) : null,
                            "cat_id": domainCard.category ? Number(domainCard.category) : null,
                            "csv": false
                        }
                    ]
                }
            ]
            GetDomainCardData({
                payload: payload,
                paramid: paramid
            })
        }
    }

    useEffect(() => {
        if (tableData && tableData.domain_score_card_list && tableData.domain_score_card_list.length > 0) {
            setNewTableArray(tableData.domain_score_card_list)
        }
    }, [tableData])

    useEffect(() => {
        if (newTableArray && newTableArray.length > 0) {
            let temp = []
            let temp1 = []
            let temp2 = []
            let temp3 = []
            let temp4 = []
            for (let i in newTableArray) {
                temp.push(Number(newTableArray[i].assigned_count))
                temp1.push(Number(newTableArray[i].unassigned_count))
                temp2.push(Number(newTableArray[i].not_opted_count))
                temp3.push(Number(newTableArray[i].not_applicable_count))
                // temp4.push(Number(newTableArray[i].unassigned_count))
            }
            let assignedCount = temp.reduce(function (accumulator, curValue) {
                return accumulator + curValue
            }, 0)
            let unAssignedCount = temp1.reduce(function (accumulator, curValue) {
                return accumulator + curValue
            }, 0)
            let notOptedCount = temp2.reduce(function (accumulator, curValue) {
                return accumulator + curValue
            }, 0)
            let notApplicableCount = temp3.reduce(function (accumulator, curValue) {
                return accumulator + curValue
            }, 0)
            // let unAssignedCountValue = temp4.reduce(function (accumulator, curValue) {
            //     return accumulator + curValue
            // }, 0)
            // let notOptedCountValue = temp5.reduce(function (accumulator, curValue) {
            //     return accumulator + curValue
            // }, 0)
            // let notApplicableCountValue = temp6.reduce(function (accumulator, curValue) {
            //     return accumulator + curValue
            // }, 0)

            temp4.push({
                domain_name: 'Total',
                assigned_count: assignedCount,
                unassigned_count: unAssignedCount,
                not_opted_count: notOptedCount,
                not_applicable_count: notApplicableCount,
                total: <b>{Number(assignedCount) + Number(unAssignedCount) + Number(notOptedCount) + Number(notApplicableCount)}</b>
                // unassigned_count: unAssignedCountValue,
                // not_opted_count: notOptedCountValue,
                // not_applicable_count: notApplicableCountValue,
            })
            setTotalArray(temp4)
        }
    }, [newTableArray])


    useEffect(() => {
        validator.current.showMessages()
    }, [])

    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetDomainScoreCardFilters",
                        {
                            "le_id": entityid
                        }
                    ]
                }
            ]
            if (entityid != 'null') {
                domainCardData({
                    payload: payload,
                    paramid: paramid
                })
            }
        }

    }, [isAuth, entityid])

    useEffect(() => {
        if (entityid === null) {
            setCurrentEntity(domainCard.le_id)
        }
    }, [entityid])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') != 'All Legal Entity') {
            let temp = localStorage.getItem("SelectedEntity")
            let tempcountry = _.filter(sessionArr, { le_name: temp })
            setDomainCard({
                ...domainCard,
                country: tempcountry && tempcountry.length > 0 ? Number(tempcountry[0].c_id) : '',
                countryName: tempcountry && tempcountry.length > 0 ? tempcountry[0].c_name : '',
                le_id: localStorage.getItem('SelectedEntityid')
            })
        }
    }, [localStorage.getItem('SelectedEntity') != 'All Legal Entity'])

    useEffect(() => {
        if (domain_data) {
            // setCategoryList(domain_data && domain_data.cat_infos)
            setDivisionList(domain_data && domain_data.div_infos)
            setDomainList(domain_data && domain_data.domains)
        }

    }, [domain_data])

    // useEffect(() => {
    //     let temp = []
    //     if (tableData && tableData.domain_score_card_list && tableData.domain_score_card_list.length > 0) {
    //         tableData.domain_score_card_list.map((item, i) => {
    //             temp.push(item.units_wise_count)
    //         })
    //     }
    //     setFilteredArray(temp)

    // }, [tableData])
    useEffect(() => {
        if (localStorage.getItem('SelectedEntityid') !== null) {
            if (sessionArr && sessionArr.length > 0) {
                let bgName = _.find(sessionArr, { le_id: localStorage.getItem('SelectedEntityid') })
                console.log(bgName, 'bgName');
                if (bgName != undefined) {
                    setBusinessName(bgName && bgName.bg_name)
                }
            }
        }
    }, [localStorage.getItem('SelectedEntityid'), sessionArr])
    useEffect(() => {
        if (filteredArray && filteredArray.length > 0) {
            setDataSource(filteredArray)
            if (type === 'domainOnClick') {
                let temp = []
                let temp1 = []
                let temp2 = []
                let temp3 = []
                let temp4 = []
                let temp5 = []
                let temp6 = []
                let temp7 = []
                for (let i in filteredArray) {
                    temp.push(Number(filteredArray[i].inprogress_count))
                    temp1.push(Number(filteredArray[i].complied_count))
                    temp2.push(Number(filteredArray[i].delayed_count))
                    temp3.push(Number(filteredArray[i].overdue_count))
                    temp4.push(Number(filteredArray[i].unassigned_count))
                    temp5.push(Number(filteredArray[i].not_opted_count))
                    temp6.push(Number(filteredArray[i].not_applicable_count))
                }
                let inProgressCountValue = temp.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
                let compliedCountValue = temp1.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
                let delayedCountValue = temp2.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
                let notCompliedCountValue = temp3.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
                let unAssignedCountValue = temp4.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
                let notOptedCountValue = temp5.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
                let notApplicableCountValue = temp6.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
                // temp7.push({
                //     unit: <b>Total</b>,
                //     inprogress_count: <b>{inProgressCountValue}</b> ,
                //     complied_count:  <b>{compliedCountValue}</b> ,
                //     delayed_count:   <b>{delayedCountValue}</b> ,
                //     overdue_count:  <b>{notCompliedCountValue}</b> ,
                //     unassigned_count:   <b>{unAssignedCountValue}</b> ,
                //     not_opted_count:   <b>{notOptedCountValue}</b> ,
                //     not_applicable_count:   <b>{notApplicableCountValue}</b> ,
                //     total:<b>{Number(inProgressCountValue)+Number(compliedCountValue)+Number(delayedCountValue)+Number(notCompliedCountValue)+Number(unAssignedCountValue)+Number(notOptedCountValue)+Number(notApplicableCountValue)}</b>
                // })
                temp7.push({
                    unit: 'Total',
                    inprogress_count: inProgressCountValue,
                    complied_count: compliedCountValue,
                    delayed_count: delayedCountValue,
                    overdue_count: notCompliedCountValue,
                    unassigned_count: unAssignedCountValue,
                    not_opted_count: notOptedCountValue,
                    not_applicable_count: notApplicableCountValue,
                    // total:<b>{Number(inProgressCountValue)+Number(compliedCountValue)+Number(delayedCountValue)+Number(notCompliedCountValue)+Number(unAssignedCountValue)+Number(notOptedCountValue)+Number(notApplicableCountValue)}</b>
                })
                setNewDataArray(temp7)
            }
        }
    }, [filteredArray, type])

    useEffect(() => {
        if (newDataArray && newDataArray.length > 0) {
            if (type === 'domainOnClick') {
                // const newData = newDataArray
                setDataSource([...filteredArray, ...newDataArray])
            }
        }

    }, [newDataArray, type])

    const homescreen = () => {
        historynew.push(homepage);
        // window.location.href = homepage
        document.getElementById('homehover').style.color = "blue"
    }

    const filterHandle = () => {
        setFilterTaskAcc(true);
        setFilterModalVisible(true);
    };

    const [fullscreen, setfullscreen] = useState(false);

    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
            document.addEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch), false);
            return () => {
                document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch));
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode(dispatch);
            setfullscreen(true);
        } else {
            fullscreenexit(dispatch);
            setfullscreen(false);
        }
    }
    const setcancelFilter = () => {
        setFilterModalVisible(false);
        setFilterTaskAcc(false);
    };
    return (
        <Fragment>
            <div id='page-wrapper' className="page-wrapper">
                {/* <ScrollToTop smooth color="red" component={<Tooltip title="Back to top"><UpOutlined /></Tooltip>} />
                {document.body.scrollHeight > document.body.clientHeight ?
                    <div className='back-to-top'>
                        <ScrollButton />
                    </div> : ''} */}
                {tableshow == true ?
                    <div className='back-to-top'>
                        <ScrollButton />
                    </div> : ''}
                <div className="page-titles pb-0 pt-2 page-title-sticky">
                    <div className="row">
                        <div className="col-lg-8 col-md-6 col-12 align-self-center">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 d-flex align-items-center">
                                    <li className="breadcrumb-item">
                                        <span className='pointer' id='homehover' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        <span style={{ fontSize: '14px', fontWeight: "bold" }}>Report</span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: '15px', fontWeight: 'bold' }}>
                                        <span style={{ fontSize: "15px", fontWeight: "bold" }}>Score Cards</span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                        <span>Domain Score Card</span>
                                    </li>
                                </ol>
                            </nav>

                        </div>
                        <div className="col-lg-4 text-end">
                            <Tooltip title="Advance Filter"> <Button
                                className="mx-1"
                                type="primary"
                                shape="round"
                                style={{
                                    display: filterTaskAcc ? "none" : "initial",
                                }}
                                onClick={filterHandle}
                            >
                                <FilterOutlined /> Filter
                            </Button></Tooltip>
                            <button style={{ float: 'right' }} onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black ms-2 full-mode fullscreenradius" >
                                <Tooltip placement="left" title={is_Fullscreen == true ? "Exit Full Screen Mode" : "Full Screen Mode"}><FullscreenOutlined /></Tooltip>
                            </button>
                        </div>
                    </div>
                </div>
                <Modal title={domainName} className={'class scorecardtable add-service-prv ' + localStorage.getItem('currentTheme')} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null} width={750} maskClosable={false}>
                    <div className={localStorage.getItem('currentTheme')}>
                        <Table className='domaincardtotal'
                            dataSource={dataSource}
                            bordered={true}
                            pagination={false}>
                            <Column title="Unit" dataIndex="unit" key="unit" />
                            <ColumnGroup title="Assigned">
                                <Column title="Inprogress" align='center' dataIndex="inprogress_count" key="inprogress_count" />
                                <Column title="Complied" align='center' dataIndex="complied_count" key="complied_count" />
                                <Column title="Delayed Complied" align='center' dataIndex="delayed_count" key="delayed_count" />
                                <Column title="Not Complied" align='center' dataIndex="overdue_count" key="overdue_count" />
                            </ColumnGroup>
                            <Column title="Un Assigned" align='center' dataIndex="unassigned_count" key="unassigned_count" />
                            <Column title="Not Opted" align='center' dataIndex="not_opted_count" key="not_opted_count" />
                            <Column title="Not Applicable" align='center' dataIndex="not_applicable_count" key="not_applicable_count" />
                            <Column title="Total" align='center' dataIndex={false} key={false}
                                render={(row, record) => <b>{Number(record.inprogress_count) + Number(record.complied_count) + Number(record.delayed_count) + Number(record.overdue_count) + Number(record.unassigned_count) + Number(record.not_opted_count) + Number(record.not_applicable_count)}</b>}
                            />
                        </Table>
                    </div>
                </Modal>
                <div className="container-fluid pt-1">
                    <div className="row">
                        <div className="col-12">
                            <div className="card-body" id='only-restricted' style={{ padding: "8px 1px" }}>
                                <div className={localStorage.getItem('currentTheme')}>
                                    <Collapse defaultActiveKey={["1"]} className='report-collapse' onChange={onChange} style={{ display: exitCollapse ? "none" : "block" }}>
                                        <Panel header="Domain Score Card" key={key}>
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className='col-md-4'>
                                                        <label><b>Country : </b> <span style={{ color: "red" }}>*</span></label>
                                                        <div className="form-group">
                                                            {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                                <div className="form-group">
                                                                    <Select
                                                                        allowClear={true}
                                                                        size="default"
                                                                        placeholder="Enter Country"
                                                                        onDrop={(e) => {
                                                                            e.preventDefault();
                                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                            return false;
                                                                        }}
                                                                        // onPaste={(e) => {
                                                                        //     handlePasting(e, /^[a-z]+$/gi)
                                                                        // }}
                                                                        onPaste={onPasteToaster}
                                                                        searchValue={selectSearchValue.country}
                                                                        onSearch={(value) => {
                                                                            let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                                                            setSelectSearchValue({
                                                                                ...selectSearchValue,
                                                                                country: result
                                                                            });
                                                                        }}
                                                                        onChange={(value, data) => {
                                                                            if (value !== undefined) {
                                                                                let le = _.filter(sessionArr, { c_id: Number(value) })
                                                                                setEntity(le)
                                                                            }
                                                                            setDomainCard({
                                                                                ...domainCard,
                                                                                country: value != undefined ? value : '',
                                                                                countryName: data != undefined ? data.children : '',
                                                                                legel_entity: '',
                                                                                le_id: '',
                                                                                category: '',
                                                                                categoryName: '',
                                                                                businessGroup: '',
                                                                                businessGroupName: '',
                                                                                division: '',
                                                                                divisionName: '',
                                                                                domain: '',
                                                                                domainName: ''
                                                                            })
                                                                        }}
                                                                        showSearch
                                                                        optionFilterProp="children"
                                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                        //value={Settings.le_id}
                                                                        style={{ width: '100%', marginTop: '3px' }}
                                                                        value={domainCard.countryName || undefined}
                                                                    >
                                                                        {uniq_country_data && uniq_country_data.length && uniq_country_data.map((item, i) => {
                                                                            return (
                                                                                <Option key={item.c_id}>
                                                                                    {item.c_name}
                                                                                </Option>
                                                                            );
                                                                        })}
                                                                    </Select>
                                                                    {validator.current.message(
                                                                        'countryName',
                                                                        domainCard.countryName,
                                                                        'required',
                                                                        {
                                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                            messages: {
                                                                                required: 'Country Required',
                                                                            }
                                                                        })}
                                                                </div> :
                                                                <p>{domainCard.countryName}</p>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4 text-wrap-report'>
                                                        <label><b>Legal Entity : </b>  <span style={{ color: "red" }}>*</span></label>
                                                        {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                            <div className="form-group">
                                                                <Select
                                                                    allowClear={true}
                                                                    size="default"
                                                                    placeholder="Enter Legal Entity"
                                                                    onDrop={(e) => {
                                                                        e.preventDefault();
                                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                        return false;
                                                                    }}
                                                                    // onPaste={(e) => {
                                                                    //     handlePasting(e, /^[a-z 0-9 ()]+$/gi)
                                                                    // }}
                                                                    onPaste={onPasteToaster}
                                                                    searchValue={selectSearchValue.legalEntity}
                                                                    onSearch={(value) => {
                                                                        let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                                        setSelectSearchValue({
                                                                            ...selectSearchValue,
                                                                            legalEntity: result
                                                                        });
                                                                    }}
                                                                    onChange={(data, value) => {
                                                                        setCurrentEntity(data != undefined ? data : localStorage.getItem('SelectedEntityid'))
                                                                        setDomainCard({
                                                                            ...domainCard,
                                                                            le_id: data != undefined ? data : '',
                                                                            legel_entity: value != undefined ? value.children : '',
                                                                            category: '',
                                                                            categoryName: '',
                                                                            division: '',
                                                                            divisionName: '',
                                                                            domain: '',
                                                                            domainName: ''
                                                                        })
                                                                        setCategoryList([])
                                                                    }}
                                                                    showSearch
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                    //value={Settings.le_id}
                                                                    style={{ width: '100%', marginTop: '3px' }}
                                                                    value={domainCard.legel_entity || undefined}
                                                                    disabled={domainCard.country == '' ? true : false}
                                                                >
                                                                    {entity && entity.length && entity.map((item, i) => {
                                                                        return (
                                                                            <Option key={item.le_id}>
                                                                                {item.le_name}
                                                                            </Option>
                                                                        );
                                                                    })}
                                                                </Select>
                                                                {validator.current.message(
                                                                    'legalentity',
                                                                    domainCard.legel_entity,
                                                                    'required',
                                                                    {
                                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                        messages: {
                                                                            required: 'Legal Entity Required',
                                                                        }
                                                                    })}
                                                            </div> :
                                                            <p>{localStorage.getItem('SelectedEntity')}</p>
                                                        }
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label><b>Division : </b></label>
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={true}
                                                                onDrop={(e) => {
                                                                    e.preventDefault();
                                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                    return false;
                                                                }}
                                                                // onPaste={(e) => {
                                                                //     handlePasting(e, /^[a-z 0-9]+$/gi)
                                                                // }}
                                                                onPaste={onPasteToaster}
                                                                searchValue={selectSearchValue.division}
                                                                onSearch={(value) => {
                                                                    let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                                    setSelectSearchValue({
                                                                        ...selectSearchValue,
                                                                        division: result
                                                                    });
                                                                }}
                                                                onChange={(value, data) => {
                                                                    setDomainCard({
                                                                        ...domainCard,
                                                                        division: value != undefined ? value : '',
                                                                        divisionName: data != undefined ? data.children : ''
                                                                    })
                                                                    let filteredCategory = _.filter(domain_data.cat_infos, { div_id: Number(value) })
                                                                    setCategoryList(filteredCategory)

                                                                }}
                                                                placeholder="Enter Division"
                                                                style={{ width: '100%', marginTop: '3px' }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                value={domainCard.divisionName || undefined}
                                                                disabled={domainCard.le_id == '' ? true : false}


                                                            >
                                                                {divisonList && divisonList.length && divisonList.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.div_id}>
                                                                            {item.div_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                        </div>
                                                        {/* {validator.current.message(
                                                        'divisionName',
                                                        domainCard.divisionName,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Required Field Missing',
                                                            }
                                                        })} */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12 mt-2">
                                                <div className="row">
                                                    <div className='col-md-4'>
                                                        <label><b>Business Group : </b></label>
                                                        <div className="form-group">
                                                            {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                                <div className="form-group">
                                                                    <Select
                                                                        allowClear={true}
                                                                        size="default"
                                                                        placeholder="Enter Business Group"
                                                                        onDrop={(e) => {
                                                                            e.preventDefault();
                                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                            return false;
                                                                        }}
                                                                        // onPaste={(e) => {
                                                                        //     handlePasting(e, /^[a-z 0-9]+$/gi)
                                                                        // }}
                                                                        onPaste={onPasteToaster}
                                                                        searchValue={selectSearchValue.BusinessGroup}
                                                                        onSearch={(value) => {
                                                                            let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                                            setSelectSearchValue({
                                                                                ...selectSearchValue,
                                                                                BusinessGroup: result
                                                                            });
                                                                        }}
                                                                        onChange={(value, data) => {
                                                                            if (data == undefined) {
                                                                                setDomainCard({
                                                                                    ...domainCard,
                                                                                    businessGroup: '',
                                                                                    businessGroupName: '',
                                                                                    legel_entity: '',
                                                                                    le_id: ''
                                                                                })
                                                                                let le = _.filter(sessionArr, { c_id: Number(domainCard.country) })
                                                                                setEntity(le)
                                                                            } else {
                                                                                setDomainCard({
                                                                                    ...domainCard,
                                                                                    businessGroup: value != undefined ? value : '',
                                                                                    businessGroupName: data != undefined ? data.children : '',
                                                                                    legel_entity: '',
                                                                                    le_id: ''
                                                                                })
                                                                                console.log(sessionArr, value, 'check')
                                                                                let le = _.filter(sessionArr, { bg_id: Number(value) })
                                                                                setEntity(le)
                                                                            }
                                                                        }}
                                                                        showSearch
                                                                        optionFilterProp="children"
                                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                        value={domainCard.businessGroup || undefined}
                                                                        style={{ width: '100%', marginTop: '3px' }}

                                                                        disabled={domainCard.country == '' ? true : false}

                                                                    // value={domainCard.countryName}
                                                                    >
                                                                        {bggroup && bggroup.length && bggroup.map((item, i) => {
                                                                            if (item.bg_id != null) {
                                                                                return (
                                                                                    <Option key={item.bg_id}>
                                                                                        {item.bg_name}
                                                                                    </Option>
                                                                                );
                                                                            }
                                                                        })}
                                                                    </Select>
                                                                    {/* {validator.current.message(
                                                                    'businessGroupName',
                                                                    domainCard.businessGroupName,
                                                                    'required',
                                                                    {
                                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                        messages: {
                                                                            required: 'Business Group Required',
                                                                        }
                                                                    })} */}
                                                                </div> :
                                                                <p>{businessName ? businessName : '-'}</p>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label><b>Category : </b></label>
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                placeholder="Enter Category"
                                                                onDrop={(e) => {
                                                                    e.preventDefault();
                                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                    return false;
                                                                }}
                                                                // onPaste={(e) => {
                                                                //     handlePasting(e, /^[a-z 0-9]+$/gi)
                                                                // }}
                                                                onPaste={onPasteToaster}
                                                                searchValue={selectSearchValue.category}
                                                                onSearch={(value) => {
                                                                    let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                                    setSelectSearchValue({
                                                                        ...selectSearchValue,
                                                                        category: result
                                                                    });
                                                                }}
                                                                onChange={(data, value) => {
                                                                    setDomainCard({
                                                                        ...domainCard,
                                                                        category: data != undefined ? data : '',
                                                                        categoryName: value != undefined ? value.children : ''
                                                                    })
                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                //value={Settings.le_id}
                                                                businessGroupName
                                                                value={domainCard.categoryName || undefined}
                                                                disabled={domainCard.le_id == '' ? true : false}
                                                                style={{ width: '100%', marginTop: '3px' }}

                                                            >
                                                                {categoryList && categoryList.length && categoryList.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.cat_id}>
                                                                            {item.cat_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label><b>Domain : </b></label>
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={true}
                                                                onDrop={(e) => {
                                                                    e.preventDefault();
                                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                    return false;
                                                                }}
                                                                // onPaste={(e) => {
                                                                //     handlePasting(e, /^[a-z - .]+$/gi)
                                                                // }}
                                                                onPaste={onPasteToaster}
                                                                searchValue={selectSearchValue.domain}
                                                                onSearch={(value) => {
                                                                    let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                                                    setSelectSearchValue({
                                                                        ...selectSearchValue,
                                                                        domain: result
                                                                    });
                                                                }}
                                                                onChange={(value, data) => {
                                                                    setDomainCard({
                                                                        ...domainCard,
                                                                        domain: value != undefined ? value : '',
                                                                        domainName: data != undefined ? data.children : ''
                                                                    })
                                                                }}
                                                                placeholder="Enter Domain"
                                                                businessGroupName
                                                                value={domainCard.domainName || undefined}
                                                                disabled={domainCard.le_id == '' ? true : false}
                                                                style={{ width: '100%', marginTop: '3px' }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                                                            >
                                                                {domainList && domainList.length && domainList.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.d_id}>
                                                                            {item.d_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                        </div>
                                                        {/* {validator.current.message(
                                                        'domain',
                                                        domainCard.domain,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Required Field Missing',
                                                            }
                                                        })} */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={' popupbtncolour text-center ' + localStorage.getItem('currentTheme')} style={{ marginTop: "7px" }}>
                                                <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')} style={{ background: "#0096FF", borderColor: "#0096FF" }}
                                                    icon={<EyeOutlined />} onClick={onshow}
                                                >
                                                    Show
                                                </Button>
                                                {/* <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')} style={{ background: "#32a852", borderColor: "#32a852", width: "100px" }}
                                                    // onClick={showModal}
                                                    >
                                                        Export
                                                    </Button> */}
                                            </div>
                                        </Panel>
                                    </Collapse>
                                    <Modal
                                        onCancel={setcancelFilter}
                                        footer={false}
                                        title="Domain Score Card"
                                        className={
                                            "add-service-prv cons-report " +
                                            localStorage.getItem("currentTheme")
                                        }
                                        open={filterModalVisible}
                                        maskClosable={false}
                                    >
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className='col-md-4'>
                                                    <label><b>Country : </b> <span style={{ color: "red" }}>*</span></label>
                                                    <div className="form-group">
                                                        {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                            <div className="form-group">
                                                                <Select
                                                                    allowClear={true}
                                                                    size="default"
                                                                    placeholder="Enter Country"
                                                                    onDrop={(e) => {
                                                                        e.preventDefault();
                                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                        return false;
                                                                    }}
                                                                    // onPaste={(e) => {
                                                                    //     handlePasting(e, /^[a-z]+$/gi)
                                                                    // }}
                                                                    onPaste={onPasteToaster}
                                                                    searchValue={selectSearchValue.country}
                                                                    onSearch={(value) => {
                                                                        let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                                                        setSelectSearchValue({
                                                                            ...selectSearchValue,
                                                                            country: result
                                                                        });
                                                                    }}
                                                                    onChange={(value, data) => {
                                                                        if (value !== undefined) {
                                                                            let le = _.filter(sessionArr, { c_id: Number(value) })
                                                                            setEntity(le)
                                                                        }
                                                                        setDomainCard({
                                                                            ...domainCard,
                                                                            country: value != undefined ? value : '',
                                                                            countryName: data != undefined ? data.children : '',
                                                                            legel_entity: '',
                                                                            le_id: '',
                                                                            category: '',
                                                                            categoryName: '',
                                                                            businessGroup: '',
                                                                            businessGroupName: '',
                                                                            division: '',
                                                                            divisionName: '',
                                                                            domain: '',
                                                                            domainName: ''
                                                                        })
                                                                    }}
                                                                    showSearch
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                    //value={Settings.le_id}
                                                                    style={{ width: '100%', marginTop: '3px' }}
                                                                    value={domainCard.countryName || undefined}
                                                                    getPopupContainer={trigger => trigger.parentNode}
                                                                >
                                                                    {uniq_country_data && uniq_country_data.length && uniq_country_data.map((item, i) => {
                                                                        return (
                                                                            <Option key={item.c_id}>
                                                                                {item.c_name}
                                                                            </Option>
                                                                        );
                                                                    })}
                                                                </Select>
                                                                {validator.current.message(
                                                                    'countryName',
                                                                    domainCard.countryName,
                                                                    'required',
                                                                    {
                                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                        messages: {
                                                                            required: 'Country Required',
                                                                        }
                                                                    })}
                                                            </div> :
                                                            <p>{domainCard.countryName}</p>
                                                        }
                                                    </div>
                                                </div>
                                                <div className='col-md-4 text-wrap-report'>
                                                    <label><b>Legal Entity : </b>  <span style={{ color: "red" }}>*</span></label>
                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                placeholder="Enter Legal Entity"
                                                                onDrop={(e) => {
                                                                    e.preventDefault();
                                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                    return false;
                                                                }}
                                                                // onPaste={(e) => {
                                                                //     handlePasting(e, /^[a-z 0-9 ()]+$/gi)
                                                                // }}
                                                                onPaste={onPasteToaster}
                                                                searchValue={selectSearchValue.legalEntity}
                                                                onSearch={(value) => {
                                                                    let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                                    setSelectSearchValue({
                                                                        ...selectSearchValue,
                                                                        legalEntity: result
                                                                    });
                                                                }}
                                                                onChange={(data, value) => {
                                                                    setCurrentEntity(data != undefined ? data : localStorage.getItem('SelectedEntityid'))
                                                                    setDomainCard({
                                                                        ...domainCard,
                                                                        le_id: data != undefined ? data : '',
                                                                        legel_entity: value != undefined ? value.children : '',
                                                                        category: '',
                                                                        categoryName: '',
                                                                        division: '',
                                                                        divisionName: '',
                                                                        domain: '',
                                                                        domainName: ''
                                                                    })
                                                                    setCategoryList([])
                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                //value={Settings.le_id}
                                                                style={{ width: '100%', marginTop: '3px' }}
                                                                value={domainCard.legel_entity || undefined}
                                                                disabled={domainCard.country == '' ? true : false}
                                                                getPopupContainer={trigger => trigger.parentNode}>
                                                                {entity && entity.length && entity.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.le_id}>
                                                                            {item.le_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                            {validator.current.message(
                                                                'legalentity',
                                                                domainCard.legel_entity,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Legal Entity Required',
                                                                    }
                                                                })}
                                                        </div> :
                                                        <p>{localStorage.getItem('SelectedEntity')}</p>
                                                    }
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Division : </b></label>
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={true}
                                                            onDrop={(e) => {
                                                                e.preventDefault();
                                                                // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                return false;
                                                            }}
                                                            // onPaste={(e) => {
                                                            //     handlePasting(e, /^[a-z 0-9]+$/gi)
                                                            // }}
                                                            onPaste={onPasteToaster}
                                                            searchValue={selectSearchValue.division}
                                                            onSearch={(value) => {
                                                                let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                                setSelectSearchValue({
                                                                    ...selectSearchValue,
                                                                    division: result
                                                                });
                                                            }}
                                                            onChange={(value, data) => {
                                                                setDomainCard({
                                                                    ...domainCard,
                                                                    division: value != undefined ? value : '',
                                                                    divisionName: data != undefined ? data.children : ''
                                                                })
                                                                let filteredCategory = _.filter(domain_data.cat_infos, { div_id: Number(value) })
                                                                setCategoryList(filteredCategory)

                                                            }}
                                                            placeholder="Enter Division"
                                                            style={{ width: '100%', marginTop: '3px' }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            value={domainCard.divisionName || undefined}
                                                            disabled={domainCard.le_id == '' ? true : false}
                                                            getPopupContainer={trigger => trigger.parentNode}>
                                                            {divisonList && divisonList.length && divisonList.map((item, i) => {
                                                                return (
                                                                    <Option key={item.div_id}>
                                                                        {item.div_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>
                                                    {/* {validator.current.message(
                                                        'divisionName',
                                                        domainCard.divisionName,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Required Field Missing',
                                                            }
                                                        })} */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mt-1">
                                            <div className="row">
                                                <div className='col-md-4'>
                                                    <label><b>Business Group : </b></label>
                                                    <div className="form-group">
                                                        {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                            <div className="form-group">
                                                                <Select
                                                                    allowClear={true}
                                                                    size="default"
                                                                    placeholder="Enter Business Group"
                                                                    onDrop={(e) => {
                                                                        e.preventDefault();
                                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                        return false;
                                                                    }}
                                                                    // onPaste={(e) => {
                                                                    //     handlePasting(e, /^[a-z 0-9]+$/gi)
                                                                    // }}
                                                                    onPaste={onPasteToaster}
                                                                    searchValue={selectSearchValue.BusinessGroup}
                                                                    onSearch={(value) => {
                                                                        let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                                        setSelectSearchValue({
                                                                            ...selectSearchValue,
                                                                            BusinessGroup: result
                                                                        });
                                                                    }}
                                                                    onChange={(value, data) => {
                                                                        if (data == undefined) {
                                                                            setDomainCard({
                                                                                ...domainCard,
                                                                                businessGroup: '',
                                                                                businessGroupName: '',
                                                                                legel_entity: '',
                                                                                le_id: ''
                                                                            })
                                                                            let le = _.filter(sessionArr, { c_id: Number(domainCard.country) })
                                                                            setEntity(le)
                                                                        } else {
                                                                            setDomainCard({
                                                                                ...domainCard,
                                                                                businessGroup: value != undefined ? value : '',
                                                                                businessGroupName: data != undefined ? data.children : '',
                                                                                legel_entity: '',
                                                                                le_id: ''
                                                                            })
                                                                            
                                                                            let le = _.filter(sessionArr, { bg_id: Number(value) })
                                                                            setEntity(le)
                                                                        }
                                                                    }}
                                                                    showSearch
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                    value={domainCard.businessGroupName || undefined}
                                                                    style={{ width: '100%', marginTop: '3px' }}
                                                                    getPopupContainer={trigger => trigger.parentNode}
                                                                    disabled={domainCard.country == '' ? true : false}

                                                                // value={domainCard.countryName}
                                                                >
                                                                    {bggroup && bggroup.length && bggroup.map((item, i) => {
                                                                        if (item.bg_id != null) {
                                                                            return (
                                                                                <Option key={item.bg_id}>
                                                                                    {item.bg_name}
                                                                                </Option>
                                                                            );
                                                                        }
                                                                    })}
                                                                </Select>
                                                                {/* {validator.current.message(
                                                                    'businessGroupName',
                                                                    domainCard.businessGroupName,
                                                                    'required',
                                                                    {
                                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                        messages: {
                                                                            required: 'Business Group Required',
                                                                        }
                                                                    })} */}
                                                            </div> :
                                                            <p>{businessName ? businessName : '-'}</p>
                                                        }
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Category : </b></label>
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Enter Category"
                                                            onDrop={(e) => {
                                                                e.preventDefault();
                                                                // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                return false;
                                                            }}
                                                            // onPaste={(e) => {
                                                            //     handlePasting(e, /^[a-z 0-9]+$/gi)
                                                            // }}
                                                            onPaste={onPasteToaster}
                                                            searchValue={selectSearchValue.category}
                                                            onSearch={(value) => {
                                                                let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                                setSelectSearchValue({
                                                                    ...selectSearchValue,
                                                                    category: result
                                                                });
                                                            }}
                                                            onChange={(data, value) => {
                                                                setDomainCard({
                                                                    ...domainCard,
                                                                    category: data != undefined ? data : '',
                                                                    categoryName: value != undefined ? value.children : ''
                                                                })
                                                            }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            //value={Settings.le_id}
                                                            businessGroupName
                                                            value={domainCard.categoryName || undefined}
                                                            disabled={domainCard.le_id == '' ? true : false}
                                                            style={{ width: '100%', marginTop: '3px' }}
                                                            getPopupContainer={trigger => trigger.parentNode}
                                                        >
                                                            {categoryList && categoryList.length && categoryList.map((item, i) => {
                                                                return (
                                                                    <Option key={item.cat_id}>
                                                                        {item.cat_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Domain : </b></label>
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={true}
                                                            onDrop={(e) => {
                                                                e.preventDefault();
                                                                // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                return false;
                                                            }}
                                                            // onPaste={(e) => {
                                                            //     handlePasting(e, /^[a-z - .]+$/gi)
                                                            // }}
                                                            onPaste={onPasteToaster}
                                                            searchValue={selectSearchValue.domain}
                                                            onSearch={(value) => {
                                                                let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                                                setSelectSearchValue({
                                                                    ...selectSearchValue,
                                                                    domain: result
                                                                });
                                                            }}
                                                            onChange={(value, data) => {
                                                                setDomainCard({
                                                                    ...domainCard,
                                                                    domain: value != undefined ? value : '',
                                                                    domainName: data != undefined ? data.children : ''
                                                                })
                                                            }}
                                                            placeholder="Enter Domain"
                                                            businessGroupName
                                                            value={domainCard.domainName || undefined}
                                                            disabled={domainCard.le_id == '' ? true : false}
                                                            style={{ width: '100%', marginTop: '3px' }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            getPopupContainer={trigger => trigger.parentNode}
                                                        >
                                                            {domainList && domainList.length && domainList.map((item, i) => {
                                                                return (
                                                                    <Option key={item.d_id}>
                                                                        {item.d_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>
                                                    {/* {validator.current.message(
                                                        'domain',
                                                        domainCard.domain,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Required Field Missing',
                                                            }
                                                        })} */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={' popupbtncolour text-center ' + localStorage.getItem('currentTheme')} style={{ marginTop: "5px" }}>
                                            <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')} style={{ background: "#0096FF", borderColor: "#0096FF" }}
                                                icon={<EyeOutlined />} onClick={onshow}
                                            >
                                                Show
                                            </Button>
                                            {/* <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')} style={{ background: "#32a852", borderColor: "#32a852", width: "100px" }}
                                                    // onClick={showModal}
                                                    >
                                                        Export
                                                    </Button> */}
                                        </div>
                                    </Modal>
                                </div>

                                {tableshow == true ?
                                    <Card className='rounded-3'>

                                        <div >
                                            <div className='col-md-12 mt-1 mb-1 filter-detial-sticky' >
                                                <div className='row'>
                                                    <div className='col-md-6 text-wrap-report'>
                                                        <label><b>Legal Entity :</b> {localStorage.getItem('SelectedEntity') === 'All Legal Entity' ? domainCard.legalEntityLabel : localStorage.getItem('SelectedEntity')}</label>
                                                    </div>
                                                    <div className='col-md-6 text-end' >
                                                        <label><b>Country :</b> {domainCard.countryLabel}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={localStorage.getItem('currentTheme')}>
                                                <div className='ant-table-wrapper domaincardtotal'>
                                                    <div className='ant-table ant-table-bordered'>
                                                        <div className='ant-table-container'>
                                                            <div className='ant-table-content scorecardtable'>
                                                                <table>
                                                                    <colgroup>
                                                                        <col style={{ width: '10px' }}></col>
                                                                    </colgroup>
                                                                    <thead className="ant-table-thead table-head-stick">
                                                                        <tr>
                                                                            <th className='ant-table-cell w10px'>#</th>
                                                                            <th className='ant-table-cell custom-width1'>Domain</th>
                                                                            <th className='ant-table-cell custom-width1'>Assigned</th>
                                                                            <th className='ant-table-cell custom-width1'><center>Un Assigned</center></th>
                                                                            <th className='ant-table-cell custom-width1'>Not Opted</th>
                                                                            <th className='ant-table-cell custom-width1'>Not Applicable</th>
                                                                            <th className='ant-table-cell custom-width1'>Total</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className='ant-table-tbody'>
                                                                        {tableData && tableData.domain_score_card_list && tableData.domain_score_card_list.length > 0 ? tableData && tableData.domain_score_card_list && tableData.domain_score_card_list.length > 0 && tableData.domain_score_card_list.map((item, i) => {
                                                                            return (
                                                                                <tr>
                                                                                    <td className='ant-table-cell'><center>{i + 1}</center></td>
                                                                                    <td className='ant-table-cell'><center><a href="javascript:;" onClick={() => {
                                                                                        setFilteredArray(item.units_wise_count)
                                                                                        showModal()
                                                                                        setDomainName(item.domain_name)
                                                                                        setType('domainOnClick')
                                                                                        setTotal(Number(item.assigned_count) + Number(item.unassigned_count) + Number(item.not_opted_count) + Number(item.not_applicable_count))
                                                                                    }}>{item.domain_name}</a></center></td>
                                                                                    <td className='ant-table-cell'><center>{item.assigned_count}</center></td>
                                                                                    <td className='ant-table-cell'><center>{item.unassigned_count}</center></td>
                                                                                    <td className='ant-table-cell'><center>{item.not_opted_count}</center></td>
                                                                                    <td className='ant-table-cell'><center>{item.not_applicable_count}</center></td>
                                                                                    <td className='ant-table-cell'><center><b>{Number(item.assigned_count) + Number(item.unassigned_count) + Number(item.not_opted_count) + Number(item.not_applicable_count)}</b></center></td>
                                                                            
                                                                                </tr>
                                                                            )
                                                                        }) :
                                                                            <tr>
                                                                                <td colSpan="100%" style={{ paddingTop: '15px', textAlign: 'center', fontSize: '14px', fontWeight: 'bold', paddingBottom: '15px' }}>No Records Found</td>
                                                                            </tr>
                                                                        }


                                                                        {(tableData && tableData.domain_score_card_list && tableData.domain_score_card_list.length > 1) && (totalArray && totalArray.length > 0) ?

                                                                            <tr>
                                                                                <td className='ant-table-cell'><b><center></center></b></td>
                                                                                <td className='ant-table-cell'><b><center>Total</center></b></td>
                                                                                <td className='ant-table-cell'><center>{totalArray && totalArray[0].assigned_count}</center></td>
                                                                                <td className='ant-table-cell'><center>{totalArray && totalArray[0].unassigned_count}</center></td>
                                                                                <td className='ant-table-cell'><center>{totalArray && totalArray[0].not_opted_count}</center></td>
                                                                                <td className='ant-table-cell'><center>{totalArray && totalArray[0].not_applicable_count}</center></td>
                                                                                <td className='ant-table-cell'><center>{totalArray && totalArray[0].total}</center></td>
                                                                            </tr> : ''}


                                                                      
                                                                    </tbody>

                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </Card>
                                    : false}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default connect(mapStateToProps, {
    domainCardData,
    GetDomainCardData
})(DomainCard);