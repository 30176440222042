import { Fragment, useState, useEffect, useRef, useMemo } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useHistory } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import DataTable from "react-data-table-component";
import Swal from 'sweetalert2';
import { Modal, Input, Table, Button, Space, Tooltip, Select, InputNumber, DatePicker, Collapse } from 'antd';
import { certificatelist, certificateadd, certificateupdate, updatestatus } from './../../Store/Action/Master/CV_Certificate_Label';
import { GetRemarksData } from './../../Store/Action/Transcations/Taskrecall'
import { PlayCircleOutlined, PlusOutlined, EditOutlined, StopOutlined, SearchOutlined, FilterFilled, PlusCircleOutlined, UpOutlined, FullscreenOutlined, CloseCircleOutlined, CloseOutlined } from '@ant-design/icons';
import _, { merge } from 'lodash'
import { fullscreenmode, fullscreenexit, handleKeyDown } from "./../../Libs/fullscreenmode";
import ScrollToTop from 'react-scroll-to-top';
import { RESET_INVALID } from '../../Store/types';
import { Toaster } from '../../Libs/Toaster';
import { HomeRedirect } from './../../Libs/country'
const homepage = HomeRedirect()


const mapStateToProps = (state) => ({
    lists: state.CertificateLabel.list,
    invalidpassword: state.CertificateLabel.invalidpass,
    filterrecall: state.recallfilters.recallfilters
})
const CertificateLabel = ({
    certificatelist,
    lists,
    certificateadd,
    certificateupdate,
    invalidpassword,
    updatestatus,
    updateblock,
    GetRemarksData,
    filterrecall: { recallremarks },
}) => {
    const historynew = useHistory();
    console.log(invalidpassword, 'invalidpassword');
    const { TextArea } = Input;
    const { Panel } = Collapse;
    const formValidator = useRef(new SimpleReactValidator());
    const formValidator1 = useRef(new SimpleReactValidator());
    const formValidator2 = useRef(new SimpleReactValidator());
    const location = useLocation();
    const is_Fullscreen = useSelector((state) => state.Login.is_fullscreen);
    const paramid = location.pathname;
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [AddFormSubmit1, setAddFormSubmit1] = useState(false);
    const [AddFormSubmit2, setAddFormSubmit2] = useState(false);
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const dispatch = useDispatch();
    const [modalVisible, SetmodalVisible] = useState(false);
    const [Add, SetAdd] = useState(false)
    const [passvisible, Setpassvisible] = useState(false);
    const [blockvisible, Setblockvisible] = useState(false);
    const [remarksvalue, Setremarksvalue] = useState();
    const [labelname, setlabelname] = useState('add');
    console.log(labelname, "labelnamelabelname");
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true,
        pageSizeOptions: ['10', '30', '50']
    })
    const { Search } = Input;
    const [remarksModal, setRemarksModal] = useState(false);
    const [remarks, setRemarks] = useState('')
    const [page, setPage] = useState(1);
    const [baseData, setBaseData] = useState([]);
    const [baseData1, setBaseData1] = useState([]);
    const [passwordata, Setpassword] = useState({
        passvalue: '',
        sp_id: '',
        active_status: ''
    })

    // Auto Focus 
    const serviceProviderRef = useRef(null);
    const setFocus = (ref) => {
        setTimeout(() => {
            ref.current.focus();
        });
    };

    const [shortName, setShortName] = useState('')

    const [blockdata, Setblockdata] = useState({
        "sp_id": '',
        "is_blocked": '',
        "remarks": '',
        "password": ''
    })

    const modalcancel = () => {
        setServiceProviderDetails({
            ...serviceProviderDetails,
            provider_name: '',
        })
        setAddFormSubmit(false);
        SetmodalVisible(false);
    }

    const addopen = () => {
        setAddFormSubmit(false)
        SetAdd(true)
        setServiceProviderDetails({
            ...serviceProviderDetails,
            provider_name: ''
        })

    }

    const passwordcancel = () => {
        setAddFormSubmit1(false)
        Setpassvisible(false);
        Setpassword({
            ...passwordata,
            passvalue: ""

        })
    }

    const blockcancel = () => {
        Setblockvisible(false);
        Setblockdata({
            ...blockdata,
            password: '',
            remarks: ''
        })
        setAddFormSubmit2(false);


    }

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (e, confirm, dataIndex, setSelectedKeys) => {
        if (e) {
            setSelectedKeys(e.target.value ? [e.target.value] : [])
            confirm({ closeDropdown: false });
            // setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex)
        } else {
            setSelectedKeys([])
            confirm({ closeDropdown: false });
            // setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex)
        }
    };
    const clearFocus = (searchInput) => {
        setTimeout(() => searchInput.current?.select(), 100);
    }

    const getColumnSearchProps = (dataIndex, placeholder) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div className='d-flex' style={{ position: 'relative' }}>
                <Input className='form-control'
                    ref={searchInput}
                    placeholder={'Search ' + placeholder}
                    value={selectedKeys[0]}
                    autoFocus={'on'}
                    // onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onChange={(e) => handleSearch(e, confirm, dataIndex, setSelectedKeys)}
                />
                <CloseCircleOutlined className='table-filter-reset'
                    onClick={() => {
                        handleReset(clearFilters,
                            handleSearch, confirm, dataIndex, setSelectedKeys)
                        clearFocus(searchInput)
                    }
                    } />

            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    //     // color: filtered ? '#1890ff' : undefined,
                    //     fontWeight: 'bold',
                    //     // backgroundColor:'blue',
                    //     fontSize: '15px',
                    //     color: 'black'
                }}
            />
            // <FileSearchOutlined 
            // style={{
            //     // color: filtered ? '#1890ff' : undefined,
            //     fontWeight:'bold',
            //     // backgroundColor:'blue',
            //     // fontSize:'20px',
            //     color:'black'
            // }}
            // />
            // <i class="bi bi-search"></i>
        ),
        onFilter: (value, record) =>
            record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text
    });

    const onInputChange = e => {
        const { value } = e.target;
        const re = /^[a-zA-Z0-9,.-_]*$/;
        if (re.test(value) === true) {
            // setShortName(true)
            setServiceProviderDetails({
                ...serviceProviderDetails,
                [e.target.name]: e.target.value
            })
        }
        else {
            // setShortName(false)
            setServiceProviderDetails({
                ...serviceProviderDetails,
                [e.target.name]: e.target.value
            })
        }
    }

    const Activate = () => {

        Swal.fire({
            title: 'Are You Sure?',
            icon: 'success',
            text: 'You want to deactivate',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            customClass: localStorage.getItem("currentTheme") == 'theme_four' ? "customSWal" : 'customSWal2',
            confirmButtonColor: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 '
        }).then((result) => {
            if (result.isConfirmed) {
                Setpassvisible(true);
                setFocus(serviceProviderRef)


            }
        });
    };

    const Deactivate = () => {

        Swal.fire({
            title: 'Are You Sure?',
            icon: 'success',
            text: 'You want to activate',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            customClass: localStorage.getItem("currentTheme") == 'theme_four' ? "customSWal" : 'customSWal2',
            confirmButtonColor: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 '
        }).then((result) => {
            if (result.isConfirmed) {
                Setpassvisible(true);
                setFocus(serviceProviderRef)

            }
        });
    };

    const handleReset = (clearFilters, handleSearch, confirm, dataIndex, setSelectedKeys) => {
        handleSearch('', confirm, dataIndex, setSelectedKeys)
        clearFilters();
        setSearchText('');
    };

    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});

    const handleChange = (pagination, filters, sorter) => {
        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };

    const [paginationSize, setPaginationSize] = useState(25);

    // Full Screen Mode
    const [fullscreen, setfullscreen] = useState(false);

    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
            document.addEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch), false);
            return () => {
                document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch));
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode(dispatch);
            setfullscreen(true);
        } else {
            fullscreenexit(dispatch);
            setfullscreen(false);
        }
    }


    const columns = [
        {
            title: 'S.No',
            key: 'index',
            align: "center",
            width: '40px',
            render: (text, record, index) => {
                return (
                    (page - 1) * paginationSize + index + 1
                )
            },
        },
        {
            // title: 'Label Name',
            title: <><span style={{ marginRight: '15px' }} className="btn btn-sm btn-success text-white btn-circle1 align-items-center justify-content-center">
                <i class="ri-user-follow-fill"></i></span>
                <span style={{ marginLeft: '5px' }} >Active Label Name</span></>,
            dataIndex: 'desc',
            key: 'desc',
            align: "center",
            ...getColumnSearchProps('desc', 'Label Name'),
            ellipsis: true,
            width: '300px',
            sorter: (a, b) => a.desc.localeCompare(b.desc)

        },
        {
            title: 'Edit',
            dataIndex: 'false',
            key: 'false',
            align: "center",
            width: '50px',
            ellipsis: false,
            render: (text, record) => {
                console.log(record, "record111");
                return (
                    <Fragment>
                        {record.isactive === true ?
                            <div className='hover'>
                                <span className="social-link btn btn-sm btn-light-info text-info btn-circle d-flex 
                                align-items-center justify-content-center text-center"
                                    onClick={(e) => { editservice(record) }} style={{ marginInline: 'auto' }}>
                                    <Tooltip title="Click here to Edit">
                                        <EditOutlined /></Tooltip>
                                </span>
                            </div> :
                            <div>
                                <span className="social-link btn btn-sm btn-light-info text-info btn-circle d-flex 
                                align-items-center justify-content-center text-center" style={{ marginInline: 'auto' }} >
                                    <EditOutlined style={{ cursor: 'not-allowed' }} />
                                </span>
                            </div>
                        }

                    </Fragment>
                )
            }
        },
        {
            title: "Status",
            dataIndex: 'false',
            key: 'false',
            align: "center",
            width: '60px',
            ellipsis: false,
            render: (text, record) => {

                return (
                    <Fragment>
                        <div className='row hover'>
                            {/* {record.isactive === true ?
                                <span className="btn btn-sm btn-light-danger text-danger btn-circle d-flex align-items-center justify-content-center" onClick={(e) => { statuschange(record) }}><Tooltip title="Click here to Activate" color='#fc4b6c'><StopOutlined /></Tooltip>
                                </span>
                                :
                                <span className="btn btn-sm btn-light-success text-success btn-circle d-flex align-items-center justify-content-center" onClick={(e) => { statuschange(record) }}><Tooltip title="Click here to Deactivate" color='#39cb7f'><i className="ri-check-line"></i></Tooltip>
                                </span>
                            } */}
                            {record.isactive === true ?
                                <span className="social-link btn btn-sm btn-light-success
                                text-success btn-circle d-flex align-items-center justify-content-center " style={{ marginInline: 'auto' }} onClick={(e) => { statuschange(record) }}><Tooltip title="Click here to Deactivate" ><StopOutlined /></Tooltip>
                                </span> :
                                <span className="social-link btn btn-sm btn-light-danger
                                            text-danger btn-circle d-flex align-items-center justify-content-center " style={{ marginInline: 'auto' }} onClick={(e) => { blockprovider(record) }}><Tooltip title="Click here to Activate" ><StopOutlined /></Tooltip>
                                </span>
                            }
                        </div>
                    </Fragment>
                )
            }
        },
    ];

    const columns1 = [
        {
            title: 'S.No',
            key: 'index',
            align: "center",
            width: '40px',
            render: (text, record, index) => {
                return (
                    (page - 1) * paginationSize + index + 1
                )
            },
        },
        {
            // title: 'Label Name',
            title: <><span style={{ marginRight: '15px' }} className="btn btn-sm btn-danger text-white btn-circle1  align-items-center justify-content-center"><i class="ri-user-unfollow-fill" ></i></span><span style={{ marginLeft: '5px' }}>InActive Label Name</span></>,
            dataIndex: 'desc',
            key: 'desc',
            align: "center",
            ...getColumnSearchProps('desc', 'Label Name'),
            ellipsis: true,
            width: '300px',
            sorter: (a, b) => a.desc.localeCompare(b.desc)

        },
        // {
        //     title: 'Edit',
        //     dataIndex: 'false',
        //     key: 'false',
        //     align: "center",
        //     width: '50px',
        //     ellipsis: false,
        //     render: (text, record) => {
        //         console.log(record, "record111");
        //         return (
        //             <Fragment>
        //                 {record.isactive === true ?
        //                     <div className='row hover'>
        //                         <div className="social-link btn btn-sm btn-light-info text-info btn-circle d-flex 
        //                         align-items-center justify-content-center text-center"
        //                             onClick={(e) => { editservice(record) }} style={{ marginInline: 'auto' }}>
        //                             <Tooltip title="Click here to Edit">
        //                                 <EditOutlined /> </Tooltip>
        //                         </div>
        //                     </div> :
        //                     ''
        //                     // <div className='row hover'>
        //                     //     <div className="social-link btn btn-sm btn-light-info text-info btn-circle d-flex 
        //                     //     align-items-center justify-content-center text-center" style={{ marginInline: 'auto' }} >
        //                     //         <EditOutlined style={{ cursor: 'not-allowed' }} />
        //                     //     </div>
        //                     // </div>
        //                 }

        //             </Fragment>
        //         )
        //     }
        // },
        {
            title: "Status",
            dataIndex: 'false',
            key: 'false',
            align: "center",
            width: '60px',
            ellipsis: false,
            render: (text, record) => {

                return (
                    <Fragment>
                        <div className='row hover'>
                            {/* {record.isactive === true ?
                                <span className="btn btn-sm btn-light-danger text-danger btn-circle d-flex align-items-center justify-content-center" onClick={(e) => { statuschange(record) }}><Tooltip title="Click here to Activate" color='#fc4b6c'><StopOutlined /></Tooltip>
                                </span>
                                :
                                <span className="btn btn-sm btn-light-success text-success btn-circle d-flex align-items-center justify-content-center" onClick={(e) => { statuschange(record) }}><Tooltip title="Click here to Deactivate" color='#39cb7f'><i className="ri-check-line"></i></Tooltip>
                                </span>
                            } */}
                            {record.isactive === true ?
                                <span className="social-link btn btn-sm btn-light-success
                                text-success btn-circle d-flex align-items-center justify-content-center " style={{ marginInline: 'auto' }} onClick={(e) => { statuschange(record) }}><Tooltip title="Click here to Deactivate"><StopOutlined /></Tooltip>
                                </span> :
                                <span className="social-link btn btn-sm btn-light-danger
                                            text-danger btn-circle d-flex align-items-center justify-content-center " style={{ marginInline: 'auto' }} onClick={(e) => { blockprovider(record) }}><Tooltip placement='topRight' title="Click here to Activate"><StopOutlined /></Tooltip>
                                </span>
                            }
                        </div>
                    </Fragment>
                )
            }
        },
    ];

    const [serviceProviderDetails, setServiceProviderDetails] = useState({
        provider_name: '',
    });

    const [sid, Setsid] = useState({
        s_id: ''
    })


    // const serviceProviderOnChange = (e) => {
    //     setServiceProviderDetails({
    //         ...serviceProviderDetails,
    //         [e.target.name]: e.target.value
    //     })
    // }

    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "Fetch_Certificate_Label",
                        {}
                    ]
                }
            ]

            certificatelist({
                payload: payload,
                paramid: paramid
            })
        }
    }, [isAuth])

    useEffect(() => {
        formValidator.current.showMessages()
        formValidator1.current.showMessages()
        formValidator2.current.showMessages()
    }, []);

    useEffect(() => {
        if (recallremarks) {
            let temp = [];
            for (let i = 0; i < recallremarks.length; i++) {
                if (recallremarks[i].r_description != '') {
                    temp.push(recallremarks[i])
                }
                Setremarksvalue(temp)
            }
        }

    }, [recallremarks])

    useEffect(() => {
        if (invalidpassword == "InvalidPassword") {
            Toaster.error('Invalid Password')
            Setpassword({
                ...passwordata,
                passvalue: ''
            })
            setFocus(serviceProviderRef)
            setAddFormSubmit1(false)
            dispatch({
                type: RESET_INVALID,
            })
        }
    }, [invalidpassword])

    const addservice = (e) => {
        e.preventDefault();
        setAddFormSubmit(true);
        if (formValidator.current.allValid()) {
            SetAdd(false)
            if (sid.s_id === '') {
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "SaveCertificateLabel",
                            {
                                "label_description": serviceProviderDetails.provider_name
                            }
                        ]
                    }
                ]
                certificateadd({
                    payload: payload,
                    paramid: paramid
                })
            }

            else {
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "UpdateCertificateLabel",
                            {
                                "clb_id": sid.s_id,
                                "clb_name": serviceProviderDetails.provider_name,
                            }
                        ]
                    }
                ]
                certificateupdate({
                    payload: payload,
                    paramid: paramid
                })
            }

        }
    }

    const statuschange = (e) => {
        console.log(e, "pppppoooo");

        Activate()
        Setpassword({
            ...passwordata,
            sp_id: e.id,
            active_status: !e.isactive
        })
    }

    const blockprovider = (e) => {
        Deactivate()
        Setpassword({
            ...passwordata,
            sp_id: e.id,
            active_status: !e.isactive
        })
    }


    const submitpassword = () => {
        setAddFormSubmit1(true)
        if (formValidator1.current.allValid()) {
            const pay = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "ChangeCertificateLabelStatus",
                        {
                            "clb_id": passwordata.sp_id,
                            "is_active": passwordata.active_status,
                            "password": passwordata.passvalue,
                            "pwd_validate": true
                        }
                    ]
                }
            ]
            updatestatus({
                payload: pay,
                paramid: paramid
            })
        }
    }

    const blockservice = () => {
        setAddFormSubmit2(true)
        if (formValidator2.current.allValid()) {
            const pay = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "BlockServiceProvider",
                        {
                            "sp_id": blockdata.sp_id,
                            "is_blocked": blockdata.is_blocked,
                            "remarks": blockdata.remarks,
                            "password": blockdata.password
                        }
                    ]
                }
            ]
            updateblock({
                payload: pay,
                paramid: paramid
            })

        }
    }

    const editservice = (data) => {
        setlabelname('edit');
        setServiceProviderDetails({
            ...serviceProviderDetails,
            provider_name: data.desc,

        })
        Setsid({
            ...sid,
            s_id: data.id
        })
        SetmodalVisible(true);
        SetAdd(true)
    }

    const handleOk = () => {
        setRemarksModal(false)
    };

    const CloseIcon = () => {
        SetAdd(false)
        setAddFormSubmit(false)
        setlabelname('add')
    };

    const handleCancel = () => {
        setRemarksModal(false)
    };

    useEffect(() => {
        if (lists && lists.certificate_info.length > 0) {
            let array = _.filter(lists.certificate_info, { isactive: true });
            setBaseData(array);
            let array1 = _.filter(lists.certificate_info, { isactive: false });
            setBaseData1(array1);
        }
    }, [lists.certificate_info])

    console.log(lists.certificate_info, "lists.certificate_info")

    const RemarksColumn = [
        {
            title: 'S.No',
            dataIndex: false,
            width: '30px',
            align: 'center',
            render: (text, record, index) => {
                return (page - 1) * 10 + index + 1
            }
        },
        {
            title: 'Description',
            dataIndex: 'r_description',
            key: 'r_description',
            width: '200px',
            ellipsis: true,
            render: (text, record) => <a onClick={() => {
                Setblockdata({
                    ...blockdata,
                    remarks: record.r_description
                })
                setRemarksModal(false)
            }}>{record.r_description}</a>,
        },
    ]

    const showRemarksModal = () => {
        GetRemarksData({
            payload:
                [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetRemarksData",
                            {}
                        ]
                    }
                ],
            paramid: paramid
        })
        setRemarksModal(true);
    };

    const homescreen = () => {
        historynew.push(homepage);
        // window.location.href = homepage
        document.getElementById('homehover').style.color = "blue"
    }



    return (
        <Fragment>
            <div>
                <ScrollToTop smooth color="red" component={<Tooltip title="Back to top"><UpOutlined /></Tooltip>} />
                <div id='page-wrapper' className="page-wrapper">
                    <div className="page-titles pb-0 pt-1 page-title-sticky">
                        <div className="row">
                            <nav className="col-lg-4 col-md-3 col-6 align-self-center" aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 d-flex align-items-center">
                                    <li className="breadcrumb-item">
                                        <span className='pointer' id='homehover' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        <span style={{ fontSize: '16px' }}>Master</span>
                                    </li>
                                    <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                        <span >Certificate Label</span>
                                    </li>
                                </ol>
                            </nav>

                            {/* <div className="col-lg-8 col-md-9 d-none d-md-flex align-items-center justify-content-end"> */}

                            <div className="col-lg-8 p-0 d-flex justify-content-end align-items-center" >

                                {/* <div className="popupbtncolour" style={{display: 'initial'}}>
                                    
                                </div> */}
                                {Add == false ?
                                    <Tooltip title="Add Label" placement='left' ><Button style={{ float: 'right' }} type="primary" shape="round" className='addbutton'
                                        icon={<PlusCircleOutlined />} size='default' onClick={addopen} >
                                        Add
                                    </Button></Tooltip>
                                    : ''}
                                <button onClick={(e) => { fullscreenMode() }} className="bg-transparent ms-2 text-black full-mode fullscreenradius" style={{ marginRight: '10px' }} >
                                    <Tooltip placement="left" title={is_Fullscreen == true ? "Exit Full Screen Mode" : "Full Screen Mode"}><FullscreenOutlined /></Tooltip>
                                </button>
                            </div>



                        </div>

                    </div>
                    <div className="container-fluid pt-1">
                        <div className={localStorage.getItem('currentTheme')}>
                            {Add == true ?
                                <Collapse className={' report-collapse mb-1  ' + localStorage.getItem('currentTheme')} accordion defaultActiveKey={["1"]}>
                                    <Panel header={labelname == 'add' ? "Add Certificate Label" : "Edit Certificate Label"} key="1">

                                        <div className='row p-1'>
                                            <div className='col-md-6'>
                                                <label><b>Label Name :</b><span style={{ color: "red" }}> * </span> </label>

                                                <Input
                                                    type="text"
                                                    id="provider_name"
                                                    name='provider_name'
                                                    placeholder="Label Name"
                                                    autoComplete={'off'}
                                                    onChange={onInputChange}
                                                    onKeyPress={(event) => {
                                                        
                                                        if (!/^[A-Za-z 0-9.,_-]+$/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    value={serviceProviderDetails.provider_name}
                                                    maxlength='100'
                                                    style={{ borderColor: shortName === true && serviceProviderDetails.provider_name != '' ? '#87d068' : shortName === false && serviceProviderDetails.provider_name != '' ? '#fc4b6c' : '' }}
                                                />

                                                {shortName === true && serviceProviderDetails.provider_name != '' ?
                                                    <i style={{ left: 565, position: 'absolute' }} className="ri-check-line  text-success fs-5 fw-bold" ></i>
                                                    :
                                                    shortName === false && serviceProviderDetails.provider_name != '' ?
                                                        <i style={{ left: 565 }} className="ri-close-line position-absolute text-danger fs-5 fw-bold"></i>
                                                        : ''
                                                }
                                                {formValidator.current.message(
                                                    'provider_name',
                                                    serviceProviderDetails.provider_name,
                                                    [
                                                        'required',

                                                        { regex:/^[A-Za-z 0-9.,_-]+$/ },

                                                        `max:100`
                                                    ],
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Label Name Required',
                                                            regex: 'Enter Valid Name',
                                                            max: 'Maximum character is 100 only'
                                                        }
                                                    })}
                                            </div>
                                            {/* <div className='col-md-6 align-self-end text-center'>

                                               
                                            </div> */}

                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-md-12">
                                                <div className="col-md-6" style={{ marginLeft: '42%', marginTop: '10px' }}>
                                                    <Button type="primary" shape="round" className={'addbutton me-2 ' + localStorage.getItem('currentTheme')}
                                                        icon={<PlayCircleOutlined />} size='default'
                                                        onClick={addservice}
                                                    >
                                                        Submit
                                                    </Button>
                                                    <Button type="primary" shape="round" onClick={CloseIcon} icon={<CloseCircleOutlined />} className={'addbutton me-2 ' + localStorage.getItem('currentTheme')} >
                                                        Cancel
                                                    </Button>
                                                </div>
                                            </div>

                                        </div>

                                    </Panel>
                                </Collapse>
                                : ''}
                        </div>
                        <div className="row" >
                            <div className="col-md-6">
                                <div className="card">
                                    <div className='card-body' style={{ padding: '0px' }}>
                                        <div className='service-provider-table'>
                                            <Table

                                                className={localStorage.getItem("currentTheme")}
                                                columns={columns}
                                                //dataSource={lists}
                                                showSorterTooltip={false}
                                                dataSource={baseData}
                                                bordered
                                                // pagination={{
                                                //     defaultPageSize: dataTableProperties.pagesize,
                                                //     showSizeChanger: dataTableProperties.sizechanger,
                                                //     pageSizeOptions: dataTableProperties.pageSizeOptions
                                                // }}
                                                pagination={false}
                                                onChange={handleChange} />
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="card">
                                    <div className='card-body' style={{ padding: '0px' }}>
                                        <div className='service-provider-table'>
                                            <Table

                                                className={localStorage.getItem("currentTheme")}
                                                columns={columns1}
                                                //dataSource={lists}
                                                dataSource={baseData1}
                                                showSorterTooltip={false}
                                                bordered
                                                // pagination={{
                                                //     defaultPageSize: dataTableProperties.pagesize,
                                                //     showSizeChanger: dataTableProperties.sizechanger,
                                                //     pageSizeOptions: dataTableProperties.pageSizeOptions
                                                // }}
                                                pagination={false}
                                                onChange={handleChange} />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div >
            </div>
            {/* <Modal title="Add Label" visible={modalVisible} footer={null} maskClosable={false} className={"serviceProvider add-service-prv " + localStorage.getItem('currentTheme')}
                onCancel={modalcancel} >
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className='row'>
                                <div className='col-md-1'></div>
                                <div className='col-md-3'>
                                    <label><b>Label Name :</b><span style={{ color: "red" }}> * </span> </label>
                                </div>
                                <div className='col-md-6'>
                                    <div className="form-group">
                                        <Input
                                            type="text"
                                            id="provider_name"
                                            name='provider_name'
                                            placeholder="Label Name"
                                            autoComplete={'off'}
                                            onChange={onInputChange}
                                            value={serviceProviderDetails.provider_name}
                                            maxlength='100'
                                        />
                                        {formValidator.current.message(
                                            'provider_name',
                                            serviceProviderDetails.provider_name,
                                            [
                                                'required',
                                                {
                                                    regex: /^[a-zA-Z0-9]*$/,
                                                },

                                                `max:100`
                                            ],
                                            {
                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                messages: {
                                                    required: 'Label Name Required',
                                                    regex: 'Enter Valid Name',
                                                    max: 'Maximum character is 100 only'
                                                }
                                            })}
                                    </div>
                                    <div className='col-md-1'></div>
                                </div>
                            </div>
                            <div className="form-actions text-center popupbtncolour">
                                <Button type="primary" shape="round" className={'addbutton mt-4 ' + localStorage.getItem('currentTheme')}
                                    icon={<PlayCircleOutlined />} size='default'
                                    onClick={addservice}
                                >
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal> */}
            <Modal autoFocus={true} open={passvisible} className="newStyleModalPassword" footer={null} onCancel={passwordcancel}
            maskClosable={false}>
                <div className="row">
                    <div className="col-md-12">
                        <div className="mb-6">
                            <label className="control-label"> <b>Password </b><span style={{ "color": "red" }}>*</span></label>
                            <input
                                ref={serviceProviderRef}
                                autoFocus='on'
                                type="password"
                                id="password"
                                name='passwordvalue'
                                placeholder="Enter password"
                                maxLength={20}
                                onChange={(e) => {
                                    Setpassword({
                                        ...passwordata,
                                        passvalue: e.target.value
                                    })
                                }}
                                value={passwordata.passvalue}
                                style={{ "margin-top": "5px", width: '100%' }}
                                className="form-control"
                            />
                            {formValidator1.current.message(
                                'Password',
                                passwordata.passvalue,
                                ['required'],
                                {
                                    className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                    messages: {
                                        required: 'Password Required'
                                    }
                                })}
                        </div>
                    </div>

                </div>
                <br />
                <div className={'form-actions popupbtncolour ' + localStorage.getItem('currentTheme')}>
                    <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')}
                        style={{ marginLeft: '33%' }}
                        icon={<PlayCircleOutlined />} size='default'
                        onClick={submitpassword}
                    >Submit
                    </Button>

                    {/* <button
                        className="btn btn-light-danger rounded-pill px-4 ms-2 text-danger waves-effect text-start"
                        onClick={passwordcancel}
                    >Cancel
                    </button> */}
                </div>
            </Modal>
            <Modal autoFocus={true} open={blockvisible} className='usr_modal_class' footer={null}
                onCancel={blockcancel} maskClosable={false}>
                <div className="col-md-12">
                    <div className="form-group">
                        <label htmlFor=""><b>Password</b> <span style={{ "color": "red" }}>*</span></label>
                        <input
                            ref={serviceProviderRef}
                            autoFocus='on'
                            type="password"
                            id="password"
                            name='passwordvalue'
                            className="form-control"
                            placeholder="Enter password"
                            maxLength={20}

                            onChange={(e) => {
                                Setblockdata({
                                    ...blockdata,
                                    password: e.target.value
                                })
                            }}
                            value={blockdata.password}
                        />
                        {formValidator2.current.message(
                            'Password',
                            blockdata.password,
                            ['required'],
                            {
                                className: `invalid-feedback ${AddFormSubmit2 ? 'show' : 'hide'}`,
                                messages: {
                                    required: 'Password Required'
                                }
                            })}

                    </div>
                </div>
                <br />
                <div className="col-md-12">
                    <label htmlFor=""><b>Remarks:</b> <span style={{ "color": "red" }}>*</span></label>
                    <textarea className='form-control' name='remark' value={blockdata.remarks}
                        row='1' cols='4' style={{ resize: 'none' }} placeholder="Enter Remarks" onChange={(e) => {
                            Setblockdata({
                                ...blockdata,
                                remarks: e.target.value
                            })

                        }}>
                    </textarea>
                    <span className="btn btn-sm btn-light-info text-info btn-circle d-flex 
                                align-items-center justify-content-center" style={{ "float": "right" }}
                        onClick={(e) => { showRemarksModal() }}>
                        <PlusOutlined />
                    </span>
                    {formValidator2.current.message(
                        'remark',
                        blockdata.remarks,
                        ['required'],
                        {
                            className: `invalid-feedback ${AddFormSubmit2 ? 'show' : 'hide'}`,
                            messages: {
                                required: 'Remarks Required'
                            }
                        })}

                </div>
                <br />
                <div className={'form-actions popupbtncolour ' + localStorage.getItem('currentTheme')}>
                    <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')}
                        style={{ marginLeft: '33%' }}
                        icon={<PlayCircleOutlined />} size='default'
                        onClick={blockservice}
                    >
                        Submit
                    </Button>
                </div>
            </Modal>
            <Modal title="Remarks List" footer={null} open={remarksModal} className="remarksClass"
                onOk={handleOk}
                onCancel={handleCancel} maskClosable={false}>
                {/* <DataTable
                    columns={RemarksColumn}
                    data={remarksvalue && remarksvalue.length > 0 && remarksvalue}
                    pagination
                    responsive

                /> */}
                <Table
                    className='userprivclass'
                    columns={RemarksColumn}
                    dataSource={remarksvalue && remarksvalue.length > 0 && remarksvalue}
                    bordered
                    pagination={{
                        defaultPageSize: dataTableProperties.pagesize,
                        showSizeChanger: dataTableProperties.sizechanger,
                        pageSizeOptions: dataTableProperties.pageSizeOptions
                    }}
                />

            </Modal>
        </Fragment >
    )
}

export default connect(mapStateToProps, {
    certificatelist,
    certificateadd,
    certificateupdate,
    updatestatus,
    GetRemarksData
})(CertificateLabel);