import React, { Fragment } from 'react'
import { Collapse, Select, Input, DatePicker, Card, Modal, Table, Button, Tooltip, Pagination, Divider } from 'antd';
import { useState, useRef, useEffect } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";
import { connect, useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { updateCertificate, getComplianceCertificateFilter, UploadFile1, getDocumentTotalSize, getComplianceCertificateTable, searchCompliance, submitComplianceCertificate, UploadFile, Downloadfile, mappingDetails, getLabelList, viewComplianceStatus, viewComplianceDetails } from '../../Store/Action/Transcations/CV-ComplianceCertificate';
// import { MonthlyComplianceFilterReport, MonthlyComplianceTable, MonthlyComplianceExport } from '../../../src/Store/Action/Report/CV_MonthlyComplianceReport';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { ExclamationCircleTwoTone, UploadOutlined, PlayCircleOutlined, ArrowLeftOutlined, PlusOutlined, DownloadOutlined, CloseOutlined, SearchOutlined, FullscreenOutlined, UpOutlined, EyeOutlined, FileAddOutlined, CloudUploadOutlined, SelectOutlined, EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Toaster } from '../../Libs/Toaster';
import EncryptDecrypt from '../../Libs/Encrypt&Decrypt'
import { max } from 'lodash';
import ScrollToTop from 'react-scroll-to-top';
import { fullscreenmode, fullscreenexit, handleKeyDown } from "./../../Libs/fullscreenmode";
import { validationType, validateSelect, onPasteToaster } from './../../Libs/inputValidation';
import ScrollButton from '../Scrollbar';
import TextArea from 'antd/lib/input/TextArea';
import { HomeRedirect, CountrySplit } from './../../Libs/country'
const homepage = HomeRedirect()
const uniq_country_data = CountrySplit()

const mapStateToProps = (state) => ({
    filterValue: state.CV_ComplianceCertificate.complianceCertificate.FilterData,
    tabledatas: state.CV_ComplianceCertificate.complianceCertificate.tabledata,
    complianceStatusModals: state.CV_ComplianceCertificate.complianceCertificate.complianceStatusModal,
    detailsModals: state.CV_ComplianceCertificate.complianceCertificate.detailsModal,
    MappingDetail: state.CV_ComplianceCertificate.complianceCertificate.MappingDetails,
    labelLists: state.CV_ComplianceCertificate.complianceCertificate.labelList,
    searchDatas: state.CV_ComplianceCertificate.complianceCertificate.searchData,
    downloadFile: state.CV_ComplianceCertificate.complianceCertificate.downloadFile,
    fileDatas: state.CV_ComplianceCertificate.complianceCertificate.fileData,
    // invalid: state.CV_ComplianceCertificate.complianceCertificate.invalidFile,

})

const CV_ComplianceCertificates = ({
    MonthlyComplianceFilterReport,
    UploadFile,
    Downloadfile,
    UploadFile1,
    MonthlyComplianceTable,
    MonthlyComplianceExport,
    complianceStatusModals,
    getComplianceCertificateTable,
    submitComplianceCertificate,
    getDocumentTotalSize,
    getComplianceCertificateFilter,
    viewComplianceStatus,
    searchDatas,
    detailsModals,
    fileDatas,
    downloadFile,
    searchCompliance,
    filterValue,
    labelLists,
    filerunitdatas,
    MappingDetail,
    viewComplianceDetails,
    mappingDetails,
    getLabelList,
    tabledatas,
    updateCertificate
    // invalid
    // tabledatas


}) => {
    // console.log(invalid, 'invalid');
    console.log(filterValue, 'downlofilterValueadFile');
    console.log(labelLists, 'labelLists');
    console.log(MappingDetail, 'MappingDetail');
    console.log(detailsModals, 'detailsModals');
    console.log(viewComplianceDetails, 'viewComplianceDetails');
    console.log(tabledatas, "tabledatastabledatas");
    const _ = require("lodash");
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const [domainValue, setDomainValue] = useState(false)
    const country_info = sessionParsedValue.country_info
    const sessionArr = sessionParsedValue.entity_info
    const [complianceSave, setComplianceSave] = useState(false)
    const [isEdit, setIsEdit] = useState(false);
    const historynew = useHistory();

    console.log(complianceSave, 'complianceSave')
    const [dateChange, setDateChange] = useState(false)
    const [labelList, setLabelList] = useState({})
    console.log(labelList, 'labelList');
    const [getreportlist, setGetReportList] = useState([])
    const isAuth = localStorage.getItem('isAuthenticated');
    const [assignee, setAssignee] = useState({
        assignee: '',
        concurrer: ''

    })
    const authtoken = localStorage.getItem('authToken');
    const [labelCheckBox, setlabelCheckBox] = useState([])
    console.log(labelCheckBox, 'labelCheckBox');
    const location = useLocation();
    const is_Fullscreen = useSelector((state) => state.Login.is_fullscreen);
    const dispatch = useDispatch();
    const paramid = location.pathname;
    const [invoiceData, setInvoiceData] = useState({
        invoice_no: '',
        invoice_date: ''
    })
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const [tableshow, setTableShow] = useState(false)
    console.log(tableshow, 'tableshow');
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [complianceStatusModal, setComplianceStatusModal] = useState(false)
    const [detailsModal, setDetailsModal] = useState(false)
    const [searchModal, setsearchModal] = useState(false)
    const [labelSubmit, setLabelSubmit] = useState([])
    const [updatevalue, setupdatevalue] = useState([])
    const [removelabel, setRemovelabel] = useState([])
    console.log(removelabel, 'removelabel');
    const [approveLabel, setApproveLabel] = useState([])
    console.log(approveLabel, 'approveLabel');
    const [fileuploadData, setFileUploadData] = useState([])
    console.log(labelSubmit, 'labelSubmit');
    const [key, setKey] = useState("1")
    const [type, setType] = useState('')
    const [month, setMonth] = useState('')
    const [certId, setCertId] = useState()
    const [txt, setTxt] = useState('')
    const [invoice, setInvoice] = useState(false)
    const [vendorDetails, setVendorDetails] = useState({
        name: '',
        code: '',
        month: '',
        year: '',
        documents: '',
        labelValue: {}
    })
    const [labelArray, setLabelArray] = useState([])
    const [labelValue, setLabelValue] = useState({})
    const [titledata, Settitledata] = useState()
    const [entity, setEntity] = useState([])

    const [filteredArray, setFilteredArray] = useState([])
    const [filteredArrayState, setFilteredArrayState] = useState([])
    const [division, setDivision] = useState([])
    const [category, setCategory] = useState([])
    const [domain, setDomain] = useState([])
    const [domainName, setDomainName] = useState("")
    const [vendor, setVendor] = useState([])
    const [paginationdata, setPaginationdata] = useState([])
    const [count, setCount] = useState([])
    let [index, setindex] = useState(0)
    const [labelModal, setLabelModal] = useState(false)
    const [current, setCurrent] = useState(1);
    const [pageState, setpageState] = useState(false);
    const [pageSize, setPageSize] = useState(25)
    const [checkboxValue, setCheckBoxValue] = useState([0, 1, 6, 7, 8, 9, 28, 30, 33])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [submit, setSubmit] = useState(false)
    const [legalstate, Setlegalstate] = useState(false)
    const [tablecount, setTablecount] = useState([])
    const [data, setData] = useState({
        country: '',
        legalEntity: '',
        domain: '',
        vendor: '',
        category: '',
        division: '',
        vendorName: '',
        vendorCode: '',
        countryName: '',
        leName: '',
        divName: '',
        catName: '',
        dName: '',
        domainName: ''
    })

    console.log(data, 'ssssss');
    const [validationState, setValidationState] = useState(false)
    console.log(validationState, 'validationState');
    const [vendorComplianceScore, setVendorComplianceScore] = useState({
        country: '',
        countryName: '',
        division: '',
        divisionName: '',
        typeid: '',
        typeName: '',
        category: '',
        categoryName: '',
        from_month: '',
        to_month: '',
        legal_entity_name: localStorage.getItem('SelectedEntity') === "All Legal Entity" ? '' : localStorage.getItem('SelectedEntity'),
        legal_entity_id: '',
        domain: '',
        domainName: '',
        vendor_id: '',
        vendor_name: ''
    })
    const [fileData, setFileData] = useState([])
    const [fileupload, setFileUpload] = useState([])
    const [certificateFormSubmit, setcertificateFormSubmit] = useState(false);
    const validator = useRef(new SimpleReactValidator());
    const Formvalidator = useRef(new SimpleReactValidator());
    const { Panel } = Collapse;
    const { Option } = Select;
    const [selectSearchValue, setSelectSearchValue] = useState({
        country: '',
        legalEntity: '',
        domain: '',
        division: '',
        category: '',
        vendor: '',
    })

    const [fullscreen, setfullscreen] = useState(false);

    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
            document.addEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch), false);
            return () => {
                document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch));
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode(dispatch);
            setfullscreen(true);
        } else {
            fullscreenexit(dispatch);
            setfullscreen(false);
        }
    }

    const showModal = (row) => {
        console.log(row, 'rowrow');
        const payload2 = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "GetComplianceStatusCertifications",
                    {
                        "legal_entity_id": data.legalEntity,
                        "cert_id": row.cert_id

                    }
                ]
            }
        ]

        viewComplianceStatus({
            payload: payload2,
            paramid: paramid
        })
        setComplianceStatusModal(true)
    };
    const showModalData = (row) => {
        console.log(row, 'rowrow');
        const payload2 = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "CompCertificateEditView",
                    {
                        "le_id": data.legalEntity,
                        "cert_id": row.cert_id
                    }
                ]

            }
        ]

        viewComplianceDetails({
            payload: payload2,
            paramid: paramid
        })
        setDetailsModal(true)
    };
    useEffect(() => {
        if (fileDatas && fileDatas == 'FileUploadSuccess') {
            if (fileupload.length > 0) {
                if (Formvalidator.current.allValid()) {

                    let datas = JSON.stringify(labelList)
                    let data1 = JSON.stringify(datas)

                    const payload =
                        [
                            authtoken,
                            {
                                "session_token": authtoken,
                                "request": [
                                    "UpdateCertificate",
                                    {
                                        "le_id": data.legalEntity,
                                        "cert_id": Number(certId),
                                        "inv_req": invoice == true ? 1 : 0,
                                        "inv_no": invoice == true ? invoiceData.invoice_no : null,
                                        "inv_date": invoice == true ? moment(new Date(invoiceData.invoice_date)).format('DD/MM/YYYY') : null,
                                        "vendorid": Number(data.vendor),
                                        "cer_mon": month,
                                        "label_value": datas ? datas : "{}",
                                        "removed_documents": [],
                                        "documents": fileuploadData.length > 0 ? fileuploadData : null
                                    }
                                ]
                            }
                        ]

                    const payload1 = [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "FetchCertificateDetails",
                                {
                                    "le_id": data.legalEntity,
                                    "v_id": Number(data.vendor),
                                    "d_id": Number(data.domain)
                                }
                            ]
                        }
                    ]

                    updateCertificate({
                        payload: payload,
                        paramid: paramid,
                        payload1: payload1,
                        setComplianceSave: setComplianceSave
                    })
                    console.log('aishuuuuuuu');
                    // setKey(0)

                }
            }

        }
    }, [fileDatas])
    const onInputChange = e => {
        const { value } = e.target;

        const re = /^[A-Za-z . 0-9_-]{0,20}$/
        console.log(re.test(value), 'VALUE OF INVOICE')
        if (value === "" || re.test(value)) {
            setInvoiceData({
                ...invoiceData,
                invoice_no: e.target.value
            })
            setTxt(value)
        }
    }

    useEffect(() => {
        if (month != '') {
            if (searchDatas && searchDatas.cmplist && searchDatas.cmplist.length > 0) {
                if (submit == true) {
                    submitData()
                }
                else {
                    setsearchModal(true)
                }

            }
            else {
                Toaster.error("No compliance for the selected month")
            }
        }
    }, [searchDatas && searchDatas.cmplist])

    useEffect(() => {
        if (fileupload && fileupload.length > 0) {
            let temp = []
            for (let i in fileupload) {
                let obj = {
                    "file_size": fileupload[i].file_size,
                    "file_name": fileupload[i].file_name,
                    "file_content": null
                }
                temp.push(obj)
            }
            setFileUploadData(temp)
        }
    }, [fileupload])

    console.log(fileupload, 'fileupload');

    useEffect(() => {
        if (approveLabel && approveLabel.length > 0) {
            let finalObj = {};
            for (let i in approveLabel) {
                Object.assign(finalObj, approveLabel[i]);
            }
            console.log(finalObj, 'finalObjfinalObjfinalObj');
            setLabelList(finalObj)
        }
        else {
            setLabelList([])
        }
    }, [approveLabel])

    const submitData = () => {

        Swal.fire({
            title: 'Are You Sure,You want to submit the certificate?',
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            customClass: localStorage.getItem("currentTheme") == 'theme_four' ? "customSWal" : 'customSWal2',
            confirmButtonColor: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 '
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                let datas = JSON.stringify(labelList)
                let data1 = JSON.stringify(datas)

                const payload1 = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "ComplianceCertificate",
                            {
                                "le_id": data.legalEntity,
                                "v_id": Number(data.vendor),
                                "inv_req": invoice == true ? 1 : 0,
                                "cer_mon": month,
                                "inv_no": invoice == true ? invoiceData.invoice_no : null,
                                "inv_date": invoice == true ? moment(new Date(invoiceData.invoice_date)).format('DD/MM/YYYY') : null,
                                "label_value": datas ? datas : "{}",
                                "documents": fileuploadData.length > 0 ? fileuploadData : null,
                                "assignee": assignee.assignee,
                                "concurrer": assignee.concurrer,
                                "mail_data": [

                                ],
                                "c_id": Number(data.country),
                                "d_id": Number(data.domain),
                                "unit_name": data.vendorName
                            }
                        ]

                    }
                ]
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "FetchCertificateDetails",
                            {
                                "le_id": data.legalEntity,
                                "v_id": Number(data.vendor),
                                "d_id": Number(data.domain)
                            }
                        ]
                    }
                ]
                submitComplianceCertificate({
                    payload: payload1,
                    paramid: paramid,
                    setComplianceSave: setComplianceSave,
                    payloadData: payload,
                    setTableShow: setTableShow

                })
                // Swal.fire('Closed.!', 'Closed...', 'success');
            }
        });
    };
    useEffect(() => {
        if (MappingDetail) {
            setAssignee({
                ...assignee,
                assignee: MappingDetail.assignee,
                concurrer: MappingDetail.concurrer
            })
        }
    }, [MappingDetail])

    const onSearch = () => {
        setSubmit(false)
        const payload = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "GetVndrWiseCmp",
                    {
                        "cer_mon": month,
                        "v_id": Number(data.vendor),
                        "legal_entity_id": data.legalEntity,
                        "d_id": Number(data.domain)
                    }
                ]

            }
        ]

        searchCompliance({
            payload: payload,
            paramid: paramid
        })
    }
    useEffect(() => {
        if (detailsModals) {
            setVendorDetails({
                name: detailsModals && detailsModals.u_name,
                code: detailsModals && detailsModals.u_code,
                month: detailsModals && detailsModals.pro_mon,
                year: detailsModals && detailsModals.pro_year,
                documents: detailsModals && detailsModals.doc_name,
                labelValue: detailsModals && detailsModals.label_value && detailsModals.label_value.length > 0 && detailsModals.label_value[0]




            })
            setLabelValue(detailsModals && detailsModals.label_value && detailsModals.label_value.length > 0 && detailsModals.label_value[0])
        }
    }, [detailsModals])

    useEffect(() => {
        if (labelValue) {
            for (let i in labelValue) {
                console.log(labelValue[i], 'labelValue[i]');
                if ((Object.keys(labelValue[i]).length) > 0) {
                    let temp = []
                    for (let j in labelValue[i]) {
                        temp.push({
                            label: j,
                            input: labelValue[i][j],
                        })
                    }
                    setLabelArray([...labelArray, ...temp])
                }

            }

        }

    }, [labelValue])

    console.log(labelArray, 'labelArray');



    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') != "All Legal Entity") {
            let domains = _.filter(filterValue && filterValue.domains_list, { legal_entity_id: localStorage.getItem('SelectedEntityid') })
            setData({
                ...data,
                country: sessionArr[0].c_name,
                legalEntity: localStorage.getItem('SelectedEntityid'),
                // domain: domains && domains.length > 0 && domains[0].d_id
            })
        }
    }, [localStorage.getItem('SelectedEntity')])
    // useEffect(() => {
    //     if (domain && domain.length > 0) {
    //         setData({
    //             ...data,
    //             domain: domain && domain.length > 0 && domain[0].domain_id,
    //             // domainName:  domain && domain.length > 0 && domain[0].domain_name
    //         })
    //     }
    // }, [domain])

    useEffect(() => {
        if (data.legalEntity != '' && filterValue.domains_list && filterValue.domains_list.length == 1) {
            setDomainValue(true)
            setData({
                ...data,
                domain: filterValue.domains_list[0].domain_id,
                domainName: filterValue.domains_list[0].domain_name
            })
        }
    }, [data.legalEntity, filterValue])

    useEffect(() => {
        if (approveLabel && approveLabel.length > 0) {
            let temp = []
            approveLabel && approveLabel.length > 0 && approveLabel.map((item, i) => {
                for (let j in approveLabel[i]) {
                    temp.push(approveLabel[i][j])
                }

                console.log(temp, 'temptemptemptemptemp');
            })
            setupdatevalue(temp)
        }
    }, [approveLabel])



    console.log(data, 'data && data.legalEntity ');
    const Remove = (index) => {
        console.log(index, 'fileindex');
        let temp = fileupload
        for (let i in fileupload) {

            if (fileupload[i]['file_name'] == index) {
                fileupload.splice(i, 1)
            }

        }
        setFileUpload([...fileupload])

    }
    const download = (item) => {
        console.log(item, "itemitem");
        const downloadpayload =
            [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "DownloadFileCert",
                        {
                            "le_id": data.legalEntity,
                            "c_id": Number(data.country),
                            "u_id": Number(data.vendor),
                            "upload_type": 2,
                            "file_value": item,
                        }
                    ]
                }
            ]
        console.log(downloadpayload, 'downloadpayload');
        Downloadfile({
            payload: downloadpayload,
            paramid: paramid
        });

    }




    const columns = [


        {
            title: 'Month',
            dataIndex: false,
            key: false,
            render: (text, row) => {
                return (
                    <p>{`${row.pro_mon
                        }-${row.pro_year}`}</p>
                )
            }
            // width: "50px",
            // ellipsis: true,
            // record && record.comp_name
        },

        {
            title: 'Invoice Number',
            dataIndex: false,
            ellipsis: true,

            render: (text, row) => {
                return (

                    <p>{row.inv_no ? row.inv_no : '-'}</p>



                )
            }
        },
        {
            title: 'Invoice Date',
            dataIndex: false,
            // align: 'center',
            ellipsis: true,
            render: (text, row) => {
                return (
                    <p>{row.inv_date ? row.inv_date : '-'}</p>
                )
            }
        },
        {
            title: 'Compliance Status',
            dataIndex: false,
            ellipsis: true,
            // width: '12%',
            align: 'center',
            render: (text, row) => {
                return (
                    <Button type="primary" style={{ borderRadius: '15px' }}
                        // style={{ background: "#008000"}}
                        onClick={() => {
                            showModal(row)
                            // setType('unit_overdue')
                            // showModal()
                        }}
                    >View</Button>
                )
            }
        },
        {
            title: 'Details Enclosed',
            dataIndex: false,
            ellipsis: true,
            // width: '12%',
            align: 'center',
            render: (text, row) => {
                return (
                    <Button type="primary"
                        style={{ borderRadius: '15px' }}
                        onClick={() => {
                            //   setType('unit_overdue')
                            showModalData(row)
                        }}
                    >View</Button>
                )
            }
        },
        {
            title: 'Status',
            // align: 'center',
            dataIndex: false,
            ellipsis: true,
            render: (text, row) => {
                if (row.current_status == 0) {
                    return (
                        <>
                            {(row.current_status == 1 || row.current_status == 2) ? <span>Pending</span> : (row.current_status == 3) ? <span>Approved</span> :
                                <span className='d-flex justify-content-start align-items-center'>Rectify<Tooltip title={row.remarks}>
                                    <span className='mx-1 text-primary'>
                                        <i className="ri-information-fill" /></span>
                                </Tooltip></span>
                            }
                        </>
                    )
                }
                else {
                    return (

                        <p>{(row.current_status == 1 || row.current_status == 2) ? <p>Pending</p> : (row.current_status == 3) ? <p>Approved</p> : <p>Rectify</p>
                        }</p>

                    )
                }
            }
        },
        {
            title: 'Edit',
            dataIndex: false,
            ellipsis: true,
            render: (text, row) => {
                console.log(row, 'row55555');
                if (row.current_status === 0 && row.remarks != null) {
                    return (
                        <Fragment>
                            <div className='hover'>
                                <Link className="social-link btn btn-sm btn-light-info text-info btn-circle d-flex 
                            align-items-center justify-content-center margin0"
                                    onClick={(e) => {
                                        let newMnth = `${row.pro_mon} - ${row.pro_year}`
                                        console.log(newMnth, 'newMnth');
                                        setMonth(newMnth)
                                        setComplianceSave(true)
                                        setIsEdit(true)
                                        setCertId(row.cert_id)
                                        if (row.current_status == 0 && row.inv_date !== null && row.inv_no !== null) {
                                            setInvoice(true)
                                            setInvoiceData({
                                                invoice_date: row.inv_date,
                                                invoice_no: row.inv_no
                                            })
                                        }
                                        const payload2 = [
                                            authtoken,
                                            {
                                                "session_token": authtoken,
                                                "request": [
                                                    "CompCertificateEditView",
                                                    {
                                                        "le_id": data.legalEntity,
                                                        "cert_id": row.cert_id
                                                    }
                                                ]

                                            }
                                        ]

                                        viewComplianceDetails({
                                            payload: payload2,
                                            paramid: paramid
                                        })

                                    }}
                                >
                                    <Tooltip title="Click here to Edit">
                                        <EditOutlined /></Tooltip>
                                </Link>
                            </div>
                        </Fragment>
                    )
                }
            }

        },



    ]
    const searchcolumns = [


        {
            title: 'Act',
            dataIndex: false,
            key: false,
            render: (text, row) => {
                return (
                    <span className='cut-text'>{row.act

                    }</span>
                )
            },
            // width: "50px",
            ellipsis: true,
            // record && record.comp_name
        },

        {
            title: 'Compliance Task',
            dataIndex: false,
            ellipsis: true,

            render: (text, row) => {
                return (
                    <span style={{ wordBreak: 'break-word' }}

                        className='cut-text'>{row.compliance_task
                        }</span>
                )
            }
        },
        {
            title: 'Status',
            dataIndex: false,
            width: '100px',
            render: (text, row) => {
                return (
                    <span>{row.task_status

                    }</span>
                )
            }
        },
        {
            title: 'Due Date',
            dataIndex: false,
            ellipsis: true,
            width: '110px',
            render: (text, row) => {
                return (
                    <span>{row.due_date

                    }</span>
                )
            }
        },




    ]
    const columns1 = [


        {
            title: 'Act',
            dataIndex: false,
            key: false,
            render: (text, row) => {
                return (<span className='cut-text-compliancecertificate'>{row.stat_mapping}</span>)
            }
        },
        {
            title: 'Compliance Task',
            dataIndex: false,
            ellipsis: false,

            render: (text, row) => {
                return (
                    // <p>{row.c_task
                    // }</p>
                    <span className=''>{row.c_task}</span>
                )
            }
        },
        {
            title: 'Status',
            dataIndex: false,
            ellipsis: true,
            render: (text, row) => {
                return (
                    <span>{row.a_status}</span>
                )
            }
        },




    ]
    console.log(labelCheckBox.length, 'labelCheckBox.length === labelLists && labelLists.label_name && labelLists.label_name.length ');
    const labelcolumns = [


        {
            title: <Fragment>
                <span><input type="checkbox" name="allchecked" onClick={(e) => {
                    setRemovelabel([])
                }} key={labelCheckBox} checked={((labelCheckBox.length) == (labelLists && labelLists.label_name && labelLists.label_name.length)) ? true : false} />&nbsp;Select All</span>
            </Fragment>,
            dataIndex: false,
            key: false,
            ellipsis: true,
            width: '15px',
            align: 'center',
            render: (text, record, i) => {
                return (
                    <Fragment>
                        <input type="checkbox"
                            name={'checked'}
                            id={i}
                            onClick={(e) => {
                                let checked = e.target.checked;
                                setRemovelabel([])
                                addSelectedDataValues(
                                    checked,
                                    record
                                );
                            }}
                        />
                    </Fragment>
                )
            }
        },

        {
            title: '',
            dataIndex: false,
            // ellipsis: true,
            width: '70px',
            render: (text, row) => {
                return (
                    <p>{row.label_description

                    }</p>
                )
            }
        },





    ]

    const uploadFileFormat = (size, name, content) => {
        var result = {
            'file_size': parseInt(size),
            'file_name': name,
            'file_content': content,
        };

        return result;
    }

    const convert_to_base64 = (file, name, size, callback) => {
        var reader = new FileReader();
        if (/MSIE \d|Trident.*rv:/.test(navigator.userAgent)) {
            var binary = "";
            reader.onload = function (readerEvt) {
                var bytes = new Uint8Array(readerEvt.target.result);
                var length = bytes.byteLength;
                for (var i = 0; i < length; i++) {
                    binary += String.fromCharCode(bytes[i]);
                }
                callback(EncryptDecrypt.encryptdata(binary), name, size);
            }
            reader.readAsArrayBuffer(file);
        } else {
            reader.onload = function (readerEvt) {
                var binaryString = readerEvt.target.result;
                callback(EncryptDecrypt.encryptdata(binaryString), name, size);
            };
            reader.readAsBinaryString(file);
        }
    }
    useEffect(() => {
        if (fileupload.length > 0) {
            // const payload =
            //     [
            //         authtoken,
            //         {
            //             "session_token": authtoken,
            //             "request": [
            //                 "DocumentsTotalSize",
            //                 {
            //                     "legal_entity_id": data && data.legalEntity,
            //                     "cert_id": Number(certId)
            //                 }
            //             ]
            //         }
            //     ]
            // const filePayload = [
            //     authtoken,
            //     {
            //         "session_token": authtoken,
            //         "request": [
            //             "CertDocumentsTemp",
            //             {
            //                 "le_id": data && data.legalEntity,
            //                 "unit_id": Number(data.vendor),
            //                 "file_info": fileupload,
            //             }
            //         ]
            //     }
            // ]
            // // console.log(
            // console.log(payload, 'payload5555');
            // getDocumentTotalSize({
            //     payload: payload,
            //     paramid: paramid,
            //     filePayload: filePayload
            // })
            const filePayload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "CertDocumentsTemp",
                        {
                            "le_id": data && data.legalEntity,
                            "unit_id": Number(data.vendor),
                            "file_info": fileupload,
                        }
                    ]
                }
            ]
            console.log(filePayload, 'filePayload');
            UploadFile({
                payload: filePayload,
                paramid: paramid
            })
        }
    }, [fileupload])
    const SavepastRecordOnChange = (e) => {
        if (e.target.name === 'file') {
            let FILE_TYPE = [
                "doc", "docx", "rtf", "pdf", "txt", "png", "jpeg", "gif", "csv", "xls", "xlsx",
                "rar", "tar", "gz", "ppt", "pptx", "jpg", "bmp", "odt", "odf", "ods", "xml"
            ];
            var results = [];
            const fileRegex = /^[a-zA-Z0-9-_& ,.+#()[-\]{}$^=%@]*$/
            let files = e.target.files
            let file_max_size = 26214400
            for (var i = 0; i < files.length; i++) {
                var file = files[i];
                var file_name = file.name;
                const f_name = file_name.substring(0, file_name.lastIndexOf('.'));
                var file_extension = file_name.substring(file_name.lastIndexOf('.') + 1).toLowerCase();
                console.log(FILE_TYPE.includes(file_extension), 'file_extension');
                let fileExtArray = []
                fileExtArray.push(FILE_TYPE.includes(file_extension))
                console.log(fileExtArray, 'fileExtArray');
                if (FILE_TYPE.includes(file_extension) == true) {
                    if (fileRegex.test(f_name) === false) {
                        return;
                    }
                    let file_size = files[i].size;
                    if (files[i].size > file_max_size) {
                        toast.error("File max limit exceeded")
                        return;
                    }
                    if (file) {
                        convert_to_base64(file, file_name, file_size, function (file_content, name, size) {
                            var fN = name.substring(0, name.lastIndexOf('.'));
                            var fE = name.substring(name.lastIndexOf('.') + 1);
                            var uniqueId = Math.floor(Math.random() * 90000) + 10000;
                            var f_Name = fN;

                            var result = uploadFileFormat(size, file_name, file_content);
                            setFileData(result)
                            results.push(result);


                            let temp1 = []
                            let temp = []
                            if (results && results.length > 0) {
                                for (let i in results) {
                                    temp1.push(results[i])
                                }
                                setFileUpload([...fileupload, ...temp1])
                            }
                            console.log(temp1, 'temp1');
                            // else {
                            //     for (let i in results) {
                            //         temp.push(results[i])
                            //     }
                            //     setFileUpload([...temp])
                            // }

                        });
                    }
                }
                else {
                    Toaster.error('Invalid File Format')
                }
            }
        }
        React.forwardRef(({ onClick, ...rest }, ref) => {
            return (
                <>
                    <div className="form-check pb-5" style={{ backgroundColor: '' }}>
                        <input
                            type="checkbox"
                            className="form-check-input"
                            style={{ height: '20px', width: '20px' }}
                            ref={ref}
                            onClick={onClick}
                            {...rest}
                        />
                        <label className="form-check-label" id="booty-check" />
                    </div>
                </>
            )
        })
    }
    const addSelectedDataValues = (checked, record) => {
        console.log(record, 'record');
        var ele = document.getElementsByName('allchecked');

        let array
        let tempArray = labelCheckBox


        if (checked === true) {

            let temp = []
            tempArray.push(record.label_description)
        } else {
            for (let i in tempArray) {
                if (tempArray[i] === record.label_description) {
                    tempArray.splice(i, 1)
                }

                for (var j = 0; j < ele.length; j++) {
                    if (ele[j].type === 'checkbox')
                        ele[j].checked = false;
                }

            }

        }
        setlabelCheckBox([...tempArray])


    }
    console.log(labelLists && labelLists.label_name, 'abelLists.label_name');
    const addAllSelectedDataValues = (checked) => {

        // console.log(data,'data');

        var ele = document.getElementsByName('checked');

        if (checked === true) {

            let data = labelLists && labelLists.label_name && labelLists.label_name.length > 0 && labelLists.label_name.map((item, i) => {
                return item.label_description
            })
            console.log(data, 'data');
            setlabelCheckBox(data)
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type === 'checkbox')
                    ele[i].checked = true;
            }
        } else {

            setlabelCheckBox([])
            for (var j = 0; j < ele.length; j++) {
                if (ele[j].type === 'checkbox')
                    ele[j].checked = false;
            }
        }
    }
    console.log(labelCheckBox, 'labelCheckBox');

    useEffect(() => {
        if (data && data.legalEntity != '') {
            const payload2 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "complianceCertTaskFilters",
                        {
                            "le_id": data.legalEntity
                        }
                    ]
                }
            ]

            getComplianceCertificateFilter({
                payload: payload2,
                paramid: paramid
            })


        }

    }, [data.legalEntity])

    const handleOk = () => {
        setIsModalOpen(true);
        if (validator.current.allValid()) {
            const payload2 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetMonthlyComplianceReport",
                        {
                            "country_id": vendorComplianceScore.country ? Number(vendorComplianceScore.country) : 1,
                            "legal_entity_id": entityid,
                            "domain_id": vendorComplianceScore.domain ? Number(vendorComplianceScore.domain) : 0,
                            "division_id": vendorComplianceScore.division ? Number(vendorComplianceScore.division) : 0,
                            "category_id": vendorComplianceScore.category ? Number(vendorComplianceScore.category) : 0,
                            "unit_id": vendorComplianceScore.vendor_id ? Number(vendorComplianceScore.vendor_id) : 0,
                            "from_date": moment(vendorComplianceScore.from_date).format("MMMM-YYYY"),
                            "to_date": moment(vendorComplianceScore.from_date).format("MMMM-YYYY"),
                            "csv": true,
                            "from_count": 0,
                            "page_count": 0,
                            "list_check": checkboxValue
                        }
                    ]
                }
            ]
            MonthlyComplianceExport({
                payload: payload2,
                paramid: paramid
            })
        }
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        setComplianceStatusModal(false)
        setDetailsModal(false)
        setLabelModal(false)
        setsearchModal(false)
        setlabelCheckBox([])
        setLabelArray([])
    };
    // useEffect(() => {
    //     if (tabledatas.row) {
    //         if (tabledatas.row.length > 0) {
    //             setTableShow(true)
    //         }
    //         else {
    //             setTableShow(false)
    //             // Toaster.error("There is no compliance certificate available")
    //         }
    //     }
    // }, [tabledatas.row])

    console.log(tabledatas.row, 'tabledatas.row');

    const onshow = () => {
        setAddFormSubmit(true)
        console.log(validator.current.allValid(), 'validator.current.allValid()');
        if (validator.current.allValid()) {
            setTableShow(true)
            console.log('aishuuuuuuu');
            // setKey(0)
            const payload1 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "FetchCertificateDetails",
                        {
                            "le_id": data.legalEntity,
                            "v_id": Number(data.vendor),
                            "d_id": Number(data.domain)
                        }
                    ]
                }
            ]
            getComplianceCertificateTable({
                payload: payload1,
                paramid: paramid
            })

        }
    }
    const onSubmit = () => {


        setSubmit(true)

        setcertificateFormSubmit(true)

        if (Formvalidator.current.allValid()) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetVndrWiseCmp",
                        {
                            "cer_mon": month,
                            "v_id": Number(data.vendor),
                            "legal_entity_id": data.legalEntity,
                            "d_id": Number(data.domain)
                        }
                    ]

                }
            ]

            searchCompliance({
                payload: payload,
                paramid: paramid
            })
            console.log('aishuuuuuuu');
            // setKey(0)


        }
    }

    console.log(certId, 'certId');

    const onRectifySubmit = () => {
        setSubmit(true)
        setcertificateFormSubmit(true)
        if (fileupload.length == 0) {
            if (Formvalidator.current.allValid()) {

                let datas = JSON.stringify(labelList)
                let data1 = JSON.stringify(datas)

                const payload =
                    [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "UpdateCertificate",
                                {
                                    "le_id": data.legalEntity,
                                    "cert_id": Number(certId),
                                    "inv_req": invoice == true ? 1 : 0,
                                    "inv_no": invoice == true ? invoiceData.invoice_no : null,
                                    "inv_date": invoice == true ? moment(new Date(invoiceData.invoice_date)).format('DD/MM/YYYY') : null,
                                    "vendorid": Number(data.vendor),
                                    "cer_mon": month,
                                    "label_value": datas ? datas : "{}",
                                    "removed_documents": [],
                                    "documents": fileuploadData.length > 0 ? fileuploadData : null
                                }
                            ]
                        }
                    ]

                const payload1 = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "FetchCertificateDetails",
                            {
                                "le_id": data.legalEntity,
                                "v_id": Number(data.vendor),
                                "d_id": Number(data.domain)
                            }
                        ]
                    }
                ]

                updateCertificate({
                    payload: payload,
                    paramid: paramid,
                    payload1: payload1,
                    setComplianceSave: setComplianceSave
                })
                console.log('aishuuuuuuu');
                // setKey(0)

            }
        }
        else {
            const filePayload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "SaveCertDocuments",
                        {
                            "le_id": data && data.legalEntity,
                            "unit_id": Number(data.vendor),
                            "c_id": 1,
                            "file_info": fileupload,
                        }
                    ]
                }
            ]
            console.log(filePayload, 'filePayload');
            UploadFile1({
                payload: filePayload,
                paramid: paramid
            })
        }
    }

    const onCreate = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            setComplianceSave(true)
            console.log('aishuuuuuuu');
            // setKey(0)
            const payload1 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetMappingInfo",
                        {
                            "v_id": Number(data && data.vendor),
                            "legal_entity_id": data && data.legalEntity
                        }
                    ]
                }
            ]
            mappingDetails({
                payload: payload1,
                paramid: paramid
            })
            const payload2 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetLabelList",
                        {}
                    ]
                }
            ]
            getLabelList({
                payload: payload2,
                paramid: paramid
            })
            setMonth('')
        }
    }
    const onChange = (key) => {
        console.log(key);
    };

    useEffect(() => {
        validator.current.showMessages()
        Formvalidator.current.showMessages()
    }, [])

    useEffect(() => {
        if (vendorComplianceScore.legel_entity == localStorage.getItem('SelectedEntity')) {
            Setlegalstate(true)
        }
    }, [vendorComplianceScore.legel_entity])

    useEffect(() => {
        if (isAuth) {
            if (legalstate === true) {
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetMonthlyComplianceReportFilters",
                            {
                                "country_id": Number(vendorComplianceScore.country),
                                "legal_entity_id": entityid
                            }
                        ]
                    }
                ]
                if (entityid != 'null') {

                    MonthlyComplianceFilterReport({
                        payload: payload,
                        paramid: paramid
                    })
                }
                Setlegalstate(false)
            }
        }

    }, [isAuth, entityid, legalstate])

    useEffect(() => {
        if (entityid === null) {
            setCurrentEntity(vendorComplianceScore.le_id)
        }
    }, [entityid])
    useEffect(() => {
        if (data && data.legalEntity != "") {
            if (filterValue && filterValue.domains_list && filterValue.domains_list.length > 0) {
                let domainData = _.filter(filterValue && filterValue.domains_list, { legal_entity_id: data.legalEntity })
                setDomain(domainData)
            }

        }
    }, [filterValue && filterValue.domains_list, data && data.legalEntity])

    useEffect(() => {
        if (filteredArray && filteredArray.length > 0) {
            filteredArray && filteredArray.map((item, i) => {
                return (
                    setFilteredArrayState(item)
                )
            })
        }
    }, [filteredArray])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') != 'All Legal Entity') {
            setVendorComplianceScore({
                ...vendorComplianceScore,
                le_id: localStorage.getItem('SelectedEntityid')
            })
        }
    }, [localStorage.getItem('SelectedEntity') != 'All Legal Entity'])

    useEffect(() => {
        if (filterValue) {
            setCategory(filterValue && filterValue.categories_list)
            setDivision(filterValue && filterValue.divisions_list)
            // setDomain(filterValue && filterValue.domains_list)
            setVendor(filterValue && filterValue.units_list)
        }
    }, [filterValue])

    const homescreen = () => {
        historynew.push(homepage);
        // window.location.href = homepage
        document.getElementById('homehover').style.color = "blue"
    }

    const checkBoxOnChange = (e) => {
        let tempIds = []
        let temp = checkboxValue
        if (temp.includes(Number(e.target.value)) == true) {
            for (let i in temp) {
                if (temp[i] == Number(e.target.value)) {
                    temp.splice(i, 1)
                }
            }
            setCheckBoxValue([...temp])
        } else {
            tempIds.push(Number(e.target.value))
            setCheckBoxValue([...checkboxValue, ...tempIds])
        }
    }

    const selectAllCheckBox = (checked) => {
        var ele = document.getElementsByName('chk');
        let tempAllIds = []
        if (checked == true) {
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == 'checkbox') {
                    ele[i].checked = true;
                    tempAllIds.push(Number(ele[i].value))
                }
            }
            setCheckBoxValue(tempAllIds)
        }
        else {
            var ele = document.getElementsByName('chk');
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == 'checkbox')
                    ele[i].checked = false;

            }
            setCheckBoxValue([1, 2, 3, 4, 5, 6, 11])
        }
    }
    // useEffect(() => {
    //     if (tabledatas) {
    //         setTableData(tabledatas && tabledatas.monthly_compliances)
    //     }
    //     if (tabledatas.total_count) {
    //         setCount(tabledata && tabledatas.total_count)
    //     }
    // }, [tabledatas, tabledatas.monthly_compliances, tabledatas.total_count, count])


    // useEffect(() => {
    //     if (pageState == true) {
    //         const payload1 = [
    //             authtoken,
    //             {
    //                 "session_token": authtoken,
    //                 "request": [
    //                     'GetCriticalityData', {
    //                         "country_id": vendorComplianceScore.country ? Number(vendorComplianceScore.country) : 1,
    //                         "legal_entity_id": entityid,
    //                         "domain_id": vendorComplianceScore.domain ? Number(vendorComplianceScore.domain) : 0,
    //                         "division_id": vendorComplianceScore.division ? Number(vendorComplianceScore.division) : 0,
    //                         "category_id": vendorComplianceScore.category ? Number(vendorComplianceScore.category) : 0,
    //                         "unit_id": vendorComplianceScore.vendor_id ? Number(vendorComplianceScore.vendor_id) : 0,
    //                         "from_date": moment(vendorComplianceScore.from_date).format("MMMM-YYYY"),
    //                         "to_date": moment(vendorComplianceScore.from_date).format("MMMM-YYYY"),
    //                         "csv": true,
    //                         "from_count": 0,
    //                         "page_count": 0,
    //                         "list_check": null,
    //                         'csv': false,
    //                         'f_count': Math.max(((current - 1) * pageSize), 0) + 1,
    //                         't_count': current != 0 ? current * pageSize : pageSize,
    //                         'count_qry': true,
    //                         'list_check': null
    //                     }
    //                 ]
    //             }
    //         ]
    //         MonthlyComplianceTable({
    //             payload: payload1,
    //             paramid: paramid
    //         })
    //     }
    // }, [current, pageSize, pageState])

    console.log(vendor, 'vendorrrrr')
    console.log(domain, 'domaindomain');

    return (

        <Fragment>
            <div id='page-wrapper' className="page-wrapper">
                {/* <ScrollToTop smooth color="red" component={<Tooltip title="Back to top"><UpOutlined /></Tooltip>} />
            {document.body.scrollHeight > document.body.clientHeight ?
                <div className='back-to-top'>
                    <ScrollButton />
                </div> : ''} */}
                <div className='back-to-top'>
                    <ScrollButton />
                </div>
                <div className="page-titles pb-0 pt-0 page-title-sticky">
                    {/* <div className="col-md-12"> */}
                    <div className="row py-2">
                        {/* <div className="col-md-8"> */}
                        <div className="col-lg-8 col-md-6 col-12 align-self-center">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 d-flex align-items-center">
                                    <li className="breadcrumb-item">
                                        <span className='pointer' id='homehover' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        <span >Transaction</span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                        <span>Compliance Certificate</span>
                                    </li>

                                </ol>
                            </nav>
                        </div>
                        {/* </div> */}
                        <div className="col-lg-4 col-md-6 text-end">
                            {complianceSave == true ?
                                <Button type="primary" shape="round" className='addbutton' style={{ marginRight: '2%' }}
                                    icon={<ArrowLeftOutlined />} size='default' onClick={() => {
                                        setIsEdit(false)
                                        setComplianceSave(false)
                                        setApproveLabel([])
                                        setcertificateFormSubmit(false)
                                        setInvoice(false)
                                        setFileUpload([])
                                        setDateChange(false)
                                    }}>
                                    Back
                                </Button> : false}

                            <button onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black full-mode fullscreenradius" >
                                <Tooltip placement="left" title={is_Fullscreen == true ? "Exit Full Screen Mode" : "Full Screen Mode"}><FullscreenOutlined /></Tooltip>
                            </button>
                        </div>
                    </div>
                    {/* </div> */}
                </div>

                {complianceSave == false ?
                    <div className="container-fluid pt-1">
                        <div className="row">
                            <div className="col-12">

                                <div>
                                    <div className={localStorage.getItem('currentTheme')} >
                                        <Collapse className='report-collapse' accordion defaultActiveKey={["1"]}>
                                            <Panel header="Compliance Certificate" key="1"
                                            // extra={tableshow == true ? genextra() : false}
                                            >

                                                <div className='col-md-12'>
                                                    <div className='row'>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label htmlFor=""><b>Country : </b><span style={{ color: "red" }}>*</span></label><br />
                                                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                                    <div className="form-group">
                                                                        <Select
                                                                            placeholder='Enter Country'
                                                                            allowClear={true}
                                                                            size="default"
                                                                            onPaste={onPasteToaster}
                                                                            searchValue={selectSearchValue.country}
                                                                            onSearch={(value) => {
                                                                                let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                                                                setSelectSearchValue({
                                                                                    ...selectSearchValue,
                                                                                    country: result
                                                                                });
                                                                            }}

                                                                            onChange={(value, datas) => {
                                                                                console.log(datas, 'datas');
                                                                                if (value !== undefined) {
                                                                                    let entityData = _.filter(sessionArr, { c_id: Number(value) })
                                                                                    setEntity(entityData)
                                                                                    setData({
                                                                                        ...data,
                                                                                        country: value,
                                                                                        countryName: datas.children,
                                                                                        legalEntity: '',
                                                                                        division: '',
                                                                                        category: '',
                                                                                        vendor: '',
                                                                                        domain: '',
                                                                                        vendorName: '',
                                                                                        dName: '',
                                                                                        leName: '',
                                                                                        // countryName: '',
                                                                                        divName: '',
                                                                                        catName: ''
                                                                                    })
                                                                                    // setDomain([])
                                                                                }
                                                                                else {
                                                                                    setData({
                                                                                        ...data,
                                                                                        country: '',
                                                                                        legalEntity: '',
                                                                                        division: '',
                                                                                        category: '',
                                                                                        vendor: '',
                                                                                        domain: '',
                                                                                        vendorName: '',
                                                                                        countryName: '',
                                                                                        divName: '',
                                                                                        catName: '',
                                                                                        dName: '',
                                                                                        leName: '',
                                                                                    })
                                                                                    // setDomain([])
                                                                                }
                                                                            }}

                                                                            showSearch
                                                                            optionFilterProp="children"
                                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                            value={data.countryName ? data.countryName : null}
                                                                            style={{ width: '100%', marginTop: '5px' }}

                                                                        // value={unitdata.countryName || undefined}
                                                                        >
                                                                            {/* {country_info && country_info.length && country_info.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.c_id}>
                                                                            {item.c_name}
                                                                        </Option>
                                                                    );
                                                                })} */}


                                                                            {uniq_country_data && uniq_country_data.length && uniq_country_data.map((item, i) => {
                                                                                return (
                                                                                    <Option key={item.c_id}>
                                                                                        {item.c_name}
                                                                                    </Option>
                                                                                );
                                                                            })}


                                                                        </Select>
                                                                        {validator.current.message(
                                                                            'country',
                                                                            data.country,
                                                                            'required',
                                                                            {
                                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                                messages: {
                                                                                    required: 'Country Required',

                                                                                }
                                                                            })}
                                                                    </div> :
                                                                    <p>{sessionArr[0].c_name}</p>
                                                                }

                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label htmlFor=""><b>Legal Entity : </b><span style={{ color: "red" }}>*</span></label><br />

                                                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?

                                                                    <div className="form-group">

                                                                        <Select
                                                                            // disabled={consolidatedData.country == '' ? true : false}
                                                                            allowClear={true}
                                                                            size="default"
                                                                            className='text-wrap-report'
                                                                            disabled={data.country == '' ? true : false}
                                                                            placeholder="Enter Legal Entity"
                                                                            onPaste={onPasteToaster}
                                                                            searchValue={selectSearchValue.legalEntity}
                                                                            onSearch={(value) => {
                                                                                let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                                                setSelectSearchValue({
                                                                                    ...selectSearchValue,
                                                                                    legalEntity: result
                                                                                });
                                                                            }}
                                                                            onChange={(value, datas) => {
                                                                                if (value !== undefined) {
                                                                                    setData({
                                                                                        ...data,
                                                                                        legalEntity: value,
                                                                                        leName: datas.children,
                                                                                        division: '',
                                                                                        category: '',
                                                                                        vendor: '',
                                                                                        domain: '',
                                                                                        vendorName: '',
                                                                                        domainName: '',
                                                                                        divName: '',
                                                                                        catName: ''
                                                                                    })
                                                                                    // setDomain([])
                                                                                }
                                                                                else {
                                                                                    setData({
                                                                                        ...data,
                                                                                        legalEntity: '',
                                                                                        leName: '',
                                                                                        division: '',
                                                                                        category: '',
                                                                                        domainName: '',
                                                                                        vendor: '',
                                                                                        domain: '',
                                                                                        vendorName: '',
                                                                                        divName: '',
                                                                                        catName: ''

                                                                                    })
                                                                                    // setDomain([])
                                                                                }
                                                                                // setCurrentEntity(data)
                                                                                // setConsolidatedData({

                                                                                //     ...consolidatedData,
                                                                                //     legel_entity: data
                                                                                // })
                                                                                // setData({
                                                                                //     ...data,
                                                                                //     le_name: value.children
                                                                                // })
                                                                                // setCurrentEntity(data)
                                                                            }}
                                                                            showSearch
                                                                            optionFilterProp="children"
                                                                            value={data.leName ? data.leName : null}
                                                                            // value={consolidatedData.legel_entity || undefined}
                                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                            //value={Settings.le_id}
                                                                            style={{ width: '100%', marginTop: '5px' }}

                                                                        >

                                                                            {entity && entity.length > 0 && entity.map((item, i) => {
                                                                                return (
                                                                                    <Option key={item.le_id}>
                                                                                        {item.le_name}
                                                                                    </Option>
                                                                                );
                                                                            })}


                                                                        </Select>

                                                                        {validator.current.message(
                                                                            'legal Entity',
                                                                            data.legalEntity,
                                                                            'required',
                                                                            {
                                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                                messages: {
                                                                                    required: 'Legal Entity Required',

                                                                                }
                                                                            })}
                                                                    </div> :
                                                                    <p className='text-wrap-report' style={{ width: '100%' }}>{localStorage.getItem('SelectedEntity')}</p>
                                                                }


                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label htmlFor=""><b>Domain : </b><span style={{ color: "red" }}>*</span></label><br />
                                                                {domainValue == false || (domain && domain.length > 1) ?
                                                                    <>
                                                                        <Select
                                                                            allowClear={true}
                                                                            disabled={data.legalEntity == '' ? true : false}
                                                                            // disabled={dataa.legalentity == "" ? true : false}
                                                                            size="default"
                                                                            placeholder="Enter Domain"
                                                                            // onClick={() => {
                                                                            //     if (dataa.legalentity == "") {

                                                                            //         toast.warning("Legal Entity Required")
                                                                            //     }
                                                                            // }}
                                                                            onPaste={onPasteToaster}
                                                                            searchValue={selectSearchValue.domain}
                                                                            onSearch={(value) => {
                                                                                let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                                                                setSelectSearchValue({
                                                                                    ...selectSearchValue,
                                                                                    domain: result
                                                                                });
                                                                            }}
                                                                            onChange={(value, datas) => {
                                                                                if (value !== undefined) {
                                                                                    setData({
                                                                                        ...data,
                                                                                        domain: value,
                                                                                        domainName: datas.children,
                                                                                        vendor: '',
                                                                                        vendorCode: '',
                                                                                        vendorName: '',
                                                                                        division: '',
                                                                                        divName: '',
                                                                                        catName: '',
                                                                                        category: '',

                                                                                    })
                                                                                } else {
                                                                                    setData({
                                                                                        ...data,
                                                                                        domain: '',
                                                                                        domainName: '',
                                                                                        vendor: '',
                                                                                        vendorCode: '',
                                                                                        vendorName: '',
                                                                                        division: '',
                                                                                        divName: '',
                                                                                        catName: '',
                                                                                        category: '',
                                                                                    })
                                                                                }
                                                                            }}
                                                                            value={data.domainName ? data.domainName : null}
                                                                            // value={dataa.d_name || undefined}
                                                                            showSearch
                                                                            optionFilterProp="children"
                                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                            style={{ width: '100%', marginTop: "5px" }}
                                                                        >
                                                                            {domain && domain.length && domain.map((item, i) => {
                                                                                return (
                                                                                    <Option key={item.domain_id}>
                                                                                        {item.domain_name}
                                                                                    </Option>
                                                                                );
                                                                            })}

                                                                        </Select>
                                                                        {validator.current.message(
                                                                            'domain',
                                                                            domain && domain.length && domain[0].domain_name,
                                                                            'required',
                                                                            {
                                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                                messages: {
                                                                                    required: 'Domain Required',

                                                                                }
                                                                            })}
                                                                    </>

                                                                    :
                                                                    <>
                                                                        <p className='cut-text-word' style={{ width: '100%', marginTop: "5px" }} value={domain && domain.length && domain[0].domain_name}>{domain && domain.length && domain[0].domain_name}</p>
                                                                        {validator.current.message(
                                                                            'domain',
                                                                            domain && domain.length && domain[0].domain_name,
                                                                            'required',
                                                                            {
                                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                                messages: {
                                                                                    required: 'Domain Required',

                                                                                }
                                                                            })}
                                                                    </>

                                                                }

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12" style={{ marginTop: "5px" }}>
                                                    <div className='row'>

                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label htmlFor=""><b>Division : </b></label><br />
                                                                <Select
                                                                    placeholder='Enter Division'
                                                                    value={data.divName ? data.divName : null}
                                                                    disabled={data.domainName == '' ? true : false}
                                                                    size="default"
                                                                    allowClear={true}
                                                                    onPaste={onPasteToaster}
                                                                    searchValue={selectSearchValue.division}
                                                                    onSearch={(value) => {
                                                                        let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                                        setSelectSearchValue({
                                                                            ...selectSearchValue,
                                                                            division: result
                                                                        });
                                                                    }}
                                                                    onChange={(value, datas) => {
                                                                        setData({
                                                                            ...data,
                                                                            division: value ? value : '',
                                                                            divName: datas ? datas.children : '',
                                                                            catName: '',
                                                                            category: '',
                                                                            vendor: '',
                                                                            vendorName: ''
                                                                        })
                                                                        if (value !== undefined) {
                                                                            let catData = _.filter(filterValue && filterValue.categories_list, { div_id: Number(value) })
                                                                            setCategory(catData)
                                                                            let vendorData = _.filter(filterValue && filterValue.units_list, { division_id: Number(value) })
                                                                            console.log(vendorData, 'vendorData');
                                                                            setVendor(vendorData)
                                                                        } else {
                                                                            console.log(filterValue && filterValue.units_list, 'filterValue && filterValue.units_list');
                                                                            setCategory(filterValue && filterValue.categories_list)
                                                                            setVendor(filterValue && filterValue.units_list)
                                                                        }
                                                                    }}
                                                                    showSearch

                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                    style={{ width: '100%', marginTop: "5px" }}

                                                                >
                                                                    {division && division.length && division.map((item, i) => {
                                                                        return (
                                                                            <Option key={item.div_id
                                                                            }>
                                                                                {item.div_name
                                                                                }
                                                                            </Option>
                                                                        );
                                                                    })}

                                                                </Select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label htmlFor=""><b>Category : </b></label><br />
                                                                <Select
                                                                    value={data.catName ? data.catName : null}
                                                                    allowClear={true}
                                                                    disabled={data.divName == '' ? true : false}
                                                                    size="default"
                                                                    placeholder="Enter Category"
                                                                    onPaste={onPasteToaster}
                                                                    searchValue={selectSearchValue.category}
                                                                    onSearch={(value) => {
                                                                        let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                                        setSelectSearchValue({
                                                                            ...selectSearchValue,
                                                                            category: result
                                                                        });
                                                                    }}
                                                                    onChange={(value, datas) => {
                                                                        if (value !== undefined) {
                                                                            setData({
                                                                                ...data,
                                                                                category: value,
                                                                                catName: datas.children
                                                                            })
                                                                            let vendorData = _.filter(filterValue && filterValue.units_list, {
                                                                                category_id: Number(value)
                                                                            })
                                                                            setVendor(vendorData)
                                                                        } else {
                                                                            setData({
                                                                                ...data,
                                                                                category: '',
                                                                                catName: '',
                                                                                vendor: '',
                                                                                vendorName: ''
                                                                            })
                                                                            setVendor(filterValue && filterValue.units_list)
                                                                        }
                                                                    }}
                                                                    // onClick={() => {
                                                                    //     if (dataa.legalentity == "") {

                                                                    //         toast.warning("Legal Entity Required")
                                                                    //     }
                                                                    // }}
                                                                    // onChange={(data, value) => {


                                                                    //     setDataa({
                                                                    //         ...dataa,
                                                                    //         d_id: data,
                                                                    //         d_name: value.children,
                                                                    //         usr_name: '',
                                                                    //         unit_name: '',
                                                                    //         user_type: ''
                                                                    //     })
                                                                    // }}
                                                                    // value={dataa.d_name || undefined}
                                                                    showSearch
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                    style={{ width: '100%', marginTop: "5px" }}
                                                                >
                                                                    {category && category.length && category.map((item, i) => {
                                                                        return (
                                                                            <Option key={item.cat_id}>
                                                                                {item.cat_name}
                                                                            </Option>
                                                                        );
                                                                    })}

                                                                </Select>
                                                                {/* {validator.current.message(
                                                                        'domain',
                                                                        dataa.d_id,
                                                                        'required',
                                                                        {
                                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                            messages: {
                                                                                required: 'Domain Required',

                                                                            }
                                                                        })} */}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label htmlFor=""><b>Vendor : </b><span style={{ color: "red" }}>*</span></label><br />
                                                                <Select
                                                                    allowClear={true}
                                                                    value={data.vendorName ? data.vendorName : null}
                                                                    disabled={data.domainName == '' ? true : false}
                                                                    // disabled={dataa.legalentity == "" ? true : false}
                                                                    size="default"
                                                                    placeholder="Enter Vendor"
                                                                    // onClick={() => {
                                                                    //     if (dataa.legalentity == "") {

                                                                    //         toast.warning("Legal Entity Required")
                                                                    //     }
                                                                    // }}
                                                                    onPaste={onPasteToaster}
                                                                    searchValue={selectSearchValue.vendor}
                                                                    onSearch={(value) => {
                                                                        let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                                        setSelectSearchValue({
                                                                            ...selectSearchValue,
                                                                            vendor: result
                                                                        });
                                                                    }}
                                                                    onChange={(value, datas) => {
                                                                        if (value !== undefined) {
                                                                            console.log(datas, 'datas');
                                                                            let splitName = datas.children.split('-')
                                                                            console.log(splitName, 'splitName');

                                                                            setData({
                                                                                ...data,
                                                                                vendor: value,
                                                                                vendorName: datas.children,
                                                                                vendorCode: splitName && splitName.length > 0 && splitName[0],
                                                                                // division: '',
                                                                                // divName: '',
                                                                                // catName: '',
                                                                                // category: '',
                                                                            })
                                                                        } else {
                                                                            setData({
                                                                                ...data,
                                                                                vendor: '',
                                                                                vendorName: '',
                                                                                vendorCode: '',
                                                                                // division: '',
                                                                                // divName: '',
                                                                                // catName: '',
                                                                                // category: '',
                                                                            })

                                                                        }
                                                                    }}
                                                                    // value={dataa.d_name || undefined}
                                                                    showSearch
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                    style={{ width: '100%', marginTop: "5px" }}
                                                                >
                                                                    {vendor && vendor.length && vendor.map((item, i) => {
                                                                        return (
                                                                            <Option key={item.unit_id
                                                                            }>
                                                                                {item.unit_name
                                                                                }
                                                                            </Option>
                                                                        );
                                                                    })}
                                                                </Select>
                                                                {validator.current.message(
                                                                    'vendor',
                                                                    data.vendor,
                                                                    'required',
                                                                    {
                                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                        messages: {
                                                                            required: 'Vendor Required',

                                                                        }
                                                                    })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className='col-md-12' style={{ marginTop: "5px" }}>
                                                    <div className='row'>
                                                        <div className='col-md-4'></div>
                                                        <div className='col-md-4 text-center'>
                                                            <Button type="primary" shape="round" className='addbutton m-1' icon={<FileAddOutlined />}
                                                                onClick={onCreate}
                                                            >

                                                                Create Certificate
                                                            </Button>
                                                            <Button type="primary" shape="round" className='addbutton m-1' icon={<EyeOutlined />}
                                                                onClick={onshow}
                                                            >
                                                                Show
                                                            </Button>

                                                        </div>
                                                        <div className='col-md-4'></div>
                                                    </div>
                                                </div>
                                            </Panel>
                                        </Collapse>
                                    </div>
                                    {tableshow == true ? <div className="col-md-12" style={{ marginTop: "20px" }}>
                                        <div className='service-provider-table'>
                                            <Table
                                                size={'small'}
                                                className={localStorage.getItem("currentTheme")}
                                                // className='userprivclass'
                                                columns={columns}
                                                dataSource={tabledatas.row}
                                                bordered
                                                // fixedHeader={dataTableProperties.fixedHeader}
                                                pagination={false} />
                                        </div>
                                    </div> : false}

                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="container-fluid pt-1">
                        {/* <div className="row">
                            <div className="col-12"> */}
                        {/* <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", marginLeft: '90%' }}
                            icon={<ArrowLeftOutlined />} size='default' onClick={() => { setComplianceSave(false) }}>
                            Back
                        </Button> */}
                        <div className="card mt-2">
                            <div className="card-body" >
                                <div className={localStorage.getItem('currentTheme')}>
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label className="mt-1" htmlFor=""><b>Vendor Code:</b></label>
                                                <Input type="text" autocomplete="off" className='form-control' name="emp_name" value={data.vendorCode}
                                                // style={{ marginTop: "5px", width: "60%" }} 
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <label className="mt-1" htmlFor=""><b>Vendor Name:</b></label>
                                                <Input type="text" autocomplete="off" className='form-control' name="emp_name" value={data.vendorName}
                                                // style={{ marginTop: "5px", width: "60%" }} 
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <label className="mt-1" htmlFor=""><b>Certificate process for the month of :</b><span style={{ "color": "red" }}>*</span></label>
                                                <div className="form-group">
                                                    <DatePicker
                                                        style={{ width: dateChange == true ? "85%" : "95%" }}
                                                        allowClear={false} picker="month" format={'MMMM-YYYY'}
                                                        placeholder='Month Year'
                                                        value={month ? moment(month) : undefined}
                                                        disabled={isEdit}

                                                        onChange={(date, dateString) => {
                                                            console.log(dateString, 'dateString');
                                                            setMonth(dateString)
                                                            // setVendorComplianceScore({
                                                            //     ...vendorComplianceScore,
                                                            //     from_month: dateString,
                                                            //     to_month: moment(dateString).add(2, 'months')
                                                            // })
                                                            setDateChange(true)
                                                        }}
                                                        onKeyDown={(event) => {
                                                            event.preventDefault()
                                                        }}
                                                        disabledDate={(current) => {
                                                            return Date.now() <= current ||
                                                                moment().subtract(2, 'years') > current

                                                        }}
                                                    />
                                                    {Formvalidator.current.message(
                                                        'month',
                                                        month,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${certificateFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Certificate month Required',

                                                            }
                                                        })}

                                                    {dateChange == true ?
                                                        <button onClick={onSearch} className="bg-transparent text-black full-mode fullscreenradius" style={{ marginLeft: '10px' }} >
                                                            <Tooltip placement="top" title="Search"><SearchOutlined /></Tooltip>
                                                        </button>
                                                        : false}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-1">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label className="mt-3" htmlFor="" ><b>Invoice:</b></label><br />
                                                <input style={{ marginTop: '1%' }} checked={invoice === true ? true : false} ty type="radio" id="html" name="fav_language" value="Yes" onChange={() => {
                                                    setInvoice(true)
                                                }}></input><label style={{ marginLeft: '2%' }} >Yes</label>
                                                <input style={{ marginLeft: '2%' }} checked={invoice === false ? true : false} type="radio" id="html" name="fav_language" value="No" onChange={() => {
                                                    setInvoice(false)
                                                }}></input><label style={{ marginLeft: '2%' }} >No</label>
                                            </div>
                                            <div className="col-md-4">
                                                {invoice === true ?
                                                    <>
                                                        <label className="mt-1" htmlFor=""><b>Invoice Number:</b><span style={{ "color": "red" }}>*</span></label>
                                                        {/* // <p style={{ marginTop: '3%', marginLeft: '40%' }}> - </p> : */}
                                                        <div className="form-group">
                                                            <Input maxLength='20' placeholder='Enter Invoice Number' onKeyPress={(event) => {
                                                                if (!/^[A-Za-z0-9 . _-]{0,20}$/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }} type="text" autocomplete="off" className='form-control' value={invoiceData.invoice_no} disabled={invoice === false ? true : false} name="invoice_no" onChange={onInputChange}

                                                                style={{ marginTop: "5px", width: "100%" }} />
                                                            {Formvalidator.current.message(
                                                                'invoice_no',
                                                                invoiceData.invoice_no,
                                                                [
                                                                    'required',

                                                                    { regex: /^[A-Za-z0-9 ._-]{0,20}$/ }



                                                                ],
                                                                {
                                                                    className: `invalid-feedback ${certificateFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Invoice Number Required',
                                                                        regex: 'Enter Valid Invoice Number',


                                                                    }
                                                                })}
                                                        </div>
                                                    </> : ''}
                                            </div>
                                            <div className="col-md-4">
                                                {invoice === true ? <>
                                                    <label className="mt-1" htmlFor=""><b>Invoice Date:</b><span style={{ "color": "red" }}>*</span></label><br />
                                                    {/* <p style={{ marginTop: '3%', marginLeft: '40%' }}> - </p> : */}
                                                    <div className="form-group">
                                                        <DatePicker style={{ width: "95%", marginTop: '5px' }} value={invoiceData.invoice_date ? moment(invoiceData.invoice_date) : undefined} disabled={invoice === false ? true : false} allowClear={false} picker="date"
                                                            format={"DD-MMM-YYYY"}
                                                            className={"form-control"}
                                                            placeholder="DD-MMM-YYYY"
                                                            // value={vendorComplianceScore.from_month ? moment(vendorComplianceScore.from_month) : ''}
                                                            onKeyDown={(event) => {
                                                                event.preventDefault()
                                                            }}
                                                            onChange={(date, dateString) => {
                                                                // setVendorComplianceScore({
                                                                //     ...vendorComplianceScore,
                                                                //     from_month: dateString,
                                                                //     to_month: moment(dateString).add(2, 'months')
                                                                // })

                                                                setInvoiceData({
                                                                    ...invoiceData,
                                                                    invoice_date: dateString
                                                                })
                                                            }}
                                                            disabledDate={(current) => {
                                                                return Date.now() <= current

                                                            }}
                                                        />
                                                        {Formvalidator.current.message(
                                                            'invoice Date',
                                                            invoiceData.invoice_date,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${certificateFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Invoice Date Required',

                                                                }
                                                            })}
                                                    </div></> : ''}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Divider />
                                <div className="col-md-12">
                                    <div className="row certificate-card">
                                        <div className="col-md-6">
                                            <Card className='bg-light' style={{ height: '295px', borderRadius: '20px' }}>
                                                <div style={{ width: '100%' }} className="d-flex align-items-center">
                                                    <div>
                                                        <label style={{ minWidth: '180px' }} htmlFor=""><b>Upload Documents : </b></label>
                                                        <p className='m-0 fs-2 p-0'>(Maximum 25MB per file/task)</p>
                                                        {fileupload && fileupload.length > 0 ?
                                                            <span><b className='mt-2'>Attached Documents :</b></span> : ''}
                                                    </div>
                                                    {/* <div className={'d-flex flex-row-reverse serv-file-upload my-1 popupbtncolour ' + localStorage.getItem('currentTheme')}>
                                                        <label htmlFor="file-upload" className='btn btn-primary upload-btn p-1 d-flex justify-content-center align-items-center'><CloudUploadOutlined /></label>
                                                        <input
                                                            id="file-upload"
                                                            type="file"
                                                            multiple
                                                            name="file"
                                                            value={''}
                                                            accept="application/pdf, application/vnd.ms-excel,image/x-png,image/gif,image/jpeg"
                                                            className={'file-upload-certificate d-none'}
                                                            onChange={(e) => {
                                                                SavepastRecordOnChange(e)
                                                            }}
                                                        // value={fileupload && fileupload.length}
                                                        />
                                                    </div> */}
                                                    <div className={'d-flex flex-row-reverse serv-file-upload my-1 popupbtncolour ' + localStorage.getItem('currentTheme')}>
                                                        {/* <label htmlFor="file-upload"
                                                                                    className={`rounded-end w-45 btn  p-1 d-flex justify-content-center align-items-center ${localStorage.getItem('currentTheme')}`}><i className='fas fa-upload me-1'></i>Select File</label>
                                                                                <input
                                                                                    className='bg-white w-50 border rounded-start p-2'
                                                                                    // value={0}
                                                                                    id="file-upload"
                                                                                    type="file"
                                                                                    name="file"
                                                                                    multiple
                                                                                    onChange={(e) => {
                                                                                        SavepastRecordOnChange(e)
                                                                                    }
                                                                                    }
                                                                                /> */}
                                                        <label htmlFor="file-upload" className='btn btn-primary upload-btn p-1 d-flex justify-content-center align-items-center '><CloudUploadOutlined /></label>
                                                        <input
                                                            multiple
                                                            id="file-upload"
                                                            type="file"
                                                            name="file"
                                                            className={'file-upload-ctcy d-none'}
                                                            onChange={(e) => {
                                                                SavepastRecordOnChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                {/* <input type="file" name="" id="" /> */}

                                                <div id='service-table-action' className='mt-3' >
                                                    {fileupload && fileupload.length > 0 ?
                                                        fileupload.map((item, i) => {

                                                            return (

                                                                <div className='border-bottom' style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px gray solid', marginTop: '2%' }}>
                                                                    <p>{item.file_name} </p>
                                                                    <div className='d-flex'>
                                                                        <button style={{ marginRight: '10px', marginBottom: '8px' }} onClick={(e) => { download(item.file_name) }} className="ms-3 bg-transparent text-black full-mode fullscreenradius" >
                                                                            <DownloadOutlined />
                                                                        </button>
                                                                        <button style={{ marginBottom: '7px' }} onClick={(e) => { Remove(item.file_name) }} className="ms-3 bg-transparent text-black full-mode fullscreenradius" >
                                                                            <CloseOutlined />
                                                                        </button>
                                                                    </div>

                                                                </div>)
                                                        }) :
                                                        <p style={{ marginTop: '13%', marginLeft: '40%', fontWeight: 'bold' }}>No files chosen</p>
                                                    }
                                                </div>
                                            </Card>

                                        </div>
                                        <div className="col-md-6">
                                            <Card className='bg-light' style={{ height: '295px', borderRadius: '20px' }}>

                                                <Button type="primary" style={{ padding: '20px 15px', borderRadius: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                                    onClick={(e) => {
                                                        if (labelLists && labelLists.label_name && labelLists.label_name.length > 0) {
                                                            setLabelModal(true)
                                                        }
                                                        else {
                                                            Toaster.warning("No Label")
                                                        }
                                                        for (let i in removelabel) {
                                                            document.getElementById(removelabel[i]).checked = false
                                                        }
                                                    }}
                                                    icon={<PlusOutlined />} size='default'>
                                                    Insert Label
                                                </Button>
                                                <div id='service-table-action' className='mt-3' style={{ overflowY: labelSubmit && labelSubmit.length > 3 ? 'scroll' : 'hidden' }}>
                                                    {approveLabel && approveLabel.length > 0 ?
                                                        // <div className="row-md-6 mt-3" style={{ border: '1px solid lightgrey', padding: '2%', height: '120px', width: '80%', overflowY: labelSubmit.length > 2 ? "scroll" : "hidden" }}>
                                                        <table >
                                                            <tbody>
                                                                {approveLabel && approveLabel.length > 0 && approveLabel.map((item, i) => {
                                                                    let k = approveLabel[i]
                                                                    let temp = []
                                                                    for (let j in approveLabel[i]) {
                                                                        return (
                                                                            <tr className='border-bottom'>
                                                                                <td style={{ width: '205px' }}>
                                                                                    <label htmlFor="">{j}</label>
                                                                                </td>
                                                                                <td>
                                                                                    <Input type="text" autocomplete="off" autoFocus={true} id={j} className='form-control' name="emp_name" defaultValue={updatevalue[i]} key={updatevalue[i]}
                                                                                        // onChange={(e) => {
                                                                                        //     let temp = []
                                                                                        //     console.log(e.target.value, 'e.target.value');
                                                                                        //     //  approveLabel[i][j] = e.target.value
                                                                                        //     updatevalue[i] = e.target.value
                                                                                        //     setupdatevalue(updatevalue)
                                                                                        //     console.log(temp, 'temp');
                                                                                        // }} 

                                                                                        onChange={(e) => {
                                                                                            console.log(approveLabel[i], 'approveLabel[i]');
                                                                                            let array = approveLabel
                                                                                            // let temp = []
                                                                                            for (let k in array) {
                                                                                                for (let l in array[k]) {
                                                                                                    console.log(l, 'llll')
                                                                                                    console.log(e.target.id, 'iddddddddd');;

                                                                                                    if (l == e.target.id) {

                                                                                                        temp.push({

                                                                                                            [l]: e.target.value,



                                                                                                        })

                                                                                                    }
                                                                                                    else {

                                                                                                        temp.push(array[k])

                                                                                                    }

                                                                                                }
                                                                                            }
                                                                                            console.log(temp, 'temp7777');
                                                                                            setApproveLabel(temp)
                                                                                            // setEmailvalue(temp)

                                                                                        }}


                                                                                        style={{ width: "80%", marginLeft: '12%' }} />

                                                                                    {Formvalidator.current.message(
                                                                                        'approveLabel',
                                                                                        approveLabel[i][j],
                                                                                        'required',
                                                                                        {
                                                                                            className: `invalid-feedback ${certificateFormSubmit ? 'show' : 'hide'}`,
                                                                                            messages: {
                                                                                                required: 'Please provide valid additional info',

                                                                                            }
                                                                                        })}
                                                                                </td>
                                                                                <td>
                                                                                    <button id={j} onClick={(e) => {
                                                                                        let array = approveLabel
                                                                                        let s
                                                                                        let temp = removelabel
                                                                                        for (let j in array) {
                                                                                            if (i == j) {
                                                                                                let l = Object.keys(array[i])
                                                                                                s = { label_description: l[0] }
                                                                                                console.log(Object.keys(array[i]), i, s, j, 'arrayarrayarray');
                                                                                                array.splice(i, 1);
                                                                                                temp.push(i)
                                                                                            }
                                                                                        }
                                                                                        setRemovelabel([...temp])
                                                                                        setApproveLabel([...array])
                                                                                        addSelectedDataValues(
                                                                                            false,
                                                                                            s
                                                                                        )
                                                                                        // let temp = labelSubmit
                                                                                        // // if (labelSubmit[i] === item) {
                                                                                        // temp.splice(i, 1)
                                                                                        // // }
                                                                                        // // console.log(temp, 'temptemptemp');
                                                                                        // setLabelSubmit([...temp])




                                                                                        // let array = approveLabel

                                                                                        // let temp = []

                                                                                        // for (let k in array) {
                                                                                        //     for (let l in array[k]) {
                                                                                        //         console.log(l, 'llll')
                                                                                        //         console.log(e.target.id, 'iddddddddd');;

                                                                                        //         if (l == e.target.id) {

                                                                                        //             array.splice(array[k], i)


                                                                                        //         }
                                                                                        //         setApproveLabel(approveLabel)
                                                                                        //     }
                                                                                        // }
                                                                                        console.log(labelSubmit, 'labelSubmit7777');
                                                                                    }} className="ms-3 bg-transparent text-black full-mode fullscreenradius" >
                                                                                        <CloseOutlined />
                                                                                    </button>

                                                                                </td>

                                                                            </tr>
                                                                        )
                                                                    }

                                                                })}

                                                            </tbody>
                                                        </table>
                                                        // </div>
                                                        :
                                                        <p style={{ marginTop: '18%', marginLeft: '45%', fontWeight: 'bold' }}>No label</p>

                                                    }
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </div> <div className='text-center'>
                                    <Button type="primary" shape="round" className='addbutton' style={{ background: "#198754", borderColor: "#00FF7F", marginTop: '1%', marginLeft: '50%' }}
                                        icon={<PlayCircleOutlined />} size='default' onClick={isEdit === false ? onSubmit : onRectifySubmit}

                                    >
                                        Submit
                                    </Button></div>
                                <div className="col-md-12">

                                </div>

                                {/* <div className="row">
                                    <div className="column" style={{
                                        flex: "50%",

                                    }}>
                                        <div className="row-md-6">
                                            <div className="row-md-2">   <label htmlFor=""><b>Vendor Code:</b></label></div>
                                            <div className="row-md-4"> <Input type="text" autocomplete="off" className='form-control' name="emp_name" value={data.vendorCode}

                                                style={{ marginTop: "5px", width: "60%" }} /></div>
                                        </div>
                                        <div className="row-md-6">
                                            <div className="row-md-2">   <label className="mt-1" htmlFor=""><b>Vendor Name:</b></label></div>
                                            <div className="row-md-4">   <Input type="text" autocomplete="off" className='form-control' name="emp_name" value={data.vendorName}

                                                style={{ marginTop: "5px", width: "60%" }} /></div>
                                        </div>

                                        <div className="row-md-6">

                                            <div className="row-md-2">                                     <label className="mt-1" htmlFor=""><b>Certificate process for month of :</b><span style={{ "color": "red" }}>*</span></label> </div>
                                            <div className="row-md-4">      <DatePicker style={{ width: "40%", marginTop: '5px' }} allowClear={false} picker="month" format={'MMMM-YYYY'}
                                                // value={vendorComplianceScore.from_month ? moment(vendorComplianceScore.from_month) : ''}
                                                onChange={(date, dateString) => {
                                                    console.log(dateString, 'dateString');
                                                    setMonth(dateString)
                                                    // setVendorComplianceScore({
                                                    //     ...vendorComplianceScore,
                                                    //     from_month: dateString,
                                                    //     to_month: moment(dateString).add(2, 'months')
                                                    // })
                                                    setDateChange(true)
                                                }}
                                                disabledDate={(current) => {
                                                    return Date.now() <= current

                                                }}
                                            />
                                                {Formvalidator.current.message(
                                                    'month',
                                                    month,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${certificateFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Certificate month Required',

                                                        }
                                                    })}

                                                {dateChange == true ? <Tooltip title="search">
                                                    <Button type="primary" shape="circle" icon={<SearchOutlined />} style={{ marginLeft: '2%' }} onClick={onSearch} />
                                                </Tooltip> : false}
                                            </div>

                                        </div>



                                        <label className="mt-3" htmlFor="" ><b>Invoice:</b></label>
                                        <input style={{ marginLeft: '2%', marginTop: '3%' }} type="radio" id="html" name="fav_language" value="Yes" onChange={() => {
                                            setInvoice(true)
                                        }}></input><label style={{ marginLeft: '2%' }} >Yes</label>
                                        <input style={{ marginLeft: '2%' }} checked={invoice === false ? true : false} type="radio" id="html" name="fav_language" value="No" onChange={() => {
                                            setInvoice(false)
                                        }}></input><label style={{ marginLeft: '2%' }} >No</label>
                                        {invoice == true ?
                                            <>
                                                <div className="row-md-6 mt-2">
                                                    <div className="row-md-2">   <label className="mt-1" htmlFor=""><b>Invoice Number:</b><span style={{ "color": "red" }}>*</span></label></div>
                                                    <div className="row-md-4">   <Input type="text" autocomplete="off" className='form-control' value={txt} name="emp_name" onChange={onInputChange}

                                                        style={{ marginTop: "5px", width: "60%" }} />
                                                        {Formvalidator.current.message(
                                                            'invoice',
                                                            invoiceData.invoice_no,
                                                            [
                                                                // 'required',
                                                                // {
                                                                //     regex: /^[a-zA-Z0-9]*$/,
                                                                // },
                                                                `max:20`

                                                            ],
                                                            {
                                                                className: `invalid-feedback ${certificateFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Invoice Number Required',
                                                                    // regex: 'Enter Valid Invoice Number',
                                                                    max: 'Maximum character is 20 only'

                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                                <div className="row-md-6">
                                                    <div className="row-md-2">                                               <label className="mt-1" htmlFor=""><b>Invoice Date:</b><span style={{ "color": "red" }}>*</span></label> </div>
                                                    <div className="row-md-4">      <DatePicker style={{ width: "60%", marginTop: '5px' }} allowClear={false} picker="date"
                                                        // value={vendorComplianceScore.from_month ? moment(vendorComplianceScore.from_month) : ''}
                                                        onChange={(date, dateString) => {
                                                            // setVendorComplianceScore({
                                                            //     ...vendorComplianceScore,
                                                            //     from_month: dateString,
                                                            //     to_month: moment(dateString).add(2, 'months')
                                                            // })

                                                            setInvoiceData({
                                                                ...invoiceData,
                                                                invoice_date: dateString
                                                            })
                                                        }}
                                                        disabledDate={(current) => {
                                                            return Date.now() <= current

                                                        }}
                                                    />
                                                        {Formvalidator.current.message(
                                                            'invoice Date',
                                                            invoiceData.invoice_date,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${certificateFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Invoice Date Required',

                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                            </> : false}
                                    </div>
                                    <div className="column" style={{
                                        flex: "50%",
                                    }}>
                                        <Button type="primary" style={{ background: "green" }}
                                            onClick={(e) => {
                                                if (labelLists && labelLists.label_name && labelLists.label_name.length > 0) {
                                                    setLabelModal(true)
                                                }
                                                else {
                                                    Toaster.warning("No Label")
                                                }
                                            }}
                                            icon={<PlusOutlined />} size='default'>
                                            Insert Label
                                        </Button>
                                        {/* {labelSubmit && labelSubmit.length > 0 ?
                                            <div className="row-md-6 mt-3" style={{ height: '120px', width: '300px', overflowY: labelSubmit.length > 2 ? "scroll" : "hidden" }}>
                                                <table >
                                                    <tbody>
                                                        {labelSubmit && labelSubmit.length > 0 && labelSubmit.map((item, i) => {
                                                            let k = labelSubmit[i]
                                                            return (
                                                                <tr>
                                                                    <td>
                                                                        <label htmlFor="">{item}</label>
                                                                    </td>
                                                                    <td>
                                                                        <Input type="text" autocomplete="off" className='form-control' name="emp_name"

                                                                            onChange={(e) => {
                                                                                let temp
                                                                                let temp2 = []

                                                                                temp = {
                                                                                    [item]: e.target.value,

                                                                                }

                                                                                setLabelList({ ...labelList, ...temp })
                                                                                console.log(temp, 'tempppppppp');
                                                                            }} style={{ width: "80%", marginLeft: '12%' }} />
                                                                        {Formvalidator.current.message(
                                                                            'labelList',
                                                                            labelList[i],
                                                                            'required',
                                                                            {
                                                                                className: `invalid-feedback ${certificateFormSubmit ? 'show' : 'hide'}`,
                                                                                messages: {
                                                                                    required: 'Please provide valid additional info',

                                                                                }
                                                                            })}

                                                                    </td>

                                                                    <td>

                                                                        <Button style={{ marginLeft: '2%' }} icon={<CloseOutlined />} onClick={() => {
                                                                            let temp = labelSubmit
                                                                            // if (labelSubmit[i] === item) {
                                                                            temp.splice(i, 1)
                                                                            // }
                                                                            // console.log(temp, 'temptemptemp');
                                                                            setLabelSubmit([...temp])
                                                                            console.log(labelSubmit, 'labelSubmit7777');
                                                                        }}></Button>




                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}

                                                    </tbody>
                                                </table>
                                            </div>
                                            : false} */}
                                {/* {approveLabel && approveLabel.length > 0 ?
                                            <div className="row-md-6 mt-3" style={{ border: '1px solid lightgrey', padding: '2%', height: '120px', width: '80%', overflowY: labelSubmit.length > 2 ? "scroll" : "hidden" }}>
                                                <table >
                                                    <tbody>
                                                        {approveLabel && approveLabel.length > 0 && approveLabel.map((item, i) => {
                                                            let k = approveLabel[i]
                                                            let temp = []
                                                            for (let j in approveLabel[i]) {
                                                                return (
                                                                    <tr>
                                                                        <td>


                                                                            <label htmlFor="">{j}</label>
                                                                        </td>
                                                                        <td>


                                                                            <Input type="text" autocomplete="off" autoFocus={true} id={j} className='form-control' name="emp_name" defaultValue={updatevalue[i]} key={updatevalue[i]}
                                                                                // onChange={(e) => {
                                                                                //     let temp = []
                                                                                //     console.log(e.target.value, 'e.target.value');
                                                                                //     //  approveLabel[i][j] = e.target.value
                                                                                //     updatevalue[i] = e.target.value
                                                                                //     setupdatevalue(updatevalue)
                                                                                //     console.log(temp, 'temp');
                                                                                // }} 

                                                                                onChange={(e) => {
                                                                                    console.log(approveLabel[i], 'approveLabel[i]');
                                                                                    let array = approveLabel
                                                                                    // let temp = []
                                                                                    for (let k in array) {
                                                                                        for (let l in array[k]) {
                                                                                            console.log(l, 'llll')
                                                                                            console.log(e.target.id, 'iddddddddd');;

                                                                                            if (l == e.target.id) {

                                                                                                temp.push({

                                                                                                    [l]: e.target.value,



                                                                                                })

                                                                                            }
                                                                                            else {

                                                                                                temp.push(array[k])

                                                                                            }

                                                                                        }
                                                                                    }
                                                                                    console.log(temp, 'temp7777');
                                                                                    setApproveLabel(temp)
                                                                                    // setEmailvalue(temp)

                                                                                }}


                                                                                style={{ width: "80%", marginLeft: '12%' }} />

                                                                            {Formvalidator.current.message(
                                                                                'approveLabel',
                                                                                approveLabel[i][j],
                                                                                'required',
                                                                                {
                                                                                    className: `invalid-feedback ${certificateFormSubmit ? 'show' : 'hide'}`,
                                                                                    messages: {
                                                                                        required: 'Please provide valid additional info',

                                                                                    }
                                                                                })}
                                                                        </td>

                                                                        <td>

                                                                            <Button style={{ marginLeft: '2%', border: 'none', padding: '10px', color: 'red' }} id={j} icon={<CloseOutlined />} onClick={(e) => {
                                                                                let array = approveLabel
                                                                                for (let j in array) {
                                                                                    if (i == j) {
                                                                                        array.splice(i, 1);
                                                                                    }
                                                                                }
                                                                                setApproveLabel([...array])
                                                                                // let temp = labelSubmit
                                                                                // // if (labelSubmit[i] === item) {
                                                                                // temp.splice(i, 1)
                                                                                // // }
                                                                                // // console.log(temp, 'temptemptemp');
                                                                                // setLabelSubmit([...temp])




                                                                                // let array = approveLabel

                                                                                // let temp = []

                                                                                // for (let k in array) {
                                                                                //     for (let l in array[k]) {
                                                                                //         console.log(l, 'llll')
                                                                                //         console.log(e.target.id, 'iddddddddd');;

                                                                                //         if (l == e.target.id) {

                                                                                //             array.splice(array[k], i)


                                                                                //         }
                                                                                //         setApproveLabel(approveLabel)
                                                                                //     }
                                                                                // }
                                                                                console.log(labelSubmit, 'labelSubmit7777');
                                                                            }}></Button>




                                                                        </td>

                                                                    </tr>
                                                                )
                                                            }

                                                        })}

                                                    </tbody>
                                                </table>
                                            </div>
                                            : false} */}
                                {/* <div className="col-md-6 mt-5">
                                            <label htmlFor=""><b>Upload Documents:</b></label>
                                        </div>
                                        <div className="col-md-6" style={{ marginTop: '2%' }}>
                                            <input type="file" multiple name="file"
                                                onChange={(e) => {
                                                    SavepastRecordOnChange(e)
                                                }}
                                                id="" />
                                            <p>(Maximum 25MB per file/task)</p>

                                        </div>
                                        <label htmlFor=""><b>Attached Documents:</b></label><br /> */}
                                {/* {fileupload && fileupload.length > 0 && fileupload.map((item, i) => {

                                            return (
                                                <div style={{ display: 'flex', width: '400px' }}>
                                                    <p style={{ width: '50%' }}>{item.file_name} </p>
                                                    <div style={{ width: '25%' }}>
                                                        <Button type="primary" class='fa fa-download text-primary c-pointer'
                                                            icon={<DownloadOutlined />} size='small'
                                                            onClick={(e) => { download(item.file_name) }}
                                                        >
                                                        </Button> </div>
                                                    <div style={{ width: '25%' }}>
                                                        <Button type="primary" class='fa fa-times text-primary removeicon'
                                                            icon={< CloseOutlined />} size='small'
                                                            onClick={(e) => { Remove(item.file_name) }}
                                                        >
                                                        </Button></div>
                                                </div>)
                                        })}

                                    </div>
                                    <div className='text-center'>
                                        <Button type="primary" shape="round" className='addbutton' style={{ background: "#198754", borderColor: "#00FF7F", marginTop: '1%' }}
                                            icon={<PlayCircleOutlined />} size='default' onClick={onSubmit}

                                        >
                                            Submit
                                        </Button></div>
                                </div> */}

                            </div>



                            {/* </div>
                            </div> */}
                        </div>

                    </div>}
            </div>

            <Modal title='Compliance Status' className={"remark-header modelradius add-service-prv labelModal  " + localStorage.getItem('currentTheme')} onCancel={handleCancel} open={complianceStatusModal} footer={null} width={350} maskClosable={false}>
                <Table
                    size={'small'}
                    className={localStorage.getItem("currentTheme")}
                    columns={columns1}
                    dataSource={complianceStatusModals.compliancestatus_certifications}
                    bordered
                    // fixedHeader={dataTableProperties.fixedHeader}
                    // scroll={{ x: 1500, y: 1000 }}
                    pagination={false} />


            </Modal>
            <Modal title='Additional Input' className={"remark-header modelradius add-service-prv labelModal  " + localStorage.getItem('currentTheme')} onCancel={handleCancel} open={detailsModal} footer={null} width={350} maskClosable={false}>
                <div className="col-md-12">
                    <div className="row">
                        {labelArray && labelArray.length > 0 ?
                            <div style={{ marginLeft: '0%', marginBottom: '3%' }}>
                                <Card className='bg-light' id='service-table-action' style={{ overflowY: labelArray && labelArray.length > 2 ? 'scroll' : 'hidden', height: '130px', borderRadius: '20px' }}>
                                    <div className='mt-3'>
                                        <table>
                                            <tbody>

                                                {labelArray ?
                                                    labelArray && labelArray.map((item, i) => {
                                                        return (
                                                            <tr className='border-bottom'>
                                                                <td style={{ width: '520px' }}>
                                                                    <label htmlFor="">{item.label}</label>
                                                                </td>
                                                                <td>
                                                                    <TextArea id="remarks" cols="40" rows="1" type="text" autocomplete="off" autoFocus={true} className='form-control' name="emp_name" defaultValue={item.input} key={item.input} readOnly={true}
                                                                        // onChange={(e) => {
                                                                        //     let temp = []
                                                                        //     console.log(e.target.value, 'e.target.value');
                                                                        //     //  approveLabel[i][j] = e.target.value
                                                                        //     updatevalue[i] = e.target.value
                                                                        //     setupdatevalue(updatevalue)
                                                                        //     console.log(temp, 'temp');
                                                                        // }} 
                                                                        style={{ width: "80%", marginLeft: '-35%' }} />
                                                                </td>
                                                            </tr>
                                                        )
                                                    }) : ''}

                                            </tbody>
                                        </table>
                                    </div>
                                </Card>
                            </div>
                            : ''
                        }

                        <div className="col-md-2 mt-2">
                            <label>Vendor Code:</label>
                        </div>
                        <div className="col-md-4">  <input type='text' className='form-control' value={vendorDetails.code} ></input></div>
                        <div className="col-md-2">
                            <label>Certification process for the month of:</label>
                        </div>
                        <div className="col-md-4">  <input type='text' value={`${vendorDetails.month}-${vendorDetails.year}`} className='form-control' ></input></div>

                    </div>
                </div>
                <div className="col-md-12 mt-3">
                    <div className="row">
                        <div className="col-md-2">
                            <label>Vendor Name:</label>
                        </div>
                        <div className="col-md-4">  <input type='text' value={vendorDetails.name} className='form-control' ></input></div>
                        <div className="col-md-2">
                            <label>Attached Documents:</label>

                        </div>
                        <div className="col-md-4">  {vendorDetails.documents && vendorDetails.documents.length > 0 && vendorDetails.documents.map((item, i) => {

                            return <p>{item}    <Button type="primary" class='fa fa-download text-primary c-pointer'
                                icon={<DownloadOutlined />} size='small'
                                onClick={(e) => { download(item) }}
                                style={{ marginTop: '2%' }}  >
                            </Button> &nbsp;
                            </p>
                        })}</div>

                    </div>
                </div>
            </Modal>
            {/* <div className={localStorage.getItem('currentTheme')} > */}
            <Modal title='Select Additional Title' className={"remark-header modelradius add-service-prv labelModal  " + localStorage.getItem('currentTheme')} onCancel={handleCancel} open={labelModal} footer={null} width={350} maskClosable={false}>
                <Table
                    size={'small'}
                    className={localStorage.getItem("currentTheme")}
                    columns={labelcolumns}
                    dataSource={labelLists && labelLists.label_name
                    }
                    bordered
                    // fixedHeader={dataTableProperties.fixedHeader}
                    // scroll={{ x: 1500, y: 1000 }}
                    pagination={false}
                    onHeaderRow={(columns, index) => {

                        return {
                            onClick: (e) => {

                                let checked = e.target.checked;
                                addAllSelectedDataValues(
                                    checked,
                                );
                            }


                        };
                    }}

                />
                <div className={"form-actions text-center popupbtncolour " + localStorage.getItem('currentTheme')}>
                    <Button type="primary" shape="round" className={'addbutton mt-1 ' + localStorage.getItem('currentTheme')}
                        icon={<PlayCircleOutlined />} size='default'
                        onClick={() => {
                            setLabelSubmit(labelCheckBox)
                            setLabelModal(false)
                            if (labelCheckBox.length > 0) {
                                let temp = []
                                let temp2 = []
                                // temp = approveLabel
                                console.log(approveLabel, 'approveLabel');
                                for (let j in approveLabel) {
                                    console.log(approveLabel[j], 'approveLabel[j]');
                                    for (let l in approveLabel[j]) {
                                        temp2.push(l)
                                    }
                                }
                                labelCheckBox && labelCheckBox.map((item, i) => {
                                    if (approveLabel.length > 0) {
                                        if (temp2.includes(item)) {
                                            for (let j in approveLabel) {
                                                console.log(approveLabel[j], 'approveLabel[j]');
                                                for (let l in approveLabel[j]) {
                                                    console.log(l, '689144444444');
                                                    if (item == l) {
                                                        if (Object.values(approveLabel[j]) == '') {
                                                            console.log('testttttttttttttttt');
                                                            approveLabel.splice(l, 1)
                                                            temp.push({
                                                                [item]: ''
                                                            })
                                                        }
                                                        else {
                                                            temp2.push(l)
                                                            temp.push({
                                                                [item]: Object.values(approveLabel[j])
                                                            })
                                                        }
                                                    }

                                                }
                                            }
                                        }
                                        else {

                                            temp.push({
                                                [item]: ''
                                            })


                                        }
                                    }
                                    else {
                                        temp.push({
                                            [item]: ''
                                        })
                                    }
                                })

                                // if (approveLabel.length > 0) {
                                //     for (let j in approveLabel) {
                                //         console.log(approveLabel[j], 'approveLabel[j]');
                                //         for (let l in approveLabel[j]) {
                                //             console.log(l, 'kkkkkkkkkkkkk');
                                //             labelCheckBox && labelCheckBox.map((item, i) => {
                                //                 console.log(Object.values(approveLabel[j]), 'approveLabel[j]');
                                //                 if (item == l) {
                                //                     if (Object.values(approveLabel[j]) == '') {
                                //                         approveLabel.splice(l, 1)
                                //                         temp.push({
                                //                             [item]: ''
                                //                         })
                                //                     }
                                //                     else {
                                //                         temp2.push(l)
                                //                         temp.push({
                                //                             [item]: Object.values(approveLabel[j])
                                //                         })
                                //                     }
                                //                 }
                                //             })
                                //         }
                                //     }
                                // }
                                // else {
                                //     labelCheckBox && labelCheckBox.map((item, i) => {


                                //         temp.push({
                                //             [item]: ''
                                //         })


                                //     })
                                // }



                                setApproveLabel([...temp])
                            }
                            else {
                                setApproveLabel([])
                            }
                        }
                        }
                    // onClick={userDetailsOnSubmit
                    // }
                    >
                        Submit
                    </Button>
                </div>
                {/* <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')}
                            style={{ marginLeft: '-4%' }}
                            icon={<PlayCircleOutlined />} size='default'
                            onClick={blockservice}
                        >
                            Submit
                        </Button> */}



            </Modal>
            {/* </div> */}
            <Modal title='Compliance Status' className={"remark-header modelradius add-service-prv labelModal  " + localStorage.getItem('currentTheme')} onCancel={handleCancel} open={searchModal} footer={null} width={350} maskClosable={false}>
                <Table
                    // className='userprivclass'
                    size={'small'}
                    columns={searchcolumns}
                    dataSource={searchDatas && searchDatas.cmplist}

                    // fixedHeader={dataTableProperties.fixedHeader}
                    // scroll={{ x: 1500, y: 1000 }}
                    pagination={false} />


            </Modal>
        </Fragment >

    )


}
export default connect(mapStateToProps, {
    getComplianceCertificateFilter,
    getComplianceCertificateTable,
    viewComplianceStatus,
    viewComplianceDetails,
    mappingDetails,
    getLabelList,
    UploadFile,
    Downloadfile,
    submitComplianceCertificate,
    getDocumentTotalSize,
    searchCompliance,
    updateCertificate,
    UploadFile1
})(CV_ComplianceCertificates);