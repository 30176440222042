import React, { useEffect, useState, useRef } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux';
import { Route, Switch, BrowserRouter, Link, useLocation, useHistory } from 'react-router-dom';
import { Collapse, Input, Modal, Table, List, Row, Tooltip, Card, Select, Tree, Button, Checkbox, Pagination } from 'antd';
import { PlayCircleOutlined, EyeOutlined, SearchOutlined, FullscreenOutlined, CloseCircleOutlined } from '@ant-design/icons';
import SimpleReactValidator from "simple-react-validator";
import DataTable from "react-data-table-component";
import Swal from 'sweetalert2';
import { getViewOnlyRestrictedList, getViewOnlyRestUnitData, saverecords } from '../../Store/Action/Master/ViewOnlyRestricted';
import { filter, indexOf, merge } from 'lodash';
import { Toaster } from '../../Libs/Toaster';
import { fullscreenmode, fullscreenexit, handleKeyDown } from "./../../Libs/fullscreenmode";
import { HomeRedirect } from './../../Libs/country'
const homepage = HomeRedirect()
const _ = require("lodash");


const mapStateToProps = (state) => ({
    viewOnlyRestList: state.ViewOnlyRestictedList.ViewOnlyRestrictedList.viewOnlyRestList,
    viewOnlyRestUnitList: state.ViewOnlyRestictedList.ViewOnlyRestrictedList.viewOnlyRestUnitList,
    viewOnlyRestCompDetails: state.ViewOnlyRestictedList.ViewOnlyRestrictedList.viewOnlyRestCompDetails,
    access_info: state.ViewOnlyRestictedList.ViewOnlyRestrictedList.access_info
})

const ViewOnlyRestricted = ({
    viewOnlyRestList,
    getViewOnlyRestrictedList,
    viewOnlyRestUnitList,
    getViewOnlyRestUnitData,
    viewOnlyRestCompDetails,
    saverecords,
    access_info
}) => {
    const historynew = useHistory();
    console.log(viewOnlyRestCompDetails, 'viewOnlyRestCompDetails')
    console.log(access_info, 'access_info')
    const { Panel } = Collapse;
    const { Option } = Select;
    const { Search } = Input;
    const location = useLocation();
    const is_Fullscreen = useSelector((state) => state.Login.is_fullscreen);
    const dispatch = useDispatch();
    const [show, setShow] = useState(false)
    const [current, setCurrent] = useState(1);
    const [pageState, setpageState] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    let [index, setIndex] = useState(0);

    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const paramid = location.pathname;
    // const entityid = localStorage.getItem('SelectedEntityid');
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const sessionArr = sessionParsedValue.entity_info
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const validator = useRef(new SimpleReactValidator());
    const [complianceTask, setComplianceTask] = useState([])
    const [branchName, setBranchName] = useState([])
    const [uniqueValue, setUniqueValue] = useState([])
    const [localFilter, setLocalFilter] = useState(0)
    const [actionFilter, setActionFilter] = useState(false)
    const [output, setOutput] = useState()
    const [userId, setUserId] = useState('')
    const [legalentity, setLegalentity] = useState('')
    console.log(index, 'indexindex')
    const [value, setValue] = useState()
    const [temp, setTemp] = useState({
        tempData: []
    });
    const [unitList, setUnitList] = useState()

    console.log(unitList, "unitList123");

    const [unitKey, setunitKey] = useState([])
    console.log(unitKey, "unitkeyyyy");
    const [state, setState] = useState([])
    const [data, setData] = useState([]);
    const [tempState, setTempState] = useState([])
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [checkedKeys, setCheckedKeys] = useState([]);
    const [complianceIds, setComplianceIds] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [autoExpandParent, setAutoExpandParent] = useState(true);
    const [existingChecked, setExistingChecked] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [existingUnits, setExistingUnits] = useState([]);
    const [unitAccess, setUnitAccess] = useState(false)
    const [all, setAll] = useState([]);
    const [selectcheckbox, Setselectcheckbox] = useState('block');
    const [unselectcheckbox, Setunselectcheckbox] = useState('none');
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const [viewOnlyFilter, setViewOnlyFilter] = useState([]);
    const [fullscreen, setfullscreen] = useState(false);
    console.log(data, 'datadata');
    useEffect(() => {

        if (localStorage.getItem('SelectedEntityid') != null) {
            setCurrentEntity(localStorage.getItem('SelectedEntityid'))
            setLegalentity(localStorage.getItem('SelectedEntityid'))
        }
    }, [localStorage.getItem('SelectedEntityid')])
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true
    })

    const [filtervalue, setfiltervalue] = useState({
        Compliancetask: '',
        domain: '',
        frequency: '',
        domaindata: ''

    })
    // const HandelChange = (obj, value) => {
    //     setComplianceTask(obj)
    //     let diff = Object.keys(checkedKeys).filter(x => !obj.includes(x));
    //     for (let i = 0; i < value.length; i++) {
    //         setBranchName(value && value[i].children)
    //     }
    // };

    const HandelChange = (obj) => {
        // setUnitAccess(true)
        console.log(obj, 'objobjobj');
        let allArray = [];
        if (obj.includes('all')) {
            if (unitList && unitList.length > 0) {
                let temp = []
                for (let i in unitList) {
                    temp.push((unitList[i].unit_id).toString())
                    allArray.push((unitList[i].unit_id).toString())
                }
                setAll(temp)
                setComplianceTask(obj)
            }
            Setselectcheckbox('none');
            Setunselectcheckbox('block');
        } else if (obj.includes('unselect')) {
            let temp = [];
            setComplianceTask(obj)
            setAll(temp);
            Setselectcheckbox('block');
            Setunselectcheckbox('none');
        } else {
            console.log(obj)
            if (obj.length == unitList.length) {
                Setselectcheckbox('none');
                Setunselectcheckbox('block');
            } else {
                Setselectcheckbox('block');
                Setunselectcheckbox('none');
            }
            setAll(obj)
            setComplianceTask(obj)
        }
        setfiltervalue({
            ...filtervalue,
            domain: ''
        })
        // return obj;

    };


    const onExpand = (newExpandedKeys) => {
        setExpandedKeys(newExpandedKeys);
        setAutoExpandParent(false);
    };

    useEffect(() => {
        // console.log(pageState,pageSize,current,'pageState')
        // if (pageState === true) {
        //     setIndex(pageSize * (current - 1))
        // } else {
        //     setIndex(pageSize)
        // }
        setIndex(pageSize * current)
    }, [pageState, pageSize, current])

    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetViewonlyRestList",
                        {}
                    ]
                }
            ]
            getViewOnlyRestrictedList({
                payload: payload,
                paramid: paramid
            })
        }
    }, [isAuth, show])

    useEffect(() => {

        validator.current.showMessages()
    }, [])

    useEffect(() => {
        if (userId != '') {
            // setAddFormSubmit(true)
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetViewonlyRestPrivilegeDetails",
                        {
                            "user_id": Number(userId),
                            "legal_entity_id": entityid
                        }
                    ]
                }
            ]
            if (validator.current.allValid()) {
                setShow(true)
                getViewOnlyRestUnitData({
                    payload: payload,
                    paramid: paramid
                })
            }
        }
    }, [userId])

    useEffect(() => {
    
        if (access_info && unitList ) {
            if (Object.keys(access_info[0]).length == unitList.length) {
                Setselectcheckbox('none');
                Setunselectcheckbox('block');

            } else {

                Setselectcheckbox('block');
                Setunselectcheckbox('none');
            }
        }
    }, [unitList])

    useEffect(() => {
        if (all && all.length > 0) {
            const arr = all && all
            const obj = viewOnlyRestCompDetails && viewOnlyRestCompDetails[0]
            const select = (arr, obj) => arr.reduce((r, prop) => {
                obj.hasOwnProperty(prop) && (r[prop] = obj[prop]);
                return r;
            }, {});
            const output = select(arr, obj);
            setOutput(output)

        } else {
            setOutput({})
        }
        /* Code hided for automatically update once Units changed*/
        // if (access_info && access_info.length > 0) {
        //     let tempAccessInfo = existingChecked;
        //     let finalKeyWithValue = [];
        //     let keys;
        //     let strOutput = [];
        //     keys = Object.keys(access_info[0])
        //     Object.values(access_info[0]).map((access_info_list, index) => {
        //         access_info_list.map((access_info_list_child) => {
        //             finalKeyWithValue = keys[index] + "-" + access_info_list_child
        //             tempAccessInfo.push(finalKeyWithValue)
        //         })


        //     })
        //     setExistingChecked(tempAccessInfo)
        //     setSelectedRowKeys(tempAccessInfo)
        //     setExpandedKeys(tempAccessInfo)
        //     setCheckedKeys(access_info[0])
        // }
    }, [all])

    useEffect(() => {
        // if (unitAccess == true) {
        if (access_info && access_info != null) {
            if (access_info[0] && access_info.length > 0) {
                let tempAccessInfo = existingChecked;
                let finalKeyWithValue = [];
                let keys;
                let strOutput = [];
                keys = Object.keys(access_info[0])
                Object.values(access_info[0]).map((access_info_list, index) => {
                    access_info_list.map((access_info_list_child) => {
                        finalKeyWithValue = keys[index] + "-" + access_info_list_child
                        tempAccessInfo.push(finalKeyWithValue)
                    })


                })
                setExistingChecked(tempAccessInfo)
                setSelectedRowKeys(tempAccessInfo)
                setExpandedKeys(tempAccessInfo)
                setCheckedKeys(access_info[0])

                setExistingUnits(Object.keys(access_info[0]));
                setComplianceTask(Object.keys(access_info[0]))
                setAll(Object.keys(access_info[0]))
            }
        }
        // }
    }, [access_info])


    // useEffect(() => {
    //     if (viewOnlyRestUnitList && viewOnlyRestUnitList.length > 0) {
    //         let finalizedUnits = viewOnlyRestUnitList.filter((ele, ind) => ind === viewOnlyRestUnitList.findIndex(elem => elem.unit_id === ele.unit_id))
    //         setUnitList(finalizedUnits)
    //     }
    // }, [viewOnlyRestUnitList])


    // Keys values
    useEffect(() => {
        if (viewOnlyRestCompDetails) {
            console.log(viewOnlyRestCompDetails, "viewOnlyRestCompDetails");
            let keys;
            keys = Object.keys(viewOnlyRestCompDetails[0])
            console.log(keys, "keyskeys");
            keys.forEach((items) => {
                return Number(items)
            })
            setunitKey(keys);
        }
    }, [viewOnlyRestCompDetails])



    // UnitsFilter based on key values 

    useEffect(() => {
        // viewOnlyRestCompDetails
        let unitfilter = viewOnlyRestUnitList && viewOnlyRestUnitList.length > 0 && viewOnlyRestUnitList.filter(exist => unitKey.some(exist2 => exist.unit_id == exist2));
        console.log(unitfilter,viewOnlyRestUnitList, "unitfilter");
        setUnitList(unitfilter)

    }, [unitKey])







    // useEffect(() => {
    //     let tempArrayState = []
    //     let tempArray = []
    //     let separatedArray = [];
    //     let finalArrayPush = [];
    //     if (output) {
    //         Object.entries(output).forEach(([key, value]) => {

    //             if (value && value.length > 0) {
    //                 let arr = [];

    //                 let tempSetArray = [];
    //                 const pmenus = value.map(function (user) {
    //                     if ((arr.indexOf(user.statutory) < 0))
    //                         arr.push(user.statutory);
    //                 });
    //                 let totalListNew = [];
    //                 let countrylisttempdata = [];
    //                 let tempComplianceId = []
    //                 arr.map((pmenu, index) => {
    //                     let totalList = []
    //                     let entityArray = []
    //                     let tempArray = []


    //                     if (pmenu) {
    //                         {
    //                             value && value.map((subMenuList, childIndex) => {
    //                                 if (pmenu == subMenuList.statutory) {
    //                                     entityArray = {
    //                                         'level': 3,
    //                                         'unit_id': key,
    //                                         'comp_id': subMenuList.compliance_id,
    //                                         'key': key + "-" + subMenuList.compliance_id,
    //                                         'title': subMenuList.compliance_task,
    //                                         'domain': subMenuList.domain_id,
    //                                         'frequency': subMenuList.frequency_id
    //                                     }
    //                                     tempArray.push(entityArray)
    //                                     tempComplianceId.push(subMenuList.compliance_id)
    //                                 }

    //                             })
    //                         }
    //                     }
    //                     totalList = {
    //                         'level': 2,
    //                         'unit_id': key,
    //                         'title': pmenu,
    //                         'key': key + "_" + index,
    //                         'children': tempArray,
    //                         'childComplianceId': tempComplianceId,
    //                     }
    //                     countrylisttempdata.push(totalList)

    //                 })
    //                 const unit_name = viewOnlyRestUnitList.find(element => {
    //                     return element.unit_id == parseInt(key);
    //                 })
    //                 totalListNew = {
    //                     'level': 1,
    //                     'title': unit_name.unitname,
    //                     'key': key,
    //                     'children': countrylisttempdata,
    //                     'childComplianceId': tempComplianceId
    //                 }
    //                 finalArrayPush.push(totalListNew)
    //             }


    //         })
    //         setData(finalArrayPush)
    //     }

    // }, [output])


    const onCheck = (checkedKeysValue) => {
        let myArray = [];
        let unit_id = '';
        let yArray = {};
        let x = [];
        let k = {}
        //let splitStr = checkedKeysValue.split("-")
        setExistingChecked(checkedKeysValue)
        let compliance_id = [];
        for (let i = 0; i < checkedKeysValue.length; i++) {

            let str = checkedKeysValue[i];

            if (str.indexOf('_') == -1) {
                if (str.indexOf('-') != -1) {
                    let arr1 = str.split('-')
                    unit_id = arr1[0]
                    if (!yArray[unit_id])
                        yArray[unit_id] = [];

                    if (yArray[unit_id].indexOf(arr1[1]) < 0)
                        yArray[unit_id].push(arr1[1]) //compliance_id.push(arr1[1])                   
                    x.push(arr1[1])
                } else {
                    //let arr1 = str.split('');
                    unit_id = str;
                    if (!yArray[unit_id])
                        yArray[unit_id] = [];
                }
            } else {
                let arr1 = str.split('_');
                unit_id = arr1[0];
                if (!yArray[unit_id])
                    yArray[unit_id] = [];

            }

        }

        myArray = yArray;
        setCheckedKeys(myArray);
    };

    const saveRecords = (e) => {
        e.preventDefault();
        let temp = checkedKeys
        let key = Object.keys(temp)
        let val = Object.values(temp)
        let k = {}
        for (let i in all) {
            for (let j in val) {
                if (all[i] == key[j]) {
                    k[all[i]] = val[i]
                }
            }
        }

        const savepayload = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "SubmitViewonlyRestUserPrivileges",
                    {
                        "user_id": parseInt(userId),
                        "legal_entity_id": entityid,
                        "unit_compliances": k
                    }
                ]
            }
        ]
        const payloadbck = [

            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "GetViewonlyRestPrivilegeDetails",
                    {
                        "user_id": Number(userId),
                        "legal_entity_id": entityid
                    }
                ]
            }

        ]
        console.log(k, all, 'savePayloads')
        if (all && all.length > 0) {
            if (Object.keys(k).length > 0 && Object.keys(k).length == all.length) {
                //get Diff from two array and remove it from final array            
                // const finalArray = Object.keys(checkedKeys).filter(x => !complianceTask.includes(x));
                // if (finalArray) {
                //     finalArray.forEach(e => delete checkedKeys[e]);
                // }

                saverecords({
                    payload: savepayload,
                    paramid: paramid,
                    payloadbck: payloadbck
                })
            } else {
                if (localStorage.getItem('isVendor') == 'true') {
                    Toaster.error('Please Select any Compliance Task for selected vendor')
                } else {
                    Toaster.error('Please Select any Compliance Task for selected units')
                }
            }
        } else {
            if (localStorage.getItem('isVendor') == 'true') {
                Toaster.warning('Please Select any Vendors')
            } else {
                Toaster.warning('Please Select any Units')
            }
        }
    }
    const [uni_domain, setuni_domain] = useState();
    useEffect(() => {
        if (viewOnlyRestUnitList && viewOnlyRestUnitList.length > 0) {
            let newArray = getUnique(viewOnlyRestUnitList, 'domain_name');
            setuni_domain(newArray);
        }
    }, [viewOnlyRestUnitList])

    // const frequencyfilter = () => {
    //     console.log(filtervalue, 'filtervaluefiltervalue')
    //     let finalArrayPush = [];
    //     Object.entries(output).forEach(([key, value]) => {
    //         if (filtervalue.Compliancetask == '' && filtervalue.domain == '' && filtervalue.frequency == '') {

    //             if (value && value.length > 0) {
    //                 let arr = [];

    //                 let tempSetArray = [];
    //                 const pmenus = value.map(function (user) {
    //                     if ((arr.indexOf(user.statutory) < 0))
    //                         arr.push(user.statutory);
    //                 });
    //                 let totalListNew = [];
    //                 let countrylisttempdata = [];
    //                 let tempComplianceId = []
    //                 arr.map((pmenu, index) => {
    //                     let totalList = []
    //                     let entityArray = []
    //                     let tempArray = []


    //                     if (pmenu) {
    //                         {
    //                             value && value.map((subMenuList, childIndex) => {
    //                                 if (pmenu == subMenuList.statutory) {
    //                                     entityArray = {
    //                                         'level': 3,
    //                                         'unit_id': key,
    //                                         'comp_id': subMenuList.compliance_id,
    //                                         'key': key + "-" + subMenuList.compliance_id,
    //                                         'title': subMenuList.compliance_task,
    //                                         'domain': subMenuList.domain_id,
    //                                         'frequency': subMenuList.frequency_id
    //                                     }
    //                                     tempArray.push(entityArray)
    //                                     tempComplianceId.push(subMenuList.compliance_id)
    //                                 }

    //                             })
    //                         }
    //                     }
    //                     totalList = {
    //                         'level': 2,
    //                         'unit_id': key,
    //                         'title': pmenu,
    //                         'key': key + "_" + index,
    //                         'children': tempArray,
    //                         'childComplianceId': tempComplianceId,
    //                     }
    //                     countrylisttempdata.push(totalList)

    //                 })
    //                 const unit_name = viewOnlyRestUnitList.find(element => {
    //                     return element.unit_id == parseInt(key);
    //                 })
    //                 totalListNew = {
    //                     'level': 1,
    //                     'title': unit_name.unitname,
    //                     'key': key,
    //                     'children': countrylisttempdata,
    //                     'childComplianceId': tempComplianceId
    //                 }
    //                 finalArrayPush.push(totalListNew)
    //             }


    //             setData(finalArrayPush)
    //         } else {
    //             let obj = {}
    //             //  && filtervalue.frequency != 'all'
    //             if (filtervalue.frequency && filtervalue.frequency != 'all') {
    //                 obj.frequency_id = filtervalue.frequency
    //             }
    //             if (filtervalue.domain) {
    //                 obj.domain_id = filtervalue.domain
    //             }
    //             console.log(obj, 'objobj')
    //             let compliance_task = [];
    //             let commonElements;
    //             if (filtervalue.Compliancetask) {
    //                 const ab = value.filter(o =>
    //                     Object.keys(o).some(k =>
    //                         String(o[k])
    //                             .toLowerCase()
    //                             .includes(filtervalue.Compliancetask.toLowerCase())
    //                     )
    //                 );
    //                 console.log(ab, 'compliancetaskfilter')
    //                 compliance_task = ab.map((item => {
    //                     return item.compliance_task
    //                 }))
    //             }

    //             console.log(compliance_task, 'compliance_taskcompliance_task')

    //             const arrayvalue = _.filter(value, obj)
    //             console.log(arrayvalue, 'arrayValue')
    //             if (compliance_task && compliance_task.length > 0) {

    //                 commonElements = arrayvalue.filter((element) => {
    //                     return compliance_task.includes(element.compliance_task);
    //                 });
    //             } else if (compliance_task && compliance_task.length == 0 && filtervalue.domain != '' || (filtervalue.frequency != '' || filtervalue.frequency != 'all')) {
    //                 commonElements = arrayvalue
    //             }
    //             console.log(commonElements, 'commonElements')

    //             if (commonElements && commonElements.length > 0) {
    //                 let arr = [];

    //                 let tempSetArray = [];
    //                 const pmenus = commonElements.map(function (user) {
    //                     if ((arr.indexOf(user.statutory) < 0))
    //                         arr.push(user.statutory);
    //                 });
    //                 let totalListNew = [];
    //                 let countrylisttempdata = [];
    //                 let tempComplianceId = []
    //                 arr.map((pmenu, index) => {
    //                     let totalList = []
    //                     let entityArray = []
    //                     let tempArray = []


    //                     if (pmenu) {
    //                         {
    //                             commonElements && commonElements.map((subMenuList, childIndex) => {
    //                                 if (pmenu == subMenuList.statutory) {
    //                                     entityArray = {
    //                                         'level': 3,
    //                                         'unit_id': key,
    //                                         'comp_id': subMenuList.compliance_id,
    //                                         'key': key + "-" + subMenuList.compliance_id,
    //                                         'title': subMenuList.compliance_task,
    //                                         'domain': subMenuList.domain_id,
    //                                         'frequency': subMenuList.frequency_id
    //                                     }
    //                                     tempArray.push(entityArray)
    //                                     tempComplianceId.push(subMenuList.compliance_id)
    //                                 }

    //                             })
    //                         }
    //                     }
    //                     totalList = {
    //                         'level': 2,
    //                         'unit_id': key,
    //                         'title': pmenu,
    //                         'key': key + "_" + index,
    //                         'children': tempArray,
    //                         'childComplianceId': tempComplianceId,
    //                     }
    //                     countrylisttempdata.push(totalList)

    //                 })
    //                 const unit_name = viewOnlyRestUnitList.find(element => {
    //                     return element.unit_id == parseInt(key);
    //                 })
    //                 totalListNew = {
    //                     'level': 1,
    //                     'title': unit_name.unitname,
    //                     'key': key,
    //                     'children': countrylisttempdata,
    //                     'childComplianceId': tempComplianceId
    //                 }
    //                 finalArrayPush.push(totalListNew)
    //             }
    //             setData(finalArrayPush)

    //         }

    //     });

    // }

    const getUnique = (arr, index) => {
        const unique = arr.map(e => e[index]).map((e, i, final) => final.indexOf(e) === i && i)
            .filter(e => arr[e]).map(e => arr[e]);
        return unique;
    }

    //  "country_ids": [1], multiple id search
    useEffect(() => {
        let userArray = [];
        let tempArray = [];
        if (legalentity) {
            viewOnlyRestList && viewOnlyRestList && viewOnlyRestList.map((userlist) => {
                const checkUserList = userlist.le_ids.includes(legalentity);
                console.log(checkUserList, 'checkUserList');
                if (checkUserList) {
                    tempArray = {
                        'user_id': userlist.user_id,
                        'emp_name': userlist.emp_name,
                        'emp_code': userlist.emp_code,
                        'user_name': userlist.user_name
                    }
                    userArray.push(tempArray)
                }
            })
            setViewOnlyFilter(userArray)

        }
    }, [legalentity, viewOnlyRestList])
    console.log(existingChecked, 'existingChecked');
    // Call this once
    let getAllKeys = (tree) => {
        let result = [];
        tree.forEach((x) => {
            console.log(x.key, 'treess');

            let childKeys = [];
            if (x.children) {
                childKeys = getAllKeys(x.children);
            }

            result.push(...[x.key, ...childKeys]);
        });

        return result;
    };

    const allKeys = getAllKeys(data);
    console.log(allKeys.length, 'allkeys')

    const onChange = () => {
        if (existingChecked.length === allKeys.length) {
            setExistingChecked([]);
        } else {
            setExistingChecked(allKeys);

            let myArraynew = [];
            let unit_idnew = '';
            let yArraynew = {};
            let xnew = [];
            let k = {}
            //let splitStr = checkedKeysValue.split("-")
            let compliance_id = [];
            for (let inew = 0; inew < allKeys.length; inew++) {

                let strnew = allKeys[inew];

                if (strnew.indexOf('_') == -1) {
                    if (strnew.indexOf('-') != -1) {
                        let arr11 = strnew.split('-')
                        unit_idnew = arr11[0]
                        if (!yArraynew[unit_idnew])
                            yArraynew[unit_idnew] = [];

                        if (yArraynew[unit_idnew].indexOf(arr11[1]) < 0)
                            yArraynew[unit_idnew].push(arr11[1]) //compliance_id.push(arr1[1])                   
                        xnew.push(arr11[1])
                    } else {
                        //let arr1 = str.split('');
                        unit_idnew = strnew;
                        if (!yArraynew[unit_idnew])
                            yArraynew[unit_idnew] = [];
                    }
                } else {
                    let arr11 = strnew.split('_');
                    unit_idnew = arr11[0];
                    if (!yArraynew[unit_idnew])
                        yArraynew[unit_idnew] = [];

                }

            }

            myArraynew = yArraynew;
            setCheckedKeys(myArraynew);


        }
    };

    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
            document.addEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch), false);
            return () => {
                document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch));
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode(dispatch);
            setfullscreen(true);
        } else {
            fullscreenexit(dispatch);
            setfullscreen(false);
        }
    }

    const homescreen = () => {
        historynew.push(homepage);
        // window.location.href = homepage
        document.getElementById('homehover').style.color = "blue"
    }
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    console.log(searchedColumn, 'searchedColumn')
    const handleSearch = (e, confirm, dataIndex, setSelectedKeys) => {
        if (e) {
            setSelectedKeys(e.target.value ? [e.target.value] : [])
            confirm({ closeDropdown: false });
            // setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex)
        } else {
            setSelectedKeys([])
            confirm({ closeDropdown: false });
            // setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex)
        }
    };

    const handleReset = (clearFilters, handleSearch, confirm, dataIndex, setSelectedKeys) => {
        handleSearch('', confirm, dataIndex, setSelectedKeys)
        clearFilters();
        setSearchText('');
    };
    const clearFocus = (searchInput) => {
        setTimeout(() => searchInput.current?.select(), 100);
    }

    const getColumnSearchProps = (dataIndex, placeholder) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div className='d-flex' style={{ position: 'relative' }}>
                <Input className='form-control'
                    ref={searchInput}
                    placeholder={'Search ' + placeholder}
                    value={selectedKeys[0]}
                    autoFocus={'on'}
                    // onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onChange={(e) => handleSearch(e, confirm, dataIndex, setSelectedKeys)}
                />
                <CloseCircleOutlined className='table-filter-reset'
                    onClick={() => {
                        handleReset(clearFilters,
                            handleSearch, confirm, dataIndex, setSelectedKeys)
                        clearFocus(searchInput)
                    }
                    } />

            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    //     // color: filtered ? '#1890ff' : undefined,
                    //     fontWeight: 'bold',
                    //     // backgroundColor:'blue',
                    //     fontSize: '15px',
                    //     color: 'black'
                }}
            />
            // <FileSearchOutlined 
            // style={{
            //     // color: filtered ? '#1890ff' : undefined,
            //     fontWeight:'bold',
            //     // backgroundColor:'blue',
            //     // fontSize:'20px',
            //     color:'black'
            // }}
            // />
            // <i class="bi bi-search"></i>
        ),
        onFilter: (value, record) =>
            record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text
    });

    useEffect(() => {
        let newFinalArrayPush = [];
        if (output) {
            Object.entries(output).forEach(([key, value]) => {
                const unit_name = viewOnlyRestUnitList.find(element => {
                    return element.unit_id == parseInt(key);
                })
                console.log(unit_name, 'keyValye')
                let newTempSetArray = [];
                if (value && value.length > 0) {
                    let newArray = [];
                    let entityArray = []
                    value.map((valueList, i) => {

                        entityArray = {
                            'key': key + '-' + valueList.compliance_id,
                            'unitName': unit_name.unitname,
                            'act': valueList.statutory,
                            'domainName': valueList.domain_id,
                            'frequencyId': valueList.frequency_id,
                            'complianceTask': valueList.compliance_task
                        }
                        newTempSetArray.push(entityArray)
                    })

                }
                newFinalArrayPush.push(newTempSetArray)

            })
            setData([].concat.apply([], newFinalArrayPush))
        }

    }, [output])
    const handleSelect = (record, selected) => {
        console.log(record, selected, 'selectedRowKeysselectedRowKeys')

        if (selected) {
            setSelectedRowKeys((keys) => [...keys, record.key]);
        } else {
            setSelectedRowKeys((keys) => {
                const index = keys.indexOf(record.key);
                return [...keys.slice(0, index), ...keys.slice(index + 1)];
            });
        }

    };
    useEffect(() => {

        if (selectedRowKeys && selectedRowKeys.length > 0) {
            let myArray = [];
            let unit_id = '';
            let yArray = {};
            let x = [];
            let k = {}

            for (let i = 0; i < selectedRowKeys.length; i++) {

                let str = selectedRowKeys[i];
                if (str != undefined) {
                    if (str.indexOf('-') != -1) {
                        let arr1 = str.split('-')
                        unit_id = arr1[0]
                        if (!yArray[unit_id])
                            yArray[unit_id] = [];

                        if (yArray[unit_id].indexOf(arr1[1]) < 0)
                            yArray[unit_id].push(arr1[1]) //compliance_id.push(arr1[1])                   
                        x.push(arr1[1])
                    } else {
                        //let arr1 = str.split('');
                        unit_id = str;
                        if (!yArray[unit_id])
                            yArray[unit_id] = [];
                    }
                }

            }

            myArray = yArray;
            setCheckedKeys(myArray);
        } else {
            setCheckedKeys([])
        }
    }, [selectedRowKeys])
    const toggleSelectAll = () => {
        setSelectedRowKeys((keys) =>
            keys.length === data.length ? [] : data.map((r) => r.key)
        );
    };
    const headerCheckbox = (
        <Checkbox
            checked={selectedRowKeys.length}
            indeterminate={
                selectedRowKeys.length > 0 && selectedRowKeys.length < data.length
            }
            onChange={toggleSelectAll}
        />
    );
    const rowSelection = {
        selectedRowKeys,
        type: "checkbox",
        fixed: true,
        onSelect: handleSelect,
        columnTitle: headerCheckbox
    }

    const columns = [
        {
            title: localStorage.getItem('isVendor') == 'false' ? 'Unit Name' : 'Vendor Name',
            dataIndex: 'unitName',
            key: 'unitName',
            ...getColumnSearchProps('unitName', localStorage.getItem('isVendor') == 'false' ? 'Unit Name' : 'Vendor Name'),
            width: '15%',
            render: (text, record) => {
                return (
                    <>
                        <span>{text}</span>
                        <span hidden>{(index =
                            index + 1)}</span>
                    </>
                )
            }

        },
        {
            title: 'Act',
            dataIndex: 'act',
            key: 'act',
            ...getColumnSearchProps('act', 'Act'),
            width: '30%'

        },
        {
            title: 'Compliance Task',
            dataIndex: 'complianceTask',
            key: 'complianceTask',
            ...getColumnSearchProps('complianceTask', 'Compliance Task'),
            width: '30%'

        },
        {
            title: 'Domain',
            dataIndex: 'domainName',
            key: 'domainName',
            ...getColumnSearchProps('domainName', 'Domain Name'),
            width: '15%'
        },
        {
            title: 'Frequency',
            dataIndex: 'frequencyId',
            key: 'frequencyId',
            ...getColumnSearchProps('frequencyId', 'Frequency'),
            width: '10%'
        },
    ];

    return (
        <div className="page-wrapper" id="page-wrapper">
            <div className="page-titles pb-1 pt-2 page-title-sticky">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-10">
                            <div className="col-lg-8 col-md-6 col-12 align-self-center">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 d-flex align-items-center">
                                        <li className="breadcrumb-item">
                                            <span className='pointer' id='homehover' onClick={homescreen}><i className="ri-home-3-line fs-5"></i>
                                            </span>
                                        </li>
                                        <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                            <span style={{ fontSize: '16px' }}>Master</span>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: "large", fontWeight: 'bold' }}>
                                            <span>View Only Restricted - Privilege Settings</span>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <button onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black full-mode fullscreenradius" style={{ marginRight: '10px', marginLeft: '145px' }} >
                                <Tooltip placement="left" title={is_Fullscreen == true ? "Exit Full Screen Mode" : "Full Screen Mode"}><FullscreenOutlined /></Tooltip>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid pt-1">
                <div className="col-12">
                    <div className="card servcardround p-2 pb-2">
                        <div className="row">
                            <div className='col-md-6'>
                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                    <div className="form-group">
                                        <label htmlFor=""><b>Legal Entity</b> <span style={{ "color": "red" }}>*</span></label>
                                        <Select style={{ marginTop: "5px", width: '60%', marginLeft: '5px' }}
                                            allowClear={true}
                                            size="default"
                                            placeholder="Select Legal Entity"
                                            onChange={(data, value) => {
                                                setCurrentEntity(data)
                                                setLegalentity(data)
                                                setUserId('')
                                                setShow(false)
                                                // setUnitAccess(false)
                                                setAll([])
                                                Setselectcheckbox('block');
                                                Setunselectcheckbox('none');
                                            }}
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                            //  value={modalData.legal_entity}

                                            display="none"
                                        >
                                            {sessionArr && sessionArr.length > 0 && sessionArr.map((item, i) => {
                                                return (
                                                    <Option title="" key={item.le_id}>
                                                        {item.le_name}
                                                    </Option>
                                                );
                                            })}
                                        </Select>
                                        {validator.current.message(
                                            'legalentity',
                                            legalentity != "null" ? legalentity : '',
                                            'required',
                                            {
                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                messages: {
                                                    required: 'Legal Entity Required',
                                                }
                                            })}
                                    </div> :
                                    <div className="form-group d-flex" style={{ marginTop: "5px" }} >
                                        <label htmlFor=""><b>Legal Entity : </b> <span style={{ "color": "red" }}>*</span></label>
                                        <p style={{ marginLeft: "5px" }} className='text-wrap-report'>{localStorage.getItem('SelectedEntity')}</p>
                                    </div>

                                }
                            </div>
                            <div className='col-md-6 text-right'>
                                <div>
                                    <label htmlFor=""><b>User</b> <span style={{ "color": "red" }}>*</span></label>
                                    <Select style={{ marginTop: "5px", width: '60%', marginLeft: '5px', textAlign: 'left' }}
                                        labelInValue={false}
                                        allowClear={true}
                                        onFocus={false}
                                        onSelect={false}
                                        disabled={entityid == null || entityid == 'null' ? true : false}
                                        placeholder="Select View Only Restricted"
                                        onChange={(data, value) => {
                                            setUserId(data)
                                            setShow(false)
                                            setAll([])
                                            setUnitAccess(false)
                                            Setselectcheckbox('block');
                                            Setunselectcheckbox('none');
                                        }}
                                        showSearch
                                        value={userId || undefined}
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    >
                                        {viewOnlyFilter && viewOnlyFilter.length > 0 && viewOnlyFilter.map((item) => {
                                            return (
                                                <Option title="" key={item.user_id}>
                                                    {`${item.emp_code}-${item.emp_name}`}
                                                </Option>
                                            );
                                        })}

                                    </Select>
                                    {validator.current.message(
                                        'userId',
                                        userId,
                                        ['required'],
                                        {
                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                            messages: {
                                                required: 'User Required',
                                                regex: 'Select view only restricted'
                                            }
                                        })}
                                </div>
                            </div>
                        </div>

                        {show == true ?
                            <>
                                <div className="col-md-12" style={{ marginTop: "10px" }}>
                                    {/* <div className="col-md-6"> */}
                                    {localStorage.getItem('isVendor') == 'true' ?
                                        <div className={"d-flex " + localStorage.getItem('currentTheme')} id='only-restricted'>
                                            <label className='d-flex me-2' htmlFor=""><b>Vendors</b> <span style={{ "color": "red" }}>*</span></label>
                                            <Select
                                                name="units"
                                                id="units"
                                                className='form-control'
                                                placeholder="Select Vendors"
                                                onChange={(option) => HandelChange(option)}
                                                mode="multiple"
                                                value={unitList && unitList.length > 0 ? all : undefined}
                                                maxTagCount='responsive'
                                            // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                            >
                                                {unitList && unitList.length > 1 ?
                                                    <>
                                                        <Option key="all" value="all" style={{ display: selectcheckbox }}>---SELECT ALL---</Option>
                                                        <Option key="unselect" value="unselect" style={{ display: unselectcheckbox }}>--UNSELECT ALL--</Option>
                                                    </>
                                                    : false}
                                                {unitList && unitList.length > 0 && unitList.map((item, i) => {
                                                    return (
                                                        <Option key={item.unit_id}>
                                                            {item.unitname}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        </div>

                                        :

                                        <div className={"d-flex " + localStorage.getItem('currentTheme')} id='only-restricted'>
                                            <label className='d-flex me-2' htmlFor=""><b>Units</b> <span style={{ "color": "red" }}>*</span></label>
                                            <Select
                                                name="units"
                                                id="units"
                                                className='form-control'
                                                placeholder="Select Units"
                                                onChange={(option) => HandelChange(option)}
                                                mode="multiple"
                                                value={unitList && unitList.length > 0 ? all : undefined}
                                                maxTagCount='responsive'
                                            // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                            >
                                                {unitList && unitList.length > 1 ?
                                                    <>
                                                        <Option key="all" value="all" style={{ display: selectcheckbox }}>---SELECT ALL---</Option>
                                                        <Option key="unselect" value="unselect" style={{ display: unselectcheckbox }}>--UNSELECT ALL--</Option>
                                                    </>
                                                    : false}
                                                {unitList && unitList.length > 0 && unitList.map((item, i) => {
                                                    return (
                                                        <Option key={item.unit_id}>
                                                            {item.unitname}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        </div>

                                    }

                                </div>

                                {all && all.length > 0 && data && data.length > 0 ?
                                    <div className="col-md-12" style={{ marginTop: "10px" }}>
                                        <div className="row">
                                            {/* <div className={"form-group " + localStorage.getItem('currentTheme')} id='only-restricted'>
                                            {/* <Collapse accordion defaultActiveKey={['1']}>
                                                <Panel header="Compliance Task"

                                                    key="1">
                                                    <div className="d-flex justify-content-between" style={{ marginBottom: '25px' }}>
                                                        <Input
                                                            className='form-control'
                                                            type="text"
                                                            autoComplete={'off'}
                                                            placeholder="Enter Compliance Task"
                                                            style={{ width: '300px', height: '32px' }}
                                                            onChange={(e) => {
                                                                setfiltervalue({
                                                                    ...filtervalue,
                                                                    Compliancetask: e.target.value
                                                                })
                                                            }} />&nbsp;&nbsp;
                                                        <div className='d-flex'>
                                                            <label style={{ marginTop: "5px" }}><b>Domain</b></label>&nbsp;&nbsp;
                                                            <Select
                                                                disabled={all == "" ? true : false}
                                                                labelInValue={false}
                                                                allowClear={false}
                                                                onFocus={false}
                                                                onSelect={false}
                                                                value={filtervalue.domain || undefined}
                                                                className='form-control'
                                                                style={{ width: '230px', height: '32px' }}
                                                                placeholder="Select Domain"
                                                                onChange={(e) => {
                                                                    setfiltervalue({
                                                                        ...filtervalue,
                                                                        domain: e
                                                                    })
                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
                                                                {uni_domain && uni_domain.length > 1 ?
                                                                    <Option key={0}>Select All</Option>
                                                                    : false}
                                                                {uni_domain && uni_domain.length > 0 && uni_domain.map((data) => {
                                                                    return (
                                                                        <Option key={data.domain_id} value={data.domain_name}>{data.domain_name}</Option>
                                                                    )
                                                                })}
                                                            </Select>
                                                        </div>&nbsp;&nbsp;
                                                        <div className="d-flex">
                                                            <label htmlFor="" style={{ marginTop: "5px" }}><b>Frequency</b></label>&nbsp;&nbsp;
                                                            <Select
                                                                labelInValue={false}
                                                                allowClear={false}
                                                                onFocus={false}
                                                                onSelect={false}
                                                                style={{ width: '170px', height: '32px' }}
                                                                className='form-control'
                                                                placeholder="Select Frequency"
                                                                defaultValue={'All'}
                                                                onChange={(e) => {
                                                                    setfiltervalue({
                                                                        ...filtervalue,
                                                                        frequency: e
                                                                    })
                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            >
                                                                <Option key={1} value="all">All</Option>
                                                                <Option key={2} value='One Time'>One Time</Option>
                                                                <Option key={3} value='Periodical'>Periodical</Option>
                                                                <Option key={4} value='Review'>Review</Option>
                                                                <Option key={5} value='Flexi Review'>Flexi Review</Option>
                                                                <Option key={6} value='On Occurrence'>On Occurrence</Option>
                                                            </Select>
                                                        </div>
                                                        <div style={{ marginTop: '-6px', 'paddingRight': '12px', paddingLeft: '3px', paddingTop: '6px' }}>

                                                            <Button type="primary" icon={<SearchOutlined />}
                                                                shape="round"
                                                                onClick={frequencyfilter}>
                                                                Search
                                                            </Button>
                                                        </div>
                                                    </div>
                                                    {/* {
                                                        data && data.length > 0 && all.length > 0 ?
                                                            <Checkbox
                                                                onChange={onChange}
                                                                checked={existingChecked.length == allKeys.length ? true : false}
                                                            >
                                                                Select All
                                                            </Checkbox>
                                                            : ''
                                                    }
                                                    <Tree
                                                        checkable
                                                        onCheck={onCheck}
                                                        //onExpand={onExpand}
                                                        //expandedKeys={expandedKeys}
                                                        checkedKeys={existingChecked}
                                                        //autoExpandParent={autoExpandParent}
                                                        treeData={data}
                                                        height={233}
                                                    /> *
                                                    
                                                </Panel >
                                            </Collapse > 
                                           
                                        </div >*/}
                                            <div className='service-provider-table'>

                                                <Table
                                                    className={localStorage.getItem("currentTheme")}
                                                    columns={columns}
                                                    bordered
                                                    onChange={(pagination, filters, sorter, extra) => {
                                                        setLocalFilter(extra.currentDataSource)
                                                        setActionFilter(extra.action)
                                                        console.log(filters, 'filters', extra, sorter)
                                                    }}
                                                    pagination={{
                                                        onChange(page, pageSizes) {
                                                            console.log(page, pageSizes, 'pagesizdd')
                                                            setCurrent(page)
                                                            // setCurrent(pageSize !== pageSizes ? 1 : page);
                                                            setpageState(true)
                                                            setPageSize(pageSizes);
                                                        }
                                                    }}
                                                    showSorterTooltip={false}
                                                    dataSource={data}
                                                    rowSelection={
                                                        actionFilter == false ?
                                                            rowSelection : actionFilter != false && localFilter && localFilter.length > 0 ? rowSelection : undefined

                                                    }
                                                />
                                            </div>
                                            <label style={{ marginTop: '-41px' }}>
                                                Showing {pageSize * (current - 1) + 1} to {data.length < index ? data.length : index} of{" "}
                                                {data && data.length} entries
                                            </label>
                                        </div >

                                    </div >
                                    : false}
                                {/* </Card> */}
                                {actionFilter == false ?
                                    <div className="col-md-12" style={{ marginTop: "8px", textAlign: "center" }}>
                                        <div className="row">
                                            <div>

                                                <Button type="primary" shape="round" className='addbutton'
                                                    style={{ background: "#198754", borderColor: "#198754" }}
                                                    icon={<PlayCircleOutlined />} size='default'
                                                    onClick={saveRecords}>
                                                    Submit
                                                </Button>

                                            </div>
                                        </div>
                                    </div > : actionFilter != false && localFilter && localFilter.length > 0 ? <div className="col-md-12" style={{ marginTop: "8px", textAlign: "center" }}>
                                        <div className="row">
                                            <div>
                                                <Button type="primary" shape="round" className='addbutton'
                                                    style={{ background: "#198754", borderColor: "#198754" }}
                                                    icon={<PlayCircleOutlined />} size='default'
                                                    onClick={saveRecords}>
                                                    Submit
                                                </Button>
                                            </div>

                                        </div>
                                    </div > : undefined}

                            </>
                            : ""}

                    </div >
                </div >
            </div >
        </div >
    )
}

export default connect(mapStateToProps, {
    getViewOnlyRestrictedList,
    getViewOnlyRestUnitData,
    saverecords
})(ViewOnlyRestricted)
