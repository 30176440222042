import React from 'react'
import { InfoCircleOutlined } from '@ant-design/icons';
import { Collapse, Select, Input, DatePicker, Card, Modal, Table, Pagination, Divider, Button, Descriptions, Tooltip, Popover, Drawer } from 'antd';
import DataTable from "react-data-table-component";
import { useState, useRef, useEffect, Fragment } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";
import { connect, useDispatch, useSelector } from 'react-redux';
import { ExclamationCircleTwoTone, ExclamationCircleOutlined, PlayCircleOutlined, UpOutlined, FilterOutlined, FullscreenOutlined, EyeOutlined, ExportOutlined } from '@ant-design/icons';
import moment from 'moment';
import { getData, getVendorTempTabledata, getVendorTempExport } from '../../../Store/Action/Report/CV_VendorTempClosureReport';
import ScrollToTop from "react-scroll-to-top";
import { fullscreenmode, fullscreenexit, handleKeyDown } from './../../../Libs/fullscreenmode';
import { validationType, validateSelect, onPasteToaster } from './../../../Libs/inputValidation';
import useStickyHeight from "../../../Libs/useStickyHeight";
import ScrollButton from '../../Scrollbar';
import { Toaster } from '../../../Libs/Toaster';
import { HomeRedirect } from './../../../Libs/country'
const homepage = HomeRedirect()

const mapStateToProps = (state) => ({
    allData: state.VendorTempClosureReport.userwisereport.userwisereports,
    tableData: state.VendorTempClosureReport.userwisereport.userwisefilter,
    // downloadedPath:state.RiskReport.riskReport.downloadPath
})

const VendorTempClosureReport = ({
    getData,
    getVendorTempTabledata,
    getVendorTempExport,
    allData,
    tableData,
    downloadedPath,
}) => {
    console.log(allData, "allData");
    useStickyHeight();
    const historynew = useHistory();
    const _ = require("lodash");
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const sessionArr = sessionParsedValue.entity_info
    console.log(sessionArr, "sessionArrsessionArr");
    const [getreportlist, setGetReportList] = useState([])
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const location = useLocation();
    const is_Fullscreen = useSelector((state) => state.Login.is_fullscreen);
    const dispatch = useDispatch();
    const paramid = location.pathname;
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [tableshow, setTableShow] = useState(false)
    const [key, setKey] = useState("1")
    const [titledata, Settitledata] = useState()
    const [current, setCurrent] = useState(1);
    const [pageState, setpageState] = useState(false);
    const [pageSize, setPageSize] = useState(25)
    const [childCompliceId, setChildComplinaceId] = useState([])
    let [index, setIndex] = useState(0)
    const [uniqueData, setUniqueData] = useState([])
    const [valueModal, setValueModal] = useState({
        concurrer_name: '',
        Approver: ''
    })
    const [filteredArray, setFilteredArray] = useState([])
    const [filteredArrayState, setfilteredArrayState] = useState([])
    const [consolidateddata, setConsolidatedDate] = useState({
        country: "",
        category: '',
        legel_entity: "",
        legel_entity_name: "",
        unit: '',
        division: '',
        unit_name: '',
        division_name: '',
        category_name: '',
        bg_id: '',
        bg_name: ''

    })
    console.log(consolidateddata, "consolidateddata");

    const validator = useRef(new SimpleReactValidator());
    const [exportButton, setExportButton] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [divisionList, setDivisionList] = useState([])
    const [vendorlist, setVendorlist] = useState([])
    console.log(vendorlist, "vendorlist");
    const [categoryList, setCategoryList] = useState([])
    const [legalentityfilter, setlegalentityfilter] = useState([])
    const [actLegalEntity, setActLegalEntity] = useState([])
    const [actName, setActName] = useState([])
    const [actNameFilteredState, setActNameFilteredState] = useState([])
    const [finalResult, SetfinalResult] = useState({
        Resultdata: []
    })
    const [showValueModal, setShowValueModal] = useState(false)
    const country_info = sessionParsedValue.country_info
    const SelectedEntity = localStorage.getItem('SelectedEntity')
    const [businessGroup, setBusinessGroup] = useState({
        bg_id: '',
        bg_name: ''
    })
    const [checkboxValue, setCheckBoxValue] = useState([1, 2, 3, 4, 5])
    const [filterTaskAcc, setFilterTaskAcc] = useState(true);
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const [exitCollapse, setExitCollapse] = useState(false);

    const { Panel } = Collapse;
    const { Option } = Select;
    const [bggroup, setBggroup] = useState([]);
    const [selectSearchValue, setSelectSearchValue] = useState({
        legalEntity: '',
        BusinessGroup: '',
        division: '',
        category: '',
        vendor: '',
    });

    useEffect(() => {
        if (sessionArr && sessionArr.length > 0) {
            let unique = _.uniqBy(sessionArr, function (e) {
                return e.bg_id;
            });
            setBggroup(unique)
        }
    }, [])
    const columns = [
        {
            title: 'User Name',
            ellipsis: true,
            // width: '30px',
            dataIndex: 'user_name',
            key: 'user_name',
        },
        {
            title: 'Due Date',
            ellipsis: true,
            // width: '30px',
            dataIndex: 'due_date',
            key: 'due_date',
        },


    ];
    useEffect(() => {
        if (pageState == true) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetStatutorySettingsUnitWise",
                        {
                            "c_id": Number(sessionArr && sessionArr[0].c_id),
                            "bg_id": consolidateddata.bg_id ? Number(consolidateddata.bg_id) : null,
                            "le_id": entityid,
                            "d_id": Number(consolidateddata.domain),
                            "unit_id": consolidateddata.unit ? Number(consolidateddata.unit) : null,
                            "div_id": consolidateddata.division ? Number(consolidateddata.division) : null,
                            "cat_id": consolidateddata.category ? Number(consolidateddata.category) : null,
                            "parent_id": consolidateddata.act ? Number(consolidateddata.act) : 0,
                            "compliance_task": consolidateddata.compliance_task ? Number(consolidateddata.compliance_task) : null,
                            "frequency_id": consolidateddata.compliance_frequency ? Number(consolidateddata.compliance_frequency) : 0,
                            "status_name": "All",
                            "csv": false,
                            "f_count": Math.max(((current - 1) * pageSize), 0) + 1,
                            "t_count": current != 0 ? current * pageSize : pageSize,
                            "count_qry": true,
                            "list_check": null
                        }
                    ]

                }

            ]
            getVendorTempTabledata({
                payload: payload,
                paramid: paramid
            })
        }
    }, [current, pageSize, pageState])

    useEffect(() => {
        if (pageState === true) {
            setIndex(pageSize * (current - 1))
        }
    }, [pageState, pageSize, current])

    useEffect(() => {
        let tempArr = []
        if (tableData && tableData.vendor_temporary_closure_report_list && tableData.vendor_temporary_closure_report_list.length > 25) {
            filteredArrayState && filteredArrayState.length > 0 && filteredArrayState.map((item, i) => {

                let uniqueObjArray = [...new Map(item && item.length && item.map((items) => [items["compliance_id"], items])).values()];

                const uniqueIds = uniqueObjArray && uniqueObjArray.map((itemData) => {
                    return itemData.compliance_id
                })


                for (let i in uniqueIds) {
                    let filteredArr = _.find(item && item.length && item, { compliance_id: uniqueIds[i] })
                    tempArr.push(filteredArr)
                }
            })


            setUniqueData(tempArr)

        }
    }, [filteredArrayState])

    useEffect(() => {
        let array = [];
        let finalarray = [];
        let datavalue = [];
        if (tableData && tableData.vendor_temporary_closure_report_list && tableData.vendor_temporary_closure_report_list.length > 25) {
            // setTempeditdata(editdata)
            if (uniqueData && uniqueData.length > 0) {
                const ab = uniqueData && uniqueData.length && uniqueData.map(function (item) {
                    if ((array.indexOf(item.act_name) < 0))
                        array.push(item.act_name)
                })


                array.map((data, i) => {
                    let temp = [];
                    let entityArray = [];
                    let datavalue = [];
                    if (data) {
                        uniqueData && uniqueData.length && uniqueData.map((child, childIndex) => {
                            if (data === child.act_name) {
                                entityArray = {
                                    "act_name": child.act_name,
                                    "approver_name": child.approver_name,
                                    "assignee_name": child.assignee_name,
                                    "category_name": child.category_name,
                                    "compliance_description": child.compliance_description,
                                    "compliance_id": child.compliance_id,
                                    "compliance_task": child.compliance_task,
                                    "concurrer_name": child.concurrer_name,
                                    " criticality_name": child.criticality_name,
                                    "division_name": child.division_name,
                                    " document_name": child.document_name,
                                    "download_url": child.download_url,
                                    "due_date": child.due_date,
                                    " extend_due_date": child.extend_due_date,
                                    "frequency": child.frequency,
                                    "task_status": child.task_status,
                                    " tz_name": child.tz_name,
                                    "unit": child.unit,
                                    "unit_address": child.unit_address,
                                    "unit_id": child.unit_id,
                                    "user_name": child.user_name,
                                    'childId': childIndex,
                                    'parentId': i
                                }
                                temp.push(entityArray)
                            }
                        })
                    }

                    datavalue = {
                        index: i,
                        parent: data,
                        child: temp
                    }
                    finalarray.push(datavalue)
                })
                Settitledata(finalarray)

            }
        }
        else {

            if (tableData && tableData.vendor_temporary_closure_report_list && tableData.vendor_temporary_closure_report_list.length > 0) {
                const ab = tableData && tableData.vendor_temporary_closure_report_list && tableData.vendor_temporary_closure_report_list.length && tableData.vendor_temporary_closure_report_list.map(function (item) {
                    if ((array.indexOf(item.act_name) < 0))
                        array.push(item.act_name)
                })
                array.map((data, i) => {
                    let temp = [];
                    let entityArray = [];
                    let datavalue = [];
                    if (data) {
                        tableData && tableData.vendor_temporary_closure_report_list && tableData.vendor_temporary_closure_report_list.length && tableData.vendor_temporary_closure_report_list.map((child, childIndex) => {
                            if (data === child.act_name) {
                                entityArray = {
                                    "act_name": child.act_name,
                                    "approver_name": child.approver_name,
                                    "assignee_name": child.assignee_name,
                                    "category_name": child.category_name,
                                    "compliance_description": child.compliance_description,
                                    "compliance_id": child.compliance_id,
                                    "compliance_task": child.compliance_task,
                                    "concurrer_name": child.concurrer_name,
                                    " criticality_name": child.criticality_name,
                                    "division_name": child.division_name,
                                    " document_name": child.document_name,
                                    "download_url": child.download_url,
                                    "due_date": child.due_date,
                                    " extend_due_date": child.extend_due_date,
                                    "frequency": child.frequency,
                                    "task_status": child.task_status,
                                    " tz_name": child.tz_name,
                                    "unit": child.unit,
                                    "unit_address": child.unit_address,
                                    "unit_id": child.unit_id,
                                    "user_name": child.user_name,
                                    'childId': childIndex,
                                    'parentId': i
                                }
                                temp.push(entityArray)
                            }
                        })
                    }

                    datavalue = {
                        index: i,
                        parent: data,
                        child: temp
                    }
                    finalarray.push(datavalue)
                })
                Settitledata(finalarray)
            }
        }
    }, [tableData && tableData.vendor_temporary_closure_report_list, uniqueData])
    useEffect(() => {
        if (tableData && tableData.vendor_temporary_closure_report_list && tableData.vendor_temporary_closure_report_list.length > 0) {
            let uniqueObjArray = [...new Map(tableData && tableData.vendor_temporary_closure_report_list && tableData.vendor_temporary_closure_report_list.length && tableData.vendor_temporary_closure_report_list.map((item) => [item["unit_id"], item])).values()];

            const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                return item.unit_id
            })

            let tempArr = []
            for (let i in uniqueIds) {
                let filteredArr = _.filter(tableData && tableData.vendor_temporary_closure_report_list && tableData.vendor_temporary_closure_report_list.length && tableData.vendor_temporary_closure_report_list, { unit_id: uniqueIds[i] })

                tempArr.push(filteredArr)
            }
            setFilteredArray([...filteredArray, tempArr])

        }

    }, [tableData && tableData.vendor_temporary_closure_report_list])
    useEffect(() => {
        if (filteredArray.length) {
            let temp = []
            for (let i in filteredArray) {

                temp = filteredArray[i]
            }
            setfilteredArrayState([...temp])
        }

    }, [filteredArray])
    useEffect(() => {
        if (entityid === null) {

            setCurrentEntity(consolidateddata.legel_entity)
        }
    }, [entityid])
    useEffect(() => {
        validator.current.showMessages()
    }, [])
    useEffect(() => {
        if (entityid === null) {

            setCurrentEntity(consolidateddata.legel_entity)
        }
    }, [entityid])
    useEffect(() => {
        if (SelectedEntity != 'All Legal Entity') {
            let businessName = _.filter(sessionArr, { le_name: SelectedEntity })
            setBusinessGroup({
                ...businessGroup,
                bg_id: businessName && businessName.length > 0 && businessName[0].bg_id,
                bg_name: businessName && businessName.length > 0 && businessName[0].bg_name
            })


        }
    }, [SelectedEntity, sessionArr])

    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetVendorTemporaryReportFilters",
                        {
                            "c_id": Number(sessionArr && sessionArr[0].c_id),
                            "le_id": entityid
                        }
                    ]
                }
            ]
            if (entityid != 'null' && entityid != '') {
                getData({
                    payload: payload,
                    paramid: paramid
                })
            }
        }
    }, [isAuth, authtoken, entityid])

    useEffect(() => {
        if (allData) {

            setCategoryList(allData && allData.cat_infos)
            setVendorlist(allData && allData.ven_names)
            setDivisionList(allData && allData.div_infos)
        }

    }, [allData])

    const onshow = () => {
        console.log("trueeeeeeeeeeeee");

        setAddFormSubmit(true)
       

        if (validator.current.allValid()) {
            Settitledata([])
            setfilteredArrayState([])
            setIndex(0)
            setCurrent(1);
            setpageState(false)
            setPageSize(25)
            console.log("trueeeeeeeeeeeee");
            setFilterModalVisible(false)
            setTableShow(true)
            setKey(0)
            setTableShow(true)
            setExitCollapse(true);
            setFilterTaskAcc(false);
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetVendorTemporaryClosure",
                        {
                            "bg_id": null,
                            "le_id": entityid,
                            "div_id": consolidateddata.division ? Number(consolidateddata.division) : null,
                            "cat_id": consolidateddata.category ? Number(consolidateddata.category) : null,
                            "unit_id": consolidateddata.unit ? Number(consolidateddata.unit) : null,
                            "csv": false,
                            "f_count": 1,
                            "t_count": 25,
                            "count_qry": true,
                            "list_check": null
                        }
                    ]
                }
            ]
            getVendorTempTabledata({
                payload: payload,
                paramid: paramid
            })
        }
    }
    const showModal = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            setIsModalOpen(true);
        }
    };
    const handleOk = () => {
        setAddFormSubmit(true)
        setExportButton(true)
        if (validator.current.allValid()) {
            // setTableShow(true)
            setKey(0)
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetVendorTemporaryClosure",
                        {
                            "bg_id": consolidateddata.bg_id ? Number(consolidateddata.bg_id) : null,
                            "le_id": entityid,
                            "div_id": consolidateddata.division ? Number(consolidateddata.division) : null,
                            "cat_id": consolidateddata.category ? Number(consolidateddata.category) : null,
                            "unit_id": consolidateddata.unit ? Number(consolidateddata.unit) : null,
                            "csv": true,
                            "f_count": 1,
                            "t_count": 25,
                            "count_qry": true,
                            "list_check": checkboxValue,
                        }
                    ]

                }
            ]
            getVendorTempExport({
                payload: payload,
                paramid: paramid
            })
        }
        setIsModalOpen(false);
        setCheckBoxValue([1, 2, 3, 4, 5])
    };
    const chilData = (id, unitId) => {
        setIsModalVisible(true)
        if (tableData && tableData.vendor_temporary_closure_report_list && tableData.vendor_temporary_closure_report_list.length > 25) {
            let tempArr = []
            for (let i in tableData && tableData.vendor_temporary_closure_report_list && tableData.vendor_temporary_closure_report_list.length && tableData.vendor_temporary_closure_report_list) {
                if (tableData.vendor_temporary_closure_report_list && tableData.vendor_temporary_closure_report_list.length && tableData.vendor_temporary_closure_report_list[i].compliance_id == id) {
                    if (tableData.vendor_temporary_closure_report_list && tableData.vendor_temporary_closure_report_list.length && tableData.vendor_temporary_closure_report_list[i].unit_id == unitId) {
                        tempArr.push(tableData.vendor_temporary_closure_report_list && tableData.vendor_temporary_closure_report_list.length && tableData.vendor_temporary_closure_report_list[i])
                    }
                }
            }

            setChildComplinaceId(tempArr)

        }
    }
    const handleCancel = () => {
        setIsModalOpen(false);
        setShowValueModal(false)
        setIsModalVisible(false)
    };
    const showValues = () => {
        setShowValueModal(true);
    };
    const onChange = (key) => {
        console.log(key);
    };

    const checkBoxOnChange = (e) => {
        let temp = checkboxValue
        let tempIds = []
        if (temp.includes(Number(e.target.value)) == true) {
            for (let i in temp) {
                if (temp[i] == Number(e.target.value)) {
                    temp.splice(i, 1)
                }
            }
            setCheckBoxValue([...temp])
        } else {
            tempIds.push(Number(e.target.value))
            setCheckBoxValue([...checkboxValue, ...tempIds])
        }
        console.log(checkboxValue, 'tempIdstempIds')
    }

    const selectAllCheckBox = (checked) => {
        var ele = document.getElementsByName('chk');
        let tempAllIds = []
        if (checked == true) {
            for (var i = 0; i < ele.length; i++) {

                if (ele[i].type == 'checkbox') {
                    ele[i].checked = true;
                    tempAllIds.push(Number(ele[i].value))
                }
            }
            setCheckBoxValue(tempAllIds)
        }
        else {
            var ele = document.getElementsByName('chk');
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == 'checkbox')
                    ele[i].checked = false;
            }
            setCheckBoxValue([])
        }
    }

    const [fullscreen, setfullscreen] = useState(false);

    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
            document.addEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch), false);
            return () => {
                document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch));
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode(dispatch);
            setfullscreen(true);
        } else {
            fullscreenexit(dispatch);
            setfullscreen(false);
        }
    }


    const homescreen = () => {
        historynew.push(homepage);
        // window.location.href = homepage
        document.getElementById('homehover').style.color = "blue"
    }

    const filterHandle = () => {
        setFilterTaskAcc(true);
        setFilterModalVisible(true);
    };


    const setcancelFilter = () => {
        setFilterModalVisible(false);
        setFilterTaskAcc(false);
    };
    const handlePasting = (e, regex) => {

        var regex = regex;
        var key = e.clipboardData.getData('text')
        if (!regex.test(key)) {
            e.preventDefault();
            Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
            return false;
        }
        // e.clipboardData.getData('text')
        // Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
        // e.preventDefault();
        // return false;
    }

    return (
        <div id='page-wrapper' className="page-wrapper">
            <div className='back-to-top'>
                <ScrollButton />
            </div>
            <div className="page-titles py-0 page-title-sticky">
                <div className="row py-1">
                    <div className="col-lg-7 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <span className='pointer' id='homehover' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <span style={{ fontSize: '14px', fontWeight: "bold" }}>Report</span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: "15px", fontWeight: "bold" }}>
                                    <span>Vendor Reports</span>
                                </li>

                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                    <span>Vendor Temporary Closure Report</span>
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div className="col-lg-5 text-end">
                        {/* <div style={{ display: "initial" }}>
                            <Button className="mx-1" shape="round" disabled>
                                Total Vendors : {filteredArrayState?.length}
                            </Button>
                            <Button className="mx-1 fw-2" shape="round" disabled>
                                Total Acts : {titledata ? titledata?.length : '0'}
                            </Button>
                        </div> */}
                        <Tooltip title='Advance  Filter'>    <Button
                            type="primary"
                            shape="round"
                            style={{
                                display: filterTaskAcc ? "none" : "initial",
                            }}
                            onClick={filterHandle}
                        >
                            <FilterOutlined /> Filter
                        </Button></Tooltip>
                        <button onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black ms-2 full-mode fullscreenradius" >
                            <Tooltip placement="left" title={is_Fullscreen == true ? "Exit Full Screen Mode" : "Full Screen Mode"}><FullscreenOutlined /></Tooltip>
                        </button>
                    </div>
                </div>
            </div>
            <Drawer title="Select Fields" className={'add-service-prv export-checkbox ' + localStorage.getItem('currentTheme')} open={isModalOpen} footer={null} onCancel={handleCancel} onClose={handleCancel} width={600} maskClosable={false}>
                <div className='d-flex flex-wrap'>
                    <input id="select_all"
                        checked={checkboxValue.length === 19 ? true : false}
                        width="100%" type="checkbox" autocomplete="off" onClick={(e) => {
                            let checked = e.target.checked
                            selectAllCheckBox(checked)
                        }} /> <label for='select_all'>Select All</label>



                    <input id="column_1" name='chk' type="checkbox" checked disabled="disabled" value='1' onChange={(e) => (e) => checkBoxOnChange(e)} /><label for='column_1'>Business Group</label>
                    <input id="column_2" name='chk' type="checkbox" checked disabled="disabled" value='2' onChange={(e) => checkBoxOnChange(e)} /><label for='column_2'>Legal Entity</label>
                    <input id="column_3" name='chk' type="checkbox" value='3' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /><label for='column_3'>Division</label>
                    <input id="column_4" name='chk' type="checkbox" value='4' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> <label for='column_4'>Category</label>
                    <input id="column_5" name='chk' type="checkbox" value='5' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> <label for='column_5'>Vendor Name</label>
                    <input id="column_6" name='chk' type="checkbox" value='6' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_6'>Vendor Code</label>
                    <input id="column_7" name='chk' type="checkbox" value='7' onChange={(e) => checkBoxOnChange(e)} /><label for='column_7'>Vendor Code 1</label>
                    <input id="column_8" name='chk' type="checkbox" value='8' onChange={(e) => checkBoxOnChange(e)} /><label for='column_8'>Vendor Code 2</label>
                    <input id="column_9" name='chk' type="checkbox" value='9' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_9'>Vendor Code 3</label>
                    <input id="column_10" name='chk' type="checkbox" value='10' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_10'>Vendor Code 4</label>
                    <input id="column_11" name='chk' type="checkbox" value='11' onChange={(e) => checkBoxOnChange(e)} /><label for='column_11'>Location</label>
                    <input id="column_12" name='chk' type="checkbox" value='12' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_12'>Postal Code</label>
                    <input id="column_13" name='chk' type="checkbox" value='13' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_13'>Domain</label>
                    <input id="column_14" name='chk' type="checkbox" value='14' onChange={(e) => checkBoxOnChange(e)} /><label for='column_14'>Organization Type</label>
                    <input id="column_15" name='chk' type="checkbox" value='15' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_15'>Date of Temporary Closure</label>
                    <input id="column_16" name='chk' type="checkbox" value='16' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_16'>Temporary Closure done by</label>
                    <input id="column_17" name='chk' type="checkbox" value='17' onChange={(e) => checkBoxOnChange(e)} /><label for='column_17'>Remarks for Temporary Closure</label>
                    <input id="column_18" name='chk' type="checkbox" value='18' onChange={(e) => checkBoxOnChange(e)} /><label for='column_18'>Date Reactivated From</label>
                    <input id="column_19" name='chk' type="checkbox" value='19' onChange={(e) => checkBoxOnChange(e)} /><label for='column_19'>Reactivated By</label>

                </div>
                <div className="form-actions text-center">
                    <div className='popupbtncolour'>
                        <Button type="primary" shape="round" className={
                            "addbutton " + localStorage.getItem("currentTheme")
                        }
                            style={{ background: "#198754", borderColor: "#198754" }}
                            icon={<PlayCircleOutlined />} size='default' onClick={handleOk}> Submit
                        </Button>
                    </div>
                </div>

            </Drawer >
            <Modal
                onCancel={setcancelFilter}
                footer={false}
                title="Vendor Temporary Closure"
                className={
                    "add-service-prv cons-report " +
                    localStorage.getItem("currentTheme")
                }
                open={filterModalVisible}
                maskClosable={false}
            >
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-4">
                            <label><b>Business Group: </b>  </label>
                            {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                <div className="form-group">
                                    <Select
                                        getPopupContainer={trigger => trigger.parentNode}
                                        allowClear={true}
                                        size="default"
                                        placeholder="Entry Business Group"
                                        onChange={(data, value) => {
                                            if (data != undefined) {
                                                setConsolidatedDate({
                                                    ...consolidateddata,
                                                    bg_id: data ? data : '',
                                                    bg_name: value ? value.children : '',
                                                    division_name: '',
                                                    legel_entity_name: '',
                                                    category_name: '',
                                                    unit_name: ''
                                                })
                                            }
                                            else {
                                                setConsolidatedDate({
                                                    ...consolidateddata,
                                                    bg_id: '',
                                                    bg_name: '',
                                                    division_name: '',
                                                    legel_entity_name: '',
                                                    category_name: '',
                                                    unit_name: ''
                                                })
                                            }
                                        }}
                                        onDrop={(e) => {
                                            e.preventDefault();
                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                            return false;
                                        }}
                                        // onPaste={(e) => {
                                        //     handlePasting(e, /^[a-z]+$/gi)
                                        // }}
                                        onPaste={onPasteToaster}
                                        searchValue={selectSearchValue.BusinessGroup}
                                        onSearch={(value) => {
                                            let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                            setSelectSearchValue({
                                                ...selectSearchValue,
                                                BusinessGroup: result
                                            });
                                        }}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        value={consolidateddata.bg_name || undefined}
                                        style={{ width: '100%', marginTop: '5px' }}
                                    >
                                        {bggroup && bggroup.length && bggroup.map((item, i) => {

                                            if (item.bg_name !== null) {
                                                return (
                                                    <Option key={item.bg_id}>
                                                        {item.bg_name}
                                                    </Option>
                                                );
                                            }
                                        })}
                                    </Select>

                                </div> :
                                <p> - </p>
                            }
                        </div>
                        <div className="col-md-4 text-wrap-report">
                            <label><b>Legal Entity:</b> <span style={{ color: "red" }}>*</span></label>
                            {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                <div className="form-group">
                                    <Select
                                        getPopupContainer={trigger => trigger.parentNode}
                                        allowClear={true}
                                        size="default"
                                        // disabled={consolidateddata.country == "" ? true : false}
                                        placeholder="Enter Legal Entity"
                                        onChange={(data, value) => {
                                            if (data != undefined) {
                                                setCurrentEntity(data)
                                                setConsolidatedDate({
                                                    ...consolidateddata,
                                                    legel_entity: data ? data : '',
                                                    legel_entity_name: value ? value.children : '',
                                                    division_name: '',
                                                    category_name: '',
                                                    unit_name: ''
                                                })


                                            }
                                            else {
                                                setCurrentEntity("")
                                                setConsolidatedDate({
                                                    ...consolidateddata,
                                                    legel_entity: '',
                                                    legel_entity_name: '',
                                                    division_name: '',
                                                    category_name: '',
                                                    unit_name: ''
                                                })
                                            }
                                        }}
                                        onDrop={(e) => {
                                            e.preventDefault();
                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                            return false;
                                        }}
                                        // onPaste={(e) => {
                                        //     handlePasting(e, /^[a-z]+$/gi)
                                        // }}
                                        onPaste={onPasteToaster}
                                        searchValue={selectSearchValue.legalEntity}
                                        onSearch={(value) => {
                                            let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                            setSelectSearchValue({
                                                ...selectSearchValue,
                                                legalEntity: result
                                            });
                                        }}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        //value={Settings.le_id}
                                        style={{ width: '100%', marginTop: '5px' }}

                                        value={consolidateddata.legel_entity_name || undefined}
                                    >
                                        {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                            return (
                                                <Option key={item.le_id}>
                                                    {item.le_name}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                    {validator.current.message(
                                        'legalentity',
                                        consolidateddata.legel_entity,
                                        'required',
                                        {
                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                            messages: {
                                                required: 'Legal Entity Required',
                                            }
                                        })}
                                </div> :
                                <p>{localStorage.getItem('SelectedEntity')}</p>
                            }
                        </div>
                        <div className="col-md-4">
                            <label><b>Vendor</b></label><br />
                            <Select
                                getPopupContainer={trigger => trigger.parentNode}
                                showSearch
                                onChange={(value, data) => {
                                    if (value != undefined) {
                                        setConsolidatedDate({
                                            ...consolidateddata,
                                            unit: value ? value : '',
                                            unit_name: data ? data.children : ''
                                        })
                                    }
                                    else {
                                        setConsolidatedDate({
                                            ...consolidateddata,
                                            unit: '',
                                            unit_name: ''
                                        })
                                    }
                                }}
                                onDrop={(e) => {
                                    e.preventDefault();
                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                    return false;
                                }}
                                // onPaste={(e) => {
                                //     handlePasting(e, /^[a-z]+$/gi)
                                // }}
                                onPaste={onPasteToaster}
                                searchValue={selectSearchValue.vendor}
                                onSearch={(value) => {
                                    let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                    setSelectSearchValue({
                                        ...selectSearchValue,
                                        vendor: result
                                    });
                                }}
                                placeholder="Enter Vendor"
                                style={{ width: '100%', marginTop: '5px' }}
                                allowClear={true}
                                value={consolidateddata.unit_name || undefined}
                                disabled={consolidateddata.legel_entity_name == '' ? true : false}

                            >
                                {vendorlist && vendorlist.length && vendorlist.map((item, i) => {
                                    return (
                                        <Option key={item.unit_id}>
                                            {`${item.unit_code}-${item.unit_name}`}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </div>
                    </div>
                </div>
                <div className="col-md-12" style={{ marginTop: '5px' }}>
                    <div className="row">
                        <div className="col-md-4">
                            <label><b>Category:</b></label><br />
                            <Select allowClear={true}
                                getPopupContainer={trigger => trigger.parentNode}
                                showSearch
                                onChange={(value, data) => {
                                    if (value != undefined) {
                                        setConsolidatedDate({
                                            ...consolidateddata,
                                            category: value ? value : '',
                                            category_name: data ? data.children : '',
                                            unit_name: ''
                                        })
                                        let filteredUnit = _.filter(allData && allData.ven_names, { category_id: Number(value) })
                                        setVendorlist(filteredUnit)
                                    }
                                    else {
                                        setConsolidatedDate({
                                            ...consolidateddata,
                                            category: '',
                                            category_name: '',
                                            unit_name: ''
                                        })
                                    }

                                }}
                                onDrop={(e) => {
                                    e.preventDefault();
                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                    return false;
                                }}
                                // onPaste={(e) => {
                                //     handlePasting(e, /^[a-z]+$/gi)
                                // }}
                                onPaste={onPasteToaster}
                                searchValue={selectSearchValue.category}
                                onSearch={(value) => {
                                    let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                    setSelectSearchValue({
                                        ...selectSearchValue,
                                        category: result
                                    });
                                }}
                                placeholder="Enter Category Name"
                                style={{ width: '100%', marginTop: '5px' }}

                                value={consolidateddata.category_name || undefined}
                                disabled={consolidateddata.legel_entity_name == '' ? true : false}

                            >
                                {categoryList && categoryList.length && categoryList.map((item, i) => {
                                    return (
                                        <Option key={item.cat_id}>
                                            {item.cat_name}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor=""><b>Division:</b></label><br />
                            <Select
                                getPopupContainer={trigger => trigger.parentNode}
                                showSearch
                                onChange={(value, data) => {
                                    if (value != undefined) {

                                        setConsolidatedDate({
                                            ...consolidateddata,
                                            division: value ? value : '',
                                            division_name: data ? data.children : '',
                                            category_name: '',
                                            unit_name: ''
                                        })
                                        let filteredCategory = _.filter(allData && allData.cat_infos, { div_id: Number(value) })
                                        setCategoryList(filteredCategory)

                                    }
                                    else {
                                        setConsolidatedDate({
                                            ...consolidateddata,
                                            division: '',
                                            division_name: '',
                                            category_name: '',
                                            unit_name: ''
                                        })
                                        // let filteredCategory = _.filter(allData && allData.cat_infos, { div_id: Number(value) })
                                        setCategoryList([])
                                        // let filteredUnit = _.filter(allData && allData.ven_names, { division_id: Number(value) })
                                        setVendorlist([])
                                    }

                                }}
                                onDrop={(e) => {
                                    e.preventDefault();
                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                    return false;
                                }}
                                // onPaste={(e) => {
                                //     handlePasting(e, /^[a-z]+$/gi)
                                // }}
                                onPaste={onPasteToaster}
                                searchValue={selectSearchValue.division}
                                onSearch={(value) => {
                                    let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                    setSelectSearchValue({
                                        ...selectSearchValue,
                                        division: result
                                    });
                                }}
                                placeholder="Enter Division Name"
                                style={{ width: '100%', marginTop: '5px' }}
                                allowClear={true}
                                value={consolidateddata.division_name || undefined}
                                disabled={consolidateddata.legel_entity_name == '' ? true : false}

                            >
                                {divisionList && divisionList.length && divisionList.map((item, i) => {

                                    return (
                                        <Option key={item.div_id}>
                                            {item.div_name}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </div>
                    </div>
                </div>

                <div className='col-md-12' style={{ marginTop: "1%" }}>
                    <div className='row'>
                        {/* <div className='col-md-4'></div>
                                            <div className='col-md-4'></div> */}
                        <div className='col-md-4 popupbtncolour' style={{ marginLeft: '35%' }}>

                            <Button type="primary" shape="round" icon={<EyeOutlined />} className={
                                "addbutton " + localStorage.getItem("currentTheme")
                            } style={{ marginLeft: "39px", marginRight: "10px" }}
                                onClick={onshow}
                            >
                                Show
                            </Button>
                            <Button icon={<ExportOutlined />} type="primary" shape="round" className={
                                "addbutton " + localStorage.getItem("currentTheme")
                            } style={{ background: "#32a852", borderColor: "#32a852" }} onClick={showModal}
                            >
                                Export
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal open={isModalVisible} onCancel={handleCancel} onOk={() => {
                setIsModalVisible(false)
            }} maskClosable={false}>
                <Table
                    className='userprivclass'
                    columns={columns}
                    dataSource={childCompliceId}
                    bordered
                    pagination={false} />
            </Modal>
            <div className="container-fluid pt-0">
                <div className="row">
                    <div className="col-12">
                        <div className="card-body" style={{ padding: "0px 0px" }}>
                            <div className={localStorage.getItem("currentTheme") + ' card-body1-statuatory'}>
                                <Collapse accordion defaultActiveKey={["1"]} className='report-collapse' onChange={onChange} style={{ display: exitCollapse ? "none" : "block" }}>
                                    <Panel header="Vendor Temporary Closure Report" key={key}>
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <label><b>Business Group: </b>  </label>
                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                placeholder="Entry Business Group"
                                                                onChange={(data, value) => {
                                                                    if (data != undefined) {
                                                                        setConsolidatedDate({
                                                                            ...consolidateddata,
                                                                            bg_id: data ? data : '',
                                                                            bg_name: value ? value.children : '',
                                                                            division_name: '',
                                                                            legel_entity_name: '',
                                                                            category_name: '',
                                                                            unit_name: ''
                                                                        })
                                                                    }
                                                                    else {
                                                                        setConsolidatedDate({
                                                                            ...consolidateddata,
                                                                            bg_id: '',
                                                                            bg_name: '',
                                                                            division_name: '',
                                                                            legel_entity_name: '',
                                                                            category_name: '',
                                                                            unit_name: ''
                                                                        })
                                                                    }
                                                                }}
                                                                onDrop={(e) => {
                                                                    e.preventDefault();
                                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                    return false;
                                                                }}
                                                                // onPaste={(e) => {
                                                                //     handlePasting(e, /^[a-z]+$/gi)
                                                                // }}
                                                                onPaste={onPasteToaster}
                                                                searchValue={selectSearchValue.BusinessGroup}
                                                                onSearch={(value) => {
                                                                    let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                                    setSelectSearchValue({
                                                                        ...selectSearchValue,
                                                                        BusinessGroup: result
                                                                    });
                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                value={consolidateddata.bg_name || undefined}
                                                                style={{ width: '100%', marginTop: '5px' }}
                                                            >
                                                                {bggroup && bggroup.length && bggroup.map((item, i) => {

                                                                    if (item.bg_name !== null) {
                                                                        return (
                                                                            <Option key={item.bg_id}>
                                                                                {item.bg_name}
                                                                            </Option>
                                                                        );
                                                                    }
                                                                })}
                                                            </Select>

                                                        </div> :
                                                        <p> - </p>
                                                    }
                                                </div>
                                                <div className="col-md-4 text-wrap-report">
                                                    <label><b>Legal Entity:</b> <span style={{ color: "red" }}>*</span></label>
                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                // disabled={consolidateddata.country == "" ? true : false}
                                                                placeholder="Enter Legal Entity"
                                                                onChange={(data, value) => {
                                                                    if (data != undefined) {

                                                                        setCurrentEntity(data)
                                                                        setConsolidatedDate({
                                                                            ...consolidateddata,
                                                                            legel_entity: data ? data : '',
                                                                            legel_entity_name: value ? value.children : '',
                                                                            division_name: '',
                                                                            category_name: '',
                                                                            unit_name: ''
                                                                        })

                                                                    }
                                                                    else {
                                                                        setCurrentEntity("")
                                                                        setConsolidatedDate({
                                                                            ...consolidateddata,
                                                                            legel_entity: '',
                                                                            legel_entity_name: '',
                                                                            division_name: '',
                                                                            category_name: '',
                                                                            unit_name: ''
                                                                        })
                                                                    }
                                                                }}
                                                                onDrop={(e) => {
                                                                    e.preventDefault();
                                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                    return false;
                                                                }}
                                                                // onPaste={(e) => {
                                                                //     handlePasting(e, /^[a-z]+$/gi)
                                                                // }}
                                                                onPaste={onPasteToaster}
                                                                searchValue={selectSearchValue.legalEntity}
                                                                onSearch={(value) => {
                                                                    let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                                    setSelectSearchValue({
                                                                        ...selectSearchValue,
                                                                        legalEntity: result
                                                                    });
                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                //value={Settings.le_id}
                                                                style={{ width: '100%', marginTop: '5px' }}

                                                                value={consolidateddata.legel_entity_name || undefined}
                                                            >
                                                                {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.le_id}>
                                                                            {item.le_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                            {validator.current.message(
                                                                'legalentity',
                                                                consolidateddata.legel_entity,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Legal Entity Required',
                                                                    }
                                                                })}
                                                        </div> :
                                                        <p>{localStorage.getItem('SelectedEntity')}</p>
                                                    }
                                                </div>
                                                <div className="col-md-4">
                                                    <label><b>Vendor</b></label><br />
                                                    <Select
                                                    showSearch
                                                        onChange={(value, data) => {
                                                            if (value != undefined) {
                                                                setConsolidatedDate({
                                                                    ...consolidateddata,
                                                                    unit: value ? value : '',
                                                                    unit_name: data ? data.children : ''
                                                                })
                                                            }
                                                            else {
                                                                setConsolidatedDate({
                                                                    ...consolidateddata,
                                                                    unit: '',
                                                                    unit_name: ''
                                                                })
                                                            }
                                                        }}
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        // onPaste={(e) => {
                                                        //     handlePasting(e, /^[a-z]+$/gi)
                                                        // }}
                                                        onPaste={onPasteToaster}
                                                        searchValue={selectSearchValue.vendor}
                                                        onSearch={(value) => {
                                                            let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                            setSelectSearchValue({
                                                                ...selectSearchValue,
                                                                vendor: result
                                                            });
                                                        }}
                                                        placeholder="Enter Vendor"
                                                        style={{ width: '100%', marginTop: '5px' }}
                                                        allowClear={true}
                                                        value={consolidateddata.unit_name || undefined}
                                                        disabled={consolidateddata.legel_entity_name == '' ? true : false}
                                                    >
                                                        {vendorlist && vendorlist.length && vendorlist.map((item, i) => {
                                                            return (
                                                                <Option key={item.unit_id}>
                                                                    {`${item.unit_code}-${item.unit_name}`}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12" style={{ marginTop: '5px' }}>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <label><b>Category:</b></label><br />
                                                    <Select allowClear={true}
                                                    showSearch
                                                        onChange={(value, data) => {
                                                            if (value != undefined) {
                                                                setConsolidatedDate({
                                                                    ...consolidateddata,
                                                                    category: value ? value : '',
                                                                    category_name: data ? data.children : '',
                                                                    unit_name: ''
                                                                })
                                                                let filteredUnit = _.filter(allData && allData.ven_names, { category_id: Number(value) })
                                                                setVendorlist(filteredUnit)
                                                            }
                                                            else {
                                                                setConsolidatedDate({
                                                                    ...consolidateddata,
                                                                    category: '',
                                                                    category_name: '',
                                                                    unit_name: ''
                                                                })
                                                            }

                                                        }}
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        // onPaste={(e) => {
                                                        //     handlePasting(e, /^[a-z]+$/gi)
                                                        // }}
                                                        onPaste={onPasteToaster}
                                                        searchValue={selectSearchValue.category}
                                                        onSearch={(value) => {
                                                            let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                            setSelectSearchValue({
                                                                ...selectSearchValue,
                                                                category: result
                                                            });
                                                        }}
                                                        placeholder="Enter Category Name"
                                                        style={{ width: '100%', marginTop: '5px' }}

                                                        value={consolidateddata.category_name || undefined}
                                                        disabled={consolidateddata.legel_entity_name == '' ? true : false}

                                                    >
                                                        {categoryList && categoryList.length && categoryList.map((item, i) => {
                                                            return (
                                                                <Option key={item.cat_id}>
                                                                    {item.cat_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                                <div className="col-md-4">
                                                    <label htmlFor=""><b>Division:</b></label><br />
                                                    <Select
                                                    showSearch
                                                        onChange={(value, data) => {
                                                            if (value != undefined) {

                                                                setConsolidatedDate({
                                                                    ...consolidateddata,
                                                                    division: value ? value : '',
                                                                    division_name: data ? data.children : '',
                                                                    category_name: '',
                                                                    unit_name: ''
                                                                })
                                                                let filteredCategory = _.filter(allData && allData.cat_infos, { div_id: Number(value) })
                                                                setCategoryList(filteredCategory)

                                                            }
                                                            else {
                                                                setConsolidatedDate({
                                                                    ...consolidateddata,
                                                                    division: '',
                                                                    division_name: '',
                                                                    category_name: '',
                                                                    unit_name: ''
                                                                })
                                                                // let filteredCategory = _.filter(allData && allData.cat_infos, { div_id: Number(value) })
                                                                setCategoryList([])
                                                                // let filteredUnit = _.filter(allData && allData.ven_names, { division_id: Number(value) })
                                                                setVendorlist([])
                                                            }

                                                        }}
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        // onPaste={(e) => {
                                                        //     handlePasting(e, /^[a-z]+$/gi)
                                                        // }}
                                                        onPaste={onPasteToaster}
                                                        searchValue={selectSearchValue.division}
                                                        onSearch={(value) => {
                                                            let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                            setSelectSearchValue({
                                                                ...selectSearchValue,
                                                                division: result
                                                            });
                                                        }}
                                                        placeholder="Enter Division Name"
                                                        style={{ width: '100%', marginTop: '5px' }}
                                                        allowClear={true}
                                                        value={consolidateddata.division_name || undefined}
                                                        disabled={consolidateddata.legel_entity_name == '' ? true : false}

                                                    >
                                                        {divisionList && divisionList.length && divisionList.map((item, i) => {

                                                            return (
                                                                <Option key={item.div_id}>
                                                                    {item.div_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-md-12' style={{ marginTop: "1%" }}>
                                            <div className='row'>
                                                {/* <div className='col-md-4'></div>
                                            <div className='col-md-4'></div> */}
                                                <div className='col-md-4 popupbtncolour' style={{ marginLeft: '35%' }}>

                                                    <Button type="primary" shape="round" icon={<EyeOutlined />} className={
                                                        "addbutton " + localStorage.getItem("currentTheme")
                                                    } style={{ marginLeft: "39px", marginRight: "10px" }}
                                                        onClick={onshow}
                                                    >
                                                        Show
                                                    </Button>
                                                    <Button icon={<ExportOutlined />} type="primary" shape="round" className={
                                                        "addbutton " + localStorage.getItem("currentTheme")
                                                    } style={{ background: "#32a852", borderColor: "#32a852" }} onClick={showModal}
                                                    >
                                                        Export
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </Panel>
                                </Collapse>
                                {tableshow == true ?
                                    <>
                                        <div>
                                            <div className='ant-table ant-table-bordered ant-table-scroll-horizontal service-provider-table'>
                                                <div className='ant-table-container'>
                                                    <div className='ant-table-content'>
                                                        <table>
                                                            <colgroup>
                                                                <col style={{ width: '10px' }}></col>
                                                            </colgroup>
                                                            <thead className='ant-table-thead '>
                                                                <tr>
                                                                    <th className={'ant-table-cell'} style={{ width: '10px' }}><center>#</center></th>
                                                                    <th className={'ant-table-cell'} style={{ width: '80px' }}>Vendor Code</th>
                                                                    <th className={'ant-table-cell'} style={{ width: '70px' }}>Location</th>
                                                                    <th className={'ant-table-cell'} style={{ width: '70px' }}>Postal Code</th>
                                                                    <th className={'ant-table-cell'} style={{ width: '100px' }}>Domain</th>
                                                                    <th className={'ant-table-cell'} style={{ width: '100px' }}>Organization Type</th>
                                                                    <th className={'ant-table-cell'} style={{ width: '80px' }}>Date of Temporary Closure</th>
                                                                    <th className={'ant-table-cell'} style={{ width: '100px' }}>Temporary Closure Done by</th>
                                                                    <th className={'ant-table-cell'} style={{ width: '100px' }}>Remarks for Temporary Closure</th>
                                                                    <th className={'ant-table-cell'} style={{ width: '70px' }}>Date Reactivated from</th>
                                                                    <th className={'ant-table-cell'} style={{ width: '100px' }}>Reactivated By</th>


                                                                </tr>
                                                            </thead>
                                                            {filteredArrayState && filteredArrayState.length ?
                                                                filteredArrayState.map((item, i) => {


                                                                    return (
                                                                        <tbody className='ant-table-tbody'>
                                                                            <tr className="bg-lightdark">
                                                                            </tr>
                                                                            {/* {titledata && titledata.map((itemdata, i) => {
                                                                                let data = _.find(itemdata.child, { unit_id: item[0].unit_id })

                                                                                if (data != undefined) {
                                                                                    if (data.act_name == itemdata.parent) {
                                                                                        return <Fragment>
                                                                                            <tr className='ant-table-row ant-table-row-level-0'>
                                                                                                <td colSpan={12} className='ant-table-cell'><b>{itemdata.parent}</b></td>
                                                                                            </tr>
                                                                                            {itemdata.child.map((items) => {
                                                                                                if (item[0].unit_id == items.unit_id) {
                                                                                                    let array = []
                                                                                                    for (let i in tableData && tableData.vendor_temporary_closure_report_list && tableData.vendor_temporary_closure_report_list.length && tableData.vendor_temporary_closure_report_list) {
                                                                                                        if (tableData.vendor_temporary_closure_report_list && tableData.vendor_temporary_closure_report_list.length && tableData.vendor_temporary_closure_report_list[i].compliance_id == items.compliance_id) {
                                                                                                            if (tableData.vendor_temporary_closure_report_list && tableData.vendor_temporary_closure_report_list.length && tableData.vendor_temporary_closure_report_list[i].unit_id == items.unit_id) {
                                                                                                                array.push(tableData.vendor_temporary_closure_report_list && tableData.vendor_temporary_closure_report_list.length && tableData.vendor_temporary_closure_report_list[i])
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                    return <tr>
                                                                                                        <td className='ant-table-cell'>
                                                                                                            {index + 1}


                                                                                                        </td>

                                                                                                        <td title={items.compliance_task} className='ant-table-cell'><Tooltip title={items.compliance_description}>
                                                                                                            <ExclamationCircleTwoTone />
                                                                                                        </Tooltip> &nbsp;{array.length > 1 ? <a href='javascript:;' onClick={() => {
                                                                                                            chilData(items.compliance_id, items.unit_id)
                                                                                                        }}>{items.compliance_task}</a> : <a >{items.compliance_task}</a>}</td>
                                                                                                        <td className='ant-table-cell'>{items[" criticality_name"]}</td>
                                                                                                        <td className='ant-table-cell'>{items.frequency}</td>
                                                                                                        <td className='ant-table-cell'><center>{items.due_date}</center></td>
                                                                                                        <td className='ant-table-cell'><center>{items.task_status}</center></td>
                                                                                                        <td className='ant-table-cell'><center>  <>
                                                                                                       

                                                                                                        </></center></td>
                                                                                                        <td className='ant-table-cell'><center>{
                                                                                                                {items[" document_name"]}
                                                                                                            </a>}</center></td>
                                                                                                      
                                                                                                        <span hidden>{index = index + 1}</span>

                                                                                                    </tr>
                                                                                                }

                                                                                            })

                                                                                            }
                                                                                        </Fragment>
                                                                                    }
                                                                                }
                                                                            })
                                                                            } */}
                                                                            {tableData.vendor_temporary_closure_report_list && tableData.vendor_temporary_closure_report_list.map((itemdata, i) => {
                                                                                console.log(itemdata, "itemdata123");


                                                                                // if (data != undefined) {
                                                                                // if (data.act_name == itemdata.parent) {

                                                                                return <Fragment>

                                                                                    <tr>
                                                                                        <td className='ant-table-cell'><center>{index + 1}</center></td>
                                                                                        <td className='ant-table-cell'>{itemdata.unit_code}</td>
                                                                                        <td className='ant-table-cell'>{itemdata.address}</td>
                                                                                        <td className='ant-table-cell'>{itemdata.postal_code}</td>
                                                                                        <td className='ant-table-cell'>{itemdata.domain_name}</td>
                                                                                        <td className='ant-table-cell'>{itemdata.organisation_name}</td>
                                                                                        <td className='ant-table-cell'>{itemdata.vendor_closed_date}</td>
                                                                                        <td className='ant-table-cell'>{itemdata.closed_by}</td>
                                                                                        <td className='ant-table-cell'><center>{itemdata.vendor_closed_remarks}</center></td>
                                                                                        <td className='ant-table-cell'><center>{itemdata.reopen_by}</center></td>
                                                                                        <td className='ant-table-cell'><center>{itemdata.vendor_activation_date}</center></td>
                                                                                        <span hidden>{index = index + 1}</span>

                                                                                    </tr>

                                                                                </Fragment>
                                                                                // }
                                                                                // }
                                                                            })
                                                                            }


                                                                        </tbody>
                                                                    )
                                                                }) :
                                                                <tr>
                                                                    <td colSpan="100%" style={{ paddingTop: '15px', textAlign: 'center', fontSize: '14px', fontWeight: 'bold', paddingBottom: '15px' }}>No Records Found</td>
                                                                </tr>
                                                            }
                                                        </table>
                                                        <br />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="row mt-3">
                                            {index != 0 ?
                                                <div className='col-md-6' >
                                                    <label>Showing {pageSize * (current - 1) + 1} to {index} of {tableData.total_count} entries</label>
                                                </div> : ''}

                                            <div className='col-md-6 text-right'>
                                                <Pagination
                                                    current={current}
                                                    showSizeChanger={true}
                                                    // showQuickJumper={true}
                                                    onShowSizeChange={() => {
                                                        setCurrent(1)
                                                        // setpageState(true)
                                                    }}
                                                    pageSizeOptions={[25, 50, 100]} hideOnSinglePage={tableData.total_count > 25 ? false : true} defaultPageSize={25}
                                                    onChange={(page, pageSizes) => {
                                                        setpageState(true)
                                                        setCurrent(pageSize !== pageSizes ? 1 : page);
                                                        setPageSize(pageSizes)
                                                    }} total={tableData.total_count}
                                                // showTotal={(total) => `Showing 1 to ${index} of ${tableData.total_count} entries`}
                                                />
                                            </div>
                                        </div>

                                    </>
                                    : ""}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >

    )
}

export default connect(mapStateToProps, {
    getData,
    getVendorTempTabledata,
    getVendorTempExport,
})(VendorTempClosureReport);