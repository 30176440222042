import React from 'react'
import { InfoCircleOutlined, PlayCircleOutlined, ExclamationCircleTwoTone, FullscreenOutlined, UpOutlined, EyeOutlined, ExportOutlined, FilterOutlined } from '@ant-design/icons';
import { Collapse, Select, Input, DatePicker, Card, Modal, Table, Button, Tooltip, Pagination, Drawer } from 'antd';
import DataTable from "react-data-table-component";
import { useState, useRef, useEffect, Fragment } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";
import { connect, useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { RiskReportData, getRiskReportData, getRiskExportData, Downloadfile, downloadfiles, downloadDocument } from '../../../Store/Action/Report/RiskReport';
import { AUDIT_TRAIL_REPORT_EXPORT_RESET } from './../../../Store/types/index'
import { fullscreenmode, fullscreenexit, handleKeyDown } from './../../../Libs/fullscreenmode';
import useStickyHeight from "../../../Libs/useStickyHeight";
import { validationType, validateSelect, onPasteToaster } from './../../../Libs/inputValidation';
import ScrollToTop from "react-scroll-to-top";
import ScrollButton from '../../Scrollbar';
import { Toaster } from '../../../Libs/Toaster';
import url, { getUrl } from './../../../Libs/URL'
import { CountrySplit, HomeRedirect } from './../../../Libs/country'
const urls = getUrl()
const uniq_country_data = CountrySplit()
const homepage = HomeRedirect()

const mapStateToProps = (state) => ({
    allData: state.RiskReport.riskReport.data,
    tableData: state.RiskReport.riskReport.getTableData,
    downloadpath: state.RiskReport.riskReport.downpath,
    downloadFile: state.RiskReport.riskReport.downloadFile,
})

const RiskReport = ({
    RiskReportData,
    allData,
    tableData,
    getRiskReportData,
    getRiskExportData,
    Downloadfile,
    downloadfiles,
    downloadDocument,
    downloadFile
}) => {
    console.log(tableData, "tableDatatableData");
    useStickyHeight();
    const historynew = useHistory();
    const _ = require("lodash");
    const is_Fullscreen = useSelector((state) => state.Login.is_fullscreen);
    const dispatch = useDispatch();
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const country_info = sessionParsedValue.country_info
    const sessionArr = sessionParsedValue.entity_info
    const [getreportlist, setGetReportList] = useState([])
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const location = useLocation();
    const paramid = location.pathname;
    const [entity, setEntity] = useState([])
    console.log(entity, "entityentity");
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [tableshow, setTableShow] = useState(false)
    const [titledata, Settitledata] = useState()
    const [key, setKey] = useState("1")
    let [showindex, setshowIndex] = useState(0)
    console.log(showindex, 'showindexshowindex')

    const [consolidateddata, setConsolidatedDate] = useState({
        country: "",
        category: "",
        user_type: "",
        legel_entity: "",
        unit: '',
        user: "",
        domain: "",
        act: "",
        from_date: "",
        task_category: "",
        compliance_task: "",
        to_date: "",
        task_sub_category: "",
        compliance_frequency: "",
        compliance_task_status: "",
        division: "",
        domainName: '',
        countryName: '',
        unit_name: '',
        user_name: '',
        statutory_mapping: '',
        division_name: '',
        compliance_frequency_name: '',
        compliance_task_status_name: '',
        category_name: '',
        user_type_name: '',
        task_status: 'All Exposed Risk',
        legal_entity_name: '',
        bg_id: '',
        bg_name: '',
        legalEnityLabel: '',
        countryLabel: '',
        domainLabel: ''

    })
    const validator = useRef(new SimpleReactValidator());
    const [exportButton, setExportButton] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isTaskHistoryModalOpen, setIsTaskHistoryModalOpen] = useState(false);
    const [domainList, setDomainList] = useState([])
    const [divisionList, setDivisionList] = useState([])
    const [unitCodeLabelList, setUnitCodeLabelList] = useState([])
    const [unitLegalEntity, setUnitLegalEntity] = useState([])
    const [complianceUser, setComplianceUser] = useState([])
    const [complianceUserType, setComplianceUserType] = useState([])
    const [complianceTaskStatus, setComplianceTaskStatus] = useState([])
    const [complianceFrequencyList, setComplianceFrequencyList] = useState([])
    const [categoryList, setCategoryList] = useState([])
    const [actLegalEntity, setActLegalEntity] = useState([])
    const [filteredArray, setFilteredArray] = useState([])
    const [filteredArrayState, setFilteredArrayState] = useState([])
    const [actName, setActName] = useState([])
    const [actNameFilteredState, setActNameFilteredState] = useState([])
    const [finalResult, SetfinalResult] = useState({
        Resultdata: []
    })
    const [exitCollapse, setExitCollapse] = useState(false);
    const [filterTaskAcc, setFilterTaskAcc] = useState(true);
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const SelectedEntity = localStorage.getItem('SelectedEntity')
    const [checkboxValue, setCheckBoxValue] = useState([0,
        1,
        2,
        3,
        8,
        9,
        10,
        11,
        29,
        33])
    const [count, setCount] = useState('')
    const [current, setCurrent] = useState(1)

    const [pageSize, setPageSize] = useState(25)
    console.log(pageSize, "pageSizepageSize");
    const [pageState, setpageState] = useState(false);
    const [dataSource, setDataSource] = useState([])
    const [taskArray, setTaskArray] = useState([])

    const { Panel } = Collapse;
    const { Option } = Select;
    const [bggroup, setBggroup] = useState([]);
    const [selectSearchValue, setSelectSearchValue] = useState({
        country: '',
        legalEntity: '',
        BusinessGroup: '',
        domain: '',
        actRules: '',
        complianceTask: '',
        user: '',
        division: '',
        category: '',
        unit: '',
    });

    useEffect(() => {
        if (sessionArr && sessionArr.length > 0) {
            let unique = _.uniqBy(sessionArr, function (e) {
                return e.bg_id;
            });
            setBggroup(unique)
        }
    }, [])
    const ComplianceTaskName = e => {
        const { value } = e.target;
        // onChange={(e) => {
        let chk_valid = ''

        for (let i = 0; i < value.length; i++) {
            if (value[i] == '<' || value[i] == '>') {
                chk_valid += value[i]
            }
        }
        if (chk_valid.match('<>')) {
            //   UpdatePannelRemarks[collapseId] = ''
            setConsolidatedDate({
                ...consolidateddata,
                compliance_task: ''
            })
            Toaster.error('Please check the input, it having some invalid characters')
        } else {

            const re = /[^|~^]+$/;
            if (value === "" || re.test(value)) {
                if (value.includes('~') || value.includes('^') || value.includes('|')) {
                    return false
                } else {
                    setConsolidatedDate({
                        ...consolidateddata,
                        compliance_task: e.target.value
                    })
                }
            }
        }
    }
    // const ComplianceTaskName = e => {
    //     const { value } = e.target;
    //     const re = /^[A-Z a-z 0-9 ,.-_]*$/;
    //     console.log(re.test(value), value, 'retest');
    //     if (re.test(value)) {
    //         // setErrorField(true)
    //         setConsolidatedDate({
    //             ...consolidateddata,
    //             compliance_task: e.target.value
    //         })
    //     }
    // }

    const [fullscreen, setfullscreen] = useState(false);
    const filterHandle = () => {
        setFilterTaskAcc(true);
        setFilterModalVisible(true);
    };
    const setcancelFilter = () => {
        setFilterModalVisible(false);
        setFilterTaskAcc(false);
    };

    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
            document.addEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch), false);
            return () => {
                document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch));
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode(dispatch);
            setfullscreen(true);
        } else {
            fullscreenexit(dispatch);
            setfullscreen(false);
        }
    }

    const onshow = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            Settitledata([])
            setFilteredArrayState([])
            setFilteredArrayState([])
            setCurrent(1);
            setpageState(false)
            setPageSize(25)
            setshowIndex(0)
            setFilterModalVisible(false);
            setConsolidatedDate({
                ...consolidateddata,
                legalEnityLabel: consolidateddata.legal_entity_name,
                countryLabel: consolidateddata.countryName,
                domainLabel: consolidateddata.domainName
            })
            setCount('')
            setExitCollapse(true);
            setFilterTaskAcc(false);
            setTableShow(true)
            setKey(0)
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetRiskReportData",
                        {
                            "country_id": Number(consolidateddata.country),
                            "business_group_id": Number(consolidateddata.bg_id) ? Number(consolidateddata.bg_id) : 1,
                            "legal_entity_id": consolidateddata.legel_entity,
                            "domain_id": Number(consolidateddata.domain),
                            "division_id": Number(consolidateddata.division),
                            "category_id": Number(consolidateddata.category) ? Number(consolidateddata.category) : 0,
                            "unit_id": Number(consolidateddata.unit) ? Number(consolidateddata.unit) : 0,
                            "parent_id": Number(consolidateddata.act) ? Number(consolidateddata.act) : 0,
                            "compliance_task": consolidateddata.compliance_task ? consolidateddata.compliance_task : null,
                            "task_status": consolidateddata.task_status,
                            "csv": false,
                            "from_count": 0,
                            "page_count": 25,
                            "list_check": null
                        }
                    ]
                }
            ]
            console.log(payload, 'payload');
            getRiskReportData({
                payload: payload,
                paramid: paramid
            })
        }
    }

    const columns = [
        {
            title: 'Start Date',
            // ellipsis: true,
            width: '70px',
            // dataIndex: 'start_date',
            // key: 'start_date',
            render: (record) => {
                console.log(record, 'record');
                return <p style={{ textAlign: 'center' }}>{record.start_date ? record.start_date : '-'}</p>
            }
        },
        {
            title: 'Due Date',
            // ellipsis: true,
            // selector: (row) => row.compliance_task,
            width: '70px',
            // dataIndex: 'due_date',
            // key: 'due_date',
            render: (record) => {
                console.log(record, 'record');
                return <p style={{ textAlign: 'center' }}>{record.due_date ? record.due_date : '-'}</p>
            }
        },
        {
            title: "Assignee",
            width: '60px',
            align: 'center',
            render: (text, record) => {
                // if(record.task_status == 'Delayed Compliance'){
                //     return <><span style={{ textAlign: 'center' }}>{record.assignee}</span> <br/><span>{record.assigned_on}</span></>
                // }else{
                //     return <span style={{ textAlign: 'center' }}>{record.assignee ? record.assignee : '-'}</span>
                // }
                // return <> <span style={{ textAlign: 'center' }}>{record.assignee}</span> <br/><span>{record.assigned_on}</span></>
                return <>{record.assignee ? <><span style={{ textAlign: 'center' }}>{record.assignee}</span> <br /><span>{record.assigned_on}</span></> : <span>-</span>}</>

            }
        },
        {
            title: "Concurr",
            width: '50px',
            align: 'center',
            render: (record) => {
                // if(record.task_status == 'Delayed Compliance'){

                return <>{record.concurr ? <><span style={{ textAlign: 'center' }}>{record.concurr}</span> <br /><span>{record.concurred_on}</span></> : <span>-</span>}</>

                // }else{}

                //     return <span style={{ textAlign: 'center' }}>{record.concurr ? record.concurr : '-'}</span>
                // }                
            }
        },
        {
            title: "Approver",
            width: '50px',
            align: 'center',
            render: (record) => {
                // if(record.task_status == 'Delayed Compliance'){
                // return <><span style={{ textAlign: 'center' }}>{record.approver}</span> <br/><span>{record.approved_on}</span></>
                return <>{record.approver ? <><span style={{ textAlign: 'center' }}>{record.approver}</span> <br /><span>{record.approved_on}</span></> : <span>-</span>}</>

                // }else{
                //     return <span style={{ textAlign: 'center' }}>{record.approver ? record.approver : '-'}</span>
                // }      

            }
        },
        {
            title: "Remarks",
            width: '50px',
            // ellipsis: true,
            // dataIndex: 'remarks',
            // key: 'remarks',
            render: (record) => {
                console.log(record, 'record12345');
                return <p style={{ textAlign: 'center' }}>{record.remarks}</p>
            }
        },
    ];


    const showModal = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            setIsModalOpen(true);
        }
    };

    const handleOk = () => {
        const remarks = checkboxValue.includes(34);
        const myArray = checkboxValue;
        let status = myArray.includes(0);
        if (!status) {
            myArray.unshift(0);
        }

        if (remarks) {
            const index = myArray.indexOf(34);
            const x = myArray.splice(index, 1);
        }
        setCheckBoxValue(myArray);

        setAddFormSubmit(true)
        setExportButton(true)
        if (validator.current.allValid()) {
            setTableShow(true)
            setKey(0)
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetRiskReportData",
                        {
                            "country_id": Number(consolidateddata.country),
                            "business_group_id": Number(consolidateddata.bg_id),
                            "legal_entity_id": entityid,
                            "domain_id": Number(consolidateddata.domain),
                            "division_id": Number(consolidateddata.division),
                            "category_id": Number(consolidateddata.category) ? Number(consolidateddata.category) : 0,
                            "unit_id": Number(consolidateddata.unit) ? Number(consolidateddata.unit) : 0,
                            "parent_id": Number(consolidateddata.act) ? Number(consolidateddata.act) : 0,
                            "compliance_task": consolidateddata.compliance_task ? consolidateddata.compliance_task : null,
                            "task_status": consolidateddata.task_status ? consolidateddata.task_status : 'All Exposed Risk',
                            "csv": true,
                            "from_count": 0,
                            "page_count": 25,
                            "list_check": checkboxValue
                        }
                    ]
                }
            ]
            console.log('PayloAd', payload);
            getRiskExportData({
                payload: payload,
                paramid: paramid
            })
        }
        setIsModalOpen(false);
        setCheckBoxValue([0,
            1,
            2,
            3,
            8,
            9,
            10,
            11,
            29,
            33])
    };

    const handleTaskHistoryOk = () => {
        setIsTaskHistoryModalOpen(false);
    }

    const handleCancel = () => {
        setIsModalOpen(false);
        setCheckBoxValue([0,
            1,
            2,
            3,
            8,
            9,
            10,
            11,
            29,
            33])
    };

    const showTaskHistoryModal = () => {
        setIsTaskHistoryModalOpen(true);
    };

    const handleTaskHistoryCancel = () => {
        setIsTaskHistoryModalOpen(false);
    };


    const onChange = (key) => {
        console.log(key);
    };

    const checkBoxOnChange = (e) => {
        let temp = checkboxValue
        let tempIds = []
        if (temp.includes(Number(e.target.value)) == true) {
            for (let i in temp) {
                if (temp[i] == Number(e.target.value)) {
                    temp.splice(i, 1)
                }
            }
            setCheckBoxValue([...temp])
        } else {
            tempIds.push(Number(e.target.value))
            setCheckBoxValue([...checkboxValue, ...tempIds])
        }
    }

    const selectAllCheckBox = (checked) => {
        var ele = document.getElementsByName('chk');
        let tempAllIds = []
        if (checked == true) {
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == 'checkbox') {
                    ele[i].checked = true;
                    tempAllIds.push(Number(ele[i].value))
                }
            }
            setCheckBoxValue(tempAllIds)
        }
        else {
            var ele = document.getElementsByName('chk');
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == 'checkbox')
                    ele[i].checked = false;

            }
            setCheckBoxValue([0,
                1,
                2,
                3,
                8,
                9,
                10,
                11,
                29,
                33])
        }
    }

    useEffect(() => {
        validator.current.showMessages()
    }, [])

    useEffect(() => {
        if (tableData && tableData.total_count > 0) {
            setCount(tableData.total_count)
        }
    }, [tableData])

    // useEffect(() => {
    //     if (downloadpath) {
    //         const path = [
    //             authtoken,
    //             {
    //                 "session_token": authtoken,
    //                 "request": [
    //                     "GetStatus",
    //                     {
    //                         "legal_entity_id": entityid,
    //                         "csv_name": downloadpath.csv_name
    //                     }
    //                 ]
    //             }
    //         ]
    //         downloadfiles({
    //             payload: path,
    //             paramid: paramid
    //         })
    //     }
    // }, [downloadpath])

    useEffect(() => {
        if (tableData && tableData.risk_report && tableData.risk_report.length > 0) {
            let uniqueObjArray = [...new Map(tableData.risk_report && tableData.risk_report.length && tableData.risk_report.map((item) => [item["unit_id"], item])).values()];
            const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                return item.unit_id
            })
            let tempArr = []
            for (let i in uniqueIds) {
                let filteredArr = _.filter(tableData.risk_report, { unit_id: uniqueIds[i] })
                tempArr.push(filteredArr)
                setFilteredArray([...filteredArray, tempArr])
            }
        }
    }, [tableData.risk_report])

    useEffect(() => {
        // setTempeditdata(editdata)
        let array = [];
        let finalarray = [];
        let datavalue = [];
        if (tableData && tableData.risk_report && tableData.risk_report.length && tableData.risk_report) {
            const ab = tableData && tableData.risk_report && tableData.risk_report.length && tableData.risk_report.map(function (item) {
                if ((array.indexOf(item.statutory_mapping) < 0))
                    array.push(item.statutory_mapping)
            })
            array.map((data, i) => {
                let temp = [];
                let entityArray = [];
                let datavalue = [];
                if (data) {
                    tableData && tableData.risk_report && tableData.risk_report.length && tableData.risk_report.map((child, childIndex) => {
                        if (data === child.statutory_mapping) {
                            entityArray = {
                                "Criticality": child.Criticality,
                                "admin_incharge": child.admin_incharge,
                                "approved_on": child.approved_on,
                                "approver_name": child.approver_name,
                                "assigned_on": child.assigned_on,
                                "assignee_name": child.assignee_name,
                                "category_name": child.category_name,
                                "comp_remarks": child.comp_remarks,
                                "compliance_description": child.compliance_description,
                                "compliance_task": child.compliance_task,
                                "concurred_on": child.concurred_on,
                                "concurrer_name": child.concurrer_name,
                                "division_name": child.division_name,
                                "doc_ref_num": child.doc_ref_num,
                                "document_name": child.document_name,
                                "due_date": child.due_date,
                                "extend_due_date": child.extend_due_date,
                                "frequency_name": child.frequency_name,
                                "logo_url": child.logo_url,
                                "penal_consequences": child.penal_consequences,
                                "start_date": child.start_date,
                                "statutory_mapping": child.statutory_mapping,
                                "task_status": child.task_status,
                                "tz_name": child.tz_name,
                                "unit_address": child.unit_address,
                                "unit_id": child.unit_id,
                                "unit_name": child.unit_name,
                                "url": child.url,
                            }
                            temp.push(entityArray)
                        }
                    })
                }
                datavalue = {
                    index: i,
                    parent: data,
                    child: temp
                }
                finalarray.push(datavalue)
            })
            Settitledata(finalarray)

        }

    }, [tableData && tableData.risk_report && tableData.risk_report.length && tableData.risk_report])

    useEffect(() => {
        if (filteredArray && filteredArray.length > 0) {
            filteredArray && filteredArray.map((item, i) => {
                return (
                    setFilteredArrayState(item)
                )
            })
        }
    }, [filteredArray])

    useEffect(() => {
        if (downloadFile && downloadFile.link) {
            window.open(urls + '/' + downloadFile.link);
            dispatch({
                type: AUDIT_TRAIL_REPORT_EXPORT_RESET
            });
        }

    }, [downloadFile])


    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') != 'All Legal Entity') {
            let temp = localStorage.getItem("SelectedEntity")
            let businessName = _.filter(sessionArr, { le_name: temp })
            let tempcountry = _.filter(sessionArr, { le_name: temp })
            setConsolidatedDate({
                ...consolidateddata,
                legel_entity: localStorage.getItem('SelectedEntityid'),
                country: tempcountry && tempcountry.length > 0 ? tempcountry[0].c_id : '',
                countryName: tempcountry && tempcountry.length > 0 ? tempcountry[0].c_name : '',
                bg_id: businessName[0].bg_id,
                bg_name: businessName[0].bg_name
            })
        }
    }, [localStorage.getItem('SelectedEntity') != 'All Legal Entity'])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntityid') != null) {
            setCurrentEntity(localStorage.getItem('SelectedEntityid'))
        }
        else {
            setCurrentEntity(consolidateddata.legel_entity)
        }
    }, [localStorage.getItem('SelectedEntityid')])

    useEffect(() => {
        if (isAuth) {
            if (entityid != 'null') {
                let temp = localStorage.getItem("SelectedEntity")
                let tempcountry = _.filter(sessionArr, { le_name: temp })
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetRiskReportFilters",
                            {
                                "country_id": consolidateddata.country ? Number(consolidateddata.country) : tempcountry && tempcountry[0].c_id,
                                "business_group_id": Number(consolidateddata.bg_id),
                                "legal_entity_id": entityid
                            }
                        ]
                    }
                ]

                RiskReportData({
                    payload: payload,
                    paramid: paramid
                })
            }
        }
    }, [isAuth, authtoken, paramid, entityid])

    useEffect(() => {

        if (pageState == true) {
            console.log(Math.max(((current - 1) * pageSize), 0) + 1, "11111111111111");
            console.log(current != 0 ? current * pageSize : pageSize);
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetRiskReportData",
                        {
                            "country_id": Number(consolidateddata.country),
                            "business_group_id": Number(consolidateddata.bg_id),
                            "legal_entity_id": entityid,
                            "domain_id": Number(consolidateddata.domain),
                            "division_id": Number(consolidateddata.division),
                            "category_id": Number(consolidateddata.category) ? Number(consolidateddata.category) : 0,
                            "unit_id": Number(consolidateddata.unit) ? Number(consolidateddata.unit) : 0,
                            "parent_id": Number(consolidateddata.act) ? Number(consolidateddata.act) : 0,
                            "compliance_task": consolidateddata.compliance_task ? consolidateddata.compliance_task : null,
                            "task_status": consolidateddata.task_status,
                            "csv": false,
                            "from_count": Math.max(((current - 1) * pageSize), 0),
                            "page_count": pageSize == 50 ? 50 : pageSize == 100 ? 100 : 25,
                            "list_check": null
                        }
                    ]
                }
            ]
            getRiskReportData({
                payload: payload,
                paramid: paramid
            })
        }

    }, [pageState, pageSize, current])

    useEffect(() => {
        if (pageState === true) {
            setshowIndex(pageSize * (current - 1))
        }
    }, [pageState, pageSize, current])

    useEffect(() => {
        if (allData) {
            console.log(allData, "allDataallData");
            setActLegalEntity(allData && allData.act_legal_entity)
            setComplianceFrequencyList(allData && allData.compliance_frequency_list)
            setComplianceTaskStatus(allData && allData.compliance_task_status)
            setUnitCodeLabelList(allData && allData.unit_code_label_list)
            setDomainList(allData && allData.domains)
            // setComplianceUserType(allData && allData.compliance_user_type)
            // setComplianceUser(allData && allData.compliance_users)
        }

    }, [allData])

    const homescreen = () => {
        historynew.push(homepage);
        // window.location.href = homepage
        document.getElementById('homehover').style.color = "blue"
    }
    const handlePasting = (e, regex) => {

        var regex = regex;
        var key = e.clipboardData.getData('text')
        if (!regex.test(key)) {
            e.preventDefault();
            Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
            return false;
        }
        // e.clipboardData.getData('text')
        // Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
        // e.preventDefault();
        // return false;
    }

    const downloaddocument = (unit_id, start_date, document_name) => {
        let result = document_name.replaceAll(", ", "");
        const pay = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "DownloadFile",
                    {
                        "le_id": consolidateddata.legel_entity,
                        "c_id": Number(consolidateddata.country),
                        "d_id": Number(consolidateddata.domain),
                        "u_id": unit_id,
                        "start_date": start_date,
                        "file_name": document_name
                    }
                ]
            }
        ]
        downloadDocument({
            payload: pay,
            paramid: paramid
        })
    }
    const [selectAllText, setSelectAllText] = useState('Select All')

    useEffect(() => {
        if (checkboxValue.length == 34) {
            setSelectAllText('Unselect All')
        } else {
            setSelectAllText('Select All')
        }
    }, [checkboxValue])

    const getDomainBasedRecords = (domainId) => {
        if (domainId != undefined) {
            setDivisionList(allData && allData.divisions)
            setCategoryList(allData && allData.categories)
            let domainArray = [];
            let tempArray = [];
            allData && allData.units_list.length > 0 && allData.units_list.map((unitsublist) => {
                const checkUnitExists = unitsublist.d_ids.includes(parseInt(domainId));
                if (checkUnitExists) {
                    tempArray = {
                        'unit_id': unitsublist.unit_id,
                        'unit_name': unitsublist.unit_name,
                        'unit_code': unitsublist.unit_code
                    }
                    domainArray.push(tempArray)
                }
            })
            setUnitLegalEntity(domainArray)

        } else {
            setDivisionList([])
            setCategoryList([])
            setUnitLegalEntity([])

        }

    }

    const getDivisionBasedRecords = (divisionId) => {
        let domainArray = [];
        let tempArray = [];
        if (divisionId != undefined) {

            let getCategory = _.filter(allData && allData.categories, { division_id: parseInt(divisionId) })
            setCategoryList(getCategory)
            allData && allData.units_list.length > 0 && allData.units_list.map((unitsublist) => {
                const checkUnitExists = unitsublist.d_ids.includes(parseInt(consolidateddata.domain));
                if (checkUnitExists && unitsublist.division_id == parseInt(divisionId)) {
                    tempArray = {
                        'unit_id': unitsublist.unit_id,
                        'unit_name': unitsublist.unit_name,
                        'unit_code': unitsublist.unit_code
                    }
                    domainArray.push(tempArray)
                }
            })
            setUnitLegalEntity(domainArray)
        } else {
            allData && allData.units_list.length > 0 && allData.units_list.map((unitsublist) => {
                const checkUnitExists = unitsublist.d_ids.includes(parseInt(consolidateddata.domain));
                if (checkUnitExists) {
                    tempArray = {
                        'unit_id': unitsublist.unit_id,
                        'unit_name': unitsublist.unit_name,
                        'unit_code': unitsublist.unit_code
                    }
                    domainArray.push(tempArray)
                }
            })
            setUnitLegalEntity(domainArray)
            setCategoryList(allData && allData.categories)
        }
    }

    const getCategoryBasedRecords = (categoryId) => {
        let domainArray = [];
        let tempArray = [];
        if (categoryId != undefined) {
            allData && allData.units_list.length > 0 && allData.units_list.map((unitsublist) => {
                const checkUnitExists = unitsublist.d_ids.includes(parseInt(consolidateddata.domain));
                if (checkUnitExists && unitsublist.category_id == parseInt(categoryId)) {
                    tempArray = {
                        'unit_id': unitsublist.unit_id,
                        'unit_name': unitsublist.unit_name,
                        'unit_code': unitsublist.unit_code
                    }
                    domainArray.push(tempArray)
                }
            })
            setUnitLegalEntity(domainArray)
        } else {
            allData && allData.units_list.length > 0 && allData.units_list.map((unitsublist) => {
                const checkUnitExists = unitsublist.d_ids.includes(parseInt(consolidateddata.domain));
                if (checkUnitExists) {
                    tempArray = {
                        'unit_id': unitsublist.unit_id,
                        'unit_name': unitsublist.unit_name,
                        'unit_code': unitsublist.unit_code
                    }
                    domainArray.push(tempArray)
                }
            })
            setUnitLegalEntity(domainArray)
        }
    }

    return (
        <div id='page-wrapper' className="page-wrapper">
            {/* <ScrollToTop smooth color="red" component={<Tooltip title="Back to top"><UpOutlined /></Tooltip>} />
            {document.body.scrollHeight > document.body.clientHeight ?
                <div className='back-to-top'>
                    <ScrollButton />
                </div> : ''} */}
            {tableshow == true ?
                <div className='back-to-top'>
                    <ScrollButton />
                </div> : ''}
            <div className="page-titles pb-2 pt-1 page-title-sticky">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <span className='pointer' id='homehover' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <span style={{ fontSize: '14px', fontWeight: "bold" }}>Report</span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: '15px', fontWeight: 'bold' }}>
                                    <span style={{ fontSize: "15px", fontWeight: "bold" }}>Compliance Reports</span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                    <span>Risk Report</span>
                                </li>
                            </ol>
                        </nav>

                    </div>
                    <div className="col-lg-6 text-end">
                        <Tooltip title={'Advance Filter'}><Button
                            className="mx-1"
                            type="primary"
                            shape="round"
                            style={{
                                display: filterTaskAcc ? "none" : "initial",
                            }}
                            onClick={filterHandle}
                        >
                            <FilterOutlined /> Filter
                        </Button>
                        </Tooltip>
                        <button onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black ms-2 full-mode fullscreenradius" >
                            <Tooltip placement="left" title={is_Fullscreen == true ? "Exit Full Screen Mode" : "Full Screen Mode"}><FullscreenOutlined /></Tooltip>
                        </button>
                    </div>
                </div>
            </div>

            <Drawer title="Select Fields" width={500} className={'add-service-prv export-checkbox resize-drawer ' + localStorage.getItem('currentTheme')} open={isModalOpen} footer={null} onClose={handleCancel} onCancel={handleCancel} maskClosable={false}>
                <div className='d-flex flex-wrap'>
                    <input id="select_all" width="100%" type="checkbox" autocomplete="off"
                        checked={checkboxValue.length === 34 ? true : false} Periodicity
                        onClick={(e) => {
                            let checked = e.target.checked
                            selectAllCheckBox(checked)
                        }} /> <label for='select_all'>{selectAllText}</label>

                    <input id="column_1" name='chk' type="checkbox" disabled="disabled" value='1' checked onChange={(e) => (e) => checkBoxOnChange(e)} /><label className='cursornotallow' for='column_1'>  Legal Entity</label>
                    <input id="column_2" name='chk' type="checkbox" disabled="disabled" value='2' checked onChange={(e) => checkBoxOnChange(e)} /><label className='cursornotallow' for='column_2'> Unit Code</label>


                    <input id="column_3" name='chk' type="checkbox" disabled="disabled" value='3' checked onChange={(e) => checkBoxOnChange(e)} /><label className='cursornotallow' for='column_3'> Unit Name</label>
                    <input id="column_8" name='chk' type="checkbox" value='8' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /><label className='cursornotallow' for='column_8'>  Location</label>
                    <input id="column_9" name='chk' type="checkbox" value='9' disabled="disabled" checked onChange={(e) => checkBoxOnChange(e)} /><label className='cursornotallow' for='column_9'>  Act/Rules </label>
                    <input id="column_10" name='chk' type="checkbox" value='10' disabled="disabled" checked onChange={(e) => checkBoxOnChange(e)} /><label className='cursornotallow' for='column_10'>  Compliance Task</label>
                    <input id="column_11" name='chk' type="checkbox" value='11' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /><label className='cursornotallow' for='column_11'> Frequency</label>
                    <input id="column_29" name='chk' type="checkbox" value='29' checked onChange={(e) => checkBoxOnChange(e)} /><label className='cursornotallow' for='column_29'> Validity Date</label>
                    <input id="column_33" checked disabled="disabled" name='chk' type="checkbox" value='33' onChange={(e) => checkBoxOnChange(e)} /><label className='cursornotallow' for='column_33'>   Penal Consequences </label>

                    <input id="column_34" checked disabled="disabled" name='chk' type="checkbox" value='34' onChange={(e) => checkBoxOnChange(e)} /><label className='cursornotallow' for='column_34'>   Remarks </label>
                    <input id="column_4" name='chk' type="checkbox" checked={checkboxValue.includes(4)} value='4' onChange={(e) => checkBoxOnChange(e)} /><label for='column_4'> Unit Code 1 </label>
                    <input id="column_5" name='chk' type="checkbox" value='5' checked={checkboxValue.includes(5)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_5'>  Unit Code 2 </label>

                    <input id="column_6" name='chk' type="checkbox" value='6' checked={checkboxValue.includes(6)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_6'>  Unit Code 3 </label>
                    <input id="column_7" name='chk' type="checkbox" value='7' checked={checkboxValue.includes(7)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_7'>  Unit Code 4 </label>




                    <input id="column_12" name='chk' type="checkbox" value='12' checked={checkboxValue.includes(12)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_12'>  Periodicity</label>
                    <input id="column_13" name='chk' type="checkbox" value='13' checked={checkboxValue.includes(13)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_13'>   Criticality </label>
                    <input id="column_14" name='chk' type="checkbox" value='14' checked={checkboxValue.includes(14)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_14'>  Division</label>
                    <input id="column_15" name='chk' type="checkbox" value='15' checked={checkboxValue.includes(15)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_15'> Category </label>

                    <input id="column_16" name='chk' type="checkbox" value='16' checked={checkboxValue.includes(16)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_16'>  Assigned by </label>
                    <input id="column_17" name='chk' type="checkbox" value='17' checked={checkboxValue.includes(17)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_17'>  Assigned To </label>
                    <input id="column_18" name='chk' type="checkbox" value='18' checked={checkboxValue.includes(18)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_18'>  Assigned Date</label>
                    <input id="column_19" name='chk' type="checkbox" value='19' checked={checkboxValue.includes(19)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_19'>  Assignee </label>


                    <input id="column_20" name='chk' type="checkbox" value='20' checked={checkboxValue.includes(20)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_20'> Completed on </label>
                    <input id="column_21" name='chk' type="checkbox" value='21' checked={checkboxValue.includes(21)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_21'>  Concurrer </label>
                    <input id="column_22" name='chk' type="checkbox" value='22' checked={checkboxValue.includes(22)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_22'> Concurred on</label>
                    <input id="column_23" name='chk' type="checkbox" value='23' checked={checkboxValue.includes(23)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_23'>  Approver  </label>

                    <input id="column_24" name='chk' type="checkbox" value='24' checked={checkboxValue.includes(24)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_24'> Approved on</label>
                    <input id="column_25" name='chk' type="checkbox" value='25' checked={checkboxValue.includes(25)} onChange={(e) => checkBoxOnChange(e)} /> <label for='column_25'>  Start Date </label>
                    <input id="column_26" name='chk' type="checkbox" value='26' checked={checkboxValue.includes(26)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_26'>   Due Date</label>
                    <input id="column_27" name='chk' type="checkbox" value='27' checked={checkboxValue.includes(27)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_27'> Extend Due Date</label>

                    <input id="column_28" name='chk' type="checkbox" value='28' checked={checkboxValue.includes(28)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_28'>  Task Completion Date / Document Issued Date </label>

                    <input id="column_30" name='chk' type="checkbox" checked={checkboxValue.includes(30)} value='30' onChange={(e) => checkBoxOnChange(e)} /><label for='column_30'>   Compliance Task Status</label>
                    <input id="column_31" name='chk' type="checkbox" value='31' checked={checkboxValue.includes(31)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_31'>  Duration</label>

                    <input id="column_32" name='chk' type="checkbox" value='32' checked={checkboxValue.includes(32)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_32'>  Document Reference Number </label>


                </div>
                <div className="form-actions popupbtncolour text-center">
                    <Button type="primary" shape="round" className={'addbutton mt-3 ' + localStorage.getItem('currentTheme')}
                        icon={<PlayCircleOutlined />} size='default' onClick={handleOk}> Submit
                    </Button>

                </div>
            </Drawer>
            <Modal title="Task History" className={'add-service-prv ' + localStorage.getItem('currentTheme')} open={isTaskHistoryModalOpen} onOk={handleTaskHistoryOk} onCancel={handleTaskHistoryCancel} width={100} footer={null} maskClosable={false}>
                <Table
                    className='userprivclass'
                    columns={columns}
                    dataSource={dataSource}
                    locale={{ emptyText: <b style={{ color: "black" }}>{'No Records Found'}</b> }}

                    bordered
                    // scroll={{ x: 1500 }}
                    pagination={false} />
            </Modal>
            <div className="container-fluid pt-0">
                <div className="row">
                    <div className="col-lg-12">
                        <div className={localStorage.getItem("currentTheme") + ' card-body1-statuatory'} style={{ position: 'relative', padding: "10px 3px" }}>
                            <Collapse className='report-collapse' style={{ display: exitCollapse ? "none" : "block" }} defaultActiveKey={["1"]} onChange={onChange} >
                                <Panel header=" Risk Report" key={key}>
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-4">
                                                <label htmlFor=""><b>Country : </b><span style={{ color: "red" }}>*</span></label>
                                                <div className="form-group">
                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                placeholder="Enter Country"
                                                                onChange={(value, data) => {
                                                                    if (value != undefined) {
                                                                        let le = _.filter(sessionArr, { c_id: Number(value) })
                                                                        setEntity(le)
                                                                    }
                                                                    setConsolidatedDate({
                                                                        ...consolidateddata,
                                                                        country: value != undefined ? value : '',
                                                                        countryName: data != undefined ? data.children : '',
                                                                        category: "",
                                                                        user_type: "",
                                                                        legel_entity: "",
                                                                        unit: '',
                                                                        user: "",
                                                                        domain: "",
                                                                        act: "",
                                                                        from_date: "",
                                                                        task_category: "",
                                                                        compliance_task: "",
                                                                        to_date: "",
                                                                        task_sub_category: "",
                                                                        compliance_frequency: "",
                                                                        compliance_task_status: "",
                                                                        division: "",
                                                                        domainName: '',
                                                                        unit_name: '',
                                                                        user_name: '',
                                                                        statutory_mapping: '',
                                                                        division_name: '',
                                                                        compliance_frequency_name: '',
                                                                        compliance_task_status_name: '',
                                                                        category_name: '',
                                                                        user_type_name: '',
                                                                        task_status: 'All Exposed Risk',
                                                                        legal_entity_name: localStorage.getItem('SelectedEntity') === "All Legal Entity" ? '' : localStorage.getItem('SelectedEntity'),
                                                                        bg_name: '',
                                                                        bg_id: ''
                                                                    })
                                                                }}
                                                                onDrop={(e) => {
                                                                    e.preventDefault();
                                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                    return false;
                                                                }}
                                                                // onPaste={(e) => {
                                                                //     handlePasting(e, /^[a-z 0-9 - ()]+$/gi)
                                                                // }}
                                                                onPaste={onPasteToaster}
                                                                searchValue={selectSearchValue.country}
                                                                onSearch={(value) => {
                                                                    let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                                                    setSelectSearchValue({
                                                                        ...selectSearchValue,
                                                                        country: result
                                                                    });
                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                //value={Settings.le_id}
                                                                style={{ width: '100%', marginTop: '2px' }}

                                                                value={consolidateddata.countryName || undefined}
                                                            >
                                                                {uniq_country_data && uniq_country_data.length > 0 && uniq_country_data.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.c_id}>
                                                                            {item.c_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                            {/* {validator.current.message(
                                                                'legalentity',
                                                                consolidateddata.country,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Country Required',
                                                                    }
                                                                })} */}
                                                        </div> :
                                                        <p>{consolidateddata.countryName}</p>
                                                    }
                                                </div>
                                                {validator.current.message(
                                                    'country',
                                                    consolidateddata.country,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Country Required',
                                                        }
                                                    })}
                                            </div>
                                            <div className="col-md-4">
                                                <label><b>Business Group:</b></label><br />
                                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Enter Business Group Name"
                                                            onChange={(value, data) => {
                                                                console.log(value, data, "valueeeeeeeedata");
                                                                if (value == undefined) {
                                                                    setConsolidatedDate({
                                                                        ...consolidateddata,
                                                                        legel_entity: '',
                                                                        legal_entity_name: '',
                                                                        category: "",
                                                                        user_type: "",
                                                                        unit: '',
                                                                        user: "",
                                                                        domain: "",
                                                                        act: "",
                                                                        from_date: "",
                                                                        task_category: "",
                                                                        compliance_task: "",
                                                                        to_date: "",
                                                                        task_sub_category: "",
                                                                        compliance_frequency: "",
                                                                        compliance_task_status: "",
                                                                        division: "",
                                                                        domainName: '',
                                                                        unit_name: '',
                                                                        user_name: '',
                                                                        statutory_mapping: '',
                                                                        division_name: '',
                                                                        compliance_frequency_name: '',
                                                                        compliance_task_status_name: '',
                                                                        category_name: '',
                                                                        user_type_name: '',
                                                                        task_status: 'All Exposed Risk',
                                                                        bg_id: '',
                                                                        bg_name: ''
                                                                    })
                                                                    let le = _.filter(sessionArr, { c_id: Number(consolidateddata.country) })
                                                                    setEntity(le)
                                                                } else {
                                                                    setConsolidatedDate({
                                                                        ...consolidateddata,
                                                                        legel_entity: '',
                                                                        legal_entity_name: '',
                                                                        category: "",
                                                                        user_type: "",
                                                                        unit: '',
                                                                        user: "",
                                                                        domain: "",
                                                                        act: "",
                                                                        from_date: "",
                                                                        task_category: "",
                                                                        compliance_task: "",
                                                                        to_date: "",
                                                                        task_sub_category: "",
                                                                        compliance_frequency: "",
                                                                        compliance_task_status: "",
                                                                        division: "",
                                                                        domainName: '',
                                                                        unit_name: '',
                                                                        user_name: '',
                                                                        statutory_mapping: '',
                                                                        division_name: '',
                                                                        compliance_frequency_name: '',
                                                                        compliance_task_status_name: '',
                                                                        category_name: '',
                                                                        user_type_name: '',
                                                                        task_status: 'All Exposed Risk',
                                                                        bg_id: value ? value : '',
                                                                        bg_name: data ? data.children : ''
                                                                    })
                                                                    let le = _.filter(sessionArr, { bg_id: Number(value) })
                                                                    setEntity(le)


                                                                }
                                                            }}
                                                            onDrop={(e) => {
                                                                e.preventDefault();
                                                                // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                return false;
                                                            }}
                                                            // onPaste={(e) => {
                                                            //     handlePasting(e, /^[a-z 0-9 ()]+$/gi)
                                                            // }}
                                                            onPaste={onPasteToaster}
                                                            searchValue={selectSearchValue.BusinessGroup}
                                                            onSearch={(value) => {
                                                                let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                                setSelectSearchValue({
                                                                    ...selectSearchValue,
                                                                    BusinessGroup: result
                                                                });
                                                            }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            value={consolidateddata.bg_name || undefined}
                                                            disabled={consolidateddata.country === '' ? true : false}
                                                            style={{ width: '100%', marginTop: '2px' }}

                                                        >
                                                            {bggroup && bggroup.length > 0 && bggroup.map((item, i) => {
                                                                if (item.bg_id != null) {
                                                                    return (
                                                                        <Option key={item.bg_id}>
                                                                            {item.bg_name}
                                                                        </Option>
                                                                    );
                                                                }
                                                            })}
                                                        </Select>
                                                    </div> :
                                                    <p>{consolidateddata.bg_name ? consolidateddata.bg_name : '-'}</p>
                                                }
                                            </div>
                                            <div className='col-md-4 text-wrap-report'>
                                                <b><label>Legal Entity : <span style={{ color: "red" }}>*</span></label></b>
                                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Enter Legal Entity"
                                                            onChange={(value, data) => {
                                                                setCurrentEntity(value != undefined ? value : localStorage.getItem('SelectedEntityid'))
                                                                setConsolidatedDate({
                                                                    ...consolidateddata,
                                                                    legel_entity: value != undefined ? value : '',
                                                                    legal_entity_name: data != undefined ? data.children : '',
                                                                    category: "",
                                                                    user_type: "",
                                                                    unit: '',
                                                                    user: "",
                                                                    domain: "",
                                                                    act: "",
                                                                    from_date: "",
                                                                    task_category: "",
                                                                    compliance_task: "",
                                                                    to_date: "",
                                                                    task_sub_category: "",
                                                                    compliance_frequency: "",
                                                                    compliance_task_status: "",
                                                                    division: "",
                                                                    domainName: '',
                                                                    unit_name: '',
                                                                    user_name: '',
                                                                    statutory_mapping: '',
                                                                    division_name: '',
                                                                    compliance_frequency_name: '',
                                                                    compliance_task_status_name: '',
                                                                    category_name: '',
                                                                    user_type_name: '',
                                                                    task_status: 'All Exposed Risk',
                                                                })
                                                            }}
                                                            onDrop={(e) => {
                                                                e.preventDefault();
                                                                // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                return false;
                                                            }}
                                                            // onPaste={(e) => {
                                                            //     handlePasting(e, /^[a-z 0-9 - ()]+$/gi)
                                                            // }}
                                                            onPaste={onPasteToaster}
                                                            searchValue={selectSearchValue.legalEntity}
                                                            onSearch={(value) => {
                                                                let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                                setSelectSearchValue({
                                                                    ...selectSearchValue,
                                                                    legalEntity: result
                                                                });
                                                            }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            //value={Settings.le_id}
                                                            style={{ width: '100%', marginTop: '2px' }}

                                                            value={consolidateddata.legal_entity_name || undefined}
                                                            disabled={consolidateddata.country === '' ? true : false}

                                                        >
                                                            {entity && entity.length > 0 && entity.map((item, i) => {
                                                                return (
                                                                    <Option key={item.le_id}>
                                                                        {item.le_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {validator.current.message(
                                                            'legalentity',
                                                            consolidateddata.legel_entity,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Legal Entity Required',
                                                                }
                                                            })}
                                                    </div> :
                                                    <p>{localStorage.getItem('SelectedEntity')}</p>
                                                }
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-md-12" style={{ marginTop: '5px' }}>
                                        <div className="row">
                                            <div className='col-md-4'>
                                                <label><b>Domain : </b><span style={{ color: "red" }}>*</span></label>
                                                <div className="form-group">
                                                    <Select
                                                        allowClear={true}
                                                        onChange={(value, data) => {
                                                            getDomainBasedRecords(value)
                                                            setConsolidatedDate({
                                                                ...consolidateddata,
                                                                domain: value != undefined ? value : '',
                                                                domainName: data != undefined ? data.children : '',
                                                                category: "",
                                                                user_type: "",
                                                                unit: '',
                                                                user: "",
                                                                act: "",
                                                                from_date: "",
                                                                task_category: "",
                                                                compliance_task: "",
                                                                to_date: "",
                                                                task_sub_category: "",
                                                                compliance_frequency: "",
                                                                compliance_task_status: "",
                                                                division: "",
                                                                unit_name: '',
                                                                user_name: '',
                                                                statutory_mapping: '',
                                                                division_name: '',
                                                                compliance_frequency_name: '',
                                                                compliance_task_status_name: '',
                                                                category_name: '',
                                                                user_type_name: '',
                                                                task_status: 'All Exposed Risk',
                                                            })
                                                        }}
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        // onPaste={(e) => {
                                                        //     handlePasting(e, /^[a-z 0-9 ()]+$/gi)
                                                        // }}
                                                        onPaste={onPasteToaster}
                                                        searchValue={selectSearchValue.domain}
                                                        onSearch={(value) => {
                                                            let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                                            setSelectSearchValue({
                                                                ...selectSearchValue,
                                                                domain: result
                                                            });
                                                        }}
                                                        placeholder="Enter Domain Name"
                                                        style={{ width: '100%', marginTop: '2px' }}
                                                        showSearch
                                                        value={consolidateddata.domainName || undefined}
                                                        disabled={consolidateddata.legel_entity == '' ? true : false}
                                                    >
                                                        {domainList && domainList.length > 0 && domainList.map((item, i) => {
                                                            return (
                                                                <Option key={item.d_id}>
                                                                    {item.d_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                                {validator.current.message(
                                                    'domain',
                                                    consolidateddata.domain,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Domain Required',
                                                        }
                                                    })}
                                            </div>
                                            <div className="col-4">
                                                <label htmlFor=""><b>Division : </b></label>
                                                <div className="form-group">

                                                    <Select allowClear={true}
                                                        onChange={(value, data) => {
                                                            getDivisionBasedRecords(value)
                                                            setConsolidatedDate({
                                                                ...consolidateddata,
                                                                division: value != undefined ? value : '',
                                                                division_name: data != undefined ? data.children : '',
                                                                category: '',
                                                                category_name: '',
                                                                unit: '',
                                                                unit_name: ''
                                                            })

                                                        }}
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        // onPaste={(e) => {
                                                        //     handlePasting(e, /^[a-z 0-9 ()]+$/gi)
                                                        // }}
                                                        onPaste={onPasteToaster}
                                                        searchValue={selectSearchValue.division}
                                                        onSearch={(value) => {
                                                            let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                            setSelectSearchValue({
                                                                ...selectSearchValue,
                                                                division: result
                                                            });
                                                        }}
                                                        placeholder="Enter Division"
                                                        showSearch
                                                        style={{ width: '100%', marginTop: '2px' }} s
                                                        value={consolidateddata.division_name || undefined}
                                                        disabled={consolidateddata.domain == '' ? true : false}
                                                    >
                                                        {divisionList && divisionList.length > 0 && divisionList.map((item, i) => {
                                                            return (
                                                                <Option key={item.division_id}>
                                                                    {item.division_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <label><b>Category : </b></label><br />
                                                <div className="form-group">
                                                    <Select allowClear={true}
                                                        showSearch
                                                        onChange={(value, data) => {
                                                            getCategoryBasedRecords(value)
                                                            setConsolidatedDate({
                                                                ...consolidateddata,
                                                                category: value != undefined ? value : '',
                                                                category_name: data != undefined ? data.children : '',
                                                                unit: '',
                                                                unit_name: ''
                                                            })
                                                        }}
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        // onPaste={(e) => {
                                                        //     handlePasting(e, /^[a-z 0-9 ()]+$/gi)
                                                        // }}
                                                        onPaste={onPasteToaster}
                                                        searchValue={selectSearchValue.category}
                                                        onSearch={(value) => {
                                                            let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                            setSelectSearchValue({
                                                                ...selectSearchValue,
                                                                category: result
                                                            });
                                                        }}
                                                        placeholder="Enter Category"
                                                        style={{ width: '100%', marginTop: '2px' }}
                                                        value={consolidateddata.category_name || undefined}
                                                        disabled={consolidateddata.domain == '' ? true : false}
                                                    >
                                                        {categoryList && categoryList.length > 0 && categoryList.map((item, i) => {
                                                            return (
                                                                <Option key={item.category_id}>
                                                                    {item.category_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-md-12" style={{ marginTop: '5px' }}>
                                        <div className="row">

                                            <div className='col-md-4'>
                                                <label><b>Unit : </b></label>
                                                <div className="form-group">
                                                    <Select
                                                        allowClear={true}
                                                        onChange={(value, data) => {
                                                            setConsolidatedDate({
                                                                ...consolidateddata,
                                                                unit: value != undefined ? value : '',
                                                                unit_name: data != undefined ? data.children : ''
                                                            })
                                                        }}
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        // onPaste={(e) => {
                                                        //     handlePasting(e, /^[a-z 0-9 ()]+$/gi)
                                                        // }}
                                                        onPaste={onPasteToaster}
                                                        searchValue={selectSearchValue.unit}
                                                        onSearch={(value) => {
                                                            let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                            setSelectSearchValue({
                                                                ...selectSearchValue,
                                                                unit: result
                                                            });
                                                        }}
                                                        placeholder="Enter Unit"
                                                        style={{ width: '100%', marginTop: '2px' }}
                                                        showSearch
                                                        value={consolidateddata.unit_name || undefined}
                                                        disabled={consolidateddata.domain == '' ? true : false}
                                                    >
                                                        {unitLegalEntity && unitLegalEntity.length && unitLegalEntity.map((item, i) => {
                                                            return (
                                                                <Option key={item.unit_id}>
                                                                    {`${item.unit_code}-${item.unit_name}`}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Act : </b></label>
                                                <div className="form-group">
                                                    <Select
                                                        allowClear={true}
                                                        onChange={(value, data) => {
                                                            setConsolidatedDate({
                                                                ...consolidateddata,
                                                                act: value != undefined ? value : '',
                                                                statutory_mapping: data != undefined ? data.children : '',
                                                                compliance_task: ''
                                                            })
                                                        }}
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        // onPaste={(e) => {
                                                        //     handlePasting(e, /^[a-z 0-9 ()]+$/gi)
                                                        // }}
                                                        onPaste={onPasteToaster}
                                                        searchValue={selectSearchValue.actRules}
                                                        onSearch={(value) => {
                                                            let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                            setSelectSearchValue({
                                                                ...selectSearchValue,
                                                                actRules: result
                                                            });
                                                        }}
                                                        showSearch
                                                        placeholder="Enter Act"
                                                        style={{ width: '100%', marginTop: '2px' }}
                                                        value={consolidateddata.statutory_mapping || undefined}
                                                        disabled={consolidateddata.domain == '' ? true : false}
                                                    >
                                                        {actLegalEntity && actLegalEntity.length > 0 && actLegalEntity.map((item, i) => {
                                                            return (
                                                                <Option key={item.parent_id}>
                                                                    {item.statutory_mapping}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>

                                            <div className="col-4">
                                                <label htmlFor=""><b>Compliance Task : </b></label>
                                                <div className="form-group">
                                                    <input allowClear={true}
                                                        placeholder="Enter Compliance Task"
                                                        className='ant-input'
                                                        style={{ width: '100%', marginTop: '2px' }}

                                                        onChange={ComplianceTaskName}

                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        onPaste={(e) => {
                                                            handlePasting(e, /^[a-z 0-9 ()]+$/gi)
                                                        }}
                                                        value={consolidateddata.compliance_task}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12" style={{ marginTop: '5px' }}>
                                        <div className="row">

                                            <div className="col-md-4">
                                                <label htmlFor=""><b>Compliance Task Status : </b></label>
                                                <div className="form-group">
                                                    <Select allowClear={false} placeholder='Enter Compliance Task Status'
                                                        // showSearch
                                                        onChange={(value, data) => {
                                                            setConsolidatedDate({
                                                                ...consolidateddata,
                                                                task_status: value != undefined ? value : '',
                                                                compliance_task_status_name: data != undefined ? data.children : ''
                                                            })
                                                        }}
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        onPaste={(e) => {
                                                            handlePasting(e, /^[a-z 0-9 ()]+$/gi)
                                                        }}
                                                        style={{ width: '100%', marginTop: '2px' }}
                                                        value={consolidateddata.task_status || undefined}
                                                        disabled={consolidateddata.legel_entity == '' ? true : false}
                                                    >
                                                        <Option key='All Exposed Risk'>All Exposed Risk</Option>
                                                        {complianceTaskStatus && complianceTaskStatus.length > 0 && complianceTaskStatus.map((item, i) => {
                                                            return (
                                                                <Option key={item.task_status}>
                                                                    {item.task_status}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className="col-md-4">

                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "5px" }}>
                                        <div className='row'>
                                            {/* <div className='col-md-4'></div>
                                            <div className='col-md-4'></div> */}
                                            <div className='col-md-4 popupbtncolour' style={{ marginLeft: '37%' }}>
                                                <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')} style={{ background: "#0096FF", borderColor: "#0096FF", marginLeft: "39px", marginRight: "10px" }}
                                                    icon={<EyeOutlined />} onClick={onshow}
                                                >
                                                    Show
                                                </Button>
                                                <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')} style={{ background: "#32a852", borderColor: "#32a852" }} onClick={showModal}
                                                    icon={<ExportOutlined />}   >
                                                    Export
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Panel>
                            </Collapse>
                            <Modal
                                onCancel={setcancelFilter}
                                footer={false}
                                title="Risk Report"
                                className={
                                    "add-service-prv cons-report " +
                                    localStorage.getItem("currentTheme")
                                }
                                open={filterModalVisible}
                                maskClosable={false}
                            >
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-4">
                                            <label htmlFor=""><b>Country : </b><span style={{ color: "red" }}>*</span></label>
                                            <div className="form-group">
                                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                    <div className="form-group">
                                                        <Select
                                                            getPopupContainer={trigger => trigger.parentNode}
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Enter Country"
                                                            onChange={(value, data) => {
                                                                if (value != undefined) {
                                                                    let le = _.filter(sessionArr, { c_id: Number(value) })
                                                                    setEntity(le)
                                                                }
                                                                setConsolidatedDate({
                                                                    ...consolidateddata,
                                                                    country: value != undefined ? value : '',
                                                                    countryName: data != undefined ? data.children : '',
                                                                    category: "",
                                                                    user_type: "",
                                                                    legel_entity: "",
                                                                    unit: '',
                                                                    user: "",
                                                                    domain: "",
                                                                    act: "",
                                                                    from_date: "",
                                                                    task_category: "",
                                                                    compliance_task: "",
                                                                    to_date: "",
                                                                    task_sub_category: "",
                                                                    compliance_frequency: "",
                                                                    compliance_task_status: "",
                                                                    division: "",
                                                                    domainName: '',
                                                                    unit_name: '',
                                                                    user_name: '',
                                                                    statutory_mapping: '',
                                                                    division_name: '',
                                                                    compliance_frequency_name: '',
                                                                    compliance_task_status_name: '',
                                                                    category_name: '',
                                                                    user_type_name: '',
                                                                    task_status: 'All Exposed Risk',
                                                                    legal_entity_name: '',
                                                                    bg_name: '',
                                                                    bg_id: ''
                                                                })
                                                            }}
                                                            onDrop={(e) => {
                                                                e.preventDefault();
                                                                // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                return false;
                                                            }}
                                                            // onPaste={(e) => {
                                                            //     handlePasting(e, /^[a-z 0-9 - ()]+$/gi)
                                                            // }}
                                                            onPaste={onPasteToaster}
                                                            searchValue={selectSearchValue.country}
                                                            onSearch={(value) => {
                                                                let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                                                setSelectSearchValue({
                                                                    ...selectSearchValue,
                                                                    country: result
                                                                });
                                                            }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            //value={Settings.le_id}
                                                            style={{ width: '100%', marginTop: '2px' }}

                                                            value={consolidateddata.countryName || undefined}
                                                        >
                                                            {uniq_country_data && uniq_country_data.length > 0 && uniq_country_data.map((item, i) => {
                                                                return (
                                                                    <Option key={item.c_id}>
                                                                        {item.c_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {/* {validator.current.message(
                                                                'legalentity',
                                                                consolidateddata.country,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Country Required',
                                                                    }
                                                                })} */}
                                                    </div> :
                                                    <p>{consolidateddata.countryName}</p>
                                                }
                                            </div>
                                            {validator.current.message(
                                                'country',
                                                consolidateddata.country,
                                                'required',
                                                {
                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: 'Country Required',
                                                    }
                                                })}
                                        </div>
                                        <div className="col-4">

                                            <label><b>Business Group:</b></label><br />
                                            {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                <div className="form-group">
                                                    <Select
                                                        getPopupContainer={trigger => trigger.parentNode}

                                                        allowClear={true}
                                                        size="default"
                                                        placeholder="Enter Business Group Name"
                                                        onChange={(value, data) => {
                                                            console.log(value, data, "valueeeeeeeedata");
                                                            if (value == undefined) {
                                                                setConsolidatedDate({
                                                                    ...consolidateddata,
                                                                    legel_entity: '',
                                                                    legal_entity_name: '',
                                                                    category: "",
                                                                    user_type: "",
                                                                    unit: '',
                                                                    user: "",
                                                                    domain: "",
                                                                    act: "",
                                                                    from_date: "",
                                                                    task_category: "",
                                                                    compliance_task: "",
                                                                    to_date: "",
                                                                    task_sub_category: "",
                                                                    compliance_frequency: "",
                                                                    compliance_task_status: "",
                                                                    division: "",
                                                                    domainName: '',
                                                                    unit_name: '',
                                                                    user_name: '',
                                                                    statutory_mapping: '',
                                                                    division_name: '',
                                                                    compliance_frequency_name: '',
                                                                    compliance_task_status_name: '',
                                                                    category_name: '',
                                                                    user_type_name: '',
                                                                    task_status: 'All Exposed Risk',
                                                                    bg_id: '',
                                                                    bg_name: ''
                                                                })
                                                                let le = _.filter(sessionArr, { c_id: Number(consolidateddata.country) })
                                                                setEntity(le)
                                                            } else {
                                                                setConsolidatedDate({
                                                                    ...consolidateddata,
                                                                    legel_entity: '',
                                                                    legal_entity_name: '',
                                                                    category: "",
                                                                    user_type: "",
                                                                    unit: '',
                                                                    user: "",
                                                                    domain: "",
                                                                    act: "",
                                                                    from_date: "",
                                                                    task_category: "",
                                                                    compliance_task: "",
                                                                    to_date: "",
                                                                    task_sub_category: "",
                                                                    compliance_frequency: "",
                                                                    compliance_task_status: "",
                                                                    division: "",
                                                                    domainName: '',
                                                                    unit_name: '',
                                                                    user_name: '',
                                                                    statutory_mapping: '',
                                                                    division_name: '',
                                                                    compliance_frequency_name: '',
                                                                    compliance_task_status_name: '',
                                                                    category_name: '',
                                                                    user_type_name: '',
                                                                    task_status: 'All Exposed Risk',
                                                                    bg_id: value ? value : '',
                                                                    bg_name: data ? data.children : ''
                                                                })
                                                                let le = _.filter(sessionArr, { bg_id: Number(value) })
                                                                setEntity(le)


                                                            }
                                                        }}
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        // onPaste={(e) => {
                                                        //     handlePasting(e, /^[a-z 0-9 ()]+$/gi)
                                                        // }}
                                                        onPaste={onPasteToaster}
                                                        searchValue={selectSearchValue.BusinessGroup}
                                                        onSearch={(value) => {
                                                            let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                            setSelectSearchValue({
                                                                ...selectSearchValue,
                                                                BusinessGroup: result
                                                            });
                                                        }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        value={consolidateddata.bg_name || undefined}
                                                        disabled={consolidateddata.country === '' ? true : false}
                                                        style={{ width: '100%', marginTop: '2px' }}

                                                    >
                                                        {bggroup && bggroup.length > 0 && bggroup.map((item, i) => {
                                                            if (item.bg_id != null) {
                                                                return (
                                                                    <Option key={item.bg_id}>
                                                                        {item.bg_name}
                                                                    </Option>
                                                                );
                                                            }
                                                        })}
                                                    </Select>
                                                </div> :
                                                <p>{consolidateddata.bg_name ? consolidateddata.bg_name : '-'}</p>
                                            }
                                        </div>
                                        <div className="col-4 text-wrap-report">

                                            <b><label>Legal Entity : <span style={{ color: "red" }}>*</span></label></b>
                                            {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                <div className="form-group">
                                                    <Select
                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        allowClear={true}
                                                        size="default"
                                                        placeholder="Enter Legal Entity"
                                                        onChange={(value, data) => {
                                                            setCurrentEntity(value != undefined ? value : localStorage.getItem('SelectedEntityid'))
                                                            setConsolidatedDate({
                                                                ...consolidateddata,
                                                                legel_entity: value != undefined ? value : '',
                                                                legal_entity_name: data != undefined ? data.children : '',
                                                                category: "",
                                                                user_type: "",
                                                                unit: '',
                                                                user: "",
                                                                domain: "",
                                                                act: "",
                                                                from_date: "",
                                                                task_category: "",
                                                                compliance_task: "",
                                                                to_date: "",
                                                                task_sub_category: "",
                                                                compliance_frequency: "",
                                                                compliance_task_status: "",
                                                                division: "",
                                                                domainName: '',
                                                                unit_name: '',
                                                                user_name: '',
                                                                statutory_mapping: '',
                                                                division_name: '',
                                                                compliance_frequency_name: '',
                                                                compliance_task_status_name: '',
                                                                category_name: '',
                                                                user_type_name: '',
                                                                task_status: 'All Exposed Risk',
                                                            })
                                                        }}
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        // onPaste={(e) => {
                                                        //     handlePasting(e, /^[a-z 0-9 - ()]+$/gi)
                                                        // }}
                                                        onPaste={onPasteToaster}
                                                        searchValue={selectSearchValue.legalEntity}
                                                        onSearch={(value) => {
                                                            let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                            setSelectSearchValue({
                                                                ...selectSearchValue,
                                                                legalEntity: result
                                                            });
                                                        }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        //value={Settings.le_id}
                                                        style={{ width: '100%', marginTop: '2px' }}

                                                        value={consolidateddata.legal_entity_name || undefined}
                                                        disabled={consolidateddata.country === '' ? true : false}

                                                    >
                                                        {entity && entity.length > 0 && entity.map((item, i) => {
                                                            return (
                                                                <Option key={item.le_id}>
                                                                    {item.le_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                    {validator.current.message(
                                                        'legalentity',
                                                        consolidateddata.legel_entity,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Legal Entity Required',
                                                            }
                                                        })}
                                                </div> :
                                                <p>{localStorage.getItem('SelectedEntity')}</p>
                                            }
                                        </div>


                                    </div>
                                </div>
                                <div className="col-md-12" style={{ marginTop: '5px' }}>
                                    <div className="row">
                                        <div className="col-4">

                                            <label><b>Domain : </b><span style={{ color: "red" }}>*</span></label>
                                            <div className="form-group">
                                                <Select
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    allowClear={true}
                                                    onChange={(value, data) => {
                                                        getDomainBasedRecords(value)
                                                        setConsolidatedDate({
                                                            ...consolidateddata,
                                                            domain: value != undefined ? value : '',
                                                            domainName: data != undefined ? data.children : '',
                                                            category: "",
                                                            user_type: "",
                                                            unit: '',
                                                            user: "",
                                                            act: "",
                                                            from_date: "",
                                                            task_category: "",
                                                            compliance_task: "",
                                                            to_date: "",
                                                            task_sub_category: "",
                                                            compliance_frequency: "",
                                                            compliance_task_status: "",
                                                            division: "",
                                                            unit_name: '',
                                                            user_name: '',
                                                            statutory_mapping: '',
                                                            division_name: '',
                                                            compliance_frequency_name: '',
                                                            compliance_task_status_name: '',
                                                            category_name: '',
                                                            user_type_name: '',
                                                            task_status: 'All Exposed Risk',
                                                        })
                                                    }}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    // onPaste={(e) => {
                                                    //     handlePasting(e, /^[a-z 0-9 ()]+$/gi)
                                                    // }}
                                                    onPaste={onPasteToaster}
                                                    searchValue={selectSearchValue.domain}
                                                    onSearch={(value) => {
                                                        let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                                        setSelectSearchValue({
                                                            ...selectSearchValue,
                                                            domain: result
                                                        });
                                                    }}
                                                    placeholder="Enter Domain Name"
                                                    style={{ width: '100%', marginTop: '2px' }}
                                                    showSearch
                                                    value={consolidateddata.domainName || undefined}
                                                    disabled={consolidateddata.legel_entity == '' ? true : false}
                                                >
                                                    {domainList && domainList.length > 0 && domainList.map((item, i) => {
                                                        return (
                                                            <Option key={item.d_id}>
                                                                {item.d_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                            {validator.current.message(
                                                'domain',
                                                consolidateddata.domain,
                                                'required',
                                                {
                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: 'Domain Required',
                                                    }
                                                })}
                                        </div>
                                        <div className="col-4">
                                            <label htmlFor=""><b>Division : </b></label>
                                            <div className="form-group">

                                                <Select allowClear={true}
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    onChange={(value, data) => {
                                                        getDivisionBasedRecords(value)
                                                        setConsolidatedDate({
                                                            ...consolidateddata,
                                                            division: value != undefined ? value : '',
                                                            division_name: data != undefined ? data.children : '',
                                                            category: '',
                                                            category_name: '',
                                                            unit: '',
                                                            unit_name: ''
                                                        })

                                                    }}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    // onPaste={(e) => {
                                                    //     handlePasting(e, /^[a-z 0-9 ()]+$/gi)
                                                    // }}
                                                    onPaste={onPasteToaster}
                                                    searchValue={selectSearchValue.division}
                                                    onSearch={(value) => {
                                                        let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                        setSelectSearchValue({
                                                            ...selectSearchValue,
                                                            division: result
                                                        });
                                                    }}
                                                    placeholder="Enter Division"
                                                    showSearch
                                                    style={{ width: '100%', marginTop: '2px' }} s
                                                    value={consolidateddata.division_name || undefined}
                                                    disabled={consolidateddata.domain == '' ? true : false}
                                                >
                                                    {divisionList && divisionList.length > 0 && divisionList.map((item, i) => {
                                                        return (
                                                            <Option key={item.division_id}>
                                                                {item.division_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-4">

                                            <label><b>Category : </b></label><br />
                                            <div className="form-group">
                                                <Select allowClear={true}
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    showSearch
                                                    onChange={(value, data) => {
                                                        getCategoryBasedRecords(value)
                                                        setConsolidatedDate({
                                                            ...consolidateddata,
                                                            category: value != undefined ? value : '',
                                                            category_name: data != undefined ? data.children : '',
                                                            unit: '',
                                                            unit_name: ''
                                                        })
                                                    }}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    // onPaste={(e) => {
                                                    //     handlePasting(e, /^[a-z 0-9 ()]+$/gi)
                                                    // }}
                                                    onPaste={onPasteToaster}
                                                    searchValue={selectSearchValue.category}
                                                    onSearch={(value) => {
                                                        let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                        setSelectSearchValue({
                                                            ...selectSearchValue,
                                                            category: result
                                                        });
                                                    }}
                                                    placeholder="Enter Category"
                                                    style={{ width: '100%', marginTop: '2px' }}
                                                    value={consolidateddata.category_name || undefined}
                                                    disabled={consolidateddata.domain == '' ? true : false}
                                                >
                                                    {categoryList && categoryList.length > 0 && categoryList.map((item, i) => {
                                                        return (
                                                            <Option key={item.category_id}>
                                                                {item.category_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-md-12" style={{ marginTop: '5px' }}>
                                    <div className="row">

                                        <div className="col-4">

                                            <label><b>Unit : </b></label>
                                            <div className="form-group">
                                                <Select
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    allowClear={true}
                                                    onChange={(value, data) => {
                                                        setConsolidatedDate({
                                                            ...consolidateddata,
                                                            unit: value != undefined ? value : '',
                                                            unit_name: data != undefined ? data.children : ''
                                                        })
                                                    }}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    // onPaste={(e) => {
                                                    //     handlePasting(e, /^[a-z 0-9 ()]+$/gi)
                                                    // }}
                                                    onPaste={onPasteToaster}
                                                    searchValue={selectSearchValue.unit}
                                                    onSearch={(value) => {
                                                        let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                        setSelectSearchValue({
                                                            ...selectSearchValue,
                                                            unit: result
                                                        });
                                                    }}
                                                    placeholder="Enter Unit"
                                                    style={{ width: '100%', marginTop: '2px' }}
                                                    showSearch
                                                    value={consolidateddata.unit_name || undefined}
                                                    disabled={consolidateddata.domain == '' ? true : false}
                                                >
                                                    {unitLegalEntity && unitLegalEntity.length && unitLegalEntity.map((item, i) => {
                                                        return (
                                                            <Option key={item.unit_id}>
                                                                {`${item.unit_code}-${item.unit_name}`}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <label><b>Act : </b></label>
                                            <div className="form-group">
                                                <Select
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    allowClear={true}
                                                    onChange={(value, data) => {
                                                        setConsolidatedDate({
                                                            ...consolidateddata,
                                                            act: value != undefined ? value : '',
                                                            statutory_mapping: data != undefined ? data.children : '',
                                                            compliance_task: ''
                                                        })
                                                    }}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    // onPaste={(e) => {
                                                    //     handlePasting(e, /^[a-z 0-9 ()]+$/gi)
                                                    // }}
                                                    onPaste={onPasteToaster}
                                                    searchValue={selectSearchValue.actRules}
                                                    onSearch={(value) => {
                                                        let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                        setSelectSearchValue({
                                                            ...selectSearchValue,
                                                            actRules: result
                                                        });
                                                    }}
                                                    showSearch
                                                    placeholder="Enter Act"
                                                    style={{ width: '100%', marginTop: '2px' }}
                                                    value={consolidateddata.statutory_mapping || undefined}
                                                    disabled={consolidateddata.domain == '' ? true : false}
                                                >
                                                    {actLegalEntity && actLegalEntity.length > 0 && actLegalEntity.map((item, i) => {
                                                        return (
                                                            <Option key={item.parent_id}>
                                                                {item.statutory_mapping}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <label htmlFor=""><b>Compliance Task : </b></label>
                                            <div className="form-group">
                                                <input allowClear={true}
                                                    placeholder="Enter Compliance Task"
                                                    className='ant-input'
                                                    style={{ width: '100%', marginTop: '2px' }}

                                                    onChange={ComplianceTaskName}

                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    onPaste={(e) => {
                                                        handlePasting(e, /^[a-z 0-9 ()]+$/gi)
                                                    }}
                                                    value={consolidateddata.compliance_task}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12" style={{ marginTop: '5px' }}>
                                    <div className="row">


                                        <div className="col-4">

                                            <label htmlFor=""><b>Compliance Task Status : </b></label>
                                            <div className="form-group">
                                                <Select allowClear={false} placeholder='Enter Compliance Task Status'
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    // showSearch
                                                    onChange={(value, data) => {
                                                        setConsolidatedDate({
                                                            ...consolidateddata,
                                                            task_status: value != undefined ? value : '',
                                                            compliance_task_status_name: data != undefined ? data.children : ''
                                                        })
                                                    }}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    onPaste={(e) => {
                                                        handlePasting(e, /^[a-z 0-9 ()]+$/gi)
                                                    }}
                                                    style={{ width: '100%', marginTop: '2px' }}
                                                    value={consolidateddata.task_status || undefined}
                                                    disabled={consolidateddata.legel_entity == '' ? true : false}
                                                >
                                                    <Option key='All Exposed Risk'>All Exposed Risk</Option>
                                                    {complianceTaskStatus && complianceTaskStatus.length > 0 && complianceTaskStatus.map((item, i) => {
                                                        return (
                                                            <Option key={item.task_status}>
                                                                {item.task_status}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className='col-md-12' style={{ marginTop: "5px" }}>
                                    <div className='row'>
                                        {/* <div className='col-md-4'></div>
                                            <div className='col-md-4'></div> */}
                                        <div className='col-md-4 popupbtncolour' style={{ marginLeft: '37%' }}>
                                            <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')} style={{ background: "#0096FF", borderColor: "#0096FF", marginLeft: "39px", marginRight: "10px" }}
                                                icon={<EyeOutlined />} onClick={onshow}
                                            >
                                                Show
                                            </Button>
                                            <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')} style={{ background: "#32a852", borderColor: "#32a852" }} onClick={showModal}
                                                icon={<ExportOutlined />}   >
                                                Export
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Modal>
                            {tableshow == true ?
                                <Card className='rounded-3'>

                                    <div className='col-md-12 mt-0 mb-2 filter-detial-sticky' >
                                        <div className='row'>
                                            <div className='col-md-4 text-wrap-report'>
                                                <label><b>Legal Entity :</b> {localStorage.getItem('SelectedEntity') === 'All Legal Entity' ? consolidateddata.legalEnityLabel : localStorage.getItem('SelectedEntity')}</label>
                                            </div>
                                            <div className='col-md-4 text-center'>
                                                <label><b>Country :</b> {consolidateddata.countryLabel}</label>
                                            </div>
                                            <div className='col-md-4 text-end'>
                                                <label><b>Domain :</b> {consolidateddata.domainLabel}</label>
                                            </div>


                                        </div>
                                    </div>
                                    <div className={localStorage.getItem("currentTheme") + ' ant-table-wrapper'}>
                                        <div className='ant-table ant-table-bordered ant-table-fixed-header'>
                                            <div className='ant-table-container'>
                                                <div className='ant-table-content  userprivclass1'>
                                                    <table>
                                                        <colgroup>
                                                            <col style={{ width: '10px' }}></col>
                                                        </colgroup>
                                                        <thead className="ant-table-thead table-head-stick" >
                                                            <tr>
                                                                <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '30px' }}>#</th>
                                                                <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '300px' }}>Compliance Task</th>
                                                                <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '30px' }}>Criticality</th>
                                                                <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '100px' }}>Frequency</th>
                                                                <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '140px' }}>Admin Incharge</th>
                                                                <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '120px' }}>Assignee</th>
                                                                <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '120px' }}>Compliance Task Status</th>
                                                                <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '330px' }}><center>Penal Consequences</center></th>
                                                                <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '120px' }}><center>History</center></th>
                                                                <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '120px' }}><center>Uploaded Document</center></th>
                                                            </tr>
                                                        </thead>

                                                        {filteredArrayState && filteredArrayState.length ?
                                                            filteredArrayState.map((item, i) => {

                                                                return (
                                                                    <tbody className='ant-table-tbody' key={i}>
                                                                        <tr className="bg-lightdark">
                                                                            <td style={{ paddingLeft: '0 !important', paddingRight: '0 !important' }} colSpan="100%">
                                                                                <div className='bg-white shadow-sm border-radius-reports'>
                                                                                    <div className="col-md-12">
                                                                                        <div className='row m-2'>
                                                                                            <div className='col-md-3' >
                                                                                                <label><b>Unit :</b> {item[0].unit_name}</label>
                                                                                            </div>
                                                                                            <div className='col-md-3' >
                                                                                                <label><b>Division :</b> {item[0].division_name}</label>
                                                                                            </div>
                                                                                            <div className='col-md-3' >
                                                                                                <label><b>Category :</b> {item[0].category_name}</label>
                                                                                            </div>
                                                                                            <div className='col-md-3' >
                                                                                                <label><b>Time Zone :</b> {item[0].tz_name}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        {titledata && titledata.map((itemdata, i) => {
                                                                            let data = _.find(itemdata.child, { unit_id: item[0].unit_id })
                                                                            if (data != undefined) {
                                                                                if (data.statutory_mapping == itemdata.parent) {
                                                                                    let list = itemdata.child
                                                                                    let size = 25
                                                                                    const items = list.slice(0, size)
                                                                                    return <Fragment>

                                                                                        <tr className='ant-table-row ant-table-row-level-0'>

                                                                                            <td colSpan={12} className='ant-table-cell'><b>{itemdata.parent}</b></td>
                                                                                        </tr>

                                                                                        {itemdata.child.map((items) => {
                                                                                            if (item[0].unit_id == items.unit_id) {
                                                                                                let temp
                                                                                                if (items.document_name) {
                                                                                                    let result = items.document_name.replaceAll("||", ", ||");
                                                                                                    temp = result.split(", ||")
                                                                                                }
                                                                                                console.log(items, 'items');
                                                                                                return (
                                                                                                    <>
                                                                                                        <tr>
                                                                                                            <td className='ant-table-cell'><center>{showindex + 1}</center></td>
                                                                                                            <td title={items.compliance_task} className='ant-table-cell'><span className="ms-1 d-flex justify-content-start align-items-center"><Tooltip title={items.compliance_description}>
                                                                                                                <span className='mx-1 text-primary'><i className="ri-information-fill"></i></span>
                                                                                                            </Tooltip> &nbsp;{items.compliance_task}</span></td>
                                                                                                            <td className='ant-table-cell'><center>{items.Criticality}</center></td>
                                                                                                            <td className='ant-table-cell'>{items.frequency_name}</td>
                                                                                                            <td className='ant-table-cell'>{items.admin_incharge}</td>
                                                                                                            <td className='ant-table-cell'>{items.assignee_name}</td>
                                                                                                            <td className='ant-table-cell'>{items.task_status}</td>
                                                                                                            <td title={items.penal_consequences} className='ant-table-cell'>{items.penal_consequences}</td>
                                                                                                            <td className='ant-table-cell'><a href="javascript:;" onClick={() => {
                                                                                                                showTaskHistoryModal()
                                                                                                                let temp = []
                                                                                                                temp.push({
                                                                                                                    start_date: items.start_date,
                                                                                                                    due_date: items.due_date,
                                                                                                                    assignee: items.assignee_name,
                                                                                                                    concurr: items.concurrer_name,
                                                                                                                    approver: items.approver_name,
                                                                                                                    task_status: items.task_status,
                                                                                                                    assigned_on: items.assigned_on,
                                                                                                                    concurred_on: items.concurred_on,
                                                                                                                    approved_on: items.approved_on,
                                                                                                                    remarks: items.comp_remarks
                                                                                                                })
                                                                                                                setDataSource(temp)
                                                                                                            }}><center>View</center></a></td>
                                                                                                            {items.doc_ref_num != null && (items.document_name == null || items.document_name == "") ?
                                                                                                                <td className='ant-table-cell'><center>{items.doc_ref_num}</center></td>
                                                                                                                : items.doc_ref_num != null && items.document_name != null ?
                                                                                                                    <td ><center>{
                                                                                                                        temp && temp.length > 0 && temp.map((itemcheck) => {
                                                                                                                            return <><Link onClick={() => {
                                                                                                                                downloaddocument(items.unit_id, items.start_date, itemcheck)
                                                                                                                            }}>{items.doc_ref_num}</Link><br /></>
                                                                                                                        })
                                                                                                                    }</center></td>
                                                                                                                    :
                                                                                                                    <td className='ant-table-cell'><center>{
                                                                                                                        temp && temp.length > 0 && temp.map((itemcheck) => {
                                                                                                                            return <><Link onClick={() => {
                                                                                                                                downloaddocument(items.unit_id, items.start_date, itemcheck)
                                                                                                                            }}>{itemcheck}</Link><br /></>
                                                                                                                        })
                                                                                                                    }</center></td>
                                                                                                            }
                                                                                                        </tr>
                                                                                                        <span hidden>{showindex = showindex + 1}</span>
                                                                                                    </>
                                                                                                )
                                                                                            }

                                                                                        })

                                                                                        }
                                                                                    </Fragment>
                                                                                }
                                                                            }
                                                                        })
                                                                        }
                                                                    </tbody>
                                                                )
                                                            }) :
                                                            <tr>
                                                                <td colSpan="100%" style={{ paddingTop: '15px', textAlign: 'center', fontSize: '14px', fontWeight: 'bold', paddingBottom: '15px' }}>No Records Found</td>
                                                            </tr>
                                                        }
                                                    </table>
                                                    {/* <br /> */}

                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row mt-3">
                                        {showindex != 0 ?
                                            <div className='col-md-6' >
                                                <label>Showing {pageSize * (current - 1) + 1} to {showindex} of {Number(count)} entries</label>
                                            </div> : ''}
                                        {console.log(count, 'countcount')}
                                        {filteredArrayState && filteredArrayState.length > 0 ?
                                            <div className='col-md-6 text-right'>

                                                <Pagination
                                                    current={current}
                                                    showSizeChanger={true}
                                                    onShowSizeChange={() => {
                                                        setCurrent(1);
                                                    }}
                                                    pageSizeOptions={[25, 50, 100]}
                                                    hideOnSinglePage={
                                                        Number(count) ? false : true
                                                    }
                                                    defaultPageSize={25}
                                                    onChange={(page, pageSizes) => {
                                                        setpageState(true)
                                                        setCurrent(pageSize !== pageSizes ? 1 : page);
                                                        setPageSize(pageSizes)
                                                    }}
                                                    total={Number(count)}
                                                />
                                            </div>
                                            : false}
                                    </div>

                                </Card>

                                // </div>
                                :
                                false
                            }


                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default connect(mapStateToProps, {
    RiskReportData,
    getRiskReportData,
    getRiskExportData,
    Downloadfile,
    downloadfiles,
    downloadDocument
})(RiskReport);