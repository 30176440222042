import { Fragment, useState, useEffect, useRef, useMemo } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams, useHistory } from 'react-router-dom'
import { Collapse, Modal, Input, Table, Button, Space, Tooltip, Select, DatePicker, Card, Switch, Pagination } from 'antd';
import SimpleReactValidator from "simple-react-validator";
import Swal from 'sweetalert2';
import ScrollToTop from "react-scroll-to-top";
import _, { entries, forIn, indexOf } from 'lodash';
import { CertificateLevelTable, CertificateLevelAdd, savelevelmapping, updatelevelmapping } from './../../Store/Action/Master/CV_CertificateLevelMapping';
import { InfoCircleOutlined, EditOutlined, PlayCircleOutlined, PlusOutlined, UpOutlined, SearchOutlined, AudioOutlined, CheckOutlined, ArrowLeftOutlined, FilterFilled, CloseOutlined, PlusCircleOutlined, EyeOutlined, FullscreenOutlined, UserAddOutlined, UserDeleteOutlined, UsergroupAddOutlined, UsergroupDeleteOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { GetRemarksData } from './../../Store/Action/Transcations/Taskrecall'
import { toast } from 'react-toastify';
import { CERTIFICATE_LEVEL_LIST_RESET } from '../../Store/types';
import { fullscreenmode, fullscreenexit, handleKeyDown } from "./../../Libs/fullscreenmode";
import { HomeRedirect } from './../../Libs/country'
import { Toaster } from '../../Libs/Toaster';
const homepage = HomeRedirect()

const mapStateToProps = (state) => ({
    Certilabeltablelist: state.CertificateLabelMap.Certificate.CertificateTableList,
    Certilabelunitlist: state.CertificateLabelMap.Certificate.CertificateTableAdd,
    filterrecall: state.recallfilters.recallfilters

});
const CertiticateLevelMapping = ({
    savelevelmapping,
    updatelevelmapping,
    CertificateLevelAdd,
    Certilabelunitlist,
    CertificateLevelTable,
    Certilabeltablelist,
    filterrecall: { recallremarks },
    GetRemarksData
}) => {
    const historynew = useHistory();
    console.log(Certilabeltablelist, "Certilabeltablelist");
    const { Option } = Select;
    const { Panel } = Collapse;
    const dispatch = useDispatch()
    const location = useLocation();
    const is_Fullscreen = useSelector((state) => state.Login.is_fullscreen);
    const paramid = location.pathname;
    const formValidator = useRef(new SimpleReactValidator());
    const [legalentity, setLegalentity] = useState()
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const [remarks, setRemarks] = useState('')
    //const entityid = localStorage.getItem('SelectedEntityid');
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const sessionArr = sessionParsedValue.entity_info
    const validator = useRef(new SimpleReactValidator());
    const validator1 = useRef(new SimpleReactValidator());
    const [type, setType] = useState("")
    const [show, setShow] = useState(false)
    const [editshow, Seteditshow] = useState(false)
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [AddFormSubmit1, setAddFormSubmit1] = useState(false);
    const [AddFormSubmit2, setAddFormSubmit2] = useState(false);
    const [AddFormSubmit3, setAddFormSubmit3] = useState(false);
    const [FormSubmit, setFormSubmit] = useState(false);
    const [remarksModal, setRemarksModal] = useState(false);
    const [toggleButton, setToggleButton] = useState(false)
    const [concurrerList, setConcurrerList] = useState([])
    const [ApproverList, setApproverList] = useState([])
    console.log(toggleButton, "toggleButton");
    const [toggleButton1, setToggleButton1] = useState(false)
    console.log(toggleButton1, "toggleButton1");
    const [table, SetTable] = useState(false)
    console.log(table, 'table');
    const [all, setAll] = useState([]);
    console.log(all, "all123");
    const [key, setKey] = useState(false)
    const [selectcheckbox, Setselectcheckbox] = useState('block');
    const [unselectcheckbox, Setunselectcheckbox] = useState('none');
    const [modalVisible, SetmodalVisible] = useState(false);
    const [complianceTask, setComplianceTask] = useState([])
    const [pageState, setpageState] = useState(false);
    const [pageSize, setPageSize] = useState(25)
    const [count, setCount] = useState([])
    console.log(count, "countttrttt");
    let [index, setindex] = useState(0)
    console.log(index, "index123");
    const [current, setCurrent] = useState(1);
    const [Levelmappingdata, setlevelmappingdata] = useState({
        assignee_id: null,
        assignee_name: '',
        concurrer_id: null,
        concurrer_name: '',
        approver_id: null,
        approver_name: '',
        user_id: null,
        user_name: '',
        is_approver: null,
        is_concurrence: null,
    })
    const [rowDetails, setRowDetails] = useState({
        assignee_id: '',
        assignee_name: '',
        concurrer_name: '',
        concurrence_id: '',
        approver_name: '',
        approval_person: '',
        user_id: '',
        user_name: '',
    })
    console.log(Levelmappingdata, "Levelmappingdata");
    const [assigneeMatch, setAssigneeMatch] = useState(true)
    console.log(assigneeMatch, 'assigneeMatch');
    const [concurMatch, setConcurMatch] = useState(true)
    const [approverMatch, setApproverMatch] = useState(true)
    const [userMatch, setUserMatch] = useState(true)
    const [assigneeList, setAssigneeList] = useState([])
    const [concurrerListNew, setConcurrerListNew] = useState([])
    const [userListNew, setUserListNew] = useState([])
    const [approverListNew, setApproverListNew] = useState([])
    const [tempAssigneeList, setTempAssigneeList] = useState([])
    console.log(assigneeList, 'assigneeList');
    console.log(userMatch, 'userMatch');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(
        false
    );
    // const [dataTableProperties, setDataTableProperties] = useState({
    //     fixedHeader: true,
    //     pagesize: 25,
    //     sizechanger: true
    // })

    const [modalData, setModalData] = useState({
        password: '',
        reason: '',
        unit_id: '',
        legal_entity: '',
        selectedtype: '',
    })
    const [modalData1, setModalData1] = useState({
        password: '',
        reason: '',
        unit_id: '',
        legal_entity: '',
        selectedtype: '',
        date: ''
    })
    const [disableEntity, setDisableEntity] = useState(false)

    console.log(Certilabelunitlist, 'Certilabelunitlist');
    const [remarksvalue, Setremarksvalue] = useState();
    const [page, setPage] = useState(1);
    const [entityid, setCurrentEntity] = useState("");
    console.log(entityid, 'entity');
    useEffect(() => {
        if (localStorage.getItem('SelectedEntityid') != "All Legal Entity") {
            setCurrentEntity(localStorage.getItem('SelectedEntityid'))
        }
    }, [localStorage.getItem('SelectedEntityid')])
    useEffect(() => {
        if (entityid == '') {
            setBaseData([])
            console.log('haiiiiiiiiiiiiiii');
            dispatch({
                type: CERTIFICATE_LEVEL_LIST_RESET,
            })
        }
    }, [entityid])
    useEffect(() => {
        validator1.current.showMessages()
        validator.current.showMessages()
    }, [])

    useEffect(() => {
        if (pageState === true) {
            console.log("pageState");
            setindex(pageSize * (current - 1));
        }
    }, [pageState, pageSize, current]);

    const { Search } = Input;
    const suffix = (
        <AudioOutlined
            style={{
                fontSize: 16,
                color: '#1890ff',
            }}
        />
    );
    const [searchText, setSearchText] = useState({
        data: '',
        index: 0
    });
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (e, confirm, dataIndex, setSelectedKeys) => {
        if (e) {
            setSelectedKeys(e.target.value ? [e.target.value] : [])
            confirm({ closeDropdown: false });
            // setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex)
        } else {
            setSelectedKeys([])
            confirm({ closeDropdown: false });
            // setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex)
        }
    };
    const [iconEnable, setIconEnable] = useState(false);
    const [clearText, setClearText] = useState('');

    const handleReset = (clearFilters, handleSearch, confirm, dataIndex, setSelectedKeys) => {
        handleSearch('', confirm, dataIndex, setSelectedKeys)
        clearFilters();
        setSearchText('');
    };
    const clearFocus = (searchInput) => {
        setTimeout(() => searchInput.current?.select(), 100);
    }

    const getColumnSearchProps = (dataIndex, placeholder) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div className='d-flex' style={{ position: 'relative' }}>
                <Input className='form-control'
                    ref={searchInput}
                    placeholder={'Search ' + placeholder}
                    value={selectedKeys[0]}
                    autoFocus={'on'}
                    // onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onChange={(e) => handleSearch(e, confirm, dataIndex, setSelectedKeys)}
                />
                <CloseCircleOutlined className='table-filter-reset'
                    onClick={() => {
                        handleReset(clearFilters,
                            handleSearch, confirm, dataIndex, setSelectedKeys)
                        clearFocus(searchInput)
                    }
                    } />

            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    //     // color: filtered ? '#1890ff' : undefined,
                    //     fontWeight: 'bold',
                    //     // backgroundColor:'blue',
                    //     fontSize: '15px',
                    //     color: 'black'
                }}
            />
            // <FileSearchOutlined 
            // style={{
            //     // color: filtered ? '#1890ff' : undefined,
            //     fontWeight:'bold',
            //     // backgroundColor:'blue',
            //     // fontSize:'20px',
            //     color:'black'
            // }}
            // />
            // <i class="bi bi-search"></i>
        ),
        onFilter: (value, record) =>
            record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text
    });
    const HandelChange = (obj) => {
        setlevelmappingdata({
            ...Levelmappingdata,
            user_id: '',
            assignee_name: '',
            concurrer_name: '',
            approver_name: ''
        })
        let allArray = [];
        if (obj.includes('all')) {
            if (Certilabelunitlist && Certilabelunitlist.units.length > 0) {
                let temp = []
                for (let i in Certilabelunitlist.units) {
                    temp.push((Certilabelunitlist.units[i].unit_id).toString())
                    allArray.push((Certilabelunitlist.units[i].unit_id).toString())
                }

                setAll(temp)
                setComplianceTask(obj)
            }
            Setselectcheckbox('none');
            Setunselectcheckbox('block');
        } else if (obj.includes('unselect')) {
            let temp = [];
            setComplianceTask(obj)
            setAll(temp);
            Setselectcheckbox('block');
            Setunselectcheckbox('none');
        } else {

            if (obj.length == Certilabelunitlist.units.length) {
                Setselectcheckbox('none');
                Setunselectcheckbox('block');
            } else {
                Setselectcheckbox('block');
                Setunselectcheckbox('none');
            }
            setAll(obj)
            setComplianceTask(obj)
        }
        return obj;

    };
    const [mappingid, Setmappingid] = useState({
        mapping_id: ''
    })

    const editservice = (data) => {
        console.log(data, 'data');
        setType("edit")
        setDisableEntity(true)
        const payload = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "AddCLList",
                    {
                        "legal_entity_id": entityid
                    }
                ]
            }
        ]



        if (entityid != "null") {
            CertificateLevelAdd({
                payload: payload,
                paramid: paramid
            })
        }

        setEditbasedata([data])
        setlevelmappingdata({
            ...Levelmappingdata,
            assignee_id: data.assignee,
            concurrer_id: data.concurrence_person,
            approver_id: data.approval_person,
            is_approver: data.is_approver,
            is_concurrence: data.is_concurrence,
            assignee_name: data.assignee_name,
            concurrer_name: data.concurrer_name,
            approver_name: data.approver_name,
            user_id: `${data.approval_person}`,
            user_name: data.user_name,
        })
        setRowDetails({
            ...rowDetails,
            assignee_id: data.assignee,
            concurrer_id: data.concurrence_person,
            approver_id: data.approval_person,
            assignee_name: data.assignee_name,
            concurrer_name: data.concurrer_name,
            approver_name: data.approver_name,
            user_id: `${data.approval_person}`,
            user_name: data.user_name,
        })
        if (data.is_approver == 1) {
            setToggleButton(true)
        }
        if (data.is_concurrence == 1) {
            setToggleButton1(true)
        }
        Setmappingid({
            ...mappingid,
            mapping_id: data.mapping_id
        })
        setShow(true)
        SetTable(false)
        Seteditshow(true)
    }



    // useEffect(() => {
    //     console.log(Levelmappingdata, 'Levelmappingdata');
    //     console.log(Certilabeltablelist, 'Certilabeltablelist');
    //     if (Certilabelunitlist && Certilabelunitlist.assignee_list && Certilabelunitlist.assignee_list.length > 0) {
    //         if (rowDetails.assignee_id === '') {
    //             setAssigneeMatch(true)
    //         }
    //         else {
    //             const assigneeIds = Certilabelunitlist.assignee_list.map((item) => {
    //                 return item.user_id
    //             })
    //             console.log(assigneeIds, 'assigneeIds');
    //             if (assigneeIds && assigneeIds.length > 0) {
    //                 let assigneeIncludes = assigneeIds.includes(rowDetails.assignee_id)
    //                 console.log(assigneeIncludes, 'assigneeIncludes');
    //                 setAssigneeMatch(assigneeIncludes)
    //             }
    //         }
    //     }
    //     if (Certilabelunitlist && Certilabelunitlist.concur_list && Certilabelunitlist.concur_list.length > 0) {
    //         const concurIds = Certilabelunitlist.assignee_list.map((item) => {
    //             return item.user_id
    //         })
    //         if (concurIds && concurIds.length > 0) {
    //             let concurIncludes = concurIds.includes(Levelmappingdata.concurrer_id)
    //             setConcurMatch(concurIncludes)
    //         }
    //     }
    //     if (Certilabelunitlist && Certilabelunitlist.approver_list && Certilabelunitlist.approver_list.length > 0) {
    //         const approverIds = Certilabelunitlist.approver_list.map((item) => {
    //             return item.user_id
    //         })
    //         if (approverIds && approverIds.length > 0) {
    //             let approverIds1 = approverIds.includes(Levelmappingdata.approver_id)
    //             setApproverMatch(approverIds1)
    //         }
    //     }
    //     if (Certilabelunitlist && Certilabelunitlist.user_list && Certilabelunitlist.user_list.length > 0) {
    //         const userIds = Certilabelunitlist.user_list.map((item) => {
    //             return item.user_id
    //         })
    //         if (userIds && userIds.length > 0) {
    //             let user = userIds.includes(Levelmappingdata.user_id)
    //             setUserMatch(user)
    //         }
    //     }
    // }, [Certilabelunitlist, Levelmappingdata,rowDetails])

    const onsubmit = () => {
        if (toggleButton == false && toggleButton1 == false) {
            const reloadpayload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetCLList",
                        {
                            "legal_entity_id": entityid
                        }
                    ]
                }
            ]
            if (all == '' && editshow == false) {
                Toaster.error('Select atleast one vendor');
            } else if (Levelmappingdata.user_id == '' && Levelmappingdata.user_name == '') {
                Toaster.error('User Required');
            } else {
                if (mappingid.mapping_id === '') {
                    const payload = [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "SaveCLMDetails",
                                {
                                    "legal_entity_id": entityid,
                                    "u_ids": [parseInt(all)],
                                    "is_approver": toggleButton,
                                    "is_concurrence": toggleButton1,
                                    "assignee": Number(Levelmappingdata.assignee_id),
                                    "concurrence_person": Levelmappingdata.concurrer_id !== '' ? Number(Levelmappingdata.concurrer_id) : 0,
                                    "approval_person": (toggleButton == true && Levelmappingdata.approver_id !== '') ? Number(Levelmappingdata.approver_id) : Number(Levelmappingdata.user_id),
                                }
                            ]
                        }
                    ]
                    savelevelmapping({
                        payload: payload,
                        paramid: paramid,
                        payload1: reloadpayload
                    })
                    setKey(true)
                    SetTable(true)
                    setShow(false)
                    setToggleButton(false)
                    setToggleButton1(false)
                    setDisableEntity(false)
                } else {
                    const payload = [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "UpdateCLMDetails",
                                {
                                    "legal_entity_id": entityid,
                                    "mapping_id": [mappingid.mapping_id],
                                    "is_approver": toggleButton,
                                    "is_concurrence": toggleButton1,
                                    "assignee": Number(Levelmappingdata.assignee_id),
                                    "concurrence_person": (toggleButton1 == true && Levelmappingdata.concurrer_id !== '') ? Number(Levelmappingdata.concurrer_id) : 0,
                                    "approval_person": (toggleButton == true && Levelmappingdata.approver_id !== '') ? Number(Levelmappingdata.approver_id) : Number(Levelmappingdata.user_id),
                                }
                            ]

                        }
                    ]
                    updatelevelmapping({
                        payload: payload,
                        paramid: paramid,
                        payload1: reloadpayload
                    })
                    setKey(true)
                    SetTable(true)
                    setShow(false)
                    setToggleButton(false)
                    setToggleButton1(false)
                    setDisableEntity(false)
                }
            }
        }
        else if (toggleButton == true && toggleButton1 == false) {
            const reloadpayload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetCLList",
                        {
                            "legal_entity_id": entityid
                        }
                    ]
                }
            ]
            if (all == '' && editshow == false) {
                Toaster.error('Select atleast one vendor');
            }
            else if (Levelmappingdata.assignee_id == '' && Levelmappingdata.assignee_name == '') {
                Toaster.error('Assignee Required');
            } else if (Levelmappingdata.approver_id == '' && Levelmappingdata.approver_name == '') {
                Toaster.error('Approval Required');
            } else {
                if (mappingid.mapping_id === '') {
                    const payload = [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "SaveCLMDetails",
                                {
                                    "legal_entity_id": entityid,
                                    "u_ids": [parseInt(all)],
                                    "is_approver": toggleButton,
                                    "is_concurrence": toggleButton1,
                                    "assignee": Number(Levelmappingdata.assignee_id),
                                    "concurrence_person": Levelmappingdata.concurrer_id !== '' ? Number(Levelmappingdata.concurrer_id) : 0,
                                    "approval_person": (toggleButton == true && Levelmappingdata.approver_id !== '') ? Number(Levelmappingdata.approver_id) : Number(Levelmappingdata.user_id),
                                }
                            ]
                        }
                    ]
                    savelevelmapping({
                        payload: payload,
                        paramid: paramid,
                        payload1: reloadpayload
                    })
                    setKey(true)
                    SetTable(true)
                    setShow(false)
                    setToggleButton(false)
                    setToggleButton1(false)
                    setDisableEntity(false)
                } else {
                    const payload = [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "UpdateCLMDetails",
                                {
                                    "legal_entity_id": entityid,
                                    "mapping_id": [mappingid.mapping_id],
                                    "is_approver": toggleButton,
                                    "is_concurrence": toggleButton1,
                                    "assignee": Number(Levelmappingdata.assignee_id),
                                    "concurrence_person": (toggleButton1 == true && Levelmappingdata.concurrer_id !== '') ? Number(Levelmappingdata.concurrer_id) : 0,
                                    "approval_person": (toggleButton == true && Levelmappingdata.approver_id !== '') ? Number(Levelmappingdata.approver_id) : Number(Levelmappingdata.user_id),
                                }
                            ]

                        }
                    ]
                    updatelevelmapping({
                        payload: payload,
                        paramid: paramid,
                        payload1: reloadpayload
                    })
                    setKey(true)
                    SetTable(true)
                    setShow(false)
                    setToggleButton(false)
                    setToggleButton1(false)
                    setDisableEntity(false)
                }
            }
        }
        else {
            const reloadpayload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetCLList",
                        {
                            "legal_entity_id": entityid
                        }
                    ]
                }
            ]
            if (all == [] && editshow == false) {
                console.log(all, "allallall");
                Toaster.error('Select atleast one vendor');
            } else if (Levelmappingdata.assignee_id == '' && Levelmappingdata.assignee_name == '') {
                Toaster.error('Assignee Required')
            } else if (Levelmappingdata.concurrer_id == '' && Levelmappingdata.concurrer_name == '') {
                Toaster.error('Concurrence Required');
            } else if (Levelmappingdata.approver_id == '' && Levelmappingdata.approver_name == '') {
                Toaster.error('Approval Required');
            }
            else {
                if (mappingid.mapping_id === '') {
                    const payload = [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "SaveCLMDetails",
                                {
                                    "legal_entity_id": entityid,
                                    "u_ids": [parseInt(all)],
                                    "is_approver": toggleButton,
                                    "is_concurrence": toggleButton1,
                                    "assignee": Number(Levelmappingdata.assignee_id),
                                    "concurrence_person": Levelmappingdata.concurrer_id !== '' ? Number(Levelmappingdata.concurrer_id) : 0,
                                    "approval_person": (toggleButton == true && Levelmappingdata.approver_id !== '') ? Number(Levelmappingdata.approver_id) : Number(Levelmappingdata.user_id),
                                }
                            ]
                        }
                    ]
                    savelevelmapping({
                        payload: payload,
                        paramid: paramid,
                        payload1: reloadpayload
                    })
                    setKey(true)
                    SetTable(true)
                    setShow(false)
                    setToggleButton(false)
                    setToggleButton1(false)
                    setDisableEntity(false)
                } else {
                    const payload = [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "UpdateCLMDetails",
                                {
                                    "legal_entity_id": entityid,
                                    "mapping_id": [mappingid.mapping_id],
                                    "is_approver": toggleButton,
                                    "is_concurrence": toggleButton1,
                                    "assignee": Number(Levelmappingdata.assignee_id),
                                    "concurrence_person": (toggleButton1 == true && Levelmappingdata.concurrer_id !== '') ? Number(Levelmappingdata.concurrer_id) : 0,
                                    "approval_person": (toggleButton == true && Levelmappingdata.approver_id !== '') ? Number(Levelmappingdata.approver_id) : Number(Levelmappingdata.user_id),
                                }
                            ]

                        }
                    ]
                    updatelevelmapping({
                        payload: payload,
                        paramid: paramid,
                        payload1: reloadpayload
                    })
                    setKey(true)
                    SetTable(true)
                    setShow(false)
                    setToggleButton(false)
                    setToggleButton1(false)
                    setDisableEntity(false)
                }
            }

        }

    }


    const columns = [
        {
            title: '#',
            dataIndex: false,
            align: 'center',
            width: '20px',
            // ellipsis: true,
            render: (text, record) => {
                return (
                    <>
                        <span>{index + 1}</span>
                        <span hidden>{index = index + 1}</span>
                    </>
                )
            }
        },
        {
            title: 'Vendor Code',
            dataIndex: 'unit_code',
            key: 'unit_code',
            width: '170px',
            // ellipsis: true,
            align: 'center',
            ...getColumnSearchProps('unit_code', 'Vendor Code'),
            // sorter: (a, b) => a.unit_code && b.unit_code != null ? a.unit_code.localeCompare(b.unit_code) : "",
        },
        {
            title: 'Vendor Name',
            dataIndex: 'unit_name',
            key: 'unit_name',
            width: '180px',
            // ellipsis: true,
            ...getColumnSearchProps('unit_name', 'Vendor Name'),
            // sorter: (a, b) => a.unit_name.localeCompare(b.unit_name),
            render: (text, record, index) => {
                return <span>{`${record.unit_code}-${record.unit_name}`}</span>
            }
        },
        {
            title: 'Assignee',
            dataIndex: 'assignee_name',
            key: 'assignee_name',
            // align: 'center',
            // ellipsis: true,
            width: '110px',
            ...getColumnSearchProps('assignee_name', 'Assignee'),
            render: (text, record, index) => {
                return <span>{record.assignee_name}</span>
            }
            // sorter: (a, b) => a.assignee_name.localeCompare(b.assignee_name),
        },
        {
            title: 'Concurrer',
            dataIndex: 'concurrer_name',
            key: 'concurrer_name',
            width: '110px',
            // align: 'center',
            // ellipsis: true,
            render: (text, record, index) => {
                return <span>{record.concurrer_name}</span>
            },
            ...getColumnSearchProps('concurrer_name', 'Concurrer'),
            // sorter: (a, b) => a.concurrer_name.localeCompare(b.concurrer_name),
        },
        {
            title: 'Approver',
            dataIndex: 'approver_name',
            key: 'approver_name',
            // align: 'center',
            // ellipsis: true,
            // align: 'left',
            ...getColumnSearchProps('approver_name', 'Approver'),
            // sorter: (a, b) => a.approver_name.localeCompare(b.approver_name),
            width: '110px',
            render: (text, record, index) => {
                return <span>{record.approver_name}</span>
            }
        },
        {
            title: 'Edit',
            dataIndex: 'false',
            key: 'false',
            align: "center",
            width: '40px',
            render: (text, record) => {
                console.log(record, "record111");
                return (
                    <Fragment>
                        <div className='hover'>
                            <Link className="social-link btn btn-sm btn-light-info text-info btn-circle d-flex 
                                align-items-center justify-content-center margin0" onClick={(e) => { editservice(record) }}>
                                <Tooltip title="Click here to Edit">
                                    <EditOutlined /></Tooltip>
                            </Link>
                        </div>



                        {/* <div className='hover' style={{ marginLeft: '33%' }}>
                            <span className="social-link btn btn-sm btn-light-info text-info btn-circle d-flex 
                                align-items-center justify-content-center"
                                onClick={(e) => { editservice(record) }}
                            >
                                <Tooltip title="Click here to Edit" color='#39cb7f'>
                                    <i className="ri-edit-line"></i></Tooltip>
                            </span>
                        </div> */}
                    </Fragment>
                )
            }
        },
    ];
    const columns1 = [
        {
            title: '#',
            dataIndex: false,
            align: 'center',
            width: '50px',
            // ellipsis: true,
            render: (text, record) => {
                return (
                    <>
                        <span>{index + 1}</span>
                        <span hidden>{index = index + 1}</span>
                    </>
                )
            }
        },
        {
            title: 'Vendor Code',
            dataIndex: 'unit_code',
            key: 'unit_code',
            width: '170px',
            // ellipsis: true,
            align: 'center',
            ...getColumnSearchProps('unit_code', 'Vendor Code'),
            // sorter: (a, b) => a.unit_code && b.unit_code != null ? a.unit_code.localeCompare(b.unit_code) : "",
        },
        {
            title: 'Vendor Name',
            dataIndex: 'unit_name',
            key: 'unit_name',
            width: '150px',
            align: 'center',

            // ellipsis: true,
            ...getColumnSearchProps('unit_name', 'Vendor Name'),
            // sorter: (a, b) => a.unit_name.localeCompare(b.unit_name),
        },
        {
            title: 'Assignee',
            dataIndex: 'assignee_name',
            key: 'assignee_name',
            align: 'center',
            // ellipsis: true,
            width: '120px',
            ...getColumnSearchProps('assignee_name', 'Assignee'),
            // sorter: (a, b) => a.assignee_name.localeCompare(b.assignee_name),
        },
        {
            title: 'Concurrer',
            dataIndex: 'concurrer_name',
            key: 'concurrer_name',
            width: '120px',
            align: 'center',
            // ellipsis: true,
            ...getColumnSearchProps('concurrer_name', 'Concurrer'),
            // sorter: (a, b) => a.concurrer_name.localeCompare(b.concurrer_name),
        },
        {
            title: 'Approver',
            dataIndex: 'approver_name',
            key: 'approver_name',
            align: 'center',
            // ellipsis: true,
            // align: 'left',
            ...getColumnSearchProps('approver_name', 'Approver'),
            // sorter: (a, b) => a.approver_name.localeCompare(b.approver_name),
            width: '120px',
        },
    ];

    const RemarksColumn = [
        {
            title: 'S.No',
            dataIndex: false,
            width: '30px',
            align: 'center',
            render: (text, record, index) => {
                return (page - 1) * 10 + index + 1
            }
        },
        {
            title: 'Description',
            dataIndex: 'r_description',
            key: 'r_description',
            width: '200px',
            ellipsis: true,
            render: (text, record) => <a onClick={() => {
                setRemarks(text)
                setRemarksModal(false)
            }}>{record.r_description}</a>,
        },
    ]

    console.log(Certilabelunitlist, 'Certilabelunitlist');

    useEffect(() => {
        if (recallremarks) {
            let temp = [];
            for (let i = 0; i < recallremarks.length; i++) {
                if (recallremarks[i].r_description != '') {
                    temp.push(recallremarks[i])
                }
                Setremarksvalue(temp)
            }
        }

    }, [recallremarks])

    useEffect(() => {
        formValidator.current.showMessages()
    }, [])
    useEffect(() => {
        if (Certilabelunitlist.concur_list) {
            setConcurrerList(Certilabelunitlist.concur_list)
            setApproverList(Certilabelunitlist.approver_list)
        }

    }, [Certilabelunitlist])

    const handleOk = () => {
        // setIsModalVisible(false);
        setRemarksModal(false)
    };

    const handleCancel = () => {
        // setIsModalVisible(false);
        setRemarksModal(false)
    };
    useEffect(() => {
        if (modalData.legal_entity == '' || null || undefined) {
            setModalData({
                ...modalData,
                legal_entity: entityid
            })

        }
    }, [modalData.legal_entity])
    useEffect(() => {
        if (modalData1.legal_entity == '' || null || undefined) {
            setModalData({
                ...modalData1,
                legal_entity: entityid
            })

        }
    }, [modalData1.legal_entity])

    useEffect(() => {
        if (entityid !== '' && entityid !== null && entityid !== 'null') {
            if (Certilabeltablelist && Certilabeltablelist.certificatemappinglist && Certilabeltablelist.certificatemappinglist.length > 0) {
                setBaseData(Certilabeltablelist.certificatemappinglist);
            } else {
                // toast.error("Selected legal entity haven't mapping list")
                setBaseData([]);
            }
        }
    }, [Certilabeltablelist.certificatemappinglist])
    useEffect(() => {

        if (Certilabeltablelist.certificatemappinglist) {
            setCount(Certilabeltablelist && Certilabeltablelist.certificatemappinglist && Certilabeltablelist.certificatemappinglist.length)
        }

    }, [Certilabeltablelist.certificatemappinglist, count])

    const [baseData, setBaseData] = useState([]);
    console.log(Certilabelunitlist.units, 'Certilabelunitlist.units');
    console.log(baseData, "baseData");
    const [EditbaseData, setEditbasedata] = useState([])
    console.log(EditbaseData, "EditbaseData");
    const [fullscreen, setfullscreen] = useState(false);
    const homescreen = () => {
        historynew.push(homepage);
        // window.location.href = homepage
        document.getElementById('homehover').style.color = "blue"
    }

    useEffect(() => {
        if (entityid !== '' && entityid !== null) {
            setindex(0);
            setCurrent(1);
            setpageState(false)
            setPageSize(25)
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetCLList",
                        {
                            "legal_entity_id": entityid
                        }
                    ]
                }
            ]
            // setAddFormSubmit(true);
            // if (validator.current.allValid()) {Certilabelunitlist

            if (entityid != "null") {
                CertificateLevelTable({
                    payload: payload,
                    paramid: paramid
                })
                SetTable(true)

            }
            // }
        }
    }, [entityid])
    useEffect(() => {
        if (Certilabelunitlist) {
            if (type == 'Add') {
                if (Certilabelunitlist && Certilabelunitlist.units.length > 0) {
                    setShow(true)
                    Seteditshow(false)

                }
                else {
                    toast.error('Selected Legal Entity has no units')
                }
            }
        }
    }, [Certilabelunitlist])

    console.log(ApproverList, 'ApproverList');


    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
            document.addEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch), false);
            return () => {
                document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch));
            };
        }
    }, [fullscreen]);

    // useEffect(() => {
    //     if (((EditbaseData && EditbaseData.length > 0) && (Certilabelunitlist))) {
    //         if (EditbaseData[0].assignee_id != '') {
    //             const assigneeIds = Certilabelunitlist.assignee_list.map((item) => {
    //                 return item.user_id
    //             })
    //             console.log(assigneeIds, 'assigneeIds');
    //             if (assigneeIds && assigneeIds.length > 0) {
    //                 let assigneeIncludes = assigneeIds.includes(EditbaseData[0].assignee_id)
    //                 console.log(assigneeIncludes, 'assigneeIncludes');
    //                 setAssigneeMatch(assigneeIncludes)
    //             }
    //         }
    //         else{
    //                 // setAssigneeMatch(assigneeIncludes)

    //         }
    //     }
    // }, [EditbaseData, Certilabelunitlist])

    useEffect(() => {
        if (Certilabelunitlist) {
            if (Certilabelunitlist.assignee_list && Certilabelunitlist.assignee_list.length > 0) {
                setTempAssigneeList(Certilabelunitlist.assignee_list)
            }
            if (Certilabelunitlist.concur_list && Certilabelunitlist.concur_list.length > 0) {
                setConcurrerList(Certilabelunitlist.concur_list)
            }
        }

    }, [Certilabelunitlist])

    useEffect(() => {
        if (((EditbaseData && EditbaseData.length > 0) && (Certilabelunitlist) && (tempAssigneeList.length > 0))) {
            if (EditbaseData[0].assignee != '') {
                const assigneeIds = Certilabelunitlist.assignee_list.map((item) => {
                    return item.user_id
                })
                console.log(assigneeIds, 'assigneeIds');
                if (assigneeIds && assigneeIds.length > 0) {
                    let assigneeIncludes = assigneeIds.includes(EditbaseData[0].assignee)
                    console.log(assigneeIncludes, 'assigneeIncludes');
                    console.log(assigneeList.length, 'lennn');
                    if (assigneeIncludes === false && assigneeList.length == 0) {
                        console.log('hiii');
                        let temp = tempAssigneeList
                        temp.push({
                            "user_id": EditbaseData && EditbaseData[0].assignee,
                            "employee_name": EditbaseData[0].assignee_name,
                            "employee_code": "5432",
                            "vndr_user_category": 6
                        })
                        setAssigneeMatch(false)
                        console.log(temp, 'temp');
                        // if (assigneeList && assigneeList.length === 0) {
                        setAssigneeList(temp)
                        // }
                    }
                    else {
                        console.log('hello');
                        setAssigneeList(Certilabelunitlist.assignee_list)
                    }
                    // console.log(temp, 'temptemptemp');
                }
            }

            if (EditbaseData[0].concurrence_person != '') {
                const concur = Certilabelunitlist.concur_list.map((item) => {
                    return item.user_id
                })
                if (concur && concur.length > 0) {
                    let concurIncludes = concur.includes(EditbaseData[0].concurrence_person)
                    let temp = []
                    if (concurIncludes === false) {
                        temp = Certilabelunitlist.concur_list
                        temp.push({
                            "user_id": EditbaseData && EditbaseData[0].concurrence_person,
                            "employee_name": EditbaseData[0].concurrer_name,
                            "employee_code": "5432",
                            "vndr_user_category": 6
                        })
                        if (concurrerListNew && concurrerListNew.length === 0) {
                            setConcurrerListNew(temp)
                        }
                    }
                    else {
                        temp = []
                        setConcurrerListNew(Certilabelunitlist.concur_list)
                    }
                    console.log(temp, 'temptemptemp');
                }
            }

            if (EditbaseData[0].approval_person != '') {
                const user = Certilabelunitlist.user_list.map((item) => {
                    return item.user_id
                })
                if (user && user.length > 0) {
                    let userIncludes = user.includes(EditbaseData[0].approval_person)
                    let temp = []
                    if (userIncludes === false) {
                        temp = Certilabelunitlist.user_list
                        temp.push({
                            "user_id": EditbaseData && EditbaseData[0].approval_person,
                            "employee_name": EditbaseData[0].approver_name,
                            "employee_code": "5432",
                            "vndr_user_category": 6
                        })
                        if (userListNew && userListNew.length === 0) {
                            setUserListNew(temp)
                        }
                    }
                    else {
                        temp = []
                        setUserListNew(Certilabelunitlist.user_list)
                    }
                    console.log(temp, 'temptemptemp');
                }
            }

        }

    }, [EditbaseData, Certilabelunitlist, tempAssigneeList])

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode(dispatch);
            setfullscreen(true);
        } else {
            fullscreenexit(dispatch);
            setfullscreen(false);
        }
    }
    return (
        <Fragment>
            <div>
                <div id='page-wrapper' className="page-wrapper">
                    <ScrollToTop smooth color="red" component={<Tooltip title="Back to top"><UpOutlined /></Tooltip>} />
                    <div className="page-titles pb-1 pt-1 page-title-sticky">
                        <div className="row">
                            <div className="col-lg-8 col-md-6 col-12 align-self-center">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 d-flex align-items-center">
                                        <li className="breadcrumb-item">
                                            <span className='pointer' id='homehover' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                        </li>
                                        <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                            <span style={{ fontSize: '16px' }}>Master</span>
                                        </li>
                                        <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                            <span >Certification Levels</span>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                            <div className="col-lg-4 col-md-6 text-end justify-content-end">
                                {show == false ? <Tooltip title="Add Level Mapping" placement='left'> <Button
                                    onClick={() => {
                                        setType('Add')
                                        setAll([])
                                        setlevelmappingdata({
                                            ...Levelmappingdata,
                                            assignee_id: '',
                                            assignee_name: '',
                                            concurrer_id: '',
                                            concurrer_name: '',
                                            approver_id: '',
                                            approver_name: '',
                                            user_id: '',
                                            user_name: '',
                                            is_approver: null,
                                            is_concurrence: null,
                                        })

                                        setToggleButton(false)
                                        setToggleButton1(false)
                                        // if (Certilabelunitlist && Certilabelunitlist.units.length > 0) {
                                        const payload = [
                                            authtoken,
                                            {
                                                "session_token": authtoken,
                                                "request": [
                                                    "AddCLList",
                                                    {
                                                        "legal_entity_id": entityid
                                                    }
                                                ]
                                            }
                                        ]
                                        setAddFormSubmit(true);
                                        if (validator.current.allValid()) {
                                            SetTable(false)
                                            if (entityid != "null") {
                                                CertificateLevelAdd({
                                                    payload: payload,
                                                    paramid: paramid
                                                })
                                            }
                                        }

                                    }}
                                    type="primary"
                                    shape="round"
                                    icon={<PlusCircleOutlined />}
                                    className='addbutton'
                                    style={{ marginRight: '2%' }}

                                >
                                    Add
                                </Button> </Tooltip> : <Tooltip placement='left' title="" > <Button className='addbutton' shape="round" type="primary"
                                    icon={<ArrowLeftOutlined />} size='default' style={{ marginRight: '2%' }} onClick={() => {
                                        setKey(true)
                                        SetTable(true)
                                        setShow(false)
                                        setToggleButton(false)
                                        setToggleButton1(false)
                                        setDisableEntity(false)
                                        Setselectcheckbox('block')
                                        Setunselectcheckbox('none')
                                        setAssigneeList([])
                                        setConcurrerListNew([])
                                        setUserListNew([])
                                    }}>Back

                                </Button> </Tooltip>}
                                <button onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black full-mode fullscreenradius" >
                                    <Tooltip placement="left" title={is_Fullscreen == true ? "Exit Full Screen Mode" : "Full Screen Mode"}><FullscreenOutlined /></Tooltip>
                                </button>
                            </div>
                            <br></br>
                            <div className='col-md-12' style={{ marginTop: '10px' }}>
                                <div className='row'>
                                    <div className='col-md-6' >
                                        <label style={{ "font-weight": 'bold' }}>Legal Entity<span style={{ "color": "red" }}> *</span></label>
                                        {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                            <div className="form-group" style={{ "margin-top": '-24px', "margin-left": '105px' }}>
                                                <Select style={{ width: "80%" }}
                                                    allowClear={true}
                                                    size="default"
                                                    disabled={disableEntity}
                                                    placeholder="Select Legal Entity"
                                                    onChange={(data, value) => {
                                                        if (data !== undefined) {
                                                            setCurrentEntity(data)
                                                            setLegalentity(data)
                                                            setShow(false)
                                                            setBaseData([])
                                                        }
                                                        else {
                                                            setCurrentEntity('')
                                                            setLegalentity('')
                                                            setShow(false)
                                                            setBaseData([])
                                                            setShow(false)
                                                        }

                                                    }}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    display="none"
                                                >
                                                    {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                        return (
                                                            <Option title="" key={item.le_id}>
                                                                {item.le_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                                {validator.current.message(
                                                    'legalentity',
                                                    legalentity,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Legal Entity Required',
                                                        }
                                                    })}
                                            </div>

                                            :
                                            <p style={{ width: '100%', marginTop: "5px" }}>{localStorage.getItem('SelectedEntity')}</p>

                                        }
                                    </div>
                                    {/* <div className='col-md-1'></div> */}
                                    {show == true ?

                                        <div className='col-md-6' style={{ marginTop: '-24px' }}>
                                            <div className={'row popupbtncolour ' + localStorage.getItem('currentTheme')}>
                                                <div className="col-md-3 ">
                                                    <label style={{ "font-weight": 'bold' }}>Approver:</label>

                                                    <div className="serv-switch">
                                                        <Switch
                                                            checkedChildren={<span style={{ color: localStorage.getItem('currentTheme') == 'theme_four' ? 'black' : 'white' }}>Yes</span>}
                                                            unCheckedChildren={<span style={{ color: localStorage.getItem('currentTheme') == 'theme_four' ? 'black' : 'white' }}>No</span>}
                                                            onChange={(checked) => {
                                                                console.log(checked, 'eeee');
                                                                setToggleButton(checked)
                                                                if (checked == false) {
                                                                    setToggleButton1(false)
                                                                    // setAssigneeList(Certilabelunitlist.assignee_list)
                                                                    // setConcurrerListNew(Certilabelunitlist.concur_list)
                                                                }
                                                                setAssigneeList(Certilabelunitlist.assignee_list)
                                                                setlevelmappingdata({
                                                                    ...Levelmappingdata,
                                                                    assignee_id: "",
                                                                    assignee_name: '',
                                                                    concurrer_id: '',
                                                                    concurrer_name: '',
                                                                    approver_id: '',
                                                                    approver_name: "",
                                                                    user_id: '',
                                                                })
                                                                setAddFormSubmit2(false);
                                                                setAddFormSubmit1(false);
                                                                setAddFormSubmit3(false);
                                                            }}
                                                            checked={toggleButton}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-3">

                                                    {toggleButton == true ? <>
                                                        <label style={{ "font-weight": 'bold' }}>Concurrer:</label>
                                                        <div className="serv-switch">
                                                            <Switch
                                                                checkedChildren={<span style={{ color: localStorage.getItem('currentTheme') == 'theme_four' ? 'black' : 'white' }}>Yes</span>}
                                                                unCheckedChildren={<span style={{ color: localStorage.getItem('currentTheme') == 'theme_four' ? 'black' : 'white' }}>No</span>}
                                                                onChange={(checked) => {
                                                                    setToggleButton1(checked)



                                                                    setlevelmappingdata({
                                                                        ...Levelmappingdata,
                                                                        assignee_id: checked == true ? "" : Levelmappingdata.assignee_id,
                                                                        assignee_name: checked == true ? "" : Levelmappingdata.assignee_name,
                                                                        concurrer_id: '',
                                                                        concurrer_name: '',
                                                                        approver_id: '',
                                                                        approver_name: "",
                                                                        user_id: '',

                                                                    })


                                                                    setAddFormSubmit2(false);
                                                                    setAddFormSubmit1(false);
                                                                    setAddFormSubmit3(false);
                                                                }}
                                                                checked={toggleButton1}
                                                            />
                                                        </div>
                                                    </> : ''}
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className='col-md-4'>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {show == true ?
                        <>
                            <div className="col-md-12" style={{ marginTop: "5px" }}>
                                <div className="row">
                                    <div className="form-group">
                                        <div className="card-body" style={{ paddingTop: 0 }}>
                                            {editshow == false ?

                                                <Card className='userCard vendorCard' id="only-restricted" style={{ borderRadius: "15px" }} headStyle={{ borderTopRightRadius: "15px", borderTopLeftRadius: '15px', height: '10px', background: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ', color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }} title={<p>Vendor & User Selection</p>} key="1" >
                                                    {toggleButton == true ? <>
                                                        <div className="col-md-12">
                                                            <div className="row">
                                                                <div className='col-md-4'>
                                                                    <label><b> Vendor</b>  <span style={{ color: "red" }}>*</span></label>
                                                                    <Select
                                                                        name="units"
                                                                        id="units"
                                                                        optionFilterProp="children"

                                                                        showSearch
                                                                        filterOption={(input, option) =>

                                                                            // option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 
                                                                            option?.children != undefined ? option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false


                                                                        }
                                                                        className='form-control'
                                                                        placeholder="Enter Vendor"
                                                                        onChange={(option) => HandelChange(option)}
                                                                        mode="multiple"
                                                                        value={all || undefined}
                                                                    // maxTagCount='responsive'
                                                                    // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                    >
                                                                        {Certilabelunitlist && Certilabelunitlist.units.length > 1 ?
                                                                            <>
                                                                                <Option key="all" value="all" style={{ display: selectcheckbox }}>---SELECT ALL---</Option>
                                                                                <Option key="unselect" value="unselect" style={{ display: unselectcheckbox }}>--UNSELECT ALL--</Option></> : false}
                                                                        {Certilabelunitlist && Certilabelunitlist.units.length > 0 && Certilabelunitlist.units.map((item, i) => {
                                                                            return (
                                                                                <Option key={item.unit_id}>
                                                                                    {item.unit_code} - {item.unitname} - {item.address}
                                                                                </Option>
                                                                            );
                                                                        })}

                                                                    </Select>

                                                                </div>
                                                                <div className='col-md-4'>
                                                                    <label><b>Assignee</b>   <span style={{ color: "red" }}>*</span></label>
                                                                    <div className="form-group">
                                                                        <Select name="action" className='select2 form-control custom-select bg-white' id="action" placeholder="Please Select"
                                                                            value={Levelmappingdata.assignee_id != null ? `${Levelmappingdata.assignee_id}` : undefined}
                                                                            // disabled={all.length == 0 ? true : false}

                                                                            allowClear={true}

                                                                            onChange={(value, data) => {
                                                                                setlevelmappingdata({
                                                                                    ...Levelmappingdata,
                                                                                    assignee_id: value ? value : '',
                                                                                    assignee_name: data ? data.children : '',
                                                                                    approver_id: '',
                                                                                    approver_name: '',
                                                                                    concurrer_id: '',
                                                                                    concurrer_name: ''
                                                                                })
                                                                                if (value !== undefined) {
                                                                                    let data = _.reject(Certilabelunitlist.concur_list, { user_id: Number(value) })
                                                                                    setConcurrerList(data)
                                                                                    let data1 = _.reject(Certilabelunitlist.approver_list, { user_id: Number(value) })
                                                                                    setApproverList(data1)
                                                                                }
                                                                                else {


                                                                                    setConcurrerList(Certilabelunitlist.concur_list)
                                                                                    setApproverList(Certilabelunitlist.approver_list)
                                                                                }
                                                                            }}>
                                                                            <Option key={''}>Select</Option>
                                                                            {assigneeList && assigneeList.length > 0 && assigneeList.map((item, i) => {
                                                                                return (
                                                                                    <Option key={item.user_id}>
                                                                                        {item.employee_name}
                                                                                    </Option>
                                                                                )
                                                                            })}

                                                                        </Select>
                                                                    </div>
                                                                </div>
                                                                {toggleButton1 == true ?
                                                                    <div className='col-md-4'>
                                                                        <label><b> Concurrer</b>   <span style={{ color: "red" }}>*</span></label>
                                                                        <div className="form-group">
                                                                            <Select name="action" className='select2 form-control custom-select bg-white' id="action" placeholder="Please Select"
                                                                                value={Levelmappingdata.concurrer_name || undefined}
                                                                                // disabled={Levelmappingdata.assignee_name === '' ? true : false}
                                                                                allowClear={true}
                                                                                onChange={(value, data) => {
                                                                                    setlevelmappingdata({
                                                                                        ...Levelmappingdata,
                                                                                        concurrer_id: value ? value : '',
                                                                                        concurrer_name: data ? data.children : '',
                                                                                        approver_id: '',
                                                                                        approver_name: '',
                                                                                        // is_approver: null,
                                                                                        // is_concurrence: null,
                                                                                    })
                                                                                    console.log(((value !== undefined) && Number(Levelmappingdata.assignee_id)), '((value !== undefined) && Number(Levelmappingdata.assignee_id))');
                                                                                    // if ((value !== undefined)&&(value == Number(Levelmappingdata.assignee_id))) {
                                                                                    //     let data1 = _.reject(Certilabelunitlist.approver_list, { user_id: Number(Levelmappingdata.assignee_id) })
                                                                                    //     let data2 = _.reject(data1, { user_id: value })
                                                                                    //     setApproverList(data2)
                                                                                    // }
                                                                                    // else
                                                                                    if (value !== undefined) {
                                                                                        let data1 = _.reject(Certilabelunitlist.approver_list, { user_id: Number(value) })
                                                                                        setApproverList(data1)
                                                                                    } else {
                                                                                        setApproverList(Certilabelunitlist.approver_list)
                                                                                    }
                                                                                }}
                                                                            >
                                                                                {/* {Certilabelunitlist.concur_list && Certilabelunitlist.concur_list.length && Certilabelunitlist.concur_list.map((item, i) => {
                                                                                return (
                                                                                    <Option key={item.user_id}>
                                                                                        {item.employee_name}
                                                                                    </Option>
                                                                                );
                                                                            })} */}
                                                                                <Option key={''}>Select</Option>
                                                                                {concurrerList && concurrerList.length && concurrerList.map((item, i) => {
                                                                                    return (
                                                                                        <Option key={item.user_id}>
                                                                                            {item.employee_name}
                                                                                        </Option>
                                                                                    );
                                                                                })}


                                                                            </Select>

                                                                        </div>
                                                                        <div className="col-md-4">

                                                                        </div>
                                                                        <div className="col-md-4">

                                                                        </div>
                                                                    </div>
                                                                    : ''}
                                                                <div className='col-md-4'>
                                                                    <label><b> Approver</b>  <span style={{ color: "red" }}>*</span></label>
                                                                    <div className="form-group">
                                                                        <Select name="action" className='select2 form-control custom-select bg-white' id="action" placeholder="Please Select"
                                                                            // disabled={Levelmappingdata.assignee_name === '' ? true : false}
                                                                            value={Levelmappingdata.approver_name || undefined}
                                                                            allowClear={true}

                                                                            onChange={(value, data) => {
                                                                                setlevelmappingdata({
                                                                                    ...Levelmappingdata,
                                                                                    approver_id: value ? value : '',
                                                                                    approver_name: data ? data.children : '',
                                                                                    // concurrer_id: '',
                                                                                    // concurrer_name: ''
                                                                                })
                                                                            }} >
                                                                            {/* {Certilabelunitlist.approver_list && Certilabelunitlist.approver_list.length && Certilabelunitlist.approver_list.map((item, i) => {
                                                                                return (
                                                                                    <Option key={item.user_id}>
                                                                                        {item.employee_name}
                                                                                    </Option>
                                                                                )
                                                                            })} */}
                                                                            <Option key={''}>Select</Option>
                                                                            {ApproverList && ApproverList.length && ApproverList.map((item, i) => {
                                                                                return (

                                                                                    <Option key={item.user_id}>
                                                                                        {item.employee_name}
                                                                                    </Option>
                                                                                )
                                                                            })}

                                                                        </Select>

                                                                    </div>
                                                                </div>


                                                            </div>

                                                        </div>
                                                    </> :
                                                        <div className="col-md-12">
                                                            <div className="row">


                                                                <div className='col-md-5'>
                                                                    <label><b> Vendor</b>  <span style={{ color: "red" }}>*</span></label>
                                                                    <Select
                                                                        name="units"
                                                                        id="units"
                                                                        className='form-control'
                                                                        placeholder="Enter Vendor"
                                                                        onChange={(option) => HandelChange(option)}
                                                                        mode="multiple"
                                                                        value={all}
                                                                    // maxTagCount='responsive'
                                                                    // optionFilterProp="children"

                                                                    // showSearch
                                                                    // filterOption={(input, option) =>

                                                                    //     // option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 
                                                                    //     option?.children != undefined ? option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false


                                                                    // }
                                                                    >
                                                                        {Certilabelunitlist && Certilabelunitlist.units.length > 1 ?
                                                                            <>
                                                                                <Option key="all" value="all" style={{ display: selectcheckbox }}>---SELECT ALL---</Option>
                                                                                <Option key="unselect" value="unselect" style={{ display: unselectcheckbox }}>--UNSELECT ALL--</Option></> : false}
                                                                        {Certilabelunitlist && Certilabelunitlist.units.length > 0 && Certilabelunitlist.units.map((item, i) => {
                                                                            return (
                                                                                <Option key={item.unit_id}>
                                                                                    {item.unit_code} - {item.unitname} - {item.address}
                                                                                </Option>
                                                                            );
                                                                        })}

                                                                    </Select>

                                                                </div>
                                                                <div className='col-md-5'>
                                                                    <label><b> User</b>  <span style={{ color: "red" }}>*</span></label>

                                                                    <Select name="action" className='select2 form-control custom-select bg-white' id="action" placeholder="Please Select"
                                                                        defaultValue={"Select"}
                                                                        value={Levelmappingdata.user_id || undefined}
                                                                        allowClear={true}
                                                                        // disabled={all.length == 0 ? true : false}


                                                                        onChange={(value, data) => {
                                                                            setlevelmappingdata({
                                                                                ...Levelmappingdata,
                                                                                user_id: value ? value : '',
                                                                                user_name: data ? data.children : ''
                                                                            })
                                                                        }} >
                                                                        {/* {approverMatch === false ?
                                                                            <Option key={Levelmappingdata.approver_id}>{Levelmappingdata.approver_name}</Option> : ''
                                                                        } */}
                                                                        {userListNew && userListNew.length && userListNew.map((item, i) => {
                                                                            return (
                                                                                <Option key={item.user_id}>
                                                                                    {item.employee_name}
                                                                                </Option>
                                                                            );
                                                                        })}

                                                                    </Select>

                                                                </div>
                                                                <div className="col-md-2" >
                                                                    <Button type="primary" shape="round" className='addbutton ms-2'
                                                                        style={{ marginTop: '11%' }}
                                                                        icon={<PlayCircleOutlined />} size='default'
                                                                        onClick={onsubmit}

                                                                    >
                                                                        Submit
                                                                    </Button>
                                                                </div>
                                                                {/* <div className="col-md-4"></div> */}
                                                            </div>
                                                        </div>}
                                                    <div className='col-md-12'>
                                                        <div className='row'>
                                                            <div className='col-md-4'></div>
                                                            <div className='col-md-4'>
                                                                {toggleButton == true ?
                                                                    <div className="form-actions" style={{ textAlign: 'center' }}>
                                                                        <Button type="primary" shape="round" className='addbutton'
                                                                            style={{ background: "#198754", borderColor: "#198754", marginTop: '3%' }}
                                                                            icon={<PlayCircleOutlined />} size='default'
                                                                            onClick={onsubmit}

                                                                        >
                                                                            Submit
                                                                        </Button>
                                                                    </div> : false}
                                                            </div>
                                                            <div className='col-md-4'></div>
                                                        </div>
                                                    </div>
                                                </Card>
                                                : ''}
                                            {editshow == true ?
                                                <>
                                                    <div className="card-body" style={{ padding: '0px' }}>
                                                        <div className='service-provider-table'>
                                                            <Table
                                                                columns={columns1}
                                                                className={localStorage.getItem("currentTheme")}
                                                                dataSource={EditbaseData}
                                                                bordered
                                                                pagination={false}

                                                            />
                                                        </div>
                                                    </div>
                                                    <Card className='userCard vendorCard mt-3' style={{ borderRadius: "15px" }}
                                                        headStyle={{ borderTopRightRadius: "15px", borderTopLeftRadius: '15px', height: '10px', background: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ', color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }} title={<p>User Selection</p>} key="1" >
                                                        {toggleButton == true ? <>
                                                            <div className="col-md-12">
                                                                <div className="row">

                                                                    <div className='col-md-4'>
                                                                        <label><b>Assignee</b>   <span style={{ color: "red" }}>*</span></label>
                                                                        <div className="form-group">
                                                                            <Select name="action" className='select2 form-control custom-select bg-white' id="action" placeholder="Please Select"
                                                                                value={Levelmappingdata.assignee_id != null ? `${Levelmappingdata.assignee_id}` : undefined}
                                                                                allowClear={true}
                                                                                // disabled={all === '' ? true : false}
                                                                                onChange={(value, data) => {
                                                                                    setlevelmappingdata({
                                                                                        ...Levelmappingdata,
                                                                                        assignee_id: value ? value : '',
                                                                                        assignee_name: data ? data.children : '',
                                                                                        concurrer_id: '',
                                                                                        concurrer_name: '',
                                                                                        approver_id: '',
                                                                                        approver_name: '',

                                                                                    })
                                                                                    if (value !== undefined) {
                                                                                        let data = _.reject(Certilabelunitlist.concur_list, { user_id: Number(value) })
                                                                                        setConcurrerListNew(data)
                                                                                        let data1 = _.reject(Certilabelunitlist.approver_list, { user_id: Number(value) })
                                                                                        setApproverList(data1)
                                                                                    }
                                                                                    else {


                                                                                        setConcurrerListNew(Certilabelunitlist.concur_list)
                                                                                        setApproverList(Certilabelunitlist.approver_list)
                                                                                    }

                                                                                }}>
                                                                                <Option key={''}>Select</Option>
                                                                                {/* {assigneeMatch === false ?
                                                                                    <Option key={EditbaseData[0].assignee}>{EditbaseData[0].assignee_name}</Option>
                                                                                    : ''} */}
                                                                                {assigneeList && assigneeList.length > 0 && assigneeList.map((item, i) => {
                                                                                    return (
                                                                                        <Option key={item.user_id}>
                                                                                            {item.employee_name}
                                                                                        </Option>
                                                                                    )
                                                                                })}

                                                                            </Select>

                                                                        </div>
                                                                    </div>
                                                                    {toggleButton1 == true ?

                                                                        <div className='col-md-4'>
                                                                            <label><b> Concurrer</b>   <span style={{ color: "red" }}>*</span></label>
                                                                            <div className="form-group">
                                                                                <Select name="action" className='select2 form-control custom-select bg-white' id="action" placeholder="Please Select"

                                                                                    value={Levelmappingdata.concurrer_id != null ? `${Levelmappingdata.concurrer_id}` : undefined}
                                                                                    // disabled={Levelmappingdata.assignee_id === '' ? true : false}
                                                                                    allowClear={true}
                                                                                    onChange={(value, data) => {
                                                                                        setlevelmappingdata({
                                                                                            ...Levelmappingdata,
                                                                                            concurrer_id: value ? value : '',
                                                                                            concurrer_name: data ? data.children : '',
                                                                                            approver_id: '',
                                                                                            approver_name: '',
                                                                                            // is_approver: null,
                                                                                            // is_concurrence: null,
                                                                                        })
                                                                                        console.log(((value !== undefined) && Number(Levelmappingdata.assignee_id)), '((value !== undefined) && Number(Levelmappingdata.assignee_id))');
                                                                                        // if (((value !== undefined) && Number(Levelmappingdata.assignee_id))) {
                                                                                        //     let data1 = _.reject(Certilabelunitlist.approver_list, { user_id: Number(Levelmappingdata.assignee_id) })
                                                                                        //     let data2 = _.reject(data1, { user_id: value })
                                                                                        //     setApproverList(data2)
                                                                                        // }
                                                                                        // else 
                                                                                        if (value !== undefined) {
                                                                                            let data1 = _.reject(Certilabelunitlist.approver_list, { user_id: Number(value) })
                                                                                            setApproverList(data1)
                                                                                        } else {
                                                                                            setApproverList(Certilabelunitlist.approver_list)
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    <Option key={''}>Select</Option>
                                                                                    {/* {concurMatch === false ?
                                                                                        <Option key={Levelmappingdata.concurrer_id}>{Levelmappingdata.concurrer_name}</Option>
                                                                                        : ''} */}
                                                                                    {concurrerListNew && concurrerListNew.length && concurrerListNew.map((item, i) => {
                                                                                        return (
                                                                                            <Option key={item.user_id}>
                                                                                                {item.employee_name}
                                                                                            </Option>
                                                                                        );
                                                                                    })}

                                                                                </Select>

                                                                            </div>
                                                                            <div className="col-md-4">

                                                                            </div>
                                                                            <div className="col-md-4">


                                                                            </div>
                                                                        </div> : ''}
                                                                    <div className='col-md-4'>
                                                                        <label><b> Approver</b>  <span style={{ color: "red" }}>*</span></label>
                                                                        <div className="form-group">
                                                                            <Select name="action" className='select2 form-control custom-select bg-white' id="action" placeholder="Please Select"

                                                                                // disabled={Levelmappingdata.assignee_id === '' ? true : false}
                                                                                value={Levelmappingdata.approver_id != null ? `${Levelmappingdata.approver_id}` : undefined}
                                                                                allowClear={true}

                                                                                onChange={(value, data) => {
                                                                                    console.log(data, 'data');
                                                                                    console.log(value, 'value');
                                                                                    setlevelmappingdata({
                                                                                        ...Levelmappingdata,
                                                                                        approver_id: value ? value : '',
                                                                                        approver_name: data ? data.children : ''
                                                                                    })
                                                                                }} >
                                                                                <Option key={''}>Select</Option>
                                                                                {ApproverList && ApproverList.length && ApproverList.map((item, i) => {
                                                                                    return (
                                                                                        <Option key={item.user_id}>
                                                                                            {item.employee_name}
                                                                                        </Option>
                                                                                    )
                                                                                })}

                                                                            </Select>

                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </> :
                                                            <div className="col-md-12">
                                                                <div className="row">


                                                                    <div className='col-md-4'>

                                                                    </div>
                                                                    <div className='col-md-4'>
                                                                        <label><b> User</b>  <span style={{ color: "red" }}>*</span></label>

                                                                        <Select name="action" className='select2 form-control custom-select bg-white' id="action" placeholder="Please Select"
                                                                            defaultValue={"Select"}
                                                                            value={Levelmappingdata.user_id != null ? `${Levelmappingdata.user_id}` : undefined}
                                                                            allowClear={true}
                                                                            onChange={(value, data) => {
                                                                                setlevelmappingdata({
                                                                                    ...Levelmappingdata,
                                                                                    user_id: value ? value : '',
                                                                                    user_name: data ? data.children : ''
                                                                                })
                                                                            }} >
                                                                            <Option key={''}>Select</Option>
                                                                            {/* {approverMatch === false ?
                                                                                <Option key={Levelmappingdata.approver_id}>{Levelmappingdata.approver_name}</Option> : ''
                                                                            } */}
                                                                            {Certilabelunitlist.user_list && Certilabelunitlist.user_list.length && Certilabelunitlist.user_list.map((item, i) => {
                                                                                return (
                                                                                    <Option key={item.user_id}>
                                                                                        {item.employee_name}
                                                                                    </Option>
                                                                                );
                                                                            })}

                                                                        </Select>

                                                                    </div>

                                                                    <div className="col-md-4"></div>
                                                                </div>
                                                            </div>}
                                                        <div className='col-md-12'>
                                                            <div className='row'>
                                                                <div className='col-md-4'></div>
                                                                <div className='col-md-4'>
                                                                    <div className="form-actions mt-2" style={{ textAlign: 'center' }}>
                                                                        <Button type="primary" shape="round" className='addbutton'
                                                                            style={{ background: "#198754", borderColor: "#198754" }}
                                                                            icon={<PlayCircleOutlined />} size='default'
                                                                            onClick={onsubmit}

                                                                        >
                                                                            Submit
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-4'></div>
                                                            </div>
                                                        </div>
                                                    </Card>

                                                </>
                                                : ''}




                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        : ""}



                    {show == false ?
                        <div className="container-fluid pt-0">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body" style={{ padding: '0px' }}>
                                            <div className='service-provider-table'>
                                                <Table
                                                    columns={columns}
                                                    className={localStorage.getItem("currentTheme")}
                                                    dataSource={baseData}
                                                    bordered
                                                    pagination={false}

                                                />
                                            </div>
                                            {baseData && baseData > 0 ?
                                                <div className='row mt-2 mb-2'>

                                                    <div className='col-md-6'>
                                                        <label>Showing {pageSize * (current - 1) + 1} to {count} of {count} entries</label>
                                                    </div>
                                                    <div className='col-md-6 text-end'>
                                                        <Pagination
                                                            current={current}
                                                            showSizeChanger={true}
                                                            showQuickJumper={false}
                                                            onShowSizeChange={() => {
                                                                setCurrent(1)
                                                                // setpageState(true)
                                                            }}
                                                            pageSizeOptions={[25, 50, 100]}
                                                            // hideOnSinglePage={count > 25 ? false : true}
                                                            defaultPageSize={25}
                                                            onChange={(page, pageSizes) => {
                                                                setpageState(true)
                                                                setCurrent(pageSize !== pageSizes ? 1 : page);
                                                                setPageSize(pageSizes)
                                                            }} total={count} />
                                                    </div>

                                                </div>
                                                : ''}
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                        : ''}

                </div >

            </div>

        </Fragment >
    )
}
export default connect(mapStateToProps, {
    CertificateLevelTable,
    CertificateLevelAdd,
    GetRemarksData,
    savelevelmapping,
    updatelevelmapping,
})(CertiticateLevelMapping);